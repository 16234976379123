import { Box, BoxProps, makeStyles } from '@material-ui/core';
import React from 'react';

const useStyles = makeStyles((theme) => ({
  root: {
    overflowX: 'hidden',

    display: 'flex',
    flexDirection: 'column',
    alignContent: 'center',
    rowGap: 24,

    padding: '20px 0',
    width: '100%',
    height: 'fit-content',
    minHeight: '100%',

    background: theme.palette.grey['100'],

    [theme.breakpoints.up('sm')]: {
      alignContent: 'flex-start',
      padding: 24,
    },
  },
}));

const Page = (props: BoxProps) => {
  const { children, ...rest } = props;
  const classes = useStyles();

  return (
    <Box className={classes.root} {...rest}>
      {children}
    </Box>
  );
};

Page.displayName = 'Page';
export { Page };
