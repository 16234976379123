import axios, { AxiosResponse } from 'axios';

import Headers from './headers';
import { Coin, CoinMetaData, CoinsWithMasterCoins } from './types/coin';

const API_URL = '/api/coin';

const getCoins = (): Promise<AxiosResponse<Coin[]>> => axios.get(`${API_URL}/all`, { headers: Headers.def() });

const getCoin = (coinId: number): Promise<Coin> => axios.get(`${API_URL}?coinId=${coinId}`,
  { headers: Headers.def() })
  .then((response: AxiosResponse<Coin>) => response.data);

const updateMetaData = (coinId: number, metaData: CoinMetaData): Promise<CoinMetaData> => axios.post(
  `${API_URL}/updateMetadata`, {
    coinId,
    metaData,
  },
  { headers: { ...Headers.def(), ...Headers.auth() } },
)
  .then((response: AxiosResponse<CoinMetaData>) => response.data);

const getCoinsWithMasterCoins = (): Promise<CoinsWithMasterCoins> => axios.get(
  `${API_URL}/getCoinsWithMasterCoins`, { headers: Headers.def() },
)
  .then((response: AxiosResponse<CoinsWithMasterCoins>) => response.data);

export default {
  getCoins,
  getCoin,
  updateMetaData,
  getCoinsWithMasterCoins,
};
