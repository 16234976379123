import { Box, createStyles, makeStyles } from '@material-ui/core';
import { WalletRow } from 'components/dashboard/MultiWallets/WalletRow';
import { Typography } from 'elements';
import { LightFrenchFries } from 'elements/LightFrenchFries';
import React from 'react';
import { AddOrUpdateAddressBookParameter } from 'services/types/workItemParameter';
import { useStore as useUserStore } from 'store/zustand/User';
import { useStore as useWalletStore } from 'store/zustand/Wallet';

const useStyles = makeStyles(() => createStyles({
  address: {
    display: 'flex',
    gap: 4,
    flexWrap: 'nowrap',
    minWidth: 0,
    alignItems: 'center',
  },
}));

const AddOrUpdateAddressBookDetail = (props: AddOrUpdateAddressBookParameter) => {
  const classes = useStyles();
  const [user] = useUserStore((state) => [state.user]);
  const [wallet] = useWalletStore((state) => [state.wallet]);

  const selectedWalletInfo = React.useMemo(
    () => (user?.walletInfo.find((w) => w.walletId === props.walletId)), [user, wallet],
  );
  return (
    <>
      {props.addressBookToUpdate ? (
        <>
          <Typography variant="h6" paletteColor={700} align="center">Update address book</Typography>
          <Box className={classes.address}>
            <LightFrenchFries noShrink label={props.addressBookToUpdate.tag} autoAssignColor />
            <Typography variant="h6" paletteColor={600} middleTruncation>{props.addressBookToUpdate.address}</Typography>
          </Box>
          <Typography variant="h6" paletteColor={700}>change to</Typography>
          <Box className={classes.address}>
            <LightFrenchFries noShrink label={props.name} autoAssignColor />
            <Typography variant="h6" paletteColor={600} middleTruncation>{props.address}</Typography>
          </Box>
        </>
      ) : (
        <>
          <Typography variant="h6" paletteColor={700} align="center">Create new address book</Typography>
          <Box className={classes.address}>
            <LightFrenchFries noShrink label={props.name} autoAssignColor />
            <Typography variant="h6" paletteColor={600} middleTruncation>{props.address}</Typography>
          </Box>
        </>
      )}
      {selectedWalletInfo && (
        <Box display="flex" alignItems="center" gridGap="12px" justifyContent="center">
          <Typography variant="h4" paletteColor={800} align="center">for wallet :</Typography>
          <WalletRow walletInfo={selectedWalletInfo} />
        </Box>
      )}
    </>
  );
};

export default AddOrUpdateAddressBookDetail;
