import { Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import React from 'react';

interface Props {
  outterCircleRadius: number;
  innerCircleRadius: number;
  color: string;
  className?: string;
  isSelected: boolean;
}

export function CustomRadioButton(props: Props) {
  const useStyles = makeStyles(() => ({
    root: {
      width: props.outterCircleRadius * 2,
      height: props.outterCircleRadius * 2,
      boxSizing: 'border-box',
      border: '1px solid',
      borderRadius: props.outterCircleRadius,
      borderColor: props.color,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    innerCircle: {
      width: props.innerCircleRadius * 2,
      height: props.innerCircleRadius * 2,
      borderRadius: props.innerCircleRadius,
      backgroundColor: props.color,
      display: props.isSelected ? 'flex' : 'none',
    },
  }));

  const classes = useStyles();

  return (
    <Box className={`${props.className ? props.className : ''} ${classes.root}`}>
      <Box className={classes.innerCircle} />
    </Box>
  );
}
