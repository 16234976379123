import { makeStyles, TextField, TextFieldProps } from '@material-ui/core';
import React from 'react';

type LightTextFieldProps = TextFieldProps;

const useStyles = makeStyles((theme) => ({
  root: {
    '& .Mui-disabled': {
      '& fieldset': {
        borderColor: `${theme.palette.grey[200]} !important`,
      },
    },
    '& .Mui-error': {
      '& fieldset': {
        borderColor: `${theme.palette.red[200]} !important`,
      },
    },
    '& fieldset': {
      borderRadius: '8px',
    },
    '& input::-webkit-outer-spin-button': {
      '-webkit-appearance': 'none',
      margin: 0,
    },
    '& input::-webkit-inner-spin-button': {
      '-webkit-appearance': 'none',
      margin: 0,
    },
    '& input[type=number]': {
      '-moz-appearance': 'textfield',
    },
    '& input[type=text], input[type=password], input[type=email], input[type=date], input[type], textarea': {
      background: 'inherit',
      padding: 12,
      boxSizing: 'border-box',
      borderRadius: 8,
      minHeight: ({ size }: LightTextFieldProps) => ((size === 'medium' ? '44px' : (size === 'small' ? '16px' : '36px'))),
      minWidth: 0,
      lineHeight: 'inherit',
      fontWeight: 'inherit',
      fontSize: 'inherit',
      '&::placeholder': {
        opacity: 1,
        color: theme.palette.grey[400],
      },
      '&:focus': {
        border: 'inherit',
      },
    },
  },
  input: {
    background: 'white',
    fontSize: '12px',
  },
  inputMultiline: {
    padding: '0 !important',
  },
}));

const LightTextField = (props: LightTextFieldProps) => {
  const classes = useStyles(props);
  return (
    <TextField
      {...props}
      variant={props.variant ?? 'outlined'}
      size={props.size ?? 'small'}
      classes={{ root: classes.root }}
      InputProps={{
        ...props.InputProps,
        classes: {
          ...props.InputProps?.classes,
          root: `${classes.input} ${props.inputProps?.classes?.root ?? ''}`,
          multiline: `${classes.inputMultiline} ${props.inputProps?.classes?.multiline ?? ''}`,
        },
      }}
    />
  );
};

LightTextField.displayName = 'LightTextField';
export { LightTextField };
