import { Typography } from '@material-ui/core';
import * as React from 'react';

interface Props {
  color?:
    | 'initial'
    | 'inherit'
    | 'primary'
    | 'secondary'
    | 'textPrimary'
    | 'textSecondary'
    | 'error';
}

const PasswordGuidelines: React.FC<Props> = ({ color }) => (
  <>
    <Typography variant="body2" color={color}>12-64 characters</Typography>
    <Typography variant="body2" color={color}>1 capital letter</Typography>
    <Typography variant="body2" color={color}>1 special character e.g. _*)(@#!</Typography>
    <Typography variant="body2" color={color}>1 number</Typography>
  </>
);

PasswordGuidelines.displayName = 'PasswordGuidelines';

export default PasswordGuidelines;
