import { Box, createStyles, makeStyles, Typography } from '@material-ui/core';
import { Button } from 'elements';
import LightModal from 'elements/LightModal';
import * as React from 'react';

interface LeavePageModalProps {
  show: boolean;
  setShow: (value: boolean) => void;
  onSave: () => void;
}

const useStyles = makeStyles((theme) => createStyles({
  root: {
    display: 'flex',
    flexDirection: 'column',
    gap: '12px',
    textAlign: 'center',
  },
  title: {
    color: theme.palette.grey[800],
  },
  description: {
    color: theme.palette.grey[700],
    fontWeight: 400,
  },
  button: {
    marginTop: '4px',
  },
}));

const LeavePageModal = (props: LeavePageModalProps) => {
  const classes = useStyles();
  return (
    <LightModal maxWidth={370} setOpen={props.setShow} open={props.show} showCloseButton>
      <Box className={classes.root}>
        <Typography className={classes.title} variant="h5">Save changes?</Typography>
        <Typography className={classes.description} variant="h6">
          You are leaving page without saving changes in the account details. Should we apply changes you did or just leave and cancel them?
        </Typography>
        <Button className={classes.button} size="sm" onClick={() => { props.setShow(false); props.onSave(); }}>Save</Button>
      </Box>
    </LightModal>
  );
};

export default LeavePageModal;
