export enum RebalancingMethod {
  Manual,
  Static,
  Dynamic,
}

export enum Category {
  LargeCap = 'LargeCap',
  MicroCap = 'MicroCap',
  MidCap = 'MidCap',
  SmallCap = 'SmallCap',
}

export enum Status {
  Active = 'active',
}

export interface CoinToMarketCap {
  coin: string;
  usdValue: number;
  category: Category;
}

export interface StDate {
  date: Date;
  performance: number;
}

export interface Performance {
  bundleId: number;
  bundleStateId: number;
  ytdPerf: number;
  bestDate: StDate;
  worstDate: StDate;
  coinToMarketCap: { [key: string]: CoinToMarketCap };
  calculatedAt: Date;
  oneYearAgo?: number;
  twoYearsAgo?: number;
}
