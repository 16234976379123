import { Box, makeStyles } from '@material-ui/core';
import React, { FC } from 'react';

import { HomePageLayoutFooter } from './Footer/HomePageLayoutFooter';
import { HomePageLayoutHeader } from './Header/HomePageLayoutHeader';
import { LearnBanner } from './Header/LearnBanner';

interface HomePageLayoutProps {
  children: React.ReactNode;
  isHomePage?: boolean;
}

const useStyles = makeStyles(() => ({
  root: {
    overflow: 'clip',
    position: 'relative',

    display: 'flex',
    flexDirection: 'column',

    minHeight: '100vh',
    background: 'white',
  },
}));

export const HomePageLayout: FC<HomePageLayoutProps> = ({ children, isHomePage = false }) => {
  const classes = useStyles();
  return (
    <Box className={classes.root}>
      {isHomePage && <LearnBanner />}
      <HomePageLayoutHeader isHomePage={isHomePage} />
      {children}
      <HomePageLayoutFooter />
    </Box>
  );
};
