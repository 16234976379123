import { createStyles, makeStyles, Theme } from '@material-ui/core';
import * as React from 'react';
import SVG from 'react-inlinesvg';
import { WhyCoinbagRoute } from 'routes';

import { individualInvestorsSteps } from '../Authentication/Components/Steps';
import WhyCoinbag from './Components/WhyCoinbag';

const useStyles = makeStyles((theme: Theme) => createStyles({
  root: {
    display: 'flex',
    position: 'relative',
    justifyContent: 'center',
    width: '100%',
    color: theme.palette.grey[700],
    '& em': {
      color: theme.palette.green[600],
      fontStyle: 'normal',
    },
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    width: '100%',
    maxWidth: 1080,
    padding: '48px 16px',
    gap: 120,
    alignItems: 'center',
    [theme.breakpoints.down('sm')]: {
      maxWidth: 640,
      gap: 40,
      paddingTop: 16,
    },
    '&::before': {
      content: '" "',
      width: '1600px',
      height: '1500px',
      top: '-400px',
      left: '40%',
      position: 'absolute',
      backfaceVisibility: 'hidden',
      perspective: 1000,
      transform: 'translateZ(0)',
      background: 'radial-gradient(50% 50% at 50% 50%, rgba(46, 255, 218, 0.315) 0%, rgba(255, 255, 255, 0) 100%)',
      zIndex: 0,
      filter: 'blur(80px)',
      [theme.breakpoints.down('sm')]: {
        width: '1400px',
        height: '600px',
        top: '-301px',
        left: '50%',
        transform: 'translateZ(0) translateX(-50%)',
      },
    },
  },
  icon: {
    position: 'absolute',
    '&#a': {
      top: -24,
      left: '10%',
      [theme.breakpoints.down('sm')]: {
        width: 28,
        height: 28,
        top: -8,
        left: 'unset',
        right: -14,
      },
    },
    '&#b': {
      top: 30,
      left: '53%',
      [theme.breakpoints.down('sm')]: {
        display: 'none',
      },
    },
    '&#c': {
      top: 700,
      left: -34,
      [theme.breakpoints.down('sm')]: {
        top: 240,
        width: 60,
        height: 60,
        left: -30,
      },
    },
    '&#d': {
      top: 1000,
      right: '8%',
      [theme.breakpoints.down('sm')]: {
        top: 400,
        right: -30,
        width: 60,
        height: 60,
      },
    },
  },
}));

const NotCentralized = React.lazy(() => import('./Components/NotCentralized'));
const Steps = React.lazy(() => import('../Authentication/Components/Steps'));
const Flexibility = React.lazy(() => import('./Components/Flexibility'));
const CoinbagFeature = React.lazy(() => import('./Components/CoinbagFeature'));
const HaveMoreQuestion = React.lazy(() => import('./Components/HaveMoreQuestion'));

const WhyCoinbagPage = () => {
  const classes = useStyles();
  return (
    <main className={classes.root}>
      <section itemScope className={classes.content}>
        <SVG width={60} height={60} src="/icons/signup/ic_bg_1.svg" className={classes.icon} id="a" />
        <SVG width={60} height={60} src="/icons/signup/ic_bg_3.svg" className={classes.icon} id="b" />
        <SVG width={90} height={90} src="/icons/signup/ic_bg_4.svg" className={classes.icon} id="c" />
        <SVG width={80} height={80} src="/icons/signup/ic_bg_5.svg" className={classes.icon} id="d" />
        <WhyCoinbag />
        <React.Suspense fallback={<></>}>
          <NotCentralized />
        </React.Suspense>
        <React.Suspense fallback={<></>}>
          <Steps steps={individualInvestorsSteps} />
        </React.Suspense>
        <React.Suspense fallback={<></>}>
          <CoinbagFeature />
        </React.Suspense>
        <React.Suspense fallback={<></>}>
          <Flexibility />
        </React.Suspense>
        <React.Suspense fallback={<></>}>
          <HaveMoreQuestion />
        </React.Suspense>
      </section>
    </main>
  );
};

WhyCoinbagPage.routePath = WhyCoinbagRoute;
export default WhyCoinbagPage;
