import { Box } from '@material-ui/core';
import { WalletRow } from 'components/dashboard/MultiWallets/WalletRow';
import { Typography } from 'elements';
import { LightFrenchFries } from 'elements/LightFrenchFries';
import React from 'react';
import { RemoveTagNameParameter } from 'services/types/workItemParameter';
import { useStore as useUserStore } from 'store/zustand/User';
import { useStore as useWalletStore } from 'store/zustand/Wallet';

const RemoveTagNameDetail = (props: RemoveTagNameParameter) => {
  const [user] = useUserStore((state) => [state.user]);
  const [wallet] = useWalletStore((state) => [state.wallet]);

  const selectedWalletInfo = React.useMemo(
    () => (user?.walletInfo.find((w) => w.walletId === props.tagToRemove.walletId)), [user, wallet],
  );

  return (
    <>
      <Typography variant="h6" paletteColor={700} align="center">
        Remove reporting tag <LightFrenchFries label={props.tagToRemove.tag} autoAssignColor />
      </Typography>
      {selectedWalletInfo && (
        <Box display="flex" alignItems="center" gridGap="12px" justifyContent="center">
          <Typography variant="h4" paletteColor={800} align="center">for wallet:</Typography>
          <WalletRow walletInfo={selectedWalletInfo} />
        </Box>
      )}
    </>
  );
};

export default RemoveTagNameDetail;
