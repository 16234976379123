import { Box, ListSubheader, makeStyles, MenuItem } from '@material-ui/core';
import { useConnectWallet } from '@web3-onboard/react';
import axios from 'axios';
import { Typography } from 'elements';
import { InputWithTitle } from 'elements/InputWithTitle';
import { LightSelect } from 'elements/LightSelect';
import React, { useEffect, useMemo, useState } from 'react';
import SVG from 'react-inlinesvg';
import { EstimatedGasFeeWithdrawToken, FA, FAResponseStatus } from 'services/types/wallet';
import WalletService from 'services/wallet-service';
import { useStore as useChainStore } from 'store/zustand/Chain';
import { useStore as useCoinStore } from 'store/zustand/Coin';
import { useStore as useWalletStore } from 'store/zustand/Wallet';

import { FAModal } from '../FAModal';
import { AddressField } from './Components/AddressField';
import { AmountField } from './Components/AmountField';
import { MemoField } from './Components/MemoField';
import { SelectNetwork } from './Components/SelectNetwork';
import { SelectWalletField } from './Components/SelectWalletField';
import { TokenItem } from './Components/TokenItem';
import { WithdrawWarningBanner } from './Components/WithdrawWarningBanner';
import { GasStatus } from './GasStatus';
import { TransactionCreatedModal } from './TransactionCreatedModal';
import { calculateWithdrawableBalance } from './utils';
import { WithdrawSummary } from './WithdrawSummary';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    gap: 16,
    flexGrow: 1,
  },
  selectCrypto: {
    fontSize: 14,
    lineHeight: '24px',
    fontWeight: 500,
    color: theme.palette.grey[800],
    marginBottom: 4,
  },
  unstake: {
    color: theme.palette.grey[500],
    fontSize: 10,
    lineHeight: '20px',
    fontWeight: 400,
  },
  subHeader: {
    background: '#FAFBFF',
    padding: '10px 16px',
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'column',
    gap: 4,
    '& svg': {
      marginLeft: 4,
    },
  },
  errorText: {
    textAlign: 'right',
    lineHeight: '16px',
    color: theme.palette.red[500],
  },
  placeholder: {
    display: 'flex',
    alignItems: 'center',
    gap: 4,
    fontWeight: 400,
    '& .image': {
      width: 20,
      height: 20,
      '& img': {
        width: 20,
        height: 20,
      },
    },
  },
  lock: {
    marginBottom: 4,
  },
  selectHeader: {
    color: theme.palette.grey[500],
    fontWeight: 400,
    fontSize: 12,
    lineHeight: '20px',
  },
  menuItem: {
    borderTop: `1px solid ${theme.palette.grey[200]}`,
    '&.Mui-selected': {
      backgroundColor: theme.palette.green[100],
      '&:hover': {
        backgroundColor: theme.palette.green[100],
      },
    },
  },
}));

interface WithdrawTokenSectionProps {
  terra2Holding: React.ReactNode;
  loading: boolean;
}

export const WithdrawTokenSection = ({ terra2Holding, loading }: WithdrawTokenSectionProps) => {
  const classes = useStyles();

  const [withdrawCoin, setWithdrawCoin] = React.useState<number | null>(null);
  const [amount, setAmount] = React.useState(0);
  const [percentageAmount, setPercentageAmount] = React.useState(0);
  const [selectedMasterCoin, setSelectedMasterCoin] = React.useState('');
  const [selectedNetwork, setSelectedNetwork] = React.useState('');
  const [address, setAddress] = React.useState('');
  const [addressPatternError, setAddressPatternError] = React.useState(false);
  const [availableAmount, setAvailableAmount] = React.useState(0);
  const [memo, setMemo] = React.useState<string | null>(null);
  const [isNoMemoChecked, setIsNoMemoChecked] = React.useState(false);
  const [isFaModalOpen, setFaModalOpen] = React.useState<boolean>(false);
  const [estimatedGasFee, setEstimatedGasFee] = React.useState<EstimatedGasFeeWithdrawToken | null>(null);
  const [isEstimatedGasFeeDone, setIsEstimatedGasFeeDone] = React.useState<boolean>(false);
  const [isTransactionCreatedModalOpen, setIsTransactionCreatedModalOpen] = useState(false);
  const [maxPerf] = useWalletStore((state) => [state.maxPerf]);
  const [activeWalletBalance, activeWalletInfo] = useWalletStore((state) => [
    state.activeWalletBalance, state.activeWalletInfo,
  ]);
  const [chains] = useChainStore((state) => [state.chains]);
  const [masterCoinsFromName, coins] = useCoinStore((state) => [
    state.masterCoinsFromName,
    state.coins,
  ]);
  const [{ wallet }] = useConnectWallet();

  const withdrawableCoins = useMemo(() => (calculateWithdrawableBalance(
    masterCoinsFromName, coins, activeWalletBalance, maxPerf,
  )), [maxPerf, masterCoinsFromName, activeWalletBalance]);

  const handleWithdrawButton = async () => {
    if (isWithdrawable) {
      if (activeWalletInfo?.walletProvider === 'Reporting') {
        setIsTransactionCreatedModalOpen(true);
        try {
          await handleOnSubmit();
        } finally {
          // Ignore finally
        }
      } else {
        setFaModalOpen(true);
      }
    }
  };

  const gasStatus = React.useMemo(() => {
    if (amount && !addressPatternError && address && selectedNetwork) {
      const withdrawChain = chains?.find((c) => c.chainId === selectedNetwork);
      if (!withdrawChain) return GasStatus.FAILED;

      const usdtValue = activeWalletBalance?.filter((x) => x.coinId === withdrawChain.nativeCoin.coinId).reduce((accumulator, currentValue) => accumulator += currentValue.usdtValue, 0);
    
      const nativeCoinBalance = activeWalletBalance?.find((x) => x.coinId === withdrawChain.nativeCoin.coinId);
      if(nativeCoinBalance !== undefined){
        nativeCoinBalance.usdtValue = (usdtValue || 0)
      }

      if (!nativeCoinBalance || nativeCoinBalance.usdtValue <= 0) return GasStatus.INSUFFICIENT;

      if (!isEstimatedGasFeeDone) return GasStatus.LOADING;
      if (!estimatedGasFee) return GasStatus.FAILED;

      const estimatedGasFeeToCompare = estimatedGasFee.usdtAmount > 0 ? estimatedGasFee.usdtAmount : 2;

      return (!nativeCoinBalance || nativeCoinBalance.usdtValue < estimatedGasFeeToCompare) ?
        GasStatus.INSUFFICIENT : GasStatus.SUFFICIENT;
    }
    return GasStatus.INIT;
  }, [amount, address, selectedNetwork, estimatedGasFee, isEstimatedGasFeeDone]);

  const isWithdrawable = React.useMemo((): boolean => {
    const haveAmount: boolean = amount != null && +amount > 0;
    const havePercentage: boolean = percentageAmount != null && +percentageAmount > 0;
    const filledAddress: boolean = address !== '' && !addressPatternError;
    const haveMemo: boolean = ((selectedNetwork !== 'terra-mainnet')
      || (!isNoMemoChecked && (memo != null && memo !== ''))
      || isNoMemoChecked);

    return haveAmount && havePercentage && filledAddress && haveMemo
      && isEstimatedGasFeeDone && gasStatus === GasStatus.SUFFICIENT;
  }, [amount, percentageAmount, address, selectedNetwork, isNoMemoChecked, memo, addressPatternError,
    isEstimatedGasFeeDone, gasStatus]);

  const amountUsdt = React.useMemo(() => {
    const coin = withdrawableCoins.available.find((c) => c.id === withdrawCoin);
    if (coin && coin.amount && coin.value) return (amount / coin.amount) * coin.value;
    return undefined;
  }, [amount, withdrawCoin]);

  useEffect(() => {
    const cancelTokenSource = axios.CancelToken.source();
    const fetchData = async () => {
      setIsEstimatedGasFeeDone(false);
      try {
        if (amount && address && !addressPatternError && selectedNetwork && withdrawCoin && address) {
          const withdrawChain = chains?.find((c) => c.chainId === selectedNetwork);
          const nativeCoinBalance = activeWalletBalance?.find((x) => x.coinId === withdrawChain?.nativeCoin.coinId);

          if ((nativeCoinBalance?.usdtValue || 0) <= 0) {
            setEstimatedGasFee(null);
            setIsEstimatedGasFeeDone(true);
          } else {
            const gasFee = await WalletService.estimateWithdrawToken(
              amount, withdrawCoin, address, cancelTokenSource.token
            );
            setEstimatedGasFee(gasFee);
            setIsEstimatedGasFeeDone(true);
          }
        } else {
          setEstimatedGasFee(null);
          setIsEstimatedGasFeeDone(false);
        }
        // eslint-disable-next-line no-empty
      } catch (error) {
        if (axios.isCancel(error)) return;
        setIsEstimatedGasFeeDone(true);
        setEstimatedGasFee(null);
      }
    };

    fetchData();

    return () => {
      cancelTokenSource.cancel();
    };
  }, [amount, percentageAmount, address, selectedNetwork]);

  const Placeholder = React.useCallback(() => (
    <Box className={classes.placeholder}>
      <Box className="image">
        <img src="icons/withdraw/ic_coin_placeholder.svg" alt="Placeholder" />
      </Box>
      <Typography variant="h6" className={classes.selectHeader}>
        {!loading && withdrawableCoins.available.length <= 0 ? 'No crypto available' : 'Find crypto'}
      </Typography>
    </Box>
  ), [withdrawableCoins, loading]);

  const faModalDetail = useMemo(() => (
    <Typography variant="h6" paletteColor={700} align="center">
      This process will take few minutes. It will transfer{' '}
      <Typography component="span" variant="h6" palette="green" paletteColor={500} noWrap>
        {amount ? +amount : 0} {selectedMasterCoin}
      </Typography>
      {' '}to your address.
    </Typography>
  ), [amount, selectedMasterCoin]);

  const handleWithdrawalToken = async (fa?: FA): Promise<FAResponseStatus> => {
    const res = await WalletService.withdrawToken( wallet,amount ? +amount : 0, address, withdrawCoin ?? 0, fa);
    return res as FAResponseStatus;
  };
  const handleOnSubmitWithFA = async (fa: FA): Promise<FAResponseStatus> => {
    return await handleWithdrawalToken(fa);
  }
  const handleOnSubmit = async () => {
    await handleWithdrawalToken();
  }

  return (
    <>
      <Box className={classes.root}>
        <SelectWalletField />
        <InputWithTitle title="Selected crypto">
          <LightSelect
            disabled={!loading && withdrawableCoins.available.length <= 0}
            value={withdrawCoin ?? 'placeholder'}
            renderValue={() => (withdrawCoin && withdrawableCoins.available.find((c) => c.id === withdrawCoin) ? (
              <Box marginTop={-2} marginBottom={-2} width="100%">
                <TokenItem
                  isSvg={false}
                  {...withdrawableCoins.available.find((c) => c.id === withdrawCoin)!}
                />
              </Box>
            ) : <Placeholder />)}
          >
            <MenuItem value="placeholder" style={{ display: 'none' }} />
            {withdrawableCoins.available.length > 0 && (
              <ListSubheader className={classes.subHeader}>
                <Typography variant="h6" palette="grey" paletteColor={700}>Available to withdraw</Typography>
              </ListSubheader>
            )}
            {withdrawableCoins.available.map((coin) => (
              <MenuItem
                value={coin.id}
                key={coin.id}
                className={classes.menuItem}
                onClick={() => {
                  setWithdrawCoin(coin.id);
                  setSelectedNetwork(coin.networkIds[0] ?? '');
                  setAvailableAmount(coin.amount ?? 0);
                  setSelectedMasterCoin(coin.masterCoinId ?? '');
                }}
              >
                <TokenItem {...coin} isSvg />
              </MenuItem>
            ))}
            {withdrawableCoins.locked.length > 0 && (
              <ListSubheader
                className={`${classes.subHeader} ${classes.menuItem}`}
              >
                <Typography variant="h6" palette="grey" paletteColor={500}>
                  <Typography variant="h6" palette="grey" paletteColor={700} component="span">
                    Not available |
                  </Typography> Currently earning interest
                  <SVG src="/icons/earning/ic_lock.svg" width={16} height={16} className={classes.lock} />
                </Typography>
                <Typography className={classes.unstake}>
                  Manage your staked and lent assets on the Interest earning page to make available for withdrawal.
                </Typography>
              </ListSubheader>
            )}
            {withdrawableCoins.locked.map((coin) => (
              <MenuItem
                value={coin.name}
                key={coin.name}
                className={classes.menuItem}
                disabled
              >
                <TokenItem {...coin} isSvg />
              </MenuItem>
            ))}
          </LightSelect>
        </InputWithTitle>
        <InputWithTitle title="Network">
          <SelectNetwork
            selectableNetworks={withdrawCoin ? [[withdrawCoin, selectedNetwork]] : []}
            selectedNetwork={selectedNetwork}
            setSelectedNetwork={setSelectedNetwork}
            placeholder="Please select crypto"
            disable
          />
        </InputWithTitle>
        {selectedNetwork && (
          <>
            <InputWithTitle title="Amount to Withdraw">
              <AmountField
                unit={selectedMasterCoin}
                availableAmount={availableAmount}
                value={amount}
                percent={percentageAmount}
                onPercentChanged={setPercentageAmount}
                onValueChanged={setAmount}
                disabled={!withdrawCoin}
              />
            </InputWithTitle>
            <InputWithTitle
              title="Receiving address"
              titleRightItem={
                addressPatternError && (
                  <Typography variant="h6" className={classes.errorText}>
                    Wallet address is not valid
                  </Typography>
                )
              }
            >
              <AddressField
                address={address}
                disabled={!selectedNetwork}
                setAddress={setAddress}
                selectedNetwork={selectedNetwork}
                setAddressPatternError={setAddressPatternError}
              />
            </InputWithTitle>
            {selectedMasterCoin && selectedNetwork && (
              <WithdrawWarningBanner
                selectedMasterCoin={selectedMasterCoin}
                selectedNetwork={selectedNetwork}
              />
            )}
            {(selectedNetwork === 'terra-mainnet') && (
              <MemoField
                memo={memo}
                setMemo={setMemo}
                isNoMemoChecked={isNoMemoChecked}
                setIsNoMemoChecked={setIsNoMemoChecked}
              />
            )}
          </>
        )}

        {terra2Holding}
      </Box>
      <WithdrawSummary
        isTokenAmount
        amount={amount}
        amountUsdt={amountUsdt}
        handleWithdrawButton={handleWithdrawButton}
        isWithdrawFullAmount={false}
        isWithdrawable={isWithdrawable}
        withdrawCoinId={withdrawCoin}
        selectedMasterCoin={selectedMasterCoin ? masterCoinsFromName[selectedMasterCoin] : undefined}
        withdrawChain={chains?.find((c) => c.chainId === selectedNetwork)}
        gasStatus={gasStatus}
        estimatedGasFee={estimatedGasFee || undefined}
        showEstimatedGas
      />
      <TransactionCreatedModal
        open={isTransactionCreatedModalOpen}
        setOpen={setIsTransactionCreatedModalOpen}
        action="withdrawal"
      />
      <FAModal
        title="Confirm Withdrawing"
        detail={faModalDetail}
        onSubmit={handleOnSubmitWithFA}
        open={isFaModalOpen}
        setOpen={setFaModalOpen}
        emailParams={{
          name: 'Withdrawal',
          symbol: selectedMasterCoin,
          amount: `${amount ? +amount : 0}`,
          address,
        }}
      />
    </>
  );
};
