import { chainIdMapper } from 'components/DepositAndWithdraw/mappers';
import { BundleV2 } from 'services/types/bundle';
import { MasterCoin } from 'services/types/coin';
import { Protocol } from 'services/types/protocol';

import { CoinEarningItemProps } from './CoinEarningItem';
import { CoinEarningRowProps } from './CoinEarningRow';

const ProtocolChainIdsOrder = ['1', '56', '137'];

export const mapToProps = (masterCoins: Record<string, MasterCoin>, bundle: BundleV2, protocols: Protocol[]): CoinEarningRowProps[] => {
  return bundle.bundleMasterCoins.map((coin) => {
    const supportProtocols = protocols.filter((p) => p.coins.includes(coin.id));
    const protocolItemProps: CoinEarningItemProps[] = supportProtocols.map((p) => ({
      protocolId: p.protocolId,
      coinId: coin.id,
      name: p.name,
      icon: p.logo,
      currentApy: p.currentApy.find((apy) => apy.masterCoinId === coin.id)?.apy ?? 0,
      avgApy: p.monthAvgApy.find((apy) => apy.masterCoinId === coin.id)?.apy ?? 0,
      chain: chainIdMapper[p.chainId][0] || '',
      chainId: p.chainId,
    })).sort((a, b) => ProtocolChainIdsOrder.indexOf(a.chainId) - ProtocolChainIdsOrder.indexOf(b.chainId));
    return {
      icon: masterCoins[coin.id]?.icon,
      name: coin.name,
      coinId: coin.id,
      items: [
        {
          name: 'Don’t earn interest',
          icon: '/icons/earning/ic_no_interest.svg',
          currentApy: 0,
          avgApy: 0,
          coinId: coin.id,
          protocolId: -1,
        },
        // todo add on BE
        // {
        //   name: 'Native staking',
        //   icon: '/icons/earning/ic_staking.svg',
        //   currentApy: 0.184,
        //   avgApy: 0.184,
        //   lockPeriod: 7,
        //   coinId: coin.id,
        //   protocolId: 999,
        // },
        ...protocolItemProps,
      ],
    };
  });
};

export const mapSelectedProps = (masterCoins: Record<string, MasterCoin>, bundle: BundleV2, protocols: Protocol[], selectedCoinConfig: Record<number, number>): CoinEarningRowProps[] => {
  const allConfig = mapToProps(masterCoins, bundle, protocols);
  const coinIds = Object.keys(selectedCoinConfig);
  const configs = coinIds.map((coinId) => {
    const config = allConfig.find((x) => x.coinId === +coinId);
    if (config) {
      config.items = config?.items.filter((protocol) => protocol.protocolId === selectedCoinConfig[+coinId]);
      return config;
    }
    return undefined;
  });

  return configs.filter((item) => item !== undefined) as CoinEarningRowProps[];
};
