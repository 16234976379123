// @flow
import { Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import * as React from 'react';

import { Checkbox } from './Checkbox';

interface Props {
  isChecked: boolean;
  setIsChecked: (value: any) => void;
  children: React.ReactNode
}

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    gap: '8px',

    textAlign: 'left',
    cursor: 'pointer',
  },
  checkboxWrapper: {
    flexShrink: 0,
    marginTop: '-2px',
  },
  checkbox: {
    alignItems: 'flex-start',
  },
}));

export const CheckBoxWithText = (props: Props) => {
  const classes = useStyles();
  return (
    <Box className={classes.root} onClick={() => { props.setIsChecked((prev: boolean) => !prev); }}>
      <Box className={classes.checkboxWrapper}>
        <Checkbox className={classes.checkbox} checked={props.isChecked} />
      </Box>
      { props.children }
    </Box>
  );
};
