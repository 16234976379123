// @flow
import { Box, createStyles, Hidden } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { AxiosError } from 'axios';
import { Loading } from 'components';
import { Button, LightTextField, Typography } from 'elements';
import React, { useState } from 'react';
import SVG from 'react-inlinesvg';
import Turnstile from 'react-turnstile';
import { createTmpUser } from 'services';
import { JwtUser } from 'services/types/jwt';
import WalletService from 'services/wallet-service';

import { Background } from '../../WalletReporting/Background';
import { EVM_ADDRESS_FORMAT } from '../utils/ReportingUtils';

interface Props {
  callbackFnc: () => Promise<void>
}

const useStyles = makeStyles((theme) => createStyles({
  root: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: 24,
    '& > *:not(svg)': {
      zIndex: 4,
    },
    '& > svg': {
      zIndex: 3,
    },
    textAlign: 'center',
    padding: '72px 0 86px 0',
    [theme.breakpoints.down('sm')]: {
      padding: '20px 16px 86px 16px',
    },
    '&::before': {
      content: '" "',
      width: '1800px',
      height: '1300px',
      top: '-701px',
      position: 'absolute',
      background: 'radial-gradient(50% 50% at 50% 50%, rgba(46, 255, 218, 0.315) 0%, rgba(255, 255, 255, 0) 100%)',
      zIndex: 1,
      filter: 'blur(80px)',
      backfaceVisibility: 'hidden',
      perspective: 1000,
      left: '50%',
      transform: 'translate(-50%, 0) translateZ(0)',
      [theme.breakpoints.down('sm')]: {
        width: '1400px',
        height: '600px',
        top: '-301px',
      },
    },
  },
  header: {
    fontSize: 40,
    fontWeight: 700,
    lineHeight: '80px',
    [theme.breakpoints.down('sm')]: {
      fontSize: 20,
      lineHeight: '44px',
    },
  },
  green: {
    color: theme.palette.green[600],
  },
  blue: {
    color: theme.palette.blue[600],
  },
  purple: {
    color: theme.palette.purple[600],
  },
  lightPurple: {
    color: theme.palette.purple[500],
  },
  white: {
    color: 'white',
  },
  subtitle: {
    [theme.breakpoints.up('md')]: {
      maxWidth: 528,
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: 14,
    },
  },
  textFieldWrapper: {
    width: '100%',
    maxWidth: 628,
    marginTop: 4,
  },
  textField: {
    width: '100%',
  },
  textFieldInputProps: {
    paddingRight: 8,
    paddingTop: 8,
    paddingBottom: 8,
  },
  becomePro: {
    backgroundColor: theme.palette.purple[500],
    display: 'flex',
    gap: 8,
    padding: '6px 32px',
    borderRadius: 6,
    width: 'fit-content',
  },
  crownIconWrapper: {
    width: 20,
    height: 20,
  },
  downloadSectionWrapper: {
    display: 'flex',
    flexDirection: 'column',
    gap: 16,
    alignItems: 'center',
    marginTop: 8,
    [theme.breakpoints.down('sm')]: {
      gap: 8,
    },
  },
  downloadDescription: {
    fontSize: 16,
    fontWeight: 500,
    [theme.breakpoints.up('md')]: {
      maxWidth: 528,
    },
    [theme.breakpoints.down('sm')]: {
      maxWidth: 328,
      fontSize: 14,
    },
  },
  generateReportButton: {
    width: 220,
    borderRadius: 8,
    height: 44,
    padding: '8px 12px',
    [theme.breakpoints.down('sm')]: {
      height: 24,
      width: 172,
    },
  },
  buttonText: {
    color: 'white',
    fontSize: 16,
    whiteSpace: 'nowrap',
    [theme.breakpoints.down('sm')]: {
      fontSize: 12,
    },
  },
  loading: {
    width: '100%',
    position: 'absolute',
    top: 0,
    left: 0,
    zIndex: 2,
    background: 'white',
  },
}));

export const LandingPage = ({ callbackFnc }: Props) => {
  const classes = useStyles();
  const [cloudFlareToken, setCloudFlareToken] = useState<string | undefined>(undefined);
  const [enableButton, setEnableButton] = useState<boolean>(false);
  const [address, setAddress] = useState<string>('');
  const [isInputError, setIsInputError] = useState<boolean>(false);
  const [invalidAddress, setInvalidAddress] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const handleOnSubmit = async () => {
    if (address.match(EVM_ADDRESS_FORMAT)) {
      setIsLoading(true);
      setInvalidAddress(false);
      const userData = localStorage.getItem('user');
      const user = userData ? JSON.parse(userData) as JwtUser : undefined;
      try {
        if (!user) await createTmpUser(cloudFlareToken ?? '', address);
        else await WalletService.createTmpUserWallet([address], cloudFlareToken ?? '');
        callbackFnc();
      } catch (e) {
        const err = e as AxiosError;
        if (err?.response?.status === 406) setInvalidAddress(true);
      }
      setIsLoading(false);
    } else setIsInputError(true);
  };

  if (isLoading) {
    return <Loading className={classes.loading} />;
  }

  return (
    <section className={classes.root}>
      <Background />
      <Typography variant="h1" palette="grey" paletteColor={700} className={classes.header}>
        <span className={classes.green}>Tag</span>, <span className={classes.blue}>Track</span> & <span className={classes.purple}>Filter</span> <Hidden mdUp><br /></Hidden> All of Your Crypto
        <Hidden smDown><br /></Hidden> Transactions in <span className={classes.green}>One Place.</span>
      </Typography>
      <Typography variant="h3" palette="grey" paletteColor={700} className={classes.subtitle}>
        The only tool you need to automate your cryptocurrency reporting & reconciliation.
      </Typography>
      <Box className={classes.textFieldWrapper}>
        <LightTextField
          className={classes.textField}
          placeholder="Enter wallet address"
          value={address}
          onChange={(e) => { setIsInputError(false); setAddress(e.target.value); }}
          error={isInputError}
          InputProps={{
            className: classes.textFieldInputProps,
            endAdornment: (
              <Button
                className={classes.generateReportButton}
                disabled={!enableButton}
                onClick={handleOnSubmit}
              >
                <Typography variant="h5" className={classes.buttonText}>Generate report</Typography>
              </Button>),
          }}
        />
        {invalidAddress && (
          <Typography
            palette="red"
            paletteColor={500}
            variant="h6"
          >
            This address has more than 5000 transactions. Please go to <a href="https://coinbag.finance">coinbag website</a>
          </Typography>
        )}
      </Box>
      <Box className={classes.downloadSectionWrapper}>
        <Typography
          variant="h4"
          palette="grey"
          paletteColor={700}
          className={classes.downloadDescription}
        >Download your first report, complete free!<br /> Become <span className={classes.lightPurple}>Pro</span> to download more.
        </Typography>
        <a href="https://coinbag.finance/register" className={classes.becomePro}>
          <Typography variant="h5" className={classes.white}>Become Pro</Typography>
          <Box className={classes.crownIconWrapper}>
            <SVG src="icons/wallet_reporting/ic_crown.svg" width={20} height={20} />
          </Box>
        </a>
      </Box>
      <Turnstile
        sitekey="0x4AAAAAAADm0aD1aDjFfif4"
        theme="light"
        appearance="interaction-only"
        onVerify={(token) => {
          setCloudFlareToken(token);
          setEnableButton(true);
        }}
      />
    </section>
  );
};
