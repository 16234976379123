import {
  Box,
  createStyles,
  IconButton,
  Input,
  makeStyles,
  Slider,
  Typography,
  ValueLabelProps,
  withStyles,
} from '@material-ui/core';
import { AutocompleteRenderInputParams, AutocompleteRenderOptionState } from '@material-ui/lab';
import { LightAutocomplete } from 'elements/LightAutocomplete';
import { LightTextField } from 'elements/LightTextField';
import { LightTooltip } from 'elements/LightTooltip';
import * as React from 'react';
import SVG from 'react-inlinesvg';

import { BundleCoin } from '../Bundle/BundleCard';

const useStyles = makeStyles((theme) => createStyles({
  noToken: {
    display: 'flex',
    flexGrow: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
  coin1: {
    position: 'absolute',
    right: 0,
    top: '-20px',
    pointerEvents: 'none',
  },
  coin2: {
    position: 'absolute',
    left: 0,
    bottom: '-15px',
    pointerEvents: 'none',
  },
  coin3: {
    position: 'absolute',
    bottom: '-30px',
    right: '40%',
    pointerEvents: 'none',
  },
  tokenTable: {
    paddingTop: '16px',
    display: 'grid',
    gridTemplateColumns: '32px 40px 1fr 45px 20px',
    alignItems: 'center',
    justifyContent: 'center',
    justifyItems: 'start',
    alignContent: 'center',
    columnGap: '8px',
    rowGap: '16px',
    [theme.breakpoints.down('sm')]: {
      rowGap: '8px',
    },
  },
  noTokenText: {
    color: '#909CBD',
  },
  tokenRowTitle: {
    display: 'flex',
    flexWrap: 'nowrap',
    alignItems: 'center',
  },
  tokenRowName: {
    fontSize: '14px',
    fontWeight: 500,
    color: '#38425E',
    lineHeight: '24px',
  },
  tokenRowSlider: {
    padding: '0 15px 0 5px',
    width: '100%',
  },
  tokenRowRemoveButton: {
    width: '20px',
    height: '20px',
  },
  tokenIcon: {
    boxShadow: '0px 2px 4px rgba(144, 156, 189, 0.2)',
    border: '1px solid #FFFFFF',
    borderRadius: '30px',
  },
  tokenInput: {
    fontSize: '12px',
    background: 'white',
    paddingTop: '0',
    paddingBottom: '0',
    borderRadius: '8px',
    borderColor: theme.palette.grey['200'],
  },
  textField: {
    borderRadius: '8px',
    fontSize: '12px',
    border: '1px solid #DEE3EF',
    textAlign: 'center',
    '&::before': {
      display: 'none',
    },
    '&::after': {
      display: 'none',
    },
  },
  tokensBox: {
    position: 'relative',
    minHeight: '320px',
    overflow: 'hidden',
    padding: 16,
    display: 'flex',
    flexDirection: 'column',
  },
  searchBoxTitleWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingBottom: '0.3em',
  },
  searchBoxTitle: {
    fontSize: '12px',
    fontWeight: 400,
    lineHeight: '16px',
    color: '#38425E',
  },
  searchBoxAvailable: {
    fontSize: '12px',
    lineHeight: '16px',
    fontWeight: 400,
    color: '#BBC4DD',
  },
  green: {
    color: '#37E2C3',
  },
  orange: {
    color: '#FFC107',
  },
  tokenOption: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
  },
  tokenOptionName: {
    fontSize: '16px',
    lineHeight: '24px',
    fontWeight: 500,
    color: '#38425E',
    padding: '0 8px',
  },
  textFieldInput: {
    textAlign: 'center',
  },
  checkBox: {
    width: '16px',
    height: '16px',
    borderRadius: '2px',
    border: '1px solid #37E2C3',
    overflow: 'hidden',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  checked: {
    background: '#37E2C3',
  },
  checkedIcon: {
    width: '10px',
    height: '10px',
  },
}));

const StyledSlider = withStyles({
  root: {
    height: '12px',
    padding: '10px 0',
  },
  rail: {
    height: '12px',
    background: '#F7F8FD',
    borderRadius: '6px',
  },
  track: {
    height: '12px',
    background: '#06D2AE',
    borderRadius: '6px',
  },
  thumb: {
    width: '24px',
    height: '24px',
    boxShadow: '0px 4px 8px rgba(123, 126, 156, 0.24)',
    background: 'white',
    '&:hover': {
      boxShadow: '0px 4px 8px rgba(123, 126, 156, 0.24)',
    },
    '&.Mui-focusVisible': {
      boxShadow: '0px 4px 8px rgba(123, 126, 156, 0.24)',
    },
  },
})(Slider);

const SliderBox = withStyles({
  root: {
    '@media (hover: none)': {
      pointerEvents: 'none',
    },
  },
})(Box);

export interface TokenSelectorProps {
  availableTokens: BundleCoin[];
  selectedTokens: BundleCoin[];
  // eslint-disable-next-line no-unused-vars
  setSelectedTokens: (tokens: BundleCoin[]) => void;
  disable?: boolean;
}

const TokenSelector = ({ availableTokens, selectedTokens, setSelectedTokens, disable }: TokenSelectorProps) => {
  const classes = useStyles();

  const [textFieldValues, setTextFieldValues] = React.useState<Record<string, string>>({});
  const tokenLeft = React.useMemo(() => availableTokens.length - selectedTokens.length,
    [selectedTokens.length, availableTokens.length]);
  const totalSelectedPercentage = React.useMemo(
    () => selectedTokens.reduce((total, token) => total + token.percentage, 0),
    [selectedTokens],
  );
  React.useEffect(() => {
    const fieldValues = {} as Record<string, string>;
    selectedTokens.forEach((token) => {
      fieldValues[token.name] = token.percentage.toString();
    });
    setTextFieldValues(fieldValues);
  }, [selectedTokens]);

  const getPercentage = (name: string) => selectedTokens?.find((token) => token.name === name)?.percentage;
  const setPercentage = (name: string, percentage: number) => {
    const newTokens = [...selectedTokens];
    const selected = newTokens.find((token) => token.name === name);
    if (selected) {
      const totalSelected = selectedTokens.reduce(
        (total, token) => (token.name !== name ? total + token.percentage : total), 0,
      );
      const maxPercentage = Number((100 - totalSelected).toFixed(1));
      selected.percentage = (percentage < 5 || maxPercentage < 5)
        ? 0 : (percentage > maxPercentage ? maxPercentage : percentage);
      setSelectedTokens(newTokens);
    }
  };

  const labelTooltipText = React.useMemo(() => (
    totalSelectedPercentage >= 100
      ? 'You’ve already reached 100%. Please decrease the percentage of other tokens to increase the percentage for this token.'
      : ''
  ), [totalSelectedPercentage]);

  const valueLabel = React.useCallback((labelProps: ValueLabelProps) => (
    <SliderBox>
      <LightTooltip
        arrow
        disableFocusListener
        disableTouchListener
        disableHoverListener
        open={!disable && labelProps.open}
        title={labelTooltipText}
      >
        {labelProps.children}
      </LightTooltip>
    </SliderBox>
  ), [disable, labelTooltipText]);

  const removeToken = (name: string) => {
    const index = (selectedTokens).findIndex((token) => token.name === name);
    if (index >= 0) {
      selectedTokens[index].percentage = 0;
      selectedTokens.splice(index, 1);
      setSelectedTokens([...selectedTokens]);
    }
  };

  const onTextFieldChanged = (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>, name: string) => {
    const amount = e.target.value;

    if (!amount || amount.match(/^\d{1,}(\.\d{0,4})?$/)) {
      setTextFieldValues({ ...textFieldValues, [name]: amount });
    }
  };

  const tokenRow = React.useCallback((name: string, icon: string) => (
    <React.Fragment key={name}>
      <Box className={classes.tokenIcon}>
        <SVG key={name} src={icon} width={28} height={28} />
      </Box>
      <Typography className={classes.tokenRowName}>{name}</Typography>
      <Box className={classes.tokenRowSlider}>
        <StyledSlider
          step={0.1}
          defaultValue={0}
          value={getPercentage(name)}
          valueLabelDisplay="auto"
          ValueLabelComponent={valueLabel}
          onChange={(_, value) => setPercentage(name, Math.round(Number(value) * 10) / 10)}
        />
      </Box>
      <Input
        value={textFieldValues[name] || 0}
        onChange={(event) => onTextFieldChanged(event, name)}
        onBlur={(event) => setPercentage(name, Math.round(Number(event.target.value) * 10) / 10)}
        classes={{ root: classes.textField, input: classes.textFieldInput }}
        type="text"
        inputProps={{ inputMode: 'numeric' }}
      />
      <IconButton onClick={() => removeToken(name)} size="small">
        <img className={classes.tokenRowRemoveButton} src="/icons/bundle/cross.svg" alt="remove" />
      </IconButton>
    </React.Fragment>
  ), [totalSelectedPercentage, textFieldValues, selectedTokens]);

  return (
    <Box className={classes.tokensBox}>
      <Box className={classes.searchBoxTitleWrapper}>
        <Typography className={classes.searchBoxTitle}>Search and add Tokens:</Typography>
        <Typography className={classes.searchBoxAvailable}>
          Available: <span className={tokenLeft > 0 ? classes.green : classes.orange}>{tokenLeft} tokens</span>
        </Typography>
      </Box>
      <LightAutocomplete
        multiple
        disableCloseOnSelect
        disableClearable
        blurOnSelect={false}
        limitTags={0}
        id="token-checkbox"
        value={selectedTokens}
        options={availableTokens}
        renderTags={() => <></>}
        loading={availableTokens.length === 0}
        getOptionLabel={(option) => option.name}
        style={{ background: 'white', zIndex: 1 }}
        onChange={(event: any, newValue: BundleCoin[]) => {
          setSelectedTokens(newValue);
        }}
        renderInput={(params: AutocompleteRenderInputParams) => (
          <LightTextField
            {...params}
            variant="outlined"
            fullWidth
            placeholder="Token Name"
            InputProps={{
              ...params.InputProps,
              classes: { root: classes.tokenInput },
              startAdornment: <img alt="coin" src="/icons/custom_portfolio/ic_coin.svg" />,
            }}
          />
        )}
        renderOption={(option: BundleCoin, { selected }: AutocompleteRenderOptionState) => (
          <Box className={classes.tokenOption}>
            <Box className={classes.tokenRowTitle}>
              <SVG key={option.icon} src={option.icon} width={28} height={28} />
              <Typography className={classes.tokenOptionName}>{option.name}</Typography>
            </Box>
            <Box className={`${classes.checkBox} ${selected && classes.checked}`}>
              {selected && <img alt="check" src="/icons/bundle/check.svg" className={classes.checkedIcon} />}
            </Box>
          </Box>
        )}
      />
      {!selectedTokens?.length && (
        <Box className={classes.noToken}>
          <Box>
            <Typography className={classes.noTokenText} variant="h6">No tokens added</Typography>
          </Box>
          <img className={classes.coin1} src="/icons/bundle/coin_1.svg" alt="bg" />
          <img className={classes.coin2} src="/icons/bundle/coin_2.svg" alt="bg" />
          <img className={classes.coin3} src="/icons/bundle/coin_3.svg" alt="bg" />
        </Box>
      )}
      <Box className={classes.tokenTable}>
        {selectedTokens?.map((token) => tokenRow(token.name, token.icon))}
      </Box>
    </Box>
  );
};

export default TokenSelector;
