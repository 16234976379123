import 'react-phone-input-2/lib/material.css';

import { Box, createStyles, InputAdornment, Link, makeStyles } from '@material-ui/core';
import { AutocompleteRenderInputParams } from '@material-ui/lab';
import { InfoBanner } from 'elements/InfoBanner';
import { InputWithTitle } from 'elements/InputWithTitle';
import { LightAutocomplete } from 'elements/LightAutocomplete';
import { LightTextField } from 'elements/LightTextField';
import { Typography } from 'elements/Typography';
import { isValidPhoneNumber } from 'libphonenumber-js';
import React, { useEffect, useMemo, useState } from 'react';
import SVG from 'react-inlinesvg';
import PhoneInput from 'react-phone-input-2';
import authService, { CountryListItem } from 'services/auth-service';
import { useStore as useAccountSettingStore } from 'store/zustand/AccountSetting';
import { useStore as useUserStore } from 'store/zustand/User';
import { useStore as useWalletStore } from 'store/zustand/Wallet';
import { breakpoints } from 'theme/base';

import { UserType } from '../../../models';
import ChangePasswordModal from './elements/ChangePasswordModal';
import { TwoFaButton } from './elements/TwoFaButton';
import { dateIsValid, isValidDate, isValidPhone, isValidString } from './InputValidator';
import { RevokeAccessButton } from './RevokeAccessButton';

const useStyles = makeStyles((theme) => createStyles({
  root: {
    display: 'flex',
    flexDirection: 'column',
    gap: '16px',
  },
  textFieldGroup: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    gap: '16px',
    [breakpoints.down('sm')]: {
      gridTemplateColumns: '1fr',
    },
  },
  changePassword: {
    color: theme.palette.green[600],
    cursor: 'pointer',
  },
  phoneInput: {
    '&.form-control': {
      width: '100%',
      fontSize: '12px',
      borderRadius: '8px',
      padding: '16.625px 14px 16.625px 58px',
      border: `1px solid ${theme.palette.grey[200]}`,
      color: theme.palette.grey[600],
      '&:focus': {
        boxShadow: 'none',
        borderColor: theme.palette.green[500],
      },
      '&:hover': {
        borderColor: theme.palette.green[500],
      },
      '&:active': {
        borderColor: theme.palette.green[500],
      },
      '&.invalid-number': {
        border: `1px solid ${theme.palette.grey[200]}`,
        '&:focus': {
          border: `1px solid ${theme.palette.red[200]}`,
          boxShadow: 'none',
        },
      },
    },
  },
  phoneInputError: {
    '&.form-control': {
      '&.invalid-number': {
        border: `1px solid ${theme.palette.red[200]}`,
        '&:focus': {
          border: `1px solid ${theme.palette.red[200]}`,
          boxShadow: 'none',
        },
      },
    },
  },
  paddingTop: {
    paddingTop: '8px',
  },
  warningBanner: {
    backgroundColor: theme.palette.yellow[100],
    color: theme.palette.yellow[600],
  },
  learnMore: {
    textDecoration: 'underline',
    marginTop: '8px',
  },
  approvedEmail: {
    display: 'flex',
    flexWrap: 'nowrap',
    alignItems: 'center',
    gap: '4px',
    '& svg': {
      width: '16px',
      height: '16px',
      '& circle': {
        fill: theme.palette.green[400],
      },
    },
  },
}));

export interface AccountDetailsRightPanelProps {
  showInputError: boolean;
}

const AccountDetailsRightPanel = ({ showInputError }: AccountDetailsRightPanelProps) => {
  const classes = useStyles();

  const [user] = useUserStore((state) => [state.user]);

  const [walletInfos, fetchWallet] = useWalletStore((s) => [s.walletInfos, s.fetchWallet]);
  const hasCybavo = useMemo(
    () => walletInfos?.some((w) => w.walletProvider.toLowerCase() === 'cybavo') ?? true,
    [walletInfos],
  );

  useEffect(() => {
    (async () => {
      await fetchWallet(false);
    })();
  }, []);

  const {
    birthDate,
    countryIso2,
    firstName,
    lastName,
    gender,
    phoneNumber,
    companyName,
    setPhoneNumber,
    setLastName,
    setGender,
    setFirstName,
    setBirthDate,
    setCountryIso2,
    setCompanyName,
    syncUserData,
  } = useAccountSettingStore((state) => state);

  useEffect(() => {
    syncUserData(user);
  }, [user]);

  const [showResetPasswordModal, setShowResetPasswordModal] = useState(false);
  const onChangePassword = async () => {
    if (user?.email) {
      const response = await authService.forgot(user?.email);
      if (response.status === 200) {
        setShowResetPasswordModal(true);
      }
    }
  };

  const [countries, setCountries] = useState<CountryListItem[]>([]);
  useEffect(() => {
    authService.getCountryList().then((response) => {
      const { data } = response;
      if (data) {
        setCountries(data.sort((a, b) => ((a.name < b.name) ? -1 : 1)));
      }
    });
  }, []);

  const phoneInputClass = `${classes.phoneInput} ${
    showInputError && !isValidPhone(phoneNumber) && classes.phoneInputError}`;

  return (
    <Box className={classes.root}>
      <Typography variant="h4">Required Info</Typography>
      <Box className={classes.textFieldGroup}>

        {user?.userType === UserType.Institutional ? (
          <>
            <InputWithTitle
              title="Company name"
              titleRightItem={showInputError && !isValidString(companyName)
                && <Typography palette="red" paletteColor={500} variant="h6">required</Typography>}
            >
              <LightTextField
                error={showInputError && !isValidString(companyName)}
                value={companyName ?? ''}
                onChange={(e) => setCompanyName(e.target.value)}
                placeholder="Company name"
              />
            </InputWithTitle>
          </>
        ) : (
          <>
            <InputWithTitle
              title="First name"
              titleRightItem={showInputError && !isValidString(firstName)
                && <Typography palette="red" paletteColor={500} variant="h6">required</Typography>}
            >
              <LightTextField
                error={showInputError && !isValidString(firstName)}
                value={firstName ?? ''}
                onChange={(e) => setFirstName(e.target.value)}
                placeholder="First name"
              />
            </InputWithTitle>
            <InputWithTitle
              title="Last name"
              titleRightItem={showInputError && !isValidString(lastName)
                && <Typography palette="red" paletteColor={500} variant="h6">required</Typography>}
            >
              <LightTextField
                error={showInputError && !isValidString(lastName)}
                value={lastName ?? ''}
                onChange={(e) => setLastName(e.target.value)}
                placeholder="Last name"
              />
            </InputWithTitle>
          </>
        )}
        <InputWithTitle
          title="Country of Residence"
          titleRightItem={showInputError && !isValidString(countryIso2)
            && <Typography palette="red" paletteColor={500} variant="h6">required</Typography>}
        >
          <LightAutocomplete
            options={countries}
            getOptionLabel={(option) => option.name}
            value={countries.find((c) => c.iso2 === countryIso2) || null}
            onChange={(event, newValue) => {
              if (!newValue) setCountryIso2(undefined);
              newValue?.iso2 && setCountryIso2(newValue?.iso2);
            }}
            loading={countries.length <= 0}
            getOptionSelected={(option, value) => option.name === value.name}
            renderInput={(params: AutocompleteRenderInputParams) => (
              <LightTextField
                {...params}
                error={showInputError && !isValidString(countryIso2)}
                placeholder="select country"
              />
            )}
          />
        </InputWithTitle>
        <InputWithTitle
          title={user?.userType === UserType.Institutional ? 'Date of Incorporation' : 'Date of birth'}
          titleRightItem={showInputError && !isValidDate(birthDate)
            && <Typography palette="red" paletteColor={500} variant="h6">invalid date</Typography>}
        >
          <LightTextField
            value={birthDate?.toISOString().split('T')[0] ?? ''}
            onChange={(e) => {
              if (dateIsValid(e.target.value)) setBirthDate(new Date(e.target.value));
            }}
            error={showInputError && !isValidDate(birthDate)}
            type="date"
          />
        </InputWithTitle>
        <InputWithTitle
          title="Email"
          titleRightItem={user?.email
            && (
              <Box className={classes.approvedEmail}>
                <Typography strong={false} palette="green" paletteColor={400} variant="h6">Approved</Typography>
                <SVG src="icons/account_details/icon_check_fill.svg" />
              </Box>
            )}
        >
          <LightTextField
            disabled
            type="email"
            value={user?.email ?? ''}
          />
        </InputWithTitle>
      </Box>
      <Typography className={classes.paddingTop} variant="h4">Optional Info</Typography>
      <Box className={classes.textFieldGroup}>
        <InputWithTitle title="Gender">
          <LightAutocomplete
            options={['Male', 'Female', 'Other']}
            value={gender || null}
            onChange={(event, newValue) => {
              if (!newValue) setGender(undefined);
              newValue && setGender(newValue);
            }}
            renderInput={(params: AutocompleteRenderInputParams) => (
              <LightTextField
                {...params}
                placeholder="select gender"
              />
            )}
          />
        </InputWithTitle>
        <InputWithTitle
          title="Phone number"
          titleRightItem={showInputError && !isValidPhone(phoneNumber)
            && <Typography palette="red" paletteColor={500} variant="h6">incorrect number</Typography>}
        >
          <PhoneInput
            value={phoneNumber ?? ''}
            onChange={
              (value) => {
                setPhoneNumber(value);
              }
            }
            inputClass={phoneInputClass}
            country="us"
            onlyCountries={countries.map((c) => c.iso2.toLocaleLowerCase())}
            specialLabel=""
            isValid={(value) => isValidPhoneNumber(`+${value}`)}
          />
        </InputWithTitle>
      </Box>
      <Typography className={classes.paddingTop} variant="h4">Security</Typography>
      <Box className={classes.textFieldGroup}>
        <InputWithTitle title="Password">
          <LightTextField
            disabled
            type="password"
            value="********************"
            InputProps={{
              endAdornment: (
                <InputAdornment position="end" onClick={onChangePassword}>
                  <Typography className={classes.changePassword} variant="h6">change</Typography>
                </InputAdornment>
              ),
            }}
          />
        </InputWithTitle>
        <TwoFaButton />
        {
          user && !user.isCustodial && !hasCybavo && (
            <InputWithTitle title="Revoke private key access">
              <RevokeAccessButton />
            </InputWithTitle>
          )
        }
      </Box>
      {
        user && !user.isCustodial && !hasCybavo && (
          <InfoBanner
            modifier={`${classes.warningBanner}`}
            description="By revoking access to your private key, Coinbag will no longer work,
         and you will need to manage your private keys as individual crypto wallets."
            icon="icons/circle_question_yellow.svg"
          >
            <Link
              href="https://coinbag.tawk.help/article/what-happens-when-i-revoke-access-to-my-private-key"
              target="_blank"
              rel="noreferrer"
            >
              <Typography className={`${classes.learnMore} ${classes.warningBanner}`} uppercase variant="h6">
                Learn More
              </Typography>
            </Link>
          </InfoBanner>
        )
      }
      <ChangePasswordModal
        setShow={setShowResetPasswordModal}
        show={showResetPasswordModal}
        onChangePassword={onChangePassword}
      />
    </Box>
  );
};

export default AccountDetailsRightPanel;
