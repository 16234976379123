 /// <reference path="./auth-service.d.ts" >
import axios from "axios";
import jwt_decode from "jwt-decode";
import Headers from './headers';
import * as moment from 'moment-timezone';
import { withRouter } from "react-router-dom";

const API_URL = "/api/auth/";

class AuthService {

  getAccessToken(response) {
    let authHeaderValue = response.headers['authorization']

    if (authHeaderValue !== undefined) {
      let accessToken = authHeaderValue.substring("Bearer ".length)
      let obj = jwt_decode(accessToken);
      let user = obj.user;
      user.accessToken = accessToken;
      user.version = 3;
      localStorage.setItem("user", JSON.stringify(user));
      return accessToken;
    }
    return "";
  }

  signin(email, password, robot, fa = {authenticatorCode:"",emailCode:"",phoneCode:""}) {
    return axios
      .post(API_URL + "signin", {
        email,
        password,
        robot,
        ...fa
      }, { headers: Headers.def() })
      .then(response => {
        return {...response.data, payload:this.getAccessToken(response)}
      });
  }

  logout() {
    localStorage.removeItem("user");
    sessionStorage.removeItem("userStep");
  }

  register(userType, email, password, companyName, country, robot, referralCode) {
    return axios.post(API_URL + "register", {
      userType,
      email,
      password,
      companyName,
      country,
      robot,
      referralCode
    }, { headers: Headers.def() })
      .then(response => {
        return response
      });
  }

  getReferralCode() {
    return axios.get(API_URL + 'getReferralCode', { headers: { ...Headers.def(), ...Headers.auth() } })
      .then(response => {
        return response
      });
  }

  updatePassword(token, password, country) {
    return axios.post(API_URL + "updatePassword", {
      token,
      password,
      country
    })
      .then(response => {
        return response
      });
  }

  verification(token) {
    return axios.post(API_URL + "verification", {
      token
    }, { headers: Headers.def() })
      .then(response => {
        return response
      });
  }

  forgot(email) {
    return axios.post(API_URL + "forgot", {
      email
    }, { headers: Headers.def() })
      .then(response => {
        return response
      });
  }

  getCurrentUser() {
    return JSON.parse(localStorage.getItem('user'));
  }

  getEmailCode(purpose, emailParams) {
    return axios.post(API_URL + `getEmailCode/${purpose}`, {params: emailParams}, { headers: { ...Headers.def(), ...Headers.auth() } })
  }

  getVerificationCode(emailParams) {
    return axios.post(API_URL + 'getVerificationCode', {params: emailParams}, { headers: { ...Headers.def(), ...Headers.auth() } })
  }

  /**
   * @param {string} email
   */
  addNewsletter(email) {
    return axios.post(API_URL + 'addNewsletter', {
      email
    }, { headers: Headers.def() })
      .then(response => {
        return response
      });;
  }

  updateUserInfo(userInfo) {
    if(userInfo.birthDate!=undefined)
      userInfo.birthDate = Date.UTC(userInfo.birthDate.getFullYear(), userInfo.birthDate.getMonth(), userInfo.birthDate.getDate());

    if(userInfo.residenceCountryIso2 === ""){
      userInfo.residenceCountryIso2 = undefined
    }
    return axios.post(API_URL + 'updateUserInfo', userInfo, { headers: { ...Headers.def(), ...Headers.auth() } })
      .then(response => {
        return response
      });
  }

  updateUserStep() {
    return axios.post(API_URL + 'updateUserStep', {}, { headers: { ...Headers.def(), ...Headers.auth() } })
      .then(response => {
        sessionStorage.setItem("userStep", response.data);
        return response
      });
  }

  generateSecretKey() {
    return axios.get(API_URL + 'generateSecretKey', { headers: { ...Headers.def(), ...Headers.auth() } })
  }

  addSecretKey(secretKey, totp) {
    return axios.post(API_URL + 'addSecretKey', { secretKey: secretKey, totp: parseInt(totp) }, { headers: { ...Headers.def(), ...Headers.auth() } })
      .then(response => {
        return response
      });
  }

  getOTPAuth(email, secretKey) {
    var issuer = "COINBAG"
    return "otpauth://totp/"
      + encodeURIComponent(issuer + ":" + email)
      + "?secret=" + encodeURIComponent(secretKey)
      + "&issuer=" + encodeURIComponent(issuer)
  }

  getStep(){
    return this.getUserInfo().then(resp => {
      sessionStorage.setItem("userStep", resp.data.step)
      return resp.data.step;
    });
  }

  getUserInfo() {
    return axios.get(API_URL + 'getUserInfo', { headers: { ...Headers.def(), ...Headers.auth() } })
      .then(response => {
        if (!!response.data.birthDate) {
          //DatePicker only works in local time, converting UTC back to local
          response.data.birthDate = new Date(moment(new Date(response.data.birthDate)).local(true).valueOf());
          response.data.deactivationAt = new Date(moment(new Date(response.data.deactivationAt)).local(true).valueOf());
        }
        return response;
      });
  }

  getCountryList() {
    return axios.get(API_URL + 'getCountryList', { headers: { ...Headers.def() } });
  }

  getUserEvents() {
    return axios.get(API_URL + 'getUserEvents', { headers: { ...Headers.def(), ...Headers.auth() } });
  }
}

export default withRouter(new AuthService());
