interface ReferralIdStorageItem {
  rid: string;
  dateAdded: number;
}

const expiry = (1000 * 60 * 60 * 24 * 7); // one week

const set = (rid: string) => {
  const item = {
    rid,
    dateAdded: Date.now(),
  };

  localStorage.setItem('rid', JSON.stringify(item));
}

const get = (): string | null => {
  const stored = localStorage.getItem('rid');
  const item: ReferralIdStorageItem = stored ? JSON.parse(stored) : null;

  if (item && item.dateAdded + expiry < Date.now()) {
    localStorage.removeItem('rid');
    return null;
  }

  return item?.rid;
}

const storageAvailable = (type = 'localStorage') => {
  let storage;
  try {
    storage = (window as any)[type];
    const x = '__storage_test__';
    storage.setItem(x, x);
    storage.removeItem(x);
    return true;
  }
  catch(e) {
    return e instanceof DOMException && (
    // everything except Firefox
      e.code === 22 ||
        // Firefox
        e.code === 1014 ||
        // test name field too, because code might not be present
        // everything except Firefox
        e.name === 'QuotaExceededError' ||
        // Firefox
        e.name === 'NS_ERROR_DOM_QUOTA_REACHED') &&
      // acknowledge QuotaExceededError only if there's something already stored
      (storage && storage.length !== 0);
  }
}

export default {
  set,
  get,
  storageAvailable,
};
