/* eslint-disable no-unused-vars */
/* eslint-disable no-shadow */

export interface WorkItem {
  workItemId: number;
  userId: number;
  name: WorkItemName;
  parameters: any;
  automationType: WorkItemAutomationType;
  status: WorkItemStatus;
  openedTs: string;
  lastUpdatedTs: string;
  data: any;
  approvers: WorkItemApprover[];
  email: string;
  approvesCount: number;
}

export enum WorkItemName {
  Withdrawal = 'withdrawal',
  WithdrawalToken = 'withdrawal-token',
  Rebalancing = 'rebalancing',
  CreateWallet = 'createwallet',
  Payroll = 'payroll',
  PayrollBatch = 'payroll-batch',
  AddReportingAddress = 'add-reporting-address',
  SetEarningConfig = 'set-earning-config',
  AddUserBundle = 'add-user-bundle',
  AddOrUpdateAddressBook = 'add-or-update-address-book',
  RemoveAddressBook = 'remove-address-book',
  AddTagName = 'add-tag-name',
  UpdateTagName = 'update-tag-name',
  RemoveTagName = 'remove-tag-name',
  SetRebalancingMode = 'set-rebalancing-mode',
  CreateOrEditBundle = 'create-or-edit-bundle',
  SetBasicWallet = 'set-basic-wallet'
}
export const WORK_ITEM_REQUIRE_2FA = [WorkItemName.Withdrawal, WorkItemName.WithdrawalToken, WorkItemName.PayrollBatch];

export const isApproverWorkItem = (name: WorkItemName) => (
  name === WorkItemName.Rebalancing
  || name === WorkItemName.Withdrawal
  || name === WorkItemName.WithdrawalToken
  || name === WorkItemName.PayrollBatch
  || name === WorkItemName.SetEarningConfig
  || name === WorkItemName.AddUserBundle
  || name === WorkItemName.AddOrUpdateAddressBook
  || name === WorkItemName.RemoveAddressBook
  || name === WorkItemName.AddTagName
  || name === WorkItemName.UpdateTagName
  || name === WorkItemName.RemoveTagName
  || name === WorkItemName.SetRebalancingMode
  || name === WorkItemName.CreateOrEditBundle
  || name === WorkItemName.SetBasicWallet
  || name === WorkItemName.CreateWallet
);

export enum WorkItemAutomationType {
  Manual = 'manual',
  Automatic = 'automatic',
}

export enum WorkItemStatus {
  Pending = 'pending',
  WaitingForApproval = 'waiting-for-approval',
  InProgress = 'in-progress',
  Rejected = 'rejected',
  Closed = 'closed',
}

export interface WorkItemApprover {
  workItemId: number;
  userId: number;
  email: string;
  status: WorkItemApproverStatus,
}

export enum WorkItemApproverStatus {
  Pending = 'pending',
  Rejected = 'rejected',
  Approved = 'approved',
}

export interface WithdrawalJobData {
  subStatus: WithdrawalJobSubStatus;
}

export enum WithdrawalJobSubStatus {
  INIT = 'INIT',
  DETERMINING_WALLET_BALANCES = 'DETERMINING_WALLET_BALANCES',
  CALCULATING_TARGET_BUNDLE = 'CALCULATING_TARGET_BUNDLE',
  REBALANCING = 'REBALANCING',
  WITHDRAWING = 'WITHDRAWING',
  DONE = 'DONE',
}
