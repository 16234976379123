// @flow
import { Box, createStyles } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import * as React from 'react';
import { ReactNode } from 'react';

import { WalletReportingFooter } from './Layout/Footer/WalletReportingFooter';
import { WalletReportingHeader } from './Layout/Header/WalletReportingHeader';

interface Props {
  children: ReactNode
}

const useStyles = makeStyles(() => createStyles({
  root: {
    display: 'flex',
    flexDirection: 'column',
    minHeight: '100vh',
    background: 'white',
  },
}));

export const WalletReportingLayout = ({ children }: Props) => {
  const classes = useStyles();
  return (
    <Box className={classes.root}>
      <WalletReportingHeader />
      {children}
      <WalletReportingFooter />
    </Box>
  );
};
