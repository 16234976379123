import { Box, CircularProgress, makeStyles } from '@material-ui/core';
import { useWallets } from '@web3-onboard/react';
import { Dropdown, Typography } from 'elements';
import { UserType } from 'models';
import { getWalletProviderIcon } from 'pages/responsive/WalletSetting/components/WalletTypes';
import React, { useEffect, useMemo, useState } from 'react';
import SVG from 'react-inlinesvg';
import { Link } from 'react-router-dom';
import { WalletInfo } from 'services/types/wallet';
import { useStore as useFeatureStore } from 'store/zustand/Feature';
import { useStore as useUserStore } from 'store/zustand/User';
import { useStore as useWalletStore } from 'store/zustand/Wallet';

import { isAddressSupported, WalletStatus } from '../../../services/OnboardService';
import generateRandomString from '../../../utils/generateRandomString';
import { WalletRow } from './WalletRow';

type WalletSwitcherProps = {
  fullWidth?: boolean;
};

const useStyles = makeStyles((theme) => ({
  walletDropdown: {
    padding: 4,
    width: 240,
    height: 32,
    border: 'none',
  },
  fullWidth: {
    width: '100%',
  },
  walletList: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'stretch',
    minWidth: 240,
    width: '100%',
    '& > *:last-child': {
      borderBottom: 'none',
    },
  },
  walletRoot: {
    display: 'flex',
    alignItems: 'center',
    columnGap: 4,
    cursor: 'pointer',
  },
  walletIcon: {
    position: 'relative',
    width: 24,
    height: 24,
    boxShadow: '0px 4px 12px rgba(110, 123, 162, 0.12)',
    '& img': {
      borderRadius: 4,
      width: 24,
      height: 24,
    },
  },
  walletProviderText: {
    color: 'inherit',
    fontSize: 12,
    fontWeight: 500,
  },
  walletNameText: {
    color: 'inherit',
    fontSize: 10,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  walletOrder: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: 16,
    height: 16,
    color: theme.palette.common.white,
    fontSize: 12,
    fontWeight: 500,
    background: theme.palette.blue[500],
    borderRadius: 10,
    flexShrink: 0,
  },
  selectedWalletOrder: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: 16,
    height: 16,
    fontSize: 12,
    fontWeight: 500,
    borderRadius: 10,
    background: theme.palette.common.white,
    paddingTop: 1,
  },
  walletOption: {
    padding: 8,
    borderBottom: '1px solid',
    borderBottomColor: theme.palette.grey[200],
    '&:hover': { background: theme.palette.blue[100] },
  },
  comingSoonBadge: {
    fontSize: 10,
    fontWeight: 500,
    padding: '2px 8px',
    marginLeft: 'auto',
    color: theme.palette.purple[500],
    background: theme.palette.purple[100],
    borderRadius: 16,
  },
  connectNew: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: 8,
    cursor: 'pointer',
    '&:hover': { background: theme.palette.green[100] },
  },
  connectButton: { fill: theme.palette.green[600] },
  textWhite: { color: theme.palette.common.white },
  textGreen: { color: theme.palette.green[600] },
  divider: { color: theme.palette.grey[300], fontSize: 12 },
  subHeader: {
    background: '#FAFBFF',
    padding: 8,
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'column',
    gap: 4,
    '& svg': {
      marginLeft: 4,
    },
    borderBottom: '1px solid',
    borderBottomColor: theme.palette.grey[200],
  },
  allWallets: {
    justifyContent: 'space-between',
    '& path': {
      stroke: theme.palette.blue[500],
    },
  },
  walletConnected: {
    width: 7,
    height: 7,
    position: 'absolute',
    top: -1,
    right: -1,
    backgroundColor: theme.palette.green[500],
    border: '1px solid white',
    borderRadius: 4,
  },
}));

const WalletSwitcher = ({ fullWidth }: WalletSwitcherProps) => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);

  const [fetchWallet, updateActiveWallet, activeId, isAllWallets] = useWalletStore((state) => ([
    state.fetchWallet, state.updateActiveWallet, state.activeId, state.isAllWallets,
  ]));
  const [features, fetchFeatures] = useFeatureStore((state) => [state.features, state.fetchFeatures]);
  const [user] = useUserStore((state) => [state.user]);
  const [isLoading, setIsLoading] = React.useState(false);
  const connectedWallets = useWallets();

  useEffect(() => {
    fetchFeatures();
    (async () => { await fetchWallet(false); })();
  }, []);

  // Local state for instantaneous change in UI
  const [activeWalletId, setActiveWalletId] = useState(0);
  const [activeInstitutionId, setActiveInstitutionId] = useState(0);
  const [activeWalletColor, setActiveWalletColor] = useState<'blue' | 'green'>('blue');

  useEffect(() => {
    if (activeId?.activeWalletId !== undefined) {
      setActiveWalletId(activeId?.activeWalletId);
      setActiveInstitutionId(0);
      setActiveWalletColor((user?.userType === UserType.Individual && (user?.walletInfo ?? [])
        .findIndex((w) => w.isOwn && w.walletId === activeId.activeWalletId) >= 0) ? 'green' : 'blue');
    } else if (activeId?.activeInstitutionId !== undefined) {
      setActiveWalletId(0);
      setActiveInstitutionId(activeId?.activeInstitutionId);
      setActiveWalletColor('blue');
    }
  }, [activeId, user?.walletInfo, user]);

  const updateSelectedWalletId = async (walletId?: number, institutionId?: number) => {
    setIsLoading(true);
    if (walletId != null) {
      setActiveWalletId(walletId);
      setActiveInstitutionId(0);
      setActiveWalletColor((user?.userType === UserType.Individual && (user?.walletInfo ?? [])
        .findIndex((w) => w.isOwn && w.walletId === walletId) >= 0) ? 'green' : 'blue');
    } else if (institutionId != null) {
      setActiveWalletId(0);
      setActiveInstitutionId(institutionId);
      setActiveWalletColor('blue');
    }
    await updateActiveWallet(walletId, institutionId);
    setIsLoading(false);
  };

  const selectedWallet = useMemo(() => {
    const walletInfo = user?.walletInfo.find((w) => w.walletId === activeWalletId);
    const iconUrl = getWalletProviderIcon(walletInfo?.walletProvider, walletInfo?.isNonCustodian);

    const isWalletConnected =
      walletInfo?.isNonCustodian
      && walletInfo?.address
      && isAddressSupported(walletInfo.address[0] ?? '') !== WalletStatus.No;

    return (
      <Box className={`${classes.walletRoot} ${classes.textWhite}`} minWidth={0}>
        <Box className={classes.walletIcon}>
          <img
            src={`/icons/wallet_setting/${iconUrl}_sm.svg?${generateRandomString()}`}
            aria-label={walletInfo?.walletProvider}
            alt={walletInfo?.walletProvider}
          />
          {isWalletConnected && (<div className={classes.walletConnected} />)}
        </Box>
        {activeInstitutionId !== 0 && <Typography className={classes.walletProviderText}>All wallets</Typography>}
        {activeWalletId !== 0 && walletInfo && (
          <>
            <Typography className={classes.walletProviderText}>
              {walletInfo?.walletProvider === 'Reporting' && walletInfo?.isNonCustodian ? 'Self-custody'
                : walletInfo?.walletProvider}
            </Typography>
            <Typography className={classes.divider}>|</Typography>
            <Typography className={classes.walletNameText}>{walletInfo?.name ?? ''} #</Typography>
            <Typography
              className={classes.selectedWalletOrder}
              paletteColor={500}
              palette={activeWalletColor}
            >
              {walletInfo?.order}
            </Typography>
          </>
        )}
      </Box>
    );
  }, [user?.walletInfo, activeWalletId, activeWalletColor, activeInstitutionId, connectedWallets]);

  const getWalletRow = (walletInfo: WalletInfo) => (
    <WalletRow
      key={walletInfo.walletId}
      walletInfo={walletInfo}
      onClick={() => {
        setOpen(false);
        if (activeWalletId !== walletInfo.walletId) updateSelectedWalletId(walletInfo.walletId ?? 0);
      }}
      selected={walletInfo.walletId === activeWalletId}
      className={classes.walletOption}
    />
  );

  const ownWalletRow = React.useMemo(() => (
    user?.walletInfo
      .filter((walletInfo) => walletInfo.walletId != null && walletInfo.isOwn && user?.userType === UserType.Individual)
      .map((walletInfo) => (getWalletRow(walletInfo)))
  ), [user?.walletInfo, activeWalletId]);

  const sharedWalletRow = React.useMemo(() => (
    user?.walletInfo
      .filter((walletInfo) => (walletInfo.walletId != null
        && (!walletInfo.isOwn || user?.userType !== UserType.Individual)))
      .map((walletInfo) => (getWalletRow(walletInfo)))
  ), [user?.walletInfo, activeWalletId]);

  return (
    <Dropdown
      palette={activeWalletColor}
      className={`${classes.walletDropdown} ${fullWidth ? classes.fullWidth : ''}`}
      title={isLoading
        ? <><CircularProgress thickness={6} color="inherit" size="1.7em" /> Switching wallet</> : selectedWallet}
      isOpen={open && !isLoading}
      setOpen={setOpen}
    >
      <Box className={classes.walletList}>
        {(ownWalletRow?.length ?? 0) > 0 && (
          <Box className={classes.subHeader}>
            <Typography variant="h6" paletteColor={700}>
              Your personal wallet
            </Typography>
          </Box>
        )}
        {ownWalletRow}
        {(sharedWalletRow?.length ?? 0) > 0 && (
          <Box className={classes.subHeader}>
            <Typography variant="h6" paletteColor={700}>Your company's wallet(s)</Typography>
          </Box>
        )}
        {sharedWalletRow}
        {(sharedWalletRow?.length ?? 0) > 1 && (
          <Box
            className={`${classes.walletRoot} ${classes.walletOption} ${classes.allWallets}`}
            onClick={() => {
              setOpen(false);
              const institutionIds = user?.walletInfo.map((wi) => wi.userId)
                .filter((userId) => userId !== user?.userId || user?.userType === UserType.Institutional);
              if (institutionIds && institutionIds?.length > 0) {
                updateSelectedWalletId(undefined, institutionIds[0]);
              }
            }}
          >
            <Typography variant="h4">
              All Wallets
            </Typography>
            {isAllWallets() && (
              <SVG src="/icons/icon_check.svg" width={24} height={24} />
            )}
          </Box>
        )}
        {features?.manageWallet && (
          <Link className={classes.connectNew} to="/wallet-setting" onClick={() => { setOpen(false); }}>
            <Typography variant="h4" className={classes.textGreen}>Connect new wallet</Typography>
            <SVG className={classes.connectButton} src="/icons/dashboard/ic_add.svg" width={20} height={20} />
          </Link>
        )}
      </Box>
    </Dropdown>
  );
};

WalletSwitcher.displayName = 'WalletSwitcher';
export { WalletSwitcher };
