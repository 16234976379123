import { Box, makeStyles, Typography } from '@material-ui/core';
import { InterestEarnedSection } from 'components/interest';
import { BackButton, Page } from 'elements';
import React, { useCallback, useEffect, useMemo } from 'react';
import { DashboardInterestRoute, DashboardRoute } from 'routes';
import { MasterCoin } from 'services/types/coin';
import { InterestByCoin, InterestByMasterCoin } from 'services/types/interest';
import { useStore as useInterestStore } from 'store/zustand/Interest';

const useStyles = makeStyles((theme) => ({
  mobileSection: {
    margin: '0 16px',
    [theme.breakpoints.up('sm')]: {
      margin: 0,
    },
  },
  mobileCard: {
    borderRadius: '0 !important',
    [theme.breakpoints.up('sm')]: {
      borderRadius: '4px !important',
    },
  },
  titleRow: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'start',
    rowGap: 12,
  },
  titleText: {
    width: '100%',
    textAlign: 'center',
    lineHeight: '28px',
  },
  backButton: {
    position: 'relative',
    [theme.breakpoints.up('sm')]: {
      position: 'absolute',
      top: 0,
      bottom: 0,
      left: 0,
    },
  },
  filterRow: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
}));

const InterestPage = () => {
  const classes = useStyles();

  const [interestsByCoin, fetchInterestsByCoin] = useInterestStore((state) => [
    state.interestsByCoin,
    state.fetchInterestsByCoin,
  ]);
  useEffect(() => {
    fetchInterestsByCoin(false);
  }, []);

  const convertToInterestByMasterCoin = useCallback((byCoin: InterestByCoin[]): InterestByMasterCoin[] => {
    type MasterCoinWithInterest = [MasterCoin, { interest: number, convertedInterest: number}];
    const byMasterCoinMap = byCoin
      .filter((c) => c.coin.masterCoin !== undefined)
      .reduce((masterCoinInterests: Record<number, MasterCoinWithInterest>, coinInterest: InterestByCoin) => {
        const { masterCoin } = coinInterest.coin;
        const prevData: MasterCoinWithInterest | undefined = masterCoinInterests[masterCoin.masterCoinId];
        const { interest, convertedInterest } = prevData === undefined
          ? { interest: 0, convertedInterest: 0 } : prevData[1];
        const updatedInterestValue: MasterCoinWithInterest = [masterCoin,
          { interest: interest + coinInterest.interest,
            convertedInterest: convertedInterest + (coinInterest.convertedInterest ?? 0) }];
        return { ...masterCoinInterests, [masterCoin.masterCoinId]: updatedInterestValue };
      }, {});
    return Object.entries(byMasterCoinMap)
      .map(([, [masterCoin, interest]]) => ({ masterCoin, ...interest }))
      .filter(({ masterCoin }) => masterCoin.ticker !== 'UST')
      .sort((a, b) => b.convertedInterest - a.convertedInterest);
  }, []);

  const interestsByMasterCoin = useMemo(() => (
    interestsByCoin ? convertToInterestByMasterCoin(interestsByCoin) : []
  ), [interestsByCoin]);

  return (
    <Page>
      <Box className={`${classes.mobileSection} ${classes.titleRow}`}>
        <BackButton className={classes.backButton} href={DashboardRoute} />
        <Typography variant="h3" className={classes.titleText}>Interest Earned with Coinbag</Typography>
      </Box>

      <InterestEarnedSection
        className={classes.mobileCard}
        interests={interestsByMasterCoin}
        selectedCoins={interestsByMasterCoin.map((i) => i.masterCoin.ticker)}
        isLoading={interestsByCoin === undefined}
      />
    </Page>
  );
};

InterestPage.displayName = 'InterestPage';
InterestPage.routePath = DashboardInterestRoute;
InterestPage.loginRequired = true;
InterestPage.redirect = 'interestPageRedirect';

export { InterestPage };
