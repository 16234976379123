import { Box, createStyles, makeStyles, Typography } from '@material-ui/core';
import { Button, PageAction } from 'elements';
import ManageAccountNavBar from 'elements/ManageAccountNavBar';
import { User, UserType } from 'models';
import React, { useEffect, useState } from 'react';
import { AccountDetailsRoute } from 'routes';
import AuthService from 'services/auth-service';
import { useStore as useAccountSettingStore } from 'store/zustand/AccountSetting';
import { useStore as useUserStore } from 'store/zustand/User';
import { breakpoints } from 'theme/base';

import AccountDetailsRightPanel from './AccountDetailRightPanel';
import AccountDetailsLeftPanel from './AccountDetailsLeftPanel';
import LeavePageModal from './elements/LeavePageModal';
import { isValidDate, isValidPhone, isValidString } from './InputValidator';

const useStyles = makeStyles((theme) => createStyles({
  root: {
    display: 'grid',
    gridTemplateColumns: '1fr minmax(auto, 600px) 1fr',
    justifyContent: 'center',
    width: '100%',
    gap: '96px',
    [breakpoints.down('md')]: {
      gap: '48px',
    },
    [breakpoints.down('sm')]: {
      gridTemplateColumns: '1fr',
      gap: '24px',
    },
  },
  superRoot: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    padding: '24px',
    gap: '24px',
    flex: 1,
    alignItems: 'center',
    [breakpoints.down('sm')]: {
      padding: '16px',
    },
  },
  buttonsWrapper: {
    justifyContent: 'center',
    width: '100%',
    display: 'grid',
    gridTemplateColumns: '1fr minmax(auto, 600px) 1fr',
    gap: '96px',
    [breakpoints.down('md')]: {
      gap: '48px',
    },
    [breakpoints.down('sm')]: {
      gridTemplateColumns: '1fr',
      gap: '24px',
      justifyContent: 'space-between',
    },
  },
  buttons: {
    display: 'flex',
    justifyContent: 'space-between',
    flexWrap: 'nowrap',
    gridColumnStart: 2,
    [breakpoints.down('sm')]: {
      gridColumnStart: 1,
    },
  },
  container: {
    position: 'relative',
  },
  changedApplied: {
    display: 'flex',
    fontWeight: 400,
    color: theme.palette.green[500],
    flexWrap: 'nowrap',
    gap: '4px',
    alignItems: 'center',
    padding: '12px',
    boxShadow: '0px 8px 20px rgba(110, 123, 162, 0.12)',
    borderRadius: '8px',
    position: 'absolute',
    width: '150px',
    left: '-75px',
    top: '-4px',
    background: 'white',
  },
}));

const AccountDetails = () => {
  const classes = useStyles();

  // State
  const [showLeavePageModal, setShowLeavePageModal] = useState(false);
  const [showCompleted, setShowCompleted] = useState(false);

  // Store
  const { fetchUser, user } = useUserStore((state) => state);
  const {
    birthDate,
    countryIso2,
    firstName,
    lastName,
    gender,
    phoneNumber,
    companyName,
    syncUserData,
    isChange,
  } = useAccountSettingStore((state) => state);
  const [showInputError, setShowInputError] = useState(false);

  // Effect
  useEffect(() => {
    if (window.Tawk_API && window.Tawk_API.hideWidget) window.Tawk_API.hideWidget();
    return function cleanup() {
      if (window.Tawk_API && window.Tawk_API.showWidget) window.Tawk_API.showWidget();
    };
  });

  useEffect(() => {
    if (showCompleted) {
      setTimeout(() => {
        setShowCompleted(false);
      }, 4000);
    }
  }, [showCompleted]);

  useEffect(() => {
    fetchUser(true);
  }, []);

  useEffect(() => {
    if (user) {
      const onUnloadAction = onUnload(user);
      window.addEventListener('beforeunload', onUnloadAction);
      return (() => { window.removeEventListener('beforeunload', onUnloadAction); });
    }
    return undefined;
  }, [user]);

  // Action
  const onSave = async () => {
    if ((user?.userType === UserType.Institutional ? isValidString(companyName) : (isValidString(firstName) && isValidString(lastName)))
      && isValidString(countryIso2)
      && isValidDate(birthDate)
      && isValidPhone(phoneNumber)
      && user?.kycResult) {
      await AuthService.updateUserInfo({
        birthDate,
        firstName,
        phoneNumber,
        gender,
        lastName,
        companyName,
        residenceCountryIso2: countryIso2,
        kycResult: user?.kycResult,
        consentTermsAndConditions: user?.consentTermsAndConditions,
      });
      const updatedUser = await fetchUser(true);
      syncUserData(updatedUser);
      setShowCompleted(true);
    } else {
      setShowInputError(true);
    }
  };

  const onCancel = () => {
    setShowInputError(false);
    syncUserData(user);
  };

  const onUnload = (userResponse: User) => (
    (e: BeforeUnloadEvent) => {
      if (isChange(userResponse)) {
        e.stopPropagation();
        e.preventDefault();
        setShowLeavePageModal(true);
        e.returnValue = '';
      } else {
        setShowLeavePageModal(false);
      }
    }
  );

  return (
    <>
      <ManageAccountNavBar />
      <Box className={classes.superRoot}>
        <Box className={classes.root}>
          <AccountDetailsLeftPanel />
          <AccountDetailsRightPanel showInputError={showInputError} />
        </Box>
      </Box>
      <PageAction wrapperClass={classes.buttonsWrapper}>
        <Box className={classes.buttons}>
          <Button onClick={onCancel} variant="secondary">Cancel</Button>
          {showCompleted && (
            <Box className={classes.container}>
              <Box className={classes.changedApplied}>
                <img src="icons/account_details/icon_check_fill.svg" alt="check" />
                <Typography variant="h6">Changes applied</Typography>
              </Box>
            </Box>
          )}
          <Button disabled={!isChange(user)} onClick={onSave} variant="primary">Save</Button>
        </Box>
      </PageAction>
      <LeavePageModal setShow={setShowLeavePageModal} show={showLeavePageModal} onSave={onSave} />
    </>
  );
};

AccountDetails.routePath = AccountDetailsRoute;
AccountDetails.loginRequired = true;
AccountDetails.title = 'Account Details';

export default AccountDetails;
