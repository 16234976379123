import { Box, makeStyles } from '@material-ui/core';
import { Checkbox, LightTextField, Typography } from 'elements';
import * as React from 'react';
import SVG from 'react-inlinesvg';
import { Permission } from 'services/types/role';
import { useStore as useRoleStore } from 'store/zustand/Role';
import { useStore as useUserStore } from 'store/zustand/User';

import { ApproveMember } from './type';

interface SelectMemberPopoverProps {
  members: ApproveMember[];
  permission: Permission;
  onClose: () => void;
}

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    '& > *': {
      borderBottom: `1px solid ${theme.palette.grey[200]}`,
      '&:last-child': {
        borderBottom: 'none',
      },
    },
  },
  row: {
    padding: '8px 12px',
    cursor: 'pointer',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    '&:hover': {
      backgroundColor: theme.palette.green[50],
    },
    '& svg': {
      flexShrink: 0,
    },
  },
  memberList: {
    padding: '8px 12px',
    display: 'flex',
    flexDirection: 'column',
    gap: 8,
  },
  member: {
    display: 'flex',
    flexWrap: 'nowrap',
    gap: 4,
    cursor: 'pointer',
    alignItems: 'flex-start',
  },
  memberEmail: {
    fontSize: 12,
    lineHeight: '14px',
    fontWeight: 400,
    textOverflow: 'ellipsis',
    overflow: 'hidden',
  },
  memberName: {
    textOverflow: 'ellipsis',
    overflow: 'hidden',
  },
  checkBox: {
    margin: 4,
    borderRadius: 2,
    border: `solid 2px ${theme.palette.green[400]}`,
    flexShrink: 0,
    width: 16,
    height: 16,
  },
  noResult: {
    padding: 16,
    alignSelf: 'center',
  },
}));

const SelectMemberPopover = ({ members, permission, onClose }: SelectMemberPopoverProps) => {
  const classes = useStyles();

  const [user, allowedUsers] = useUserStore((state) => [state.user, state.allowedUsers]);
  const [addPermissionApprover, removePermissionApprover, updatePermissionApprovers] = useRoleStore((state) => [
    state.addPermissionApprover, state.removePermissionApprover, state.updatePermissionApprovers,
  ]);

  const [findText, setFindText] = React.useState('');

  const filteredMember = React.useMemo(() => (
    members.filter((m) => `${m.firstName} ${m.lastName} ${m.email}`.indexOf(findText) >= 0)
  ), [findText, members, user]);

  const onSelectAll = React.useMemo(() => (
    () => {
      updatePermissionApprovers(
        permission.permissionId,
        (allowedUsers?.map((u) => ({ email: u.email ?? '', id: u.userId })) ?? []),
      );
      onClose();
    }
  ), [permission.permissionId, onClose, allowedUsers]);

  const onSelectSuperAdmin = React.useMemo(() => (
    () => {
      updatePermissionApprovers(permission.permissionId, []);
      onClose();
    }
  ), [permission.permissionId, onClose]);

  const onAddOrRemoveMember = React.useCallback((userId: number, email: string) => {
    const selected = permission.approvers.find((a) => a.userId === userId);
    if (selected) {
      removePermissionApprover(permission.permissionId, userId);
    } else {
      addPermissionApprover(permission.permissionId, userId, email);
    }
  }, [permission]);

  return (
    <Box className={classes.root}>
      {members.length > 0 && (
        <Box className={classes.row} onClick={onSelectAll}>
          <Typography variant="h6" paletteColor={700}>All approving members</Typography>
          {(permission.approvers.length > 0 && permission.approvers.length === allowedUsers?.length)
            && <SVG src="/icons/ic_check.svg" width={16} height={16} />}
        </Box>
      )}
      <Box className={classes.row} onClick={onSelectSuperAdmin}>
        <Typography variant="h6" paletteColor={700}>No approver</Typography>
        {permission.approvers.length === 0 && <SVG src="/icons/ic_check.svg" width={16} height={16} />}
      </Box>
      {members.length > 0 && (
        <Box className={classes.memberList}>
          <LightTextField
            placeholder="Find Member"
            value={findText}
            onChange={(e) => setFindText(e.target.value)}
          />
          {filteredMember.length > 0 ? filteredMember.map((member, key) => (
            <Box
              className={classes.member}
              key={key}
              onClick={() => onAddOrRemoveMember(member.id ?? 0, member.email ?? '')}
            >
              <Checkbox checked={permission.approvers.findIndex((p) => p.userId === member.id) >= 0} />
              <Box display="flex" flexDirection="column" minWidth={0}>
                <Typography className={classes.memberName} variant="h6" paletteColor={600}>
                  {member.isAdmin ? (
                    <><SVG src="/icons/ic_star.svg" width={14} height={14} style={{ verticalAlign: 'middle' }} /> admin</>
                  ) : (member.firstName || member.lastName) ? `${member.firstName ?? ''} ${member.lastName ?? ''}` : 'N/A'}
                </Typography>
                <Typography className={classes.memberEmail} paletteColor={400}>{member.email}</Typography>
              </Box>
            </Box>
          )) : (
            <Typography className={classes.noResult} variant="h4" paletteColor={700}>No Result</Typography>
          )}
        </Box>
      )}
    </Box>
  );
};

export default SelectMemberPopover;
