import React, { FC } from 'react';

interface ChartLegendProps {
  color?: string
  width?: number
  height?: number
}

const LineChartLegend: FC<ChartLegendProps> = ({ color, width, height }) => (
  <svg width={width ?? 16} height={height ?? 16} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M12.0007 7.33333L11.0007 8.33333C10.7661 8.56784 10.6489 8.68509 10.5367 8.76954C9.82448 9.30558 8.84349 9.30558 8.13127 8.76954C8.01907 8.68509 7.90182 8.56784 7.66732 8.33333V8.33333C7.43282 8.09883 7.31556 7.98158 7.20336 7.89713C6.49115 7.36108 5.51015 7.36108 4.79794 7.89713C4.68574 7.98158 4.56849 8.09883 4.33398 8.33333L3.33398 9.33333"
      stroke={color ?? '#06D2AE'}
      strokeLinecap="round"
    />
  </svg>
);

LineChartLegend.displayName = 'LineChartLegend';
export { LineChartLegend };
