import { Tag, TagId } from 'services/types/tag';
import { create } from 'zustand';

import TransactionService from '../../services/transaction.service';
import { AddressBook } from '../../services/types/address-book';
import { TransactionsNote, UserAtomicTransaction } from '../../services/types/user-transaction';

interface TransactionStore {
  txs?: UserAtomicTransaction[];
  tags: Tag[];
  notes: TransactionsNote[];
  tagsWithCounts?: Record<TagId, number>
  count: number;
  // eslint-disable-next-line no-unused-vars
  fetchTxs: (forceReload: boolean) => void;
  // eslint-disable-next-line no-unused-vars
  fetchTags: (forceReload: boolean) => Promise<void>;
  // eslint-disable-next-line no-unused-vars
  addTag: (txHash: string, tagId: number) => void;
  // eslint-disable-next-line no-unused-vars
  updateTag: (tagId: number, tag: string) => void;
  // eslint-disable-next-line no-unused-vars
  removeTag: (txHash: string, tagId: number) => void;
  // eslint-disable-next-line no-unused-vars
  addTagName: (tag: string, walletId: number) => Promise<number | undefined>;
  // eslint-disable-next-line no-unused-vars
  removeTagName: (tagId: number) => void;

  addressBooks?: AddressBook[];
  addressNameMap: Record<string, string[]>;
  nameAddressMap: Record<string, string>;
  // eslint-disable-next-line no-unused-vars
  fetchAddressBooks: (forceReload: boolean) => void
  // eslint-disable-next-line no-unused-vars
  addAddressBook: (address: string, tag: string, addressBookId?: number, walletId?: number) => void;
  // eslint-disable-next-line no-unused-vars
  removeAddressBook: (addressBookId: number) => void;
  // eslint-disable-next-line no-unused-vars
  fetchNotes: (forceReload: boolean) => Promise<void>;
  // eslint-disable-next-line no-unused-vars
  addOrEditNote: (txHash: string, note: string, walletId: number) => Promise<void>;
  // eslint-disable-next-line no-unused-vars
  removeNote: (txHash: string, walletId: number) => Promise<void>;
}

export const useStore = create<TransactionStore>((set, get) => ({
  txs: undefined,
  tags: [],
  notes: [],
  count: 0,
  fetchTxs: async (forceReload) => {
    if (get().txs === undefined || forceReload) {
      const { tagsWithCounts, ...res } = await TransactionService.getTransactions();
      const mappedTagsWithCounts = Object.fromEntries(tagsWithCounts
        .map((tagWithCount) => ([tagWithCount.tagId, tagWithCount.count])));
      set({ tagsWithCounts: mappedTagsWithCounts, ...res });
    }
  },
  addTag: async (txHash, tag) => {
    await TransactionService.addTag(txHash, tag);
  },
  updateTag: async (tagId, tag) => {
    await TransactionService.updateTag(tagId, tag);
    get().fetchTags(true);
    get().fetchTxs(true);
  },
  fetchTags: async (forceReload) => {
    if (get().tags.length === 0 || forceReload) {
      const tags = await TransactionService.getTags();
      set({ tags });
    }
  },
  removeTag: async (txHash, tag) => {
    await TransactionService.removeTag(txHash, tag);
  },
  addTagName: async (tag, walletId) => {
    const resp = await TransactionService.addTagName(tag, walletId);
    if (typeof resp.data === 'number') {
      const id = resp.data as number;
      set({ tags: get().tags.concat({ tag, walletId, tagId: id }) });
      return id;
    }
    return undefined;
  },
  removeTagName: async (tag) => {
    await TransactionService.removeTagName(tag);
  },
  addressBooks: undefined,
  addressNameMap: {},
  nameAddressMap: {},
  fetchAddressBooks: async (forceReload) => {
    if (get().addressBooks === undefined || forceReload) {
      const addressBooks = await TransactionService.getAddressBooks();
      set({ addressBooks });

      const addressNameMap = addressBooks.reduce((prev: Record<string, string[]>, curr) => {
        const value = prev[curr.address] ?? [];
        const updatedValue = [...value, curr.tag];
        return { ...prev, [curr.address]: updatedValue };
      }, {});

      const nameAddressMap = Object.fromEntries(
        addressBooks.map((ab) => [ab.tag, ab.address]),
      );
      set({ addressNameMap, nameAddressMap });
    }
  },
  addAddressBook: async (address, tag, addressBookId?: number, walletId?: number) => {
    await TransactionService.addAddressBook(address, tag, addressBookId, walletId);
    await get().fetchAddressBooks(true);
  },
  removeAddressBook: async (addressBookId) => {
    await TransactionService.removeAddressBook(addressBookId);
    await get().fetchAddressBooks(true);
  },
  fetchNotes: async (forceReload: boolean) => {
    const existingNote = get().notes;
    if (existingNote.length === 0 || forceReload) {
      const notes = await TransactionService.getNotes();
      set({ notes });
    }
  },
  addOrEditNote: async (txHash: string, note: string, walletId: number) => {
    await TransactionService.addOrEditNote(txHash, note, walletId);
    await get().fetchNotes(true);
  },
  removeNote: async (txHash: string, walletId: number) => {
    await TransactionService.removeNote(txHash, walletId);
  },
}));

export const gasFeeTag: Tag = { tag: 'Gas Fee', tagId: -1, walletId: -1 };
export const rewardTag: Tag = { tag: 'Reward', tagId: -2, walletId: -1 };
