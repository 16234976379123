import { Box, createStyles, makeStyles, Typography } from '@material-ui/core';
import { Button } from 'elements';
import React from 'react';
import { SignUpRoute } from 'routes';
import { useStore as useUserStore } from 'store/zustand/User';

const useStyles = makeStyles((theme) => createStyles({
  root: {
    display: 'flex',
    flexDirection: 'column',
    gap: '20px',
    alignItems: 'center',
    color: theme.palette.grey[600],
    textAlign: 'center',
  },
}));

const AccountRemoved = () => {
  const classes = useStyles();
  const { logout } = useUserStore();
  const handleSignOut = () => {
    logout(SignUpRoute);
  };

  return (
    <Box className={classes.root}>
      <Typography variant="h3">Your Coinbag account is no longer available.</Typography>
      <Typography variant="h5">
        If you would like to use Coinbag again, you will need to create a new account.
      </Typography>
      <Button size="sm" onClick={handleSignOut}>
        <Typography variant="h6">Log out and create a new account</Typography>
      </Button>
    </Box>
  );
};

export default AccountRemoved;
