/**
 * Format a number (especially one with large values) to a pretty format e.g. 100K, 1M, 1T, etc.
 */
export const prettyNumber = (number: number, options?: Intl.NumberFormatOptions) => {
  if (number < 1 && number > -1) return significantNumber(number, options);

  const formatter = Intl.NumberFormat('en', { notation: 'compact', ...options });
  return formatter.format(number);
};

/**
 * Format a number (especially one with small values) to show its significant digits.
 */
export const significantNumber = (number: number, options?: Intl.NumberFormatOptions) => {
  const fractionDigits = number < 1 && number > -1 && number !== 0 ? Math.ceil(-Math.log10(Math.abs(number))) : 0;
  return number.toLocaleString('en-GB', {
    minimumFractionDigits: fractionDigits,
    maximumFractionDigits: number > 1 ? (options?.maximumFractionDigits ?? undefined) : undefined,
  });
};

// eslint-disable-next-line no-bitwise
export const numDigits = (num: number) => (Math.log10((num ^ (num >> 31)) - (num >> 31)) | 0) + 1;

export const shortenNumber = (numberToShorten: number, digits: number) => {
  const lookup = [
    { value: 1, symbol: '' },
    { value: 1e3, cutoff: 1e5, symbol: 'k' },
    { value: 1e6, symbol: 'M' },
    { value: 1e9, symbol: 'G' },
  ];

  const rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
  // eslint-disable-next-line max-len
  const item = lookup.slice().reverse().find((lookupItem) => numberToShorten >= (lookupItem.cutoff !== undefined ? lookupItem.cutoff : lookupItem.value));
  return item ? (numberToShorten / item.value).toFixed(digits).replace(rx, '$1') + item.symbol : '0';
};
