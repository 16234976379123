import { ThemeProvider } from '@material-ui/core';
import axios from 'axios';
import axiosRetry, { exponentialDelay } from 'axios-retry';
import Logger from 'js-logger';
import { Features } from 'models/Features';
import React from 'react';
import { Provider as ReduxProvider } from 'react-redux';
import { Redirect, Route, Switch, withRouter } from 'react-router-dom';

import { DashboardLayout } from './components/dashboard';
import { FullPageLayout } from './components/dashboard/FullPageLayout';
import { ConsentModal } from './components/dashboard/TermAndCondition';
import { HomePageLayout } from './components/Layout/HomePageLayout';
import { PolicyLayout } from './components/Layout/PolicyLayout';
import ScrollToTop from './components/ScrollToTop';
import { WalletReportingLayout } from './components/WalletReportingLayout';
import { Web3OnboardProvider } from './components/Web3OnboardProvider';
import * as ResponsivePages from './pages/responsive';
import ComingSoon from './pages/responsive/ComingSoon';
import NotFound from './pages/responsive/NotFound/NotFound';
import { fixedLandingMap } from './pages/responsive/ReferralLanding/ReferralLanding';
import Seo from './pages/responsive/Seo';
import {
  AccessRevokedRoute,
  AccountDeleteRoute,
  AccountDetailsRoute,
  BatchPayrollRoute,
  CreateBundleRoute,
  DashboardElementsRoute,
  DashboardInterestRoute,
  DashboardRoute,
  DashboardUstRoute,
  DepositRoute,
  DisclaimerRoute,
  ForgetPasswordRoute,
  HomeRoutes,
  InterestSettingRoute,
  InvitationRoute,
  MaintenanceRoute,
  NotificationsAndRequestsRoute,
  OurStoryRoute,
  PageTitles,
  PermissionsRoute,
  PortfolioSettingRoute,
  PrivacyPolicyRoute,
  RebalanceSettingRoute,
  ReportingRoute,
  ResetPasswordRoute,
  ShowroomRoute,
  SignInRoute,
  SignUpIndividualInvestorsRoute,
  SignUpInstitutionalInvestorsRoute,
  SignUpPartnerRoute,
  SignUpRoute,
  TeamAccessRoute,
  TermsOfUseRoute,
  TrackMyAddressRoute,
  WalletSettingRoute,
  WhyCoinbagRoute,
  WithdrawRoute,
} from './routes';
import sessionService from './services/session.service';
import translationService from './services/translation.service';
import { languageChanged } from './store/language/languageSlice';
import { store } from './store/store';
import { translationsLoaded } from './store/translations/translationsSlice';
import { useStore as useFeatureStore } from './store/zustand/Feature';
import { useStore as useUserStore } from './store/zustand/User';
import { DashboardTheme, LightTheme } from './theme';
import { isWalletReporting } from './utils/domain';

// Set global retry for axios
axiosRetry(axios, { retryDelay: exponentialDelay });

const noBootstrapPages = [
  ResponsivePages.AccessRevoked,
  ResponsivePages.AccountDeleted,
  ResponsivePages.AccountDetails,
  ResponsivePages.CreateOrEditBundle,
  ResponsivePages.DashboardElements,
  ResponsivePages.DashboardUst,
  ResponsivePages.Deposit,
  ResponsivePages.Disclaimer,
  ResponsivePages.EditCoin,
  ResponsivePages.RebalanceSetting,
  ResponsivePages.Forgot,
  ResponsivePages.Home,
  ResponsivePages.IndividualInvestors,
  ResponsivePages.InstitutionalInvestors,
  ResponsivePages.InterestPage,
  ResponsivePages.InterestSetting,
  ResponsivePages.KycAdmin,
  ResponsivePages.Maintenance,
  ResponsivePages.NotFound,
  ResponsivePages.OurStory,
  ResponsivePages.PartnerProducts,
  ResponsivePages.PortfolioSetting,
  ResponsivePages.WalletSetting,
  ResponsivePages.PrivacyPolicy,
  ResponsivePages.Reset,
  ResponsivePages.Invitation,
  ResponsivePages.Showroom,
  ResponsivePages.SignIn,
  ResponsivePages.SignUpPage,
  ResponsivePages.TermsOfUse,
  ResponsivePages.Toolbox,
  ResponsivePages.ViewCoins,
  ResponsivePages.ViewTranslations,
  ResponsivePages.ViewWorkItems,
  ResponsivePages.Withdraw,
  ResponsivePages.WhyCoinbag,
  ResponsivePages.Reporting,
  ResponsivePages.TeamAccess,
  ResponsivePages.Permissions,
  ResponsivePages.Dashboard,
  ResponsivePages.TrackMyAddress,
  ResponsivePages.BatchPayroll,
  ResponsivePages.NotificationsAndRequests,
] as PageParameter[];

// TODO: Add more routes
// Page routes that need dashboard layout
const dashboardPageRoutes = [
  AccessRevokedRoute,
  AccountDetailsRoute,
  TeamAccessRoute,
  PermissionsRoute,
  DashboardElementsRoute,
  DashboardInterestRoute,
  DashboardRoute,
  DashboardUstRoute,
  DepositRoute,
  InterestSettingRoute,
  PortfolioSettingRoute,
  RebalanceSettingRoute,
  WalletSettingRoute,
  WithdrawRoute,
  ReportingRoute,
  BatchPayrollRoute,
  NotificationsAndRequestsRoute,
];

const homePageLayoutRoutes = [
  ...HomeRoutes,
  ForgetPasswordRoute,
  MaintenanceRoute,
  OurStoryRoute,
  SignInRoute,
  SignUpIndividualInvestorsRoute,
  SignUpInstitutionalInvestorsRoute,
  SignUpPartnerRoute,
  SignUpRoute,
  WhyCoinbagRoute,
  ResetPasswordRoute,
  InvitationRoute,
];

const policyLayoutRoutes = [
  DisclaimerRoute,
  PrivacyPolicyRoute,
  TermsOfUseRoute,
  WhyCoinbagRoute,
];

const fullPageRoutes = [
  AccountDeleteRoute,
  CreateBundleRoute,
  ShowroomRoute,
];

const walletReportingRoutes = [
  ...TrackMyAddressRoute,
];

axios.interceptors.response.use((response) => response, (error) => {
  if (!error || !error.config) return Promise.reject(error);

  const apiCallsToCheckForAuthentication = ['/api/session/check'];
  if (apiCallsToCheckForAuthentication.includes(error?.config?.url) && !window.location.href.includes('/login')) {
    if (error.response.status === 401) {
      const encodedURL = encodeURIComponent(window.location.pathname);
      window.location.href = `/login?redirect=${encodedURL}`;
    }
  }
  return Promise.reject(error);
});

export interface PageParameter {
  routePath: string;
  feature?: keyof Features;
  redirect?: keyof Features;
  loginRequired?: boolean;
  allowedForAccessRevokedUser?: boolean;
  adminRequired?: boolean;
  logoutRequired?: boolean;
  displayName?: string;
  title?: string;
  hideFooter?: boolean;
}

function App() {
  const [user, fetchUser] = useUserStore((state) => [state.user, state.fetchUser]);
  const [features, fetchFeatures] = useFeatureStore((state) => [state.features, state.fetchFeatures]);

  const [canRender, setCanRender] = React.useState(false);
  const [maintenanceMode] = React.useState(false);
  const [sessionCheck, setSessionCheck] = React.useState(false);

  const isDesktop = window.matchMedia('(min-width: 768px)').matches;

  React.useEffect(() => {
    (async () => {
      try {
        await Promise.all([fetchUser(true), fetchFeatures(true)]);
      } finally {
        setCanRender(true);
      }
    })();
    const isConsoleLogEnabled = process.env.REACT_APP_CONSOLE_LOG_ENABLED === 'true';
    if (isConsoleLogEnabled) {
      Logger.useDefaults();
    } else {
      Logger.setLevel(Logger.OFF);
    }

    const languageOverride = new URLSearchParams(window.location.search).get('lang');
    if (languageOverride) {
      store.dispatch(languageChanged(languageOverride));
      translationService.getAllTranslations().then((translations) => {
        store.dispatch(translationsLoaded(translations));
      });
    }
  }, []);

  React.useEffect(() => {
    const interval = setInterval(() => {
      if (sessionCheck) { sessionService.check(); }
    }, 60000);

    return () => clearInterval(interval);
  }, [sessionCheck]);

  React.useEffect(() => {
    if (user !== undefined) {
      setSessionCheck(true)
    } else {
      setSessionCheck(false)
    }
  }, [user])

  const routing = (item: PageParameter, route: string) => {
    if (maintenanceMode && route !== MaintenanceRoute) {
      return (<Redirect to={MaintenanceRoute} />);
    } if (!maintenanceMode && route === MaintenanceRoute) {
      return (<Redirect to="/" />);
    }
    if (item.loginRequired && (!user || user.isTmp)) {
      return (<Redirect to={{ pathname: SignInRoute, search: window.location.search }} />);
    }
    if (!item.allowedForAccessRevokedUser && user && user.isActive === false) {
      return (<Redirect to={{ pathname: AccessRevokedRoute, search: window.location.search }} />);
    }
    if (item.adminRequired && user?.role?.indexOf('admin') === -1) {
      return (<Redirect to={{ pathname: SignInRoute, search: window.location.search }} />);
    }
    if (item.logoutRequired && user && !user.isTmp && !(user.isCustodial === null || user.isCustodial === undefined)) {
      return (<Redirect to={{ pathname: DashboardRoute, search: window.location.search }} />);
    }
    if (user && !user.isTmp && user.isActive && user.isCustodial != null && route === '/') {
      return (<Redirect to={{ pathname: DashboardRoute, search: window.location.search }} />);
    }
    if (features && item.redirect && features[item.redirect]) {
      return (<ComingSoon route={route} />);
    }

    const Item = item as unknown as () => JSX.Element;
    return (
      <>
        <Seo
          route={route}
          pageTitle={PageTitles[route]}
        />

        <ScrollToTop />

        <Item />
      </>
    );
  };

  // TODO TechDebt Small Low, Remove dynamic imports to allow for static analysis and bundle splitting.
  // Replace with index and re-export
  const pages: PageParameter[] = React.useMemo(() => {
    const result = importAll(require.context('./pages/responsive/', false, /\.js$/))
      .map((r) => (r as { default: PageParameter }).default);
    result.push(...noBootstrapPages);
    if (isDesktop) {
      result.push(
        ...importAll(require.context(
          './pages/desktop/', false, /\.js$/)).map((r) => (r as { default: PageParameter }).default),
      );
    } else {
      result.push(
        ...importAll(require.context(
          './pages/mobile/', false, /\.js$/)).map((r) => (r as { default: PageParameter }).default),
      );
    }
    if (isWalletReporting()) {
      return result.filter((page: PageParameter) => page.displayName === ResponsivePages.TrackMyAddress.displayName);
    }
    return result;
  }, [isDesktop]);

  if (!canRender) {
    return <></>;
  }

  return (
    <ReduxProvider store={store}>
      <ThemeProvider theme={LightTheme}>
        <Switch>
          {pages.filter((item) => (item.feature && features ? features[item.feature] : true))
            .map((item, i) => [item.routePath].flat(2).map((route, index) => (
              <Route
                key={Number.parseInt(i.toString() + index.toString(), 10)}
                path={route}
                exact
                render={() => (dashboardPageRoutes.includes(route) ? (
                  <Web3OnboardProvider>
                    <ThemeProvider theme={DashboardTheme}>
                      <DashboardLayout
                        route={route}
                        item={item}
                      >
                        {routing(item, route)}

                        {/* todo lazy load */}
                        {item.loginRequired && user && !user.consentTermsAndConditions ? <ConsentModal
                          showModal={!user.consentTermsAndConditions}
                          setShowModal={() => { }}
                        /> : null}
                      </DashboardLayout>
                    </ThemeProvider>
                  </Web3OnboardProvider>
                ) : fullPageRoutes.includes(route) ? (
                  <ThemeProvider theme={DashboardTheme}>
                    <FullPageLayout
                      route={route}
                      hideFooter={item.hideFooter}
                    >
                      {routing(item, route)}
                    </FullPageLayout>
                  </ThemeProvider>
                ) : homePageLayoutRoutes.includes(route) && !isWalletReporting() ? (
                  <Web3OnboardProvider>
                    <ThemeProvider theme={DashboardTheme}>
                      <HomePageLayout isHomePage={HomeRoutes.includes(route)}>
                        {routing(item, route)}
                      </HomePageLayout>
                    </ThemeProvider>
                  </Web3OnboardProvider>
                ) : policyLayoutRoutes.includes(route) ? (
                  <ThemeProvider theme={DashboardTheme}>
                    <PolicyLayout>
                      {routing(item, route)}
                    </PolicyLayout>
                  </ThemeProvider>
                ) : walletReportingRoutes.includes(route) ? (
                  <ThemeProvider theme={DashboardTheme}>
                    <WalletReportingLayout>
                      {routing(item, route)}
                    </WalletReportingLayout>
                  </ThemeProvider>
                ) : (
                  routing(item, route)
                ))}
              />
            )))}

          {Object.keys(fixedLandingMap).map((key) => (
            <Route
              key={key}
              exact
              path={key}
              component={ResponsivePages.ReferralLanding}
            />
          ))}

          <Route
            exact
            path="/rid/:rid"
            component={ResponsivePages.ReferralLanding}
          />

          <Route
            render={() => (
              <ThemeProvider theme={DashboardTheme}>
                <HomePageLayout>
                  <NotFound />
                </HomePageLayout>
              </ThemeProvider>
            )}
          />
        </Switch>
      </ThemeProvider>
    </ReduxProvider>
  );
}

const importAll = (r: __WebpackModuleApi.RequireContext) => r.keys().map(r);

export default withRouter(App);
