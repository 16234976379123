import { Box, Drawer, Hidden, makeStyles, withStyles } from '@material-ui/core';
import React, { ReactNode, useMemo, useState } from 'react';

import { useStore as useUserStore } from '../../store/zustand/User';
import { DashboardHeader } from './DashboardHeader';
import { DashboardSidebar } from './DashboardSidebar';

type DashboardLayoutProps = {
  route: string
  children: ReactNode
  item: { title?: string }
}

const useStyles = makeStyles({
  drawerPaper: {
    border: 0,
    zIndex: 0,
  },
  mobileDrawer: { width: '100%' },
});

const Container = withStyles({
  root: {
    position: 'relative',
    display: 'flex',
  },
})(Box);

const SidebarBox = withStyles((theme) => ({
  root: {
    boxShadow: '4px 0px 32px rgba(0, 0, 0, 0.04)',

    [theme.breakpoints.down('md')]: {
      zIndex: 110,
    },
    [theme.breakpoints.up('lg')]: {
      width: 256,
      flexShrink: 0,
    },
  },
}))(Box);

const ContentBox = withStyles(() => ({
  root: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    background: 'white',
    width: '100%',
    minWidth: 0,
    minHeight: '100vh',
  },
}))(Box);

export const DashboardLayout = ({ route, children, item }: DashboardLayoutProps) => {
  const classes = useStyles();
  const [logout] = useUserStore((state) => [state.logout]);
  const onSignOut = () => {
    logout();
  };

  const [sidebarOpen, setSidebarOpen] = useState(false);
  const toggleSidebar = () => setSidebarOpen(!sidebarOpen);

  const sidebar = useMemo(() => (
    <DashboardSidebar currentRoute={route} toggleSidebar={toggleSidebar} onSignOut={onSignOut} />
  ), [route, toggleSidebar, onSignOut]);

  return (
    <Container>
      <SidebarBox>
        <Hidden lgUp>
          <Drawer
            classes={{ paper: classes.mobileDrawer }}
            variant="temporary"
            anchor="left"
            open={sidebarOpen}
            onClose={toggleSidebar}
            ModalProps={{ keepMounted: true }}
          >
            {sidebar}
          </Drawer>
        </Hidden>
        <Hidden mdDown>
          <Drawer classes={{ paper: classes.drawerPaper }} variant="permanent" open>
            {sidebar}
          </Drawer>
        </Hidden>
      </SidebarBox>
      <ContentBox>
        <DashboardHeader route={route} toggleSidebar={toggleSidebar} title={item.title} />
        {children}
      </ContentBox>
    </Container>
  );
};
