import { Box, makeStyles, Typography } from '@material-ui/core';
import React, { FC } from 'react';
import SVG from 'react-inlinesvg';
import { MasterCoin } from 'services/types/coin';
import { palette } from 'theme';

export type ProtocolCoin = MasterCoin & {
  apy: number;
  avgApy: number;

  // Whether the coin is already in the user's current portfolio
  isBundled: boolean;
};

type ProtocolCoinDetailsProps = {
  className?: string;
  coins: ProtocolCoin[];
};

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr 1fr',
    alignItems: 'center',

    gap: 12,
    padding: 12,

    [theme.breakpoints.down('sm')]: {
      gridTemplateColumns: '1fr',
    },
  },

  portfolio: {
    width: 20,
    height: 20,
    fill: theme.palette.grey['600'],
  },
  coin: {
    display: 'flex',
    alignItems: 'center',
    gap: 6,
  },
  ticker: { width: '32px' },
  apy: { display: 'flex', gap: 4 },

  subtitle: { color: theme.palette.grey['600'], fontSize: 12 },
}));

const ProtocolCoinDetails: FC<ProtocolCoinDetailsProps> = ({ className, coins }) => {
  const classes = useStyles();

  return (
    <Box className={`${classes.root} ${className}`}>
      <Typography variant="h4">Supported tokens</Typography>
      <Typography className={classes.subtitle}>
        <SVG className={classes.portfolio} src="/icons/dashboard/ic_portfolio.svg" /> = in your target portfolio
      </Typography>
      <Typography className={classes.subtitle}>
        30-Day Avg. APY | <Box display="inline" color={palette.green['500']}>Current APY</Box>
      </Typography>

      {coins.map((c, index) => (
        <Box key={index} className={classes.coin}>
          <SVG src={c.icon ?? '/icons/coin_icon/fallback-1.svg'} width={16} height={16} />
          <Typography variant="h6" className={classes.ticker}>{c.ticker}</Typography>
          <Box className={`${classes.apy} ${classes.subtitle}`}>
            <Box width="32px">{c.avgApy.toFixed(1)}%</Box>
            |
            <Box width="32px" color={palette.green['500']}>{c.apy.toFixed(1)}%</Box>
          </Box>
          {c.isBundled && (<SVG src="/icons/dashboard/ic_portfolio.svg" width={12} height={12} />)}
        </Box>
      ))}
    </Box>
  );
};

ProtocolCoinDetails.displayName = 'ProtocolCoinDetails';
export { ProtocolCoinDetails };
