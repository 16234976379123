import { Box, createStyles, makeStyles, Theme, useMediaQuery, useTheme } from '@material-ui/core';
import { Typography } from 'elements';
import React from 'react';
import { palette } from 'theme';

const useStyles = makeStyles<Theme, Partial<OurPartnerProps>>((theme) => createStyles({
  root: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    rowGap: 40,
    columnGap: 24,
    flexWrap: 'wrap',
    position: 'relative',
    '&::before': {
      content: '" "',
      width: 1260,
      height: 700,
      left: '50%',
      top: '50%',
      transform: 'translateX(-50%) translateY(-50%) translateZ(0)',
      position: 'absolute',
      background: ({ color }) => (`radial-gradient(30% 30% at 50% 50%, ${color === 'blue' ? 'rgba(110, 158, 254, 0.315)' : color === 'purple' ? 'rgba(96, 102, 243, 0.168)' : 'rgba(46, 255, 218, 0.189)'} 0%, rgba(255, 255, 255, 0) 100%)`),
      filter: 'blur(70px)',
      backfaceVisibility: 'hidden',
      perspective: 1000,
      [theme.breakpoints.down('sm')]: {
        width: 700,
        height: 700,
      },
    },
    [theme.breakpoints.down('sm')]: {
      rowGap: 16,
      columnGap: 16,
      display: 'grid',
      gridTemplateColumns: 'repeat(3, 1fr)',
      gridAutoFlow: 'row',
    },
    [theme.breakpoints.down('md')]: {
      rowGap: 24,
    },
  },
  title: {
    zIndex: 1,
    fontSize: 44,
    lineHeight: '60px',
    fontWeight: 700,
    color: theme.palette.grey[700],
    width: '100%',
    textAlign: 'center',
    marginBottom: 4,
    '& em': {
      color: ({ color }) => theme.palette[color || 'green'][600],
      fontStyle: 'normal',
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: 24,
      lineHeight: '44px',
      textAlign: 'left',
      gridColumn: '1 / all',
    },
  },
  card: {
    width: 244,
    objectFit: 'none',
    height: 150,
    borderRadius: 16,
    background: 'white',
    zIndex: 1,
    boxShadow: '0px 4px 44px rgba(144, 156, 189, 0.2)',
    userDrag: 'none',
    userSelect: 'none',
    [theme.breakpoints.down('sm')]: {
      height: 80,
      width: '100%',
      objectFit: 'scale-down',
    },
  },

  more: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',

    [theme.breakpoints.down('sm')]: {
      gridColumn: '2 / span 2',
    },

    '& p': {
      color: theme.palette.grey[600],
      fontSize: 24,
      lineHeight: '180%',

      [theme.breakpoints.up('md')]: {
        fontSize: 28,
        lineHeight: '160%',
      },
    },
  },
}));

interface OurPartnerProps {
  color?: keyof Omit<typeof palette, 'primary' | 'error'>;
  title?: React.ReactNode;
}

const mobileImages = ['Cybavo', 'Fahmai', 'CountryGroup', 'MoonPay', 'Atato', 'Pi', 'Elevate'];
const images = ['CountryGroup', 'Cybavo', 'MoonPay', 'Atato', 'Pi', 'Elevate', 'Fahmai'];
const gridCol = ['1 / span 2', '3', '1', '2 / span 2', '1 / span 2', '3', '1'];
const OurPartner = ({ color, title }: OurPartnerProps) => {
  const classes = useStyles({ color });
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const card = React.useCallback((img: string, index: number) => (
    <img
      key={img}
      className={classes.card}
      style={{ gridColumn: gridCol[index] }}
      alt={img}
      src={`/images/marketing/partner/${img}@1x.png`}
      srcSet={`/images/marketing/partner/${img}@1x.png 1x, /images/marketing/partner/${img}@2x.png 2x, /images/marketing/partner/${img}@3x.png 3x`}
    />
  ), []);

  return (
    <Box className={classes.root}>
      {title && (
        <Typography variant="h2" className={classes.title}>
          {title}
        </Typography>
      )}

      {(isMobile ? mobileImages : images).map((image, index) => card(image, index))}
      <Box className={`${classes.card} ${classes.more}`}>
        <Typography><b><em>& many</em></b> more</Typography>
      </Box>
    </Box>
  );
};

export default OurPartner;
