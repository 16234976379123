import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { Coin } from '../../models';

type PortfolioState = {
  coins: Coin[]
  peggedCoins: Coin[]
}

const initialState: PortfolioState = { coins: [], peggedCoins: [] };

export const portfolioSlice = createSlice({
  name: 'portfolio',
  initialState,
  reducers: {
    updateCoins: (state: PortfolioState, action: PayloadAction<{ coins: Coin[], peggedCoins: Coin[] }>) => ({
      ...state,
      coins: action.payload.coins,
      peggedCoins: action.payload.peggedCoins,
    }),
  },
});

export const { updateCoins } = portfolioSlice.actions;
