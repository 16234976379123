import { Box, createStyles, makeStyles, useMediaQuery } from '@material-ui/core';
import { Loading } from 'components';
import { Button, PageAction, Typography } from 'elements';
import ManagePortfolioNavBar from 'elements/ManagePortfolioNavBar';
import Logger from 'js-logger';
import React, { useEffect, useState } from 'react';
import SVG from 'react-inlinesvg';
import { RebalanceSettingRoute } from 'routes';
import { Wallet } from 'services/types/wallet';
import { RebalanceConfigMode as RCM } from 'services/types/wallet-enum';
import { WorkItemName } from 'services/types/workItem';
import { useStore as useFeatureStore } from 'store/zustand/Feature';
import { useStore as useWalletStore } from 'store/zustand/Wallet';
import { breakpoints } from 'theme/base';

import { shouldShowRebalancingStatus } from '../../../components/RebalancingStatus';
import RebalancingMethod from './RebalancingMethod';

const useStyles = makeStyles((theme) => createStyles({
  root: {
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'start',
    alignItems: 'center',
    width: '100%',
  },
  content: {
    padding: '16px',
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'start',
    alignItems: 'center',
    width: '100%',
    maxWidth: 940,
  },
  bottomNavigation: {
    flexGrow: 1,
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    gap: 8,
  },
  errorMessage: {
    color: theme.palette.red['500'],
    fontSize: 12,
  },
  loading: {
    width: '100%',
    position: 'absolute',
    top: 0,
    left: 0,
    zIndex: 2,
    background: 'white',
  },
}));

const normalizeMode = (wallet: Wallet) => wallet.rebalancingMode?.replace('-deposit', '') ?? '';
const denormalizeMode = (mode: string, rebalanceOnDeposit: boolean) => `${mode}${rebalanceOnDeposit ? '-deposit' : ''}`;

const RebalancingStatus = React.lazy(() => import('components/RebalancingStatus'));
const PortfolioConfirmationModal = React.lazy(() => import('../PortfolioConfirmationModal/PortfolioConfirmationModal'));

const RebalanceSetting = () => {
  const classes = useStyles();
  const [
    wallet,
    isDefi,
    rebalanceOnDeposit,
    updateRebalancingMethod,
    fetchWallet,
    readyWallet,
    rebalancingStatus,
    activeWalletBalanceNonSwappable,
    fetchRebalancingStatus,
    fetchActiveWalletBalance,
  ] = useWalletStore((state) => [
    state.wallet,
    state.isDefi,
    state.rebalanceOnDeposit,
    state.updateRebalancingMethod,
    state.fetchWallet,
    state.readyWallet,
    state.rebalancingStatus,
    state.activeWalletBalanceNonSwappable,
    state.fetchRebalancingStatus,
    state.fetchLatestWalletBalances,
  ]);
  const [features, fetchFeatures, findPendingAction] = useFeatureStore((state) => [
    state.features, state.fetchFeatures, state.findPendingAction]);

  useEffect(() => {
    if (readyWallet) {
      (async () => {
        fetchWallet(false);
      })();
    }
  }, [readyWallet]);

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    (async () => {
      await Promise.allSettled([fetchRebalancingStatus(), fetchActiveWalletBalance(), fetchFeatures()]);
      setLoading(false);
    })();
  }, []);

  const [mode, setMode] = useState('');
  useEffect(() => {
    if (!wallet) return;
    setMode(normalizeMode(wallet));
  }, [wallet, setMode]);

  const onSelectMethod = (selectedMode: RCM) => {
    if (![RCM.dynamic, RCM.static, RCM.manual].includes(selectedMode)) return;
    setMode(selectedMode === RCM.dynamic ? 'dynamic' : selectedMode === RCM.manual ? 'manual' : 'static');
  };

  const [autoRebalance, setAutoRebalance] = useState(false);
  useEffect(() => {
    if (!wallet) return;
    setAutoRebalance(rebalanceOnDeposit);
  }, [wallet, setAutoRebalance]);

  const [showError, setShowError] = useState(false);
  const [showConfirmation, setShowConfirmation] = useState(false);

  const saveRebalancingMode = () => {
    setShowError(false);
    setLoading(true);
    const denormalizedMode = denormalizeMode(mode, autoRebalance);
    updateRebalancingMethod(denormalizedMode)
      .then(async () => {
        setShowConfirmation(true);
        fetchFeatures(true);
        fetchWallet(true);
        setLoading(false);
      })
      .catch((e) => {
        Logger.log(e);
        setShowError(true);
      });
  };

  const isAwaitingApproval = React.useMemo(
    () => (findPendingAction(WorkItemName.SetRebalancingMode)?.length ?? 0) > 0,
    [features],
  );

  const isMobile = useMediaQuery(breakpoints.down('sm'));

  return (
    <>
      {readyWallet && (<ManagePortfolioNavBar hasNonSwappable={(activeWalletBalanceNonSwappable || []).length > 0} />)}
      {!readyWallet && <Loading className={classes.loading} />}
      <Box className={classes.root}>
        <Box className={classes.content}>
          {shouldShowRebalancingStatus(rebalancingStatus) && rebalancingStatus !== 'reporting-address' ? (
            <React.Suspense fallback={<></>}>
              <RebalancingStatus status={rebalancingStatus} />
            </React.Suspense>
          ) : (
            <RebalancingMethod
              isDeFi={isDefi}
              onChanged={onSelectMethod}
              autoRebalance={autoRebalance}
              setAutoRebalance={setAutoRebalance}
              nextRebalance={wallet?.nextRebalancingTime ? (
                new Date(wallet?.nextRebalancingTime).toLocaleString('en-US', {
                  year: 'numeric', month: 'short', day: 'numeric', hour12: false, hour: '2-digit', minute: '2-digit',
                })
              ) : undefined}
            />
          )}
        </Box>
      </Box>
      {showConfirmation && (
        <React.Suspense fallback={<></>}>
          <PortfolioConfirmationModal
            showSelectModal={showConfirmation}
            setShowSelectModal={setShowConfirmation}
          />
        </React.Suspense>
      )}
      <PageAction>
        <Box className={classes.bottomNavigation}>
          {showError && (
            <Typography className={classes.errorMessage}>An error occurred, please try again later.</Typography>
          )}
          <Button
            tooltip={isAwaitingApproval ? {
              title: (
                <Box display="flex" gridGap={4} alignItems="center">
                  <SVG width={12} height={12} src="/icons/ic_time.svg" />
                  <Typography variant="h6" paletteColor={600}>Previous change is awaiting for approval</Typography>
                </Box>
              ),
              enterTouchDelay: 1,
              placement: 'top-end',
              arrow: true,
            } : undefined}
            fullWidth={isMobile}
            variant="primary"
            onClick={saveRebalancingMode}
            disabled={loading || isAwaitingApproval || (wallet?.registerStep?.rebalance && (!wallet || (normalizeMode(wallet) === mode && rebalanceOnDeposit === autoRebalance)))}
          >
            {loading ? 'Loading...' : 'Apply'}
          </Button>
        </Box>
      </PageAction>
    </>
  );
};

RebalanceSetting.routePath = RebalanceSettingRoute;
RebalanceSetting.loginRequired = true;
RebalanceSetting.redirect = 'rebalancingSettingRedirect';

export default RebalanceSetting;
