import React, { useCallback, useState } from 'react';

import { sleep } from '../../../common';
import { Button } from '../../../elements';
import AuthService from '../../../services/auth-service';

const GetCodeButton = () => {
  const [sentEmailCode, setSentEmailCode] = useState(false);
  const [inProgress, setInProgress] = useState(false);

  const handleOnClick = useCallback(async () => {
    try {
      setInProgress(true);
      await AuthService.getVerificationCode();
      setSentEmailCode(true);
      setInProgress(false);
      sleep(60000).then(() => setSentEmailCode(false));
    } catch (error) {
      setInProgress(false);
      setSentEmailCode(false);
    }
  }, []);

  return (
    <Button
      shape="square"
      size="noSpace"
      disabled={sentEmailCode || inProgress}
      onClick={handleOnClick}
    >
      {sentEmailCode ? 'Code sent' : 'Get code'}

    </Button>
  );
};

GetCodeButton.displayName = 'GetCodeButton';
export default GetCodeButton;
