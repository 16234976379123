import { Box, createStyles, Hidden, makeStyles, Theme } from '@material-ui/core';
import { Typography } from 'elements';
import { List } from 'elements/List';
import * as React from 'react';
import SVG from 'react-inlinesvg';

const useStyles = makeStyles((theme: Theme) => createStyles({
  root: {
    zIndex: 1,
    display: 'flex',
    gap: 36,
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    color: theme.palette.grey[700],
    '& em': {
      color: theme.palette.green[600],
      fontStyle: 'normal',
    },
    '& h1': {
      fontSize: 44,
      lineHeight: '80px',
      fontWeight: 700,
      [theme.breakpoints.down('sm')]: {
        fontSize: 24,
        lineHeight: '44px',
        textAlign: 'center',
      },
    },
    '& p': {
      fontSize: 20,
      lineHeight: '32px',
      fontWeight: 500,
      color: theme.palette.grey[700],
      [theme.breakpoints.down('sm')]: {
        fontSize: 16,
        lineHeight: '24px',
        textAlign: 'center',
      },
    },
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    gap: 16,
  },
  imageWrapper: {
    padding: 18,
    borderRadius: 36,
    background: 'rgba(255, 255, 255, 0.5)',
    backdropFilter: 'blur(8px)',
    border: '2px solid #F7F8FD',
    marginRight: -400,
    position: 'relative',
    zIndex: 1,
  },
  image: {
    userDrag: 'none',
    userSelect: 'none',
    width: 823,
    height: 503,
    border: '2px solid #F7F8FD',
    borderRadius: 18,
  },
  icon: {
    position: 'absolute',
    '&#a': {
      bottom: -48,
      right: '40%',
      zIndex: 2,
    },
  },
  list: {
    '& p': {
      marginBottom: 4,
      textAlign: 'left',
    },
  },
}));

const OurStory = () => {
  const classes = useStyles();
  return (
    <article className={classes.root}>
      <Box className={classes.content}>
        <Typography variant="h1">Our <em>story</em></Typography>
        <Box>
          <p>With decades of experience in traditional finance, security, and blockchain, we started Coinbag in 2021 with one goal in mind, make it <b>simple & safe to self-manage your digital assets on-chain.</b></p>
          <p>We combine the <b>best risk management</b> and <b>ease-of-use practices</b> from <b>Web 2.0</b> with security and control from <b>Web 3.0</b> in <b>one easy-to-use platform.</b></p>
          <p>The four pillars of Coinbag are:</p>
          <List
            className={classes.list}
            items={[
              <p key={0}>Security</p>,
              <p key={1}>Self-Custody</p>,
              <p key={2}>Risk Management</p>,
              <p key={3}>Ease-of-Use</p>,
            ]}
          />
        </Box>
      </Box>
      <Hidden smDown>
        <Box className={classes.imageWrapper}>
          <img
            fetchpriority="high"
            className={classes.image}
            alt="coinbag dashboard"
            src="/images/marketing/coinbag_dashboard@1x.png"
            srcSet="/images/marketing/coinbag_dashboard@1x.png 1x, /images/marketing/coinbag_dashboard@2x.png 2x, /images/marketing/coinbag_dashboard@3x.png 3x"
          />
          <SVG width={90} height={90} src="/icons/signup/ic_bg_1.svg" className={classes.icon} id="a" />
        </Box>
      </Hidden>
    </article>
  );
};

export default OurStory;
