import React, { Component } from 'react';
import cloneDeep from 'lodash.clonedeep';

export default class SharedStateComponent extends Component {
    constructor(props) {
        super(props);

        if (props.functions){
            this.root = false
            this.name = props.name

            this.updateRootState = props.functions.updateRootState
            this.getRootState = props.functions.getRootState
            this.updateParentState = props.functions._updateSharedState
            this.getParentState = props.functions._getSharedState

            this.getState = () => this.getParentState(this.name)
            this.updateState = (obj) => this.updateParentState({[this.name]:obj})
        }else{
            this.root = true
            this.updateRootState = this.updateRootState.bind(this);
            this.getRootState = this.getRootState.bind(this);
            this.updateState = this.updateRootState.bind(this);
            this.getState = this.getRootState.bind(this);
        }

        this._updateSharedState = this._updateSharedState.bind(this);
        this._getSharedState = this._getSharedState.bind(this);
    }

    getRootState(key) {
        if (!key) {
            return this.state
        } else {
            return this.state[key]
        }
    }

    updateRootState(myObj) {
        function update(stateClone, newState) {
            Object.entries(newState).map(kv => {
                if (stateClone[kv[0]] instanceof Date) {
                    stateClone[kv[0]] = kv[1]
                } else if (typeof stateClone[kv[0]] === 'object' && stateClone[kv[0]] !== null) {
                    update(stateClone[kv[0]], kv[1])
                } else {
                    stateClone[kv[0]] = kv[1]
                }
            })
        };
        const stateClone1 = cloneDeep(this.state);
        update(stateClone1, myObj);
        this.setState(stateClone1);
    }

    _updateSharedState(myObj) {
        if (this.root){
            this.updateRootState(myObj)
        }else{
            this.updateParentState({[this.name]: myObj})
        }
    }

    _getSharedState(key) {
        if (this.root){
            var current = this.state;
            if(key){
                key.split('.').forEach(p => {
                    if(current)
                        current = current[p]
                });
            }
            return current;
        }else{
            if(!key)
                return this.getParentState(this.name)
            else
                return this.getParentState(this.name + "." + key)
        }
    }

    renderWithSharedState() {
        return false;
    }

    render(){
        const renderedElement = this.renderWithSharedState();

        function injectFunctions (element, root){
            if (element && element.props && element.props.children){
                var childrenWithProps = React.Children.map(element.props.children, child => {
                    if (React.isValidElement(child) && child.type.prototype instanceof SharedStateComponent) {
                        var functions = {
                            ...child.props.functions,
                            updateRootState: root.updateRootState,
                            getRootState: root.getRootState,
                            _updateSharedState: root._updateSharedState,
                            _getSharedState: root._getSharedState
                        }
                        return React.cloneElement(child, {
                            ...child.props,
                            functions
                        });
                    }

                    return injectFunctions(child, root);
                });

                if(childrenWithProps.length == 1){
                  childrenWithProps = childrenWithProps[0]
                }

                return React.cloneElement(element, {...element.props, children:childrenWithProps})
            } else {
                return element
            }
        }

        return injectFunctions(renderedElement, this)
    }
}
