import { Paper, PaperProps, Theme, withStyles } from '@material-ui/core';
import CloseRoundedIcon from '@material-ui/icons/CloseRounded';
import ExpandMoreRoundedIcon from '@material-ui/icons/ExpandMoreRounded';
import { Autocomplete, AutocompleteProps } from '@material-ui/lab';
import * as React from 'react';

const AutocompleteComponent = withStyles((theme: Theme) => ({
  option: {
    '&:hover': {
      background: '#EBFFFC',
    },
    '&[data-focus=true]': {
      background: '#EBFFFC',
    },
    '&[aria-selected=true]': {
      background: '#EBFFFC',
    },
    fontSize: '12px',
    borderBottom: `1px solid ${theme.palette.grey[200]}`,
  },
  noOptions: {
    fontSize: '12px',
    padding: '6px 16px',
    margin: 0,
  },
  endAdornment: {
    display: 'flex',
    alignItems: 'center',
    height: '100%',
    top: 0,
  },
  paper: {
    border: `1px solid ${theme.palette.grey[200]}`,
    borderRadius: 8,
  },
  listbox: {
    padding: 0,
    overflowX: 'hidden',
    '& li': {
      borderBottom: `1px solid ${theme.palette.grey[200]}`,
    },
    '& li:last-child': {
      borderBottom: 'none',
    },
  },
  inputRoot: {
    '&[class*="MuiOutlinedInput-root"][class*="MuiOutlinedInput-marginDense"]': {
      padding: '0',
      paddingLeft: '8px',
    },
    '&[class*="MuiOutlinedInput-root"]': {
      padding: '0',
      paddingLeft: '8px',
    },
  },
  groupLabel: {
    top: 0,
    background: '#FAFBFF',
    lineHeight: '40px',
    borderBottom: `1px solid ${theme.palette.grey[200]}`,
  },
}))(Autocomplete);

const CustomPaper = (props: PaperProps) => <Paper elevation={0} {...props} />;

export const LightAutocomplete = <
  T,
  Multiple extends boolean | undefined = undefined,
  DisableClearable extends boolean | undefined = undefined,
  FreeSolo extends boolean | undefined = undefined
>(props: AutocompleteProps<T, Multiple, DisableClearable, FreeSolo>) => (
    <AutocompleteComponent
      {...props as any}
      popupIcon={props.popupIcon ? props.popupIcon : (
        <ExpandMoreRoundedIcon />
      )}
      closeIcon={(
        <CloseRoundedIcon fontSize="small" />
      )}
      PaperComponent={CustomPaper}
    />
  );
