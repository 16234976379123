import debounce from 'lodash.debounce';
import { User, UserEvent, UserType } from 'models';
import { getUserInfo } from 'services';
import authService from 'services/auth-service';
import roleService from 'services/role-service';
import userService from 'services/user-service';
import { create } from 'zustand';

import { disconnectAllWallet } from '../../services/OnboardService';

const DEBOUNCE_TIMEOUT = 1000;

interface UserStore {
  user?: User;
  allowedUsers?: User[];
  teamMembers?: User[];
  // eslint-disable-next-line no-unused-vars
  fetchUser: (forced: boolean) => Promise<User>;
  // eslint-disable-next-line no-unused-vars
  logout: (routeTo?: string, doRedirect?: boolean) => void;
  userEvents?: UserEvent[];
  // eslint-disable-next-line no-unused-vars
  fetchEvents: (forced: boolean) => Promise<UserEvent[]>;
  // eslint-disable-next-line no-unused-vars
  fetchAllowedUsers: (forced: boolean) => Promise<User[]>;
  // eslint-disable-next-line no-unused-vars
  removeUser: (userId: number) => Promise<void>;
}

const fUserForce = debounce(async (get: () => UserStore,
  set: (partial: (UserStore | Partial<UserStore> | ((state: UserStore) => (UserStore | Partial<UserStore>))),
    replace?: (boolean | undefined)) => void): Promise<User> => {
  try {
    const userData = await getUserInfo();

    set({
      user: {
        id: userData.userId.toString(),
        ...userData,
      },
    });

    await disconnectAllWallet();

    return Promise.resolve(userData);
  } catch (e) {
    const a: User = {
      consentTermsAndConditions: false, isActive: false, isReadOnly: false, isTmp: false, secret_2fa: '', userId: 0,
      userType: UserType.Individual, walletInfo: [],
    }
    return Promise.resolve(a);
  }
}, DEBOUNCE_TIMEOUT, { leading: true, trailing: true });

const fUser = (get: () => UserStore,
  set: (partial: (UserStore | Partial<UserStore> | ((state: UserStore) => (UserStore | Partial<UserStore>))),
    replace?: (boolean | undefined)) => void) => {
  if (get().user !== undefined) return Promise.resolve(get().user as User);
  return fUserForce(get, set);
};

export const useStore = create<UserStore>((set, get) => ({
  user: undefined,
  allowedUsers: undefined,
  teamMembers: undefined,
  userEvents: undefined,
  fetchUser: (force: boolean) => (force ? fUserForce(get, set) : fUser(get, set)),
  logout: async (routeTo: string = '/', doRedirect: boolean = true) => {
    await disconnectAllWallet();

    localStorage.removeItem('user');
    sessionStorage.removeItem('userStep');
    if (doRedirect) {
      window.location.href = routeTo;
    } else {
      set({ user: undefined });
    }
  },
  fetchEvents: async (force: boolean): Promise<UserEvent[]> => {
    const events = get().userEvents;
    if (!force && events) return events;

    const response = (await authService.getUserEvents()).data;
    set({ userEvents: response });
    return response;
  },
  fetchAllowedUsers: async (force: boolean): Promise<User[]> => {
    if (!get().allowedUsers || force) {
      const userId = get().user?.userId ?? (await get().fetchUser(false)).userId;
      const data = await userService.getAllowedUsers(userId);
      const users = data.map((u) => ({ ...u } as User));
      const teamMembers = users.filter((u) => u.userType !== UserType.Institutional);
      set({ teamMembers, allowedUsers: users });
    }
    return get().allowedUsers!;
  },
  removeUser: async (userId: number) => {
    const oldState = [...(get().allowedUsers ?? [])];
    const newState = get().allowedUsers?.filter((u) => u.userId !== userId);
    set(() => ({
      allowedUsers: newState,
      teamMembers: newState?.filter((u) => u.userType !== UserType.Institutional),
    }));
    try {
      await roleService.removeUserFromInstitution(userId);
    } catch {
      set({
        allowedUsers: oldState,
        teamMembers: oldState?.filter((u) => u.userType !== UserType.Institutional),
      });
    }
  },
}));
