import { Features } from 'models/Features';
import {
  BatchPayrollRoute,
  DashboardRoute,
  DepositRoute,
  InterestSettingRoute,
  PortfolioSettingRoute,
  RebalanceSettingRoute, ReportingRoute,
  WalletSettingRoute,
  WithdrawRoute,
} from 'routes';

type DashboardLink = {
  label: string;
  iconSrc: string;
  route: string;
  defiOnly?: boolean;
  swappableOnly?: boolean;
  hideForReadOnly?: boolean;
  feature?: keyof Features;
}

export type DashboardMenuItem = {
  label: string;

  iconSrc?: string;
  route?: string;

  selectedRoutes?: string[]
  defiOnly?: boolean
  hideForReadOnly?: boolean;
  feature?: keyof Features;

  sub?: DashboardLink[]
}

export const DashboardMenuItems: DashboardMenuItem[] = [
  { label: 'Dashboard', iconSrc: '/icons/dashboard/ic_home.svg', route: DashboardRoute },
  { label: 'Deposit/Buy Crypto', iconSrc: '/icons/dashboard/ic_deposit.svg', defiOnly: true, route: DepositRoute },
  { label: 'Withdraw', iconSrc: '/icons/dashboard/ic_withdraw.svg', defiOnly: true, route: WithdrawRoute },
  { label: 'Batch send', iconSrc: '/icons/dashboard/ic_payroll.svg', defiOnly: true, route: BatchPayrollRoute, feature: 'batchPayroll', hideForReadOnly: true },
  { label: 'Reporting', iconSrc: '/icons/reporting/ic_reporting.svg', defiOnly: true, route: ReportingRoute, feature: 'reporting' },
  {
    label: 'Manage Portfolio',
    iconSrc: '/icons/dashboard/ic_portfolio.svg',
    defiOnly: true,
    hideForReadOnly: true,
    sub: [
      { label: 'Manage wallets', iconSrc: '/icons/dashboard/ic_manage_wallet.svg', route: WalletSettingRoute, feature: 'manageWallet' },
      { label: 'Wallet settings', iconSrc: '/icons/dashboard/ic_portfolio_setting.svg', route: PortfolioSettingRoute },
      { label: 'Interest earning', iconSrc: '/icons/dashboard/ic_protocol_setting.svg', route: InterestSettingRoute },
      { label: 'Rebalancing', iconSrc: '/icons/dashboard/ic_rebalance.svg', swappableOnly: true, route: RebalanceSettingRoute },
    ],
  },
];
