import {
  Box,
  ListSubheader,
  makeStyles,
  MenuItem,
} from '@material-ui/core';
import { WalletRow } from 'components/dashboard/MultiWallets/WalletRow';
import { Typography } from 'elements';
import { InputWithTitle } from 'elements/InputWithTitle';
import { LightSelect } from 'elements/LightSelect';
import { UserType } from 'models';
import React from 'react';
import { useStore as useUserStore } from 'store/zustand/User';
import { useStore as useWalletStore } from 'store/zustand/Wallet';

const useStyles = makeStyles((theme) => ({
  placeholder: {
    display: 'flex',
    alignItems: 'center',
    gap: 4,
    fontWeight: 400,
    '& .image': {
      width: 20,
      height: 20,
      '& img': {
        width: 20,
        height: 20,
      },
    },
  },
  selectHeader: {
    color: theme.palette.grey[500],
    fontWeight: 400,
    fontSize: 12,
    lineHeight: '20px',
  },
  menuItem: {
    borderBottom: `1px solid ${theme.palette.grey[200]}`,
    '&.Mui-selected': {
      backgroundColor: theme.palette.green[100],
      '&:hover': {
        backgroundColor: theme.palette.green[100],
      },
    },
  },
  subHeader: {
    borderBottom: `1px solid ${theme.palette.grey[200]}`,
    background: '#FAFBFF',
    padding: '10px 16px',
  },
}));

export const SelectWalletField = () => {
  const classes = useStyles();

  const [user] = useUserStore((state) => [state.user]);
  const [wallet, updateActiveWallet] = useWalletStore((state) => [state.wallet, state.updateActiveWallet]);

  const ownWallet = React.useMemo(() => (
    user?.walletInfo.filter((walletInfo) =>
      walletInfo.walletId != null && walletInfo.isOwn && user?.userType === UserType.Individual)
  ), [user]);

  const sharedWallet = React.useMemo(() => (
    user?.walletInfo.filter((walletInfo) =>
      walletInfo.walletId != null && (!walletInfo.isOwn || user?.userType !== UserType.Individual))
  ), [user]);

  const updateSelectedWalletId = async (walletId: number) => {
    await updateActiveWallet(walletId);
  };

  const Placeholder = React.useCallback(() => (
    <Box className={classes.placeholder}>
      <Typography variant="h6" className={classes.selectHeader}>
        Select wallet
      </Typography>
    </Box>
  ), []);

  if ((user?.walletInfo.length ?? 0) < 2) return null;

  return (
    <InputWithTitle title="Selected wallet" fullWidth>
      <LightSelect
        disabled={(user?.walletInfo.length ?? 0) <= 0}
        value={wallet?.walletId ?? 'placeholder'}
        renderValue={() => {
          const walletInfo = wallet?.walletId !== undefined && user?.walletInfo.find(
            (info) => info.walletId === wallet.walletId
          );
          return (walletInfo ? (
            <WalletRow
              selected={false}
              walletInfo={walletInfo}
            />
          ) : <Placeholder />);
        }}
      >
        <MenuItem value="placeholder" style={{ display: 'none' }} />
        {(ownWallet ?? [])?.length > 0 && (
          <ListSubheader className={classes.subHeader}>
            <Typography variant="h6" palette="grey" paletteColor={700}>Your personal wallet</Typography>
          </ListSubheader>
        )}
        {(ownWallet ?? []).map((info) => (
          <MenuItem
            value={info.walletId}
            key={info.walletId}
            className={classes.menuItem}
            onClick={async () => { await updateSelectedWalletId(info.walletId ?? 0); }}
          >
            <WalletRow
              selected={false}
              walletInfo={info}
              key={info.walletId}
            />
          </MenuItem>
        ))}
        {(sharedWallet ?? [])?.length > 0 && (
          <ListSubheader className={classes.subHeader}>
            <Typography variant="h6" palette="grey" paletteColor={700}>Your company's wallet(s)</Typography>
          </ListSubheader>
        )}
        {(sharedWallet ?? []).map((info) => (
          <MenuItem
            value={info.walletId}
            key={info.walletId}
            className={classes.menuItem}
            onClick={async () => { await updateSelectedWalletId(info.walletId ?? 0); }}
          >
            <WalletRow
              selected={false}
              walletInfo={info}
              key={info.walletId}
            />
          </MenuItem>
        ))}
      </LightSelect>
    </InputWithTitle>
  );
};
