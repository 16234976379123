import { makeStyles, Theme } from '@material-ui/core';
import Loader from 'components/Loader';
import { Card } from 'elements';
import { UserType } from 'models';
import React, { ReactNode, useEffect } from 'react';
import { DashboardRoute } from 'routes';
import { useStore as useUserStore } from 'store/zustand/User';

import BackgroundIcons from './Components/BackgroundIcons';

interface SignUpProps {
  type?: `${UserType}`;
  title: ReactNode;
  children?: ReactNode;
}

const useStyles = makeStyles<Theme, Partial<SignUpProps>>((theme) => ({
  root: {
    marginTop: -100,
    paddingTop: 100,
    position: 'relative',
    display: 'grid',
    gridTemplateColumns: '5fr 4fr',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    gap: 32,
    zIndex: 1,
    width: '100%',
    // Prevent grid's children to overflow
    '& > *': { minWidth: 0 },

    [theme.breakpoints.down('sm')]: {
      gridTemplateColumns: '1fr',
      gap: 16,
    },
  },
  formCard: {
    display: 'flex',
    flexDirection: 'column',
    gap: 16,
    background: 'rgba(255, 255, 255, 0.9)',
    backdropFilter: 'blur(8px)',
    backfaceVisibility: 'hidden',
    perspective: 1000,
    transform: 'translateZ(0)',
    borderRadius: 32,
    overflow: 'hidden',
    padding: 36,
    minHeight: ({ type }) => (type === 'partner' ? 800 : 400),
    filter: 'drop-shadow(0px 4px 36px rgba(99, 116, 165, 0.08))',
    boxShadow: '0px 4px 32px rgb(58 60 77 / 4%)',
    [theme.breakpoints.down('sm')]: {
      gap: 20,
      borderRadius: 24,
      padding: 24,
    },
  },
}));

const SignUpForm = React.lazy(() => import('components/authentication/SignUpForm'));
const PartnerSignUpForm = React.lazy(() => import('components/authentication/PartnerSignUpForm'));

const SignUp = ({ type, title, children }: SignUpProps) => {
  const classes = useStyles({ type });

  const { fetchUser, user } = useUserStore();

  useEffect(() => {
    (async () => {
      try {
        await fetchUser(true);
      } finally {
        //
      }
    })();
  }, []);

  if (user && !user.isTmp) window.location.href = DashboardRoute;

  return (
    <>
      <BackgroundIcons />
      <section itemScope id="form" className={classes.root}>
        {title}
        <Card className={classes.formCard}>
          <React.Suspense fallback={<Loader />}>
            {type === 'partner' ? (
              <PartnerSignUpForm color="purple" onSuccess={() => {}} />
            ) : (
              <SignUpForm
                type={type}
                color={type === 'institutional' ? 'blue' : 'green'}
                onSuccess={() => {}}
              />
            )}
          </React.Suspense>
        </Card>
      </section>
      {children}
    </>
  );
};
export default SignUp;
