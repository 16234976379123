import debounce from 'lodash.debounce';
import { DepositEvent } from 'models';
import moment from 'moment';
import { CoinWithBalance, WalletBalanceRowV2 } from 'services/types/coin';
import {
  ActiveId,   AvailableTimes,
  Metadatum,
  Performance,
  Point,
  RebalancingStatus, UsdBalanceTs,
  Wallet,
  WalletInfo,
} from 'services/types/wallet';
// eslint-disable-next-line import/extensions
import UserService from 'services/user-service';
// eslint-disable-next-line import/extensions
import WalletService from 'services/wallet-service';
import { create } from 'zustand';

const DEBOUNCE_TIMEOUT = 5000;

let isFetchLatestWalletBalancesRunning = false;

interface WalletStore {
  readyWallet: boolean;
  wallet?: Wallet;
  walletInfos?: WalletInfo[]
  activeWalletInfo?: WalletInfo;
  isDefi: boolean;
  isRebalancingWallet?: boolean;
  rebalancingMethod: string;
  rebalanceOnDeposit: boolean;
  minMaxTs?: AvailableTimes;
  minPerf?: { point: Point, metadata: Metadatum[] };
  maxPerf?: { point: Point, metadata: Metadatum[] };
  depositEvents?: DepositEvent[];
  deposits?: DepositEvent[];
  withdrawals?: DepositEvent[];
  estimateRebalance?: number;
  rebalancingStatus?: RebalancingStatus;
  walletBalances?: number;
  walletsTs?: Date;
  activeWalletBalance?: CoinWithBalance[];
  activeWalletBalanceNonSwappable?: CoinWithBalance[];
  activeId?: ActiveId;
  latestWalletBalances?: WalletBalanceRowV2[];

  // eslint-disable-next-line no-unused-vars
  updateRebalancingMethod: (newRebalancingMethod: string) => Promise<void>;
  // eslint-disable-next-line no-unused-vars
  fetchWallet: (forceReload: boolean) => Promise<Wallet>;
  // eslint-disable-next-line no-unused-vars
  fetchMinMaxTs: (forceReload: boolean) => Promise<AvailableTimes | undefined>;
  // eslint-disable-next-line no-unused-vars
  fetchPerf: (ts: number[]) => Promise<Performance[]>;
  // eslint-disable-next-line no-unused-vars
  fetchDepositEvents: (forceReload: boolean) => Promise<DepositEvent[]>;
  // eslint-disable-next-line no-unused-vars
  fetchMinMaxPerf: (forceReload: boolean) => Promise<{
    minPerf: { point: Point, metadata: Metadatum[] },
    maxPerf: { point: Point, metadata: Metadatum[] },
  } | undefined>;
  // eslint-disable-next-line no-unused-vars
  fetchEstimateRebalance: (walletId?: number) => Promise<void>;
  // eslint-disable-next-line no-unused-vars
  clearEstimateRebalance: () => Promise<void>;
  // eslint-disable-next-line no-unused-vars
  updateActiveWallet: (walletId?: number, institutionId?: number) => Promise<void>;
  fetchRebalancingStatus: () => Promise<RebalancingStatus>;
  // eslint-disable-next-line no-unused-vars
  fetchLatestWalletBalances: () => Promise<void>;
  // eslint-disable-next-line no-unused-vars
  fetchDepositAndWithdrawalHistory: (forceReload: boolean) =>
    Promise<{ deposits: DepositEvent[], withdrawals: DepositEvent[] }>;
  isAllWallets: () => boolean;
  // eslint-disable-next-line no-unused-vars
  getWalletsUsdBalance: (ts: number) => Promise<UsdBalanceTs>;
  setIsNonCustodian:(walletIds: number[]) => Promise<void>;
}

export const useStore = create<WalletStore>((set, get) => ({
  readyWallet: false,
  isDefi: true,
  rebalancingMethod: '',
  rebalanceOnDeposit: false,
  wallet: undefined,
  walletInfos: undefined,
  minMaxTs: undefined,
  minPerf: undefined,
  maxPerf: undefined,
  performance: undefined,
  estimateRebalance: undefined,
  walletBalances: undefined,
  walletsTs: undefined,
  rebalancingStatus: undefined,
  activeWalletBalance: undefined,
  nonSwappableCoinsInWallet: undefined,
  activeId: undefined,
  latestWalletBalances: undefined,

  fetchLatestWalletBalances: async () => {
    if (isFetchLatestWalletBalancesRunning) return;
    try {
      isFetchLatestWalletBalancesRunning = true;
      const latestWalletBalances = await WalletService.getLatestWalletBalances();
      const activeWalletBalance: CoinWithBalance[] = latestWalletBalances?.map((walletBalance) => ({
        ...walletBalance.coin,
        balance: (walletBalance.qty?.free || 0) + (walletBalance.qty?.locked || 0),
        usdtValue: ((walletBalance.convertedQty?.free || 0) + (walletBalance.convertedQty?.locked || 0)),
      })) ?? [];
      const activeWalletBalanceNonSwappable = activeWalletBalance?.filter((balance) =>
        !!balance.metadata.nonSwappable && balance.usdtValue > 3);

      set({
        walletBalances: latestWalletBalances
          .filter((c) => (((c.convertedQty?.free || 0) + (c.convertedQty?.locked || 0)) > 0))
          .map((c) => (
            ((c.convertedQty?.free || 0) + (c.convertedQty?.locked || 0))
          ) ?? 0).reduce((ps, a) => ps + a, 0),
        walletsTs: latestWalletBalances[0]?.ts ? new Date(latestWalletBalances[0].ts) : undefined,
      });
      set({ activeWalletBalance, activeWalletBalanceNonSwappable, latestWalletBalances });
    } finally {
      isFetchLatestWalletBalancesRunning = false;
    }
  },
  fetchEstimateRebalance: async (walletId?: number) => {
    set(() => ({ estimateRebalance: undefined }));
    const amount = await WalletService.getEstimateRebalance(walletId);
    set(() => ({ estimateRebalance: amount }));
  },
  clearEstimateRebalance: async () => {
    set(() => ({ estimateRebalance: undefined }));
  },
  updateRebalancingMethod: async (newRebalancingMethod: string) => {
    await WalletService.setRebalancingMode(newRebalancingMethod);
    await get().fetchWallet(true);
  },
  fetchWallet: debounce(async (forceReload: boolean = false) => {
    if ((get().wallet && !forceReload)) return get().wallet!;
    const [wallet, walletInfos, activeId] = await Promise.all([
      WalletService.getWallet().then((res) => res.data),
      WalletService.getWallets().then((res) => res.data),
      WalletService.getActiveId().then((res) => res.data),
    ]);

    const isDefi = wallet.exchangeId === undefined || wallet.exchangeId === 4;
    const rebalancingMethod = wallet.rebalanceConfig?.mode;
    const rebalancingOnDeposit = wallet.rebalanceConfig?.onDeposit;
    const isRebalancingWallet = (activeId?.activeWalletId !== undefined && walletInfos
      ? walletInfos.find((info) => info.walletId === activeId.activeWalletId)?.isRebalancingWallet : undefined);
    const activeWalletInfo = walletInfos.find((i) => i.walletId === (activeId?.activeWalletId ?? -1));

    set(() => ({
      readyWallet: true,
      wallet,
      walletInfos: walletInfos,
      isDefi,
      rebalancingMethod,
      activeId,
      isRebalancingWallet,
      rebalanceOnDeposit: rebalancingOnDeposit,
      activeWalletInfo,
    }));

    return wallet;
  }, DEBOUNCE_TIMEOUT, { leading: true }),
  fetchMinMaxTs: async (forceReload: boolean = false) => {
    if (get().minMaxTs && !forceReload) return get().minMaxTs!;
    const { walletId } = await get().fetchWallet(forceReload);
    if (walletId === undefined) return undefined;
    const minMaxTs = await WalletService.getMinMaxTs(walletId).then((res) => res.data);
    set(() => ({ minMaxTs }));
    return minMaxTs;
  },
  fetchDepositEvents: async (forceReload: boolean = false) => {
    if (get().depositEvents && !forceReload) return get().depositEvents!;
    try {
      const depositEventsResponse = await WalletService.getWalletDepositEvents();
      const depositEvents = depositEventsResponse.data.map((deposit) => ({
        ...deposit,
        price: deposit.coins.reduce((total, coin) => total + (coin.price ?? 0), 0),
        ts: moment(deposit.ts).valueOf(),
      }));
      set(() => ({ depositEvents }));
      return depositEvents;
    } catch {
      return [];
    }
  },
  fetchDepositAndWithdrawalHistory: async (forceReload: boolean = false) => {
    if (get().deposits && get().withdrawals && !forceReload) {
      return { deposits: get().deposits!, withdrawals: get().withdrawals! };
    }
    try {
      const depositAndWithdrawalHistoryResponse = await WalletService.getDepositsAndWithdrawalHistory();
      const depositAndWithdrawalHistory = depositAndWithdrawalHistoryResponse.data;

      const deposits = depositAndWithdrawalHistory.filter((event) => event.coins.every((c) => (c.quantity >= 0))) || [];
      const withdrawals = depositAndWithdrawalHistory.filter(
        (event) => event.coins.every((c) => (c.quantity < 0)),
      ) || [];

      set(() => ({ deposits, withdrawals }));
      return { deposits, withdrawals };
    } catch {
      return { deposits: [], withdrawals: [] };
    }
  },
  fetchPerf: async (ts: number[]) => {
    const { walletId } = await get().fetchWallet(false);
    if (walletId === undefined) return [];
    return WalletService.getPerformance(
      walletId,
      WalletService.stringifyTs(ts),
      0,
      false,
    ).then((res) => res.data);
  },
  fetchMinMaxPerf: debounce(async (forceReload: boolean = false) => {
    if (get().maxPerf && get().minPerf && !forceReload) return { minPerf: get().minPerf!, maxPerf: get().maxPerf! };
    const minMaxTs = await get().fetchMinMaxTs(forceReload);
    if (!minMaxTs) return undefined;
    const { minTs, maxTs } = getTs(minMaxTs);
    const performances: Performance[] = await get().fetchPerf([minTs, maxTs]);
    const minPerf = { point: performances[0].points[0], metadata: performances[0].metadata };
    const maxPerf = { point: performances[0].points[1], metadata: performances[0].metadata };
    set(() => ({ minPerf, maxPerf }));
    return { minPerf, maxPerf };
  }, DEBOUNCE_TIMEOUT, { leading: true }),
  updateActiveWallet: async (walletId?: number, institutionId?: number) => {
    await UserService.updateActiveWallet(walletId, institutionId);
    window.location.reload();
  },
  fetchRebalancingStatus: async () => {
    const status = await WalletService.getRebalancingStatus();
    set(() => ({ rebalancingStatus: status }));
    return status;
  },
  isAllWallets: () => (
    get().activeId
      ? get().activeId?.activeInstitutionId !== undefined && get().activeId?.activeWalletId === undefined
      : false
  ),
  getWalletsUsdBalance: (ts: number) => WalletService.getWalletsUsdBalance(ts),
  setIsNonCustodian: WalletService.setIsNonCustodian,
}));
export const getTs = (minMaxTs: AvailableTimes) => {
  const minTs = (minMaxTs.performanceTime !== null && typeof minMaxTs.performanceTime !== 'undefined')
    ? minMaxTs.performanceTime.minTs
    : new Date().getTime() - 86400000;
  const maxTs = (minMaxTs.performanceTime !== null && typeof minMaxTs.performanceTime !== 'undefined')
    ? minMaxTs.performanceTime.maxTs
    : new Date().getTime();
  return { minTs, maxTs };
};
