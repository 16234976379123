import React from "react";
import { Footer } from "../../components/Footer.js";
import { InternalNavbar } from "../../components/navbar.js";
import { Container, Row, Col } from 'react-bootstrap'
import Loader from "../../components/Loader.js";
import SharedStateComponent from "../../components/SharedStateComponent.js";
import ChooseExchange from "../responsive/ChooseExchange.js";

export default class AboutExchange extends SharedStateComponent {
  static routePath = '/about-exchange';
  static loginRequired = true;

  constructor(props) {
    super(props);

    this.videoSetup = this.videoSetup.bind(this)

    this.state = {
      form: {}
    }
  }

  videoSetup() {
    if(!this.state.video){
      document.body.style.height = "100%";
    }else{
      document.body.style.height = "";
    }
    this.setState({video: !this.state.video})
  }

  render() {
    return (
      <>

        <Loader loading={false} />

        <InternalNavbar history={this.props.history} title="About Exchange" />

        <div className="desktop">

        <Container fluid className={this.state.video ? "px-0" : ""}>
          <Row className="mt-0">
            <Col className="pt-4">
              <div className={("fade modal-backdrop")+(this.state.video ? " show" : " invisible")} onClick={this.videoSetup}>
              </div>
              <video autoPlay
              controls={this.state.video}
              controlsList="nodownload nofullscreen"
              disablePictureInPicture
              muted={!this.state.video}
              className={!this.state.video ? "step-video" : "full-step-video"}
              onClick={this.videoSetup}>
                <source src="../videos/step-by-step.mp4" type="video/mp4" />
                Your browser does not support the video tag.
              </video>
            </Col>
          </Row>
        </Container>
        <Container fluid className="naked">
          <Row className="mt-0">
            <Col>
              <p className="mb-0"><b>What is an exchange?</b></p>
              <p>An exchange is simply a website that allows investors to add money, then buy and sell cryptocurrencies.</p>
            </Col>
          </Row>
          <Row className="mt-3">
            <Col>
              <p className="mb-0"><b>Why does Coinbag need me to connect to an exchange?</b></p>
              <p>Coinbag uses exchanges to buy and sell coins on your behalf. We partner with only the top exchanges in the market that adhere to proper regulations and security protocols.</p>
            </Col>
          </Row>
          <Row className="mt-3">
            <Col>
              <p className="mb-0"><b>How secure are these exchanges?</b></p>
              <p>The exchanges we partner with are known to have put in place the best security measures available. There is never a 100% guarantee, though. We recommend using a strong password that you change frequently, enable two-factor authentication through an application like Google Authenticator, and follow all recommended security practices that the exchange suggests.</p>
            </Col>
          </Row>
          <Row className="mt-3">
            <Col>
              <p className="mb-0"><b>What is the purpose of Coinbag, if I am already putting money on an exchange?</b></p>
              <p>An exchange is just a product that allows you to buy/sell coins. Coinbag is the layer that aligns your investment goals with an execution plan to invest and rebalance in a set of quality currencies over time. Coinbag will also make the most of your held assets by automatically moving it to places that will earn you stable interest while you hold the currency.</p>
            </Col>
          </Row>
          <Row className="mt-3">
            <Col>
              <p className="mb-0"><b>Can I have Coinbag only use a portion of my available balance on the exchange?</b></p>
              <p>Currently Coinbag will use the full portion of your account balance. If you prefer to keep some money on the side to trade, we suggest creating a separate account specific to only your Coinbag funds that you can add funds to over time.</p>
            </Col>
          </Row>
          <Row className="mt-4 text-center">
            <Col>
              <button className="btn-inverse btn-block" onClick={() => this.props.history.push(ChooseExchange.routePath)}>
                Back to exchange selection
              </button>
            </Col>
          </Row>
        </Container>

        </div>

        <Footer />

      </>
    );
  }
}
