import {
  Box,
  createStyles,
  InputAdornment, Link,
  makeStyles,
  Typography,
} from '@material-ui/core';
import { AxiosError } from 'axios';
import { Button } from 'elements';
import { CheckBoxWithText } from 'elements/CheckBoxWithText';
import { InfoBanner } from 'elements/InfoBanner';
import { InputWithTitle } from 'elements/InputWithTitle';
import { LightTextField } from 'elements/LightTextField';
import React, { useCallback, useState } from 'react';
import userService from 'services/user-service';
import { useStore } from 'store/zustand/AccountSetting';
import { breakpoints } from 'theme/base';

import GetCodeButton from './GetCodeButton';

interface RevokeAccessDialogProps {
  onClose: () => any;
}

enum RevokeAccessSteps {
  Warning,
  StepsToRevoke,
  Form,
}

const useStyles = makeStyles((theme) => createStyles({
  root: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    maxHeight: 'calc(100vh - 64px)',
  },
  title: {
    color: theme.palette.red[600],
    textAlign: 'center',
  },
  scrollContent: {
    padding: '16px',
    paddingBottom: 0,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    overflow: 'scroll',
    '&::-webkit-scrollbar': {
      display: 'none',
    },
    flexGrow: 1,
    [breakpoints.down('sm')]: {
      paddingBottom: '8px',
    },
  },
  content: {
    marginTop: '16px',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    gap: '4px',
    '& .note': {
      fontWeight: 400,
      paddingBottom: '4px',
    },
    color: theme.palette.grey[700],
  },
  buttonsRow: {
    display: 'flex',
    flexWrap: 'nowrap',
    gap: '4px',
    width: '100%',
    justifyContent: 'space-between',
    padding: '16px',
    [breakpoints.down('sm')]: {
      boxShadow: '0px -4px 12px rgba(110, 123, 162, 0.1)',
    },
  },
  checkbox: {
    display: 'flex',
    gap: '8px',
    alignItems: 'center',
    color: theme.palette.grey[600],
    marginTop: '12px',
  },
  yellowBanner: {
    backgroundColor: theme.palette.yellow[100],
    color: theme.palette.yellow[600],
  },
  redBanner: {
    backgroundColor: theme.palette.red[100],
    color: theme.palette.red[600],
  },
  learnMore: {
    textDecoration: 'underline',
    textTransform: 'uppercase',
    marginTop: '8px',
  },
  nonBold: {
    fontWeight: 400,
  },
  centerText: {
    textAlign: 'center',
  },
  textFieldButton: {
    marginRight: '-10px',
  },
  errorBox: {
    margin: '10px',
  },
  error: {
    color: theme.palette.red[600],
  },
}));

export const RevokeAccessDialog = ({ onClose }: RevokeAccessDialogProps) => {
  const classes = useStyles();

  const [currentStep, setCurrentStep] = React.useState(RevokeAccessSteps.Warning);
  const [inProgress, setInProgress] = React.useState(false);
  const [sentRevokeEmail, setSentRevokeEmail] = React.useState(false);
  const [isChecked, setIsChecked] = React.useState(false);

  const [emailCode, setEmailCode] = useState<string | null>(null);
  const [authenticatorCode, setAuthenticatorCode] = useState<string | null>(null);

  const [revokeError, setRevokeError] = useState('');

  const { isAuthenticatorConfigured } = useStore();

  const handleProceed = useCallback(() => setCurrentStep(RevokeAccessSteps.StepsToRevoke), []);
  const handleBeginRevoking = useCallback(() => setCurrentStep(RevokeAccessSteps.Form), []);

  const handleSetEmailCode = useCallback((e) => setEmailCode(e.target.value), []);
  const handleSetAuthenticatorCode = useCallback((e) => setAuthenticatorCode(e.target.value), []);

  const handleRevoke = useCallback(async () => {
    try {
      setRevokeError('');
      setInProgress(true);
      await userService.revokeAccess(emailCode, authenticatorCode);
      setSentRevokeEmail(true);
      setInProgress(false);
      window.location.reload();
    } catch (error) {
      const axiosError = error as AxiosError;
      if (axiosError?.response?.status === 403) {
        setRevokeError('Invalid 2FA code(s)');
      } else {
        setRevokeError('An error occurred. Please try again or contact support@coinbag.finance');
      }
      setInProgress(false);
      setSentRevokeEmail(false);
    }
  }, [emailCode, authenticatorCode]);

  const privateKeyBanner = (
    <InfoBanner modifier={classes.redBanner} icon="icons/circle_question_red.svg">
      <Typography className={classes.nonBold} variant="h6">It is important you remember and store your private keys safely. <strong>If lost, you will not have access to your funds and Coinbag cannot help.</strong></Typography>
      <Link href="https://coinbag.tawk.help/article/what-happens-when-i-revoke-access-to-my-private-key" target="_blank" rel="noreferrer"><Typography className={`${classes.learnMore} ${classes.redBanner}`} variant="h6">Learn More</Typography></Link>
    </InfoBanner>
  );

  const warning = (
    <Box className={classes.content}>
      <Typography variant="h4">Important</Typography>
      <Typography className="note" variant="h6">You will no longer be able to use or access any of the Coinbag features, including:</Typography>
      <Typography className="note" variant="h6">1. Deposit<br />2. Withdraw<br />3. View/Manage portfolio</Typography>
      <InfoBanner modifier={classes.yellowBanner} icon="icons/circle_question_yellow.svg" description="You will be solely responsible for managing your wallets on each chain. Coinbag cannot help recover your wallets after 7 days after revoking.">
        <Link href="https://coinbag.tawk.help/article/what-happens-when-i-revoke-access-to-my-private-key" target="_blank" rel="noreferrer"><Typography className={`${classes.learnMore} ${classes.yellowBanner}`} variant="h6">Learn More</Typography></Link>
      </InfoBanner>
      <Box className={classes.checkbox}><CheckBoxWithText isChecked={isChecked} setIsChecked={setIsChecked}><Typography variant="h6">I understand.</Typography></CheckBoxWithText></Box>
    </Box>
  );

  const stepToRevoke = (
    <Box className={classes.content}>
      <Typography variant="h4">Step 1:</Typography>
      <Typography className="note" variant="h6">You will need to enter your 6-digit two-factor authentication code(s) via email and/or authenticator app.</Typography>
      <Typography variant="h4">Step 2:</Typography>
      <Typography className="note" variant="h6">Coinbag will email you a .txt file containing the access to the private keys for your <strong>2</strong> wallets.</Typography>
      <Typography variant="h4">Step 3:</Typography>
      <Typography className="note" variant="h6">Coinbag will permanently delete your private key after 7 days. These cannot be recovred by Coinbag and the Coinbag platform will no longer work for your account.</Typography>
      {privateKeyBanner}
    </Box>
  );

  return (
    <Box className={classes.root}>
      <Box className={classes.scrollContent}>
        <img src="icons/account_details/icon_warning.svg" alt="warning" />
        <Typography className={classes.title} variant="h5">Are you sure you want to revoke access?</Typography>

        {currentStep === RevokeAccessSteps.Warning && warning}
        {currentStep === RevokeAccessSteps.StepsToRevoke && stepToRevoke}
        {currentStep === RevokeAccessSteps.Form && (
          <Box className={classes.content}>
            <Typography className={classes.centerText} variant="h6">For security, please enter your 2FA code(s)</Typography>
            <InputWithTitle title="Email 2FA code">
              <LightTextField
                value={emailCode}
                onChange={handleSetEmailCode}
                placeholder="e.g. 666000"
                InputProps={{
                  endAdornment: (
                    <InputAdornment className={classes.textFieldButton} position="end" onClick={() => { }}>
                      <GetCodeButton />
                    </InputAdornment>
                  ),
                }}
              />
            </InputWithTitle>

            {isAuthenticatorConfigured && (
              <InputWithTitle title="Authenticator app 2FA code">
                <LightTextField
                  value={authenticatorCode}
                  onChange={handleSetAuthenticatorCode}
                  placeholder="e.g. 666000"
                />
              </InputWithTitle>
            )}

            {revokeError && (
              <Box className={classes.errorBox}>
                <Typography className={classes.error} variant="h6">{revokeError}</Typography>
              </Box>
            )}

            {privateKeyBanner}
          </Box>
        )}
      </Box>
      <Box className={classes.buttonsRow}>
        <Button variant="secondary" size="sm" onClick={onClose}><Typography variant="h6">Cancel</Typography></Button>
        {currentStep === RevokeAccessSteps.Warning && (
          <Button color="red" size="sm" disabled={!isChecked} onClick={handleProceed}><Typography variant="h6">Proceed</Typography></Button>
        )}
        {currentStep === RevokeAccessSteps.StepsToRevoke && (
          <Button color="red" size="sm" onClick={handleBeginRevoking}><Typography variant="h6">Begin revoking</Typography></Button>
        )}
        {currentStep === RevokeAccessSteps.Form && (
          <Button
            color="red"
            size="sm"
            onClick={handleRevoke}
            disabled={sentRevokeEmail || inProgress || !emailCode || (isAuthenticatorConfigured && !authenticatorCode)}
          >
            <Typography variant="h6">{inProgress ? 'Revoking...' : sentRevokeEmail ? 'Access Revoked' : 'Revoke access'}</Typography>
          </Button>
        )}
      </Box>
    </Box>
  );
};

RevokeAccessDialog.displayName = 'RevokeAccessFaModal';
export default RevokeAccessDialog;
