import { Box, makeStyles } from '@material-ui/core';
import { Button, Typography } from 'elements';
import LightModal from 'elements/LightModal';
import * as React from 'react';

interface Props {
  showModal: boolean;
  setShowModal: (value: boolean) => void;
}

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: 16,

    width: '100%',
    textAlign: 'center',
  },
  title: { color: theme.palette.grey['800'] },
  content: { color: theme.palette.grey['700'] },
}));

const DuplicateModal = ({ showModal, setShowModal }: Props) => {
  const classes = useStyles();
  return (
    <LightModal open={showModal} setOpen={setShowModal} maxWidth={372} showCloseButton>
      <Box className={classes.root}>
        <Typography variant="h5" className={classes.title}>Duplicate address</Typography>
        <Typography variant="h6" className={classes.content}>Your new wallet address has already been added</Typography>
        <Button onClick={() => setShowModal(false)} fullWidth>Close</Button>
      </Box>
    </LightModal>
  );
};

export default DuplicateModal;
