import { Box, createStyles, makeStyles } from '@material-ui/core';
import { Typography } from 'elements';
import React from 'react';
import SVG from 'react-inlinesvg';
import { WithdrawalParameter } from 'services/types/workItemParameter';
import { useStore as useCoinStore } from 'store/zustand/Coin';

const useStyles = makeStyles(() => createStyles({
  details: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    textAlign: 'center',
    gap: 8,
    marginBottom: 8,
  },
  withdrawalCoinIconWrapper: {
    width: 32,
    height: 32,
  },
  withdrawalDetails: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    gap: 8,
  },
  priceWrapper: {
    display: 'flex',
    alignItems: 'center',
  },
}));

const WithdrawalDetail = (props: WithdrawalParameter) => {
  const classes = useStyles();
  const [coins, fetchCoins] = useCoinStore((state) => [state.coins, state.fetchCoins]);

  React.useEffect(() => {
    fetchCoins();
  }, []);

  return (
    <Box className={classes.details}>
      <Typography variant="h4" palette="grey" paletteColor={700}>
        The withdrawal was requested from the company account
      </Typography>
      <Box className={classes.withdrawalDetails}>
        <Typography variant="h5" palette="grey" paletteColor={800}>
          {props.percentage.toFixed(2)}% of portfolio value to
        </Typography>
        <Box className={classes.withdrawalCoinIconWrapper}>
          <SVG src={coins[props.coinId]?.masterCoin?.icon} width={32} height={32} />
        </Box>
      </Box>
    </Box>
  );
};

export default WithdrawalDetail;
