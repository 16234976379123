import { Box, Tooltip, Typography } from '@material-ui/core';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import { ToggleButton, ToggleButtonGroup } from '@material-ui/lab';
import * as React from 'react';

import { breakpoints } from '../../theme/base';
import { QuestionIcon } from '../Icons';

const useStyles = makeStyles(() => createStyles({
  mobileSection: {
    margin: '0 16px',
    [breakpoints.up('sm')]: {
      margin: 0,
    },
  },
  contentCard: {
    borderRadius: '0 !important',
    [breakpoints.up('md')]: {
      borderRadius: '4px !important',
    },
  },
  header: {
    width: 'fit-content',
    height: 'fit-content',
    textAlign: 'center',
    [breakpoints.up('sm')]: {
      textAlign: 'left',
    },
  },

  trueConversionGrid: {
    [breakpoints.up('sm')]: {
      marginLeft: 'auto',
      marginRight: 0,
    },
    [breakpoints.up('xl')]: {
      display: 'table',
    },
  },

  trueConversionSub: {
    [breakpoints.up('xl')]: {
      display: 'table-cell',
    },
  },

  titleGrid: {
    display: 'grid',
    gridTemplateColumns: 'repeat(1, minmax(0, 1fr))',
    rowGap: 7,

    [breakpoints.up('sm')]: {
      gridTemplateColumns: 'repeat(2, minmax(0, 1fr))',
      columnGap: 24,
    },

    [breakpoints.down('sm')]: { justifyItems: 'center' },
    [breakpoints.up('md')]: { columnGap: 20 },
    [breakpoints.up('lg')]: { columnGap: 16 },
    [breakpoints.up('xl')]: { columnGap: 24 },
  },

  overviewGrid: {
    display: 'grid',
    gridTemplateColumns: 'repeat(1, minmax(0, 1fr))',
    gridAutoRows: '1fr',
    rowGap: 16,

    [breakpoints.up('sm')]: {
      gridTemplateColumns: 'repeat(2, minmax(0, 1fr))',
      columnGap: 24,
    },

    [breakpoints.up('md')]: { columnGap: 20 },
    [breakpoints.up('lg')]: { columnGap: 16 },
    [breakpoints.up('xl')]: { columnGap: 24 },
  },

  chartInstructionTitle: {
    display: 'inline',

    width: '176px',
    height: '24px',
    left: '175px',
    top: '227px',

    fontFamily: 'Poppins',
    fontStyle: 'normal',
    fontWeight: 700,
    fontSize: '14px',
    lineHeight: '24px',

    color: '#4E5A7C',
  },
  chartInstructionSub: {
    display: 'inline',
    width: '76px',
    height: '16px',
    left: '0.5px',
    top: '0px',

    fontFamily: 'Poppins',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '12px',
    lineHeight: '16px',

    color: '#737373',
  },
  oneToOneConversion: {
    display: 'inline',

    width: '148px',
    height: '24px',

    fontFamily: 'Poppins',
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: '14px',
    lineHeight: '24px',

    color: '#02BD9F',

    flex: 'none',
    order: 0,
    flexGrow: 0,
  },

  trueConversionRate: {
    display: 'inline',

    width: '176px',
    height: '24px',

    fontFamily: 'Poppins',
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: '14px',
    lineHeight: '24px',

    color: '#4E5A7C',

    flex: 'none',
    order: 2,
    flexGrow: 0,
  },

  toggleButtonGroup: {
    height: '28px',
    border: '1px solid #DEE3EF',
    borderRadius: '8px',
  },

  selectedButton: {
    color: '#6E7BA1',
    '&.Mui-selected': {
      '@media (hover: none)': { backgroundColor: '#EBFFFC' },
      color: '#02BD9F',
      backgroundColor: '#EBFFFC',
    },
  },

  whatIsThis: {
    display: 'inline',
    marginLeft: '4px',
    marginRight: '4px',
  },

  tooltip: { fontSize: '0.9em' },
}));

interface Props {
  updateIsTrueConversion: (trueConversion: boolean) => void;
  isTrueConversion: boolean;
}

const TrueConversionBadge: React.FC<Props> = ({ updateIsTrueConversion, isTrueConversion }: Props) => {
  const classes = useStyles();
  return (
    <Box className={`${classes.mobileSection} ${classes.titleGrid}`}>
      <Typography variant="h2" className={classes.header}>Welcome to your Coinbag!</Typography>

      <Box className={`${classes.mobileSection} ${classes.trueConversionGrid}`}>
        <Box className={`${classes.trueConversionSub}`}>
          <Typography className={classes.chartInstructionTitle}>How to view your chart: </Typography>
          <Tooltip
            classes={{ tooltip: classes.tooltip }}
            enterTouchDelay={0}
            leaveTouchDelay={15000}
            title={'Due to recent market events, UST has lost its intended value of 1 UST to 1 USD. ' +
              'To help, you can view your portfolio value as if 1 UST = 1 USD, ' +
              'or toggle to "True conversion rate" where your portfolio value will reflect the current market rate ' +
              'of UST to USD.'}
            placement="right"
          >
            <div className={classes.whatIsThis}>
              <Typography className={classes.chartInstructionSub}>What is this? </Typography>
              <QuestionIcon className="pointer" />
            </div>
          </Tooltip>
        </Box>

        <Box className={`${classes.trueConversionSub}`}>
          <ToggleButtonGroup
            color="primary"
            className={classes.toggleButtonGroup}
            value={isTrueConversion ? 'trueconv' : 'onetoone'}
            onChange={(_, value) =>
              updateIsTrueConversion(value !== 'onetoone')}
            exclusive
          >
            <ToggleButton className={classes.selectedButton} value="onetoone">
              1:1 UST to USDT
            </ToggleButton>
            <ToggleButton className={classes.selectedButton} value="trueconv">
              True Conversion Rate
            </ToggleButton>
          </ToggleButtonGroup>
        </Box>
      </Box>
    </Box>
  );
};

TrueConversionBadge.displayName = 'TrueConversionBadge';
export { TrueConversionBadge };
