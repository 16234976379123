import { Box, createStyles, Hidden, useTheme } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import CloseRoundedIcon from '@material-ui/icons/CloseRounded';
import DataFrame, { Row } from 'dataframe-js';
import { LightAutocomplete, LightTextField, Typography } from 'elements';
import { LightFrenchFries } from 'elements/LightFrenchFries';
import Fuse from 'fuse.js';
import Linkify from 'linkify-react';
import React, { useMemo } from 'react';
import SVG from 'react-inlinesvg';
import { Tag } from 'services/types/tag';
import { Transaction, UserAtomicTransaction } from 'services/types/user-transaction';
import { useStore as useCoinStore } from 'store/zustand/Coin';
import { useStore as useTransactionStore } from 'store/zustand/Transaction';

interface Props {
  tx: Transaction;
  // eslint-disable-next-line no-unused-vars
  setOpenCopiedSnackBar: (value: boolean) => void;
  // eslint-disable-next-line no-unused-vars
  setInitAddress: (value: string | undefined) => void;
  // eslint-disable-next-line no-unused-vars
  setInitAddressName: (value: string | undefined) => void;
  // eslint-disable-next-line no-unused-vars
  setInitWalletId: (value: number | undefined) => void
  // eslint-disable-next-line no-unused-vars
  setShowAddAddressBookModal: (value: boolean) => void;
  fromAddressBooks?: string[];
  toAddressBooks?: string[];
  dataFrame: DataFrame | null;
  // eslint-disable-next-line no-unused-vars
  setDataFrame: (value: DataFrame | null) => void;
  // eslint-disable-next-line no-unused-vars
  setInitTx: (value: UserAtomicTransaction | undefined) => void;
  // eslint-disable-next-line no-unused-vars
  setFilteredTxs: (value: Transaction[]) => void;
  // eslint-disable-next-line no-unused-vars
  setTxDetailsContent: (value: Transaction | undefined) => void;
  // eslint-disable-next-line no-unused-vars
  setShowTxDetails: (value: boolean) => void;
  // eslint-disable-next-line no-unused-vars
  setShowAddTagModal: (value: boolean) => void;
  // eslint-disable-next-line no-unused-vars
  setAddTagCallbackFnc: (value: (tagId: number, tag: string) => void) => void;
  tags: Tag[];
}

const useStyles = makeStyles((theme) => createStyles({
  txRowWrapper: {
    padding: '12px 14px',
    '&:hover': { backgroundColor: theme.palette.grey[100], cursor: 'pointer' },
  },
  eventWrapper: {
    display: 'flex',
    gap: 8,
  },
  coinIconWrapper: {
    display: 'flex',
    width: 20,
    height: 20,
  },
  coinIcon: {
    width: '100%',
    height: '100%',
  },
  eventDataWrapper: {
    display: 'flex',
    flexDirection: 'column',
    minWidth: 0,
    width: '100%',
    gap: 10,
  },
  eventDataRow: {
    display: 'flex',
    gap: 4,
    width: '100%',
    margin: '-4px 0',
    alignItems: 'center',
  },
  eventDataKey: {
    width: 56,
    flexShrink: 0,
  },
  addressRow: {
    display: 'flex',
    position: 'relative',
    gap: 8,
    width: '100%',
    alignItems: 'center',
    overflow: 'hidden',
  },
  eventDataValue: {
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    padding: '4px 0',
  },
  addDataIconWrapper: {
    width: 24,
    height: 24,
  },
  addDataWrapper: {
    gap: 8,
    opacity: 0,
    width: '100%',
    display: 'flex',
    position: 'absolute',
    justifyContent: 'flex-end',
    transition: 'all 0.1s ease-out',
    background: `linear-gradient(to right, transparent 100%, ${theme.palette.grey[100]} 100%)`,
    '&:hover': {
      opacity: 1,
      // eslint-disable-next-line max-len
      background: `linear-gradient(to right, transparent 60%, ${theme.palette.grey[100]} 90%, ${theme.palette.grey[100]})`,
    },
  },
  icon: {
    width: '100%',
    height: '100%',
  },
  tagAutocomplete: {
    width: '100%',
  },
  endAdornment: {
    alignItems: 'start',
  },
  extraSmallIcon: {
    transform: 'scale(0.75)',
  },
  tagTableInput: {
    backgroundColor: 'transparent',
    gap: '4px',
    background: theme.palette.grey[400],
    overflow: 'hidden',
    '& input[type]': {
      minWidth: '16px',
      padding: '0',
      height: '20px',
    },
  },
  statusWrapper: {
    display: 'flex',
    gap: 4,
    alignItems: 'center',
  },
  statusIconWrapper: {
    display: 'flex',
    flexShrink: 0,
    width: 16,
    height: 16,
  },
  statusIcon: {
    width: '100%',
    height: '100%',
  },
  quantityWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    flexShrink: 0,
    gap: 4,
    marginBottom: '8px',
  },
  quantityText: {
    flexShrink: 0,
  },
  note: {
    display: 'flex',
    gap: 4,
    alignItems: 'center',
    flexWrap: 'nowrap',
    '& h6': {
      minWidth: 0,
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
    '& svg': {
      cursor: 'pointer',
      flexShrink: 0,
      '&#active path': {
        fill: theme.palette.green[600],
      },
    },
  },
}));

export const TxRow = ({ tx, setOpenCopiedSnackBar, setInitAddress, setInitAddressName, setInitWalletId,
  setShowAddAddressBookModal, setDataFrame, dataFrame, setInitTx, setFilteredTxs, setTxDetailsContent,
  setShowTxDetails, setShowAddTagModal, setAddTagCallbackFnc, tags, fromAddressBooks, toAddressBooks }: Props) => {
  const date = new Date(tx.ts);
  const classes = useStyles();
  const theme = useTheme();
  const [addTag, removeTag] = useTransactionStore((state) => [state.addTag, state.removeTag]);
  const [coins] = useCoinStore((state) => [state.coins]);

  const handleCopy = (a: 'from' | 'to') => (e: any) => {
    e.stopPropagation();
    if (a === 'from' && tx.fromAddress) {
      navigator.clipboard.writeText(tx.fromAddress);
      setOpenCopiedSnackBar(true);
    } else if (a === 'to' && tx.toAddress) {
      navigator.clipboard.writeText(tx.toAddress);
      setOpenCopiedSnackBar(true);
    }
  };
  const handleAddAddressBook = (a: 'from' | 'to') => (e: any) => {
    e.stopPropagation();
    if (a === 'from' && tx.fromAddress) {
      setInitAddress(tx.fromAddress);
      if (fromAddressBooks) setInitAddressName(fromAddressBooks[0]);
    } else if (a === 'to' && tx.toAddress) {
      setInitAddress(tx.toAddress);
      if (toAddressBooks) setInitAddressName(toAddressBooks[0]);
    }
    setInitWalletId(tx.walletId);
    setShowAddAddressBookModal(true);
  };

  // eslint-disable-next-line no-unused-vars
  const checkHashAndSetTags = (r: (ro: Row) => Row) => {
    if (dataFrame != null) {
      const fdf: DataFrame = (dataFrame as any).map((row: any) => (row.get('txHash') === tx.txHash ? r(row) : row));

      setDataFrame(fdf);
      setFilteredTxs(fdf.toCollection());
    }
  };

  const component = (
    <tr
      className={classes.txRowWrapper}
      onClick={() => {
        setTxDetailsContent(tx);
        setShowTxDetails(true);
      }}
    >
      <td>
        <Box className={classes.eventWrapper}>
          <Box className={classes.coinIconWrapper}>
            <SVG
              src={tx.coinId === 341158999 ? coins['341159068']?.masterCoin?.icon
                : coins[tx.coinId.toString()]?.masterCoin?.icon ?? '/icons/coin_icon/fallback-1.svg'}
              className={classes.coinIcon}
            />
          </Box>
          <Box className={classes.eventDataWrapper}>
            <Box className={classes.eventDataRow}>
              <Typography
                variant="h6"
                className={classes.eventDataKey}
                style={{ color: coins[tx.coinId]?.masterCoin.metadata?.color ?? 'unset' }}
              >
                From:
              </Typography>
              <Box className={classes.addressRow}>
                {tx.fromAddress && fromAddressBooks && fromAddressBooks
                  .reduce((prev: string[], curr) => (prev.includes(curr) ? prev : [...prev, curr]), [])
                  .map((ab, key) => (
                    <LightFrenchFries
                      key={key}
                      label={ab}
                      autoAssignColor
                      noShrink
                    />
                  ))}
                <Typography
                  variant="h6"
                  paletteColor={500}
                  className={classes.eventDataValue}
                  middleTruncation
                >
                  {tx.reason === 'rewards' ? 'Rewards' : tx.fromAddress ?? '-'}
                </Typography>
                {tx.fromAddress !== undefined && (
                  <Box className={classes.addDataWrapper}>
                    <Box className={classes.addDataIconWrapper} onClick={handleCopy('from')}>
                      <SVG src="icons/reporting/ic_copy.svg" className={classes.icon} />
                    </Box>
                    <Box className={classes.addDataIconWrapper} onClick={handleAddAddressBook('from')}>
                      <SVG src="icons/reporting/ic_add_item.svg" className={classes.icon} />
                    </Box>
                  </Box>
                )}
              </Box>
            </Box>
            <Box className={classes.eventDataRow}>
              <Typography variant="h6" className={classes.eventDataKey}>
                To:
              </Typography>
              <Box className={classes.addressRow}>
                {tx.toAddress && toAddressBooks && toAddressBooks
                  .reduce((prev: string[], curr) => (prev.includes(curr) ? prev : [...prev, curr]), [])
                  .map((ab, key) => (
                    <LightFrenchFries
                      key={key}
                      label={ab}
                      autoAssignColor
                      noShrink
                    />
                  ))}
                <Typography
                  variant="h6"
                  paletteColor={500}
                  className={classes.eventDataValue}
                  middleTruncation
                >{tx.isGas ? `${tx.chainName} validator for gas` : tx.toAddress ?? '-'}
                </Typography>
                {tx.toAddress !== undefined && !tx.isGas && (
                  <Box className={classes.addDataWrapper}>
                    <Box className={classes.addDataIconWrapper} onClick={handleCopy('to')}>
                      <SVG src="icons/reporting/ic_copy.svg" className={classes.icon} />
                    </Box>
                    <Box className={classes.addDataIconWrapper} onClick={handleAddAddressBook('to')}>
                      <SVG src="icons/reporting/ic_add_item.svg" className={classes.icon} />
                    </Box>
                  </Box>
                )}
              </Box>
            </Box>
            <Box className={classes.eventDataRow}>
              <Typography variant="h6" className={classes.eventDataKey}>Tx hash:</Typography>
              <Typography
                variant="h6"
                paletteColor={500}
                className={classes.eventDataValue}
                ellipsis
              >
                {tx.txHash}
              </Typography>
            </Box>
            <Box className={classes.eventDataRow}>
              <Typography variant="h6" className={classes.eventDataKey}>Note:</Typography>
              <Typography
                variant="h6"
                paletteColor={500}
                className={classes.eventDataValue}
                ellipsis
              >
                <Box className={classes.note}>
                  <Typography variant="h6" paletteColor={tx.note ? 700 : 500}>
                    <Linkify
                      options={{
                        target: '_blank',
                        rel: 'noopener noreferrer nofollow',
                        attributes: { onClick: (event: any) => { event.stopPropagation(); } },
                      }}
                    >
                      {tx.note || 'Add'}
                    </Linkify>
                  </Typography>
                  <SVG id={tx.note ? 'active' : undefined} width={16} height={16} src="/icons/reporting/ic_write.svg" />
                </Box>
              </Typography>
            </Box>
          </Box>
        </Box>
      </td>
      <Hidden smDown>
        <td>
          <LightAutocomplete
            className={classes.tagAutocomplete}
            classes={{
              endAdornment: classes.endAdornment,
            }}
            openOnFocus
            blurOnSelect
            multiple
            options={tags != null
              ? [{ tag: '', tagId: 0, walletId: -1 }].concat(tags.filter((t) => t.walletId === tx.walletId))
              : [{ tag: '', tagId: 0, walletId: -1 }]}
            getOptionLabel={(option) => option.tag}
            value={tx.tags}
            onOpen={(event) => {
              event.stopPropagation();
            }}
            onClose={(event) => {
              event.stopPropagation();
            }}
            onChange={async (event, value, optionAction, change) => {
              // eslint-disable-next-line no-unused-vars

              if (change != null && change.option.tagId === 0) {
                setInitTx(tx);
                setShowAddTagModal(true);
                const callbackFnc = () => (tagId: number, tag: string) => {
                  checkHashAndSetTags((row) => row
                    .set('tags', row.get('tags').concat([{ tagId, tag, walletId: tx.walletId }])));
                };
                setAddTagCallbackFnc(callbackFnc);
              } else if (optionAction === 'select-option' && change != null) {
                addTag(tx.txHash, change.option.tagId);
                checkHashAndSetTags((row) => row.set('tags', row.get('tags').concat([change.option])));
              } else if (optionAction === 'remove-option' && change != null) {
                removeTag(tx.txHash, change.option.tagId);
                checkHashAndSetTags((row) => row
                  .set('tags', row.get('tags').filter((t: Tag) => t.tagId !== change.option.tagId)));
              }
            }}
            getOptionSelected={(option, value) => option.tagId === value.tagId && value.tagId !== 0}
            disableClearable
            forcePopupIcon={false}
            size="small"
            filterOptions={(t: Tag[], state) => {
              if (state.inputValue === '') return t;
              const fuse = new Fuse(t, { keys: ['tag'] });
              return [{ tag: '', tagId: 0, walletId: -1 }].concat(fuse.search(state.inputValue).map((s) => s.item));
            }}
            renderTags={(tagValue) => tagValue.map((option, index) => (
              <LightFrenchFries
                key={index}
                variant={option.tagId < 0 ? 'outlined' : 'default'}
                size="small"
                label={option.tag ?? option}
                autoAssignColor
              />
            ))}
            renderOption={(option, state) => (option.tagId === 0 ? (
              <Typography component="span" display="inline" variant="h6" palette="green" noWrap>
                <SVG width={20} height={20} src="icons/reporting/ic_add_item.svg" /> add tag
              </Typography>
            ) : (
              <>
                <LightFrenchFries
                  size="small"
                  label={option.tag ?? option}
                  autoAssignColor
                />
                {state.selected
                  ? (
                    <CloseRoundedIcon
                      fontSize="small"
                      htmlColor={theme.palette.grey[400]}
                      className={classes.extraSmallIcon}
                    />
                  ) : null}
                {option.tagId === -1
                  ? (
                    <CloseRoundedIcon
                      fontSize="small"
                      htmlColor={theme.palette.grey[400]}
                      className={classes.extraSmallIcon}
                    />
                  ) : null}
              </>
            ))}
            renderInput={(params) => (
              <LightTextField
                variant="standard"
                label=""
                placeholder={tx.tags.length > 0 ? '+' : 'Add tag'}
                onClick={(event) => {
                  event.stopPropagation();
                }}
                {...params}
                InputProps={{
                  ...params.InputProps,
                  className: `${classes.tagTableInput} ${params.InputProps.className}`,
                  disableUnderline: true,
                }}
              />
            )}
          />
        </td>
        <td>
          <Box className={classes.statusWrapper}>
            <Box className={classes.statusIconWrapper}>
              <SVG
                src={tx.status ? 'icons/reporting/ic_check.svg' : 'icons/reporting/ic_failed.svg'}
                className={classes.statusIcon}
              />
            </Box>
            <Typography variant="h6" palette={tx.status ? 'green' : 'red'} paletteColor={tx.status ? 600 : 500}>
              {tx.status ? 'Sent' : 'Failed'}
            </Typography>
          </Box>
        </td>
        <td>
          <Typography variant="h6" palette="grey" paletteColor={500}>
            {`${date.toLocaleDateString(undefined, { day: '2-digit', month: '2-digit', year: 'numeric' })},
        ${date.toLocaleTimeString(undefined, {
      hour: 'numeric',
      minute: 'numeric',
      second: undefined,
      hour12: false,
    })}`}
          </Typography>
        </td>
      </Hidden>
      <td>
        <Box className={classes.quantityWrapper}>
          <Typography
            variant="h6"
            palette={tx.direction === 'in' ? 'green' : 'red'}
            paletteColor={tx.direction === 'in' ? 600 : 500}
            className={classes.quantityText}
          >
            {`${tx.direction === 'out' ? '-' : ''}${tx.quantity.toLocaleString(undefined, {
              maximumSignificantDigits: tx.quantity > 1 ? undefined : 6,
              minimumFractionDigits: tx.quantity > 1e8 ? 2 : undefined,
            })} ${tx.ticker}`}
          </Typography>
          <Hidden mdUp>
            <Box className={classes.statusIconWrapper}>
              <SVG
                src={tx.status ? 'icons/reporting/ic_check.svg' : 'icons/reporting/ic_failed.svg'}
                className={classes.statusIcon}
              />
            </Box>
          </Hidden>
        </Box>
        {tx.usdAmount !== undefined
          && (
            <Typography
              variant="h6"
              palette="grey"
              paletteColor={500}
            >
              ≈ {tx.usdAmount.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })} USDT
            </Typography>
          )}
      </td>
    </tr>
  );
  return useMemo(() => component, [tx, fromAddressBooks, toAddressBooks, coins]);
};
