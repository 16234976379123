import { Box, createStyles, MenuItem } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { SelectNetwork } from 'components/DepositAndWithdraw/Components/SelectNetwork';
import { supportedChainIds } from 'components/DepositAndWithdraw/mappers';
import { Button, InputWithTitle, LightAutocomplete, LightTextField, Typography } from 'elements';
import { LightFrenchFries } from 'elements/LightFrenchFries';
import LightModal from 'elements/LightModal';
import { LightSelect } from 'elements/LightSelect';
import { OverlayLoading } from 'elements/OverlayLoading';
import React, { ChangeEvent, useEffect, useMemo, useState } from 'react';
import SVG from 'react-inlinesvg';
import { CoinWithMasterCoinInfo } from 'services/types/coin';
import { Tag } from 'services/types/tag';
import { WalletInfo } from 'services/types/wallet';
import { useStore as useCoinStore } from 'store/zustand/Coin';
import { useStore as usePayrollStore } from 'store/zustand/Payroll';
import { useStore as useTransactionStore } from 'store/zustand/Transaction';
import { useStore as useWalletStore } from 'store/zustand/Wallet';

import { AddTagModal } from '../Reporting/AddTagModal';
import { EVM_ADDRESS_FORMAT } from '../Reporting/utils/ReportingUtils';
import { CoinItemPlaceHolder } from './CoinItemPlaceHolder';
import { CoinMenuItem } from './CoinMenuItem';
import { SelectWallet } from './SelectWallet';

interface Props {
  open: boolean;
  // eslint-disable-next-line no-unused-vars
  setOpen: (value: boolean) => void;
  editPayrollId?: number;
  onClose?: () => void;
}

const useStyles = makeStyles((theme) => createStyles({
  root: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    justifyContent: 'space-between',
    gap: 16,
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    gap: 16,
    [theme.breakpoints.down('xs')]: {
      padding: '16px 16px 0',
    },
  },
  button: {
    minWidth: 84,
  },
  buttonsWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%',
    [theme.breakpoints.down('xs')]: {
      borderTop: `1px solid ${theme.palette.grey[200]}`,
      padding: 16,
    },
  },
  cryptoMenuItem: {
    padding: 8,
    '&.Mui-selected': {
      backgroundColor: theme.palette.green[100],
      '&:hover': {
        backgroundColor: theme.palette.green[100],
      },
    },
    borderBottom: `1px solid ${theme.palette.grey[200]}`,
  },
  lightText: {
    fontWeight: 400,
  },
  addressBookMenuItem: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    overflow: 'hidden',
    padding: '2px 0',
    gap: 8,
  },
  textEllipsis: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  addressBookIcon: {
    marginRight: 8,
    '&:hover': {
      cursor: 'pointer',
    },
  },
  greenAddressBook: {
    '& path': {
      fill: theme.palette.green[600],
    },
  },
  errorText: {
    fontSize: 12,
    fontWeight: 400,
    textAlign: 'end',
  },
  chip: {
    margin: '4px 4px 4px 0',
  },
}));

export const AddPayrollModal = ({ open, setOpen, editPayrollId, onClose }: Props) => {
  const classes = useStyles();
  const [coins, fetchCoins] = useCoinStore((state) => [state.coins, state.fetchCoins]);
  const [addressBooks, fetchAddressBooks, nameAddressMap, fetchTags, tags] = useTransactionStore((state) => [
    state.addressBooks, state.fetchAddressBooks, state.nameAddressMap, state.fetchTags, state.tags]);
  const [fetchPayrolls, addPayroll, payrolls] = usePayrollStore((state) => [
    state.fetch, state.add, state.payrolls,
  ]);
  const [wallet, fetchWallet, walletInfos, isAllWallets, activeWalletInfo] = useWalletStore((state) => [state.wallet, state.fetchWallet,
    state.walletInfos, state.isAllWallets, state.activeWalletInfo]);
  const activeWalletAddresses = useMemo(() => (
    wallet !== undefined && walletInfos !== undefined
      ? walletInfos
        .filter((wi) => wi.walletId === wallet.walletId)
        .reduce((prev: string[], curr: WalletInfo) => [...prev, ...(curr.address ?? [])], [])
      : []
  ), [wallet, walletInfos]);

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isAddTagModalOpen, setIsAddTagModalOpen] = useState<boolean>(false);
  const [saveButtonDisabled, setSaveButtonDisabled] = useState<boolean>(true);
  const [addressName, setAddressName] = useState<string>('');
  const [address, setAddress] = useState<string>('');
  const [amount, setAmount] = useState<string>('');
  const [selectedNetwork, setSelectedNetwork] = useState<string>('');
  const [selectedCoinId, setSelectedCoinId] = useState<number>(-1);
  const [isAddressNameOpen, setIsAddressNameOpen] = useState<boolean>(false);
  const [addressNameError, setAddressNameError] = useState<boolean>(false);
  const [addressError, setAddressError] = useState<boolean>(false);
  const [amountError, setAmountError] = useState<boolean>(false);
  const [selectedTags, setSelectedTags] = useState<Omit<Tag, 'walletId'>[]>([]);
  const [selectedWalletId, setSelectedWalletId] = useState<number | undefined>(-1);

  const isAddressValid = () => address.match(EVM_ADDRESS_FORMAT) && !activeWalletAddresses.includes(address);
  const isAmountValid = () => amount !== '' && parseFloat(amount) >= 0;

  useEffect(() => {
    const validAddress = isAddressValid();
    const validAddressName = addressName !== '';
    const validAmount = isAmountValid();
    const coinIdSelected = selectedCoinId >= 0;
    const disableButton = !validAddress || !validAddressName || !validAmount || !coinIdSelected;
    setSaveButtonDisabled(disableButton);
  }, [address, addressName, amount, selectedCoinId]);

  const selectableNetworks: [number, string][] = useMemo(
    () => supportedChainIds.map((chainId) => [0, chainId]), [],
  );

  const withdrawableCoins: CoinWithMasterCoinInfo[] = useMemo(() => Object.entries(coins)
    .map(([, coin]) => coin)
    .filter((coin) => coin.chainId === selectedNetwork)
    .filter((coin) => coin.protocolId === undefined), [coins, selectedNetwork]);

  const handleChangeAmount = (e: ChangeEvent<HTMLInputElement>) => {
    setAmountError(false);
    const { value } = e.target;
    const newAmount: number = parseFloat(value);
    if (value === '' || newAmount < 0) setAmount('');
    else if (newAmount >= 0) setAmount(value);
  };

  const handleSelectNetwork = (chainId: string) => {
    if (chainId !== selectedNetwork) setSelectedCoinId(-1);
    setSelectedNetwork(chainId);
  };

  const handleInputAddressChange = (_: any, value: string | null, reason: string) => {
    if (reason === 'select-option' && value != null) {
      setAddressNameError(false);
      setAddressError(false);
      setAddressName(value);
      setAddress(nameAddressMap[value] ?? '');
      setIsAddressNameOpen(false);
      setSaveButtonDisabled(true);
    }
  };

  const handleOnClose = () => {
    setOpen(false);
    setAddressName('');
    setAddress('');
    setAmount('');
    setSelectedNetwork('');
    setSelectedCoinId(-1);
    setIsAddressNameOpen(false);
    setAddressNameError(false);
    setAddressError(false);
    setAmountError(false);
    setIsLoading(false);
    setSelectedTags([]);
    if (onClose) onClose();
  };

  const handleOnSave = async () => {
    setSaveButtonDisabled(true);
    setIsLoading(true);
    await addPayroll(selectedCoinId, address, addressName, true, selectedTags.map((t) => t.tagId), parseFloat(amount),
      editPayrollId, (selectedWalletId ?? -1) > 0 ? selectedWalletId : undefined);
    fetchPayrolls(true);
    fetchAddressBooks(true);
    handleOnClose();
  };

  useEffect(() => {
    (async () => {
      setIsLoading(true);
      await Promise.allSettled([
        fetchAddressBooks(false),
        fetchCoins(),
        fetchTags(false),
        fetchWallet(false),
      ]);
      setIsLoading(false);
    })();
  }, []);

  useEffect(() => {
    const payroll = editPayrollId ? payrolls?.find((p) => p.payrollId === editPayrollId) : undefined;
    if (payroll) {
      setAddress(payroll.address);
      setAddressName(payroll.tag);
      setSelectedNetwork(coins[payroll.coinId.toString()]?.chainId);
      setSelectedCoinId(payroll.coinId);
      setAmount(`${payroll.quantity ?? 0}`);
      setSelectedTags(tags.filter((t) => payroll.tagIds.includes(t.tagId)));
      setSelectedWalletId(payroll.walletId);
    }
  }, [payrolls, editPayrollId, tags]);

  return (
    <LightModal
      open={open}
      setOpen={setOpen}
      showCloseButton
      maxWidth={372}
      modalTitle={editPayrollId !== undefined ? 'Edit address' : 'Add address'}
      onClose={handleOnClose}
      fullScreenOnMobile
    >
      <Box className={classes.root}>
        <Box className={classes.content}>
          <InputWithTitle
            title="Address Name"
            titleRightItem={addressNameError
              && (
                <Typography className={classes.errorText} palette="red" paletteColor={600}>
                  Invalid address name
                </Typography>
              )}
          >
            <LightAutocomplete
              options={addressBooks
                ?.filter((a) => !activeWalletAddresses.includes(a.address))
                ?.map((a) => a.tag)
                .filter((a) => a.toLowerCase().startsWith(addressName.toLowerCase())) ?? []}
              getOptionLabel={(option) => option}
              freeSolo
              disabled={editPayrollId !== undefined}
              value={addressName}
              onChange={handleInputAddressChange}
              open={isAddressNameOpen}
              renderOption={(option) => (
                <Box className={classes.addressBookMenuItem}>
                  <LightFrenchFries label={option} autoAssignColor noShrink />
                  <Typography
                    variant="h6"
                    palette="grey"
                    paletteColor={500}
                    middleTruncation
                  >
                    {nameAddressMap[option] ?? ''}
                  </Typography>
                </Box>
              )}
              renderInput={(params) => (
                <LightTextField
                  {...params}
                  onChange={(e) => {
                    setAddressNameError(false);
                    setAddressName(e.target.value);
                    setIsAddressNameOpen(true);
                  }}
                  onFocus={() => setIsAddressNameOpen(true)}
                  onBlur={() => {
                    if (addressName === '') setAddressNameError(true);
                    setIsAddressNameOpen(false);
                  }}
                  InputProps={{
                    ...params.InputProps,
                    endAdornment: (
                      <SVG
                        src="icons/reporting/ic_address_book.svg"
                        width={20}
                        height={20}
                        className={`${classes.addressBookIcon} ${isAddressNameOpen && classes.greenAddressBook}`}
                        onClick={() => { setIsAddressNameOpen((prev) => !prev); }}
                      />),
                  }}
                  error={addressNameError}
                  placeholder="Address Name"
                />
              )}
            />
          </InputWithTitle>
          <InputWithTitle
            title="Address"
            titleRightItem={addressError
              && (
                <Typography className={classes.errorText} palette="red" paletteColor={600}>
                  Invalid address
                </Typography>
              )}
          >
            <LightTextField
              value={address}
              onChange={(e) => {
                setAddressError(false);
                setAddress(e.target.value);
              }}
              onBlur={() => { if (!isAddressValid()) setAddressError(true); }}
              placeholder="Address"
              error={addressError}
              disabled={editPayrollId !== undefined}
            />
          </InputWithTitle>
          <InputWithTitle title="Network">
            <SelectNetwork
              selectableNetworks={selectableNetworks}
              selectedNetwork={selectedNetwork}
              setSelectedNetwork={handleSelectNetwork}
              placeholder="Select network"
            />
          </InputWithTitle>
          <InputWithTitle title="Crypto">
            <LightSelect
              disabled={withdrawableCoins.length <= 0}
              value={selectedCoinId}
              displayEmpty
              renderValue={() => (selectedCoinId < 0 ? (<CoinItemPlaceHolder />)
                : (<CoinMenuItem {...coins[selectedCoinId]} />))}
            >
              {withdrawableCoins.map((coin) => (
                <MenuItem
                  key={coin.coinId}
                  value={coin.coinId}
                  onClick={() => {
                    setSelectedCoinId(coin.coinId);
                  }}
                  className={classes.cryptoMenuItem}
                >
                  <CoinMenuItem {...coin} />
                </MenuItem>
              ))}
            </LightSelect>
          </InputWithTitle>
          <InputWithTitle
            title={`Enter amount to pay${selectedCoinId === -1 ? '' : ` in ${coins[selectedCoinId].symbol}`}`}
            titleRightItem={amountError
              && (
                <Typography className={classes.errorText} palette="red" paletteColor={600}>
                  Invalid amount
                </Typography>
              )}
          >
            <LightTextField
              value={amount}
              type="number"
              onChange={handleChangeAmount}
              error={amountError}
              onBlur={() => { if (!isAmountValid()) setAmountError(true); }}
              placeholder="Amount"
            />
          </InputWithTitle>
          {activeWalletInfo?.walletProvider !== 'Reporting' && (
            <InputWithTitle title="Tag">
              <LightAutocomplete
                options={[{ tag: '', tagId: 0 }, ...tags]}
                getOptionLabel={(option) => option.tag}
                multiple
                defaultValue={[]}
                onChange={(event, value, optionAction, change) => {
                  if (change !== undefined && change.option.tagId === 0) setIsAddTagModalOpen(true);
                  else setSelectedTags(value);
                }}
                renderTags={(tagValue) => tagValue.map((tag) => (
                  <LightFrenchFries
                    key={tag.tagId}
                    label={tag.tag}
                    autoAssignColor
                    size="small"
                    className={classes.chip}
                  />
                ))}
                value={selectedTags}
                renderOption={(option) => (option.tagId === 0 ? (
                  <Typography component="span" display="inline" variant="h6" palette="green" noWrap>
                    <SVG width={20} height={20} src="icons/reporting/ic_add_item.svg" /> add tag
                  </Typography>
                )
                  : (
                    <LightFrenchFries
                      label={option.tag}
                      autoAssignColor
                      size="small"
                    />
                  )
                )}
                renderInput={(params) => (
                  <LightTextField
                    label=""
                    placeholder="Tag"
                    {...params}
                    InputProps={{
                      ...params.InputProps,
                    }}
                  />
                )}
              />
            </InputWithTitle>)}
          { isAllWallets() && (
            <InputWithTitle title="Save to">
              <SelectWallet
                selectedWalletId={selectedWalletId}
                setSelectedWalletId={setSelectedWalletId}
                disabled={editPayrollId !== undefined}
              />
            </InputWithTitle>
          )}
        </Box>
        <Box className={classes.buttonsWrapper}>
          <Button variant="secondary" className={classes.button} onClick={handleOnClose}>
            Cancel
          </Button>
          <Button
            className={classes.button}
            disabled={saveButtonDisabled}
            onClick={handleOnSave}
          >
            Save
          </Button>
        </Box>
      </Box>
      {isLoading && (
        <OverlayLoading />
      )}
      <AddTagModal
        showModal={isAddTagModalOpen}
        setShowModal={setIsAddTagModalOpen}
        callbackFnc={(tagId: number, tag: string) => setSelectedTags((prev) => ([...prev, { tagId, tag }]))}
      />
    </LightModal>
  );
};
