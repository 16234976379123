import { Dialog, DialogContent, DialogTitle, IconButton, makeStyles } from '@material-ui/core';
import { MessageHandler } from '@sumsub/websdk';
import { WebSDKEvents } from '@sumsub/websdk/types/types';
import SumsubWebSdk from '@sumsub/websdk-react';
import axios from 'axios';
import React, { FC, useEffect, useState } from 'react';

import Headers from '../../services/headers';

type KycModalProps = {
  open: boolean
  onClose: () => void
}

const useStyles = makeStyles({
  root: {
    alignItems: 'center',
    minHeight: 320,
  },
  titleRow: {
    display: 'flex',
    justifyContent: 'end',
  },
  closeButton: {
    width: 20,
    height: 20,
    filter: 'invert(78%) sepia(16%) saturate(300%) hue-rotate(186deg) brightness(98%) contrast(91%)',
  },
});

const KycModal: FC<KycModalProps> = ({ open, onClose }) => {
  const classes = useStyles();

  const [token, setToken] = useState<string>('');
  useEffect(() => {
    (async () => {
      const res = await axios.get('/api/user/getKYCAccessToken', {
        headers: { ...Headers.def(), ...Headers.auth() },
      });
      setToken(res.data.token);
    })();
  }, []);

  const messageHandler: MessageHandler = (type, payload) => {
    if (type === 'idCheck.onApplicantLoaded') {
      axios.post('/api/user/updateKYCApplicantId', {
        applicantId: (payload as WebSDKEvents['idCheck.onApplicantLoaded']).applicantId,
      }, {
        headers: { ...Headers.def(), ...Headers.auth() },
      }).then(() => {});
    }
  };

  if (token === '') return null;
  return (
    <Dialog
      classes={{ paper: classes.root }}
      scroll="body"
      open={open}
    >
      <DialogTitle className={classes.titleRow}>
        <IconButton className={classes.closeButton} onClick={onClose}>
          <img alt="Close" src="/icons/dashboard/ic_cross.svg" />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <SumsubWebSdk
          accessToken={token}
          expirationHandler={() => Promise.resolve('dfsdaf')}
          onMessage={messageHandler}
        />
      </DialogContent>
    </Dialog>
  );
};

KycModal.displayName = 'KycModal';
export { KycModal };
