import { Box, makeStyles, Typography, useMediaQuery, useTheme } from '@material-ui/core';
import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import SVG from 'react-inlinesvg';
import { WorkItem, WorkItemName, WorkItemStatus } from 'services/types/workItem';

import { Card } from '../../elements';
import { DepositEvent } from '../../models';
import { CoinsWithMasterCoins } from '../../services/types/coin';
import WorkItemService from '../../services/workItem-service';
import { WithdrawalProgressBar } from './WithdrawalProgressBar';

type WithdrawStatusProps = {
  coins: CoinsWithMasterCoins;
  event?: DepositEvent;
  workItem: WorkItem;
};

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',

    padding: '2em 0em 1em',
    width: '100%',

    background: '#FFFFFF',
    boxShadow: '0px 4px 32px rgba(43, 43, 48, 0.04)',
    borderRadius: '8px',

    [theme.breakpoints.up('md')]: {
      padding: '2.2em 0em',
    },
  },

  titleBox: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',

    marginBottom: '0.8em',
    [theme.breakpoints.up('sm')]: {
      marginBottom: '1.5em',
    },
  },

  cardRoot: {
    overflow: 'hidden',
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.12)',

    padding: 16,
    height: '100%',
    width: '100%',

    background: theme.palette.common.white,
    border: '2px dashed',
    borderColor: theme.palette.grey['200'],

    [theme.breakpoints.up('sm')]: {
      padding: 32,
    },
  },
  contentWrapper: { zIndex: 2, display: 'flex', flexDirection: 'column', gap: 8 },
  cardTitle: {
    fontWeight: 600,
    '& span': { color: theme.palette.yellow[600] },
  },

  coinRow: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    columnGap: 8,
    marginTop: 20,
  },
  coinPrice: { color: theme.palette.grey['500'] },

  progressRow: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',

    marginTop: 24,
    width: '100%',
  },
  delayed: { color: theme.palette.yellow['600'] },
  progressNumber: { color: theme.palette.green['500'] },
  progressBarRoot: {
    marginTop: 4,
    width: '100%',
    height: 12,
    borderRadius: 8,

    [theme.breakpoints.down('sm')]: {
      height: 8,
    },
  },
  progressBarBar: {
    borderRadius: 8,
    backgroundColor: theme.palette.green['500'],
  },
  progressBarBackground: { backgroundColor: theme.palette.green['200'] },

  rebalanceText: {
    marginTop: 20,
    color: theme.palette.grey['400'],
    fontSize: 12,
    fontWeight: 500,
    lineHeight: '16px',
  },
  bg1: { position: 'absolute', width: 78, height: 78, left: -6, top: -36, zIndex: 1 },
  bg2: { position: 'absolute', width: 42, height: 42, right: -6, top: 12, zIndex: 1 },
  bg3: { position: 'absolute', width: 42, height: 42, left: 74, bottom: -16, zIndex: 1 },
}));

enum WithdrawalJobStatus {
  INIT = 'INIT',
  DETERMINING_WALLET_BALANCES = 'DETERMINING_WALLET_BALANCES',
  CALCULATING_TARGET_BUNDLE = 'CALCULATING_TARGET_BUNDLE',
  REBALANCING = 'REBALANCING',
  WITHDRAWING = 'WITHDRAWING',
  DONE = 'DONE',
}

interface WithdrawWorkItemData {
  subStatus?: WithdrawalJobStatus;
}

interface WithdrawalWorkItemParam {
  to: string;
  from: number;
  coinId: number;
  percentage: number;
}

const WithdrawStatus: FC<WithdrawStatusProps> = ({ coins, workItem }) => {
  const classes = useStyles();
  const status = (workItem.data as unknown as WithdrawWorkItemData)?.subStatus;
  const params: WithdrawalWorkItemParam = workItem.parameters;
  const isTokenWithdrawal = workItem.name === WorkItemName.WithdrawalToken;

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [delayed, setDelayed] = useState<boolean>(false);

  useEffect(() => {
    const checkDelayedCallback = () => {
      setDelayed(WorkItemService.checkDelayed(new Date(workItem.openedTs)));
    };

    checkDelayedCallback();

    const interval = setInterval(checkDelayedCallback, 60000);
    return () => clearInterval(interval);
  }, [workItem]);

  const stepMap: Record<WithdrawalJobStatus, number> = {
    [WithdrawalJobStatus.INIT]: 1,
    [WithdrawalJobStatus.DETERMINING_WALLET_BALANCES]: 2,
    [WithdrawalJobStatus.CALCULATING_TARGET_BUNDLE]: 2,
    [WithdrawalJobStatus.REBALANCING]: 3,
    [WithdrawalJobStatus.WITHDRAWING]: isTokenWithdrawal ? 3 : 4,
    [WithdrawalJobStatus.DONE]: isTokenWithdrawal ? 4 : 5,
  };

  const ProgressText = useCallback(() => {
    let text: string;
    if (delayed) { text = 'Delayed'; }
    else if (isTokenWithdrawal) {
      switch (status) {
        case WithdrawalJobStatus.INIT:
          text = workItem.status === WorkItemStatus.WaitingForApproval ?
            'Your withdrawal is waiting for approval.' :
            'Your withdrawal has started.';
          break;
        case WithdrawalJobStatus.DETERMINING_WALLET_BALANCES:
          text = 'Looking for optimal gas fees and speed.';
          break;
        case WithdrawalJobStatus.WITHDRAWING:
          text = 'Sending your withdrawal. This may take a few minutes.';
          break;
        default:
          text = workItem.status === WorkItemStatus.WaitingForApproval
            ? 'Your withdrawal is waiting for approval.' : '';
          break;
      }
    } else {
      switch (status) {
        case WithdrawalJobStatus.INIT:
          text = 'At the gas station now. Txs require native gas to transact so we’re filling up for you now.';
          break;
        case WithdrawalJobStatus.DETERMINING_WALLET_BALANCES:
        case WithdrawalJobStatus.CALCULATING_TARGET_BUNDLE:
          text = 'Calculating optimal cost saving path. We’re finding the most cost efficient and liquid swaps for you.';
          break;
        case WithdrawalJobStatus.REBALANCING:
          text = 'Portfolio rebalancing. Updating your wallets to match your target ratios. ' +
            'This could take up to 1 hour.';
          break;
        case WithdrawalJobStatus.WITHDRAWING:
          text = 'Withdrawal sending. We\'re swapping & sending the selected crypto using optimal cost savings.';
          break;
        default:
          text = '';
          break;
      }
    }

    return status === WithdrawalJobStatus.DONE ? null
      : (<Box className={`${delayed && classes.delayed} ${classes.progressRow}`}>
        <Typography variant="h6">{text}</Typography>
      </Box>);
  }, [workItem, delayed, isTokenWithdrawal]);

  const headlineText = useMemo(() => {
    if (delayed) return (<>Withdrawal <span>delayed</span> due to network congestion</>);
    if (workItem.status === WorkItemStatus.WaitingForApproval) return <>Your withdrawal is waiting for approval</>;
    return <>Your withdrawal is in progress</>;
  }, [workItem]);

  return (
    <Card className={classes.cardRoot}>
      <SVG src="/icons/ic_bg.svg" className={classes.bg1} />
      <SVG src="/icons/ic_bg.svg" className={classes.bg2} />
      <SVG src="/icons/ic_bg.svg" className={classes.bg3} />

      <Box className={classes.contentWrapper}>
        <Typography variant="h5" className={classes.cardTitle} align="center">
          {headlineText}
        </Typography>
        {status !== WithdrawalJobStatus.DONE && (
          <Typography variant={`${isMobile ? 'h6' : 'h4'}`} align="center">{delayed
            ? 'Don\'t worry, your assets are safe. Our systems is retrying every few minutes. ' +
            'This can take up to 24 hours.'
            : 'You have initiated a withdrawal. Depending on the complexity, network congestion, ' +
            'and wallet provider, this may take up to 24 hours.'}
          </Typography>
        )}

        <Box className={classes.coinRow}>
          <SVG
            src={coins[params.coinId.toString() ?? '']
              ? coins[params.coinId.toString() ?? ''].masterCoin.icon
              : '/icons/coin_icon/fallback-1.svg'}
            width={40}
            height={40}
          />

          {/* removed due to incorrect prices. Needs fixing */}
          {/* <Typography variant="h3">{event?.coins?.[0].quantity?.toFixed(8)}</Typography> */}
          {/* <Typography variant="h5" className={classes.coinPrice}> */}
          {/*  ≈ ${((event?.price ?? 0) * -1).toFixed(2)} */}
          {/* </Typography> */}
        </Box>

        <ProgressText />
        <WithdrawalProgressBar currentStep={stepMap[status ?? WithdrawalJobStatus.INIT]} numStep={isTokenWithdrawal ? 4 : 5} delayed={delayed} />

        {!delayed && (
          <Typography className={classes.rebalanceText} align="center">
            {status === WithdrawalJobStatus.DONE
              ? 'Your cryptos have been delivered. This message will automatically disappear after ' +
              'your leave this page.'
              : (workItem.name === WorkItemName.Withdrawal ? 'Account will be rebalanced automatically' : '')}
          </Typography>
        )}
      </Box>
    </Card>
  );
};

WithdrawStatus.displayName = 'WithdrawStatus';
export { WithdrawStatus };
