import {
  Box,
  createStyles,
  makeStyles,
  Typography,
} from '@material-ui/core';
import { CustomRadioButton } from 'components/CustomRadioButton';
import * as React from 'react';
import { EarningType } from 'store/zustand/InterestEarning';
import { useStore as usePortfolioSettingStore } from 'store/zustand/PortfolioSetting';

const useStyles = makeStyles((theme) => createStyles({
  optionItem: {
    position: 'relative',
    border: '1px solid #DEE3EF',
    borderRadius: '8px',
    display: 'flex',
    flexFlow: 'column',
    '& .highlight': {
      padding: '2px 8px',
      borderRadius: '4px',
      marginBottom: '-4px',
    },
    alignItems: 'center',
    padding: '9px 17px 13px',
    gap: '4px',
    textAlign: 'center',
    flexGrow: 1,
    cursor: 'pointer',
    height: '100%',
    margin: '0 6px',
  },
  disabled: {
    opacity: 0.5,
    cursor: 'not-allowed',
  },
  selected: {
    border: '2px solid #37E2C3',
    padding: '8px 16px 12px',
  },
  icon: {
    width: '40px !important',
    height: 40,
    userDrag: 'none',
    userSelect: 'none',
  },
  title: {
    fontSize: '14px',
    lineHeight: '24px',
    padding: '4px 0',
    width: '100%',
    color: theme.palette.grey[800],
  },
  subTitle: {
    fontWeight: 400,
    color: theme.palette.grey[600],
  },
  selectBox: {
    right: 0,
    top: 0,
    margin: '20px',
    position: 'absolute',
  },
}));

export interface InterestEarningTypeItemProps {
  title: string;
  subTitle: string;
  image: string;
  className?: string;
  badge?: string;
  type: EarningType;
  disabled?: boolean;
}

const InterestEarningTypeItem = ({ image, subTitle, title, className, badge, type, disabled }: InterestEarningTypeItemProps) => {
  const classes = useStyles();
  const [selectedEarningType, setSelectedEarningType] = usePortfolioSettingStore((state) => [state.selectedEarningType, state.setSelectedEarningType]);

  const isSelected = React.useMemo(() => selectedEarningType === type, [selectedEarningType]);
  const onSelected = () => {
    setSelectedEarningType(type);
  };

  return (
    <Box
      onClick={disabled ? undefined : onSelected}
      className={`${classes.optionItem} ${className || ''} ${(isSelected && classes.selected) || ''} ${(disabled && classes.disabled) || ''}`}
      key={title}
    >
      <CustomRadioButton className={classes.selectBox} isSelected={isSelected} outterCircleRadius={8} innerCircleRadius={5} color="#06D2AE" />
      <img
        className={classes.icon}
        src={`${image}@1x.png`}
        srcSet={`${image}@1x.png 1x, ${image}@2x.png 2x, ${image}@3x.png 3x`}
        alt={title}
      />
      {badge && <Typography variant="h6" className="highlight">{badge}</Typography>}
      <Typography variant="h6" className={classes.title}>{title}</Typography>
      <Typography variant="h6" className={classes.subTitle}>{subTitle}</Typography>
    </Box>
  );
};

export default InterestEarningTypeItem;
