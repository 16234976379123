import React from 'react';
import SharedStateComponent from '../components/SharedStateComponent.js';

import AuthService from '../services/auth-service.js';

import { Container, Row, Col, Modal } from 'react-bootstrap';
import ConnectExchange from '../pages/mobile/ConnectExchange.js';

import { ExitGreyIcon, LogoAndBinanceIcon } from '../components/Icons.js';

export default class LinkBinanceModal extends SharedStateComponent {
  constructor(props) {
    super(props);
    this.goToNext = this.goToNext.bind(this);
  }

  goToNext(redirect) {
    AuthService.updateUserStep()
      .then(() => {
        this.props.history.push(redirect);
      });
  }

  render() {
    const handleClose = () => {
      this.props.hide ? this.props.hide() : this.updateState(false);
    };

    return (
      <Modal
        centered
        show={this.props.show ? this.props.show : this.getState()}
        onHide={handleClose}
      >
        <Modal.Body className="p-4">
          <Container>
            <Row className="mt-0 justify-content-end">
              <Col className="text-right pr-0" xs={3} onClick={handleClose}>
                <ExitGreyIcon className="pointer"/>
              </Col>
            </Row>

            <Row><Col className="text-center">
              <LogoAndBinanceIcon/>
            </Col></Row>

            <Row className="mt-4"><Col>
              <h5 className="text-center mb-3">
                Before linking your account
              </h5>
              <p>
                Think of this as your "Coinbag only account." Only hold funds you wish to be managed by Coinbag
                there. We suggest creating a separate account or wallet if you want to invest in additional
                cryptos.
              </p>

              <p><b>Important note</b></p>
              <ol>
                <li>Once connected, Coinbag will build your portfolio using the full account balance.</li>
                <li>You should not buy/sell coins with this account as Coinbag will automatically do this for you.</li>
                <li>You can deposit or send funds to this account and Coinbag will automatically invest to match your
                  risk profile.
                </li>
                <li>You can stop Coinbag at any point and withdraw funds directly from Binance.</li>
              </ol>
            </Col></Row>

            <Row><Col>
              <button className="btn-inverse btn-block" onClick={() => this.goToNext(ConnectExchange.routePath)}>
                Link my account
              </button>
            </Col></Row>
          </Container>
        </Modal.Body>
      </Modal>
    );
  }
}
