import { Box, Hidden, InputAdornment, makeStyles,Popover } from '@material-ui/core';
import { Button, Typography } from 'elements';
import { LightTextField } from 'elements/LightTextField';
import ManageAccountNavBar from 'elements/ManageAccountNavBar';
import * as React from 'react';
import SVG from 'react-inlinesvg';
import { TeamAccessRoute } from 'routes';
import { useStore as useUserStore } from 'store/zustand/User';

import InviteMemberModal from './InviteMemberModal';
import { RemoveMemberModal } from './RemoveMemberModal';
import TeamMemberPopover from './TeamMemberPopover';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    padding: 24,
    height: '100%',
    [theme.breakpoints.down('sm')]: {
      padding: 0,
    },
  },
  headerWrapper: {
    display: 'flex',
    width: '100%',
    gap: 12,
    '& .right': {
      display: 'flex',
      gap: 12,
      [theme.breakpoints.down('sm')]: {
        width: '100%',
        display: 'grid',
        gridTemplateColumns: '1fr 170px',
      },
    },
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      padding: 16,
      borderBottom: `1px solid ${theme.palette.grey[200]}`,
    },
    [theme.breakpoints.up('md')]: {
      marginBottom: 24,
      justifyContent: 'space-between',
      alignItems: 'center',
    },
  },
  inviteButton: {
    borderRadius: 8,
    minWidth: 170,
    maxWidth: 170,
    '& svg': {
      width: 20,
      height: 20,
      marginLeft: 8,
    },
    '& span': {
      fontSize: 14,
      lineHeight: '24px',
      fontWeight: 500,
    },
  },
  tableWrapper: {
    display: 'flex',
    flexGrow: 1,
    [theme.breakpoints.up('md')]: {
      borderRadius: 8,
      boxShadow: '0px 4px 36px rgba(99, 116, 165, 0.08), 0px 4px 32px rgba(187, 196, 221, 0.1)',
      overflow: 'hidden',
    },
  },
  table: {
    display: 'grid',
    gridTemplateColumns: '56px repeat(3, 1fr) 40px',
    '& > *': {
      display: 'flex',
      alignItems: 'center',
      borderBottom: `1px solid ${theme.palette.grey[200]}`,
      padding: '16px 0 16px 16px',
      [theme.breakpoints.down('sm')]: {
        padding: '8px 0 8px 12px',
      },
      '&#menu': {
        padding: '16px 16px 16px 0',
      },
    },
    flexGrow: 1,
    marginBottom: 'auto',
    [theme.breakpoints.down('sm')]: {
      gridTemplateColumns: '68px 1fr 40px',
      '& #email, #name': {
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
        overflow: 'hidden',
      },
    },
  },
  tableHeader: {
    background: '#FBFCFF',
    padding: '6px 0 6px 16px',
    '&#name-header': {
      gridColumn: '1 / span 2',
    },
  },
  memberCount: {
    fontSize: 12,
    fontWeight: 400,
    lineHeight: '20px',
    color: theme.palette.grey[400],
    marginLeft: 8,
  },
  avatar: {
    position: 'relative',
    '& img': {
      userDrag: 'none',
      userSelect: 'none',
      [theme.breakpoints.up('md')]: {
        width: 40,
        height: 40,
        borderRadius: 20,
      },
      [theme.breakpoints.down('sm')]: {
        width: 52,
        height: 52,
        borderRadius: 26,
      },
    },
    '& > .icon': {
      position: 'absolute',
      width: 16,
      height: 16,
      top: 8,
      right: 0,
      border: '2px solid #FFFFFF',
      borderRadius: 8,
      display: 'flex',
      overflow: 'hidden',
      '& svg': {
        margin: -2,
      },
    },
    [theme.breakpoints.down('sm')]: {
      paddingLeft: 16,
    },
  },
  name: {
    [theme.breakpoints.down('sm')]: {
      display: 'flex',
      flexDirection: 'column',
      gap: 4,
      alignItems: 'flex-start',
      justifyContent: 'center',
    },
  },
  status: {
    display: 'flex',
    gap: 4,
    '& span': {
      fontSize: 12,
      fontWeight: 400,
      lineHeight: '16px',
    },
    '& #icon': {
      display: 'flex',
      width: 16,
      height: 16,
      '& svg': {
        width: 16,
        height: 16,
      },
    },
  },
  noData: {
    padding: 0,
    gridColumn: '1 / span all',
    borderBottom: 'none',
    color: theme.palette.grey[400],
    fontSize: 20,
    lineHeight: '24px',
    fontWeight: 500,
    textAlign: 'center',
    justifySelf: 'center',
    margin: '84px 16px',
  },
  threeDotsIconsWrapper: {
    width: 24,
    height: 24,
    display: 'flex',
    alignItems: 'center',
    '&:hover': {
      cursor: 'pointer',
    },
    '&.active': {
      border: `1px solid ${theme.palette.green[200]}`,
      borderRadius: 4,
      '& circle': {
        fill: theme.palette.green[500],
      },
    },
  },
  paperProps: {
    borderRadius: 8,
    width: 216,
    boxShadow: '0px 4px 32px rgba(105, 107, 122, 0.24)',
  },
}));

const TeamAccess = () => {
  const classes = useStyles();
  const [openInviteModal, setOpenInviteModal] = React.useState(false);
  const [teamMembers, fetchAllowedUsers] = useUserStore((state) => [state.teamMembers, state.fetchAllowedUsers]);
  const [search, setSearch] = React.useState('');
  const [menuAnchorEl, setMenuAnchorEl] = React.useState<HTMLElement | null>(null);
  const [activeMemberId, setActiveMemberId] = React.useState(0);
  const [showRemoveModal, setShowRemoveModal] = React.useState(false);

  React.useEffect(() => {
    fetchAllowedUsers(false);
  }, []);

  const users = React.useMemo(() => {
    if (search.length > 0) {
      return teamMembers?.filter(
        (u) => `${u.firstName || ''} ${u.lastName || ''} ${u.email || ''}`.toLowerCase().indexOf(search.toLowerCase()) >= 0,
      );
    }
    return teamMembers;
  }, [search, teamMembers]);

  const getStatusIcon = (isVerify: boolean) => (
    <SVG
      src={isVerify ? '/icons/ic_check.svg' : '/icons/ic_time.svg'}
    />
  );

  return (
    <>
      <ManageAccountNavBar />
      <Box className={classes.root}>
        <Box className={classes.headerWrapper}>
          <Typography variant="h5" paletteColor={700}>
            Team Members
            <Hidden mdUp>
              <Typography component="span" className={classes.memberCount}>{teamMembers?.length ?? 0} members</Typography>
            </Hidden>
          </Typography>
          <Box className="right">
            <LightTextField
              placeholder="Find Member"
              value={search}
              onChange={(e) => setSearch(e.target.value)}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end" onClick={() => { }}>
                    <SVG src="/icons/ic_search.svg" />
                  </InputAdornment>
                ),
              }}
            />
            <Button className={classes.inviteButton} onClick={() => setOpenInviteModal(true)}>
              Invite Member <SVG src="/icons/ic_add.svg" />
            </Button>
          </Box>
        </Box>
        <Box className={classes.tableWrapper}>
          <Box className={classes.table}>
            <Hidden smDown>
              <Typography className={classes.tableHeader} id="name-header" variant="h4" paletteColor={500}>
                Name
                <Typography component="span" className={classes.memberCount}>{teamMembers?.length ?? 0} members</Typography>
              </Typography>
              <Typography className={classes.tableHeader} variant="h4" paletteColor={500}>Email</Typography>
              <Typography className={classes.tableHeader} variant="h4" paletteColor={500}>Status</Typography>
              <Box className={classes.tableHeader} />
            </Hidden>
            {(users?.length ?? 0) > 0 ? users?.map((member, index) => (
              <React.Fragment key={member.userId || index}>
                <Box className={classes.avatar}>
                  <img alt="user" src={`/api/images/ape?user=${member.userId}`} />
                  <Hidden mdUp>
                    <Box className="icon">
                      {getStatusIcon(member?.isVerified ?? false)}
                    </Box>
                  </Hidden>
                </Box>
                <Box className={classes.name}>
                  {(member.firstName || member.lastName) ? (
                    <Typography id="name" variant="h5" paletteColor={700} component="span">
                      {member.firstName} {member.lastName}
                    </Typography>
                  ) : (
                    <Typography id="name" variant="h5" paletteColor={500} component="span">
                      N/A
                    </Typography>
                  )}
                  <Hidden mdUp>
                    <Typography id="email" variant="h6" paletteColor={400} component="span">
                      {member.email}
                    </Typography>
                  </Hidden>
                </Box>
                <Hidden smDown>
                  <Typography id="email" variant="h6" paletteColor={700}>{member.email}</Typography>
                  <Box id="status" className={classes.status}>
                    <Box id="icon">
                      {getStatusIcon(member?.isVerified ?? false)}
                    </Box>
                    <Typography
                      component="span"
                      paletteColor={600}
                      palette={member?.isVerified ? 'green' : 'yellow'}
                    >
                      {member?.isVerified ? 'Active' : 'Invitation sent'}
                    </Typography>
                  </Box>
                </Hidden>
                <Box id="menu">
                  <Box
                    className={`${classes.threeDotsIconsWrapper} ${activeMemberId === member.userId && menuAnchorEl !== null ? 'active' : ''}`}
                    onClick={(e) => { setActiveMemberId(member.userId); setMenuAnchorEl(e.currentTarget); }}
                  >
                    <SVG src="icons/batch_payroll/ic_three_dots.svg" width={24} height={24} />
                  </Box>
                </Box>
              </React.Fragment>
            )) : (
              <Typography className={classes.noData}>
                {users === undefined ? 'Loading..' : 'No Member'}
              </Typography>
            )}
          </Box>
        </Box>
      </Box>
      <InviteMemberModal open={openInviteModal} setOpen={setOpenInviteModal} />
      <RemoveMemberModal showModal={showRemoveModal} setShowModal={setShowRemoveModal} userId={activeMemberId} />
      <Popover
        transformOrigin={{ vertical: -8, horizontal: 'right' }}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        anchorEl={menuAnchorEl}
        open={menuAnchorEl !== null}
        PaperProps={
          { className: classes.paperProps }
        }
        onClose={() => setMenuAnchorEl(null)}
      >
        <TeamMemberPopover setShowRemoveModal={setShowRemoveModal} onClose={() => setMenuAnchorEl(null)} />
      </Popover>
    </>
  );
};

TeamAccess.displayName = 'TeamAccess';
TeamAccess.routePath = TeamAccessRoute;
TeamAccess.feature = 'teamAccess';
TeamAccess.title = 'Team members';
TeamAccess.loginRequired = true;

export default TeamAccess;
