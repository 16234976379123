import { createStyles } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import LightModal from 'elements/LightModal';
import React from 'react';
import { useStore as useUserStore } from 'store/zustand/User';

import { Front } from './Front';

interface Props {
  showModal: boolean;
  setShowModal: (value: boolean) => void;
}

const useStyles = makeStyles(() => createStyles({
  root: {
    minHeight: 176,
  },
}));

export const AddAddressModal = ({ showModal, setShowModal }: Props) => {
  const classes = useStyles();
  const fetchUser = useUserStore((state) => state.fetchUser);
  const handleOnClose = () => {
    setShowModal(false);
  };
  return (
    <LightModal
      className={classes.root}
      onClose={handleOnClose}
      open={showModal}
      setOpen={setShowModal}
      maxWidth={580}
    >
      <Front callbackFnc={() => { setShowModal(false); fetchUser(true); }} />
    </LightModal>
  );
};
