import { Box, createStyles } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useConnectWallet } from '@web3-onboard/react';
import axios from 'axios';
import { Loading } from 'components';
import { chainIdNativeCoinIdMapper, supportedChainIds } from 'components/DepositAndWithdraw/mappers';
import { TransactionCreatedModal } from 'components/DepositAndWithdraw/TransactionCreatedModal';
import { FAModal } from 'components/FAModal';
import { Button, LightModal, Typography } from 'elements';
import { Scrollable } from 'elements/Scrollable';
import pluralize from 'pluralize';
import * as React from 'react';
import { useEffect, useMemo, useState } from 'react';
import SVG from 'react-inlinesvg';
import { CoinsWithMasterCoins, CoinWithBalance } from 'services/types/coin';
import { EmailCodePurpose } from 'services/types/email-code-purpose';
import { PayrollWithExchangeRate } from 'services/types/payroll';
import { FA, FAResponseStatus } from 'services/types/wallet';
import { FAStatus } from 'services/types/wallet-enum';
import WalletService from 'services/wallet-service';
import { useStore as useCoinsStore } from 'store/zustand/Coin';
import { EstimatedFees, useStore as usePayrollStore } from 'store/zustand/Payroll';
import { useStore as useWalletStore } from 'store/zustand/Wallet';
import { useStore as useWorkItemStore } from 'store/zustand/WorkItem';

import { groupUsdFeesByChain } from './EstimateFee';
import { PayrollSummary } from './PayrollSummary';

interface Props {
  open: boolean;
  // eslint-disable-next-line no-unused-vars
  setOpen: (value: boolean) => void;
  // eslint-disable-next-line no-unused-vars
  setPayrollId: (value?: number) => void;
  payrollId?: number;
}

const useStyles = makeStyles((theme) => createStyles({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: 16,
    width: '100%',
    height: '100%',
    justifyContent: 'space-between',
    [theme.breakpoints.down('xs')]: {
      gap: 0,
    },
  },
  scroll: {
    flexGrow: 1,
    width: '100%',
    maxHeight: 'calc(80vh - 100px)',
    position: 'relative',
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: 24,
    width: '100%',
    [theme.breakpoints.down('xs')]: {
      padding: 16,
      position: 'absolute',
    },
  },
  titleWrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: 12,
    width: '100%',
    maxWidth: '340',
  },
  buttonsWrapper: {
    display: 'flex',
    width: '100%',
    justifyContent: 'space-between',
    [theme.breakpoints.down('xs')]: {
      padding: 16,
      borderTop: `1px solid ${theme.palette.grey[200]}`,
    },
  },
  button: {
    minWidth: 135,
  },
  summary: {
    display: 'flex',
    flexDirection: 'column',
    gap: 12,
    width: '100%',
  },
  loading: {
    position: 'absolute',
    top: 0,
    left: 0,
    zIndex: 2,
    backdropFilter: 'blur(12px)',
    background: 'rgba(255, 255, 255, 0.6)',
  },
}));
export const ConfirmationModal = ({ open, setOpen, payrollId, setPayrollId }: Props) => {
  const classes = useStyles();

  const [payrolls, estimatedFees, estimateFees, pay, singlePay] = usePayrollStore((state) => [
    state.payrolls, state.estimatedFees, state.estimateFees, state.pay, state.singlePay,
  ]);
  const [coins] = useCoinsStore((state) => [state.coins]);
  const [activeWalletBalance, activeWalletInfo] = useWalletStore((state) => [state.activeWalletBalance, state.activeWalletInfo]);
  const [fetchWorkItems] = useWorkItemStore((state) => [state.fetchWorkItems]);

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [singlePayrollFee, setSinglePayrollFee] = useState<EstimatedFees | undefined>();
  const [singlePayrollErr, setSinglePayrollErr] = useState<boolean>(false);
  const [isTransactionCreatedModalOpen, setIsTransactionCreatedModalOpen] = useState<boolean>(false);

  const [{ wallet }] = useConnectWallet();

  const numPayroll = useMemo(() => (
    payrollId !== undefined ? 1
      : payrolls?.filter((p) => p.enable).length ?? 0), [payrolls, payrollId]);

  const chainIdsToWithdraw = useMemo(() => (
    coins && payrolls ? payrolls
      ?.filter((pr) => pr.enable)
      .reduce((prev: string[], curr) => {
        const coinChainId = coins[curr.coinId.toString()]?.chainId;
        const chainIdToAdd = prev.includes(coinChainId) ? [] : [coinChainId];
        return [...prev, ...chainIdToAdd];
      }, []) : []
  ), [payrolls, payrollId, coins]);

  const groupedFeesByChainId = useMemo(() => (
    groupUsdFeesByChain(coins, estimatedFees, payrolls)
  ), [estimatedFees, payrolls, payrollId, coins]);

  useEffect(() => {
    if (payrolls === undefined || estimatedFees === undefined || Object.entries(coins).length === 0) {
      setIsLoading(true);
    } else setIsLoading(false);
  }, [payrolls, estimatedFees, coins]);

  const estimateSinglePayrollGas = async () => {
    if (payrollId !== undefined && payrolls !== undefined) {
      const payroll = payrolls.find((pr) => pr.payrollId === payrollId);
      if (payroll !== undefined) {
        const cancelTokenSource = axios.CancelToken.source();
        const { quantity, coinId, address } = payroll;
        try {
          setIsLoading(true);
          const { usdtAmount, amount } = await WalletService.estimateWithdrawToken(
            quantity ?? 0, coinId, address, cancelTokenSource.token,
          );
          const updatedTs = Date.now();
          setSinglePayrollFee({ updatedTs, fees: { [payrollId]: { fee: amount, usdValue: usdtAmount } } });
          setIsLoading(false);
        } catch (e) {
          setSinglePayrollFee(undefined);
          setSinglePayrollErr(true);
          setIsLoading(false);
        }
      }
    } else {
      setSinglePayrollFee(undefined);
      setSinglePayrollErr(false);
    }
  };

  useEffect(() => {
    estimateSinglePayrollGas();
  }, [payrolls, payrollId]);

  const handleOnClose = () => {
    setOpen(false);
    setTimeout(setPayrollId, 1000);
  };
  const [isFAModalOpen, setIsFAModalOpen] = useState<boolean>(false);

  const handleOnSubmitPayment = async (fa?: FA) => {
    if (payrollId === undefined) {
      return await pay(wallet, fa?.emailCode)
    } else {
      const payroll = payrolls?.find((pr) => pr.payrollId === payrollId)
      return payroll === undefined ? { emailStatus: FAStatus.Unknown, authenticatorStatus: FAStatus.Unknown }
        : await singlePay(wallet, fa?.emailCode, payrollId)
    }
  }

  const handleOnSubmitWithFA = async (fa: FA): Promise<FAResponseStatus> => {
    setIsLoading(true);
    const res = await handleOnSubmitPayment(fa)
    await fetchWorkItems(true);
    setIsLoading(false);
    handleOnClose();
    return res as FAResponseStatus;
  };

  const handleOnSubmitWithoutFA =  async () => {
    handleOnClose();
    setIsTransactionCreatedModalOpen(true);
    await handleOnSubmitPayment();
  }

  const isInsufficientGas = useMemo(() => checkInsufficientGas(coins, undefined, payrollId,
    activeWalletBalance, payrolls, estimatedFees), [coins, payrollId, activeWalletBalance, payrolls, estimatedFees]);

  const summariesMemo = useMemo(() => (
    <Box className={classes.summary}>
      <PayrollSummary
        summary
        payrolls={payrolls}
        coins={coins}
        payrollId={payrollId}
        chainIdsToWithdraw={chainIdsToWithdraw}
        estimatedFees={payrollId ? singlePayrollFee : estimatedFees}
        groupedFeesByChainId={groupedFeesByChainId}
        estimateFees={payrollId === undefined ? estimateFees : estimateSinglePayrollGas}
        singlePayrollErr={singlePayrollErr}
        isInsufficientGas={checkInsufficientGas(coins, undefined, payrollId,
          activeWalletBalance, payrolls, estimatedFees)}
      />
      {payrollId === undefined && chainIdsToWithdraw
        && chainIdsToWithdraw.map((chainId) => (
          <PayrollSummary
            payrolls={payrolls}
            coins={coins}
            payrollId={payrollId}
            chainIdsToWithdraw={chainIdsToWithdraw}
            estimatedFees={estimatedFees}
            groupedFeesByChainId={groupedFeesByChainId}
            estimateFees={estimateFees}
            chainId={chainId}
            singlePayrollErr={singlePayrollErr}
            isInsufficientGas={checkInsufficientGas(coins, chainId, payrollId,
              activeWalletBalance, payrolls, estimatedFees)}
            key={chainId}
          />
        ))}
    </Box>
  ), [payrolls, estimatedFees, coins, chainIdsToWithdraw, singlePayrollFee, singlePayrollErr, payrollId,
    activeWalletBalance]);

  return (
    <>
      <LightModal
        open={open}
        setOpen={setOpen}
        showCloseButton
        maxWidth={520}
        onClose={handleOnClose}
        fullScreenOnMobile
        modalTitle="Confirm payment"
      >
        <Box className={classes.root}>
          <Scrollable className={classes.scroll}>
            <Box className={classes.content}>
              <Box className={classes.titleWrapper}>
                <Typography variant="h4" paletteColor={600} align="center">
                  Payroll will be made to {numPayroll} {pluralize('address', numPayroll)}, total amount in USDT equivalent:
                </Typography>
              </Box>
              {summariesMemo}
            </Box>
          </Scrollable>
          <Box className={classes.buttonsWrapper}>
            <Button variant="secondary" className={classes.button} size="md" onClick={handleOnClose}>
              Deny
            </Button>
            <Button
              className={classes.button}
              size="md"
              onClick={ async () => {
                if (activeWalletInfo?.walletProvider === 'Reporting') {
                  await handleOnSubmitWithoutFA();
                  setOpen(false);
                } else {
                  setIsFAModalOpen(true);
                }}}
              disabled={isInsufficientGas || singlePayrollErr}
            >
              Approve <Box width={24} height={24}><SVG src="/icons/ic_shield.svg" width={24} height={24} /></Box>
            </Button>
          </Box>
        </Box>
        {isLoading && <Loading className={classes.loading} />}
      </LightModal>
      <FAModal
        title={`Confirm Proceeding ${pluralize('Payroll', numPayroll)}`}
        detail="Please enter the 2FA code to proceed with your request."
        onSubmit={handleOnSubmitWithFA}
        open={isFAModalOpen}
        setOpen={setIsFAModalOpen}
        emailParams={{}}
        purpose={EmailCodePurpose.Payroll}
      />
      <TransactionCreatedModal
        open={isTransactionCreatedModalOpen}
        setOpen={setIsTransactionCreatedModalOpen}
        action="payrolls"
      />
    </>
  );
};

export const checkInsufficientGas = (
  coins: CoinsWithMasterCoins,
  chainId?: string,
  payrollId?: number,
  activeWalletBalance?: CoinWithBalance[],
  payrolls?: PayrollWithExchangeRate[],
  estimatedFees?: EstimatedFees,
) => {
  if (activeWalletBalance === undefined || payrolls === undefined
    || estimatedFees === undefined) return false;

  if (Object.entries(estimatedFees.fees).map(([, fee]) => fee.fee).includes(-1)) return false;

  const nativeCoins = Object.entries(chainIdNativeCoinIdMapper)
    .map(([, coinId]) => coinId);

  const nativeCoinsInBalanceByChainId = Object.fromEntries(activeWalletBalance
    .filter((wb) => nativeCoins.includes(wb.coinId))
    .map((wb) => [wb.chainId, wb.balance]));

  if (payrollId !== undefined) {
    const payroll = payrolls.find((pr) => pr.payrollId === payrollId);
    if (payroll === undefined) return true;
    const cid = coins[payroll.coinId]?.chainId;
    return nativeCoinsInBalanceByChainId[cid]
      < (payroll.coinId === chainIdNativeCoinIdMapper[cid ?? ''] ? payroll.quantity ?? 0 : 0) + (estimatedFees.fees[payrollId]?.fee ?? 0);
  }

  const filteredPayrolls = payrolls
    .filter((pr) => nativeCoins.includes(pr.coinId))
    .filter((pr) => coins[pr.coinId]?.chainId === chainId || chainId === undefined)
    .filter((pr) => pr.enable);

  const nativeCoinsToWithdrawByChainId = filteredPayrolls
    .reduce((prev: Record<string, number>, curr) => {
      const cid = coins[curr.coinId.toString()]?.chainId ?? '';
      return { ...prev, [cid]: (prev[cid] ?? 0) + (curr.quantity ?? 0) };
    }, {});

  const estimatedGasByChainId = filteredPayrolls
    .reduce((prev: Record<string, number>, curr) => {
      const cid = coins[curr.coinId]?.chainId ?? '';
      return { ...prev, [cid]: (prev[cid] ?? 0) + (estimatedFees.fees[curr.payrollId]?.fee ?? 0) };
    }, {});

  return supportedChainIds
    .map((cid) => (
      nativeCoinsInBalanceByChainId[cid] ?? 0)
      < (nativeCoinsToWithdrawByChainId[cid] ?? 0) + (estimatedGasByChainId[cid] ?? 0))
    .reduce((prev, curr) => prev || curr, false);
};
