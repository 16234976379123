import { Box, createStyles, makeStyles } from '@material-ui/core';
import { chainIdIconMapper, chainIdMapper } from 'components/DepositAndWithdraw/mappers';
import { Typography } from 'elements';
import pluralize from 'pluralize';
import React from 'react';
import SVG from 'react-inlinesvg';
import { PayrollBatchParameter } from 'services/types/workItemParameter';
import { useStore as useCoinsStore } from 'store/zustand/Coin';

const useStyles = makeStyles(() => createStyles({
  root: {
    display: 'flex',
    flexDirection: 'column',
    gap: 8,
    marginBottom: 8,
    width: '100%',
  },
  item: {
    display: 'flex',
    flexDirection: 'column',
  },
  itemRow: {
    display: 'flex',
    flexWrap: 'nowrap',
    alignItems: 'center',
    columnGap: 4,
  },
  title: {
    marginBottom: 4,
  },
}));

const PayrollDetail = (props: PayrollBatchParameter) => {
  const classes = useStyles();
  const [coins, fetchCoins] = useCoinsStore((state) => [state.coins, state.fetchCoins]);

  React.useEffect(() => {
    fetchCoins();
  }, []);

  return (
    <Box className={classes.root}>
      <Typography variant="h4" paletteColor={700} className={classes.title}>
        Payroll will be sent to {props.payrolls.length} {pluralize('address', props.payrolls.length)}
      </Typography>
      {props.payrolls.map((payroll, index) => (
        <Box className={classes.item} key={index}>
          <Box className={classes.itemRow}>
            <Typography variant="h4" paletteColor={700}>
              {payroll.tag}
            </Typography>
            <Typography variant="h6" paletteColor={500} component="span" middleTruncation>
              {payroll.to}
            </Typography>
          </Box>
          <Box className={classes.itemRow} justifyContent="space-between">
            <Box className={classes.itemRow}>
              <SVG
                src={coins[payroll.coinId]?.masterCoin.icon ?? '/icons/coin_icon/fallback-1.svg'}
                height={16}
                width={16}
              />
              <Typography variant="h6" paletteColor={700} mr={4}>
                {coins[payroll.coinId]?.symbol ?? ''}
              </Typography>
              <SVG
                src={chainIdIconMapper[coins[payroll.coinId.toString()]?.chainId ?? '']
                  ?? '/icons/coin_icon/fallback-1.svg'}
                width={16}
                height={16}
              />
              <Typography variant="h6" paletteColor={700}>
                {(chainIdMapper[coins[payroll.coinId.toString()]?.chainId ?? ''] ?? [])[0] ?? ''}
              </Typography>
            </Box>
            <Box className={classes.itemRow} gridGap={8}>
              <Typography variant="h6" paletteColor={700}>
                Amount
              </Typography>
              <Typography variant="h6" palette="blue" paletteColor={700} align="right">
                {payroll.amount.toLocaleString(undefined, {
                  maximumSignificantDigits: payroll.amount > 1 ? undefined : 6,
                  minimumFractionDigits: payroll.amount > 1e8 ? 2 : undefined,
                })}
              </Typography>
            </Box>
          </Box>
        </Box>
      ))}
    </Box>
  );
};

export default PayrollDetail;
