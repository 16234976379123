import { createTheme } from '@material-ui/core';
import createBreakpoints from '@material-ui/core/styles/createBreakpoints';

declare module '@material-ui/core/styles/createBreakpoints' {
  // eslint-disable-next-line no-unused-vars
  interface BreakpointOverrides {
    xm: true
  }
}

export const breakpoints = createBreakpoints({
  values: {
    xs: 0,
    sm: 600,
    md: 900,
    xm: 1024,
    lg: 1200,
    xl: 1536,
  },
});

const base = createTheme({
  breakpoints,
  overrides: {
    MuiTypography: {
      root: {
      },
      gutterBottom: {
        marginBottom: '10px',
      },
      h1: {
        marginTop: '20px',
        marginBottom: '10px',
        letterSpacing: '2px',
        fontSize: 52,
        fontWeight: 700,
        [breakpoints.down('md')]: {
          fontSize: 38,
        },
        [breakpoints.down('sm')]: {
          fontSize: 28,
        },
      },
      h2: {
        marginTop: '20px',
        marginBottom: '10px',
        letterSpacing: '1px',
        fontSize: 32,
        fontWeight: 700,
      },
      h3: {
        marginTop: '20px',
        marginBottom: '10px',
        fontSize: 18,
        fontWeight: 600,
      },
      h4: {
        fontSize: 16,
        marginTop: '10px',
        marginBottom: '10px',
        letterSpacing: '1px',
        lineHeight: '22px',
        textAlign: 'center',
      },
      h5: {
        fontSize: 16,
        marginTop: '10px',
        marginBottom: '10px',
        letterSpacing: 1.5,
        fontWeight: 400,
      },
      h6: {
        fontSize: 16,
        marginTop: '10px',
        marginBottom: '10px',
        letterSpacing: '1px',
        fontWeight: 400,
      },
      body1: {
        fontSize: 18,
        fontWeight: 400,
        lineHeight: '27px',
        [breakpoints.down('md')]: {
          fontSize: '16px',
        },
      },
      body2: {
        fontSize: 14,
        fontWeight: 300,
        lineHeight: '27px',
      },
    },
    MuiCardContent: {
      root: {
        height: '100%',
      },
    },
    MuiButton: {
      root: {
        textTransform: 'none',
      },
      outlined: {
        borderRadius: '100px',
        fontSize: '18px',
        fontWeight: 'bold',
        letterSpacing: '1px',
        padding: '12px 36px',
        transition: ' all 0.4s ease-in-out',
        cursor: 'pointer',
        border: '2px solid',
      },
      contained: {
        borderRadius: '100px',
        fontSize: '18px',
        fontWeight: 'bold',
        letterSpacing: '1px',
        padding: '12px 36px',
        transition: ' all 0.4s ease-in-out',
        cursor: 'pointer',
        border: '2px solid',
      },
    },
    MuiTextField: {
      root: {
        width: '100%',
      },
    },
    MuiFormHelperText: {
      root: {
        marginTop: '-15px',
        marginBottom: '15px',
        color: '#E12D2D',
      },
    },
    MuiInput: {
      root: {
        border: '1px solid #d5dae2',
        boxShadow: 'none',
        marginBottom: '20px',
        marginTop: '0',
        fontSize: '13px',
        fontWeight: 'normal',
        width: '100%',
        transition: 'border-color ease-in-out .15s,box-shadow ease-in-out .15s',
      },
      input: {
        padding: '15px 10px',
        minHeight: '16px !important',
        height: '16px !important',
        backgroundColor: '#FFFFFF',
        '&[type="password"]': {
          backgroundColor: '#FFFFFF',
          padding: '15px 10px',
          minHeight: '16px',
          height: '16px',
          fontSize: '13px',
          '&:focus': {
            border: 'none',
            padding: '15px 10px',
            minHeight: '16px',
            height: '16px',
            fontSize: '13px',
          },
        },
        '&[type="text"]': {
          backgroundColor: '#FFFFFF',
          padding: '15px 10px',
          minHeight: '16px',
          height: '16px',
          fontSize: '13px',
          '&:focus': {
            border: 'none',
            padding: '15px 10px',
            minHeight: '16px',
            height: '16px',
            fontSize: '13px',
          },
        },
      },
      underline: {
        '&:hover': {
          '&:not(.Mui-disabled)': {
            '&:before': {
              borderBottom: 'none',
            },
          },
        },
        '&:before': {
          borderBottom: 'none',
          transition: 'none',
        },
      },
    },
    MuiLink: {
      root: {
        fontSize: '16px',
        fontWeight: 500,
      },
    },
  },
  typography: {
    fontFamily: [
      'Poppins',
      'sans-serif',
    ].join(','),
    fontSize: 16,
  },
});

export default base;
