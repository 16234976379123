import { Box, Divider, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { AutocompleteRenderInputParams } from '@material-ui/lab';
import { Button } from 'elements';
import { CheckBoxWithText } from 'elements/CheckBoxWithText';
import { InputWithTitle } from 'elements/InputWithTitle';
import { LightAutocomplete } from 'elements/LightAutocomplete';
import LightModal from 'elements/LightModal';
import { LightTextField } from 'elements/LightTextField';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { consentTermsAndConditions, CountryListItem, getCountryList } from 'services';

interface Props {
  showModal: boolean
  setShowModal: () => void;
}

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    textAlign: 'center',

    gap: '16px',
  },
  title: { color: theme.palette.grey['800'] },
  content: { color: theme.palette.grey['700'] },
  a: {
    color: theme.palette.green['600'],
    textDecoration: 'underline',
  },
  divider: {
    backgroundColor: theme.palette.grey['200'],
    margin: '8px 0px',
    height: '1px',
  },
  footer: { color: theme.palette.grey['500'] },
  email: { color: theme.palette.green['500'] },
}));

export const ConsentModal = ({ showModal, setShowModal } : Props) => {
  const classes = useStyles();
  const [isChecked, setIsChecked] = React.useState<boolean>(false);
  const [selectedCountry, setSelectedCountry] =
    React.useState<CountryListItem | null>(null);

  const [countryList, setCountryList] =
    useState<CountryListItem[]>([]);
  useEffect(() => {
    getCountryList().then((list) => setCountryList(list));
  }, []);

  const CountrySelect = () => (
    <InputWithTitle
      title="Please update your country of residence"
      titleRightItem=""
    >
      <LightAutocomplete
        options={countryList}
        getOptionLabel={(option) => option.name}
        value={countryList.find((cL) => cL === selectedCountry) || null}
        onChange={(event, newValue) => { setSelectedCountry(newValue); }}
        loading={countryList.length <= 0}
        getOptionSelected={(option, value) => option.name === value.name}
        renderInput={(params: AutocompleteRenderInputParams) => (
          <LightTextField
            {...params}
            type="text"
            error={false}
            placeholder="Select"
          />
        )}
      />
    </InputWithTitle>
  );

  const handleClickAccept = async () => {
    await consentTermsAndConditions(selectedCountry!.iso2);
    window.onbeforeunload = null;
    window.location.reload();
  };

  return (
    <LightModal open={showModal} setOpen={setShowModal} maxWidth={372}>
      <Box className={classes.root}>
        <Typography variant="h5" className={classes.title}>Welcome to Coinbag</Typography>
        <Typography variant="h6" className={classes.content}>
          Coinbag would like to inform you that
          <b> as of September 13th, 2022 at 12:00 UTC</b>,
          your account is now registered under
          the Coinbag Technologies entity in Hong Kong
          with company number: <b>3189703</b>.
          This has no impact on the operations of your account, but we suggest reading through our
          &thinsp;<a
            className={classes.a}
            href="https://coinbag.finance/terms-of-use">
          terms and conditions
          </a> & <a className={classes.a} href="https://coinbag.finance/privacy-policy">privacy policy</a>
        </Typography>
        <CountrySelect />
        <CheckBoxWithText isChecked={isChecked} setIsChecked={setIsChecked}>
          <Typography variant="h6">
            I accept all the terms and conditions and agree with privacy policy
          </Typography>
        </CheckBoxWithText>
        <Button disabled={!isChecked || !selectedCountry} onClick={handleClickAccept}>
          <Typography variant="h5">Accept</Typography>
        </Button>
        <Divider className={classes.divider} />
        <Typography variant="h6" className={classes.footer}>
          If you have any questions or concerns regarding
          the above changes, please contact us:
        </Typography>
        <Typography variant="h4" className={classes.email}>
          support@coinbag.finance
        </Typography>
      </Box>
    </LightModal>
  );
};
