import { Box, createStyles } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Button, Typography } from 'elements';
import { Scrollable } from 'elements/Scrollable';
import pluralize from 'pluralize';
import React, { useState } from 'react';
import SVG from 'react-inlinesvg';
// eslint-disable-next-line import/extensions
import { FA, FAResponseStatus } from 'services/types/wallet';
import { FAStatus } from 'services/types/wallet-enum';
import {
  WORK_ITEM_REQUIRE_2FA,
  WorkItem,
  WorkItemApproverStatus,
  WorkItemName,
  WorkItemStatus,
} from 'services/types/workItem';
import {
  AddOrUpdateAddressBookParameter,
  AddTagNameParameter,
  AddUserBundleParameter,
  CreateOrEditBundleParameter, CreateWalletParameter,
  PayrollBatchParameter,
  RebalancingParameter,
  RemoveAddressBookParameter,
  RemoveTagNameParameter,
  SetEarningConfigParameter,
  SetRebalancingModeParameter,
  UpdateTagNameParameter,
  WithdrawalParameter,
  WithdrawalTokenParameter,
} from 'services/types/workItemParameter';
import workItemService from 'services/workItem-service';
import { useStore as useUserStore } from 'store/zustand/User';
import { useStore as useWorkItemStore } from 'store/zustand/WorkItem';

import { EmailCodePurpose } from '../../services/types/email-code-purpose';
import { FAModal } from '../FAModal';
import AddOrUpdateAddressBookDetail from './Details/AddOrUpdateAddressBookDetail';
import AddTagNameDetail from './Details/AddTagNameDetail';
import AddUserBundleDetail from './Details/AddUserBundleDetail';
import CreateOrEditBundleDetail from './Details/CreateOrEditBundleDetail';
import CreateWalletDetail from './Details/CreateWalletDetail';
import PayrollDetail from './Details/PayrollDetail';
import RebalanceDetail from './Details/RebalanceDetail';
import RemoveAddressBookDetail from './Details/RemoveAddressBookDetail';
import RemoveTagNameDetail from './Details/RemoveTagNameDetail';
import { SetBasicWalletDetail } from './Details/SetBasicWalletDetail';
import SetEarningConfigDetail from './Details/SetEarningConfigDetail';
import SetRebalancingModeDetail from './Details/SetRebalancingModeDetail';
import UpdateTagNameDetail from './Details/UpdateTagNameDetail';
import WithdrawalDetail from './Details/WithdrawalDetail';
import WithdrawalTokenDetail from './Details/WithdrawalTokenDetail';
import { getWorkItemNotificationTitle, getWorkItemShortName } from './utils';

interface Props {
  item: WorkItem;
  onClose?: () => void;
}

const useStyles = makeStyles((theme) => createStyles({
  root: {
    display: 'flex',
    flexDirection: 'column',
    gap: 8,
    padding: '0 24px 24px',
    [theme.breakpoints.down('xs')]: {
      paddingTop: 16,
      height: '100%',
      justifyContent: 'stretch',
    },
  },
  memberList: {
    display: 'flex',
    flexDirection: 'column',
    gap: 8,
  },
  memberRow: {
    display: 'flex',
    flexWrap: 'nowrap',
    alignItems: 'center',
    gap: 8,
    '& img': {
      width: 32,
      height: 32,
      borderRadius: 16,
      userDrag: 'none',
      userSelect: 'none',
    },
  },
  memberName: {
    fontSize: 12,
    lineHeight: '16px',
    fontWeight: 400,
    color: theme.palette.grey[600],
    '&#approve': {
      color: theme.palette.green[600],
    },
    '&#reject': {
      color: theme.palette.red[500],
    },
  },
  buttonWrapper: {
    display: 'flex',
    flexDirection: 'column',
    gap: 16,
    marginTop: 16,
    justifySelf: 'flex-end',
    [theme.breakpoints.down('xs')]: {
      flexGrow: 1,
      justifyContent: 'flex-end',
    },
  },
  button: {
    display: 'flex',
    flexDirection: 'column',
    gap: 16,
    width: '100%',
    '& svg': {
      marginLeft: 4,
    },
    '&:disabled': {
      '& path': {
        fill: theme.palette.grey[300],
      },
    },
  },
  avatar: {
    position: 'relative',
    '& img': {
      userDrag: 'none',
      userSelect: 'none',
    },
  },
  status: {
    width: 16,
    height: 16,
    position: 'absolute',
    bottom: 0,
    right: -4,
    border: '2px solid #FFFFFF',
    borderRadius: 8,
    display: 'flex',
    overflow: 'hidden',
    background: 'white',
    '& svg': {
      margin: -2,
      width: 16,
      height: 16,
    },
  },
  reject: {
    marginTop: 16,
  },
}));
export const ApprovalContent = ({ item, onClose }: Props) => {
  const classes = useStyles();
  const [fetchWorkItems] = useWorkItemStore((state) => [state.fetchWorkItems]);
  const [user] = useUserStore((state) => [state.user]);
  const [submitting, setSubmitting] = useState(false);
  const [isFAModalOpen, setIsFAModalOpen] = useState<boolean>(false);

  const submitApproval = async (fa?: FA) => {
    const faStatus = await workItemService.approveWorkItem(item.workItemId, fa?.emailCode);
    fetchWorkItems(true);
    setSubmitting(false);
    return faStatus;
  };
  const onApprove = async () => {
    if (WORK_ITEM_REQUIRE_2FA.includes(item.name)) {
      setIsFAModalOpen(true);
    } else {
      setSubmitting(true);
      await submitApproval();
      if (onClose) onClose();
    }
  };
  const handleOnFASubmit = async (fa: FA): Promise<FAResponseStatus> => {
    const faStatus = await submitApproval(fa);
    return faStatus ?? { emailStatus: FAStatus.Unknown, authenticatorStatus: FAStatus.Unknown };
  };

  const onDeny = () => {
    setSubmitting(true);
    const submit = async () => {
      await workItemService.rejectWorkItem(item.workItemId);
      fetchWorkItems(true);
      setSubmitting(false);
      if (onClose) onClose();
    };
    submit();
  };

  const detail = React.useMemo(() => {
    switch (item?.name) {
      case WorkItemName.Withdrawal: {
        const parameters = item.parameters as WithdrawalParameter;
        return <WithdrawalDetail {...parameters} />;
      }
      case WorkItemName.WithdrawalToken: {
        const parameters = item.parameters as WithdrawalTokenParameter;
        return <WithdrawalTokenDetail {...parameters} />;
      }
      case WorkItemName.Rebalancing: {
        const parameters = item.parameters as RebalancingParameter;
        return <RebalanceDetail {...parameters} />;
      }
      case WorkItemName.PayrollBatch: {
        const parameters = item.parameters as PayrollBatchParameter;
        return <PayrollDetail {...parameters} />;
      }
      case WorkItemName.SetEarningConfig: {
        const parameters = item.parameters as SetEarningConfigParameter;
        return <SetEarningConfigDetail {...parameters} />;
      }
      case WorkItemName.AddUserBundle: {
        const parameters = item.parameters as AddUserBundleParameter;
        return <AddUserBundleDetail {...parameters} />;
      }
      case WorkItemName.CreateOrEditBundle: {
        const parameters = item.parameters as CreateOrEditBundleParameter;
        return <CreateOrEditBundleDetail {...parameters} />;
      }
      case WorkItemName.AddOrUpdateAddressBook: {
        const parameters = item.parameters as AddOrUpdateAddressBookParameter;
        return <AddOrUpdateAddressBookDetail {...parameters} />;
      }
      case WorkItemName.RemoveAddressBook: {
        const parameters = item.parameters as RemoveAddressBookParameter;
        return <RemoveAddressBookDetail {...parameters} />;
      }
      case WorkItemName.AddTagName: {
        const parameters = item.parameters as AddTagNameParameter;
        return <AddTagNameDetail {...parameters} />;
      }
      case WorkItemName.UpdateTagName: {
        const parameters = item.parameters as UpdateTagNameParameter;
        return <UpdateTagNameDetail {...parameters} />;
      }
      case WorkItemName.RemoveTagName: {
        const parameters = item.parameters as RemoveTagNameParameter;
        return <RemoveTagNameDetail {...parameters} />;
      }
      case WorkItemName.SetRebalancingMode: {
        const parameters = item.parameters as SetRebalancingModeParameter;
        return <SetRebalancingModeDetail {...parameters} />;
      }
      case WorkItemName.SetBasicWallet: {
        return <SetBasicWalletDetail />;
      }
      case WorkItemName.CreateWallet: {
        const parameters = item.parameters as CreateWalletParameter;
        return <CreateWalletDetail {...parameters} />;
      }
      default:
        return <></>;
    }
  }, [item]);
  const isPending = React.useMemo(() => {
    if (item?.status === WorkItemStatus.Rejected) return false;
    if (item && user && item.status === WorkItemStatus.WaitingForApproval && item?.email === user?.email) {
      return false;
    }
    const approve = item?.approvers.find((approver) => approver.email === user?.email);
    return approve?.status === WorkItemApproverStatus.Pending;
  }, [user, item]);
  const rejectedApprover = React.useMemo(() => item?.approvers
    .filter((approver) => approver.status === WorkItemApproverStatus.Rejected)
    .map(((approver) => approver.email)).join(', '), [item]);
  const itemName = React.useMemo(() => (item ? getWorkItemShortName(item.name) : ''), [item]);

  return item ? (
    <>
      <Scrollable className={classes.root}>
        {detail}
        <Box className={classes.memberList}>
          <Typography variant="h6" paletteColor={700}>
            Initiated by:
          </Typography>
          <Box className={classes.memberRow}>
            <img alt="user" src={`/api/images/ape?user=${item.userId}`} />
            <Typography className={classes.memberName} id="approve">
              {item.email === user?.email ? 'You' : item.email}
            </Typography>
          </Box>
        </Box>
        <Box className={classes.memberList}>
          <Typography variant="h6" paletteColor={700}>
            Approvers:
          </Typography>
          {
            item.approvers.map((approver) => (
              <Box className={classes.memberRow} key={approver.userId}>
                <Box className={classes.avatar}>
                  <img alt="user" src={`/api/images/ape?user=${approver.userId}`} />
                  <Box className={classes.status}>
                    <SVG
                      src={`/icons/${approver.status === WorkItemApproverStatus.Approved ?
                        'ic_check' :
                        approver.status === WorkItemApproverStatus.Rejected ? 'ic_cross' : 'ic_time'}.svg`}
                    />
                  </Box>
                </Box>
                <Typography
                  className={classes.memberName}
                  id={approver.status === WorkItemApproverStatus.Approved ?
                    'approve' :
                    approver.status === WorkItemApproverStatus.Rejected ? 'reject' : ''}
                >
                  {approver.email === user?.email ? 'You' : approver.email}
                </Typography>
              </Box>
            ))
          }
        </Box>
        {(item.status === WorkItemStatus.Rejected && rejectedApprover) && (
          <Typography variant="h6" align="center" paletteColor={600} className={classes.reject}>
            {rejectedApprover} denied the request, check an issue with your team to reactivate the {itemName}
          </Typography>
        )}
        <Box className={classes.buttonWrapper}>
          <Button
            className={classes.button}
            disabled={!isPending || submitting}
            fullWidth
            size="xmd"
            onClick={onApprove}
          >
            {item.name === WorkItemName.PayrollBatch
              ? `Approve ${(item.parameters as PayrollBatchParameter).payrolls.length} ${pluralize(
                'transaction',
                (item.parameters as PayrollBatchParameter).payrolls.length)
              }`
              : 'Approve transaction'}
            <SVG src="/icons/ic_shield.svg" width={24} height={24} />
          </Button>
          <Button
            disabled={!isPending || submitting}
            variant="secondary"
            fullWidth
            size="xmd"
            onClick={onDeny}
          >
            Deny
          </Button>
        </Box>
      </Scrollable>
      <FAModal
        title="Please enter verification code to approve the request"
        detail={getWorkItemNotificationTitle(item.name)}
        onSubmit={handleOnFASubmit}
        open={isFAModalOpen}
        setOpen={setIsFAModalOpen}
        emailParams={{ workItemTopic: getWorkItemNotificationTitle(item.name) }}
        purpose={EmailCodePurpose.Approval}
      />
    </>
  ) : (<></>);
};
