import { Box, makeStyles, Menu, Theme } from '@material-ui/core';
import React, { Dispatch, FC, MouseEvent, ReactNode, SetStateAction, useState } from 'react';
import SVG from 'react-inlinesvg';
import { palette as colors } from 'theme/dashboard';

type DropdownProps = {
  id?: string
  className?: string
  palette?: keyof typeof DropdownPalettes;
  anchorPosition?: 'left' | 'center' | 'right'

  title: string | ReactNode
  iconSrc?: string

  isOpen?: boolean
  setOpen?: Dispatch<SetStateAction<boolean>>
}

const DropdownPalettes = {
  default: {
    background: 'white',
    color: colors.grey['700'],
    colorActive: colors.grey['800'],
    border: colors.grey['200'],
    borderActive: colors.green['500'],
    toggle: colors.grey['200'],
    toggleActive: colors.green['500'],
  },
  blue: {
    background: colors.blue[600],
    color: 'white',
    colorActive: 'white',
    border: colors.blue[600],
    borderActive: colors.blue[600],
    toggle: colors.blue[300],
    toggleActive: colors.blue[300],
  },
  green: {
    background: colors.green[600],
    color: 'white',
    colorActive: 'white',
    border: colors.green[600],
    borderActive: colors.green[600],
    toggle: colors.green[300],
    toggleActive: colors.green[300],
  },
};

const useStyles = makeStyles<Theme, { palette: keyof typeof DropdownPalettes }>((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    columnGap: 6,

    padding: 8,

    fontSize: 12,
    fontWeight: 400,
    lineHeight: '20px',

    background: ({ palette }) => DropdownPalettes[palette].background,
    border: '1px solid',
    borderRadius: 8,

    cursor: 'pointer',
  },

  inactive: {
    color: ({ palette }) => DropdownPalettes[palette].color,
    borderColor: ({ palette }) => DropdownPalettes[palette].border,
  },
  active: {
    color: ({ palette }) => DropdownPalettes[palette].colorActive,
    borderColor: ({ palette }) => DropdownPalettes[palette].borderActive,
  },

  titleRow: {
    flex: 1,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    columnGap: 6,
    minWidth: 0,
  },
  icon: { filter: 'invert(66%) sepia(82%) saturate(3024%) hue-rotate(126deg) brightness(101%) contrast(95%)' },
  toggleClosed: { fill: ({ palette }) => DropdownPalettes[palette].toggle },
  toggleOpen: {
    fill: ({ palette }) => DropdownPalettes[palette].toggleActive,
    transform: 'rotate(180deg)',
  },

  menu: { marginTop: 8 },
  menuPaper: {
    background: theme.palette.common.white,
    boxShadow: '0px 4px 32px rgba(43, 43, 48, 0.06)',

    border: '1px solid',
    borderColor: theme.palette.grey['200'],
    borderRadius: '8px',
  },
  menuList: { padding: 0 },
}));

const Dropdown: FC<DropdownProps> = ({
  id,
  className,
  palette = 'default',
  anchorPosition = 'center',
  title,
  iconSrc,
  isOpen,
  setOpen,
  children,
}) => {
  const classes = useStyles({ palette });
  const [anchor, setAnchor] = useState<HTMLElement | null>(null);

  const handleOpen = (e: MouseEvent<HTMLElement>) => {
    setAnchor(e.currentTarget);
    if (setOpen) setOpen(true);
  };
  const handleClose = () => {
    setAnchor(null);
    if (setOpen) setOpen(false);
  };

  return (
    <>
      <Box
        id={id}
        className={`${classes.root} ${(isOpen ?? anchor) ? classes.active : classes.inactive} ${className}`}
        aria-controls="dropdown"
        aria-haspopup="true"
        onClick={handleOpen}
      >
        <Box className={classes.titleRow}>
          {iconSrc && <img className={classes.icon} alt="" src={iconSrc} width={16} height={16} />}
          {title}
        </Box>

        <SVG
          className={`${isOpen ?? anchor ? classes.toggleOpen : classes.toggleClosed}`}
          src="/icons/dashboard/ic_arrow_down.svg"
          width={16}
          height={16}
        />
      </Box>

      <Menu
        className={classes.menu}
        classes={{ paper: classes.menuPaper, list: classes.menuList }}
        anchorOrigin={{ vertical: 'bottom', horizontal: anchorPosition ?? 'center' }}
        transformOrigin={{ vertical: 'top', horizontal: anchorPosition ?? 'center' }}
        elevation={0}
        id="dropdown"
        anchorEl={anchor}
        getContentAnchorEl={null}
        open={isOpen ?? !!anchor}
        onClose={handleClose}
        autoFocus={false}
        keepMounted
      >
        {children}
      </Menu>
    </>
  );
};

Dropdown.displayName = 'Dropdown';
export { Dropdown };
