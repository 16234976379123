import { Box, createStyles, Link, makeStyles, Theme, useMediaQuery, useTheme } from '@material-ui/core';
import SocialLinks from 'elements/SocialLink';
import { Typography } from 'elements/Typography';
import * as React from 'react';
import { FC } from 'react';
import SVG from 'react-inlinesvg';
import { Link as RouterLink } from 'react-router-dom';
import {
  DisclaimerRoute,
  HomeRoutes,
  OurStoryRoute,
  PrivacyPolicyRoute,
  SignUpIndividualInvestorsRoute,
  SignUpInstitutionalInvestorsRoute,
  SignUpPartnerRoute,
  TermsOfUseRoute,
  WhyCoinbagRoute,
} from 'routes';

import AccountSecurityBadge from './AccountSecurityBadge';

const useStyles = makeStyles((theme: Theme) => createStyles({
  root: {
    zIndex: 2,
    display: 'flex',
    justifyContent: 'center',
    marginTop: 'auto',

    [theme.breakpoints.down('sm')]: {
      filter: 'drop-shadow(0px 4px 40px rgba(81, 89, 111, 0.1))',
    },

    '& a:hover': {
      textDecoration: 'none',
    },
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    maxWidth: '1080px',
    padding: '40px 16px',
    gap: 40,
  },

  row: {
    display: 'flex',
    justifyContent: 'space-between',
    flexWrap: 'nowrap',
  },
  column: {
    display: 'flex',
    flexDirection: 'column',
  },

  footer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    gap: 16,
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column-reverse',
    },
  },

  divider: {
    height: '16px',
    borderRight: '1px solid #BBC4DD',
    margin: '0 28px',
    [theme.breakpoints.down('sm')]: {
      margin: '0 8px',
    },
  },
  hide: {
    display: 'none',
  },
}));

const HomePageLayoutFooter: FC = () => {
  const classes = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <footer className={classes.root}>
      <Box className={classes.content}>
        <Box className={isMobile ? classes.column : classes.row} gridGap={24}>
          <Box className={classes.column} gridGap={20}>
            <Link aria-label="coinbag homepage" href={`${HomeRoutes[1]}#`}>
              <SVG src="/logos/coinbag_long.svg" style={{ width: '130px', height: '32px' }} />
            </Link>
            <AccountSecurityBadge isAuthenticated />
          </Box>

          <Box className={isMobile ? classes.column : classes.row} gridGap={80}>
            <Box className={classes.column} gridGap={30}>
              <Box className={classes.column} gridGap={14}>
                <Typography variant="h5" palette="grey" paletteColor={600}>Follow us</Typography>
                <SocialLinks />
              </Box>
              <Box className={classes.column} gridGap={8}>
                <Typography variant="h5" palette="grey" paletteColor={600}>Contact Us</Typography>
                <Link href="mailto:support@coinbag.finance">support@coinbag.finance</Link>
              </Box>
            </Box>

            <Box className={isMobile ? classes.hide : classes.column} gridGap={12} minWidth={120}>
              <Typography variant="h5" palette="grey" paletteColor={600}>Quick Links</Typography>
              <Link href={OurStoryRoute}>Our story</Link>
              <Link href={WhyCoinbagRoute}>Why Coinbag</Link>
              <Link href={SignUpIndividualInvestorsRoute}>Individuals</Link>
              <Link href={SignUpInstitutionalInvestorsRoute}>Businesses</Link>
              <Link href={SignUpPartnerRoute}>Partner</Link>
              <Link href="https://coinbag.tawk.help" target="_blank" rel="noreferrer">FAQ</Link>
            </Box>
          </Box>
        </Box>

        <Box className={classes.footer}>
          <Typography variant="h4" palette="grey" paletteColor={500}>
            Copyright © {new Date().getFullYear()} Coinbag. All rights reserved.
          </Typography>

          <Box className={classes.row} component="nav" alignItems="center">
            <RouterLink to={TermsOfUseRoute}>
              <Typography variant="h4" palette="grey" paletteColor={500}>Terms&nbsp;of&nbsp;use</Typography>
            </RouterLink>

            <Box className={classes.divider} />
            <RouterLink to={PrivacyPolicyRoute}>
              <Typography variant="h4" palette="grey" paletteColor={500}>Privacy&nbsp;policy</Typography>
            </RouterLink>

            <Box className={classes.divider} />
            <RouterLink to={DisclaimerRoute}>
              <Typography variant="h4" palette="grey" paletteColor={500}>Disclaimer</Typography>
            </RouterLink>
          </Box>
        </Box>
      </Box>
    </footer>
  );
};

HomePageLayoutFooter.displayName = 'Footer';
export { HomePageLayoutFooter };
