import { LinearProgress, makeStyles, Typography } from '@material-ui/core';
import { Card } from 'elements';
import React, { FC, useMemo } from 'react';

import { RebalancingStatus as RebalancingStatusType } from '../services/types/wallet';

type RebalancingStatusProps = {
  className?: string,
  status: RebalancingStatusType;
}

const useStyles = makeStyles((theme) => ({
  root: {
    overflow: 'hidden',
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',

    padding: 16,
    height: '100%',
    width: '100%',

    background: theme.palette.common.white,
    border: '2px dashed',
    borderColor: theme.palette.grey[200],

    [theme.breakpoints.up('sm')]: {
      padding: 32,
    },
  },
  background: {
    position: 'absolute',
    right: 0,
    bottom: 0,
    height: '100%',
  },
  title: {
    [theme.breakpoints.down('sm')]: {
      fontSize: 12,
      fontWeight: 500,
      lineHeight: '20px',
    },
  },
  progressBarRoot: {
    marginTop: 4,
    width: '100%',
    height: 12,
    borderRadius: 8,

    [theme.breakpoints.down('sm')]: {
      height: 8,
    },
  },
  progressBarBar: {
    borderRadius: 8,
    backgroundColor: theme.palette.green[500],
  },
  progressBarBackground: {
    backgroundColor: theme.palette.green[200],
  },
}));

export const shouldShowRebalancingStatus = (status: RebalancingStatusType): boolean => !!status && status !== 'unknown';

const RebalancingStatus: FC<RebalancingStatusProps> = ({ className, status }) => {
  const classes = useStyles();

  const message = useMemo(() => {
    if (status === 'withdrawing-pending' || status === 'withdrawing') {
      return 'Withdrawal is in progress';
    }
    if (status === 'rebalancing-pending' || status === 'rebalancing') {
      return 'Your portfolio rebalancing is in progress';
    }
    if (status === 'reporting-address') {
      return 'Your read-only wallet is being imported';
    }
    if (status === 'payroll-in-progress') {
      return 'Payroll is in progress';
    }
    if (status === 'payroll-pending') {
      return 'Payroll is waiting for approval';
    }
    return null;
  }, [status]);

  if (!status || status === 'unknown') return null;
  return (
    <Card className={`${classes.root} ${className ?? ''}`}>
      <img className={classes.background} alt="" src="/images/dashboard/bg_interest_total.svg" />
      <Typography variant="h4" className={classes.title} align="center">
        {message}
      </Typography>
      <LinearProgress
        classes={{
          root: classes.progressBarRoot,
          bar: classes.progressBarBar,
          colorPrimary: classes.progressBarBackground,
        }}
      />
    </Card>
  );
};

RebalancingStatus.displayName = 'RebalancingStatus';
export default RebalancingStatus;
