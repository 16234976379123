import { Box, BoxProps, makeStyles } from '@material-ui/core';
import React, { FC } from 'react';
import { Link, useHistory } from 'react-router-dom';

type BackButtonProps = {
  className?: string
  href?: string
}

const useStyles = makeStyles((theme) => ({
  link: {
    display: 'flex',
    alignItems: 'center',
  },
  root: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    columnGap: 8,

    color: theme.palette.grey[500],
    fontSize: 14,
    fontWeight: 500,
    lineHeight: '24px',
  },
}));

const BackButton: FC<BackButtonProps> = ({ className, href }) => {
  const classes = useStyles();
  const history = useHistory();

  const Content = (props: BoxProps) => (
    <Box {...props}>
      <img alt="" src="/icons/dashboard/ic_arrow_left.svg" width={24} />
      Back
    </Box>
  );

  if (href) {
    return (
      <Link className={`${classes.link} ${className}`} to={href}>
        <Content className={classes.root} />
      </Link>
    );
  }

  return <Content className={`${classes.root} ${className}`} onClick={() => history.goBack()} />;
};

BackButton.displayName = 'BackButton';
export { BackButton };
