import { Box, Link } from '@material-ui/core';
import * as React from 'react';
import SVG from 'react-inlinesvg';

interface SocialLinksProps {
  width?: string;
  align?: string;
}

const SocialLinks = (props: SocialLinksProps) => (
  <Box width={props.width || '100%'} justifyContent={props.align} display="flex" flexWrap="nowrap" gridGap={4}>
    <Link aria-label="Coinbag's linkedin" href="https://www.linkedin.com/company/coinbag" target="_blank" rel="noreferrer">
      <SVG width={24} height={24} src="/icons/ic_linkedin.svg" />
    </Link>
    <Link aria-label="Coinbag's facebook" href="https://www.facebook.com/coinbagapp" target="_blank" rel="noreferrer">
      <SVG width={24} height={24} src="/icons/ic_facebook.svg" />
    </Link>
    <Link aria-label="Coinbag's instagram" href="https://www.instagram.com/coinbag_app/" target="_blank" rel="noreferrer">
      <SVG width={24} height={24} src="/icons/ic_instagram.svg" />
    </Link>
    <Link aria-label="Coinbag's twitter" href="https://twitter.com/coinbag_app" target="_blank" rel="noreferrer">
      <SVG width={24} height={24} src="/icons/ic_twitter.svg" />
    </Link>
  </Box>
);

export default SocialLinks;
