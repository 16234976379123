import { makeStyles,Theme } from '@material-ui/core';
import { ToggleButton, ToggleButtonGroup, ToggleButtonGroupProps } from '@material-ui/lab';
import React, { FC } from 'react';

export type ToggleItem = {
  value: string
  title: string
}

type ToggleGroupProps = ToggleButtonGroupProps & {
  labelClassName?: string
  values: ToggleItem[]
};

const useStyles = makeStyles<Theme, Partial<ToggleGroupProps>>((theme) => ({
  toggleButton: {
    borderRadius: 8,
    padding: ({ size }) => (size === 'medium' ? '10px 8px' : '6px 8px'),
    minWidth: 36,
    borderColor: theme.palette.grey['200'],
  },
  toggleLabel: {
    fontSize: 12,
    fontWeight: 500,
    lineHeight: '12px',
    textTransform: 'none',
  },
  toggleSelected: {
    '&.Mui-selected': {
      color: theme.palette.green['600'],
      backgroundColor: theme.palette.green['100'],
      borderLeft: '1px solid rgba(0, 0, 0, 0.12)',
    },
    '&.Mui-selected:hover': {
      backgroundColor: theme.palette.green['200'],
    },
  },
}));

const LightToggleGroup: FC<ToggleGroupProps> = (props) => {
  const { className, values, value, onChange, ...rest } = props;

  const classes = useStyles({ size: rest.size });
  return (
    <ToggleButtonGroup
      className={className}
      value={value}
      onChange={onChange}
      {...rest}
    >
      {values.map((item, index) => (
        <ToggleButton
          key={index}
          classes={{
            root: classes.toggleButton,
            label: classes.toggleLabel,
            selected: classes.toggleSelected,
          }}
          value={item.value}
        >
          {item.title}
        </ToggleButton>
      ))}
    </ToggleButtonGroup>
  );
};

LightToggleGroup.displayName = 'ToggleGroup';
export { LightToggleGroup };
