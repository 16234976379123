import * as React from 'react';
import { Helmet } from 'react-helmet';
import { DisclaimerRoute, PrivacyPolicyRoute, TermsOfUseRoute } from 'routes';
import { isWalletReporting } from 'utils/domain';

import * as ResponsivePages from '../index';
import { fixedLandingMap } from '../ReferralLanding/ReferralLanding';

interface Props {
  route: string
  pageTitle?: string
}

const Seo = ({ route, pageTitle }: Props) => {
  const kebabCase = route.slice(1);
  const capital = kebabCase.charAt(0).toUpperCase() + kebabCase.slice(1);

  let title = pageTitle ?? capital.split('-').join(' ');
  if (ResponsivePages.Home.routePath.includes(route)) title = 'Professional-grade digital asset management software.';

  const noIndexPages = [
    DisclaimerRoute,
    PrivacyPolicyRoute,
    TermsOfUseRoute,
    ...Object.keys(fixedLandingMap),
    '/rid',
    'notfound',
  ];

  return (
    <Helmet>
      {noIndexPages.find((x) => x === route) && (
        <meta name="robots" content="noindex" />
      )}

      <title>{isWalletReporting() ? 'Walletreporting.com' : `Coinbag: ${title}`}</title>
      <meta name="twitter:card" content="summary" />
      <meta name="twitter:site" content="@coinbag_app" />

      {ResponsivePages.Home.routePath.includes(route) && (
        <link rel="canonical" href="https://www.coinbag.finance/" />
      )}

      <script async src="https://www.googletagmanager.com/gtag/js?id=G-N2XD4VPGJT" />
      <script>
        {`window.dataLayer = window.dataLayer || [];
          function gtag(){dataLayer.push(arguments);}
          gtag('js', new Date());
          gtag('config', 'G-N2XD4VPGJT');`}
      </script>

      <script async src="https://www.googletagmanager.com/gtag/js?id=UA-198809652-1" />
      <script>
        {`window.dataLayer = window.dataLayer || [];
          function ugtag(){dataLayer.push(arguments);}
          ugtag('js', new Date());
          ugtag('config', 'UA-198809652-1');`}
      </script>

      <script>
        {`(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
        new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
        j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
        'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
        })(window,document,'script','dataLayer','GTM-NGR3DFG');`}
      </script>
    </Helmet>
  );
};

Seo.displayName = 'Seo';
export default Seo;
