import { WalletState } from '@web3-onboard/core';
import { ThemingMap } from '@web3-onboard/core/dist/types';
import gnosisModule from '@web3-onboard/gnosis';
import injectedModule from '@web3-onboard/injected-wallets';
import ledgerModule, { LedgerOptionsWCv2 } from '@web3-onboard/ledger';
import { init as initOnboard } from '@web3-onboard/react';
import trezorModule from '@web3-onboard/trezor';
import walletConnectModule from '@web3-onboard/walletconnect';
import { ethers } from 'ethers';

import { chainHexIdMapper } from '../components/DepositAndWithdraw/mappers';
import { MutateAPIResponse } from './types/mutate-api-response';

const injected = injectedModule();
const gnosis = gnosisModule();
const walletConnect = walletConnectModule({
  projectId: 'a4e47e40b2d46c20737168b9807e011d',
  requiredChains: [1, 56, 137],
});
const ledger = ledgerModule({
  walletConnectVersion: 2,
  projectId: 'a4e47e40b2d46c20737168b9807e011d',
  requiredChains: [1, 56, 137],
} as LedgerOptionsWCv2);

const trezor = trezorModule({
  email: 'support@coinbag.finance',
  appUrl: 'https://coinbag.finance',
})

const customTheme: ThemingMap = {
  '--w3o-background-color': '#ffffff',
  '--w3o-foreground-color': '#f7f8fd',
  '--w3o-text-color': '#38425e',
  '--w3o-border-color': 'unset',
  '--w3o-action-color': '#02BD9F',
  '--w3o-border-radius': '8px',
  '--w3o-font-family': 'Poppins',
}

/**
 * Initializes the Onboard API with the specified configuration.
 *
 * @param {Object} config - The configuration object.
 * @param {Array} config.wallets - The array of supported wallets.
 * @param {Array} config.chains - The array of supported blockchain networks.
 * @param {string} config.chains.id - The ID of the blockchain network.
 * @param {string} config.chains.token - The token symbol of the blockchain network.
 * @param {string} config.chains.label - The label of the blockchain network.
 * @param {string} config.chains.rpcUrl - The RPC URL of the blockchain network.
 *
 * @returns {Object} - The initialized Onboard API instance.
 */
export const onBoardApi = initOnboard({
  theme: customTheme,
  appMetadata: {
    name: 'Coinbag',
    icon: '/icons/ic_coinbag.svg',
    logo: '/icons/ic_coinbag_large.svg',
    description: 'Professional-grade tools to de-risk your digital asset holdings and streamline your day-to-day business operations.',
  },
  accountCenter: {
    desktop: {
      enabled: true,
      position: 'bottomLeft',
    },
    mobile: {
      enabled: true,
      position: 'bottomLeft',
    },
  },
  connect: {
    autoConnectLastWallet: true,
    showSidebar: false,
  },
  wallets: [injected, gnosis, walletConnect, ledger, trezor],
  chains: [
    {
      id: '0x1',
      token: 'ETH',
      label: 'Ethereum Mainnet',
      rpcUrl: 'https://1rpc.io/eth',
    },
    {
      id: '0x38',
      token: 'BNB',
      label: 'Binance Smart Chain Mainnet',
      rpcUrl: 'https://1rpc.io/bnb',
    },
    {
      id: '0x89',
      token: 'MATIC',
      label: 'Polygon Mainnet',
      rpcUrl: 'https://1rpc.io/matic',
    },
  ],
  disableFontDownload: true,
});

export const showAccountCenter = () => {
  const ac = document.querySelector('onboard-v2')?.shadowRoot?.querySelector('.ac-trigger');
  if (ac != null) {
    const html = ac as HTMLElement;
    html.click();
  }
};

/**
 * Determines whether the wallet supports the given address.
 *
 * @param {string} findAddress - The address to check for support.
 * @returns {WalletStatus} - The status of the address support.
 */
export const isAddressSupported = (findAddress: string): WalletStatus => {
  const addresses = onBoardApi.state.get().wallets.flatMap(
    (wallet) => wallet.accounts.map((account) => account.address),
  );
  const addressMap: Map<string, number> = new Map();

  for (const address of addresses) {
    addressMap.set(address, (addressMap.get(address) ?? 0) + 1);
  }

  const addressesInWallets = addressMap.get(findAddress) ?? 0;

  if (addresses[0] === findAddress) {
    return WalletStatus.Ok;
  }
  if (addressesInWallets === 1) {
    return WalletStatus.OkAuto;
  }
  if (addressesInWallets > 1) {
    return WalletStatus.OkManual;
  }
  return WalletStatus.No;
};

/**
 * This function is used to switch the address and chain in a wallet.
 * You will need to make sure that the address can be auto switched,
 * or else it will just pick the first one that matches.
 *
 * @param {string} switchAddress - The address to switch to.
 * @param {string} chainId - The ID of the chain to switch to.
 *
 * @returns {Promise<boolean>} - A promise that resolves to true if the address and chain were successfully switched,
 *                               or false otherwise.
 */
export const autoSwitchToAddressAndChain = (switchAddress: string, chainId: string): Promise<boolean> => {
  const onBoardState = onBoardApi.state.get();
  const foundWallet = onBoardState.wallets.find(
    (wallet) => wallet.accounts.some((account) => account.address === switchAddress)
  );

  const currentAddress = onBoardState.wallets[0].accounts[0].address;
  const currentChainId = onBoardState.wallets[0].chains[0].id;

  if (currentAddress === switchAddress && currentChainId === chainId) {
    return Promise.resolve(true);
  }

  if (currentAddress === switchAddress && currentChainId !== chainId) {
    return onBoardApi.setChain({ chainId });
  }

  if (currentAddress !== switchAddress && foundWallet != null) {
    onBoardApi.state.actions.setPrimaryWallet(foundWallet, switchAddress)
    return onBoardApi.setChain({ chainId });
  }

  return Promise.resolve(false);
};

export enum WalletStatus {
  Ok,
  OkAuto,
  OkManual,
  No,
}

export const disconnectAllWallet: () => Promise<Awaited<WalletState[]>[]> = () => {
  const wallets = onBoardApi.state.get().wallets;
  const states = wallets.map((wallet) => onBoardApi.disconnectWallet({ label: wallet.label }));
  return Promise.all(states);
}

/**
 * Applies a custodial transaction filter to a given function
 * and executes the custodial transactions after the original function is called.
 *
 * @template T - The type of the original function
 * @param {T} fn - The original function to be filtered
 * @returns {Promise<ReturnType<T>>} - The result of the original function after applying the custodial transactions
 */
export const custodialTxsFilter = <T extends (...args: never[]) => Promise<any>>(fn: T):
  (wallet?: WalletState | null | undefined, ...args: Parameters<T>) => Promise<Awaited<ReturnType<T>>> => {
  type Params = Parameters<typeof fn>
  return async (...args: [wallet?: WalletState | null, ...args: Params]): Promise<Awaited<ReturnType<T>>> => {
    const [wallet, ...restArgs] = args;
    const res = (await fn(...restArgs)) as Awaited<ReturnType<T>>;
    if (res.doTransaction) {
      const { doTransaction: doTransactions } = res as MutateAPIResponse;
      if (wallet != null) {
        const ethersProvider = new ethers.providers.Web3Provider(wallet.provider, 'any');
        const signer = ethersProvider.getSigner();
        const sortedTransactions = doTransactions.sort((a, b) => a.chainId - b.chainId)

        let chainId = chainHexIdMapper[wallet.chains[0]?.id] ?? '';
        for (const doTransaction of sortedTransactions) {
          const txChainHex = chainHexIdMapper[doTransaction.chainId.toString()];
          const switched = chainId === txChainHex ? true
            : await autoSwitchToAddressAndChain(doTransaction.from, txChainHex)
          if (switched) {
            const transaction = await signer.populateTransaction(doTransaction);
            await signer.sendTransaction(transaction);
          }
          chainId = chainHexIdMapper[doTransaction.chainId.toString()]
        }
      }
    }
    return res;
  };
};
