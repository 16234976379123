import { Box, Typography } from '@material-ui/core';
import React, { FC } from 'react';

type NoDataProps = {
  hasSelectedCoins?: boolean
}

const NoData: FC<NoDataProps> = ({ hasSelectedCoins = true }) => (
  <Box display="flex" justifyContent="center" alignItems="center" height="120px">
    <Typography variant="body1">
      {hasSelectedCoins ? 'Select tokens' : 'No data'}
    </Typography>
  </Box>
);

NoData.displayName = 'NoData';
export default NoData;
