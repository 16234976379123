import { Box, createStyles, makeStyles, useTheme } from '@material-ui/core';
import { getWorkItemIcon, getWorkItemNotificationTitle } from 'components/TransactionApproval/utils';
import { Button, Typography } from 'elements';
import React from 'react';
import SVG from 'react-inlinesvg';
import {
  WorkItem, WorkItemApproverStatus, WorkItemName, WorkItemStatus,
} from 'services/types/workItem';
import { useStore as useUserStore } from 'store/zustand/User';

import { NotificationItemBase } from './NotificationType';

const useStyles = makeStyles((theme) => createStyles({
  wrapper: {
    display: 'flex',
    flexWrap: 'nowrap',
    flexDirection: 'column',
    width: '100%',
    padding: '16px 0',
    borderBottom: `1px solid ${theme.palette.grey[200]}`,
    gap: 12,
  },
  fullSizeWrapper: {
    display: 'grid',
    gridTemplateColumns: '1fr auto',
    width: '100%',
    padding: '16px 0',
    borderBottom: `1px solid ${theme.palette.grey[200]}`,
    '& $detailRow': {
      display: 'grid',
      gridTemplateColumns: '120px 140px 120px',
    },
    '& $status': {
      flexDirection: 'row-reverse',
      justifyContent: 'flex-end',
      gap: 4,
    },
  },
  titleWrapper: {
    display: 'flex',
    flexWrap: 'nowrap',
    gap: 8,
    alignItems: 'center',
  },
  icon: {
    width: 28,
    height: 28,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: 14,
  },
  title: {
    fontSize: 14,
    lineHeight: '20px',
    fontWeight: 500,
    color: theme.palette.grey[700],
  },
  user: {
    fontSize: 12,
    lineHeight: '16px',
  },
  shieldIcon: {
    width: 18,
    height: 18,
    marginLeft: 8,
  },
  detailRow: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    '& button': {
      justifySelf: 'flex-end',
    },
  },
  status: {
    display: 'flex',
    alignItems: 'center',
    gap: 8,
    '&#deny': {
      '& h2': {
        color: theme.palette.red[500],
      },
    },
    '&#approved': {
      '& h2': {
        color: theme.palette.green[600],
      },
      '& path': {
        fill: theme.palette.green[600],
      },
    },
    '&#pending': {
      '& h2': {
        color: theme.palette.yellow[600],
      },
    },
    '& h2': {
      fontSize: 12,
      lineHeight: '24px',
      fontWeight: 500,
    },
    '& svg': {
      width: 18,
      height: 18,
    },
  },
}));

export interface TransactionApprovalItemProps extends NotificationItemBase {
  item: WorkItem;
}

const TransactionApprovalItem = React.memo(({
  item, onSeeDetail, fullSize, creation }: TransactionApprovalItemProps) => {
  const classes = useStyles();
  const [user] = useUserStore((state) => [state.user]);
  const theme = useTheme();

  const color = React.useMemo(() => {
    switch (item.name) {
      case WorkItemName.Withdrawal:
      case WorkItemName.WithdrawalToken:
      case WorkItemName.PayrollBatch:
        return theme.palette.green[600];
      case WorkItemName.RemoveTagName:
      case WorkItemName.AddTagName:
      case WorkItemName.UpdateTagName:
      case WorkItemName.AddOrUpdateAddressBook:
      case WorkItemName.RemoveAddressBook:
        return theme.palette.purple[500];
      default:
        return theme.palette.blue[500];
    }
  }, [item.name]);

  const title = React.useMemo(() => getWorkItemNotificationTitle(item.name), [item.name]);
  const icon = React.useMemo(() => getWorkItemIcon(item.name), [item.name]);

  const currentUserStatus = React.useMemo(() => {
    if (item && user) {
      if (item.status === WorkItemStatus.Rejected) return WorkItemApproverStatus.Rejected;
      if (item.userId === user.userId) return WorkItemApproverStatus.Approved;
      return item.approvers.find((a) => a.userId === user.userId)?.status ?? WorkItemApproverStatus.Approved;
    }
    return WorkItemApproverStatus.Pending;
  }, [user, item]);

  const initiator = React.useMemo(() => (
    item.email === user?.email ? 'You' : item.email
  ), [item.email, user?.email]);

  return (
    <Box className={fullSize ? classes.fullSizeWrapper : classes.wrapper}>
      <Box display="flex" gridGap={fullSize ? 4 : 12} flexDirection="column">
        <Box className={classes.titleWrapper}>
          <Box className={classes.icon} flexShrink={0} style={{ backgroundColor: color }}>
            <SVG src={icon} width={18} height={18} />
          </Box>
          <Typography className={classes.title}>{title}</Typography>
        </Box>
        <Typography className={classes.user} strong paletteColor={500} ml={fullSize ? 36 : undefined}>
          Initiated by
          <Typography ml={4} component="span" className={classes.user} palette="green" paletteColor={600}>
            {initiator}
          </Typography>
        </Typography>
      </Box>
      <Box className={classes.detailRow}>
        {(currentUserStatus === WorkItemApproverStatus.Pending && fullSize) && (
          <Box className={classes.status} id="pending">
            <Typography variant="h2">Pending</Typography>
            <SVG src="/icons/ic_time.svg" />
          </Box>
        )}
        {(currentUserStatus === WorkItemApproverStatus.Approved
          || currentUserStatus === WorkItemApproverStatus.Rejected)
          && (
            <Box
              className={classes.status}
              id={currentUserStatus === WorkItemApproverStatus.Approved ? 'approved' : 'deny'}
            >
              <Typography variant="h2">
                {currentUserStatus === WorkItemApproverStatus.Approved
                  ? `${item.approvers.filter((approver) => approver.status === WorkItemApproverStatus.Approved).length}
                  /${item.approvesCount} Approved`
                  : 'Denied'}
              </Typography>
              <SVG
                src={`/icons/${currentUserStatus === WorkItemApproverStatus.Approved ? 'ic_shield' : 'ic_deny'}.svg`}
              />
            </Box>
          )}
        {fullSize && (
          <Typography variant="h4" paletteColor={600}>
            {
              creation.toLocaleDateString('en-GB', {
                day: 'numeric',
                month: 'numeric',
                year: 'numeric',
              }).split('/').join('.')
            }, {
              creation.toLocaleTimeString('en-GB', { hour: '2-digit', minute: '2-digit' })
            }
          </Typography>
        )}
        <Button
          fullWidth={!fullSize && currentUserStatus === WorkItemApproverStatus.Pending}
          onClick={onSeeDetail}
          size="sm"
          variant={(fullSize && currentUserStatus !== WorkItemApproverStatus.Pending) ? 'icon' : 'primary'}
          color={(fullSize || currentUserStatus === WorkItemApproverStatus.Pending) ? 'green' : 'blue'}
        >
          {currentUserStatus === WorkItemApproverStatus.Pending ? (
            <>{fullSize ? 'Review' : 'Review request'} <SVG src="/icons/ic_shield.svg" width={18} height={18} /></>
          ) : 'View Details'}
        </Button>
      </Box>
    </Box>
  );
});

TransactionApprovalItem.displayName = 'TransactionApprovalItem';

export default TransactionApprovalItem;
