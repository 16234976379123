import {
  Box,
  createStyles,
  Hidden,
  Link,
  makeStyles,
  Theme,
  useMediaQuery,
  useTheme,
} from '@material-ui/core';
import { Typography } from 'elements/Typography';
import * as React from 'react';
import SVG from 'react-inlinesvg';
import { palette } from 'theme';

const useStyles = makeStyles((theme: Theme) => createStyles({
  root: {
    display: 'flex',
    background: 'white',
    flexWrap: 'nowrap',
    gap: 12,
    padding: '12px 16px',
    boxShadow: '0px 4px 32px rgba(135, 138, 165, 0.24)',
    borderRadius: 16,
    '& em': {
      color: theme.palette.green[600],
      fontStyle: 'normal',
    },
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      padding: 16,
      gap: 8,
    },
  },
  icon: {
    width: 20,
    height: 20,
  },
  badge: {
    background: palette.grey[800],
    color: 'white',
    display: 'flex',
    gap: 8,
    borderRadius: 6,
    whiteSpace: 'nowrap',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '0 16px',
    [theme.breakpoints.down('sm')]: {
      padding: '2px 12px',
    },
  },
  linkIcon: {
    float: 'right',
  },
  text: {
    fontSize: 16,
    lineHeight: '24px',
    fontWeight: 500,
    [theme.breakpoints.down('sm')]: {
      fontSize: 14,
      lineHeight: '24px',
    },
  },
}));

const PrivateClientBanner = () => {
  const classes = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Box className={classes.root}>
      <Box display="flex" justifyContent="space-between">
        <Box className={classes.badge}>
          <Typography variant={isMobile ? 'h6' : 'h5'}>Private client</Typography>
          <SVG className={`${classes.icon} sm1`} src="/icons/home/ic_private_client.svg" />
        </Box>
        <Hidden mdUp>
          <Link href="#private_client" underline="none">
            <SVG src="/icons/ic_link_out.svg" width={20} height={20} className={classes.linkIcon} />
          </Link>
        </Hidden>
      </Box>
      <Typography className={classes.text} variant="body1" palette="grey" paletteColor={700}>
        <Hidden smDown>
          <Link href="#private_client" underline="none">
            <SVG src="/icons/ic_link_out.svg" width={20} height={20} className={classes.linkIcon} />
          </Link>
        </Hidden>
        Invest <em>$100,000+ equivalent</em> to unlock Private client benefits
      </Typography>
    </Box>
  );
};

export default PrivateClientBanner;
