// @flow
import { Box, CheckboxProps, Hidden } from '@material-ui/core';
import { Checkbox, Typography } from 'elements';
import React from 'react';

interface PayrollHeaderProps {
  cellClass: string;
  checkBoxProps?: CheckboxProps;
  selectedPayrolls?: number;
  allPayrolls?: number;
}

export const PayrollHeader = (props: PayrollHeaderProps) => (
  <>
    {props.checkBoxProps && (
      <Box className={props.cellClass} id="checkbox">
        <Checkbox {...props.checkBoxProps} />
      </Box>
    )}
    <Hidden smDown>
      <Box className={props.cellClass}>
        <Typography variant="h4" paletteColor={500} component="span">
          Name & address
          {props.allPayrolls !== undefined && props.allPayrolls > 0 && props.selectedPayrolls !== undefined && (
            <Typography strong={false} component="span" variant="h6" paletteColor={400} noWrap>
              {' '}{props.selectedPayrolls}/{props.allPayrolls} selected
            </Typography>
          )}
        </Typography>
      </Box>
      <Box className={props.cellClass}>
        <Typography variant="h4" paletteColor={500}>Network</Typography>
      </Box>
      <Box className={props.cellClass}>
        <Typography variant="h4" paletteColor={500}>Cryptos</Typography>
      </Box>
      <Box className={props.cellClass} id="amount">
        <Typography variant="h4" paletteColor={500} align="right">
          Amount
        </Typography>
      </Box>
      <Box className={props.cellClass} height="100%" />
      <Box className={props.cellClass} id="usdt">
        <Typography variant="h4" paletteColor={500} align="right" noWrap>
          ≈ USDT
        </Typography>
      </Box>
    </Hidden>
  </>
);
