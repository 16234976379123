import FundWallet from "pages/responsive/FundWallet.js";
import React from "react";
import {Container, Row, Col, InputGroup} from 'react-bootstrap'
import SharedStateComponent from "./SharedStateComponent.js";

export default class DepositStatus extends SharedStateComponent {

  constructor(props) {
    super(props);

    this.state = {...props}
  }

  static getDerivedStateFromProps(props, state) {
    return {...props}
  }

  getBscScanTxUrl = (tx) => `https://bscscan.com/tx/${tx}`;
  getTerraFinderTxUrl = (tx) => `https://finder.terra.money/columbus-5/tx/${tx}`;
  getTronScanTxUrl = (tx) => `https://tronscan.org/#/transaction/${tx}`;
  getCoinName = (coinId) => {
    const coin = Object.values(this.props.coins).find(c => c.coinId === coinId);
    if (coin) return coin.name
    return '';
  }

  getLastThreeTransactions = () => {
    if (!this.state.txStatuses || this.state.txStatuses.length === 0) return [];

    const allTxs = [];
    this.state.txStatuses.forEach(txStatus => {

      try {
        txStatus.doneTxs.map((tx, i) =>
          allTxs.push({
            quantity: Number(tx.quantity),
            status: 'completed',
            coin: this.getCoinName(tx.coinId),
            url: tx.chainId === "56" ? this.getBscScanTxUrl(tx.txHash) : this.getTerraFinderTxUrl(tx.txHash),
            ts: tx.ts,
          }))
      } catch (error) {
        console.error(error);
      }

      try {
        txStatus.pendingTxs.map(tx =>
          allTxs.push({
            quantity: Number(tx.value),
            status: 'pending',
            coin: txStatus.coin,
            url: txStatus.wallet.chainId === "56" ? this.getBscScanTxUrl(tx.txHash) : this.getTerraFinderTxUrl(tx.txHash),
            ts: tx.ts,
          }))
      } catch (error) {
        console.error(error);
      }
    })

    return allTxs
      .sort((tx1, tx2) => new Date(tx2.ts) - new Date(tx1.ts))
      .slice(0, 3);
  }

  renderWithSharedState() {

    const countDecimals = (number) => {
      if (Math.floor(number.valueOf()) === number.valueOf()) return 0;
      return number.toString().split(".")[1].length || 0;
    }
    const toFixedWithElipsis = (value) => countDecimals(value) > 6 ? `${value.toFixed(6)}...` : value;

    const renderTransaction = (tx) => {
      return (
        <>
          <span style={{
            color: '#535bd4',
            textDecoration: tx.url ? 'underline' : ''
          }}>{toFixedWithElipsis(tx.quantity)}</span>
          <span style={{color: '#535bd4'}}> {tx.coin}</span>
          <span style={{color: '#000000'}}> | </span>
          <span style={{color: tx.status === 'completed' ? '#10AC84' : 'darkorange'}}>{tx.status}</span>
        </>
      )
    }

    const lastThreeTransactions = this.getLastThreeTransactions();

    return (
      <>
        <Container className="m-0 p-0">
          <Row className="mt-0">
            <Col><p className="p9 mb-0">Deposit status:<span
              className="p5 float-right">{this.state.timeLeft == -2 ? "" : (this.state.timeLeft == -1 ? "Updating.." : "Updates in " + this.state.timeLeft + " secs")}</span>
            </p></Col>
          </Row>
          <Row className="mt-2">
            <Col><p className={"p5 mb-0 grey"}>{this.state.depositStatus}</p></Col>
          </Row>
          <Row className="mt-4">
            <Col><p className="p9 mb-0">Deposit amounts:</p></Col>
          </Row>
          {lastThreeTransactions ? lastThreeTransactions.map((tx, i) => (
            <Row key={i} className="mt-2" style={{fontSize: '14px'}}>
              <Col>
                {tx.url ? (
                  <a href={tx.url} target="_blank">
                    {renderTransaction(tx)}
                  </a>
                ) : (
                  <>
                    {renderTransaction(tx)}
                  </>
                )}
              </Col>
            </Row>
          )) : (
            <Row className="mt-2">
              <Col><p className="mb-0">---</p></Col>
            </Row>
          )}
          {this.state.addMoreFunds &&
            <Row className="text-center">
              <Col><a href={FundWallet.routePath}>Add more funds</a></Col>
            </Row>
          }
        </Container>
      </>
    );
  }
}
