import axios from 'axios';

import Headers from './headers';
import { custodialTxsFilter } from './OnboardService';
import { MutateAPIResponse } from './types/mutate-api-response';
import { PayrollEstimatedGasFee, PayrollId, PayrollToggleRequest, PayrollWithExchangeRate } from './types/payroll';
import { FAResponseStatus } from './types/wallet';

const API_URL = '/api/payroll';

const get = (): Promise<PayrollWithExchangeRate[]> => axios.get(API_URL,
  { headers: { ...Headers.auth() } }).then((res) => res.data);

const post = (
  coinId: number,
  address: string,
  addressName: string,
  enable: boolean,
  tagIds: number[],
  quantity?: number,
  payrollId?: number,
  walletId?: number,
): Promise<void> => axios.post(API_URL, { coinId, address, addressName, quantity, enable, payrollId, tagIds, walletId },
  { headers: { ...Headers.auth() } });

const remove = (payrollId: number, addressBookId: number): Promise<void> => axios.delete(
  `${API_URL}/${payrollId}/${addressBookId}`,
  { headers: { ...Headers.auth() } },
);

const estimateFee = (): Promise<Record<PayrollId, PayrollEstimatedGasFee>> => axios.get(`${API_URL}/estimate`,
  { headers: { ...Headers.auth() } }).then((res) => res.data);

const pay = custodialTxsFilter((emailCode?: string): Promise<FAResponseStatus | MutateAPIResponse> => axios.post(`${API_URL}/pay`, { emailCode },
  { headers: { ...Headers.auth() } }).then((res) => res.data));

const singlePay = custodialTxsFilter((emailCode: string | undefined, payrollId: number): Promise<FAResponseStatus> => axios.post(
  `${API_URL}/singlePay`, { emailCode, payrollId }, { headers: { ...Headers.auth() } },
).then((res) => res.data));

const toggle = (request: PayrollToggleRequest[]): Promise<void> => axios.post(`${API_URL}/toggle`, request,
  { headers: { ...Headers.auth() } });

export default {
  get,
  post,
  remove,
  estimateFee,
  pay,
  singlePay,
  toggle,
};
