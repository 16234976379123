import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';

import { selectLanguage } from '../language/languageSlice';
import { RootState } from '../store';
import { Translation } from './translation';

// Define a type for the slice state
type TranslationsState = Translation[]

// Define the initial state using that type
const initialState: TranslationsState = []

export const translationsSlice = createSlice({
  name: 'translations',
  initialState,
  reducers: {
    translationsLoaded: (state: TranslationsState, action: PayloadAction<Translation[]>) => {
      return action.payload;
    },
  },
})

export const { translationsLoaded } = translationsSlice.actions

export const selectTranslations = (state: RootState) => state.translations;
export const selectTranslationsForCurrentLanguage = createSelector(
  selectTranslations,
  selectLanguage,
  (translations: Translation[], language: string) => translations.filter(translation => translation.isoCode === language)
)

export const selectAllCopyForCurrentLanguage = createSelector(
  selectTranslationsForCurrentLanguage,
  (translations: Translation[]) => Object.assign({}, ...translations.map(translation => ({[translation.translationId]: translation.copy})))
)

export const selectTranslationForId =  createSelector(
  selectTranslationsForCurrentLanguage,
  (state: RootState, id: number) => id,
  (translations: Translation[], id: number) => translations.find(translation => translation.translationId === id))

export default translationsSlice.reducer
