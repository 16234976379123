// eslint-disable-next-line no-shadow

import { WalletInfo } from '../services/types/wallet';

export enum UserType {
  Individual = 'individual',
  Institutional = 'institutional',
  Partner = 'partner',
}

export interface User {
  id?: string;
  email?: string;
  userId: number;

  firstName?: string;
  lastName?: string;
  birthDate?: string;
  avatar?: string;

  gender?: 'Male' | 'Female' | 'Other';
  residenceCountry?: string;
  companyName?: string;

  phoneNumber?: string;
  consentTermsAndConditions: boolean;
  kycResult?: 'RED' | 'GREEN' | 'NOT_SUBMITTED' | 'SUBMITTED';

  isActive: boolean;
  isVerified?: boolean;
  isCustodial?: boolean;
  deactivationAt?: string;

  role?: string;

  isReadOnly: boolean;

  // eslint-disable-next-line camelcase
  secret_2fa: string;
  userType: UserType;
  isTmp: boolean;
  walletInfo: WalletInfo[];
}
