/* eslint-disable no-unused-vars */
import { makeStyles } from '@material-ui/core';
import { Typography } from 'elements';
import { CheckBoxWithText } from 'elements/CheckBoxWithText';
import { InputWithTitle } from 'elements/InputWithTitle';
import { LightTextField } from 'elements/LightTextField';
import React, { FC } from 'react';

const useStyles = makeStyles((theme) => ({
  memoCheckBoxText: {
    display: 'inline-flex',
    alignItems: 'center',
    color: theme.palette.grey[600],
    fontWeight: 400,
  },
}));

interface MemoFieldProps {
  isNoMemoChecked: boolean;
  setIsNoMemoChecked: (value: boolean) => void;
  memo: string | null;
  setMemo: (value: string) => void;
}

export const MemoField: FC<MemoFieldProps> = (props) => {
  const classes = useStyles();

  return (
    <>
      <InputWithTitle title="Memo">
        <LightTextField
          variant="outlined"
          id="memo"
          disabled={props.isNoMemoChecked}
          placeholder="Enter memo code"
          value={props.memo}
          onChange={(e) => props.setMemo(e.target.value)}
        />
      </InputWithTitle>
      <CheckBoxWithText
        setIsChecked={props.setIsNoMemoChecked}
        isChecked={props.isNoMemoChecked}
      >
        <Typography
          variant="h6"
          className={classes.memoCheckBoxText}
        >
          My wallet doesn't require a memo
        </Typography>
      </CheckBoxWithText>
    </>
  );
};
