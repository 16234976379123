import {
  Box,
  Collapse,
  Divider,
  Hidden,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  makeStyles,
} from '@material-ui/core';
import { Typography } from 'elements';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import SVG from 'react-inlinesvg';
import { Link } from 'react-router-dom';
import { AccountDetailsRoute, DashboardRoute, TeamAccessRoute } from 'routes';
import { useStore as useFeatureStore } from 'store/zustand/Feature';
import { useStore as useUserStore } from 'store/zustand/User';
import { useStore as useWalletStore } from 'store/zustand/Wallet';

import { DashboardMenuItem, DashboardMenuItems } from './DashboardMenuItem';

type DashboardSidebarProps = {
  currentRoute: string
  toggleSidebar: () => void
  onSignOut: () => void
}

const useStyles = makeStyles((theme) => ({
  root: {
    overflowY: 'auto',
    scrollbarWidth: 'none',

    display: 'flex',
    flexDirection: 'column',
    alignItems: 'start',

    padding: '24px 0 24px 0',
    width: '100%',
    height: '100%',

    background: theme.palette.common.white,

    [theme.breakpoints.up('lg')]: {
      width: 256,
    },
  },
  logoBox: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',

    padding: '0 24px 0 24px',
    width: '100%',
    '& img': {
      userSelect: 'none',
      userDrag: 'none',
    },
  },
  closeButton: {
    filter: 'invert(67%) sepia(18%) saturate(477%) hue-rotate(186deg) brightness(90%) contrast(88%)',
  },
  accountBox: {
    overflow: 'hidden',
    flexGrow: 0,
    flexShrink: 0,

    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: 8,

    marginTop: 24,
    padding: '16px 24px',
    width: '100%',

    background: theme.palette.grey[100],
    borderTop: `1px solid ${theme.palette.grey[200]}`,
    borderBottom: `1px solid ${theme.palette.grey[200]}`,
  },
  accountAvatar: {
    flexShrink: 0,
    width: 48,
    height: 48,
    '& img': {
      userSelect: 'none',
      userDrag: 'none',
      width: 48,
      height: 48,
      borderRadius: 24,
    },
  },
  companyAvatar: {
    '& img': {
      borderRadius: 8,
      border: '2px solid white',
    },
  },
  accountDetailsBox: {
    overflow: 'hidden',
    flexDirection: 'column',
    alignItems: 'left',
    textAlign: 'left',
    gap: 4,
    width: '100%',
  },
  accountNameBox: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    gap: 8,
    alignItems: 'center',
    flexWrap: 'nowrap',
  },
  accountName: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    color: theme.palette.grey[700],
    fontSize: 16,
    lineHeight: '24px',
    fontWeight: 500,
  },
  companyName: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    color: theme.palette.grey[700],
    fontSize: 12,
    lineHeight: '20px',
    fontWeight: 500,
  },
  accountEmail: {
    overflow: 'hidden',
    color: theme.palette.grey[400],
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    marginTop: 4,
  },
  list: {
    marginTop: 12,
    padding: 0,
    width: '100%',
  },
  listItem: {
    marginBottom: 12,
    padding: '16px 24px',
    cursor: 'pointer',
    '&:hover': {
      background: theme.palette.grey[100],
    },
  },
  listItemIcon: { minWidth: 0 },
  listItemText: { margin: 0, marginLeft: 12 },
  subItemParent: { marginBottom: 4 },
  subItemText: {
    fontSize: '14px',
    paddingLeft: '8px',
  },
  subListToggleClosed: {
    filter: 'invert(79%) sepia(4%) saturate(3216%) hue-rotate(190deg) brightness(82%) contrast(76%)',
  },
  subListToggleOpen: {
    filter: 'invert(79%) sepia(4%) saturate(3216%) hue-rotate(190deg) brightness(82%) contrast(76%)',
    transform: 'rotate(180deg)',
  },
  subList: {
    marginBottom: 12,
    padding: 0,
    width: '100%',
  },
  subListItem: {
    paddingLeft: 60,
    '&:hover': {
      background: theme.palette.grey[100],
    },
  },
  editIcon: {
    width: 16,
    height: 16,
    flexShrink: 0,
    filter: 'invert(49%) sepia(95%) saturate(401%) hue-rotate(121deg) brightness(98%) contrast(102%)',
  },
  userAvatar: {
    display: 'flex',
    gap: 4,
    alignItems: 'center',
    '& img': {
      width: 28,
      height: 28,
      border: '2px solid white',
      borderRadius: 14,
    },
  },
  unselectedIcon: { filter: 'invert(67%) sepia(18%) saturate(477%) hue-rotate(186deg) brightness(90%) contrast(88%)' },
  selectedIcon: { filter: 'invert(49%) sepia(95%) saturate(401%) hue-rotate(121deg) brightness(98%) contrast(102%)' },
  selectedText: { color: theme.palette.green[600] },
  logoutDivider: { margin: '0 24px 12px', background: theme.palette.grey['200'] },
}));

const DashboardSidebar = ({ currentRoute, toggleSidebar, onSignOut }: DashboardSidebarProps) => {
  const classes = useStyles();
  const [user, fetchUser, teamMembers, fetchAllowedUsers] = useUserStore((state) => [
    state.user, state.fetchUser, state.teamMembers, state.fetchAllowedUsers,
  ]);
  const [features, fetchFeature] = useFeatureStore((state) => [state.features, state.fetchFeatures]);
  const [
    _ready, fetchWallet, _isDefi, _activeWalletBalanceNonSwappable, fetchLatestWalletBalances,
  ] = useWalletStore((state) => [
    state.readyWallet,
    state.fetchWallet,
    state.isDefi,
    state.activeWalletBalanceNonSwappable,
    state.fetchLatestWalletBalances,
  ]);

  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isDefi, setIsDefi] = useState(true);
  const [isReady, setIsReady] = useState(false);

  const userName = useMemo(() => {
    if (!user) return '';

    let name = '';
    if (user.firstName) name += user.firstName.split(' ')[0];
    if (user.lastName) name += ` ${user.lastName[0]}.`;

    return name;
  }, [user]);

  useEffect(() => {
    if (!_ready) return;
    setIsReady(_ready);
    setIsDefi(_isDefi);
  }, [_ready]);

  useEffect(() => {
    (async () => {
      await Promise.all([
        fetchUser(false),
        fetchWallet(false),
        fetchFeature(),
        fetchAllowedUsers(false),
        fetchLatestWalletBalances(),
      ]);
      setIsLoading(false);
    })();
  }, []);

  const menuItems = React.useMemo(() => (
    DashboardMenuItems
      .filter((item) => (!item.defiOnly || isDefi)
        && (!item.hideForReadOnly || !user?.isReadOnly)
        && (item.feature ? (features && features[item.feature]) : true))
  ), [DashboardMenuItems, isDefi, user, features]);

  const SimpleItem = useCallback((props: { link: DashboardMenuItem }) => {
    const isSelected = () => {
      let routeConfig = ['.'];
      if (props.link.selectedRoutes) routeConfig = props.link.selectedRoutes;
      else if (props.link.route) routeConfig = [props.link.route];

      return routeConfig.find((c) => currentRoute.startsWith(c));
    };

    return (
      <Link
        id={`menu-${props.link.label.replaceAll(' ', '-').replaceAll('/', '-').toLowerCase()}`}
        to={props.link?.route ?? ''}
      >
        <ListItem className={classes.listItem}>
          <ListItemIcon className={classes.listItemIcon}>
            <img
              className={isSelected()
                ? classes.selectedIcon
                : classes.unselectedIcon}
              alt={props.link?.label}
              src={props.link?.iconSrc}
              width={24}
            />
          </ListItemIcon>
          <ListItemText
            className={`${classes.listItemText} ${isSelected() && classes.selectedText}`}
          >
            <Typography variant="h5">{props.link?.label}</Typography>
          </ListItemText>
        </ListItem>
      </Link>
    );
  }, [currentRoute]);

  const [subMenuOpen, setSubMenuOpen] = useState(menuItems
    .map((item) => {
      if (item.sub) {
        const routeIndex = item.sub.findIndex((subItem) => currentRoute.startsWith(subItem.route ?? '.'));
        return routeIndex >= 0;
      }
      return false;
    }));

  const SubmenuItem = useCallback((props: { link: DashboardMenuItem, index: number }) => (
    <>
      <ListItem
        className={`${classes.listItem} ${subMenuOpen[props.index] && classes.subItemParent}`}
        button
        onClick={() => {
          const newSubMenuState = [...subMenuOpen];
          newSubMenuState.splice(props.index, 1, !subMenuOpen[props.index]);
          setSubMenuOpen(newSubMenuState);
        }}
      >
        <ListItemIcon className={classes.listItemIcon}>
          <img
            className={(currentRoute.startsWith(props.link?.route ?? '.'))
              ? classes.selectedIcon
              : classes.unselectedIcon}
            alt={props.link?.label}
            src={props.link?.iconSrc}
            width={24}
          />
        </ListItemIcon>
        <ListItemText
          className={`${classes.listItemText} ${(currentRoute.startsWith(props.link?.route ?? '.')) && classes.selectedText}`}
        >
          <Typography variant="h5">{props.link?.label}</Typography>
        </ListItemText>
        <img
          alt=""
          src="/icons/dashboard/ic_arrow_down.svg"
          className={`${subMenuOpen[props.index] ? classes.subListToggleOpen : classes.subListToggleClosed}`}
          width={24}
        />
      </ListItem>
      <Collapse in={subMenuOpen[props.index]}>
        <List className={classes.subList}>
          {props
            .link?.sub?.filter((item) => (
              (!item.defiOnly || isDefi)
              && (!item.swappableOnly || _activeWalletBalanceNonSwappable?.length === 0)
              && (!item.hideForReadOnly || !user?.isReadOnly)
              && (item.feature ? (features && features[item.feature]) : true)))
            .map((subLink, subIndex) => (
              <Link
                id={`menu-${subLink.label.replaceAll(' ', '-').replaceAll('/', '-').toLowerCase()}`}
                key={subIndex}
                to={subLink.route}
              >
                <ListItem className={classes.subListItem}>
                  <ListItemIcon className={classes.listItemIcon}>
                    <img
                      className={(currentRoute.startsWith(subLink.route ?? '.'))
                        ? classes.selectedIcon
                        : classes.unselectedIcon}
                      alt={subLink.label}
                      src={subLink.iconSrc}
                      width={16}
                    />
                  </ListItemIcon>
                  <ListItemText
                    className={`${currentRoute.startsWith(subLink.route ?? '.') && classes.selectedText}`}
                    classes={{ primary: classes.subItemText }}
                  >
                    {subLink.label}
                  </ListItemText>
                </ListItem>
              </Link>
            ))}
        </List>
      </Collapse>
    </>
  ), [currentRoute, subMenuOpen, features, user, _activeWalletBalanceNonSwappable]);

  return (
    <Box className={classes.root}>
      <Box className={classes.logoBox}>
        <Link to={DashboardRoute}>
          <img alt="Coinbag logo" src="/logos/coinbag_long.svg" height={32} />
        </Link>
        <Hidden lgUp>
          <IconButton onClick={toggleSidebar}>
            <img className={classes.closeButton} alt="Close menu" src="/icons/dashboard/ic_cross.svg" height={20} />
          </IconButton>
        </Hidden>
      </Box>
      {isLoading ? (<></>) : (
        <>
          <Link className={classes.accountBox} to={features?.teamAccess ? TeamAccessRoute : AccountDetailsRoute}>
            <Box className={`${classes.accountAvatar} ${features?.teamAccess ? classes.companyAvatar : ''}`}>
              {user && <img src={`/api/images/ape?user=${user.id}`} alt="User's avatar" />}
            </Box>
            <Box className={classes.accountDetailsBox}>
              <Box className={classes.accountNameBox}>
                <Typography
                  className={!userName || !(features?.teamAccess && teamMembers && teamMembers.length > 0) ? classes.accountName : classes.companyName}
                >
                  {(features?.teamAccess ? (user?.companyName || 'Your Company') : (userName || 'Add Name'))}
                </Typography>
                <SVG
                  className={classes.editIcon}
                  src="/icons/dashboard/ic_edit.svg"
                  width={16}
                  height={16}
                />
              </Box>
              {features?.teamAccess && teamMembers && teamMembers.length > 0 ? (
                <Box className={classes.userAvatar}>
                  {teamMembers?.slice(0, 3).map((u) => (
                    <img key={u.userId} alt="user" src={`/api/images/ape?user=${u.userId}`} />))}
                  {teamMembers.length > 3
                    ? <Typography variant="h6" paletteColor={500}>{teamMembers.length - 3}+</Typography> : null}
                </Box>
              ) : (
                <Typography variant="h6" className={classes.accountEmail}>{user?.email ?? ''}</Typography>
              )}
            </Box>
          </Link>
          <List className={classes.list}>
            {isReady && menuItems.map((link, index) => (link.sub ? (
              <SubmenuItem key={index} link={link} index={index} />
            ) : (
              <SimpleItem key={index} link={link} />
            )))}
            <Divider className={classes.logoutDivider} />
            <ListItem className={classes.listItem} onClick={onSignOut}>
              <ListItemIcon className={classes.listItemIcon}>
                <img
                  className={classes.unselectedIcon}
                  alt="Log Out"
                  src="/icons/dashboard/ic_logout.svg"
                  width={24}
                />
              </ListItemIcon>
              <ListItemText className={classes.listItemText}>
                Log out
              </ListItemText>
            </ListItem>
          </List>
        </>
      )}
    </Box>
  );
};

DashboardSidebar.displayName = 'DashboardSidebar';
export { DashboardSidebar };
