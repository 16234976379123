import { Box } from '@material-ui/core';
import { WalletRow } from 'components/dashboard/MultiWallets/WalletRow';
import { Typography } from 'elements';
import { BundleCoin } from 'pages/responsive/PortfolioSetting/Bundle/BundleCard';
import { CoinBar } from 'pages/responsive/PortfolioSetting/Elements/CoinBar';
import React from 'react';
import { CreateOrEditBundleParameter } from 'services/types/workItemParameter';
import { useStore as useCoinStore } from 'store/zustand/Coin';
import { useStore as useUserStore } from 'store/zustand/User';
import { useStore as useWalletStore } from 'store/zustand/Wallet';

const CreateOrEditBundleDetail = (props: CreateOrEditBundleParameter) => {
  const [masterCoins, fetchCoins] = useCoinStore((state) => [state.masterCoins, state.fetchCoins]);
  const [user] = useUserStore((state) => [state.user]);
  const [wallet] = useWalletStore((state) => [state.wallet]);

  const selectedWalletInfo = React.useMemo(
    () => (user?.walletInfo.find((w) => w.walletId === props.walletId)), [user, wallet],
  );

  React.useEffect(() => {
    fetchCoins();
  }, []);

  const coins: BundleCoin[] = React.useMemo(() => {
    if (!props.newBundle || !Object.keys(masterCoins).length) return [];
    return props.newBundle.map((c) => {
      const masterCoin = masterCoins[c.id.toString()];
      return ({
        ...c,
        ...masterCoin,
        percentage: c.percentage / 100,
        color: masterCoin?.metadata?.color ?? '#000000',
        type: masterCoin?.metadata?.marketCap?.category ?? 'Others',
      });
    });
  }, [props.newBundle, masterCoins]);

  return (
    <>
      <Typography variant="h6" paletteColor={700} align="center">
        Update portfolio bundle
      </Typography>
      <Box marginBottom="8px">
        <CoinBar coins={coins} small />
      </Box>
      {selectedWalletInfo && (
        <Box display="flex" alignItems="center" gridGap="12px" justifyContent="center">
          <Typography variant="h4" paletteColor={800} align="center">for wallet:</Typography>
          <WalletRow walletInfo={selectedWalletInfo} />
        </Box>
      )}
    </>
  );
};

export default CreateOrEditBundleDetail;
