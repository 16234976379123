import React, {Component} from "react";
import BundleService from "../../services/bundle.service";

import MaterialTable, { MTableToolbar } from "material-table";
import ReactTooltip from 'react-tooltip';
import IconButton from "@material-ui/core/IconButton";
import DoneIcon from '@material-ui/icons/Done';
import EditIcon from '@material-ui/icons/Edit';
import PageviewIcon from '@material-ui/icons/Pageview';
import Logger from "js-logger";
import MuiTableIcons from "../../components/MuiTableIcons";

export default class ViewBundles extends Component {
  static routePath = '/view-bundles';
  static loginRequired = true;

  constructor(props) {
    super(props);

    const query = new URLSearchParams(this.props.location.search);
    const chainId = query.get('chainId');
    Logger.log(`chainId: ${chainId}, ${typeof (chainId)}`)

    this.state = {
      loading: false,
      bundles: [],
      chainId: chainId,
      message: ""
    };
  }

  componentDidMount() {
    this.setState({isLoading: true});

    BundleService.getAllBundles().then(resp => {
        let bundles = resp.data;
        let filteredBundles = bundles;
        if (this.state.chainId !== null) {
          filteredBundles = bundles.filter(x => x.coins.every(c => c.chainId === this.state.chainId));
        }
        Logger.log(`chainId: ${this.state.chainId}, bundles size ${bundles.length}, filteredBundles size ${filteredBundles.length}`)
        this.setState({
          bundles: filteredBundles,
          isLoading: false
        }, () => {
          ReactTooltip.rebuild();
        });
      }
    );
  }

  render() {
    if (this.state.isLoading) {
      return <div style={{ margin: '40px auto', width: '100px' }}>Loading ...</div>;
    }

    return (
      <div>
        <section id="exchange">
          <div style={{ margin: '20px' }}>
            <div className="row">
              <MaterialTable
                icons={MuiTableIcons}
                columns={[
                  {title: "Name", field: "name"},
                  {title: "Bundle id", field: "bundleId"},
                  {title: "Bundle state id", field: "bundleStateId"},
                  {title: "Description", field: "description", render: rowData => {
                      const randomNumber = Math.floor(Math.random() * 1000000);
                      return (
                        <>
                          <ReactTooltip id={`description-${randomNumber}`} effect="solid" place="right" type="dark" scrollHide={false}>
                            <div style={{ padding: '10px', maxWidth: '400px', color: '#ffffff' }}>
                              {rowData.description}
                            </div>
                          </ReactTooltip>
                          <label
                            data-tip=""
                            data-for={`description-${randomNumber}`}
                            style={{ cursor: 'pointer', color: '#327261', textDecoration: 'underline' }}
                          >
                            {rowData.description.substring(0, 10)}...
                          </label>
                        </>
                      )}},
                  {title: "Status", field: "status"},
                  {title: "Created", field: "creationDate"},
                  {title: "Risk score", field: "riskScore", sorting: true, defaultSort: 'desc'},
                  {title: "Is Liquidation", field: "isLiquidation"},
                  {title: "Coins", field: "coins", render: rowData => {
                    const randomNumber = Math.floor(Math.random() * 1000000);
                    return (
                        <>
                          <ReactTooltip id={`coins-${randomNumber}`} effect="solid" place="left" type="dark" scrollHide={false}>
                            <div style={{ padding: '10px', color: '#ffffff' }}>
                              <table cellPadding="5px">
                                <tr>
                                  <th>Coin Id</th>
                                  <th>Name</th>
                                  <th>Chain Id</th>
                                  <th>Weight</th>
                                </tr>
                                {rowData.coins.map(c => (
                                  <tr>
                                    <td>{c.coinId}</td>
                                    <td>{c.name}</td>
                                    <td>{c.chainId}</td>
                                    <td>{c.weight}</td>
                                  </tr>
                                ))}
                              </table>
                            </div>
                          </ReactTooltip>
                          <label
                            data-tip=""
                            data-for={`coins-${randomNumber}`}
                            style={{ cursor: 'pointer', color: '#327261', textDecoration: 'underline' }}
                          >
                            {rowData.coins.length}
                          </label>
                        </>
                      )}
                    },
                  {
                    title: "Activate bundle", render: (rowData) =>
                      rowData && (
                        <IconButton
                          color="primary"
                          onClick={() => {
                            BundleService.activateBundle(rowData.bundleId).then(resp => {
                                window.location.reload(false);
                              }
                            );
                          }
                          }
                        >
                          <DoneIcon/>
                        </IconButton>
                      )
                  },
                  {
                    title: "View", render: (rowData) =>
                      rowData && (
                        <IconButton
                          color="primary"
                          onClick={() => {
                            const url = `/view-bundle?bundleId=${rowData.bundleId}&bundleStateId=${rowData.bundleStateId}`
                            this.props.history.push(url)
                            window.location.reload();
                          }
                          }
                        >
                          <PageviewIcon/>
                        </IconButton>
                      )
                  },
                  {
                    title: "Edit", render: (rowData) =>
                      rowData && (
                        <IconButton
                          color="primary"
                          onClick={() => {
                            const url = `/modify-bundle?bundleId=${rowData.bundleId}&bundleStateId=${rowData.bundleStateId}`
                            this.props.history.push(url)
                            window.location.reload();
                          }
                          }
                        >
                          <EditIcon/>
                        </IconButton>
                      )
                  }
                ]}
                data={this.state.bundles}
                title=""
                options={{
                  sorting: true,
                  pageSize: 50,
                  emptyRowsWhenPaging: false,
                  pageSizeOptions:[20,50,100],
                  rowStyle:{
                    fontSize: 14,
                  },
                  headerStyle: {
                    fontSize: 14,
                    fontWeight: 'bold',
                  },
                  searchFieldStyle: {
                    fontSize: 14,
                  },
                  searchFieldAlignment: 'right',
                }}
                components={{
                  Toolbar: props => (
                    <div style={{ paddingTop: '10px' }}>
                      <MTableToolbar {...props} />
                    </div>
                  )
                }}
              />
            </div>
          </div>
        </section>
      </div>
    )
  }

}
