import { Box, createStyles } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import {
  chainIdMapper,
  chainIdNativeCoinSymbolMapper,
} from 'components/DepositAndWithdraw/mappers';
import { Typography } from 'elements';
import * as React from 'react';
import { CoinsWithMasterCoins } from 'services/types/coin';
import { PayrollWithExchangeRate } from 'services/types/payroll';
import { EstimatedFees } from 'store/zustand/Payroll';

import { EstimateFee, findTotalFeeUsd } from './EstimateFee';

interface Props {
  chainId?: string;
  payrolls?: PayrollWithExchangeRate[];
  coins: CoinsWithMasterCoins;
  payrollId?: number;
  chainIdsToWithdraw?: string[];
  estimatedFees?: EstimatedFees;
  groupedFeesByChainId?: Record<string, number>;
  estimateFees: () => void;
  singlePayrollErr: boolean;
  isInsufficientGas: boolean;
  summary?: boolean;
}

const useStyles = makeStyles((theme) => createStyles({
  summaryWrapper: {
    display: 'flex',
    flexDirection: 'column',
    gap: 8,
    width: '100%',
    paddingTop: 8,
    [theme.breakpoints.down('xs')]: {
      gap: 20,
    },
  },
  summaryBorder: {
    borderTop: '1px solid',
    borderColor: theme.palette.grey[200],
  },
  amountSummaryWrapper: {
    [theme.breakpoints.up('sm')]: {
      display: 'grid',
      gridTemplateColumns: 'repeat(3, 1fr)',
      width: '100%',
    },
    [theme.breakpoints.down('xs')]: {
      display: 'flex',
      flexDirection: 'column',
      gap: 20,
    },
  },
  columnWrapper: {
    display: 'flex',
    gap: 8,
    [theme.breakpoints.up('sm')]: {
      flexDirection: 'column',
    },
    [theme.breakpoints.down('xs')]: {
      justifyContent: 'space-between',
    },
  },
  firstColumn: {
    alignItems: 'flex-start',
  },
  midColumn: {
    alignItems: 'center',
  },
  lastColumn: {
    alignItems: 'flex-end',
  },
  summary: {
    background: theme.palette.grey[100],
    borderRadius: 8,
    padding: 8,
  },
}));

export const PayrollSummary = (props: Props) => {
  const {
    chainId,
    payrolls,
    coins,
    payrollId,
    chainIdsToWithdraw,
    estimatedFees,
    groupedFeesByChainId,
    estimateFees,
    singlePayrollErr,
    isInsufficientGas,
    summary,
  } = props;
  const classes = useStyles();

  const filteredPayrolls = payrollId !== undefined
    ? payrolls
      ?.filter((pr) => pr.payrollId === payrollId)
    : payrolls
      ?.filter((pr) => pr.enable)
      ?.filter((pr) => {
        const coinChainId = coins[pr.coinId.toString()]?.chainId ?? '';
        return (chainId === undefined && chainIdsToWithdraw?.includes(coinChainId)) || (chainId !== undefined && chainId === coinChainId);
      });

  const amount = filteredPayrolls
    ?.reduce((prev: number, curr) => prev + (curr?.usdAmount ?? 0), 0);

  const sumUsdFees = filteredPayrolls
    ?.reduce((prev: number, curr) => (estimatedFees?.fees[curr.payrollId]?.usdValue ?? 0) + prev, 0);

  const isGasEstError = (payrolls === undefined || estimatedFees === undefined) ? false
    : payrolls
      .filter((pr) => chainId === undefined || coins[pr.coinId]?.chainId === chainId)
      .filter((pr) => pr.enable)
      .reduce((prev, curr) => (
        estimatedFees.fees[curr.payrollId]?.fee ?? 0) < 0 || prev, false);

  const totalFeeUsd = payrollId
    ? estimatedFees?.fees[payrollId].usdValue ?? 0
    : groupedFeesByChainId === undefined ? undefined
      : chainId !== undefined ? groupedFeesByChainId[chainId]
        : findTotalFeeUsd(groupedFeesByChainId);

  return (
    <Box
      className={`${classes.summaryWrapper} ${chainId ? classes.summaryBorder : ''} ${summary ? classes.summary : ''}`}
      key={props.chainId}
    >
      {chainId !== undefined && isInsufficientGas && (
        <Typography variant="h6" paletteColor={700}>
          Insufficient&nbsp;
          <Typography variant="h6" palette="red" paletteColor={600} component="span">
            {chainIdNativeCoinSymbolMapper[chainId]}&nbsp;
          </Typography>
          for gas fees on&nbsp;
          <Typography variant="h6" palette="red" paletteColor={600} component="span">
            {chainIdMapper[chainId][1]}.
          </Typography>&nbsp;
          Please deposit {chainIdNativeCoinSymbolMapper[chainId]}.
        </Typography>
      )}
      <Box className={classes.amountSummaryWrapper}>
        <Box className={`${classes.columnWrapper} ${classes.firstColumn}`}>
          <Typography variant="h6" paletteColor={500}>
            {chainId ? `${chainIdNativeCoinSymbolMapper[chainId]} ` : ''}Estimated fees:
          </Typography>
          <EstimateFee
            updatedTs={estimatedFees?.updatedTs}
            estimateFees={estimateFees}
            isGasEstError={isGasEstError || singlePayrollErr}
            totalFeeUsd={totalFeeUsd}
            isInsufficientGas={isInsufficientGas}
          />
        </Box>
        <Box className={`${classes.columnWrapper} ${classes.midColumn}`}>
          <Typography variant="h6" paletteColor={500}>Amount to withdraw</Typography>
          <Typography variant="h4" paletteColor={700}>{amount ? `${amount.toLocaleString()} USD` : ''}</Typography>
        </Box>
        <Box className={`${classes.columnWrapper} ${classes.lastColumn}`}>
          <Typography variant="h6" paletteColor={500}>Total amount USDT</Typography>
          <Typography variant="h4" palette={(amount && sumUsdFees) ? 'green' : 'grey'} paletteColor={600}>
            {(amount && sumUsdFees && !isGasEstError) ? ((amount ?? 0) + (sumUsdFees ?? 0)).toLocaleString() : '-'}
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};
