import { Box, Hidden, Link, makeStyles } from '@material-ui/core';
import { Typography } from 'elements';
import * as React from 'react';
import SVG from 'react-inlinesvg';
import { SignUpInstitutionalInvestorsRoute } from 'routes';

import { SignUp } from '.';
import DistributeRisk from './Components/DistributeRisk';
import { institutionalFeatures, MainFeatures } from './Components/MainFeatures';
import OurPartner from './Components/OurPartner';
import Steps, { institutionalInvestorsSteps } from './Components/Steps';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'center',
    gap: 40,
    margin: '0 auto',
    padding: 16,
    width: '100%',
    maxWidth: 640,
    color: theme.palette.grey[700],
    '& em': {
      color: theme.palette.blue[600],
      fontStyle: 'normal',
    },
    [theme.breakpoints.up('md')]: {
      gap: 120,
      maxWidth: 1080,
      paddingTop: 48,
    },
    '&::before': {
      content: '" "',
      width: '1600px',
      height: '1200px',
      top: '-400px',
      left: '40%',
      position: 'absolute',
      backfaceVisibility: 'hidden',
      perspective: 1000,
      transform: 'translateZ(0)',
      background: 'radial-gradient(50% 50% at 50% 50%, rgba(110, 158, 254, 0.315) 0%, rgba(255, 255, 255, 0) 100%)',
      zIndex: 0,
      filter: 'blur(80px)',
      [theme.breakpoints.down('sm')]: {
        width: '1400px',
        height: '680px',
        top: '-300px',
        left: 'unset',
      },
    },
  },
  title: {
    display: 'flex',
    flexDirection: 'column',
    gap: 12,
    textAlign: 'center',

    [theme.breakpoints.up('md')]: {
      gap: 20,
      textAlign: 'left',
      position: 'sticky',
      top: 170,
    },

    '& h1': {
      fontSize: 24,
      fontWeight: 700,
      lineHeight: '44px',

      [theme.breakpoints.up('md')]: {
        fontSize: 44,
        lineHeight: '70px',
      },
    },
    '& h2': {
      fontSize: 12,
      fontWeight: 400,
      lineHeight: '20px',

      [theme.breakpoints.up('md')]: {
        fontSize: 20,
        fontWeight: 500,
        lineHeight: '32px',
      },
    },
    '& a': {
      color: theme.palette.blue[600],
      fontSize: 14,
      fontWeight: 500,

      [theme.breakpoints.up('md')]: {
        fontSize: 16,
        fontWeight: 600,
        textTransform: 'uppercase',
      },
    },
    '& em': {
      color: theme.palette.blue[500],
      fontStyle: 'normal',
    },
  },
  learnMore: {
    width: '100%',
    margin: '0 0 200px',
    [theme.breakpoints.down('sm')]: {
      margin: '80px 0 100px',
      textAlign: 'center',
    },
  },
  feature: {
    marginTop: -80,
    paddingTop: 80,
  },
}));

const DashboardFeatures = React.lazy(() => import('../../../components/Marketing/DashboardFeatures/DashboardFeatures'));
const LearnMoreAboutCoinbag = React.lazy(() => import('../../../components/Marketing/LearnMoreAboutCoinbag'));
const Supported = React.lazy(() => import('../../../components/Marketing/Supported'));
const Pricing = React.lazy(() => import('../Home/Pricing/Pricing'));

const InstitutionalInvestors = () => {
  const classes = useStyles();
  return (
    <main className={classes.root}>
      <SignUp
        type="institutional"
        title={(
          <article className={classes.title}>
            <Typography variant="h1">
              Sign up to Coinbag <br />
              as a <em>Business</em>
            </Typography>
            <Hidden smDown>
              <Typography variant="h2">
                Streamline your digital asset operations in minutes! Create multiple, self-managed and secure wallets to
                distribute your digital asset risk, while benefiting from multi-signature approval, batch-payroll, and
                cross-chain wallet reporting!
              </Typography>
            </Hidden>
            <Link underline="none" href="#feature">
              Learn about our business solutions{' '}
              <SVG src="/icons/icon_arrow_down.svg" height={24} width={24} />
            </Link>
          </article>
        )}
      >
        <Steps steps={institutionalInvestorsSteps} color="blue" title={<>How it <em>works</em></>} />
        <DistributeRisk institutional />
        <Box id="feature" className={classes.feature}>
          <MainFeatures
            title={<>Features to <em>ease your mind</em></>}
            features={institutionalFeatures}
            color="blue"
          />
        </Box>
        <OurPartner
          title={<>Our trusted <em>partners</em></>}
          color="blue"
        />
        <React.Suspense fallback={<></>}>
          <Pricing type="business" />
          <Hidden smDown>
            <DashboardFeatures selectType={1} />
          </Hidden>
          <Supported color="blue" />
          <Hidden mdUp>
            <DashboardFeatures selectType={1} />
          </Hidden>
          <Box className={classes.learnMore}>
            <LearnMoreAboutCoinbag color="blue" />
          </Box>
        </React.Suspense>
      </SignUp>
    </main>
  );
};

InstitutionalInvestors.routePath = SignUpInstitutionalInvestorsRoute;
InstitutionalInvestors.logoutRequired = true;

export default InstitutionalInvestors;
