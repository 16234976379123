import { Box, createStyles, Hidden, Link, makeStyles, useMediaQuery, useTheme } from '@material-ui/core';
import { useConnectWallet } from '@web3-onboard/react';
import { Loading } from 'components';
import RebalancingStatus from 'components/RebalancingStatus';
import { Card, Typography } from 'elements';
import { PagesNavBar } from 'elements/PagesNavBar';
import React, { useEffect, useMemo, useState } from 'react';
import { WalletSettingRoute } from 'routes';
import { WalletProvider } from 'services/types/wallet';
import { WorkItemName, WorkItemStatus } from 'services/types/workItem';
import { CreateWalletParameter } from 'services/types/workItemParameter';
import { useStore as useUserStore } from 'store/zustand/User';
import { useStore as useWorkItemStore } from 'store/zustand/WorkItem';

import AddWalletModal from './components/AddWalletModal';
import { CustodianItem } from './components/CustodianItem';
import DuplicateModal from './components/DuplicateModal';
import WalletItem, { WalletItemProps } from './components/WalletItem';
import { LicensedCustodians } from './components/WalletTypes';

const useStyles = makeStyles((theme) => createStyles({
  root: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'stretch',
    width: '100%',

    [theme.breakpoints.up('md')]: {
      height: '100%',
    },
  },
  content: {
    display: 'grid',
    gridTemplateColumns: '1fr max(368px, 30%)',
    gap: 16,

    padding: 24,
    width: '100%',
    height: '100%',

    [theme.breakpoints.down('sm')]: {
      gridTemplateColumns: '1fr',
      padding: '16px 0 0',
    },
  },
  navbar: {
    zIndex: 10,
    '& > div': { width: '100%' },
  },
  detail: {
    display: 'flex',
    flexDirection: 'column',
    gap: 16,
  },
  sidebar: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'stretch',
    rowGap: 16,
  },
  loading: {
    width: '100%',
    position: 'absolute',
    top: 0,
    left: 0,
    zIndex: 2,
    background: 'white',
  },
  title: {
    color: theme.palette.grey[700],
    fontSize: 16,
    fontWeight: 500,
    lineHeight: '24px',
  },
  subtitle: {
    paddingLeft: 12,

    color: theme.palette.grey[500],
    fontSize: 14,
    fontWeight: 500,
    lineHeight: '24px',

    [theme.breakpoints.down('sm')]: {
      paddingLeft: 0,
      paddingTop: 4,
      fontSize: 12,
      lineHeight: '20px',
    },
  },
  importStatus: {
    height: 'auto',
    [theme.breakpoints.down('sm')]: {
      margin: '0 16px',
    },
  },
  walletList: {
    display: 'flex',
    flexDirection: 'column',
    gap: 8,
    marginTop: 20,

    [theme.breakpoints.down('sm')]: {
      marginTop: 12,
      gap: 0,
    },

    '& > div:last-child': {
      borderBottom: `1px solid ${theme.palette.grey[200]}`,
    },
  },
  sidebarCard: {
    flexDirection: 'column',
    rowGap: 12,
    padding: '12px 16px',
  },
  sidebarTitle: {
    fontSize: 16,
    lineHeight: '24px',
    fontWeight: 500,
    color: theme.palette.grey[700],

    [theme.breakpoints.down('sm')]: {
      fontSize: 16,
      lineHeight: '24px',
    },
  },
  sidebarText: {
    fontSize: 14,
    fontWeight: 500,
    lineHeight: '24px',

    '& > a': {
      color: `${theme.palette.green[600]} !important`,
      '&:hover': {
        color: `${theme.palette.green[700]} !important`,
      },
    },
  },
}));

const WalletSetting = () => {
  const [, connect] = useConnectWallet();

  const [isLoading, setLoading] = useState(true);

  const [newWalletProvider, setNewWalletProvider] = useState<WalletProvider | undefined>(undefined);
  const [showAddModal, setShowAddModal] = useState(false);

  const [user, fetchUser] = useUserStore((state) => [state.user, state.fetchUser]);
  const [workItems, fetchWorkItems] = useWorkItemStore((state) => [state.workItems, state.fetchWorkItems]);
  useEffect(() => {
    (async () => {
      await Promise.allSettled([fetchUser(false), fetchWorkItems(false)]);
      setLoading(false);
    })();
  }, []);

  const isImportingReadOnlyWallet = useMemo(() => (
    (workItems ?? [])
      .filter((i) => i.name === WorkItemName.AddReportingAddress && (i.status === WorkItemStatus.Pending || i.status === WorkItemStatus.InProgress))
      .length > 0
  ), [workItems]);

  const walletItems = useMemo(() => (user?.walletInfo ?? [])
    .map((i) => ({
      status: i.status === 'Active'
        ? 'connected'
        : i.status === 'Inactive'
          ? 'disconnected'
          : 'pending',
      provider: i.walletProvider,
      name: i.name ?? '',
      order: i.order ?? 0,
      isNonCustodian: i.isNonCustodian,
      address: i.address ? i.address[0] : '',
    } as WalletItemProps)), [user]);

  const pendingWalletItems = useMemo(() => (workItems || [])
    .filter((w) => w.name === WorkItemName.CreateWallet && (w.status === WorkItemStatus.Pending || w.status === WorkItemStatus.WaitingForApproval))
    .map((w) => {
      const param = w.parameters as CreateWalletParameter;
      return {
        name: param.name,
        provider: param.walletProvider,
        status: 'pending',
      } as WalletItemProps;
    }), [workItems]);

  const onAddWallet = async (provider: WalletProvider) => {
    setNewWalletProvider(provider);
    setShowAddModal(true);
  };

  const connectedAddresses = (user?.walletInfo ?? []).flatMap((i) => i.address);
  const [onboardAddresses, setOnboardAddresses] = useState<string[]>([]);

  const [showDuplicateModal, setShowDuplicateModal] = useState(false);
  const onConnectWallet = async () => {
    const walletStates = await connect();
    if (walletStates.length === 0) {
      setOnboardAddresses([]);
      setNewWalletProvider(undefined);
      setShowDuplicateModal(false);
      setShowAddModal(false);
      return;
    }

    const addresses = walletStates.flatMap((ws) => ws.accounts.map((a) => a.address));
    if (addresses.some((a) => connectedAddresses.includes(a))) {
      setShowDuplicateModal(true);
    } else {
      setOnboardAddresses(addresses);
      setNewWalletProvider('Self-custody');
      setShowAddModal(true);
    }
  };

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [tab, setTab] = useState(0);

  const classes = useStyles();
  return (
    <Box className={classes.root}>
      {isLoading && <Loading className={classes.loading} />}

      {newWalletProvider && <DuplicateModal showModal={showDuplicateModal} setShowModal={setShowDuplicateModal} />}
      {newWalletProvider && (
        <AddWalletModal
          type={newWalletProvider}
          addresses={onboardAddresses}
          showModal={showAddModal}
          setShowModal={setShowAddModal}
        />
      )}

      <Hidden mdUp>
        <PagesNavBar
          className={classes.navbar}
          items={[
            { text: 'Your active wallets', onSelect: () => setTab(0), selected: tab === 0 },
            { text: 'Add a new wallet', onSelect: () => setTab(1), selected: tab === 1 },
          ]}
        />
      </Hidden>

      <Box className={classes.content}>
        {(!isMobile || (isMobile && tab === 0)) && (
          <Box className={classes.detail}>
            {isImportingReadOnlyWallet && (
              <Box className={classes.importStatus}>
                <RebalancingStatus status="reporting-address" />
              </Box>
            )}

            <Card flexDirection="column" flexGrow={1}>
              <Typography variant="h3" component="span" className={classes.title}>
                Your active wallets
                <Hidden mdUp><br /></Hidden>
                <Typography component="span" className={classes.subtitle}>
                  Assets are distributed evenly across active wallets.
                </Typography>
              </Typography>

              <Box className={classes.walletList}>
                {[...walletItems, ...pendingWalletItems].map((i, index) => <WalletItem {...i} key={index} />)}
              </Box>
            </Card>
          </Box>
        )}

        {(!isMobile || (isMobile && tab === 1)) && (
          <Box className={classes.sidebar}>
            <Card className={classes.sidebarCard} isSection>
              <Typography className={classes.sidebarTitle}>Self-custody</Typography>
              <Typography paletteColor={500} variant="h6">
                MetaMask, Gnosis SAFE, Fireblocks via WalletConnect, Ledger, Trezor and many more available!
              </Typography>
              <CustodianItem
                title="Gnosis SAFE, MetaMask"
                subtitle="& many more"
                iconCircle
                icons={['/icons/wallet_setting/ic_safe.png', '/icons/wallet_setting/ic_metamask.png', '/icons/wallet_setting/ic_3.svg']}
                actionText="Choose"
                actionColor="blue"
                onAction={onConnectWallet}
              />
            </Card>
            <Card className={classes.sidebarCard} isSection>
              <Typography className={classes.sidebarTitle}>Licensed custodians</Typography>
              <Typography variant="h6" paletteColor={500}>
                3rd party custodians with on-chain, customer segregated wallets.
              </Typography>
              {LicensedCustodians.map((custodian) => (
                <CustodianItem
                  key={custodian.title}
                  subtitle="Licensed Custodian"
                  actionText="Add"
                  actionColor="green"
                  onAction={() => onAddWallet(custodian.title)}
                  {...custodian}
                />
              ))}
              <Typography className={classes.sidebarText} paletteColor={700}>
                Don't see yours? <Link underline="always" onClick={() => window.Tawk_API?.maximize()}>Let us know!</Link>
              </Typography>
            </Card>
            <Card className={classes.sidebarCard} isSection>
              <Typography className={classes.sidebarTitle}>Read only</Typography>
              <CustodianItem
                title="Import any EVM wallet"
                subtitle="Tag, track & report on transactions!"
                iconCircle
                icons={['/icons/wallet_setting/ic_reporting_sm.svg']}
                actionText="Add"
                actionColor="purple"
                onAction={() => onAddWallet('Reporting')}
              />
            </Card>

            <Card className={classes.sidebarCard} isSection>
              <Typography className={classes.sidebarTitle}>Self-hosted, self-custody MPC</Typography>
              <Typography variant="h6" paletteColor={500}>
                Coinbag can help install and deploy MPC wallets on your own servers.
              </Typography>
              <Link underline="none" onClick={() => window.Tawk_API?.maximize()} className={classes.sidebarText}>
                Contact us
              </Link>
            </Card>
          </Box>
        )}
      </Box>
    </Box>
  );
};

WalletSetting.routePath = WalletSettingRoute;
WalletSetting.loginRequired = true;
WalletSetting.feature = 'manageWallet';

export default WalletSetting;
