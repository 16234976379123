import { Box, Grid, Hidden, makeStyles, Typography, useTheme } from '@material-ui/core';
import { Card } from 'elements';
import React, { FC } from 'react';
import AuthService from 'services/auth-service';
import { ReferralStats as ReferralStatsType } from 'services/types/wallet';
import WalletService from 'services/wallet-service';

import { ReferralLink } from './ReferralLink';
import { ReferralStats } from './ReferralStats';

type ReferralSectionProps = {
  className?: string;
};

const useStyles = makeStyles({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '16px 0',
  },
});

const ReferralSection: FC<ReferralSectionProps> = ({ className }) => {
  const classes = useStyles();
  const theme = useTheme();

  const [referralLink, setReferralLink] = React.useState('');
  const [stats, setStats] = React.useState<ReferralStatsType | undefined>(undefined);

  React.useEffect(() => {
    const fetchData = async () => {
      const [referralStats, referralCode] = await Promise.all([WalletService.getReferralStats(), AuthService.getReferralCode()]);
      setReferralLink(`https://coinbag.finance/rid/${referralCode.data}`);
      setStats(referralStats);
    };
    fetchData();
  }, []);

  return (
    <Card className={`${classes.root} ${className}`} isSection isLoading={!referralLink || !stats}>
      <Typography variant="h2">Refer & Earn</Typography>
      <Hidden smUp>
        <Box margin="16px 0 0" width="100%" height="1px" bgcolor={theme.palette.grey['200']} />
      </Hidden>
      <Box marginTop="16px" padding="0 16px" width="100%">
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <ReferralLink link={referralLink} />
          </Grid>
          <Hidden smUp>
            <Grid item xs={12}>
              <Box width="100%" height="1px" bgcolor={theme.palette.grey['200']} />
            </Grid>
          </Hidden>
          <Grid item xs={12} md={6}>
            {stats && <ReferralStats stats={stats} />}
          </Grid>
        </Grid>
      </Box>
    </Card>
  );
};

ReferralSection.displayName = 'ReferralSection';
export { ReferralSection };
