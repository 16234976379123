import React, { useCallback, useEffect, useState } from 'react';

import AuthService from '../../services/auth-service';
import WalletService from '../../services/wallet-service';

import { FIELD } from '../../common';

import Loader from '../../components/Loader';
import { InternalNavbar } from '../../components/navbar.js';
import { StepNav } from '../../components/StepNav.js';
import { Footer } from '../../components/Footer';

import GenericModal from '../../components/GenericModal';
import BinanceModal from '../../components/BinanceModal';
import LinkBinanceModal from '../../components/LinkBinanceModal';

import ConnectExchange from '../desktop/ConnectExchange';
import ManageRisk from '../desktop/ManageRisk';

import { Box, Grid, Typography, useMediaQuery, useTheme, withStyles } from '@material-ui/core';
import { ChooseExchangeCoinbagCard } from './ChooseExchange/ChooseExchangeCoinbagCard';
import { ChooseExchangeBinanceCard } from './ChooseExchange/ChooseExchangeBinanceCard';
import FundWallet from './FundWallet';

const TitleText = withStyles({
  root: {
    fontSize: 24,
    fontWeight: 500,
    lineHeight: '28px',
  },
})(Typography);

const SubtitleText = withStyles({
  root: {
    fontSize: 14,
    fontWeight: 400,
    lineHeight: '20px',
  },
})(Typography);

const ChooseExchange = ({ history }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const [wallet, setWallet] = useState({});
  const [form, setForm] = useState({});

  useEffect(() => {
    WalletService.getWallet()
      .then((wallet) => {
        if (!wallet.data.walletId) return;

        setWallet(wallet.data);
        setForm({
          apiKey: FIELD(wallet.data.apiKey),
          apiSecret: FIELD(wallet.data.apiSecret),
          exchangeId: FIELD(wallet.data.exchangeId),
          modify: false
        });
      });
  }, []);

  const goToNext = useCallback((next) => {
    AuthService.updateUserStep()
      .then(() => {
        history.push(next);
      });
  }, [history]);

  const selectExchange = useCallback((id) => {
    if (wallet.riskScore === 0 && id === 1) {
      setOnlyDefiModal(true);
      return;
    }

    WalletService.addExchangeIdSignUp(id)
      .then(() => setForm({
        ...form,
        exchangeId: FIELD(id)
      }));
  }, [wallet, form]);

  const [binanceModalShow, setBinanceModalShow] = useState(false);
  const [linkBinanceModalShow, setLinkBinanceModalShow] = useState(false);
  const [onlyDefiModal, setOnlyDefiModal] = useState(false);

  if (form.exchangeId === undefined) {
    return <Loader loading/>;
  }

  return (
    <>
      <InternalNavbar history={history}/>
      <StepNav active={3}/>

      <div className="desktop-stepnav">
        <TitleText>Funding Options</TitleText>

        <Box sx={{ marginTop: 8 }}>
          <SubtitleText>
            You may choose to skip any of these steps and come back to it later.
          </SubtitleText>
        </Box>

        <Box sx={{ flexGrow: 1, marginTop: 24, width: '100%' }}>
          <Grid container spacing={isMobile ? 2 : 4}>
            <Grid item xs={12} lg={6}>
              <ChooseExchangeCoinbagCard handleSubmit={() => {
                selectExchange(4);
                goToNext(FundWallet.routePath);
              }}/>
            </Grid>

            <Grid item xs={12} lg={6}>
              <ChooseExchangeBinanceCard
                handleCreate={() => {
                  selectExchange(1);
                  if (wallet.riskScore !== 0) setBinanceModalShow(true);
                }}
                handleLink={() => {
                  selectExchange(1);
                  if (wallet.riskScore !== 0) setLinkBinanceModalShow(true);
                }}
              />
            </Grid>
          </Grid>
        </Box>
      </div>

      <Footer/>

      <BinanceModal
        show={binanceModalShow}
        hide={() => setBinanceModalShow(false)}
        next={ConnectExchange.routePath}
        history={history}
      />

      <LinkBinanceModal
        show={linkBinanceModalShow}
        hide={() => setLinkBinanceModalShow(false)}
        next={ConnectExchange.routePath}
        history={history}
      />

      <GenericModal
        show={onlyDefiModal}
        hide={() => setOnlyDefiModal(false)}
      >
        <div className="text-center w-100">
          <h5 className="mb-3">Decentralized Staking</h5>
        </div>
        <p>Your current risk level is: <b>0 / 10</b></p>
        <p>This type of investment is profitable only by using our Decentralized wallet (Option 1: Direct deposit)</p>
        <p>
          Please proceed with option 1 or go back to change your{' '}
          <a href={ManageRisk.routePath}><span className="yellow">risk level here</span></a>
        </p>
      </GenericModal>
    </>
  );
};

ChooseExchange.routePath = '/choose-exchange';
ChooseExchange.loginRequired = true;
ChooseExchange.displayName = 'Choose Exchange';

export default ChooseExchange;
