import { Box } from '@material-ui/core';
import { Button, Typography } from 'elements';
import React from 'react';

interface Props {
  openNotificationBox: () => void;
}

export const WorkItemNotAvailable = ({ openNotificationBox }: Props) => (
  <Box display="flex" flexDirection="column" gridRowGap="16px" padding="0 16px 16px">
    <Typography variant="h6" paletteColor={600} align="center">
      Please open the notification box to check for any pending requests.
    </Typography>
    <Button onClick={openNotificationBox}>Open notification box</Button>
  </Box>
);
