import { Box, makeStyles } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { Loading } from 'components';
import { Button, Card, Checkbox,Typography } from 'elements';
import { InputWithTitle } from 'elements/InputWithTitle';
import { LightTextField } from 'elements/LightTextField';
import { LightTooltip } from 'elements/LightTooltip';
import * as React from 'react';
import { Link } from 'react-router-dom';
import { ResetPasswordRoute } from 'routes';
import AuthService from 'services/auth-service';
import { checkPassword } from 'utils';

import BackgroundIcons from './Components/BackgroundIcons';
import PasswordGuidelines from './Components/PasswordGuidelines';

enum Result {
  None,
  Success,
  AllFieldsMandatory,
  PasswordGuidelinesNotMet,
  PasswordsNotMatching,
  InvalidToken,
  GenericError,
}

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    width: '100%',
    justifyContent: 'center',
    '& em': {
      color: theme.palette.green[600],
      fontStyle: 'normal',
    },
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    minHeight: '70vh',
    backgroundColor: 'white',
    padding: 16,
    '&::before': {
      content: '" "',
      width: '1600px',
      height: '1200px',
      top: '-400px',
      left: '40%',
      position: 'absolute',
      background: 'radial-gradient(48.42% 48.42% at 50% 50%, rgba(46, 255, 218, 0.315) 0%, rgba(235, 255, 252, 0) 100%)',
      zIndex: 0,
      filter: 'blur(80px)',
      [theme.breakpoints.down('sm')]: {
        display: 'none',
      },
    },
  },
  card: {
    maxWidth: 490,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    rowGap: 16,
    textAlign: 'center',
    padding: 24,
    background: 'rgba(255, 255, 255, 0.9)',
    backdropFilter: 'blur(8px)',
    width: '100%',
    [theme.breakpoints.down('sm')]: {
      padding: 24,
    },
  },
  title: {
    color: theme.palette.grey[700],
    fontSize: 28,
    lineHeight: '44px',
    fontWeight: 700,
    [theme.breakpoints.down('sm')]: {
      fontSize: 24,
      lineHeight: '32px',
    },
  },
  subTitle: {
    color: theme.palette.grey[700],
    fontSize: 14,
    lineHeight: '24px',
    fontWeight: 400,
    [theme.breakpoints.down('sm')]: {
      fontSize: 12,
      lineHeight: '20px',
    },
  },
  input: {
    width: '100%',
    margin: '4px 0',
    display: 'flex',
    flexDirection: 'column',
    gap: 12,
  },
  loading: {
    position: 'absolute',
    top: 0,
    left: 0,
    zIndex: 2,
  },
  greenText: { color: theme.palette.green[600] },
  passwordTooltip: {
    borderRadius: 8,
    backgroundColor: 'white',
    boxShadow: '0px 4px 32px rgba(105, 107, 122, 0.24)',
  },
  passwordTooltipContent: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'stretch',
    rowGap: 8,
    padding: '8px 4px 4px',
  },
  passwordTooltipRow: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    columnGap: 24,
    paddingBottom: 4,
    color: theme.palette.grey[700],
  },
  passwordTooltipText: {
    fontSize: 12,
    color: theme.palette.grey[700],
  },
}));

export const passwordCheck = /^(?=.*[A-Z])(?=.*[$&+,:;=?@#|'<>.^*()%!-])(?=.*[0-9]).{8,64}/;

const Reset = () => {
  const classes = useStyles();

  const [token, setToken] = React.useState<string | null>('');
  const [result, setResult] = React.useState(Result.None);
  const [isProcessing, setIsProcessing] = React.useState(false);
  const [password, setPassword] = React.useState('');
  const [confirmPassword, setConfirmPassword] = React.useState('');
  const [showPwdTooltip, setShowPwdTooltip] = React.useState<boolean>(false);

  React.useEffect(() => {
    const query = new URLSearchParams(window.location.search);
    const token = query.get('token');

    if (token === null) {
      window.location.href = '/home';
    }
    setToken(token);
  });

  const handleClick = React.useCallback(
    async () => {
      setIsProcessing(true);
      setResult(Result.None);

      if (!password || !confirmPassword) {
        setIsProcessing(false);
        return setResult(Result.AllFieldsMandatory);
      }
      if (!passwordCheck.test(password)) {
        setIsProcessing(false);
        return setResult(Result.PasswordGuidelinesNotMet);
      }
      if (password !== confirmPassword) {
        setIsProcessing(false);
        return setResult(Result.PasswordsNotMatching);
      }

      try {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        const response = await AuthService.updatePassword(token, password);
        if (!response.data.validPsw) return setResult(Result.PasswordGuidelinesNotMet);
        if (!response.data.validToken) return setResult(Result.InvalidToken);
        return setResult(Result.Success);
      } catch (error: any) {
        return setResult(Result.GenericError);
      } finally {
        setIsProcessing(false);
      }
    },
    [token, password, confirmPassword],
  );

  const PasswordTooltipRow = React.useCallback(({ title = '', passed = false }) => (
    <Box className={classes.passwordTooltipRow}>
      <Typography
        palette="grey"
        paletteColor={800}
        className={`${classes.passwordTooltipText} ${passed && classes.greenText}`}
        strong={passed}
      >
        {title}
      </Typography>

      <Checkbox
        size="small"
        icon={<img alt="" src="/icons/account_details/icon_check_empty.svg" width={16} height={16} />}
        checkedIcon={<img alt="" src="/icons/account_details/icon_check_fill.svg" width={16} height={16} />}
        checked={passed}
      />
    </Box>
  ), []);

  const PasswordTooltip = React.useCallback(() => {
    const check = checkPassword(password);
    return (
      <Box className={classes.passwordTooltipContent}>
        <Typography style={{ fontSize: 12, lineHeight: '20px' }} palette="grey" paletteColor={600}>
          Password must include:
        </Typography>
        <PasswordTooltipRow title="12 - 64 characters" passed={check[0]} />
        <PasswordTooltipRow title="1 capital letter" passed={check[1]} />
        <PasswordTooltipRow title="1 number" passed={check[2]} />
        <PasswordTooltipRow title="1 special character e.g. _*)(@#!" passed={check[3]} />
      </Box>
    );
  }, [password]);

  return (
    <Box className={classes.root}>
      <Box className={classes.content}>
        <BackgroundIcons />
        <Card className={classes.card} borderRadius={32}>
          {isProcessing ? (<Loading className={classes.loading} />) : undefined}
          <Typography className={classes.title} variant="h1">Reset <em>password?</em></Typography>
          <Typography className={classes.subTitle} variant="h2" strong={false} palette="grey" paletteColor={700}>
            Create new strong password
          </Typography>
          <Box className={classes.input}>
            <InputWithTitle title="New password">
              <LightTooltip
                placement="bottom"
                arrow
                classes={{ tooltip: classes.passwordTooltip }}
                title={<PasswordTooltip />}
                open={showPwdTooltip}
                disableHoverListener
                disableTouchListener
              >
                <Box width="100%">
                  <LightTextField
                    fullWidth
                    type="password"
                    data-tip=""
                    data-for="password"
                    data-place="left"
                    data-event="keydown"
                    data-event-off="focusout"
                    onFocus={() => setShowPwdTooltip(true)}
                    onBlur={() => setShowPwdTooltip(false)}
                    size="medium"
                    name="password"
                    placeholder="Create a strong password"
                    value={password}
                    autoComplete="new-password"
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => setPassword(e.target.value)}
                    onKeyPress={(event: React.KeyboardEvent<HTMLDivElement>) => event.key === 'Enter' && handleClick()}
                  />
                </Box>
              </LightTooltip>
            </InputWithTitle>
            <InputWithTitle title="Confirm new password">
              <LightTextField
                fullWidth
                type="password"
                name="confirm"
                placeholder="Confirm your password"
                value={confirmPassword}
                size="medium"
                autoComplete="new-password"
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => setConfirmPassword(e.target.value)}
                onKeyPress={(event: React.KeyboardEvent<HTMLDivElement>) => event.key === 'Enter' && handleClick()}
              />
            </InputWithTitle>
          </Box>
          {result !== Result.None && result !== Result.Success ? (
            <Box textAlign="left" width="100%">
              {result === Result.AllFieldsMandatory && (
                <Typography variant="body2" color="error">All fields are mandatory</Typography>
              )}
              {result === Result.PasswordsNotMatching && (
                <Typography variant="body2" color="error">Passwords do not match</Typography>
              )}
              {result === Result.GenericError && (
                <Typography variant="body2" color="error">Something went wrong. Please try again.</Typography>
              )}
              {result === Result.PasswordGuidelinesNotMet && (
                <Box>
                  <Typography variant="body2" color="error">
                    Invalid password. It must contain:
                  </Typography>
                  <PasswordGuidelines color="error" />
                </Box>
              )}
              {result === Result.InvalidToken && (
                <Alert severity="error">
                  <Typography variant="body2">Unfortunately, your link has expired or is no longer valid.</Typography>
                </Alert>
              )}
            </Box>
          ) : null}
          {result === Result.Success && (
            <Alert severity="success">
              <Typography align="left" variant="body2">
                Thank you! Your password has been reset. You can now <Link to="/login">Sign in</Link>
              </Typography>
            </Alert>
          )}
          <Button
            fullWidth
            name="create-account"
            onClick={handleClick}
            type="button"
            size="xlg"
          >
            Reset Password
          </Button>
        </Card>
      </Box>
    </Box>
  );
};

Reset.routePath = ResetPasswordRoute;
Reset.loginRequired = false;
Reset.displayName = 'Reset';

export default Reset;
