import { Box, createStyles, Divider, Hidden } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import React from 'react';
import SVG from 'react-inlinesvg';
import { Link } from 'react-router-dom';

import { Typography } from '../../../elements';

const useStyles = makeStyles((theme) => createStyles({
  root: {
    filter: 'drop-shadow(0px 4px 40px rgba(81, 89, 111, 0.1))',
    display: 'flex',
    justifyContent: 'center',
  },
  content: {
    width: '100%',
    maxWidth: 1200,
    display: 'grid',
    gridTemplateColumns: '1fr 200px 1fr',
    alignItems: 'center',
    padding: 24,
    [theme.breakpoints.down('sm')]: {
      gridTemplateColumns: '1fr',
      gap: 32,
      justifyContent: 'flex-start',
      flexDirection: 'column',
      padding: '8px 16px',
    },
  },
  contentColumn: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    gap: 24,
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      padding: 0,
    },
  },
  logoWrapper: {
    width: 32,
    height: 32,
    display: 'flex',
  },
  titleWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    gap: 8,
  },
  coinbagLogoWrapper: {
    width: 98,
    height: 24,
    display: 'flex',
  },
  powerByCoinbag: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    gap: 12,
  },
  head: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    gap: 24,
    width: '100%',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
  },
  socialLinks: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'flex-end',
    height: '100%',
  },
  socialLinkIconWrapper: {
    display: 'flex',
    width: '100%',
    maxWidth: 108,
    justifyContent: 'space-between',
    [theme.breakpoints.down('sm')]: {
      padding: 0,
    },
  },
  centerText: {
    textAlign: 'center',
  },
  legalDetailsWrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
    justifyContent: 'space-between',
    height: '100%',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'row',
      alignItems: 'center',
      padding: '0 24px',
    },
  },
  divider: {
    height: '70%',
    borderColor: theme.palette.grey[300],
    backgroundColor: theme.palette.grey[300],
  },
}));

export const WalletReportingFooter = () => {
  const classes = useStyles();
  return (
    <footer className={classes.root}>
      <Box className={classes.content}>
        <Box className={classes.contentColumn}>
          <Box className={classes.head}>
            <Box className={classes.titleWrapper}>
              <Link to="/" className={classes.logoWrapper}>
                <SVG src="icons/wallet_reporting/ic_logo.svg" width={32} height={32} />
              </Link>
              <Typography variant="h6" palette="grey" paletteColor={600}>Wallet Reporting</Typography>
            </Box>
            <Box className={classes.powerByCoinbag}>
              <Typography variant="h6" palette="grey" paletteColor={500}>Powered by:</Typography>
              <a href="https://coinbag.finance" className={classes.coinbagLogoWrapper}>
                <SVG src="logos/coinbag_long.svg" width={98} height={24} />
              </a>
            </Box>
          </Box>
          <Hidden smDown>
            <Typography
              variant="h4"
              palette="grey"
              paletteColor={500}
            >Copyright © 2023 WalletReporting.com. All rights reserved.
            </Typography>
          </Hidden>
        </Box>
        <Box className={classes.socialLinks}>
          <Typography variant="h6" palette="grey" paletteColor={500}>Follow & reach us:</Typography>
          <Box className={classes.socialLinkIconWrapper}>
            <Link to="/"><SVG width={20} height={20} src="/icons/ic_linkedin.svg" /></Link>
            <Link to="/"><SVG width={20} height={20} src="/icons/ic_facebook.svg" /></Link>
            <Link to="/"><SVG width={20} height={20} src="/icons/ic_instagram.svg" /></Link>
            <Link to="/"><SVG width={20} height={20} src="/icons/ic_twitter.svg" /></Link>
          </Box>
        </Box>
        <Box className={classes.legalDetailsWrapper}>
          <Link to="/">
            <Typography variant="h4" palette="grey" paletteColor={500}>
              Terms of Use
            </Typography>
          </Link>
          <Hidden mdUp>
            <Divider orientation="vertical" className={classes.divider} />
          </Hidden>
          <Link to="/">
            <Typography variant="h4" palette="grey" paletteColor={500}>
              Privacy Policy
            </Typography>
          </Link>
          <Hidden mdUp>
            <Divider orientation="vertical" className={classes.divider} />
          </Hidden>
          <Link to="/">
            <Typography variant="h4" palette="grey" paletteColor={500}>
              Disclaimer
            </Typography>
          </Link>
        </Box>
        <Hidden mdUp>
          <Typography
            variant="h4"
            palette="grey"
            paletteColor={500}
            className={classes.centerText}
          >Copyright © 2023 WalletReporting.com. All rights reserved.
          </Typography>
        </Hidden>
      </Box>
    </footer>
  );
};
