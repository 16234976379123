import {
  Box,
  createStyles,
  makeStyles,
} from '@material-ui/core';
import { Typography } from 'elements';
import { InfoBanner } from 'elements/InfoBanner';
import { InputWithTitle } from 'elements/InputWithTitle';
import { LightTooltip } from 'elements/LightTooltip';
import React, { FC, useEffect, useMemo } from 'react';
import { CoinsWithMasterCoins, CoinWithMasterCoinInfo } from 'services/types/coin';
import { Coin, FA, FAResponseStatus } from 'services/types/wallet';
import WalletService from 'services/wallet-service';

import { FAModal } from '../FAModal';
import { AddressField } from './Components/AddressField';
import { AmountField } from './Components/AmountField';
import { MemoField } from './Components/MemoField';
import { SelectNetwork } from './Components/SelectNetwork';
import { SelectToken } from './Components/SelectToken';
import { SelectWalletField } from './Components/SelectWalletField';
import { WithdrawWarningBanner } from './Components/WithdrawWarningBanner';
import { unsupportedChain } from './UnsupportedChain';
import { WithdrawSummary } from './WithdrawSummary';

interface WithdrawPercentSectionProps {
  withdrawableCoins: CoinsWithMasterCoins;
  portfolioValue: number;
  luna: Coin | null;
  terra2Holding: React.ReactNode;
}

const useStyles = makeStyles((theme) => createStyles({
  root: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    gap: 16,
  },
  withdrawNoteBanner: {
    background: '#F9FBFF',
    lineHeight: '16px',
    color: '#4078E8',
  },
  amountLabel: {
    fontWeight: 500,
    fontSize: 14,
    lineHeight: '24px',
    color: theme.palette.grey[800],
    marginBottom: 4,
  },
  errorText: {
    textAlign: 'right',
    lineHeight: '16px',
    color: theme.palette.red['500'],
  },
  availableToWithdrawText: {
    color: theme.palette.grey[500],
    fontSize: 12,
    fontWeight: 400,
    lineHeight: '16px',
    display: 'inline',
    '&:last-of-type': {
      marginRight: 4,
    },
  },
  availableToWithdrawAmount: {
    color: theme.palette.grey[600],
    fontSize: 12,
    fontWeight: 600,
    lineHeight: '16px',
    padding: '0 4px',
    display: 'inline',
  },
  availableToWithdrawIcon: {
    width: 14,
    height: 14,
    display: 'inline',
    cursor: 'pointer',
  },
  availableToWithdrawTooltip: {
    margin: 8,
    textAlign: 'center',
  },
  availableToWithdrawTooltipText: {
    color: theme.palette.grey[500],
  },
  availableToWithdrawTooltipEmail: {
    color: theme.palette.green[500],
    textDecoration: 'underline',
  },
}));

export const WithdrawPercentSection: FC<WithdrawPercentSectionProps> = ({
  withdrawableCoins,
  portfolioValue,
  luna,
  terra2Holding,
}) => {
  const classes = useStyles();

  const [amount, setAmount] = React.useState(0);
  const [percentageAmount, setPercentageAmount] = React.useState(0);
  const [withdrawableTokens, setWithdrawableTokens] = React.useState<Record<string, CoinWithMasterCoinInfo[]>>({});
  const [selectedMasterCoin, setSelectedMasterCoin] = React.useState<string>('');
  const [selectableNetworks, setSelectableNetworks] = React.useState<[number, string][]>([]);
  const [selectedNetwork, setSelectedNetwork] = React.useState<string>('');
  const [withdrawCoin, setWithdrawCoin] = React.useState<number | null>(null);
  const [addressPatternError, setAddressPatternError] = React.useState<boolean>(false);
  const [isNoMemoChecked, setIsNoMemoChecked] = React.useState(false);
  const [isFaModalOpen, setFaModalOpen] = React.useState<boolean>(false);
  const [memo, setMemo] = React.useState<string | null>(null);
  const [address, setAddress] = React.useState('');

  // This useEffect is use for extract withdrawable coins from props and parse to withdrawbleTokens
  useEffect(() => {
    const tokens = Object.values(withdrawableCoins);
    const masterCoins = tokens.reduce<Record<string, CoinWithMasterCoinInfo[]>>((coins, token) => ({
      ...coins,
      [token.masterCoin.ticker]: [...coins[token.masterCoin.ticker] || [], token],
    }), {});
    setWithdrawableTokens(masterCoins);
  }, [withdrawableCoins]);

  // This useEffect is used for filter network to select after withdrawable Token is selected
  useEffect(() => {
    if (!selectedMasterCoin) return;
    const networks: [number, string][] = withdrawableTokens[selectedMasterCoin]
      .map((coin) => ([coin.coinId, coin.chainId]));
    const networksFiltered = networks
      .filter((network) => !unsupportedChain.includes(network[1]));
    setSelectableNetworks(networksFiltered);
    if (networksFiltered.length === 1) setSelectedNetwork(networksFiltered[0][1]);
  }, [selectedMasterCoin]);

  useEffect(() => {
    const coin = withdrawableTokens[selectedMasterCoin]?.find((c) => c.chainId === selectedNetwork);
    if (coin != null) setWithdrawCoin(coin.coinId);
  }, [selectedNetwork, selectedMasterCoin]);

  const handleWithdrawButton = () => {
    if (isWithdrawable) {
      if (isWithdrawFullAmount) {
        setPercentageAmount(100);
        setAmount(parseFloat(availableToWithdrawAmount.toFixed(2)));
      }
      setFaModalOpen(true);
    }
  };

  const isWithdrawable = useMemo((): boolean => {
    const haveAmount: boolean = amount != null && +amount > 3;
    const havePercentage: boolean = percentageAmount != null && +percentageAmount > 0;
    const filledAddress: boolean = address !== '' && !addressPatternError;
    const haveMemo: boolean = ((selectedNetwork !== 'terra-mainnet')
      || (!isNoMemoChecked && (memo != null && memo !== ''))
      || isNoMemoChecked);

    return haveAmount && havePercentage && filledAddress && haveMemo;
  }, [amount, percentageAmount, address, selectedNetwork, isNoMemoChecked, memo, addressPatternError]);

  const availableToWithdrawAmount = useMemo((): number => portfolioValue - (luna?.price ?? 0), [portfolioValue, luna]);
  const isWithdrawFullAmount = useMemo(
    (): boolean => availableToWithdrawAmount - (Number(amount) * 1) < 350,
    [amount, availableToWithdrawAmount],
  );

  const faModalDetail = useMemo(() => (
    <Typography variant="h6" paletteColor={700} align="center">
      This process will take few minutes. It will convert your assets to {selectedMasterCoin} and transfer{' '}
      <Typography component="span" variant="h6" palette="green" paletteColor={500} noWrap>
        {percentageAmount ? +percentageAmount : 0}% (~{amount ? +amount : 0} USD)
      </Typography>
      {' '}of your portfolio
    </Typography>
  ), [percentageAmount, amount, selectedMasterCoin]);
  const handleOnSubmitFA = async (fa: FA): Promise<FAResponseStatus> => {
    const res = await WalletService.withdraw(
      fa, (percentageAmount ? +percentageAmount : 0) / 100,
      address ?? '',
      withdrawCoin ?? 0,
    );
    return res.data;
  };

  return (
    <>
      <Box className={classes.root}>
        <SelectWalletField />
        <InputWithTitle
          title="Which amount or percentage would you like to withdraw?"
        >
          <AmountField
            unit="USD"
            availableAmount={parseFloat(availableToWithdrawAmount.toFixed(2))}
            value={amount}
            percent={percentageAmount}
            onPercentChanged={setPercentageAmount}
            onValueChanged={setAmount}
          />
        </InputWithTitle>
        <Box>
          <Typography className={classes.availableToWithdrawText}>Available to withdraw:</Typography>
          <Typography className={classes.availableToWithdrawAmount}>
            {`${parseFloat((availableToWithdrawAmount * 1).toFixed(2)).toLocaleString()} USD`}
          </Typography>
          <Typography className={classes.availableToWithdrawText}>Equivalent</Typography>
          <LightTooltip
            arrow
            leaveDelay={1000}
            title={(
              <Box className={classes.availableToWithdrawTooltip}>
                <Typography variant="h6" className={classes.availableToWithdrawTooltipText}>
                  Due to your LUNA holdings, your actual portfolio value will be
                  higher than the available amount you can withdraw on this page.
                  To withdraw your LUNA, please contact us.
                </Typography>
                <a href="mailto:support@coinbag.finance">
                  <Typography variant="h6" className={classes.availableToWithdrawTooltipEmail}>
                    support@coinbag.finance
                  </Typography>
                </a>
              </Box>
            )}
          >
            <img
              data-for="withdrawTooltip"
              data-tip=""
              src="/icons/withdraw/info_icon_blue.svg"
              alt="icon"
              className={classes.availableToWithdrawIcon}
            />
          </LightTooltip>
        </Box>
        <InfoBanner
          modifier={classes.withdrawNoteBanner}
          title="Please note: Withdrawals using this method may take 10-20 minutes."
          description="Due to this, price fluctuations of cryptocurrencies may alter the total amount received."
          icon="/icons/withdraw/pending_icon_blue.svg"
        />
        <InputWithTitle title="Select token to receive">
          <SelectToken
            type="Withdraw"
            tokens={withdrawableTokens}
            setSelectedMasterCoin={setSelectedMasterCoin}
          />
        </InputWithTitle>
        {selectedMasterCoin && (
          <InputWithTitle title="Network">
            <SelectNetwork
              selectableNetworks={selectableNetworks}
              selectedNetwork={selectedNetwork}
              setSelectedNetwork={setSelectedNetwork}
            />
          </InputWithTitle>
        )}
        {selectedNetwork && (
          <>
            <InputWithTitle
              title="Receiving address:"
              titleRightItem={
                addressPatternError &&
                <Typography variant="h6" className={classes.errorText}>
                  Wallet address is not valid
                </Typography>
              }
            >
              <AddressField
                address={address}
                setAddress={setAddress}
                selectedNetwork={selectedNetwork}
                setAddressPatternError={setAddressPatternError}
              />
            </InputWithTitle>
            <WithdrawWarningBanner
              selectedMasterCoin={selectedMasterCoin}
              selectedNetwork={selectedNetwork}
            />
            {(selectedNetwork === 'terra-mainnet') && (
              <MemoField
                memo={memo}
                setMemo={setMemo}
                isNoMemoChecked={isNoMemoChecked}
                setIsNoMemoChecked={setIsNoMemoChecked}
              />
            )}
          </>
        )}
      </Box>
      {terra2Holding}
      {selectedNetwork && (
        <WithdrawSummary
          amount={amount}
          handleWithdrawButton={handleWithdrawButton}
          isWithdrawFullAmount={isWithdrawFullAmount}
          isWithdrawable={isWithdrawable}
          withdrawCoinId={withdrawCoin}
          selectedMasterCoin={withdrawableTokens[selectedMasterCoin][0].masterCoin}
        />
      )}
      <FAModal
        title="Confirm Withdrawing"
        detail={faModalDetail}
        onSubmit={handleOnSubmitFA}
        open={isFaModalOpen}
        setOpen={setFaModalOpen}
        emailParams={{
          name: 'Withdrawal',
          symbol: 'USD',
          amount: `~${amount ? +amount : 0}`,
          address,
        }}
      />
    </>
  );
};
