function tryParseJSONObject(jsonString: string): any {
  try {
    const o = JSON.parse(jsonString);

    if (o && typeof o === 'object') {
      return o;
    }
    // eslint-disable-next-line no-empty
  } catch (e) { }

  return false;
}

export default tryParseJSONObject;
