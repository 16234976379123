import { Box, Button, createStyles, Hidden, makeStyles, Typography } from '@material-ui/core';
import React, { useCallback, useState } from 'react';
import { breakpoints } from 'theme/base';

import { UserInfo } from '../../../services/auth-service';
import UserService from '../../../services/user-service';

const useStyles = makeStyles((theme) => createStyles({
  root: {
    display: 'flex',
    width: '100%',
    padding: '16px',
    gap: '100px',
    borderColor: theme.palette.red[300],
    borderRadius: '8px',
    borderWidth: '1px',
    borderStyle: 'solid',
    color: theme.palette.grey[600],
  },
  column: {
    display: 'flex',
    flexDirection: 'row',
    gap: '8px',
    width: '100%',
    [breakpoints.down('sm')]: {
      flexDirection: 'column',
    },
  },
  note: {
    color: theme.palette.red[500],
    '& h6': {
      fontWeight: 400,
    },
    '& strong': {
      '& a': {
        color: theme.palette.blue[600],
        textDecoration: 'underline !important',
      },
    },
    display: 'flex',
    gap: '8px',
    [breakpoints.down('sm')]: {
      flexDirection: 'column',
    },
  },
  icon: {
    alignSelf: 'flex-start',
  },
  textGroup: {
    display: 'flex',
    flexDirection: 'column',
    gap: '8px',
    width: '100%',
  },
  resendLink: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'flex-start',
    flexShrink: 0,
    flexGrow: 0,
    textTransform: 'uppercase',
    color: theme.palette.green[500],
  },
  header: {
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'space-between',
    flexWrap: 'nowrap',
  },
  resendButton: {
    background: 'none',
    textTransform: 'none',
    color: theme.palette.green[500],
    '&:hover': {
      background: 'none',
    },
  },
  error: {
    color: theme.palette.red[500],
  },
}));

interface Props {
  userInfo: UserInfo;
}

export const RevokeAccessBanner: React.FC<Props> = ({ userInfo }: Props) => {
  const classes = useStyles();

  const [resendInProgress, setResendInProgress] = useState(false);
  const [resendDone, setResendDone] = useState(false);
  const [isError, setIsError] = useState(false);

  const handleResend = useCallback(async () => {
    try {
      setResendInProgress(true);
      setResendDone(false);
      await UserService.resendRevokeEmail();
      setResendDone(true);
      setResendInProgress(false);
    } catch (_) {
      setIsError(true);
    }
  }, [userInfo]);

  if (!userInfo) {
    return null;
  }

  if (userInfo && userInfo.deactivationAt) {
    const revokeDate = new Date(new Date(userInfo.deactivationAt || new Date()));
    return (
      <Box className={classes.root}>
        <Box className={classes.column}>
          <Box className={classes.header}>
            <img className={classes.icon} src="icons/account_details/icon_lock.svg" alt="lock" />
          </Box>
          <Box className={classes.textGroup}>
            <Typography variant="h5">
              You have revoked Coinbag’s access to your wallets
            </Typography>
            <Box className={classes.note}>
              <Typography variant="h6">
                This account is now disabled. As of {revokeDate.toDateString()}, Coinbag can no longer
                assist you in
                recovering your wallets as you revoked access and data has been deleted.
              </Typography>
            </Box>
          </Box>
        </Box>
      </Box>
    );
  }

  return (
    <Box className={classes.root}>
      <Box className={classes.column}>
        <Box className={classes.header}>
          <img className={classes.icon} src="icons/account_details/icon_lock.svg" alt="lock" />
          <Hidden mdUp>
            <Box className={classes.resendLink}>
              {resendDone ? (
                <Typography variant="h6">
                  Email sent
                </Typography>
              ) : (
                <>
                  {!isError ? (
                    <Button className={classes.resendButton} variant="text" onClick={handleResend}>
                      <Typography variant="h6">
                        {resendInProgress ? 'Sending...' : 'Resend email'}
                      </Typography>
                    </Button>
                  ) : (
                    <Typography variant="h6" className={classes.error}>
                      Error sending email
                    </Typography>
                  )}
                </>
              )}
            </Box>
          </Hidden>
        </Box>
        <Box className={classes.textGroup}>
          <Typography variant="h5">
            You have revoked Coinbag’s access to your wallets
          </Typography>
          <Typography variant="h6">
            We have sent you the link to your email with the access to your private keys, and your Coinbag account has
            been disabled.
          </Typography>
          <Box className={classes.note}>
            <Typography variant="h6">
              Note:
            </Typography>
            <Typography variant="h6">
              After you click confirm email delivery, Coinbag will no longer be able to assist you in recovering your private
              keys. It is important you save them safely.<br />
              <strong>If you have not received an email, please check your spam or contact us immediately at: <a
                href="mailto:support@coinbag.finance">support@coinbag.finance</a></strong>
            </Typography>
          </Box>
        </Box>
        <Hidden smDown>
          <Box className={classes.resendLink}>
            {resendDone ? (
              <Typography variant="h6">
                Email sent
              </Typography>
            ) : (
              <>
                {!isError ? (
                  <Button className={classes.resendButton} variant="text" onClick={handleResend}>
                    <Typography variant="h6">
                      {resendInProgress ? 'Sending...' : 'Resend email'}
                    </Typography>
                  </Button>
                ) : (
                  <Typography variant="h6" className={classes.error}>
                    Error sending email
                  </Typography>
                )}
              </>
            )}
          </Box>
        </Hidden>
      </Box>
    </Box>
  );
};
