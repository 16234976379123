import { Box, Button, Divider, Theme, Typography, withStyles } from '@material-ui/core';
import * as React from 'react';

interface ChooseExchangeBinanceCardProps {
  handleCreate: () => void;
  handleLink: () => void;
}

const ContainerBox = withStyles((theme: Theme) => ({
  root: {
    position: 'relative',
    display: 'flex',

    flexDirection: 'column',
    justifyContent: 'flex-start',
    flexGrow: 1,

    height: 'calc(100% - 44px)',
    width: '100%',
    marginTop: 44,

    background: '#FFFFFF',
    boxShadow: '3px 4px 20px rgba(0, 0, 0, 0.15)',
    borderRadius: 10,

    [theme.breakpoints.down('sm')]: {
      marginTop: 0,
      height: '100%',
    },
  },
}))(Box);

const WalletBox = withStyles({
  root: {
    display: 'flex',
    alignItems: 'baseline',
    columnGap: 12,
    padding: '12px 12px',
  },
})(Box);

const WalletTitle = withStyles({
  root: {
    marginTop: 8,

    color: '#2A2A2E',
    fontSize: 14,
    fontWeight: 500,
    lineHeight: '21px',
  },
})(Typography);

const WalletSubtitle = withStyles({
  root: {
    marginTop: 4,

    color: '#327261',
    fontSize: 12,
    fontWeight: 400,
    lineHeight: '20px',
  },
})(Typography);

const BenefitBox = withStyles({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'start',

    rowGap: 8,
    margin: '12px 16px 0',
  },
})(Box);

const GreenSemiboldText = withStyles({
  root: {
    display: 'inline',
    color: '#1A9071',
    fontSize: 16,
    fontWeight: 600,
    lineHeight: '16px',
  },
})(Typography);

const BlackSemiboldText = withStyles({
  root: {
    color: '#000000',
    fontSize: 12,
    fontWeight: 600,
    lineHeight: '20px',
  },
})(Typography);

const BlackRegularText = withStyles({
  root: {
    color: '#000000',
    fontSize: 12,
    fontWeight: 400,
    lineHeight: '20px',
  },
})(Typography);

const ButtonGroupBox = withStyles((theme: Theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    rowGap: 12,

    margin: 'auto 16px 16px',
    [theme.breakpoints.down('sm')]: {
      margin: 16,
    },
  },
}))(Box);

const OutlinedButton = withStyles({
  root: {
    boxSizing: 'border-box',
    width: '100%',
    height: '40px',

    color: '#10AC84',
    fontSize: '14px',
    lineHeight: '16px',

    background: '#FFFFFF',
    border: '3px solid #10AC84',
    borderRadius: '36px',
    boxShadow: 'none',

    '&:hover': {
      color: '#FFFFFF',
      backgroundColor: '#10AC84',
      border: '3px solid #10AC84',
      boxShadow: 'none',
    },
  },
})(Button);

const TextButton = withStyles({
  root: {
    boxSizing: 'border-box',
    width: '100%',
    padding: 0,

    color: '#10AC84',
    fontSize: '14px',
    lineHeight: '16px',

    '&:hover': {
      backgroundColor: '#FFFFFF',
      boxShadow: 'none',
    },
  },
})(Button);

const ListItem = ({ children }: any) => (
  <Box sx={{ display: 'flex', alignItems: 'baseline' }}>
    <Box sx={{ width: 16, height: 16, marginRight: 4 }}>
      <img alt="Bullet icon" src="/icons/ic_bullet_fill_green.svg" height={16} width={16} />
    </Box>
    {children}
  </Box>
);

const ChooseExchangeBinanceCard: React.FC<ChooseExchangeBinanceCardProps> = ({
  handleCreate,
  handleLink,
}: ChooseExchangeBinanceCardProps) => (
  <ContainerBox>
    <WalletBox>
      <Box sx={{ width: 18, height: 18 }}>
        <img alt="Coinbag logo" src="/images/logo_binance.svg" height={18} width={18} />
      </Box>

      <WalletTitle>Fund a Binance.com Account</WalletTitle>
      <WalletSubtitle>crypto only</WalletSubtitle>
    </WalletBox>

    <Divider />

    <BenefitBox>
      <Box sx={{ display: 'flex', alignItems: 'baseline' }}>
        <Box sx={{ width: 20, height: 20, marginRight: 4 }}>
          <img alt="Benefit icon" src="/icons/ic_favorite_fill_yellow.svg" height={20} width={20} />
        </Box>
        <BlackSemiboldText>Benefits:</BlackSemiboldText>
      </Box>

      <ListItem>
        <BlackRegularText>Intelligent portfolio management</BlackRegularText>
      </ListItem>

      <ListItem>
        <BlackRegularText>Deposit/Withdraw local currencies</BlackRegularText>
      </ListItem>

      <ListItem>
        <BlackRegularText>Low rebalancing fees</BlackRegularText>
      </ListItem>
    </BenefitBox>

    <BenefitBox>
      <Box sx={{ display: 'flex', alignItems: 'baseline' }}>
        <Box sx={{ width: 20, height: 20, marginRight: 4 }}>
          <img alt="Benefit icon" src="/icons/ic_subtract_fill_yellow.svg" height={20} width={20} />
        </Box>
        <BlackSemiboldText>Drawbacks:</BlackSemiboldText>
      </Box>

      <ListItem>
        <BlackRegularText>No interest earning features</BlackRegularText>
      </ListItem>
    </BenefitBox>

    <BenefitBox>
      <Box sx={{ display: 'flex', alignItems: 'baseline' }}>
        <Box sx={{ width: 20, height: 20, marginRight: 4 }}>
          <img alt="Benefit icon" src="/icons/ic_paper_fill_yellow.svg" height={20} width={20} />
        </Box>
        <BlackSemiboldText>Terms:</BlackSemiboldText>
      </Box>

      <ListItem>
        <BlackRegularText>Minimum investment: <GreenSemiboldText>100
          USD
        </GreenSemiboldText> equivalent
        </BlackRegularText>
      </ListItem>

      <ListItem>
        <BlackRegularText>Fees: <GreenSemiboldText>Free</GreenSemiboldText> but no interest earned on
          cryptos
        </BlackRegularText>
      </ListItem>

      <ListItem>
        <BlackRegularText>Deposit/Withdrawal at any time, no penalties</BlackRegularText>
      </ListItem>

      <ListItem>
        <BlackRegularText>Account protected with military grade encryption</BlackRegularText>
      </ListItem>
    </BenefitBox>

    <ButtonGroupBox>
      <TextButton disableRipple onClick={handleLink}>Connect an existing Binance account</TextButton>
      <OutlinedButton onClick={handleCreate}>Create an account with Binance</OutlinedButton>
    </ButtonGroupBox>
  </ContainerBox>
);

ChooseExchangeBinanceCard.displayName = 'ChooseExchangeBinanceCard';
export { ChooseExchangeBinanceCard };
