import { Box, createStyles } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import React from 'react';

const useStyles = makeStyles((theme) => createStyles({
  root: {
    zIndex: 1200,
    position: 'absolute',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,

    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',

    background: theme.palette.common.white,
    borderRadius: 8,
    opacity: 0.8,
  },
}));

export const OverlayLoading = () => {
  const classes = useStyles();
  return (
    <Box className={classes.root}>
      <Box className="sk-spinner-pulse" />
    </Box>
  );
};
