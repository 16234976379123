import { create } from 'zustand';

import ChainService from '../../services/chain.service';
import { ChainDetails } from '../../services/types/chain';

interface ChainStore {
  chains?: ChainDetails[];

  // eslint-disable-next-line no-unused-vars
  fetchChains: () => Promise<ChainDetails[]>;
}

export const useStore = create<ChainStore>((set, get) => ({
  chains: undefined,
  fetchChains: async (): Promise<ChainDetails[]> => {
    const { chains } = get();
    if (!chains) {
      const fetchedChains = await ChainService.getBlockchains();

      set(() => ({ chains: fetchedChains }));
      return fetchedChains;
    }
    return chains;
  },
}));
