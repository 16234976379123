// @flow
import { Box } from '@material-ui/core';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import React from 'react';

interface Props {
  children: React.ReactNode;
  wrapperClass?: string;
}

const useStyles = makeStyles((theme) => createStyles({
  root: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    bottom: '0',
    background: 'white',
    width: '100%',
    height: 84,
    position: 'sticky',
    overflow: 'visible',
    zIndex: 1,
    borderTop: '1px solid',
    borderTopColor: theme.palette.grey['200'],
    padding: '0 16px',
    flexGrow: 0,
    flexShrink: 0,
    [theme.breakpoints.down('sm')]: {
      height: 64,
    },
  },
  content: {
    display: 'flex',
    width: '100%',
    maxWidth: '780px',
    flexWrap: 'nowrap',
    gap: '1em',
  },
}));

export const PageAction = (props: Props) => {
  const classes = useStyles();
  return (
    <Box className={classes.root}>
      <Box className={props.wrapperClass || classes.content}>
        {props.children}
      </Box>
    </Box>
  );
};
