// @flow
import { Chip, ChipProps, createStyles } from '@material-ui/core';
import { makeStyles, Theme, useTheme } from '@material-ui/core/styles';
import React from 'react';

import { Typography } from './Typography';

type LightFrenchFriesProps = ChipProps & Props

interface Props {
  label: string;
  autoAssignColor?: boolean;
  noShrink?: boolean;
  disabled?: boolean;
}

type UseStylesInput = { backgroundColor?: string, textColor?: string }

const useStyles = makeStyles<Theme, UseStylesInput & Partial<LightFrenchFriesProps>>((theme) => createStyles({
  root: {
    flexShrink: ({ noShrink }) => (noShrink ? 0 : 1),
    minWidth: 0,
    height: 'unset',
    padding: '1px 3px',
    borderRadius: 4,
    cursor: 'pointer',
    '& span': {
      padding: 0,
    },
    backgroundColor: ({ backgroundColor, disabled }) => (
      disabled ? theme.palette.grey[100] : (backgroundColor ?? 'unset')
    ),
    color: ({ textColor, disabled }) => (disabled ? theme.palette.grey[500] : textColor ?? 'unset'),
  },
  typography: {
    width: '100%',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
}));

const getChipStyle = (text: string) => {
  let seed = 0;
  for (let i = 0; i < text?.length ?? 0; i += 1) {
    // eslint-disable-next-line no-bitwise
    seed = (seed * 31 + text.charCodeAt(i)) & 0xffffffff;
  }

  seed = (seed * 9301 + 49297) % 233280;

  return {
    backgroundColor: `hsl(${seed % 360}, 70%, 90%)`,
    textColor: `hsl(${seed % 360}, 30%, 30%)`,
  };
};

export const LightFrenchFries = React.memo(({
  label, autoAssignColor, noShrink, variant, className, ...rest
}: LightFrenchFriesProps) => {
  const genColor: UseStylesInput = autoAssignColor ? getChipStyle(label) : {};
  const classes = useStyles({ ...genColor, noShrink, variant, disabled: rest.disabled });
  const theme = useTheme();

  if (!label) return null;

  return (
    <Chip
      className={`${classes.root} ${className ?? ''}`}
      style={{ border: variant === 'outlined' ? undefined : `1px solid ${rest.disabled ? theme.palette.grey[100] : genColor.backgroundColor ?? ''}` }}
      variant="outlined"
      label={<Typography className={classes.typography} variant="h6">{label}</Typography>}
      {...rest}
    />
  );
});

LightFrenchFries.displayName = 'LightFrenchFries';
