import { Box, BoxProps, Grid, Hidden, makeStyles, Typography } from '@material-ui/core';
import { Button, Card } from 'elements';
import { UserEvent, UserEventType } from 'models';
import React, { FC, useEffect, useState } from 'react';

import { AccountHistoryItem } from './AccountHistoryItem';

type AccountHistorySectionProps = BoxProps & {
  className?: string
  events?: UserEvent[]
}

const useStyle = makeStyles((theme) => ({
  root: {
    flexDirection: 'column',
    alignItems: 'center',
    padding: 0,
  },
  noData: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    minHeight: 120,
  },
  sectionTitle: { padding: '20px 16px' },
  tableHeader: {
    padding: '12px 16px',
    color: theme.palette.grey['400'],

    borderStyle: 'solid',
    borderColor: theme.palette.grey['200'],
    borderWidth: '1px 0',
  },
  tableContent: {
    display: 'flex',
    flexDirection: 'column',

    padding: '0 0 12px',
    width: '100%',

    borderTop: `1px solid ${theme.palette.grey['200']}`,

    '& div:last-child': {
      border: 0,
      paddingBottom: 0,
    },

    [theme.breakpoints.up('sm')]: {
      padding: '0 16px 12px',
      borderTop: 0,
    },
  },
  button: {
    marginBottom: 12,
    color: theme.palette.green['500'],
    fontSize: 12,
    fontWeight: 500,
  },
}));

const acceptedEventTypes = [
  UserEventType.Created,
  UserEventType.DefiSwitch,
  UserEventType.Deposit,
  UserEventType.Withdrawal,
  UserEventType.Rebalanced,
  UserEventType.StoppedRebalance,
  UserEventType.Fee,
];

const AccountHistorySection: FC<AccountHistorySectionProps> = ({ className, events }) => {
  const classes = useStyle();
  const [showFull, setShowFull] = useState(false);
  const [filteredEvents, setFilteredEvents] = useState<UserEvent[]>([]);

  useEffect(() => {
    if (events) {
      const filtered = events.filter((event) => acceptedEventTypes.includes(event.eventType));
      setFilteredEvents(filtered);
    }
  }, [events]);

  return (
    <Card className={`${classes.root} ${className}`} isSection isLoading={events === undefined}>
      <Typography variant="h2" className={classes.sectionTitle}>Account History</Typography>
      {(!filteredEvents || !filteredEvents.length) ? (
        <Box className={classes.noData}>
          <Typography variant="body1">No data</Typography>
        </Box>
      ) : (
        <>
          <Hidden xsDown>
            <Grid className={classes.tableHeader} container>
              <Grid item xs={6}>
                <Typography variant="h4" color="inherit">Event</Typography>
              </Grid>
              <Grid item xs={3}>
                <Typography variant="h4" color="inherit">Date</Typography>
              </Grid>
              <Grid item xs={3}>
                <Typography variant="h4" color="inherit" align="right">Amount</Typography>
              </Grid>
            </Grid>
          </Hidden>

          <Box className={classes.tableContent}>
            {filteredEvents.slice(0, showFull ? filteredEvents.length : 6).map((event, index) => (
              <AccountHistoryItem key={index} event={event} />
            ))}
          </Box>

          {filteredEvents.length > 6 && (
            <Button variant="tertiary" className={classes.button} onClick={() => setShowFull((prev) => !prev)}>
              {showFull ? 'HIDE' : 'SHOW'} FULL HISTORY
            </Button>
          )}
        </>
      )}
    </Card>
  );
};

AccountHistorySection.displayName = 'AccountHistorySection';
export { AccountHistorySection };
