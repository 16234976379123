import React from "react";
import { Redirect } from "react-router-dom";
import { InternalNavbar } from "../../components/navbar.js";
import Risk from "../../components/Risk";
import bundleService from "../../services/bundle.service";
import WalletService from "../../services/wallet-service";
import CoinService from "../../services/coin.service";
import SharedStateComponent from "../../components/SharedStateComponent.js";
import Loader from "../../components/Loader.js";
import { Container, Row, Col } from 'react-bootstrap';
import { Footer } from "../../components/Footer.js";
import { PencilGreenIcon, RocketIllustration } from "../../components/Icons.js";
import { FIELD } from "../../common.js";
import Welcome from "./Welcome.js";
import ChooseExchange from "../responsive/ChooseExchange.js";
import ConnectExchange from "./ConnectExchange.js";
import AuthService from "../../services/auth-service.js";
import Logger from "js-logger";
import DepositStatus from "../../components/DepositStatus.js";
import { DashboardRoute } from "routes";

export default class ProfileReview extends SharedStateComponent {
  static routePath = '/final-review';
  static loginRequired = true;

  constructor(props) {
    super(props);

    this.handleTestConnection = this.testWalletConnection.bind(this);
    this.beginInvestment = this.beginInvestment.bind(this);
    this.getWalletsStatus = this.getWalletsStatus.bind(this);

    var prioritizeQuestions = false
    if (props?.location?.state?.prioritizeQuestions !== undefined)
      prioritizeQuestions = props.location.state.prioritizeQuestions

    this.state = {
      bundles: [],
      prioritizeQuestions,
      exchanges: [],
      form: {},
      timeLeft: 0,
      depositStatus: "--",
      balance: "--"
    };
  }

  componentDidMount() {
    let bundles = bundleService.getActiveBundles()
    let wallet = WalletService.getWallet()
    let riskScore = WalletService.getLatestRiskScore(this.state.prioritizeQuestions)
    let exchanges = WalletService.getAllExchanges()
    const allCoins = CoinService.getCoins();

    Promise.all([bundles, wallet, riskScore, exchanges, allCoins]).then(arr => {
      var bundles = arr[0].data
      var wallet = arr[1].data
      var riskScore = arr[2].data != null ? arr[2].data : 50
      var exchanges = arr[3].data
      var coins = arr[4].data

      if (wallet.exchangeId)
        bundles = bundleService.filterBundles(bundles, wallet.exchangeId)
      else {
        bundles = bundleService.filterBundles(bundles, 1).concat(bundles.filter(bundle => bundle.riskScore === 0))
      }

      if (wallet.walletId) {
        const form = {
          apiKey: FIELD(wallet.apiKey),
          apiSecret: FIELD(wallet.apiSecret === undefined ? "" : "********************************"),
          exchangeId: FIELD(wallet.exchangeId),
          modify: false
        }

        this.setState({
          wallet: wallet,
          initRiskScore: wallet.riskScore,
          bundles,
          riskScore,
          exchanges,
          coins,
          form
        })
        if (form.exchangeId.value == 1 && form.apiKey.value && form.apiSecret.value !== "") {
          this.testWalletConnection(wallet.walletId);
        } else {
          this.setState({ connectionTested: true })
        }
      } else {
        this.props.history.push({ pathname: Welcome.routePath })
      }
      if (wallet.exchangeId === 4) {
        this.getWalletsStatus()
      }
    })
  }

  testWalletConnection(walletId) {
    WalletService.testWalletConnection(Number.parseInt(walletId)).then(
      resp => {
        this.updateState({
          form: resp.data,
          connectionTested: true,
          loading: false
        })
      },
      error => {
        this.updateState({
          form: { connected: undefined, canTrade: undefined, canWithdraw: undefined },
          connectionTested: true,
          loading: false
        })
      }
    );
  }

  beginInvestment(e) {
    e.preventDefault();
    WalletService.beginInvestment(this.state.wallet.walletId).then(resp => {
      AuthService.updateUserStep().then(resp => {
        this.props.history.push(DashboardRoute)
      })
    })
  }

  componentWillUnmount() {
    clearInterval(this.interval);
  }

  getWalletsStatus() {
    this.interval = setInterval(() => {
      if (this.state.timeLeft == 0) {
        this.setState({ timeLeft: -1 })
        WalletService.getWalletStatus()
          .then(txStatuses => {
            var txStatus = txStatuses.find(txStatus => txStatus.wallet.chainId === "56")
            if (this.state.riskScore.value === 0) {
              txStatus = txStatuses.find(txStatus => txStatus.wallet.chainId === "terra-mainnet")
            }
            this.setState({
              txStatus,
              txStatuses,
              balance: txStatus.balance,
              usdtBalance: txStatus.usdtBalance,
              balanceInt: txStatus.balanceInt,
              depositStatus: txStatus.depositStatus,
              timeLeft: 30
            })
          }, error => {
            this.setState({
              txStatus: [{}],
              txStatuses: [],
              depositStatus: "One moment please...",
              timeLeft: 30,
              balance: "--",
              balanceInt: 0,
              usdtBalance: 0
            })
          })
      } else if (this.state.timeLeft > 0) {
        this.setState({ timeLeft: this.state.timeLeft - 1 })
      }
    }, 1000);
  }

  renderWithSharedState() {
    if (this.state.redirect) {
      return <Redirect to={{
        pathname: this.state.redirect,
        redirectState: this.state.redirectState
      }} />
    }

    const canRender = this.state.exchanges?.length > 0 && this.state.bundles?.length > 0 && this.state.riskScore != null && this.state.connectionTested

    Logger.log(this.state);

    if (!canRender) {
      return (<><Loader loading={true} /></>)
    }

    var yourExchange = this.state.exchanges.find(x => x.exchangeId === Number.parseInt(this.state.form.exchangeId?.value))?.name
    yourExchange = yourExchange ? (yourExchange == "Dex" ? "Decentralized" : yourExchange) : "Not selected"

    // let initiateInvestment = !!this.state.form.exchangeId.value;
    let initiateInvestment = false
    if(this.state.form.exchangeId.value == 1){
      initiateInvestment = this.state.form.canRead && this.state.form.canTrade
    }

    return (
      <>
        <Loader loading={false} />

        <InternalNavbar history={this.props.history} />

        <Container className="px-4 pb-3">
          <Row>
            <Col className="text-center mt-4">
              <RocketIllustration />
            </Col>
          </Row>
          <Row className="mt-4">
            <Col className="text-center">
              <h5>Final review</h5>
              <p className="mb-0">
                Please review your settings here before we initiate your investment.
              </p>
            </Col>
          </Row>
          <Row className="mt-4">
            <Col>
              <Container className="content-card p-4">
                <Row className="mt-0">
                  <Col className="pr-0"><p className="p3 mb-0">Your exchange:</p></Col>
                  <Col className="text-right px-0">
                    <p className="p2 mb-0" onClick={() => this.props.history.push({ pathname: ChooseExchange.routePath })}>
                      {yourExchange} <PencilGreenIcon />
                    </p>
                  </Col>
                </Row>
                {yourExchange === "Not selected" && (
                  <Row>
                    <Col>
                      <button className="btn-inverse-red btn-block py-2" onClick={() => this.props.history.push({ pathname: ChooseExchange.routePath })}>Select an exchange</button>
                    </Col>
                  </Row>
                )}
              </Container>
            </Col>
          </Row>
          <Row>
            <Col>
              {this.state.form.exchangeId.value !== 4 ?
                <Container className="content-card p-4">
                  <Row className="mt-0">
                    <Col><p className="p9 mb-0">API key:</p></Col>
                  </Row>
                  <Row className="mt-2">
                    <Col><p className="p5 mb-0 grey">{this.state.form.apiKey?.value ? this.state.form.apiKey?.value : "--"}</p></Col>
                  </Row>
                  <Row className="mt-4">
                    <Col><p className="p9 mb-0">SECRET key:</p></Col>
                  </Row>
                  <Row className="mt-2">
                    <Col><p className="p5 mb-0 grey">{this.state.form.apiSecret?.value ? "**************************************" : "--"}</p></Col>
                  </Row>
                  {initiateInvestment && (
                    <Row className="text-center mt-4">
                      <Col>
                        <p className="p3 mb-0 green" onClick={() => this.props.history.push(ConnectExchange.routePath)}>Edit </p>
                      </Col>
                    </Row>
                  )}
                  {!initiateInvestment && (
                    <Row>
                      <Col>
                        <button className="btn-inverse-red btn-block py-2" onClick={() => this.props.history.push(ConnectExchange.routePath)}>Check your connection</button>
                      </Col>
                    </Row>
                  )}
                </Container>
                :
                <Container className="content-card p-4">
                  <DepositStatus
                    timeLeft={this.state.timeLeft}
                    balance={this.state.balance}
                    depositStatus={this.state.depositStatus}
                    address={this.state.txStatus?.wallet.address}
                    txStatuses={this.state.txStatuses}
                    coins={this.state.coins}
                    addMoreFunds={true}
                    />
                </Container>
              }
            </Col>
          </Row>
          <Row>
            <Col>
              <Container className="content-card">
                <Risk name="riskScore" value={this.state.riskScore} bundles={this.state.bundles} editMode={false} />
              </Container>
            </Col>
          </Row>

          <Row className="mt-4">
            <Col>
              <button id="begin-investment" disabled={!initiateInvestment} className={initiateInvestment ? "btn-first btn-block" : "btn-inverse btn-block"} onClick={this.beginInvestment}>
                Initiate investment
              </button>
            </Col>
          </Row>
        </Container>

        <Footer />
      </>
    );
  }
}
