import { Checkbox as MuiCheckbox, CheckboxProps, withStyles } from '@material-ui/core';
import React from 'react';

export const Checkbox = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: 0,
    color: theme.palette.green['400'],
    '&$checked': {
      color: theme.palette.green['500'],
    },
  },
  checked: {},
}))((props: CheckboxProps) => <MuiCheckbox color="default" {...props} />);
