import { Box, createStyles, makeStyles, useMediaQuery } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import { Loading } from 'components';
import { Button, Card, Typography } from 'elements';
import * as React from 'react';
import { useState } from 'react';
import { PortfolioSettingRoute } from 'routes';
import { useStore as useBundleStore } from 'store/zustand/Bundle';
import { useStore as useCoinStore } from 'store/zustand/Coin';
import { useStore as useFeatureStore } from 'store/zustand/Feature';
import { EarningType, useStore as useInterestEarningStore } from 'store/zustand/InterestEarning';
import { useStore as usePortfolioSettingStore } from 'store/zustand/PortfolioSetting';

import { CautionBanner } from '../CautionBanner';
import { mapToProps } from './CoinEarningMapper';
import CoinEarningRow from './CoinEarningRow';

const useStyles = makeStyles((theme) => createStyles({
  root: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: 12,
    gap: 8,
    borderRadius: 16,
    transition: 'all ease-in-out 0.1s',
    flexGrow: 1,
    [theme.breakpoints.down('sm')]: {
      boxShadow: 'unset',
      borderRadius: 0,
      padding: 0,
      margin: '0 -16px',
      width: 'calc(100% + 32px)',
      maxWidth: '100vw',
      marginTop: 8,
    },
  },
  header: {
    display: 'flex',
    gap: 16,
    alignItems: 'center',
    flexWrap: 'nowrap',
    marginBottom: 4,
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      gap: 4,
      alignItems: 'flex-start',
      padding: '0 16px',
    },
  },
  title: {
    fontSize: 20,
    lineHeight: '32px',
    fontWeight: 500,
    color: theme.palette.grey[700],
    [theme.breakpoints.down('sm')]: {
      color: theme.palette.grey[800],
      fontSize: 14,
      lineHeight: '24px',
    },
  },
  subTitle: {
    fontSize: 14,
    lineHeight: '24px',
    fontWeight: 500,
    color: theme.palette.grey[500],
    [theme.breakpoints.down('sm')]: {
      fontSize: 12,
      lineHeight: '20px',
    },
  },
  loading: {
    height: 200,
    background: 'white',
  },
}));

const LockedModal = React.lazy(() => import('../LockedModal'));

const CoinEarningList = () => {
  const classes = useStyles();
  const [fetchCurrentBundle, currentBundle] = useBundleStore((state) => [state.fetchCurrentBundle, state.currentBundle]);
  const [fetchCoins, masterCoins] = useCoinStore((state) => [state.fetchCoins, state.masterCoins]);
  const [protocols, currentEarningType, fetchCurrentProtocolIds] = useInterestEarningStore((state) => [state.protocols, state.currentEarningType, state.fetchCurrentProtocolIds]);
  const [features, fetchFeatures] = useFeatureStore((state) => [state.features, state.fetchFeatures]);

  const [setSelectedEarningType] = usePortfolioSettingStore((state) => [state.setSelectedEarningType]);
  const [showLockedModal, setShowLockedModal] = React.useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const callbackFnc = () => {
    if (currentEarningType === undefined) {
      setSelectedEarningType(EarningType.Basic);
    } else {
      setSelectedEarningType(currentEarningType);
    }
  };
  const header = 'Unlock advanced interest method selection!';
  const content = <>Deposit <em>{(3000).toLocaleString()} USD equivalent or more</em> to unlock the ability to choose your interest methods!</>;
  const secondaryButtonTxt = 'Back to basic interest methods';
  const secondaryCallback = () => { setSelectedEarningType(EarningType.Basic); };

  const lockedModalProps = { callbackFnc, header, content, secondaryButtonTxt, secondaryCallback };

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  React.useEffect(() => {
    (async () => {
      setIsLoading(true);
      await Promise.allSettled([
        fetchCurrentBundle(false),
        fetchCoins(),
        fetchFeatures(),
        fetchCurrentProtocolIds(false),
      ]);
      setIsLoading(false);
    })();
  }, []);

  const noBundleWallet = (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      gridRowGap={8}
      padding="16px"
      flexGrow={1}
    >
      <Typography variant={isMobile ? 'h6' : 'h3'} paletteColor={700}>Please select your target portfolio</Typography>
      <Button size={isMobile ? 'sm' : 'md'} href={PortfolioSettingRoute}>
        Portfolio setting
      </Button>
    </Box>
  );

  React.useEffect(() => {
    if (features && !features.customInterestMethod) {
      setShowLockedModal(true);
    }
  }, [features]);

  const rowProps = React.useMemo(
    () => masterCoins && currentBundle && protocols && mapToProps(masterCoins, currentBundle, protocols),
    [currentBundle, masterCoins, protocols],
  );

  return (
    <>
      <CautionBanner />
      <Card className={classes.root}>
        <Box className={classes.header}>
          <Typography className={classes.title}>Manage interest methods for your cryptos</Typography>
          <Typography className={classes.subTitle}>Select where to earn interest</Typography>
        </Box>
        {isLoading ? (<Loading className={classes.loading} />)
          : rowProps ? rowProps.map((props, index) => <CoinEarningRow key={index} {...props} />)
            : noBundleWallet}
      </Card>
      {showLockedModal && (
        <React.Suspense fallback={<></>}>
          <LockedModal showModal={showLockedModal} setShowModal={setShowLockedModal} {...lockedModalProps} />
        </React.Suspense>
      )}
    </>
  );
};

export default CoinEarningList;
