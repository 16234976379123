import { Box, Hidden, IconButton, makeStyles } from '@material-ui/core';
import Notifications from 'components/Notifications/Notifications';
import { Typography } from 'elements';
import React, { useEffect, useMemo } from 'react';
import { useStore as useFeatureStore } from 'store/zustand/Feature';

import { DashboardMenuItems } from './DashboardMenuItem';
import { WalletSwitcher } from './MultiWallets';

type DashboardHeaderProps = {
  route: string
  toggleSidebar: () => void
  title?: string;
}

const useStyles = makeStyles((theme) => ({
  root: {
    zIndex: 10,
    position: 'sticky',
    top: 0,
    display: 'flex',
    flexShrink: 0,
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',

    padding: '12px 16px',
    width: '100%',
    height: 60,
    gap: 8,
    background: theme.palette.common.white,
    boxShadow: '4px 0px 32px rgba(0, 0, 0, 0.04)',

    [theme.breakpoints.up('sm')]: {
      padding: '12px 24px',
    },
    [theme.breakpoints.down('xs')]: {
      gap: 4,
    },
    [theme.breakpoints.up('lg')]: {
      padding: '12px 28px',
      height: 56,
    },
  },
  walletSwitcher: {
    marginLeft: 'auto',
    display: 'flex',
    alignItems: 'center',
    columnGap: 8,
  },
}));

const DashboardHeader = ({ route, toggleSidebar, title }: DashboardHeaderProps) => {
  const classes = useStyles();

  const [features, fetchFeatures] = useFeatureStore((state) => [state.features, state.fetchFeatures]);
  useEffect(() => {
    (async () => { await fetchFeatures(); })();
  }, []);

  const titleDisplay = useMemo(
    () => {
      if (title) return title;
      const menuItem = DashboardMenuItems.find((item) => (item.route ? route.startsWith(item.route)
        : item.sub ? item.sub.find((sub) => route.startsWith(sub.route)) : ''));
      return menuItem?.route
        ? menuItem.label : menuItem?.sub
          ? menuItem.sub.find((sub) => route.startsWith(sub.route))?.label : '';
    },
    [route],
  );

  return (
    <Box className={classes.root}>
      <Hidden lgUp>
        <IconButton size="small" onClick={toggleSidebar}>
          <img alt="Menu" src="/icons/dashboard/ic_menu.svg" width={32} height={32} />
        </IconButton>
      </Hidden>
      <Hidden mdDown>
        <Typography variant="h5">{titleDisplay}</Typography>
      </Hidden>
      {features?.switchWallet ? (
        <Box className={classes.walletSwitcher}>
          <Hidden xsDown>
            <Typography palette="grey" paletteColor={600} variant="h6">Currently viewing:</Typography>
          </Hidden>
          <WalletSwitcher />
        </Box>
      ) : null}
      <Notifications />
    </Box>
  );
};

DashboardHeader.displayName = 'DashboardHeader';
export { DashboardHeader };
