import InterestServiceV2 from 'services/interestV2-service';
import { InterestByCoin } from 'services/types/interest';
import { create } from 'zustand';

interface InterestStore {
  numActiveLendingTokens?: number,
  fetchNumActiveLendingTokens: (forceReload: boolean) => Promise<void>
  interestsByCoin?: InterestByCoin[];
  fetchInterestsByCoin: (forceReload: boolean) => Promise<void>;
}

export const useStore = create<InterestStore>((set, get) => ({
  interestsByCoin: undefined,
  numActiveLendingTokens: undefined,
  fetchInterestsByCoin: async (forceReload: boolean) => {
    if (get().interestsByCoin === undefined || forceReload) {
      const interestsByCoin = await InterestServiceV2.getInterestEarned();
      set({ interestsByCoin });
    }
  },
  fetchNumActiveLendingTokens: async (forceReload: boolean) => {
    if (get().numActiveLendingTokens === undefined || forceReload) {
      const numActiveLendingTokens = await InterestServiceV2.getNumberOfInterestCoins();
      set({ numActiveLendingTokens });
    }
  },
}));
