import { LightModal } from 'elements';
import React from 'react';
import { useStore as useWorkItemStore } from 'store/zustand/WorkItem';

import { ApprovalContent } from './ApprovalContent';
import { getWorkItemNotificationTitle } from './utils';
import { WorkItemNotAvailable } from './WorkItemNotAvailable';

interface TransactionApprovalModalProps {
  id?: number;
  // eslint-disable-next-line no-unused-vars
  setOpen: (value: boolean) => void;
  open: boolean;
  isWorkItemAvailable: boolean;
  openNotificationBox: () => void;
  onClose?: () => void;
}

const TransactionApprovalModal = (props: TransactionApprovalModalProps) => {
  const [workItems] = useWorkItemStore((state) => [state.workItems]);
  const item = React.useMemo(() => (workItems?.find((i) => i.workItemId === props.id)), [props.id, workItems]);
  const title = React.useMemo(() => (item ? getWorkItemNotificationTitle(item.name) : ''), [item]);

  return (
    <LightModal
      open={props.open}
      setOpen={props.setOpen}
      modalTitle={!props.isWorkItemAvailable ? 'This request is no longer available' : title ?? ''}
      showCloseButton
      maxWidth={376}
      fullScreenOnMobile={props.isWorkItemAvailable}
      noPadding
      onClose={props.onClose}
    >
      { item && props.isWorkItemAvailable && (<ApprovalContent item={item} onClose={props.onClose} />) }
      { !props.isWorkItemAvailable && (
        <WorkItemNotAvailable
          openNotificationBox={props.openNotificationBox}
        />
      )}
    </LightModal>
  );
};

export default TransactionApprovalModal;
