import { Box, createStyles, makeStyles, Theme } from '@material-ui/core';
import { Typography } from 'elements';
import * as React from 'react';

import { CaseStudiesCard, CaseStudiesCardProps } from './CaseStudiesCard';

const useStyles = makeStyles((theme: Theme) => createStyles({
  root: {
    display: 'flex',
    flexDirection: 'column',
    gap: 60,
    width: '100%',
    '& em': {
      color: theme.palette.purple[600],
      fontStyle: 'normal',
    },
    [theme.breakpoints.down('sm')]: {
      gap: 24,
    },
  },
  title: {
    display: 'flex',
    flexDirection: 'column',
    gap: 16,
    '& h2': {
      fontSize: 44,
      lineHeight: '60px',
      fontWeight: 700,
      [theme.breakpoints.down('sm')]: {
        fontSize: 24,
        lineHeight: '44px',
      },
    },
    '& h3': {
      fontSize: 20,
      lineHeight: '32px',
      fontWeight: 500,
    },
  },
  container: {
    display: 'grid',
    gridTemplateColumns: 'repeat(3, 1fr)',
    width: '100%',
    gap: 24,
    [theme.breakpoints.down('sm')]: {
      gap: 16,
      overflowX: 'scroll',
      maxWidth: '100vw',
      width: '100vw',
      margin: '0 calc(calc(100% - 100vw) / 2)',
      padding: '0 calc(calc(100vw - 100%) / 2)',
      '&::-webkit-scrollbar': {
        display: 'none',
        width: '0px',
        background: 'transparent',
      },
    },
  },
}));

const cases: CaseStudiesCardProps[] = [
  {
    name: 'FahMai Holdings Group, Inc.',
    partnerType: 'Affiliate',
    logoImg: '/images/marketing/partner_fmh',
    screenshot: '/images/marketing/partner_fhm_screen',
    desc: 'FahMai Holdings is an alternative investment firm with clients looking to invest in assets such as whiskey barrels, which appreciate over time. They leverage Coinbag\'s affiliate program to add crypto investments to their client offerings.',
    color: '#EE4B94',
  },
  {
    name: 'Pi Securities',
    partnerType: 'White-label',
    logoImg: '/images/marketing/partner_pi',
    screenshot: '/images/marketing/partner_pi_screen',
    desc: 'Pi Securities offers clients a low-cost way to easily buy stocks, bonds, and ETFs in Southeast Asia. They leverage Coinbag\'s SDK integration to offer crypto investments and interest to their clients, all within the same app.',
    color: '#21CE99',
  },
  {
    name: '100Man',
    partnerType: 'Direct integration',
    logoImg: '/images/marketing/partner_100man',
    screenshot: '/images/marketing/partner_100man_screen',
    desc: '100Man is a neo-bank and payments platform in Vietnam allowing customers to easily save, buy and spend their local currency digitally. As part of their offering, they leveraged Coinbag\'s robust APIs to offer localized crypto investments and interest directly within their application.',
    color: '#FF7A43',
  },
];

const CaseStudies = () => {
  const classes = useStyles();
  return (
    <section itemScope className={classes.root}>
      <Box className={classes.title}>
        <Typography variant="h2" align="center">Case <em>Studies</em></Typography>
        <Typography variant="h3" align="center">Our Partners</Typography>
      </Box>
      <Box className={classes.container}>
        {cases.map((c, index) => (<CaseStudiesCard key={index} {...c} />))}
      </Box>
    </section>
  );
};

export default CaseStudies;
