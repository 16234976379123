import { Box, createStyles } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Button, LightModal, Typography } from 'elements';
import React from 'react';
import { useStore as useUserStore } from 'store/zustand/User';

interface Props {
  showModal: boolean
  // eslint-disable-next-line no-unused-vars
  setShowModal: (value: boolean) => void;
  userId: number;
}

const useStyles = makeStyles(() => createStyles({
  root: {
    display: 'flex',
    flexDirection: 'column',
    position: 'relative',
    gap: 16,
    width: '100%',
    height: '100%',
  },
  buttonWrapper: {
    display: 'flex',
    width: '100%',
    justifyContent: 'space-between',
  },
  button: {
    minWidth: 84,
  },
  memberDetail: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    gap: 8,
    '& img': {
      width: 40,
      height: 40,
      borderRadius: 20,
    },
  },
}));

export const RemoveMemberModal = (
  { showModal, setShowModal, userId }: Props,
) => {
  const classes = useStyles();

  const [removeUser, allowedUsers, fetchAllowedUsers] = useUserStore((state) => [
    state.removeUser, state.allowedUsers, state.fetchAllowedUsers]);

  const onRemove = async () => {
    setShowModal(false);
    await removeUser(userId);
    fetchAllowedUsers(true);
  };

  const member = React.useMemo(() => allowedUsers?.find((u) => u.userId === userId), [allowedUsers, userId]);

  return (
    <LightModal
      open={showModal}
      setOpen={setShowModal}
      maxWidth={372}
      showCloseButton
      modalTitle="Remove team member"
    >
      <Box className={classes.root}>
        <Typography variant="h4" paletteColor={700} align="center">
          Are you sure you want to remove the member?
        </Typography>
        {member && (
          <Box className={classes.memberDetail}>
            <img alt="user" src={`/api/images/ape?user=${member.userId}`} />
            <Box display="flex" flexDirection="column">
              {(member.firstName || member.lastName) && (
                <Typography variant="h5" paletteColor={700}>{member.firstName} {member.lastName}</Typography>
              )}
              <Typography palette="green" variant="h6" paletteColor={600}>{member.email}</Typography>
            </Box>
          </Box>
        )}
        <Box className={classes.buttonWrapper}>
          <Button
            variant="secondary"
            size="md"
            onClick={() => setShowModal(false)}
            className={classes.button}
          >
            Cancel
          </Button>
          <Button
            size="md"
            onClick={onRemove}
            className={classes.button}
            color="red"
          >
            Remove
          </Button>
        </Box>
      </Box>
    </LightModal>
  );
};
