import React, {Component} from "react";
import BundleService from "../../services/bundle.service";
import {PieChart} from "../../components/linechart.js";

export default class ViewBundle extends Component {
  static routePath = '/view-bundle';
  static loginRequired = true;

  constructor(props) {
    super(props);

    const query = new URLSearchParams(this.props.location.search);
    const bundleId = query.get('bundleId');
    const bundleStateId = query.get('bundleStateId');

    this.state = {
      bundleId: bundleId,
      bundleStateId: bundleStateId,
      loading: false,
      bundle: {
        coins: [],
        isLiquidation: false,
      },
      message: ""
    };
  }

  componentDidMount() {
    this.setState({isLoading: true});

    BundleService.getBundle(this.state.bundleId, this.state.bundleStateId).then(resp => {
        const colors = ['#00876c', '#b6cf8b', '#f5ba75', '#d43d51', '#2f4b7c', '#665191', '#a05195', '#d45087', '#f95d6a', '#ff7c43', '#b2b2b2']
        resp.data.coins.sort((a, b) => a.weight > b.weight ? -1 : 1)
        resp.data.coins.map((c, i) => {
          c.other = i >= 9
          if (i >= 9)
            c.color = colors[colors.length - 1]
          else
            c.color = colors[i]
        })

        this.setState({
          bundle: resp.data,
          isLoading: false
        });
      }
    );
  }


  render() {
    if (this.state.isLoading) {
      return <div style={{ margin: '40px auto', width: '100px' }}>Loading ...</div>;
    }
    return (
      <div>
        <section id="exchange">
          <div className="container">
            <div className="row">
              <div className="col-md-7 col-sm-4">
                <div className="content-card">
                  <h5 className="card-title">{this.state.bundle.name}</h5>
                  <div className="overview-title">
                    <div className="row">
                      <div className="col-sm">
                        Description
                      </div>
                      <div className="col-sm">
                        {this.state.bundle.description}
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-sm">
                        Risk score
                      </div>
                      <div className="col-sm">
                        {this.state.bundle.riskScore}
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-sm">
                        Is liquidation
                      </div>
                      <div className="col-sm">
                        {JSON.stringify(this.state.bundle.isLiquidation)}
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-sm">
                        Chain ids
                      </div>
                      <div className="col-sm">
                        <ul>
                        {this.state.bundle.coins.map(coin => (
                          <li>{`${coin.name} (${coin.chainId})`}</li>
                        ))}
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div className="card-img-bottom">
                    <PieChart coins={this.state.bundle.coins} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    )
  }
}
