// @flow
import { Box, createStyles } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Typography } from 'elements';
import { Scrollable } from 'elements/Scrollable';
import pluralize from 'pluralize';
import React from 'react';
import { WorkItemName, WorkItemStatus } from 'services/types/workItem';
import { PayrollBatchParameter, PayrollParameter } from 'services/types/workItemParameter';
import { useStore as usePayrollStore } from 'store/zustand/Payroll';
import { useStore as useWalletStore } from 'store/zustand/Wallet';
import { useStore as useWorkItemStore } from 'store/zustand/WorkItem';

import { PayrollHeader } from './PayrollHeader';
import { PayrollRow } from './PayrollRow';

const useStyles = makeStyles((theme) => createStyles({
  root: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    boxShadow: '0px 4px 32px rgba(58, 60, 77, 0.04)',
    [theme.breakpoints.up('md')]: {
      borderRadius: 16,
      position: 'relative',
      overflow: 'hidden',
    },
  },
  header: {
    padding: '18px 16px',
    display: 'flex',
    columnGap: 8,
    alignItems: 'flex-end',
    flexWrap: 'wrap',
    [theme.breakpoints.down('sm')]: {
      padding: '14px 12px',
      background: '#FBFCFF',
      borderTop: '1px solid',
      borderTopColor: theme.palette.grey[200],
    },
  },
  grid: {
    display: 'grid',
    width: '100%',
    gridTemplateColumns: '3fr 1.2fr 80px 1fr 28px 0.7fr',
    [theme.breakpoints.down('sm')]: {
      gridTemplateColumns: '40px auto auto 56px',
      gridTemplateAreas: `
      "a a a a"
      "c c d d"
      "e e f f"
      `,
    },
  },
  cell: {
    display: 'flex',
    gap: 8,
    alignItems: 'center',
    borderTop: '1px solid',
    borderTopColor: theme.palette.grey[200],
    padding: '14px 0 14px 8px',
    height: '100%',
    minWidth: 0,
    overflow: 'hidden',
    [theme.breakpoints.down('sm')]: {
      padding: '6px 0 6px 8px',
    },
    '&#name': {
      [theme.breakpoints.up('md')]: {
        paddingLeft: 16,
        paddingRight: 16,
      },
      [theme.breakpoints.down('sm')]: {
        gridArea: 'a',
        paddingLeft: 16,
        paddingTop: 8,
      },
    },
    '&#chain': {
      [theme.breakpoints.down('sm')]: {
        gridArea: 'c',
        borderTop: 'none',
        paddingLeft: 16,
      },
      '& h4': {
        overflow: 'hidden',
        textOverflow: 'ellipsis',
      },
    },
    '&#address': {
      [theme.breakpoints.down('sm')]: {
        justifyContent: 'flex-end',
        gridArea: 'd',
        paddingRight: 16,
        borderTop: 'none',
      },
    },
    '&#coin': {
      [theme.breakpoints.down('sm')]: {
        gridArea: 'e',
        borderTop: 'none',
        paddingLeft: 16,
        paddingBottom: 16,
      },
    },
    '&#coin_value': {
      justifyContent: 'flex-end',
      [theme.breakpoints.down('sm')]: {
        gridArea: 'f',
        borderTop: 'none',
        paddingRight: 16,
        paddingBottom: 12,
      },
    },
    '&#equal': {
      justifyContent: 'center',
    },
    '&#usdt_value': {
      justifyContent: 'flex-end',
      [theme.breakpoints.up('md')]: {
        paddingRight: 16,
      },
    },
  },
  columnHeader: {
    display: 'flex',
    alignItems: 'center',
    alignSelf: 'center',
    background: '#FBFCFF',
    padding: '6px 0 6px 8px',
    borderTop: '1px solid',
    borderTopColor: theme.palette.grey[200],
    height: '100%',
    width: '100%',
    '&#checkbox': {
      [theme.breakpoints.up('md')]: {
        padding: '8px 0 8px 16px',
      },
      [theme.breakpoints.down('sm')]: {
        gridColumn: 1,
        gridRow: 1,
        padding: '14px 0 14px 12px',
      },
    },
    '&#amount': {
      justifyContent: 'flex-end',
      width: '100%',
    },
    '&#usdt': {
      paddingRight: 16,
      justifyContent: 'flex-end',
      width: '100%',
    },
    '&#addAddress': {
      padding: '12px 16px 12px 0',
      gridColumn: '3 / span 2',
    },
  },
  scrollable: {
    flexDirection: 'column',
    display: 'flex',
    width: '100%',
    position: 'relative',
    [theme.breakpoints.up('md')]: {
      maxHeight: 124,
    },
  },
  scrollContent: {
    width: '100%',
  },
}));

export const InProgressSection = () => {
  const classes = useStyles();
  const [workItems, fetchWorkItems] = useWorkItemStore((state) => [state.workItems, state.fetchWorkItems]);
  const [payrolls] = usePayrollStore((state) => [state.payrolls]);
  const [
    activeWalletBalance,
    maxPerf,
  ] = useWalletStore(
    (state) => ([
      state.activeWalletBalance,
      state.maxPerf,
    ]),
  );
  React.useEffect(() => {
    fetchWorkItems(true);
  }, []);

  const [items, type] = React.useMemo(() => {
    const payroll = workItems?.filter((i) => i.name === WorkItemName.Payroll
      && [WorkItemStatus.InProgress, WorkItemStatus.Pending].includes(i.status))
      .map((i) => i.parameters as PayrollParameter) ?? [];
    const pendingPayroll = workItems?.filter((i) => i.name === WorkItemName.PayrollBatch && i.status === WorkItemStatus.WaitingForApproval)
      .map((i) => i.parameters as PayrollBatchParameter)
      .map((i) => i.payrolls.map((p) => ({ amount: p.amount, coinId: p.coinId, from: p.from, memo: p.memo, payrollId: p.payrollId, to: p.to } as PayrollParameter)))
      .reduce((arr, p) => arr.concat(...p), []) ?? [];
    return [[...payroll, ...pendingPayroll], payroll.length > 0 ? 'inProgress' : 'waitingForApproval'];
  }, [workItems]);

  const usdtAmountMap = React.useMemo(() => {
    const amount: Record<number, number> = {};
    if (items.length > 0) {
      if (activeWalletBalance) {
        for (let i = 0; i < items.length; i += 1) {
          const coinBalance = activeWalletBalance.find((b) => b.coinId === items[i].coinId);
          if (coinBalance) {
            amount[items[i].payrollId] = (coinBalance?.usdtValue / coinBalance?.balance) * items[i].amount;
          }
        }
      } else if (maxPerf) {
        for (let i = 0; i < items.length; i += 1) {
          const coinBalance = maxPerf.point.coins.find((b) => b.coinId === items[i].coinId);
          if (coinBalance) {
            amount[items[i].payrollId] = (coinBalance?.price / coinBalance?.quantity) * items[i].amount;
          }
        }
      }
    }
    return amount;
  }, [items, activeWalletBalance, maxPerf]);

  if (items.length <= 0 || (payrolls ?? []).length <= 0) return null;

  return (
    <Box className={classes.root}>
      <Box className={classes.header}>
        <Typography palette="yellow" paletteColor={600} variant="h5">
          {items.length} {pluralize('transaction', items.length)} {type === 'inProgress' ? 'in progress' : 'waiting for approval'}
        </Typography>
        <Typography paletteColor={400} variant="h6" strong={false}>
          Transactions will appear in your reports when completed.
        </Typography>
      </Box>
      <Box className={classes.grid}>
        <PayrollHeader
          cellClass={classes.columnHeader}
        />
      </Box>
      <Scrollable className={classes.scrollable}>
        {items.map((item, index) => (
          <Box className={classes.scrollContent} key={index}>
            <PayrollRow
              address={item.to}
              className={classes.grid}
              cellClassName={classes.cell}
              coinId={item.coinId}
              payrollId={item.payrollId}
              quantity={item.amount}
              tag={payrolls?.find((p) => p.payrollId === item.payrollId)?.tag}
              usdAmount={usdtAmountMap[item.payrollId] ?? 'N/A'}
            />
          </Box>
        ))}
      </Scrollable>
    </Box>
  );
};
