import { Box, CircularProgress, createStyles, makeStyles } from '@material-ui/core';
import { Typography } from 'elements';
import React from 'react';
import SVG from 'react-inlinesvg';
import { WithdrawalTokenParameter } from 'services/types/workItemParameter';
// eslint-disable-next-line import/extensions
import walletService from 'services/wallet-service';
import { useStore as useCoinStore } from 'store/zustand/Coin';

const useStyles = makeStyles(() => createStyles({
  details: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    textAlign: 'center',
    gap: 8,
    marginBottom: 8,
  },
  withdrawalCoinIconWrapper: {
    width: 32,
    height: 32,
    borderRadius: 16,
  },
  withdrawalDetails: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    gap: 8,
  },
  priceWrapper: {
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'nowrap',
    '& .amount': {
      fontSize: 16,
      lineHeight: '24px',
      fontWeight: 500,
    },
    '& .equal': {
      padding: '0 4px',
      fontSize: 16,
      lineHeight: '24px',
      fontWeight: 500,
    },
    '& .usdt': {
      fontSize: 16,
      lineHeight: '24px',
      fontWeight: 500,
    },
  },
}));

const WithdrawalTokenDetail = React.memo((props: WithdrawalTokenParameter) => {
  const classes = useStyles();
  const [coins, fetchCoins] = useCoinStore((state) => [state.coins, state.fetchCoins]);

  const [withdrawalUsdtAmount, setWithdrawalUsdtAmount] = React.useState<number | undefined>(undefined);

  React.useEffect(() => {
    (async () => {
      const USDT_MASTER_COIN_ID = 9;
      const { amount, coinId } = props;
      const usdtAmountString = await walletService.convert(coinId, USDT_MASTER_COIN_ID, amount);
      setWithdrawalUsdtAmount(Number(usdtAmountString));
    })();
  }, []);

  React.useEffect(() => {
    fetchCoins();
  }, []);

  return (
    <Box className={classes.details}>
      <Typography variant="h4" palette="grey" paletteColor={700}>
        The withdrawal was requested from the company account
      </Typography>
      <Box className={classes.withdrawalDetails}>
        <Box className={classes.withdrawalCoinIconWrapper}>
          <SVG src={coins[props.coinId]?.masterCoin?.icon} width={32} height={32} />
        </Box>
        <Box className={classes.priceWrapper}>
          <Typography className="amount" paletteColor={800}>
            {props.amount.toLocaleString(undefined, {
              maximumSignificantDigits: props.amount > 1 ? undefined : 6,
              minimumFractionDigits: props.amount > 1e8 ? 2 : undefined,
            })}
          </Typography>
          <Typography className="equal" paletteColor={500}>≈</Typography>
          {withdrawalUsdtAmount !== undefined
            ? (
              <Typography className="usdt" paletteColor={500}>
                ${withdrawalUsdtAmount.toFixed(2)}
              </Typography>
            )
            : <CircularProgress color="primary" size="1rem" />}
        </Box>
      </Box>
    </Box>
  );
});

WithdrawalTokenDetail.displayName = 'WithdrawalTokenDetail';

export default WithdrawalTokenDetail;
