import { configureStore } from '@reduxjs/toolkit';

import { languageSlice } from './language/languageSlice';
import { portfolioSlice } from './portfolio/portfolioSlice';
import { translationsSlice } from './translations/translationsSlice';
import { userSlice } from './user/userSlice';

export const store = configureStore({
  reducer: {
    language: languageSlice.reducer,
    translations: translationsSlice.reducer,
    user: userSlice.reducer,
    portfolio: portfolioSlice.reducer,
  },
});

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch
