import { Refresh, Replay } from '@material-ui/icons';
import AddBox from '@material-ui/icons/AddBox';
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import Check from '@material-ui/icons/Check';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Clear from '@material-ui/icons/Clear';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import Edit from '@material-ui/icons/Edit';
import FilterList from '@material-ui/icons/FilterList';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import Remove from '@material-ui/icons/Remove';
import SaveAlt from '@material-ui/icons/SaveAlt';
import Search from '@material-ui/icons/Search';
import ViewColumn from '@material-ui/icons/ViewColumn';
import * as React from 'react';

const MuiTableIcons = {
  // eslint-disable-next-line react/display-name
  Add: React.forwardRef((props: any, ref: any) => <AddBox {...props} ref={ref} />),
  // eslint-disable-next-line react/display-name
  Check: React.forwardRef((props: any, ref: any) => <Check {...props} ref={ref} />),
  // eslint-disable-next-line react/display-name
  Clear: React.forwardRef((props: any, ref: any) => <Clear {...props} ref={ref} />),
  // eslint-disable-next-line react/display-name
  Delete: React.forwardRef((props: any, ref: any) => <DeleteOutline {...props} ref={ref} />),
  // eslint-disable-next-line react/display-name
  DetailPanel: React.forwardRef((props: any, ref: any) => <ChevronRight {...props} ref={ref} />),
  // eslint-disable-next-line react/display-name
  Edit: React.forwardRef((props: any, ref: any) => <Edit {...props} ref={ref} />),
  // eslint-disable-next-line react/display-name
  Export: React.forwardRef((props: any, ref: any) => <SaveAlt {...props} ref={ref} />),
  // eslint-disable-next-line react/display-name
  Filter: React.forwardRef((props: any, ref: any) => <FilterList {...props} ref={ref} />),
  // eslint-disable-next-line react/display-name
  FirstPage: React.forwardRef((props: any, ref: any) => <FirstPage {...props} ref={ref} />),
  // eslint-disable-next-line react/display-name
  LastPage: React.forwardRef((props: any, ref: any) => <LastPage {...props} ref={ref} />),
  // eslint-disable-next-line react/display-name
  NextPage: React.forwardRef((props: any, ref: any) => <ChevronRight {...props} ref={ref} />),
  // eslint-disable-next-line react/display-name
  PreviousPage: React.forwardRef((props: any, ref: any) => <ChevronLeft {...props} ref={ref} />),
  // eslint-disable-next-line react/display-name
  ResetSearch: React.forwardRef((props: any, ref: any) => <Clear {...props} ref={ref} />),
  // eslint-disable-next-line react/display-name
  Search: React.forwardRef((props: any, ref: any) => <Search {...props} ref={ref} />),
  // eslint-disable-next-line react/display-name
  SortArrow: React.forwardRef((props: any, ref: any) => <ArrowDownward {...props} ref={ref} />),
  // eslint-disable-next-line react/display-name
  ThirdStateCheck: React.forwardRef((props: any, ref: any) => <Remove {...props} ref={ref} />),
  // eslint-disable-next-line react/display-name
  ViewColumn: React.forwardRef((props: any, ref: any) => <ViewColumn {...props} ref={ref} />),
  // eslint-disable-next-line react/display-name
  Replay: React.forwardRef((props: any, ref: any) => <Replay {...props} ref={ref} />),
  // eslint-disable-next-line react/display-name
  Refresh: React.forwardRef((props: any, ref: any) => <Refresh {...props} ref={ref} />),
};

export default MuiTableIcons;
