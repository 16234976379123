import { Box, CircularProgress, createStyles, makeStyles } from '@material-ui/core';
import { Button, Typography } from 'elements';
import LightModal from 'elements/LightModal';
import { UserType } from 'models';
import * as React from 'react';
import { useStore as useUserStore } from 'store/zustand/User';
import { useStore as useWalletStore } from 'store/zustand/Wallet';

interface Props {
  showModal: boolean;
  // eslint-disable-next-line no-unused-vars
  setShowModal: (value: boolean) => void;
  action: () => void;
}

const useStyles = makeStyles((theme) => createStyles({
  modal: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    backgroundColor: 'white',
    borderRadius: '8px',
    gap: '8px',
    outline: 0,
    width: '100%',
  },
  modalTitle: {
    fontSize: 16,
    lineHeight: '24px',
    textAlign: 'center',
    fontWeight: 500,
    color: theme.palette.grey[800],
  },
  modalDialog: {
    color: theme.palette.grey[700],
    textAlign: 'center',
    fontSize: 12,
    lineHeight: '24px',
    fontWeight: 400,
  },
  logo: {
    width: 48,
    height: 48,
    userDrag: 'none',
    userSelect: 'none',
    marginBottom: '16px',
  },
  circularProgress: {
    margin: '0 0 -2px 4px',
  },
  buttons: {
    display: 'flex',
    justifyContent: 'space-between',
    gap: 8,
    width: '100%',
    marginTop: 12,
    '& button': {
      minWidth: 130,
    },
  },
}));

const RebalanceConfirmModal = ({ showModal, setShowModal, action }: Props) => {
  const classes = useStyles();

  const [user] = useUserStore((state) => [state.user]);
  const { fetchEstimateRebalance, estimateRebalance } = useWalletStore();

  React.useEffect(() => {
    if (showModal) {
      fetchEstimateRebalance();
    }
  }, [showModal]);

  const estimateRebalanceText = estimateRebalance != null ? (
    <>
      <Typography variant="h4" palette="green" paletteColor={600}>Your estimated fees</Typography>
      <Typography variant="h4" palette="grey" paletteColor={600}>
        {estimateRebalance.toFixed(2)} USDT
      </Typography>
    </>
  ) : (
    <>
      <Typography variant="h4" palette="green" paletteColor={600}>
        Calculating your fees
        <CircularProgress className={classes.circularProgress} color="primary" size="1rem" />
      </Typography>
      <Typography variant="h6" palette="grey" paletteColor={400} strong={false}>May take up to 30 seconds</Typography>
    </>
  );

  return (
    <LightModal
      maxWidth={460}
      setOpen={setShowModal}
      open={showModal}
      showCloseButton
    >
      <Box className={classes.modal}>
        <img className={classes.logo} src="/icons/rebalancing/ic_manual.png" alt="manual" />
        <Typography className={classes.modalTitle}>Do you want to rebalance your portfolio?</Typography>
        <Typography variant="h4" className={classes.modalDialog} strong={false}>
          This process might shift your next rebalancing date{user?.userType === UserType.Institutional ? ' after other team members approve it.' : '.'}
        </Typography>
        <Typography variant="h4" className={classes.modalDialog}>
          {estimateRebalanceText}
        </Typography>
        <Box className={classes.buttons}>
          <Button
            variant="secondary"
            onClick={() => setShowModal(false)}
          >
            Cancel
          </Button>
          <Button
            onClick={action}
          >
            Rebalance
          </Button>
        </Box>
      </Box>
    </LightModal>
  );
};

export default RebalanceConfirmModal;
