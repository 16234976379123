import { Box, createStyles, makeStyles } from '@material-ui/core';
import { Typography } from 'elements';
import { UserEventType } from 'models';
import React from 'react';
import SVG from 'react-inlinesvg';
import { CoinWithMasterCoinInfo } from 'services/types/coin';
import { useStore as useCoinStore } from 'store/zustand/Coin';

import { NotificationItemBase } from './NotificationType';

const useStyles = makeStyles((theme) => createStyles({
  wrapper: {
    display: 'flex',
    flexWrap: 'nowrap',
    width: '100%',
    justifyContent: 'space-between',
    padding: '16px 0',
    borderBottom: `1px solid ${theme.palette.grey[200]}`,
    gap: 4,
  },
  leftWrapper: {
    display: 'flex',
    flexWrap: 'nowrap',
    gap: 8,
  },
  icon: {
    width: 40,
    height: 40,
    flexShrink: 0,
    '& svg': {
      width: 40,
      height: 40,
      borderRadius: 20,
    },
    '&#fullsize': {
      width: 24,
      height: 24,
      '& svg': {
        width: 24,
        height: 24,
      },
    },
  },
  textWrapper: {
    display: 'flex',
    flexDirection: 'column',
    gap: 4,
    '& .title': {
      fontSize: 14,
      lineHeight: '20px',
      fontWeight: 500,
      color: theme.palette.grey[700],
    },
    '& .subTitle': {
      fontSize: 12,
      lineHeight: '16px',
      fontWeight: 500,
      color: theme.palette.grey[500],
    },
  },
  timeWrapper: {
    display: 'flex',
    flexDirection: 'column',
    gap: 6,
    alignItems: 'flex-end',
    textAlign: 'end',
    flexShrink: 0,
    marginTop: 2,
    '& .time': {
      fontSize: 12,
      lineHeight: '16px',
      fontWeight: 400,
      color: theme.palette.grey[400],
    },
    '& .type': {
      fontSize: 12,
      lineHeight: '16px',
      fontWeight: 400,
      color: theme.palette.green[500],
      display: 'inline',
      '& span': {
        fontSize: 12,
        lineHeight: '16px',
        fontWeight: 400,
      },
      '& svg': {
        marginRight: 4,
        display: 'inline',
        verticalAlign: 'bottom',
      },
    },
  },
}));

export interface UserEventItemProps extends NotificationItemBase {
  eventType: UserEventType;
  title: string;
  summary?: string;
  coin?: CoinWithMasterCoinInfo;
}

const UserEventItem = React.memo((props: UserEventItemProps) => {
  const classes = useStyles();
  const [masterCoins] = useCoinStore((state) => [state.masterCoins]);

  const alertType = React.useMemo(() => {
    switch (props.eventType) {
      case UserEventType.Created:
        return <><SVG src="/icons/ic_flag.svg" /><Typography component="span" palette="green" paletteColor={600}>System alert</Typography></>;
      case UserEventType.Withdrawal:
        return <><SVG src="/icons/ic_withdraw.svg" /><Typography component="span" palette="green" paletteColor={600}>Sent Withdrawal</Typography></>;
      case UserEventType.Deposit:
        return <><SVG src="/icons/ic_deposit.svg" /><Typography component="span" palette="green" paletteColor={600}>Received Deposit</Typography></>;
      default:
        return undefined;
    }
  }, [props.eventType]);

  const icon = React.useMemo(() => {
    switch (props.eventType) {
      case UserEventType.Deposit:
      case UserEventType.Withdrawal:
        return (masterCoins !== undefined
          && props.coin?.masterCoin?.masterCoinId !== undefined
          && masterCoins[props.coin?.masterCoin?.masterCoinId]?.icon)
          || (props.eventType === UserEventType.Deposit
            ? '/icons/notifications/ic_deposit.svg' : '/icons/notifications/ic_withdraw.svg');
      case UserEventType.Created:
        return '/icons/notifications/ic_star.svg';
      case UserEventType.Rebalanced:
        return '/icons/notifications/ic_rebalance.svg';
      default:
        return undefined;
    }
  }, [props.eventType, props.coin, masterCoins, props.eventType]);

  return (
    <Box className={classes.wrapper}>
      <Box className={classes.leftWrapper}>
        {icon && (
          <Box className={classes.icon} id={props.fullSize ? 'fullsize' : ''}>
            <SVG src={icon} />
          </Box>
        )}
        <Box className={classes.textWrapper}>
          <Typography className="title">{props.title}</Typography>
          {props.summary && <Typography className="subTitle">{props.summary}</Typography>}
        </Box>
      </Box>
      <Box className={classes.timeWrapper}>
        <Typography className="time">
          {
            props.creation.toLocaleDateString('en-GB', {
              day: 'numeric',
              month: 'numeric',
              year: 'numeric',
            }).split('/').join('.')
          }, {
            props.creation.toLocaleTimeString('en-GB', { hour: '2-digit', minute: '2-digit' })
          }
        </Typography>
        {alertType && !props.fullSize && <Typography className="type">{alertType}</Typography>}
      </Box>
    </Box>
  );
});

UserEventItem.displayName = 'UserEventItem';

export default UserEventItem;
