import { CoinWithMasterCoinInfo } from '../services/types/coin';

// eslint-disable-next-line no-shadow
export enum UserEventType {
  Unknown = 'unknown',
  Created = 'account-created',
  DefiSwitch = 'defi-switch',
  Deposit = 'deposit',
  Withdrawal = 'withdrawal',
  Rebalanced = 'rebalancing',
  StoppedRebalance = 'stopped-rebalancing',
  Fee = 'coinbag-fee',
}

export type UserEvent = {
  eventType: UserEventType;
  title: string;
  summary?: string;
  timestamp: Date;
  amount?: number;
  coin?: CoinWithMasterCoinInfo;
  price?: number;
}
