import { Box, Button, Container, Link, makeStyles, Typography } from '@material-ui/core';
import React, { FC, ReactNode } from 'react';
import { HomeRoutes, SignInRoute } from 'routes';

type FullPageLayoutHeaderProps = {
  className?: string;
  menu?: ReactNode;
  buttonRoute?: string;
  buttonText?: string;
};

const useStyles = makeStyles(() => ({
  root: {
    position: 'sticky',
    top: 0,
    zIndex: 2,
    padding: '0.85rem 0',

    backgroundColor: 'white',
    filter: 'drop-shadow(0px 4px 40px rgba(81, 89, 111, 0.1))',
  },
  box: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
  },

  logo: {
    width: 80,
    height: 20,
  },
  link: {
    display: 'flex',
    alignItems: 'center',
  },

  menuBox: {
    display: 'flex',
    justifyContent: 'end',
    alignItems: 'center',
    rowGap: 8,
  },

  button: { padding: '4px 16px' },
  buttonText: {
    color: 'white',
    textTransform: 'none',
  },
}));

export const FullPageLayoutHeader: FC<FullPageLayoutHeaderProps> = ({
  className,
  menu,
  buttonRoute,
  buttonText,
}) => {
  const classes = useStyles();

  return (
    <header className={`${classes.root} ${className}`}>
      <Container>
        <Box className={classes.box}>
          <Link className={classes.link} href={HomeRoutes[0]}>
            <img className={classes.logo} alt="Icon" src="/logos/coinbag_long.svg" fetchpriority="high" />
          </Link>

          {menu || (buttonRoute && buttonText
            && (
              <Link href={buttonRoute}>
                <Button className={classes.button} href={SignInRoute}>
                  <Typography variant="button" className={classes.buttonText}>{buttonText}</Typography>
                </Button>
              </Link>
            )
          )}
        </Box>
      </Container>
    </header>
  );
};
