// TODO: Refactor all routes here

// Public pages
import { isWalletReporting } from './utils/domain';

export const HomeRoutes = ['/', '/home'];
export const SignInRoute = '/login';
export const SignUpRoute = '/register';
export const SignUpIndividualInvestorsRoute = '/register/individual-investors';
export const SignUpInstitutionalInvestorsRoute = '/register/businesses';
export const SignUpPartnerRoute = '/register/partner';
export const ForgetPasswordRoute = '/forgot-password';
export const OurStoryRoute = '/our-story';
export const WhyCoinbagRoute = '/why-coinbag';

export const DisclaimerRoute = '/disclaimer';
export const PrivacyPolicyRoute = '/privacy-policy';
export const TermsOfUseRoute = '/terms-of-use';

export const NotFoundRoute = '/not-found';
export const MaintenanceRoute = '/maintenance';

// Dashboard pages
export const DashboardRoute = '/dashboard';
export const DashboardInterestRoute = '/dashboard/interest';
export const EditRiskRoute = '/edit-level';

export const DepositRoute = '/deposit';
export const WithdrawRoute = '/withdraw';

export const SettingsRiskProfileRoute = '/edit-level';

export const ReportingRoute = '/reporting';
export const TrackMyAddressRoute = isWalletReporting() ? HomeRoutes : ['/track-my-address'];

export const BatchPayrollRoute = '/batch-send';

export const PortfolioSettingRoute = '/portfolio-setting';
export const NotificationsAndRequestsRoute = '/notifications-requests';
export const InterestSettingRoute = '/interest-setting';
export const RebalanceSettingRoute = '/rebalance-setting';
export const WalletSettingRoute = '/wallet-setting';
export const ComingSoonRoute = '/comingsoon';

export const AccountDetailsRoute = '/account-details';
export const TeamAccessRoute = '/team-members';
export const PermissionsRoute = '/permissions';
export const AccessRevokedRoute = '/access-revoked';
export const AccountDeleteRoute = '/account-deleted';

export const HelpConnectBinanceRoute = '/help/connect-binance';
export const ResetPasswordRoute = '/reset-password';
export const InvitationRoute = '/invitation';
export const DecentralizedRoute = '/decentralized';

// UST crisis pages
export const DashboardUstRoute = '/dashboard/ust';

// Dev only pages
export const DashboardElementsRoute = '/dev/dashboard-elements';
export const ShowroomRoute = '/dev/showroom';
export const CreateBundleRoute = '/bundle-create';
export const KycAdminRoute = '/kyc-admin';

// Page titles
export const PageTitles: Record<string, string> = {
  [DashboardInterestRoute]: 'Interest Earned',
  [DashboardUstRoute]: 'Terra 2.0 Support',
  [PortfolioSettingRoute]: 'Choose Cryptos',
  [InterestSettingRoute]: 'Choose your interest options',
  [RebalanceSettingRoute]: 'Manage your rebalancing settings',
  [SignUpIndividualInvestorsRoute]: 'Sign up to Coinbag as an Individual',
  [SignUpInstitutionalInvestorsRoute]: 'Sign up to Coinbag as a Business',
  [SignUpPartnerRoute]: 'Sign up to Coinbag as a Partner',
  [SignInRoute]: 'Sign in to your account',
  [SignUpRoute]: 'Sign up to Coinbag',
  [OurStoryRoute]: 'Our story',
  [WhyCoinbagRoute]: 'Why Coinbag',
  [NotFoundRoute]: 'Page Not Found',
  [MaintenanceRoute]: 'Maintenance',
  [ComingSoonRoute]: 'Coming soon',
  [DisclaimerRoute]: 'Disclaimer',
  [PrivacyPolicyRoute]: 'Privacy Policy',
  [TermsOfUseRoute]: 'Terms of Use',
  [BatchPayrollRoute]: 'Payroll',
};
