import { Box, Hidden, Link, makeStyles } from '@material-ui/core';
import { Typography } from 'elements';
import * as React from 'react';
import SVG from 'react-inlinesvg';
import { SignUpPartnerRoute } from 'routes';

import CaseStudies from './Components/CaseStudies';
import DistributeRisk from './Components/DistributeRisk';
import { MainFeatures, partnerFeatures } from './Components/MainFeatures';
import OurPartner from './Components/OurPartner';
import SignUp from './SignUp';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'center',
    gap: 40,
    margin: '0 auto',
    padding: 16,
    width: '100%',
    maxWidth: 640,
    color: theme.palette.grey[700],
    '& em': {
      color: theme.palette.purple[600],
      fontStyle: 'normal',
    },
    [theme.breakpoints.up('md')]: {
      gap: 120,
      maxWidth: 1080,
      paddingTop: 48,
    },
    '&::before': {
      content: '" "',
      width: '1600px',
      height: '1200px',
      top: '-400px',
      left: '40%',
      position: 'absolute',
      backfaceVisibility: 'hidden',
      perspective: 1000,
      transform: 'translateZ(0)',
      background: 'radial-gradient(50% 50% at 50% 50%, rgba(96, 102, 243, 0.224) 0%, rgba(255, 255, 255, 0) 100%)',
      zIndex: 0,
      filter: 'blur(80px)',
      [theme.breakpoints.down('sm')]: {
        width: '1400px',
        height: '680px',
        top: '-300px',
        left: 'unset',
      },
    },
  },
  title: {
    display: 'flex',
    flexDirection: 'column',
    gap: 12,
    textAlign: 'center',

    [theme.breakpoints.up('md')]: {
      position: 'sticky',
      top: 170,
      gap: 20,
      textAlign: 'left',
    },

    '& h1': {
      fontSize: 24,
      fontWeight: 700,
      lineHeight: '44px',

      [theme.breakpoints.up('md')]: {
        fontSize: 44,
        lineHeight: '70px',
      },
    },
    '& h2': {
      fontSize: 12,
      fontWeight: 400,
      lineHeight: '20px',

      [theme.breakpoints.up('md')]: {
        fontSize: 20,
        fontWeight: 500,
        lineHeight: '32px',
      },
    },
    '& a': {
      color: theme.palette.purple[600],
      fontSize: 14,
      fontWeight: 500,

      [theme.breakpoints.up('md')]: {
        fontSize: 16,
        fontWeight: 600,
        textTransform: 'uppercase',
      },
    },
  },
  learnMore: {
    width: '100%',
    margin: '200px 0',
    [theme.breakpoints.down('sm')]: {
      margin: '80px 0 100px',
      textAlign: 'center',
    },
  },
  feature: {
    marginTop: -80,
    paddingTop: 80,
  },
  partner: {
    margin: '80px 0 120px',
    [theme.breakpoints.down('sm')]: {
      margin: 0,
    },
  },
}));

const LearnMoreAboutCoinbag = React.lazy(() => import('../../../components/Marketing/LearnMoreAboutCoinbag'));
const Supported = React.lazy(() => import('../../../components/Marketing/Supported'));
const PartnerFee = React.lazy(() => import('./Components/PartnerFee'));
const DashboardFeatures = React.lazy(() => import('components/Marketing/DashboardFeatures/DashboardFeatures'));

const Partner = () => {
  const classes = useStyles();
  return (
    <main className={classes.root}>
      <SignUp
        type="partner"
        title={(
          <article className={classes.title}>
            <Typography variant="h1">
              Sign up to Coinbag <br />
              as a <em>Partner</em>
            </Typography>
            <Hidden smDown>
              <Typography variant="h2">
                In only a few minutes you can integrate any of the Coinbag crypto services directly into your platform. Reach out to us today to get started.
              </Typography>
            </Hidden>
            <Link underline="none" href="#feature">
              Learn about partner solutions{' '}
              <SVG src="/icons/icon_arrow_down.svg" height={24} width={24} />
            </Link>
          </article>
        )}
      >
        <CaseStudies />
        <DistributeRisk />
        <Box id="feature" className={classes.feature}>
          <MainFeatures
            title={<><em>Integrate</em> crypto how you want.</>}
            subTitle={<>Integrate crypto products into your platform in minutes with solutions that are </>}
            features={partnerFeatures}
            maxColumn={3}
            color="purple"
          />
        </Box>
        <Box className={classes.partner}>
          <OurPartner
            title={<>Our <em>partners</em></>}
            color="purple"
          />
        </Box>
        <React.Suspense fallback={<></>}>
          <PartnerFee />
          <DashboardFeatures selectType={2} />
          <Supported color="purple" />
          <Box className={classes.learnMore}>
            <LearnMoreAboutCoinbag color="purple" />
          </Box>
        </React.Suspense>
      </SignUp>
    </main>
  );
};

Partner.routePath = SignUpPartnerRoute;
Partner.logoutRequired = true;

export default Partner;
