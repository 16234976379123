import { Box, createStyles, makeStyles, Typography } from '@material-ui/core';
import { WalletSwitcher } from 'components/dashboard/MultiWallets';
import { Button, Card } from 'elements';
import * as React from 'react';
import { useEffect } from 'react';
import { Link } from 'react-router-dom';
import {
  ComingSoonRoute, DashboardRoute,
  DepositRoute,
  InterestSettingRoute,
  PermissionsRoute,
  PortfolioSettingRoute,
  RebalanceSettingRoute, ReportingRoute, WalletSettingRoute,
  WithdrawRoute,
} from 'routes';
import { useStore as useFeatureStore } from 'store/zustand/Feature';
import { useStore as useWalletStore } from 'store/zustand/Wallet';

interface Props {
  route: string;
}

const useStyles = makeStyles((theme) => createStyles({
  root: {
    width: '100%',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: 16,
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    gap: 8,
    alignItems: 'center',
    maxWidth: 412,
  },
  title: {
    color: theme.palette.grey[700],
    fontWeight: 600,
    fontSize: 16,
    lineHeight: '24px',
    textAlign: 'center',
  },
  desc: {
    color: theme.palette.grey[700],
    fontWeight: 500,
    fontSize: 14,
    lineHeight: '24px',
    textAlign: 'center',
    '& em': {
      color: theme.palette.purple[600],
      fontStyle: 'normal',
    },
  },
}));

const ComingSoon = ({ route }: Props) => {
  const classes = useStyles();

  const [features, fetchFeatures] = useFeatureStore((state) => [state.features, state.fetchFeatures]);
  const [activeWalletInfo] = useWalletStore((state) => [state.activeWalletInfo]);

  useEffect(() => {
    fetchFeatures(false);
  }, []);

  const pageNameMap: Record<string, string> = {
    [DashboardRoute]: 'Dashboard',
    [DepositRoute]: 'Deposit',
    [WithdrawRoute]: 'Withdrawal',
    [PortfolioSettingRoute]: 'Manage cryptos',
    [InterestSettingRoute]: 'Interest earning',
    [RebalanceSettingRoute]: 'Rebalancing',
    [ReportingRoute]: 'Reporting',
    [PermissionsRoute]: 'Permissions',
  };

  if (features == null) return null;

  if (features.isActiveInstitution) {
    return (
      <Box className={classes.root}>
        <Card className={classes.content}>
          <Typography className={classes.title}>
            {pageNameMap[route] ?? 'This feature'} is unavailable for all wallets
          </Typography>
          <Typography className={classes.desc}>
            <em>All wallets</em> cannot interact with this page, yet. Please choose another wallet to view and interact with this page!
          </Typography>
          <WalletSwitcher fullWidth />
        </Card>
      </Box>
    );
  }

  if (features.basicWallet) {
    return (
      <Box className={classes.root}>
        <Card className={classes.content}>
          <Typography className={classes.title}>
            {pageNameMap[route] ?? 'This feature'} is unavailable for basic wallets
          </Typography>
          <Typography className={classes.desc}>
            {features.switchWallet
              ? (<>You can either switch your wallet type in <b>Wallet settings</b> or change views to a different wallet.</>)
              : (<>You can switch your wallet type in <b>Wallet settings</b>.</>)}
          </Typography>
          {features.switchWallet && (<WalletSwitcher fullWidth />)}
        </Card>
      </Box>
    );
  }

  if (!features.hasWallet) {
    return (
      <Box className={classes.root}>
        <Card className={classes.content}>
          <Typography className={classes.title}>
            {pageNameMap[route] ?? 'This feature'} is unavailable
          </Typography>
          <Typography className={classes.desc}>
            No wallet is available. Please create a wallet to view and interact with this page.
          </Typography>
          <Link to={WalletSettingRoute}>
            <Button size="sm" color="purple">Create wallet</Button>
          </Link>
        </Card>
      </Box>
    );
  }

  if (features.readOnlyWallet) {
    return (
      <Box className={classes.root}>
        <Card className={classes.content}>
          <Typography className={classes.title}>
            {pageNameMap[route] ?? 'This feature'} is unavailable for read-only wallets
          </Typography>
          <Typography className={classes.desc}>
            <em>{activeWalletInfo?.isNonCustodian ? 'Self-custody' : 'Read-only wallets'}</em> cannot interact with this page, yet. Please choose another wallet to view and interact with this page!
          </Typography>
          <WalletSwitcher fullWidth />
        </Card>
      </Box>
    );
  }

  return null;
};

ComingSoon.routePath = ComingSoonRoute;
ComingSoon.loginRequired = true;

export default ComingSoon;
