import { Box, makeStyles, Theme, Typography } from '@material-ui/core';
import React, { FC } from 'react';
import SVG from 'react-inlinesvg';
import { palette } from 'theme';

type AccountProtectionBadgeProps = {
  color?: keyof Omit<typeof palette, 'primary' | 'error'>;
}

const useStyles = makeStyles<Theme, AccountProtectionBadgeProps>((theme) => ({
  root: {
    display: 'flex',
    alignItems: 'center',

    columnGap: 8,
    padding: '12px 6px',

    backgroundColor: ({ color }) => theme.palette[color ?? 'green'][100],
    borderRadius: 10,
  },

  icon: {
    flexShrink: 0,
    color: ({ color }) => theme.palette[color ?? 'green'][600],
    width: 24,
    height: 24,
  },
  text: {
    fontSize: '12px',
    fontWeight: 500,
    lineHeight: '20px',
    color: theme.palette.grey[800],
  },
}));

const AccountProtectionBadge: FC<AccountProtectionBadgeProps> = ({ color = 'green' }) => {
  const classes = useStyles({ color });
  return (
    <Box className={classes.root}>
      <SVG className={classes.icon} src="/icons/ic_shield_check.svg" width={24} height={24} />
      <Typography className={classes.text}>
        Your account is protected with Military Grade Data Encryption
      </Typography>
    </Box>
  );
};

AccountProtectionBadge.displayName = 'AccountProtectionBadge';
export default AccountProtectionBadge;
