import { Box, makeStyles } from '@material-ui/core';
import { Typography } from 'elements';
import * as React from 'react';
import { MaintenanceRoute } from 'routes';

const useStyles = makeStyles((theme) => ({
  root: {
    position: 'relative',
    overflowX: 'clip',
    userSelect: 'none',

    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',

    minHeight: 'calc(100vh - 80px)',
    background: 'white',
  },

  gradient: {
    position: 'absolute',
    width: '120vw',
    height: '80vh',

    opacity: 0.3,
    filter: 'blur(76.2437px)',

    [theme.breakpoints.up('md')]: {
      height: '120vh',
    },
  },
  gradientTop: {
    top: 0,
    left: 0,

    background: 'radial-gradient(48.42% 48.42% at 50% 50%, #FFF0A0 0%, rgba(255, 251, 236, 0) 100%)',
    transform: 'translateX(-50%) translateY(-50%)',

    [theme.breakpoints.up('md')]: {
      top: '50%',
      transform: 'translateX(-50%) translateY(-50%)',
    },
  },
  gradientBottom: {
    bottom: 0,
    right: 0,

    background: 'radial-gradient(48.42% 48.42% at 50% 50%, rgba(46, 255, 218, 0.63) 0%, rgba(235, 255, 252, 0) 100%)',
    transform: 'translateX(50%) translateY(50%)',

    [theme.breakpoints.up('md')]: {
      top: '50%',
      transform: 'translateX(50%) translateY(-50%)',
    },
  },

  coin: {
    position: 'absolute',

    width: 32,
    height: 32,
    objectFit: 'contain',
    opacity: 0.6,

    borderRadius: 9999,
    filter: 'drop-shadow(0px 4px 16px rgba(110, 123, 162, 0.1))',
  },
  coin1: {
    top: '40%',
    left: -12,
    width: 44,
    height: 44,
    animation: '3s ease-in-out infinite $float',
  },
  coin2: {
    top: '15%',
    left: '10%',
    width: 52,
    height: 52,
    [theme.breakpoints.down('sm')]: {
      top: '18%',
      left: '7%',
    },
  },
  coin3: {
    top: '30%',
    left: '20%',
    [theme.breakpoints.down('xs')]: {
      display: 'none',
    },
  },
  coin4: {
    top: '10%',
    left: '40%',
    height: 112,
    width: 112,
    animation: '5s ease-in-out infinite $float',
    [theme.breakpoints.down('sm')]: {
      width: 84,
      height: 84,
      top: 0,
      left: '32%',
    },
  },
  coin5: {
    top: '30%',
    left: '60%',
    animation: '2s ease-in-out infinite $float',
  },
  coin6: {
    top: '10%',
    left: '70%',
    width: 44,
    height: 44,
    animation: '3s ease-in-out 1s infinite $float',
  },
  coin7: {
    top: '40%',
    left: '80%',
    animation: '3s ease-in-out 1s infinite $float',
    [theme.breakpoints.down('xs')]: {
      display: 'none',
    },
  },
  coin8: {
    top: '20%',
    left: '90%',
    width: 64,
    height: 64,
    animation: '5s ease-in-out 4s infinite $float',
  },
  coin9: {
    bottom: '25%',
    right: '90%',
  },
  coin10: {
    bottom: '40%',
    right: '80%',
    width: 52,
    height: 52,
    [theme.breakpoints.down('xs')]: {
      display: 'none',
    },
  },
  coin11: {
    bottom: '15%',
    right: '70%',
    width: 64,
    height: 64,
    animation: '5s ease-in-out 2s infinite $float',
    [theme.breakpoints.down('sm')]: {
      bottom: '8%',
    },
  },
  coin12: {
    bottom: '30%',
    right: '50%',
    width: 44,
    height: 44,
    animation: '3s ease-in-out infinite $float',
  },
  coin13: {
    bottom: '10%',
    right: '40%',
    width: 44,
    height: 44,
    animation: '3s ease-in-out 2s infinite $float',
    [theme.breakpoints.down('sm')]: {
      bottom: '4%',
    },
  },
  coin14: {
    bottom: '25%',
    right: '20%',
    width: 62,
    height: 62,
    [theme.breakpoints.down('sm')]: {
      bottom: '16%',
    },
  },
  coin15: {
    bottom: '15%',
    right: '15%',
    width: 52,
    height: 52,
    animation: '3s ease-in-out infinite $float',
    [theme.breakpoints.down('xs')]: {
      display: 'none',
    },
  },
  coin16: {
    bottom: '10%',
    right: -10,
    animation: '3s ease-in-out infinite $float',
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    rowGap: 8,

    margin: 'auto 24px',
    padding: 24,
    width: '100%',
    maxWidth: 320,

    background: 'rgba(255, 255, 255, 0.8)',
    borderRadius: 32,

    backdropFilter: 'blur(4px)',
    filter: 'drop-shadow(0px 4px 32px rgba(58, 60, 77, 0.04))',

    textAlign: 'center',

    [theme.breakpoints.up('md')]: {
      width: '50%',
      maxWidth: 540,
    },

    '& h1': {
      fontSize: 24,
      fontWeight: 600,
      lineHeight: '32px',

      [theme.breakpoints.up('md')]: {
        fontSize: 44,
        fontWeight: 700,
        lineHeight: '80px',
      },
    },

    '& h2': {
      fontSize: 14,
      fontWeight: 500,
      lineHeight: '24px',

      [theme.breakpoints.up('md')]: {
        fontSize: 20,
        lineHeight: '32px',
      },
    },

    '& em': {
      color: theme.palette.green[500],
      fontStyle: 'normal',
    },
  },

  '@keyframes float': {
    '0%': { transform: 'translateY(0)' },
    '50%': { transform: 'translateY(-10%)' },
    '100%': { transform: 'translateY(0)' },
  },
}));

const Maintenance = () => {
  const classes = useStyles();

  return (
    <Box className={classes.root}>
      <Box className={`${classes.gradient} ${classes.gradientTop}`} />
      <Box className={`${classes.gradient} ${classes.gradientBottom}`} />

      {['aic', 'aion', 'bch', 'btc', 'etc', 'hippo', 'fil', 'gusd', 'hcc', 'heco', 'eth', 'tmcn', 'udoo',
        'usdt', 'xra', 'yfbeta',
      ].map((coin, index) => (
        <img
          key={index}
          className={`${classes.coin} ${(classes as any)[`coin${index + 1}`] as string}`}
          src={`/icons/coins/${coin}.svg`}
          alt=""
        />
      ))}

      <Box className={classes.content}>
        <Typography palette="grey" paletteColor={700} variant="h1">
          We'll be back<br />
          <em>in 30</em> minutes
        </Typography>

        <Typography palette="grey" paletteColor={700} variant="h2">
          We are working on some improvements. Under maintenance to give you a better experience!
        </Typography>
      </Box>
    </Box>
  );
};

Maintenance.displayName = 'Maintenance';
Maintenance.routePath = MaintenanceRoute;
Maintenance.loginRequired = false;

export default Maintenance;
