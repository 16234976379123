import axios, { AxiosResponse } from 'axios';
import tryParseJSONObject from 'utils/tryParseJSONObject';

import { Features } from '../models/Features';
import Headers from './headers';

const API_URL = '/api/features';

export const getFeatures = (): Promise<Features> => axios.get(`${API_URL}/getFeatures`,
  { headers: { ...Headers.def(), ...Headers.auth() } })
  .then((res: AxiosResponse<Features>) => ({
    ...res.data,
    pendingActions: res.data.pendingActions?.map((p) => tryParseJSONObject(p as unknown as string) || p),
  }));
