// @flow
import { Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { AxiosError } from 'axios';
import { Button, LightAutocomplete, Typography } from 'elements';
import { InputWithTitle } from 'elements/InputWithTitle';
import LightModal from 'elements/LightModal';
import { LightTextField } from 'elements/LightTextField';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { WalletProvider } from 'services/types/wallet';
import WalletService from 'services/wallet-service';
import { useStore as useUserStore } from 'store/zustand/User';
import { useStore as useWalletStore } from 'store/zustand/Wallet';
import { useStore as useWorkItemStore } from 'store/zustand/WorkItem';
import generateRandomString from 'utils/generateRandomString';

import { getWalletProviderIcon } from './WalletTypes';

interface Props {
  showModal: boolean;
  // eslint-disable-next-line no-unused-vars
  setShowModal: (value: boolean) => void;
  type: WalletProvider;
  addresses?: string[];
}

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    width: '100%',
    flexDirection: 'column',
    textAlign: 'center',
    gap: '16px',
    alignItems: 'center',
  },
  title: { color: theme.palette.grey['800'] },
  content: { color: theme.palette.grey['700'] },
  icon: {
    height: 36,
    width: 36,
    borderRadius: 8,
    overflow: 'hidden',
    display: 'flex',
    marginBottom: -8,
    '& img': {
      userDrag: 'none',
      userSelect: 'none',
      objectFit: 'contain',
    },
  },
  readonlyTitle: {
    fontSize: 16,
    lineHeight: '24px',
    fontWeight: 500,
    color: theme.palette.grey[800],
    '& em': {
      color: theme.palette.purple[600],
      fontStyle: 'normal',
    },
  },
  readOnlyDesc: {
    fontSize: 14,
    lineHeight: '24px',
    fontWeight: 500,
    color: theme.palette.grey[700],
  },
  readOnlySupport: {
    fontSize: 12,
    lineHeight: '24px',
    fontWeight: 500,
    color: theme.palette.grey[600],
  },
}));

export const EVM_ADDRESS_FORMAT = /^\b0x([a-z, A-Z\d]){40}\b$/;

const AddWalletModal = ({ showModal, setShowModal, type, addresses }: Props) => {
  const classes = useStyles();

  const { fetchWallet } = useWalletStore();
  const [fetchWorkItems] = useWorkItemStore((state) => [state.fetchWorkItems]);
  const [fetchUser] = useUserStore((state) => [state.fetchUser]);

  const [walletName, setWalletName] = useState('');
  const [address, setAddress] = useState('');
  const [addressError, setAddressError] = useState('');
  const [isError, setIsError] = useState(false);
  const [isLimitError, setIsLimitError] = useState(false);
  const [isWalletTsError, setIsWalletTsError] = useState(false);
  const [isComplete, setIsComplete] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (addresses && addresses.length === 1) setAddress(addresses[0]);
  }, [addresses]);

  const onNameChange = (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    if (e.target.value?.length <= 15 && !isLoading) {
      setWalletName(e.target.value);
    }
  };

  const onAddressChange = (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    setAddress(e.target.value);
    if (e.target.value && !e.target.value.match(EVM_ADDRESS_FORMAT)) {
      setAddressError('invalid address');
    } else {
      setAddressError('');
    }
  };

  const onCreateWallet = async () => {
    setIsLoading(true);
    try {
      const walletAddress = address || undefined;
      await WalletService.createWallet(walletName, type === 'Self-custody' ? 'Reporting' : type, walletAddress);
      setIsError(false);
      setIsLimitError(false);
      setIsWalletTsError(false);
      await Promise.allSettled([fetchWallet(true), fetchWorkItems(true), fetchUser(true)]);
      setIsComplete(true);
    } catch (e) {
      if ((e as AxiosError).response?.status === 406) {
        setIsError(false);
        setIsLimitError(true);
        setIsWalletTsError(false);
      } else if (((e as AxiosError).response?.status === 405)) {
        setIsLimitError(false);
        setIsError(false);
        setIsWalletTsError(true);
      } else {
        setIsLimitError(false);
        setIsError(true);
        setIsWalletTsError(false);
      }
      setIsLoading(false);
    }
    setIsLoading(false);
  };

  React.useEffect(() => {
    if (!showModal) {
      setWalletName('');
      setIsComplete(false);
      setIsLimitError(false);
      setIsError(false);
      setIsLoading(false);
    }
  }, [showModal]);

  const icon = React.useMemo(() => getWalletProviderIcon(type), [type]);

  return (
    <LightModal open={showModal} setOpen={setShowModal} maxWidth={372} showCloseButton>
      <Box className={classes.root}>
        {isComplete ? (
          <>
            <Box className={classes.icon}>
              <img src={`/icons/wallet_setting/${icon}.svg?${generateRandomString()}`} alt={type} />
            </Box>
            <Typography variant="h6" palette="grey" paletteColor={600}>
              {type === 'Reporting' ? 'Read-only' : 'Licensed Custodian'}
            </Typography>
            <Typography variant="h6" className={classes.content}>
              {type === 'Reporting' ? (
                <>We are importing your wallet.</>
              ) : (
                <>Your wallet is awaiting review from Coinbag.<br />We will reach out once approved!</>
              )}
            </Typography>
          </>
        ) : type === 'Reporting' ? (
          <>
            <Typography className={classes.readonlyTitle}>
              Connect <em>Read-only</em> wallet
            </Typography>
            <Box display="flex" flexDirection="column" gridGap={8}>
              <Typography className={classes.readOnlyDesc}>
                This wallet has limited functionality. You can only view balances and use it for reporting
              </Typography>
              <Typography className={classes.readOnlySupport}>
                Supported networks include: Ethereum, Binance Smart Chain, Polygon. More coming soon!
              </Typography>
            </Box>
          </>
        ) : (
          <>
            <Typography variant="h5" className={classes.title}>
              Add new wallet
            </Typography>
            <Typography variant="h6" className={classes.content}>
              Confirm you would like to create new {type.toLowerCase()} wallet.
            </Typography>
          </>
        )}

        {!isComplete && (
          <>
            <InputWithTitle fullWidth title="Wallet Name (Max 15 alphanumeric characters)">
              <LightTextField
                value={walletName}
                onChange={onNameChange}
                disabled={isLoading}
                placeholder="New Wallet"
              />
            </InputWithTitle>

            {(type === 'Reporting' || (type === 'Self-custody' && addresses?.length === 1)) && (
              <InputWithTitle
                fullWidth
                title="Wallet address"
                titleRightItem={<Typography variant="h6" color="error">{addressError}</Typography>}
              >
                <LightTextField
                  value={address}
                  onChange={onAddressChange}
                  disabled={isLoading || (type === 'Self-custody' && addresses?.length === 1)}
                  error={addressError.length > 0}
                  placeholder="e.g. 0x...."
                />
              </InputWithTitle>
            )}

            {(type === 'Self-custody' && (addresses?.length ?? 0) > 1) && (
              <LightAutocomplete
                style={{ width: '100%', background: 'white', zIndex: 1 }}
                options={addresses || []}
                value={address}
                disabled={isLoading}
                onChange={(_, newValue: string | null) => setAddress(newValue ?? '')}
                renderInput={(params) => (
                  <LightTextField
                    {...params}
                    fullWidth
                    variant="outlined"
                    placeholder="Choose address"
                    InputProps={{ ...params.InputProps }}
                  />
                )}
              />
            )}

            {isError && <Typography color="error" variant="caption">Something went wrong. Please try again later.</Typography>}
            {isLimitError && <Typography color="error" variant="caption">Your wallet address exceeds the 1,000 transactions limit.</Typography>}
            {isWalletTsError && (
              <Typography color="error" variant="caption">This address has transactions that occurred before the system's acceptable date range</Typography>
            )}

            <Button
              disabled={walletName.length <= 0 || (type === 'Reporting' ? address.length <= 0 || !address.match(EVM_ADDRESS_FORMAT) : false) || isLoading}
              onClick={onCreateWallet}
              fullWidth
            >
              {isLoading ? 'Loading...' : 'Add wallet'}
            </Button>
          </>
        )}
      </Box>
    </LightModal>
  );
};

export default AddWalletModal;
