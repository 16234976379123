import { Box, createStyles, Hidden, makeStyles, MenuItem, useMediaQuery, useTheme } from '@material-ui/core';
import {
  Button, Checkbox,
  InputWithTitle, LightAutocomplete, LightTextField, LightToggleGroup, Typography } from 'elements';
import { LightFrenchFries } from 'elements/LightFrenchFries';
import { LightSelect } from 'elements/LightSelect';
import Fuse from 'fuse.js';
import pluralize from 'pluralize';
import React, { useMemo } from 'react';
import SVG from 'react-inlinesvg';
import { Tag } from 'services/types/tag';
import { gasFeeTag, rewardTag, useStore as useTransactionStore } from 'store/zustand/Transaction';
import { useStore as useWalletStore } from 'store/zustand/Wallet';

import { AddressOptionRow } from './AddressOptionRow';
import { Calendar } from './Calendar';

interface FilterAndSortingProps {
  filteredTags: number[];
  // eslint-disable-next-line no-unused-vars
  setFilteredTags: (value: number[]) => void;
  filteredAddressBooks: AddressBookOption[];
  // eslint-disable-next-line no-unused-vars
  setFilteredAddressBooks: (value: AddressBookOption[]) => void;
  filteredDate?: { startDate: Date, endDate: Date };
  // eslint-disable-next-line no-unused-vars
  setFilteredDate: (value: { startDate: Date, endDate: Date } | undefined) => void;
  selectedSortingOption: SortingOption
  // eslint-disable-next-line no-unused-vars
  setSelectedSortingOption: (value: SortingOption) => void;
  generateCsv: () => void;
  generateXlsx: () => void;
  onEditAddressBook: () => void;
  onEditTag: () => void;
}

const useStyles = makeStyles((theme) => createStyles({
  root: {
    display: 'flex',
    flexDirection: 'column',
    gap: 16,
    width: '100%',
    [theme.breakpoints.down('sm')]: {
      gap: 0,
      overscrollBehavior: 'none',
    },
  },
  titleWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  menuWrapper: {
    display: 'flex',
    gap: 16,
    alignItems: 'center',
  },
  buttonsWrapper: {
    display: 'flex',
    gap: 16,
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      '& button': {
        flexGrow: 1,
      },
    },
  },
  downloadWrapper: {
    display: 'flex',
    flexWrap: 'nowrap',
    alignItems: 'center',
    gap: 8,
  },
  buttonIcon: {
    width: 18,
    height: 18,
    display: 'flex',
    marginLeft: 8,
    '& svg': {
      width: 18,
      height: 18,
    },
  },
  tagSearchInput: {
    color: theme.palette.grey[400],
    gap: '4px',
  },
  configWrapper: {
    display: 'grid',
    gridTemplateColumns: '2fr 1fr 1fr 1fr',
    gap: 12,
    [theme.breakpoints.down('sm')]: {
      gap: 8,
      minHeight: '70vh',
      padding: '12px 16px',
      display: 'flex',
      flexDirection: 'column',
      '& > *:first-child': {
        marginBottom: 4,
      },
    },
  },
  columnTitle: {
    display: 'flex',
    alignItems: 'center',
  },
  dateMenuItem: {
    padding: 12,
    maxWidth: '100%',
    display: 'flex',
    overflow: 'hidden',
    '&:hover': {
      backgroundColor: 'white',
    },
    justifyContent: 'center',
    '&:focus': {
      backgroundColor: 'white',
    },
  },
  menuItem: {
    alignItems: 'center',
    justifyContent: 'flex-start',
    width: '100%',
    display: 'flex',
    flexWrap: 'nowrap',
    gap: 8,
  },
  rowSection: {
    width: '100%',
    padding: '12px 16px',
    display: 'flex',
    background: theme.palette.common.white,
    borderTop: `1px solid ${theme.palette.grey[200]}`,
  },
  settingButton: {
    width: 36,
    height: 36,
    background: theme.palette.grey[100],
    borderRadius: 8,
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    position: 'relative',
    justifyContent: 'center',
    transition: 'all 0.3s',
    '&#active': {
      background: theme.palette.green[100],
      '& path, circle': {
        stroke: theme.palette.green[600],
      },
    },
  },
  filteredCount: {
    height: 16,
    minWidth: 16,
    borderRadius: 8,
    padding: 4,
    fontSize: 10,
    fontWeight: 500,
    background: theme.palette.green[600],
    color: theme.palette.common.white,
    textAlign: 'center',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    border: '2px solid white',
    position: 'absolute',
    bottom: 0,
    right: -4,
  },
  addressGroupHeader: {
    gap: '6px',
    display: 'flex',
    backgroundColor: '#FAFBFF',
    fontSize: '12px',
    alignItems: 'center',
    paddingTop: '6px',
    paddingLeft: '6px',
    paddingRight: '16px',
    paddingBottom: '6px',
    borderBottom: `1px solid ${theme.palette.grey[200]}`,
  },
  selectMenuItem: {
    '&.Mui-selected': {
      backgroundColor: theme.palette.green[100],
      '&:hover': {
        backgroundColor: theme.palette.green[100],
      },
    },
    borderBottom: `1px solid ${theme.palette.grey[200]}`,
    alignItems: 'center',
    justifyContent: 'flex-start',
    width: '100%',
    display: 'flex',
    flexWrap: 'nowrap',
    gap: 8,
  },
}));

export enum SortingOption {
  DateNewToOld = 'Date (new to old)',
  DateOldToNew = 'Date (old to new)',
  AmountHighToLow = 'Amount (high to low)',
  AmountLowToHigh = 'Amount (low to high)'
}

export const FilterAndSorting = React.memo((
  { filteredDate, setFilteredDate, filteredTags, setFilteredTags, filteredAddressBooks,
    setFilteredAddressBooks, generateCsv, generateXlsx, onEditAddressBook, onEditTag,
    selectedSortingOption, setSelectedSortingOption }: FilterAndSortingProps,
) => {
  const classes = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [txs, addressBooks, tags] = useTransactionStore((state) => [state.txs, state.addressBooks, state.tags]);
  const [walletInfos] = useWalletStore((state) => [state.walletInfos]);
  const [fileFormat, setFileFormat] = React.useState<'csv' | 'xlsx'>('csv');
  const [isCalendarOpen, setIsCalendarOpen] = React.useState<boolean>(false);
  const [isDetailOpen, setIsDetailOpen] = React.useState<boolean>(false);
  const clearCalendarFilter = () => { setFilteredDate(undefined); };

  const addressBookOptions: AddressBookOption[] | undefined = useMemo(() => {
    if (walletInfos !== undefined && addressBooks !== undefined) {
      const walletAddresses = new Set(walletInfos.map((w) => (w.address)).flat());
      const addressWalletNameMap = walletInfos
        .reduce((prev: Record<string, string>, curr) => {
          const flattenAddresses = curr.address
            ?.reduce((a: Record<string, string>, b) => ({ ...a, ...{ [b]: curr.name ?? curr.walletProvider } }), {});
          return { ...prev, ...(flattenAddresses ?? {}) };
        }, {});
      const addressesToAddToOptions: AddressBookOption[] = walletInfos
        .reduce((prev: string[], curr) => [
          ...prev, ...(curr?.address ? curr.address.filter((a) => !prev.includes(a)) : [])], [])
        .map((a) => ({
          address: a,
          tags: [addressBooks.find((x) => x.address === a)?.tag || addressWalletNameMap[a]],
          connected: true,
        }));
      const addressNameMap = addressBooks
        .filter((a) => !walletAddresses.has(a.address))
        .reduce((prev: Record<string, string[]>, curr) => (
          { ...prev, [curr.address]: [...(prev[curr.address] ?? []), curr.tag] }), {});
      const optionsFromAddressBooks = Object.entries(addressNameMap)
        .map(([address, tags]) => ({ address, tags, connected: false }));

      return [...addressesToAddToOptions, ...optionsFromAddressBooks];
    }

    return [];
  }, [walletInfos, addressBooks]);

  const downloadSection = React.useMemo(() => (
    <Box className={classes.downloadWrapper} hidden={txs == null}>
      <LightToggleGroup
        size={isMobile ? 'medium' : 'small'}
        value={fileFormat}
        onChange={(_, format) => { if (format) setFileFormat(format); }}
        values={[{ value: 'csv', title: 'csv' }, { value: 'xlsx', title: 'xlsx' }]}
        exclusive
      />
      <Button
        shape="round"
        size={isMobile ? 'md' : 'sm'}
        onClick={async () => {
          if (fileFormat === 'csv') await generateCsv();
          else await generateXlsx();
        }}
      >
        <Typography variant={isMobile ? 'h4' : 'h6'}>Download report</Typography>
        <Box className={classes.buttonIcon}>
          <SVG src="/icons/reporting/ic_download.svg" />
        </Box>
      </Button>
    </Box>
  ), [generateXlsx, generateCsv, fileFormat, isMobile]);

  const manageSection = React.useMemo(() => (
    <Box className={classes.buttonsWrapper}>
      <Button
        shape="round"
        size="sm"
        variant="secondary"
        onClick={(e) => { e.currentTarget.blur(); onEditAddressBook(); }}
      >
        Address book manager
        <Box className={classes.buttonIcon}>
          <SVG src="/icons/reporting/ic_address_book.svg" />
        </Box>
      </Button>
      <Button
        shape="round"
        size="sm"
        variant="secondary"
        onClick={(e) => { e.currentTarget.blur(); onEditTag(); }}
      >
        Tag manager
        <Box className={classes.buttonIcon}>
          <SVG src="/icons/reporting/ic_tag.svg" />
        </Box>
      </Button>
    </Box>
  ), [onEditAddressBook, onEditTag, isMobile]);

  const filteredCount = React.useMemo(() => (
    filteredAddressBooks.length + filteredTags.length + (filteredDate === null ? 0 : 1)
  ), [filteredAddressBooks, filteredTags, filteredDate]);

  return (
    <Box className={classes.root}>
      {(isMobile ? isDetailOpen : true) && (
        <>
          <Hidden smDown>
            <Box className={classes.titleWrapper}>
              <Typography variant="h4" paletteColor={600}>Filtering & Sorting</Typography>
              <Box className={classes.menuWrapper}>
                {manageSection}
                {downloadSection}
              </Box>
            </Box>
          </Hidden>
          <Box className={classes.configWrapper}>
            <Hidden mdUp>
              <Typography variant="h4" paletteColor={600}>Filtering & Sorting</Typography>
            </Hidden>
            <InputWithTitle title="Address book">
              <LightAutocomplete
                multiple
                fullWidth
                limitTags={0}
                openOnFocus
                blurOnSelect
                value={filteredAddressBooks}
                defaultValue={filteredAddressBooks}
                getLimitTagsText={(num) => `${num} ${pluralize('addresses', num)} selected`}
                renderInput={(params) => (
                  <LightTextField
                    {...params}
                    placeholder={(filteredAddressBooks.length === 0) ? 'Search from address book' : ''}
                    InputProps={{
                      ...params.InputProps,
                      className: `${classes.tagSearchInput} ${params.InputProps.className}`,
                      startAdornment: (
                        <>
                          <SVG src="icons/reporting/ic_address_book.svg" width={20} height={20} />
                          {params.InputProps.startAdornment}
                        </>
                      ),
                    }}
                  />
                )}
                loading={addressBookOptions === undefined}
                options={addressBookOptions ?? []}
                groupBy={(addressBook) => (addressBook.connected ? 'connected' : 'other')}
                renderTags={(tagValue) => tagValue.map(() => (<></>))}
                getOptionLabel={(addressBook) => (`${addressBook.tags.reduce((a, b) => (`${a} ${b}`), '')} ${addressBook.address}`)}
                filterOptions={(abs, state) => {
                  if (state.inputValue === '') return abs;
                  const fuse = new Fuse(abs, { keys: ['address', 'tag'], useExtendedSearch: true });
                  return fuse
                    .search(`${state.inputValue.toLowerCase().startsWith('0x') ? '^' : ''}${state.inputValue}`)
                    .map((s) => s.item);
                }}
                renderOption={(addressBook, state) => (
                  <Box className={classes.menuItem} key={`${addressBook.tags} ${addressBook.address}`}>
                    <AddressOptionRow
                      address={addressBook.address}
                      checked={state.selected}
                      tags={addressBook.tags}
                    />
                  </Box>
                )}
                renderGroup={(params) => {
                  const selectedChildren = React.Children.toArray(params.children)
                    .filter(React.isValidElement)
                    .map((child: React.ReactElement) => child.props['aria-selected']);

                  const allChecked = selectedChildren.every((isSelected) => isSelected);
                  const indeterminate = !allChecked && selectedChildren.some((isSelected) => isSelected);

                  const onClick = () => {
                    const isConnectedGroup = params.group === 'connected';
                    const allCurrentGroupOptions = addressBookOptions.filter((ab) => ab.connected === isConnectedGroup);
                    const currentlySelected = filteredAddressBooks;

                    if (allChecked) {
                      // deselect all checkboxes
                      setFilteredAddressBooks(currentlySelected.filter((ab) => !allCurrentGroupOptions.includes(ab)));
                    } else {
                      // select all checkboxes
                      const newSelected = [...currentlySelected, ...allCurrentGroupOptions.filter((ab) => !currentlySelected.includes(ab))];
                      setFilteredAddressBooks(newSelected);
                    }
                  };

                  return (
                    <Box key={params.key}>
                      <Box className={classes.addressGroupHeader}>
                        <Checkbox checked={allChecked} indeterminate={indeterminate} onClick={onClick} />
                        <Typography paletteColor={700} variant="h6">
                          {params.group === 'connected' ? 'Your connected addresses' : 'Other addresses'}
                        </Typography>
                      </Box>
                      <Box>{params.children}</Box>
                    </Box>
                  );
                }}
                onChange={(e, values) => { setFilteredAddressBooks([...values]); }}
              />
            </InputWithTitle>
            <InputWithTitle
              title="Tags"
            >
              <LightAutocomplete
                multiple
                fullWidth
                limitTags={0}
                openOnFocus
                blurOnSelect
                defaultValue={tags.concat([gasFeeTag, rewardTag]).filter((t) => filteredTags.includes(t.tagId))}
                getLimitTagsText={(num) => `${num} ${pluralize('tag', num)} selected`}
                options={tags ? tags.concat([gasFeeTag, rewardTag]).sort((a: Tag, b: Tag) => a.tagId - b.tagId) : []}
                getOptionLabel={(option) => option.tag}
                getOptionSelected={(option, tag) => (option.tagId === tag.tagId)}
                onChange={async (event, values) => {
                  setFilteredTags(values.map((v) => v.tagId));
                }}
                size="medium"
                renderTags={(tagValue) => tagValue.map(() => (<></>))}
                renderOption={(option) => (
                  <LightFrenchFries
                    variant={option.tagId < 0 ? 'outlined' : 'default'}
                    label={option.tag}
                    autoAssignColor
                  />
                )}
                filterOptions={(t: Tag[], state) => {
                  if (state.inputValue === '') return t;
                  const fuse = new Fuse(t, { keys: ['tag'] });
                  return fuse.search(state.inputValue).map((s) => s.item);
                }}
                renderInput={(params) => (
                  <LightTextField
                    label=""
                    placeholder={filteredTags?.length === 0 ? 'Tags' : ''}
                    {...params}
                    InputProps={{
                      ...params.InputProps,
                      startAdornment: (
                        <>
                          <SVG src="icons/reporting/ic_tag.svg" width={20} height={20} />
                          {params.InputProps.startAdornment}
                        </>
                      ),
                      className: `${classes.tagSearchInput} ${params.InputProps.className}`,
                    }}
                  />
                )}
              />
            </InputWithTitle>
            <InputWithTitle
              title="Period:"
            >
              <LightSelect
                startAdornment={<SVG src="icons/reporting/ic_calendar.svg" width={20} height={20} />}
                value=""
                placeholder="Period"
                open={isCalendarOpen}
                displayEmpty
                onOpen={() => setIsCalendarOpen(true)}
                MenuProps={{
                  transformOrigin: { vertical: -8, horizontal: isMobile ? 'center' : 'right' },
                  anchorOrigin: { vertical: 'bottom', horizontal: isMobile ? 'center' : 'right' },
                }}
                renderValue={() => (
                  <Typography
                    variant="h6"
                    paletteColor={400}
                    strong={false}
                  >
                    {!filteredDate ? 'All-Time'
                      : filteredDate.startDate === filteredDate.endDate ? filteredDate.startDate.toLocaleDateString()
                        // eslint-disable-next-line max-len
                        : `${filteredDate.startDate.toLocaleDateString()} - ${filteredDate.endDate.toLocaleDateString()}`}
                  </Typography>
                )}
              >
                <MenuItem className={classes.dateMenuItem} value="Period">
                  <Calendar
                    filteredDate={filteredDate}
                    setFilteredDate={setFilteredDate}
                    setCalendarOpen={setIsCalendarOpen}
                    clear={clearCalendarFilter}
                  />
                </MenuItem>
              </LightSelect>
            </InputWithTitle>
            <InputWithTitle title="Sort by:">
              <LightSelect
                displayEmpty
                value={selectedSortingOption}
                renderValue={(option) => (
                  <Box display="flex" alignItems="center" width="100%" gridColumnGap="4px">
                    <SVG src="icons/reporting/ic_sorting.svg" width={20} height={20} />
                    <Typography variant="h6" paletteColor={500} strong={false}>{option as string}</Typography>
                  </Box>
                )}
                onChange={(e) => {setSelectedSortingOption(e.target.value as SortingOption);}}
              >
                { [SortingOption.DateNewToOld, SortingOption.DateOldToNew,
                  SortingOption.AmountHighToLow, SortingOption.AmountLowToHigh].map((option) => (
                  <MenuItem key={option} value={option} className={classes.selectMenuItem}>
                    <Typography variant="h6" paletteColor={400}>{option}</Typography>
                  </MenuItem>
                ))}
              </LightSelect>
            </InputWithTitle>
          </Box>
        </>
      )}
      <Hidden mdUp>
        {isDetailOpen && (
          <Box className={classes.rowSection} justifyContent="space-between">
            {manageSection}
          </Box>
        )}
        <Box className={classes.rowSection} justifyContent="space-between">
          <Box className={classes.settingButton} id={!isDetailOpen && filteredCount > 0 ? 'active' : ''} onClick={() => setIsDetailOpen((prev) => !prev)}>
            {isDetailOpen
              ? <SVG src="/icons/reporting/ic_cross.svg" width={24} height={24} />
              : <SVG src="/icons/reporting/ic_filter.svg" width={24} height={24} />}
            {!isDetailOpen && filteredCount > 0 && (
              <Box className={classes.filteredCount}>
                {filteredCount}
              </Box>
            )}
          </Box>
          {!isDetailOpen && downloadSection}
        </Box>
      </Hidden>
    </Box>
  );
});

FilterAndSorting.displayName = 'FilterAndSorting';

export type AddressBookOption = { address: string, tags: string[], connected: boolean };
