// eslint-disable-next-line import/extensions
import roleService from 'services/role-service';
import { ApprovalToken } from 'services/types/approval-token';
import { PermissionCategory } from 'services/types/role';
import { create } from 'zustand';

interface RoleStore {
  permissions?: PermissionCategory[];
  // eslint-disable-next-line no-unused-vars
  fetchPermissions: (force: boolean) => Promise<PermissionCategory[]>;
  // eslint-disable-next-line no-unused-vars
  updateRequiredApprovers: (permissionId: number, requiredApprovers: number) => Promise<void>;
  // eslint-disable-next-line no-unused-vars
  addPermissionApprover: (permissionId: number, userId: number, email: string) => Promise<void>;
  // eslint-disable-next-line no-unused-vars
  removePermissionApprover: (permissionId: number, userId: number) => Promise<void>;
  // eslint-disable-next-line no-unused-vars
  updatePermissionApprovers: (permissionId: number, users: { id: number, email: string }[]) => Promise<void>;
  // eslint-disable-next-line no-unused-vars
  validateApprovalToken: (token: string) => Promise<ApprovalToken>
}

export const useStore = create<RoleStore>((set, get) => ({
  permissions: undefined,
  fetchPermissions: async (force: boolean) => {
    if (force || get().permissions === undefined) {
      const response = await roleService.getAllPermissionsWithApprovers();
      set({ permissions: response });
      return response;
    }
    return get().permissions || [];
  },
  updateRequiredApprovers: async (permissionId: number, requiredApprovers: number) => {
    const permission = findPermission(permissionId, get().permissions);
    const oldState = permission?.requiredApprovers;
    if (permission) permission.requiredApprovers = requiredApprovers;
    set((state) => ({ permissions: state.permissions }));
    try {
      await roleService.updateRequiredApprovers(permissionId, requiredApprovers);
    } catch {
      if (permission && oldState) permission.requiredApprovers = oldState;
      set((state) => ({ permissions: state.permissions }));
    }
  },
  addPermissionApprover: async (permissionId: number, userId: number, email: string) => {
    const permission = findPermission(permissionId, get().permissions);
    const oldState = permission && [...permission.approvers];
    if (permission) permission.approvers = [...permission.approvers, { permissionId, userId, email }];
    set((state) => ({ permissions: state.permissions }));
    try {
      await roleService.addPermissionApprover(permissionId, userId);
    } catch {
      if (permission && oldState) permission.approvers = oldState;
      set((state) => ({ permissions: state.permissions }));
    }
  },
  removePermissionApprover: async (permissionId: number, userId: number) => {
    const permission = findPermission(permissionId, get().permissions);
    const oldState = permission && [...permission.approvers];
    if (permission) permission.approvers = permission.approvers.filter((a) => a.userId !== userId);
    set((state) => ({ permissions: state.permissions }));
    try {
      await roleService.removePermissionApprover(permissionId, userId);
    } catch {
      if (permission && oldState) permission.approvers = oldState;
      set((state) => ({ permissions: state.permissions }));
    }
  },
  updatePermissionApprovers: async (permissionId: number, users: { id: number, email: string }[]) => {
    const permission = findPermission(permissionId, get().permissions);
    const oldState = permission && [...permission.approvers];
    if (permission) {
      permission.approvers = users.map((u) => ({
        email: u.email, permissionId: permission.permissionId, userId: u.id,
      }));
    }
    set((state) => ({ permissions: state.permissions }));
    try {
      await roleService.updatePermissionApprovers(permissionId, users.map((u) => u.id));
    } catch {
      if (permission && oldState) permission.approvers = oldState;
      set((state) => ({ permissions: state.permissions }));
    }
  },
  validateApprovalToken: roleService.validateApprovalToken,
}));

const findPermission = (id: number, permissions?: PermissionCategory[]) => permissions
  ?.flatMap((c) => c.permissions)
  .find((p) => p.permissionId === id);
