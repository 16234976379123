import {
  Box,
  createStyles,
  Hidden,
  makeStyles,
  Theme,
  useMediaQuery,
  useTheme,
} from '@material-ui/core';
import { Typography } from 'elements/Typography';
import * as React from 'react';
import { palette } from 'theme';

const useStyles = makeStyles<Theme, Partial<StepsProps>>((theme: Theme) => createStyles({
  root: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    gap: 60,
    color: theme.palette.grey[700],
    zIndex: 1,
    '& em': {
      color: ({ color }) => theme.palette[color || 'green'][600],
      fontStyle: 'normal',
    },
    [theme.breakpoints.down('sm')]: {
      gap: 24,
      maxWidth: 400,
    },
  },
  title: {
    fontSize: 44,
    lineHeight: '66px',
    textAlign: 'center',
    fontWeight: 700,
    [theme.breakpoints.down('sm')]: {
      fontSize: 24,
      lineHeight: '44px',
    },
  },
  stepWrapper: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: -19,
    marginBottom: -19,
    '& div:first-of-type hr:first-of-type, div:last-of-type hr:last-of-type': {
      visibility: 'hidden',
    },
    '& > div:nth-child(odd)': {
      '& em': {
        color: theme.palette.green[600],
      },
    },
    '& > div:nth-child(even)': {
      '& em': {
        color: theme.palette.blue[600],
        fontStyle: 'normal',

      },
      '& $stepNumber': {
        background: palette.blue[500],
        borderColor: palette.blue[200],
      },
    },

  },
  stepRow: {
    display: 'flex',
    flexWrap: 'nowrap',
    gap: 12,
    [theme.breakpoints.down('sm')]: {
      gap: 20,
    },
  },
  column: {
    display: 'flex',
    width: '50%',
    paddingTop: 19,
    paddingBottom: 19,
    justifyContent: 'center',
    flexDirection: 'column',
    [theme.breakpoints.down('sm')]: {
      alignItems: 'flex-start',
      width: 'auto',
      paddingTop: 16,
      paddingBottom: 16,
    },
  },
  stepNumber: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    border: `2px solid ${palette.green[200]}`,
    background: palette.green[500],
    width: 28,
    height: 28,
    borderRadius: 99,
    fontSize: 14,
    textAlign: 'center',
    color: 'white',
    marginTop: 8,
    marginBottom: 8,
    [theme.breakpoints.down('sm')]: {
      marginTop: 0,
    },
  },
  line: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    flexShrink: 0,
    '& .line': {
      flex: 1,
      borderRight: '1px solid #DEE3EF',
      height: '100%',
      margin: 0,
    },
  },
  image: {
    borderRadius: 36,
    border: '1px solid rgba(187, 196, 221, 0.17)',
    width: 242,
    height: 142,
    userDrag: 'none',
    userSelect: 'none',
    [theme.breakpoints.down('sm')]: {
      width: 214,
      height: 124,
    },
  },
  detail: {
    fontSize: 24,
    lineHeight: '44px',
    fontWeight: 500,
    color: theme.palette.grey[700],

    [theme.breakpoints.down('sm')]: {
      fontSize: 14,
      lineHeight: '24px',
    },
  },
}));

export const individualInvestorsSteps: Step[] = [
  {
    detail: <>Sign up to <em>Coinbag</em> as Individual investor</>,
    image: '/images/register_steps/ind_step_1',
  },
  {
    detail: <>Select your cryptos<br /> or choose from <em>our ready-made bundles</em></>,
    image: '/images/register_steps/ind_step_2',
  },
  {
    detail: <>Choose <em>interest-earning</em> protocols</>,
    image: '/images/register_steps/ind_step_3',
  },
  {
    detail: <>Select <em>rebalancing</em> cadence</>,
    image: '/images/register_steps/ind_step_4',
  },
  {
    detail: <>Make your first <em>deposit</em></>,
    image: '/images/register_steps/ind_step_5',
  },
];

export const institutionalInvestorsSteps: Step[] = [
  {
    detail: <>Sign up to <em>Coinbag</em> as institutional investor</>,
    image: '/images/register_steps/ins_step_1',
  },
  {
    detail: <>Select your cryptos and choose your <em>wallet providers</em></>,
    image: '/images/register_steps/ins_step_2',
  },
  {
    detail: <>Choose <em>interest-earning</em> protocols</>,
    image: '/images/register_steps/ins_step_3',
  },
  {
    detail: <>Select <em>rebalancing</em> cadence</>,
    image: '/images/register_steps/ins_step_4',
  },
  {
    detail: <>Make your first <em>deposit</em></>,
    image: '/images/register_steps/ins_step_5',
  },
];

export interface StepsProps {
  steps: Step[];
  color?: keyof Omit<typeof palette, 'primary' | 'error'>;
  title?: React.ReactNode;
}

export interface Step {
  image: string;
  detail: React.ReactNode
}

const Steps = ({ steps, color, title }: StepsProps) => {
  const classes = useStyles({ color });
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <section itemScope className={classes.root}>
      <Typography className={classes.title} variant="h1" align="center">
        {title || (<>Get invested <em>in minutes!</em></>)}
      </Typography>
      <Box className={classes.stepWrapper}>
        {steps.map((step, index) => (
          <Box
            className={classes.stepRow}
            key={index}
            flexDirection={index % 2 === (isMobile ? 1 : 0) ? 'row' : 'row-reverse'}
          >
            <Box
              className={classes.column}
              textAlign={index % 2 === 0 && !isMobile ? 'right' : 'left'}
              justifyContent={index % 2 === 0 && !isMobile ? 'flex-end' : 'flex-start'}
              flexGrow={0}
              marginLeft={isMobile ? (index % 2 === 1 ? '-60px' : 0) : undefined}
              marginRight={isMobile ? (index % 2 === 0 ? '-60px' : 0) : undefined}
            >
              <picture>
                <source srcSet={`${step.image}@1x.png 1x, ${step.image}@2x.png 2x, ${step.image}@3x.png 3x`} />
                <img className={classes.image} alt={`step ${index + 1}`} src={`${step.image}@1x.png`} />
              </picture>
            </Box>
            <Hidden smDown>
              <Box className={classes.line}>
                <hr className="line" />
                <Box className={classes.stepNumber}>{index + 1}</Box>
                <hr className="line" />
              </Box>
            </Hidden>
            <Box
              className={classes.column}
              textAlign={index % 2 === 1 && !isMobile ? 'right' : 'left'}
              justifyContent={index % 2 === 1 && !isMobile ? 'flex-end' : 'flex-start'}
              flexGrow={1}
            >
              <Hidden mdUp>
                <Box className={classes.stepNumber}>{index + 1}</Box>
              </Hidden>
              <Typography className={classes.detail}>{step.detail}</Typography>
            </Box>
          </Box>
        ))}
      </Box>
    </section>
  );
};

export default Steps;
