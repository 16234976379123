import { Box, makeStyles, Theme, Typography } from '@material-ui/core';
import { Button, Card } from 'elements';
import React, { FC, ReactNode } from 'react';

type DepositProviderPerk = {
  title: string;
  value: ReactNode;

  icon?: ReactNode;
  subtitle?: ReactNode;
};

type DepositProviderCardProps = {
  className?: string;
  headerSrc: string;

  name: string;
  tag: string;
  iconSrc: string;

  perks: DepositProviderPerk[];
  onFund?: () => void;
};

const useStyles = makeStyles<Theme, { headerSrc: string }>((theme) => ({
  root: {
    overflow: 'hidden',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'stretch',

    padding: 0,
    boxShadow: '0px 4px 20px rgba(58, 60, 77, 0.08)',
  },

  header: {
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    padding: 12,

    background: ({ headerSrc }) => `url("${headerSrc}") no-repeat`,
    backgroundSize: 'cover!important',
  },

  providerIcon: {
    width: 32,
    height: 32,
  },
  providerName: {
    marginLeft: 12,
    color: 'white',
    fontWeight: 500,
  },
  providerTag: {
    marginLeft: 'auto',
    padding: '4px 8px',

    color: 'white',
    fontSize: 12,
    fontWeight: 500,

    background: 'rgba(255, 255, 255, 0.3)',
    borderRadius: 4,
  },

  perksGrid: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    alignItems: 'stretch',

    gap: 8,
    margin: 12,

    [theme.breakpoints.up('md')]: {
      gridTemplateColumns: '1fr 1fr 1fr',
      gap: 16,
    },
  },
  perkItem: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'stretch',

    gap: 4,
    padding: '8px 12px',

    border: '1px solid',
    borderColor: theme.palette.grey[200],
    borderRadius: 4,
  },
  perkName: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',

    color: theme.palette.grey[600],
    fontSize: 12,
    lineHeight: '20px',
  },
  perkSubtitle: { fontSize: 10, lineHeight: '20px' },
  perkValue: {
    display: 'flex',
    alignItems: 'center',

    fontSize: 10,
    fontWeight: 500,
    lineHeight: '20px',
  },

  fundButton: {
    margin: '0 12px 12px auto',
    width: 92,

    [theme.breakpoints.up('md')]: {
      width: 168,
    },
  },
}));

const DepositProviderCard: FC<DepositProviderCardProps> = ({
  className,
  headerSrc,
  name,
  tag,
  iconSrc,
  perks,
  onFund,
}) => {
  const classes = useStyles({ headerSrc });
  return (
    <Card className={`${classes.root} ${className}`}>
      <Box className={classes.header}>
        <img className={classes.providerIcon} alt="" src={iconSrc} />
        <Typography className={classes.providerName}>{name}</Typography>
        <Box className={classes.providerTag}>{tag}</Box>
      </Box>

      <Box className={classes.perksGrid}>
        {perks.map((perk, index) => (
          <Box key={index} className={classes.perkItem}>
            <Box className={classes.perkName}>
              <Typography>{perk.title}</Typography>
              {perk.icon}
            </Box>

            <Typography className={classes.perkSubtitle}>{perk.subtitle}</Typography>
            <Typography className={classes.perkValue}>{perk.value}</Typography>
          </Box>
        ))}
      </Box>
      <Button id={`purchase-${name.toLowerCase()}`} className={classes.fundButton} onClick={onFund}>Purchase</Button>
    </Card>
  );
};

DepositProviderCard.displayName = 'DepositProviderCard';
export { DepositProviderCard };
