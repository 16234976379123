import {
  Box,
  createStyles,
  Hidden,
  Link,
  makeStyles,
  Theme,
} from '@material-ui/core';
import { Typography } from 'elements/Typography';
import * as React from 'react';
import SVG from 'react-inlinesvg';

const useStyles = makeStyles((theme: Theme) => createStyles({
  root: {
    display: 'flex',
    background: '#FFFFFF',
    border: '1px solid #E8EFFF',
    borderRadius: '16px',
    padding: '16px',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'relative',
    overflow: 'hidden',
    zIndex: 1,
  },
  content: {
    display: 'flex',
    flexWrap: 'nowrap',
    gap: 30,
    flexGrow: 1,
    justifyContent: 'center',
    alignItems: 'center',
    [theme.breakpoints.down('sm')]: {
      gap: 12,
      flexDirection: 'column',
    },
  },
  logoWrapper: {
    display: 'flex',
    flexWrap: 'nowrap',
    '& img:last-child': {
      marginLeft: -16,
    },
  },
  logo: {
    width: 56,
    height: 56,
    boxShadow: '0px 4px 16px rgba(0, 96, 71, 0.12)',
    borderRadius: 50,
    userDrag: 'none',
    userSelect: 'none',
  },
  title: {
    '& a': {
      alignItems: 'center',
      display: 'flex',
      gap: '8px',
      whiteSpace: 'nowrap',
      [theme.breakpoints.down('sm')]: {
        justifyContent: 'center',
      },
    },
  },
  decsription: {
    display: 'flex',
    alignItems: 'center',
    gap: '4px',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
    },
  },
  icon: {
    position: 'absolute',
    '&.left': {
      left: 0,
      bottom: 0,
    },
    '&.right': {
      right: 0,
      top: 0,
    },
    '&.sm1': {
      left: '15%',
      top: 16,
    },
    '&.sm2': {
      left: 0,
      top: 40,
    },
    '&.sm3': {
      right: 20,
      top: 0,
    },
  },
}));

const PartnerBanner = () => {
  const classes = useStyles();
  return (
    <section itemScope className={classes.root}>
      <Box className={classes.content}>
        <Box className={classes.logoWrapper}>
          <img
            className={classes.logo}
            src="/icons/home/ic_coinbag.png"
            srcSet="/icons/home/ic_coinbag@2x.png 2x, /icons/home/ic_coinbag@3x.png 3x"
            alt="Coinbag logo"
          />
          <img
            className={classes.logo}
            src="/icons/home/ic_countrygroup.png"
            srcSet="/icons/home/ic_countrygroup@2x.png 2x, /icons/home/ic_countrygroup@3x.png 3x"
            alt="Country Group Holdings logo"
          />
        </Box>

        <Box display="flex" flexDirection="column" gridGap="4px">
          <Box className={classes.title}>
            <Link href="https://www.nationthailand.com/pr-news/business/40012162" target="_blank">
              <Typography variant="h4" palette="blue" paletteColor={600}>
                We&apos;re excited to announce! <SVG src="/icons/ic_link_out.svg" />
              </Typography>
            </Link>
          </Box>

          <Box className={classes.decsription}>
            <Typography variant="h4" palette="grey" paletteColor={800}>Country Group Holdings</Typography>
            <Typography variant="h6" palette="grey" paletteColor={600}> as our latest investor & partner</Typography>
          </Box>
        </Box>
      </Box>

      <Hidden smDown>
        <SVG className={`${classes.icon} left`} src="/icons/home/ic_banner_bg_left.svg" />
        <SVG className={`${classes.icon} right`} src="/icons/home/ic_banner_bg_right.svg" />
      </Hidden>

      <Hidden mdUp>
        <SVG className={`${classes.icon} sm1`} src="/icons/home/ic_sm_banner_bg_1.svg" />
        <SVG className={`${classes.icon} sm2`} src="/icons/home/ic_sm_banner_bg_2.svg" />
        <SVG className={`${classes.icon} sm3`} src="/icons/home/ic_sm_banner_bg_3.svg" />
      </Hidden>
    </section>
  );
};

export default PartnerBanner;
