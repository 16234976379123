import { Button, CircularProgress, TextField } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import * as React from 'react';
import { useHistory } from 'react-router-dom';
// eslint-disable-next-line import/extensions
import coinService from 'services/coin.service';
import { Coin } from 'services/types/coin';

const EditCoin = () => {
  const history = useHistory();
  const [inProgress, setInProgress] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(true);
  const [error, setError] = React.useState<string>('');
  const [coin, setCoin] = React.useState<Coin | null>(null);
  const [coinMetaData, setCoinMetaData] = React.useState<string | null>();

  const id = Number(new URLSearchParams(window.location.search).get('id'));

  React.useEffect(() => {
    coinService.getCoin(id).then(coin => {
      if (coin) {
        setCoin(coin);
        if (coin.metadata) {
          setCoinMetaData(JSON.stringify(coin.metadata));
        }
      } else {
        setError('Coin not found');
      }
      setIsLoading(false);
    }).catch(error => {
      setError(error?.message || JSON.stringify(error));
      setIsLoading(false);
    });
  }, [id]);

  const handleChangeCoinMetaData = React.useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    setCoinMetaData(event.target.value);
  }, []);

  const handleSave = React.useCallback(async () => {
    if (!coinMetaData) return;

    try {
      let parsed;
      try {
        parsed = JSON.parse(coinMetaData);
      } catch (error: any) {
        setError(`Invalid JSON object. \n\n ${error?.message || JSON.stringify(error)}`);
        return;
      }

      if (parsed && coin) {
        setError('');
        setInProgress(true);
        await coinService.updateMetaData(coin.coinId, parsed);
        history.push(`/view-coins?id=${coin.coinId}`);
      } {
        setError('Coin metadata is required');
      }

    } catch (error: any) {
      setInProgress(false);
      setError(error?.message || JSON.stringify(error));
    }
  }, [coinMetaData]);


  if (!id) {
    history.push('/view-coins');
    return;
  }

  if (isLoading) {
    return <div style={{ margin: '40px auto', width: '100px' }}>Loading ...</div>;
  }
  return (
    <div style={{ maxWidth: '100%', padding: '10px' }}>
      {error && <Alert severity="error">{error}</Alert>}
      <h1 style={{ textAlign: 'center' }}>Edit coin meta data</h1>
      <div>Name: {coin?.name}</div>
      <div>Symbol: {coin?.symbol}</div>
      <div>Chain: {coin?.chainId}</div>
      <form style={{ maxWidth: '600px', margin: '0 auto' }}>
        <TextField id="metaData" multiline={true} required={true} label="Meta Data" onChange={handleChangeCoinMetaData} value={coinMetaData} InputLabelProps={{
          shrink: true,
        }}></TextField>
        <Button disabled={inProgress} variant="outlined" onClick={handleSave}>{inProgress ? <CircularProgress /> : 'Save'}</Button>
      </form>
    </div>
  );
};


EditCoin.routePath = '/edit-coin';
EditCoin.loginRequired = true;
EditCoin.displayName = 'EditCoin';

export default EditCoin;
