import { Box, createStyles, makeStyles, Typography } from '@material-ui/core';
import { Button } from 'elements';
import LightModal from 'elements/LightModal';
import * as React from 'react';

interface ChangePasswordModalProps {
  show: boolean;
  setShow: (value: boolean) => void;
  onChangePassword: () => void;
}

const useStyles = makeStyles((theme) => createStyles({
  root: {
    display: 'flex',
    flexDirection: 'column',
    gap: '12px',
    textAlign: 'center',
  },
  title: {
    color: theme.palette.grey[800],
  },
  description: {
    color: theme.palette.grey[700],
    fontWeight: 400,
  },
  buttonsGroup: {
    display: 'flex',
    flexWrap: 'nowrap',
    gap: '8px',
    marginTop: '4px',
    justifyContent: 'space-between',
  },
}));

const ChangePasswordModal = (props: ChangePasswordModalProps) => {
  const classes = useStyles();
  return (
    <LightModal maxWidth={370} setOpen={props.setShow} open={props.show} showCloseButton>
      <Box className={classes.root}>
        <Typography className={classes.title} variant="h5">Check your email</Typography>
        <Typography className={classes.description} variant="h6">We have sent you an email with link to reset password.</Typography>
        <Box className={classes.buttonsGroup}>
          <Button variant="secondary" onClick={props.onChangePassword} size="sm">Resend</Button>
          <Button variant="primary" onClick={() => props.setShow(false)} size="sm">Done</Button>
        </Box>
      </Box>
    </LightModal>
  );
};

export default ChangePasswordModal;
