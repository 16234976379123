import { makeStyles, OutlinedInput, Select, SelectProps, Theme } from '@material-ui/core';
import ExpandMoreRoundedIcon from '@material-ui/icons/ExpandMoreRounded';
import React, { useCallback, useEffect, useRef, useState } from 'react';

import { Typography } from './Typography';

type LightSelectProps = SelectProps & {
  size?: 'small' | 'medium';
};

const useStyles = makeStyles<Theme, LightSelectProps & { maxHeight: string }>((theme) => ({
  root: {
    borderRadius: '8px',
    padding: 8,
    minHeight: ({ size }) => (size === 'medium' ? '44px' : '36px'),
    display: 'flex',
    alignItems: 'center',
    boxSizing: 'border-box',
  },
  select: {
    borderRadius: '8px',
    '&:focus': {
      backgroundColor: 'inherit',
    },
    '&.Mui-disabled': {
      borderColor: `${theme.palette.grey[100]} !important`,
    },
    '&.Mui-error': {
      borderColor: `${theme.palette.red[200]} !important`,
    },
  },
  component: {
    borderRadius: '8px',
  },
  menuPaper: {
    maxHeight: ({ maxHeight }) => maxHeight,
    border: `1px solid ${theme.palette.grey[200]}`,
    borderRadius: 8,
  },
  menuList: {
    paddingTop: 0,
    paddingBottom: 0,
    '& > *:first-child': {
      borderTop: 'none',
    },
    '& > *:last-child': {
      borderBottom: 'none',
    },
  },
  arrow: {
    transition: 'all 0.3s',
    fill: theme.palette.grey[400],
  },
  open: {
    transform: 'rotate(180deg)',
  },
  value: {
    fontSize: 12,
    lineHeight: '20px',
    fontWeight: 400,
    color: theme.palette.grey[700],
    '&#placeholder': {
      color: theme.palette.grey[400],
    },
  },
  adornedStart: {
    paddingLeft: 8,
  },
}));

const LightSelect = (props: LightSelectProps) => {
  const ref = useRef<HTMLDivElement>(null);

  // Default value from MUI
  const [maxHeight, setMaxHeight] = useState('calc(100% - 96px)');
  const updateMaxHeight = useCallback(() => {
    if (!ref.current) return;

    const rect = ref?.current?.getBoundingClientRect();
    if (rect.bottom > 0) setMaxHeight(`calc(100% - ${rect.bottom}px - 24px)`);
  }, [ref]);

  useEffect(() => {
    if (!window) return;

    // Update maxHeight so that the menu will always stay below the select.
    updateMaxHeight();
    window.addEventListener('scroll', updateMaxHeight);
    window.addEventListener('resize', updateMaxHeight);

    // eslint-disable-next-line consistent-return
    return () => {
      window.removeEventListener('scroll', updateMaxHeight);
      window.removeEventListener('resize', updateMaxHeight);
    };
  }, []);

  const classes = useStyles({ ...props, maxHeight });
  return (
    <Select
      {...props}
      ref={ref}
      variant="outlined"
      native={false}
      IconComponent={ExpandMoreRoundedIcon}
      classes={{ root: classes.root, select: classes.select }}
      className={`${classes.select} ${props.className || ''}`}
      renderValue={props.renderValue || ((value: any) => (
        <Typography className={classes.value} id={value ? undefined : 'placeholder'}>
          {(!value || value.length === 0) ? props.placeholder : value}
        </Typography>
      ))}
      input={<OutlinedInput classes={{ adornedStart: classes.adornedStart }} />}
      MenuProps={{
        ...props.MenuProps,
        PaperProps: { className: classes.menuPaper },
        MenuListProps: { className: classes.menuList },
        elevation: 0,
        anchorOrigin: props.MenuProps?.anchorOrigin || {
          vertical: 'bottom',
          horizontal: 'left',
        },
        transformOrigin: props.MenuProps?.transformOrigin || {
          vertical: -8,
          horizontal: 'left',
        },
        getContentAnchorEl: null,
      }}
    />
  );
};

LightSelect.displayName = 'LightSelect';
export { LightSelect };
