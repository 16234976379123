// @flow
import { Box, createStyles } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import React, { useEffect, useState } from 'react';
import { Redirect } from 'react-router-dom';
import { TrackMyAddressRoute } from 'routes';
import { JwtUser } from 'services/types/jwt';
import { useStore as useUserStore } from 'store/zustand/User';

import { ReportingComponent } from './Reporting';
import { LandingPage } from './TrackMyAddress/LandingPage';

const useStyles = makeStyles(() => createStyles({
  root: {
    width: '100%',
    height: '100%',
    display: 'flex',
    minHeight: '80vh',
    overflow: 'hidden',
    position: 'relative',
    justifyContent: 'center',
  },
  content: {
    width: '100%',
    maxWidth: 1200,
  },
}));

enum TrackMyAddressPageState {
  Front,
  Reporting,
}

const TrackMyAddress = () => {
  const classes = useStyles();
  const [redirectToReporting, setRedirectToReporting] = useState<boolean>(false);
  const [allowFetchingData, setAllowFetchingData] = useState<boolean>(false);
  const [pageState, setPageState] = useState<TrackMyAddressPageState>(TrackMyAddressPageState.Front);
  const fetchUser = useUserStore((state) => state.fetchUser);

  useEffect(() => {
    const getUserData = async () => {
      const userData = localStorage.getItem('user');
      const user = userData ? JSON.parse(userData) as JwtUser : undefined;
      if (user) {
        if (!user.isTmp) setRedirectToReporting(true);
        else setPageState(TrackMyAddressPageState.Reporting);
        setAllowFetchingData(true);
      }
    };
    getUserData();
  }, []);

  const enterWalletCallback = async () => {
    fetchUser(true);
    setAllowFetchingData(true);
    setPageState(TrackMyAddressPageState.Reporting);
  };

  if (redirectToReporting) {
    return <Redirect to="/reporting" />;
  }

  return (
    <>
      <main className={classes.root}>
        {pageState === TrackMyAddressPageState.Front && (
          <LandingPage callbackFnc={enterWalletCallback} />
        )}
        {pageState === TrackMyAddressPageState.Reporting && (
          <Box className={classes.content}>
            <ReportingComponent
              allowFetchingData={allowFetchingData}
              trackMyAddress
            />
          </Box>
        )}
      </main>
    </>
  );
};

TrackMyAddress.routePath = TrackMyAddressRoute;

TrackMyAddress.loginRequired = false;
TrackMyAddress.displayName = 'Track my address';

export { TrackMyAddress };
