import { Box, createStyles } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Button, LightTextField, Typography } from 'elements';
import { LightFrenchFries } from 'elements/LightFrenchFries';
import LightModal from 'elements/LightModal';
import { Scrollable } from 'elements/Scrollable';
import Linkify from 'linkify-react';
import React, { ReactNode, useEffect, useMemo, useState } from 'react';
import SVG from 'react-inlinesvg';
import { Transaction } from 'services/types/user-transaction';
import { WalletInfo } from 'services/types/wallet';
import { useStore as useCoinStore } from 'store/zustand/Coin';
import { useStore as useTransactionStore } from 'store/zustand/Transaction';
import { useStore as useWalletStore } from 'store/zustand/Wallet';

interface Props {
  tx?: Transaction;
  showTxDetails: boolean;
  // eslint-disable-next-line no-unused-vars
  setShowTxDetails: (value: boolean) => void
  allowFetchingData: boolean;
  trackMyAddress?: boolean
}

const useStyles = makeStyles((theme) => createStyles({
  modalRoot: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    position: 'relative',
    minHeight: 0,
    flexGrow: 1,
    [theme.breakpoints.up('sm')]: {
      borderTop: '1px solid',
      borderColor: theme.palette.grey[200],
    },
    '& > *:first-child': {
      borderTop: 'none',
    },
  },
  header: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: 16,
    marginBottom: 12,
    [theme.breakpoints.down('xs')]: {
      justifyContent: 'flex-start',
      margin: 16,
      gap: 8,
    },
  },
  contentRow: {
    display: 'flex',
    width: '100%',
    gap: 0,
    padding: 12,
    borderTop: '1px solid',
    borderColor: theme.palette.grey[200],
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column',
      alignItems: 'flex-start',
    },
  },
  shadeBg: {
    backgroundColor: '#FAFBFF',
  },
  rowTopic: {
    width: '24%',
    display: 'flex',
    flexShrink: 0,
    [theme.breakpoints.down('xs')]: {
      width: 'unset',
    },
  },
  rowValue: {
    width: '76%',
    display: 'flex',
    gap: 6,
    alignItems: 'center',
    [theme.breakpoints.down('xs')]: {
      width: '100%',
      overflow: 'hidden',
    },
  },
  valueText: {
    width: '100%',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  link: {
    textDecoration: 'underline',
    width: '100%',
    overflow: 'hidden',
  },
  addressWrapper: {
    minWidth: 0,
    display: 'flex',
    alignItems: 'center',
    gap: 4,
  },
  note: {
    display: 'flex',
    flexDirection: 'column',
    gap: 12,
    width: '100%',
  },
  addNote: {
    display: 'flex',
    flexWrap: 'nowrap',
    gap: 8,
    cursor: 'pointer',
    alignItems: 'center',
  },
}));

const ContentRow = (
  { topic, shadeBg, children, classes }
    : { topic: string, shadeBg: boolean, children: ReactNode, classes: ReturnType<typeof useStyles> },
) => (
  <Box className={`${classes.contentRow} ${shadeBg ? classes.shadeBg : ''}`}>
    <Box className={classes.rowTopic}>
      <Typography variant="h6" strong={false} paletteColor={500}>{topic}</Typography>
    </Box>
    <Box className={classes.rowValue}>
      {children}
    </Box>
  </Box>
);

export const TxDetails = (props: Props) => {
  const classes = useStyles();

  const { tx, showTxDetails, setShowTxDetails } = props;

  const [coins, fetchCoins] = useCoinStore((state) => [state.coins, state.fetchCoins]);
  const [addressNameMap, fetchAddressBooks, addOrEditNote, removeNote] = useTransactionStore((state) => [
    state.addressNameMap, state.fetchAddressBooks, state.addOrEditNote, state.removeNote]);
  const [walletInfos, fetchWallet] = useWalletStore((state) => [state.walletInfos, state.fetchWallet]);

  const [addressBookMap, setAddressBookMap] = useState<Record<string, string[]>>({});
  const [isEditingNote, setIsEditingNote] = useState(false);
  const [diableSaveNoteButton, setDiableSaveNoteButton] = useState(false);
  const [note, setNote] = useState('');

  useEffect(() => {
    if (props.allowFetchingData) {
      (async () => {
        await Promise.allSettled([fetchCoins(), fetchAddressBooks(false), fetchWallet(false)]);
      })();
    }
  }, [props.allowFetchingData]);

  useEffect(() => {
    if (walletInfos !== undefined) {
      const walletAddressNameMap = convertWalletInfoToAddressBooks(walletInfos);
      const mapFromWalletInfo = props.trackMyAddress ? {} : walletAddressNameMap;
      setAddressBookMap({ ...mapFromWalletInfo, ...addressNameMap });
    }
  }, [addressNameMap, walletInfos]);

  useEffect(() => {
    setNote(tx?.note ?? '');
  }, [tx]);

  useEffect(() => {
    if (!showTxDetails) {
      setIsEditingNote(false);
      setNote(tx?.note ?? '');
    }
  }, [showTxDetails]);

  const handleOnSaveNote = async () => {
    if (tx && tx.note !== note) {
      setDiableSaveNoteButton(true);
      if (note === '') await removeNote(tx.txHash, tx.walletId);
      else await addOrEditNote(tx.txHash, note, tx.walletId);
      setIsEditingNote(false);
      setDiableSaveNoteButton(false);
    }
  };

  const contentData = useMemo(() => {
    const date = new Date(tx?.ts ?? '');
    return [
      {
        topic: 'Reporting address',
        children: (
          <Box className={classes.addressWrapper}>
            {addressBookMap[tx?.direction === 'in' ? tx.toAddress! : tx?.fromAddress!]
              && addressBookMap[tx?.direction === 'in' ? tx.toAddress! : tx?.fromAddress!]
                .reduce((prev: string[], curr) => (prev.includes(curr) ? prev : [...prev, curr]), [])
                .map((ab, key) => (
                  <LightFrenchFries
                    key={key}
                    noShrink
                    autoAssignColor
                    label={ab}
                  />
                ))}
            <Typography variant="h6" paletteColor={700} middleTruncation>
              {tx?.direction === 'in' ? tx.toAddress : tx?.fromAddress}
            </Typography>
          </Box>
        ),
      },
      {
        topic: tx?.direction === 'in' ? 'From' : 'Send to',
        children: (
          <Box className={classes.addressWrapper}>
            {addressBookMap[tx?.direction === 'in' ? tx.fromAddress! : tx?.toAddress!]
              && addressBookMap[tx?.direction === 'in' ? tx.fromAddress! : tx?.toAddress!]
                .reduce((prev: string[], curr) => (prev.includes(curr) ? prev : [...prev, curr]), [])
                .map((ab, key) => (
                  <LightFrenchFries
                    key={key}
                    noShrink
                    autoAssignColor
                    label={ab}
                  />
                ))}
            <Typography variant="h6" paletteColor={700} middleTruncation>
              {`${tx?.reason === 'rewards' ? 'Rewards' : ''} ${tx?.direction === 'in' ? tx.fromAddress : tx?.isGas ? `${tx.chainName} validator for gas` : tx?.toAddress}`}
            </Typography>
          </Box>
        ),
      },
      {
        topic: 'Transaction hash',
        children: (
          <Typography variant="h6" paletteColor={700} className={classes.valueText}>{tx?.txHash}</Typography>
        ),
      },
      {
        topic: 'Date & Time',
        children: (
          <Typography
            variant="h6"
            paletteColor={700}
            className={classes.valueText}
          >
            {`${date.toLocaleDateString('de-AT', { day: '2-digit', month: '2-digit', year: 'numeric' })},
            ${date.toLocaleTimeString(undefined, { hour: 'numeric', minute: 'numeric', second: undefined, hour12: false })}`}
          </Typography>
        ),
      },
      {
        topic: 'Value',
        children: (
          <>
            <Box width={20} height={20} display="flex" flexShrink={0}>
              <SVG
                width={20}
                height={20}
                src={tx?.coinId === 341158999 ? coins['341159068']?.masterCoin?.icon
                  : coins[tx?.coinId.toString() ?? '']?.masterCoin?.icon ?? '/icons/coin_icon/fallback-1.svg'}
              />
            </Box>
            <Typography
              variant="h6"
              paletteColor={700}
              className={classes.valueText}
            >
              {tx ? `${tx.direction === 'out' ? '-' : ''}${tx.quantity} ${tx.ticker}` : ''}
            </Typography>
          </>
        ),
      },
      {
        topic: 'Value in USDT',
        children: (
          <Typography
            variant="h6"
            paletteColor={700}
            className={classes.valueText}
          >
            {tx?.usdAmount !== undefined ? `≈ $${tx.usdAmount.toFixed(2)}` : 'N/A'}
          </Typography>
        ),
      },
      {
        topic: 'Connected Tags',
        children: (
          (tx?.tags && tx?.tags.length > 0) ? tx.tags.map((tag) => (<LightFrenchFries autoAssignColor label={tag.tag} noShrink key={tag.tagId} />))
            : (<Typography variant="h6" paletteColor={700} className={classes.valueText}>-</Typography>)
        ),
      },
      {
        topic: `Price of ${tx?.ticker} in USDT`,
        children: (
          <Typography
            variant="h6"
            paletteColor={700}
            className={classes.valueText}
          >
            {tx?.usdAmount !== undefined && tx.quantity ? `${(tx.usdAmount / tx.quantity).toFixed(4)} USDT` : 'N/A'}
          </Typography>
        ),
      },
      {
        topic: 'Chain name',
        children: (
          <Typography variant="h6" paletteColor={700} className={classes.valueText}>
            {tx?.chainName}
          </Typography>
        ),
      },
      {
        topic: 'Status',
        children: (
          <>
            <Box width={16} height={16} display="flex" flexShrink={0}>
              <SVG
                src={tx?.status ? 'icons/reporting/ic_check.svg' : 'icons/reporting/ic_failed.svg'}
                width={16}
                height={16}
              />
            </Box>
            <Typography variant="h6" paletteColor={700} className={classes.valueText}>
              {tx?.status ? 'Sent' : 'Failed'}
            </Typography>
          </>
        ),
      },
      ...[...(tx?.link ? [{
        topic: 'View tx on chain:',
        children: (
          <a href={tx.link} target="_blank" rel="noopener noreferrer" className={classes.link}>
            <Typography variant="h6" className={classes.valueText}>{tx.link}</Typography>
          </a>
        ),
      },
      ] : [])],
    ];
  }, [tx]);

  return (
    <LightModal
      open={showTxDetails}
      setOpen={setShowTxDetails}
      noPadding
      maxWidth={680}
      fullScreenOnMobile
      showCloseButton
      modalTitle="Transaction details"
    >
      <Scrollable className={classes.modalRoot}>
        {contentData.map((rowData, key) => (
          <ContentRow classes={classes} topic={rowData.topic} key={key} shadeBg={key % 2 === 0}>
            {tx && rowData.children}
          </ContentRow>
        ))}
        <ContentRow classes={classes} topic="Note" shadeBg={contentData.length % 2 === 0}>
          <Box className={classes.note}>
            {isEditingNote ? (
              <>
                <LightTextField
                  fullWidth
                  multiline
                  minRows={3}
                  placeholder="Enter note"
                  value={note}
                  onChange={(e) => setNote(e.target.value)}
                />
                <Box display="flex" justifyContent="flex-end">
                  <Button
                    size="sm"
                    onClick={handleOnSaveNote}
                    disabled={diableSaveNoteButton || (tx?.note ?? '') === note}
                  >
                    Save note
                  </Button>
                </Box>
              </>
            ) : (
              <>
                {note && (
                  <Typography variant="h6" strong={false} paletteColor={600}>
                    <Linkify options={{ target: '_blank', rel: 'noopener noreferrer nofollow' }}>{note}</Linkify>
                  </Typography>
                )}
                <Box
                  className={classes.addNote}
                  onClick={() => setIsEditingNote(true)}
                  justifyContent={note ? 'flex-end' : undefined}
                >
                  <Typography variant="h6" paletteColor={500}>{note ? 'Edit note' : 'Add note'}</Typography>
                  <SVG src="/icons/reporting/ic_write.svg" width={18} height={18} />
                </Box>
              </>
            )}
          </Box>
        </ContentRow>
      </Scrollable>
    </LightModal>
  );
};

export const convertWalletInfoToAddressBooks = (walletInfo: WalletInfo[]): Record<string, string[]> => walletInfo
  .filter((wi) => wi.address !== undefined)
  .reduce((prev: [string, string][], curr) => {
    const currAddressName: [string, string][] = curr.address!.map((a) => [a, curr.name ?? curr.walletProvider]);
    return [...prev, ...currAddressName];
  }, [])
  .reduce((prev: Record<string, string[]>, curr) => {
    const value = prev[curr[0]] ?? [];
    const updatedValue = value.includes(curr[1]) ? value : [...value, curr[1]];
    return { ...prev, [curr[0]]: updatedValue };
  }, {});
