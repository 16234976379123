import React from "react";

import {isDesktop, sleep} from "../../common.js";

import AuthService from "../../services/auth-service";
import BundleService from "../../services/bundle.service";
import CoinService from "../../services/coin.service";
import WalletService from "../../services/wallet-service";

import {Col, Container, Row} from 'react-bootstrap';
import SharedStateComponent from "../../components/SharedStateComponent.js";

import {Loading} from '../../components';
import GenericModal from "../../components/GenericModal.js";

import DepositStatus from "../../components/DepositStatus.js";

import {DashboardRoute, DepositRoute} from '../../routes';

export default class ReviewDeposit extends SharedStateComponent {
  static routePath = '/deposit/deprecated';
  static loginRequired = true;
  static depositDisabledOn = '2022-05-13 at 04:00 AM (UTC)';

  constructor(props) {
    super(props);

    this.getWalletsStatus = this.getWalletsStatus.bind(this);
    this.beginInvestment = this.beginInvestment.bind(this);
    this.changeButton = this.changeButton.bind(this);

    this.state = {
      txStatus: [],
      timeLeft: 0,
      depositStatus: "--",
      balance: "--",
      buttonText: "copy wallet address"
    };
  }

  changeButton(e) {
    e.preventDefault();
    navigator.clipboard.writeText(this.state.address)
    if (this.state.buttonText !== "copied!")
      this.setState({buttonText: "copied!"})
    sleep(2000).then(x => this.setState({buttonText: "copy wallet address"}))
  }

  getWalletsStatus() {
    this.interval = setInterval(() => {
      if (this.state.timeLeft == 0) {
        this.setState({timeLeft: -1})
        WalletService.getWalletStatus()
          .then(txStatuses => {
            var txStatus = txStatuses.find(txStatus => txStatus.wallet.chainId === "56")
            if (this.state.wallet.riskScore === 0) {
              txStatus = txStatuses.find(txStatus => txStatus.wallet.chainId === "terra-mainnet")
            }
            this.setState({
              txStatus,
              txStatuses,
              balance: txStatus.balance,
              usdtBalance: txStatus.usdtBalance,
              balanceInt: txStatus.balanceInt,
              depositStatus: txStatus.depositStatus,
              timeLeft: 30
            })
          }, error => {
            this.setState({
              txStatus: [{}],
              txStatuses: [],
              depositStatus: "One moment please...",
              timeLeft: 30,
              balance: "--",
              balanceInt: 0,
              usdtBalance: 0
            })
          })
      } else if (this.state.timeLeft > 0) {
        this.setState({timeLeft: this.state.timeLeft - 1})
      }
    }, 1000);

  }

  beginInvestment(e) {
    e.preventDefault();
    WalletService.beginInvestment(this.state.wallet.walletId).then(resp => {
      this.props.history.push(DashboardRoute)
    })
  }

  componentDidMount() {
    const wallet = WalletService.getWallet()
    const dexWallets = WalletService.getDexWallets()
    const allBundlesPromise = BundleService.getAllBundles();
    const userInfo = AuthService.getUserInfo().then(user => {
      user.data.email = AuthService.getCurrentUser().email;
      return user
    })
    const countryList = AuthService.getCountryList().then(countries => {
      countries.data.sort((a, b) => (a.name < b.name) ? -1 : 1)
      return countries
    })
    const allCoins = CoinService.getCoins();

    Promise.all([wallet, dexWallets, allBundlesPromise, userInfo, countryList, allCoins]).then(arr => {
      let wallet = arr[0].data;
      let wallets = arr[1].data;
      let allBundlesNoFilter = arr[2].data;
      let form = arr[3].data
      let countries = arr[4].data
      let coins = arr[5].data
      let allBundles = BundleService.filterBundles(allBundlesNoFilter, 4)
      let nearestBundle = BundleService.nearestBundle(allBundles, wallet.riskScore);
      var address;
      if (wallet.riskScore === 0) {
        address = wallets.find(wallet => wallet.chainId === "terra-mainnet").address;
      } else {
        address = wallets.find(wallet => wallet.chainId === "56").address;
      }

      this.setState({
        wallets,
        address,
        wallet,
        nearestBundle,
        form,
        countries,
        coins,
      }, this.getWalletsStatus);
    })
  }

  renderWithSharedState() {
    if (!this.state.wallets || !this.state.wallet) {
      return <Loading />;
    }

    const haveBalance = this.state.usdtBalance >= 100;
    const enoughMoney = this.state.usdtBalance >= 1000;

    if (haveBalance && this.state.wallet.exchangeId !== 4) {
      const exchange = WalletService.addExchangeId(4)
      const assignNewBundle = BundleService.addUserBundle(this.state.nearestBundle.bundleId)
      if (enoughMoney) {
        Promise.all([exchange, assignNewBundle]).then(() => {
          WalletService.beginInvestment(this.state.wallet.walletId)
        })
      }
    }

    return (
      <>
        <div className="responsive">
          <Container className="px-4 pb-3">
            <Row>
              <Col>
                <p className={isDesktop() ? "p1 mb-1 text-center" : "p1 mb-1 mt-3 text-center"}>Fund your wallet</p>
              </Col>
            </Row>
            <Row>
              <Col>
                <p className="p1 mt-3 mb-1 text-center">Deposit & Transfer Status</p>
                <p className="mb-0 text-center">Transferring crypto takes a few minutes to appear depending on network
                  traffic.</p>
              </Col>
            </Row>
            <Row>
              <Col>
                <Container className="content-card p-4">
                  <DepositStatus
                    timeLeft={this.state.timeLeft}
                    balance={this.state.balance}
                    depositStatus={this.state.depositStatus}
                    address={this.state.address}
                    txStatuses={this.state.txStatuses}
                    coins={this.state.coins}
                  />
                </Container>
              </Col>
            </Row>

            {enoughMoney || !haveBalance ?
              <Row className="mt-3 text-center">
                <Col>
                  <button className={haveBalance ? "btn-first btn-block" : "btn-inverse btn-block"}
                          disabled={!haveBalance} onClick={() => this.props.history.push(DashboardRoute)}>
                    Back to dashboard
                  </button>
                </Col>
              </Row>
              :
              <>
                <Row className="mt-3">
                  <Col>
                    <p className="grey p7">Your account value is less than our recommended 1000 USD for rebalancing,
                      which could result in higher network fees</p>
                    <p className="grey p7">Estimated network fees: 1.5 - 2.5 USDC</p>
                    <button className="btn-first btn-block" onClick={() => this.setState({triggerModal: true})}>
                      Rebalance my portfolio!
                    </button>
                  </Col>
                </Row>
                <Row>
                  <Col className="text-center">
                    <a onClick={() => this.props.history.push(DashboardRoute)}>
                      I’ll wait for a higher balance
                    </a>
                  </Col>
                </Row>
              </>
            }
          </Container>
        </div>

        <GenericModal name="triggerModal">
          <div className="text-center">
            <h5>Everything is ready</h5>
            <p className="mx-auto mb-4">This process will take few minutes. It will rebalance your BNB assets and it
              will resume your rebalacing.</p>
          </div>
          <button className="btn-first btn-block" onClick={this.beginInvestment}>Start rebalancing</button>
        </GenericModal>
      </>
    );
  }
}
