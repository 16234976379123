import { Box, createStyles, makeStyles } from '@material-ui/core';
import { Typography } from 'elements';
import React from 'react';
import SVG from 'react-inlinesvg';
import { WalletLevel } from 'services/types/wallet-enum';

interface Props {
  isActive?: boolean;
  level?: WalletLevel;
  size?: 'sm' | 'md' | 'xmd';
}

type UseStylesProps = Pick<Props, 'isActive' | 'size' | 'level' >

const useStyles = makeStyles((theme) => createStyles({
  root: {
    display: 'flex',
    alignItems: 'center',
    gap: 4,

    width: 'fit-content',

    padding: ({ size }: UseStylesProps) => (size === 'sm' ? '0px 8px' : size === 'md' ? '0px 4px 0px 8px' : '4px 12px'),
    borderRadius: 6,
    background: ({ isActive, level }) => (isActive && level === WalletLevel.Private ? theme.palette.grey[800]
      : isActive ? 'white'
        : level === WalletLevel.Green ? 'radial-gradient(106.18% 135.98% at -19.09% 93.45%, #3DEACB 0%, #02BD9F 92.07%)'
          : level === WalletLevel.Bronze ? 'radial-gradient(93.41% 117.68% at 12.23% 65.77%, #513D36 0%, #D2B9AA 100%)'
            : level === WalletLevel.Silver ? 'radial-gradient(94.05% 134.3% at 5.95% 50.3%, #505D83 0%, #9AA7CB 100%)'
              : level === WalletLevel.Gold ? 'radial-gradient(96.11% 128.16% at 5.95% 50.3%, #A77800 6.25%, #F4D598 100%)'
                : theme.palette.grey[800]),
  },
  iconWrapper: { width: 16, height: 16, display: 'flex' },
  icon: {
    display: 'flex',
    '& path': {
      '--icon_color': ({ level, isActive }: UseStylesProps) => (!isActive ? 'white'
        : level === WalletLevel.Green ? theme.palette.green[600]
          : level === WalletLevel.Bronze ? '#715C54'
            : level === WalletLevel.Silver ? theme.palette.grey[500]
              : level === WalletLevel.Gold ? theme.palette.yellow[600]
                : 'white'),
    },
  },
  typography: {
    color: ({ level, isActive }) => (!isActive ? 'white'
      : level === WalletLevel.Green ? theme.palette.green[600]
        : level === WalletLevel.Bronze ? '#715C54'
          : level === WalletLevel.Silver ? theme.palette.grey[500]
            : level === WalletLevel.Gold ? theme.palette.yellow[600]
              : 'white'),
    fontSize: 12,
    lineHeight: '20px',
    fontWeight: 500,
    [theme.breakpoints.up('md')]: {
      fontSize: 16,
      lineHeight: '24px',
    },
  },
}));

export const LevelBadge = ({ size, isActive, level }: Props) => {
  const classes = useStyles({ isActive, size, level });

  const icon = level === WalletLevel.Green ? 'icons/account_level/ic_green.svg'
    : level === WalletLevel.Bronze ? 'icons/account_level/ic_bronze.svg'
      : level === WalletLevel.Silver ? 'icons/account_level/ic_silver.svg'
        : level === WalletLevel.Gold ? 'icons/account_level/ic_gold.svg'
          : 'icons/account_level/ic_private.svg';

  return (
    <Box className={classes.root}>
      <Typography id="account-level" variant={size === 'sm' ? 'h6' : 'h5'} className={classes.typography}>
        {(level ?? '').toString().split(' ')[0]}
      </Typography>
      <Box className={classes.iconWrapper}>
        <SVG src={icon} className={classes.icon} />
      </Box>
    </Box>
  );
};
