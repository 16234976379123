import {v4 as uuidv4} from "uuid";

export default class Headers {
  static def() {
    return { 'X-Request-ID':  uuidv4() }
  }

  static auth() {
    let user = JSON.parse(localStorage.getItem('user'));
    if (user && user.version !== 3) {
      localStorage.removeItem('user');
      user = null;
    }

    if (user && user.accessToken) {
      return { Authorization: 'Bearer ' + user.accessToken }; // for Spring Boot back-end
    } else {
      return {};
    }
  }
}
