import { Box, makeStyles } from '@material-ui/core';
import { Typography } from 'elements';
import { PortfolioCoin } from 'models';
import React, { FC } from 'react';
import SVG from 'react-inlinesvg';
import { palette } from 'theme';
import { prettyNumber, significantNumber } from 'utils';

type CoinPercentageRowProps = {
  className?: string;
  valueMode: 'percentage' | 'actual';
  hasTarget: boolean;
  coin: PortfolioCoin;
  showColor?: boolean;
};

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    columnGap: 8,
    '&#click': {
      cursor: 'pointer',
    },
  },
  token: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    columnGap: 8,
    minWidth: 0,
    '& svg': {
      flexShrink: 0,
      borderRadius: 20,
    },
  },
  versus: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    columnGap: 4,
  },
  versusText: {
    fontSize: 12,
    fontWeight: 500,
    lineHeight: '24px',
  },
  chart: { margin: '-24px -8px -32px -28px' },
  greenText: { color: theme.palette.green['500'] },
  greyText: { color: theme.palette.grey['500'] },
  purpleText: { color: theme.palette.purple['500'] },
}));

const CoinPercentageRow: FC<CoinPercentageRowProps> = ({ className, valueMode, coin, showColor, hasTarget }) => {
  const classes = useStyles();
  return (
    <Box
      className={`${classes.root} ${className ?? ''}`}
      onClick={coin.onClick}
      id={coin.onClick ? 'click' : undefined}
    >
      <Box className={classes.token}>
        {showColor && (
          <Box width={8} height={8} bgcolor={coin.color ?? palette.green['500']} borderRadius={4} />
        )}
        <SVG src={coin.icon ?? '/icons/coin_icon/fallback-1.svg'} width={24} height={24} />
        <Box overflow="hidden">
          <Typography noWrap variant="h5" paletteColor={800}>{coin.name}</Typography>
        </Box>
      </Box>
      <Box className={classes.versus}>
        <Typography className={`${classes.versusText} ${classes.greenText}`}>
          {valueMode === 'percentage' ? (
            <>{prettyNumber(coin.actualPercentage, { maximumFractionDigits: 2 })}%</>
          ) : (
            <></>
          )}
        </Typography>
        <Typography className={`${classes.versusText} ${classes.greyText}`}>
          {valueMode === 'percentage' && hasTarget ? 'vs.' : ''}
        </Typography>
        <Typography
          className={`${classes.versusText} ${valueMode === 'percentage' ? classes.purpleText : classes.greyText}`}
        >
          {valueMode === 'percentage' ? (
            hasTarget ? <>{prettyNumber(coin.targetPercentage, { maximumFractionDigits: 2 })}%</> : <></>
          ) : (
            <>{significantNumber(coin.price, { maximumFractionDigits: 2 })}</>
          )}
        </Typography>
      </Box>
    </Box>
  );
};

CoinPercentageRow.displayName = 'CoinPercentageRow';
export { CoinPercentageRow };
