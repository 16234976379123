import { Box, CircularProgress, createStyles, Hidden, Snackbar, useMediaQuery, useTheme } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Pagination } from '@material-ui/lab';
import { Loading } from 'components';
import { PortfolioValueCard } from 'components/dashboard';
import DataFrame from 'dataframe-js';
import { Card, Typography } from 'elements';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import SVG from 'react-inlinesvg';
import { ReportingRoute } from 'routes';
import { Tag } from 'services/types/tag';
import { Transaction, UserAtomicTransaction } from 'services/types/user-transaction';
import { UsdBalanceTs } from 'services/types/wallet';
import { useStore as useCoinStore } from 'store/zustand/Coin';
import { useStore as useFeatureStore } from 'store/zustand/Feature';
import { gasFeeTag, rewardTag, useStore as useTransactionStore } from 'store/zustand/Transaction';
import { useStore as useUserStore } from 'store/zustand/User';
import { useStore as useWalletStore } from 'store/zustand/Wallet';
import { utils, writeXLSX } from 'xlsx';

import RebalancingStatusComponent, { shouldShowRebalancingStatus } from '../../../components/RebalancingStatus';
import { AddAddressBookModal } from './AddAddressBookModal';
import { AddressBookModal } from './AddressBookModal/AddressBookModal';
import { AddressInput } from './AddressInput';
import { AddTagModal } from './AddTagModal';
import { AddressBookOption, FilterAndSorting, SortingOption } from './FilterAndSorting';
import { ManageTagModal } from './ManageTagModal/ManageTagModal';
import { SummarySection } from './SummarySection';
import { AddAddressModal } from './TrackMyAddress/AddAddressModal';
import { convertWalletInfoToAddressBooks, TxDetails } from './TxDetails';
import { TxRow } from './TxRow';

const useStyles = makeStyles((theme) => createStyles({
  root: {
    width: '100%',
    height: '100%',
    padding: 24,
    display: 'flex',
    backgroundColor: 'white',
    [theme.breakpoints.down('sm')]: {
      padding: 0,
    },
  },
  headerRow: {
    display: 'grid',
    gridTemplateColumns: '1fr 316px',
    gap: 16,
    [theme.breakpoints.down('sm')]: {
      gridTemplateColumns: '1fr',
      paddingTop: 12,
      gap: 12,
    },
  },
  details: {
    display: 'flex',
    flexDirection: 'column',
    gap: 16,
    flexGrow: 1,
  },
  portfolioValue: {
    height: '100%',
    borderRadius: 16,
    [theme.breakpoints.down('sm')]: {
      borderRadius: 8,
      height: 104,
    },
  },
  noDataRoot: {
    width: '100%',
    height: '100%',
    padding: 24,
    backgroundColor: 'white',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    [theme.breakpoints.down('sm')]: {
      padding: '16px 0',
    },
  },
  columnTitleWrapper: {
    backgroundColor: '#FAFBFF',
  },
  main: {
    gap: 16,
    display: 'flex',
    flexDirection: 'column',
  },
  card: {
    display: 'flex',
    flexDirection: 'column',
    borderRadius: 16,
    [theme.breakpoints.down('sm')]: {
      borderRadius: 0,
    },
  },
  amountTitle: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  pagination: {
    alignSelf: 'center',
    padding: '16px 0',
    display: 'flex',
    alignItems: 'flex-end',
    flexGrow: 1,
    '& .Mui-selected': {
      color: 'white',
    },
  },
  table: {
    tableLayout: 'fixed',
    width: '100%',
    '& tr': {
      '& > :nth-child(1)': { width: '40%' },
      '& > :nth-child(2)': { width: '15%' },
      '& > :nth-child(3)': { width: '15%' },
      '& > :nth-child(4)': { width: '15%' },
      '& > :nth-child(5)': { width: '15%', paddingRight: 14, textAlign: 'right' },
      borderTop: '1px solid',
      borderTopColor: theme.palette.grey[200],
      [theme.breakpoints.down('sm')]: {
        '& > :nth-child(1)': { width: '60%' },
        '& > :nth-child(2)': { width: '40%', paddingRight: 14, textAlign: 'right' },
        '& > :nth-child(3)': 'unset',
        '& > :nth-child(4)': 'unset',
      },
    },
    '& td': { padding: '12px 0px 12px 16px', verticalAlign: 'top' },
    '& th': { padding: '12px 0px 12px 16px' },
  },
  loading: {
    width: '100%',
    position: 'absolute',
    top: 0,
    left: 0,
    zIndex: 2,
    background: 'white',
  },
  icon: {
    width: '100%',
    height: '100%',
  },
  columnTitleWithSubtitle: {
    display: 'flex',
    alignItems: 'center',
    gap: 4,
  },
  columnSubtitle: {
    fontWeight: 400,
    color: theme.palette.grey[400],
    flexShrink: 0,
  },
  circularProgress: {
    marginBottom: -2,
    marginLeft: 4,
  },
  filterCard: {
    zIndex: 1,
    borderRadius: 16,
    [theme.breakpoints.down('sm')]: {
      boxShadow: '0px -4px 32px rgba(58, 60, 77, 0.08), 0px 4px 32px rgba(58, 60, 77, 0.04)',
      position: 'sticky',
      bottom: 0,
      padding: 0,
    },
  },
  copied: {
    display: 'flex',
    alignItems: 'center',
    gap: 8,
    backgroundColor: theme.palette.common.white,
    padding: 8,
    borderRadius: 8,
    border: `1px solid ${theme.palette.green[600]}`,
    boxShadow: '0px 4px 32px rgba(58, 60, 77, 0.1)',
  },
}));

const txPerPage = 12;

interface Props {
  allowFetchingData: boolean;
  trackMyAddress?: boolean;
}

const ReportingComponent = ({ allowFetchingData, trackMyAddress }: Props) => {
  const classes = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const [user] = useUserStore((state) => [state.user, state.fetchUser]);
  const [coins, fetchCoins] = useCoinStore((state) => [state.coins, state.fetchCoins]);
  const [txs, count, fetchTxs, addressNameMap, tags, notes] = useTransactionStore((state) => [
    state.txs, state.count, state.fetchTxs, state.addressNameMap,
    state.tags, state.notes, state.addTag, state.removeTag, state.addTagName,
  ]);
  const [
    wallet, walletInfos, fetchWallet, getWalletUsdBalance, rebalancingStatus, fetchRebalancingStatus,
  ] = useWalletStore((state) => [
    state.wallet, state.walletInfos, state.fetchWallet,
    state.getWalletsUsdBalance, state.rebalancingStatus, state.fetchRebalancingStatus,
  ]);

  const [addressBookMap, setAddressBookMap] = useState<Record<string, string[]>>({});
  const [dataFrame, setDataFrame] = useState<DataFrame | null>(null);
  const [filteredDataFrame, setFilteredDataFrame] = useState<DataFrame | null>(null);
  const [filteredTxs, setFilteredTxs] = useState<Transaction[]>([]);
  const [trackAddress, setTrackAddress] = useState<string[]>([]);
  const [showTxDetails, setShowTxDetails] = useState<boolean>(false);
  const [txDetailsContent, setTxDetailsContent] = useState<Transaction | undefined>(undefined);
  const [initAddress, setInitAddress] = useState<string | undefined>(undefined);
  const [initAddressName, setInitAddressName] = useState<string | undefined>(undefined);
  const [initWalletId, setInitWalletId] = useState<number | undefined>(undefined);
  const [editAddressBookId, setEditAddressBookId] = useState<number | undefined>(undefined);
  const [showAddAddressBookModal, setShowAddAddressBookModal] = useState<boolean>(false);
  const [showAddressBookModal, setShowAddressBookModal] = useState<boolean>(false);
  const [filteredAddressBooks, setFilteredAddressBooks] = useState<AddressBookOption[]>([]);
  const [initTx, setInitTx] = useState<UserAtomicTransaction | undefined>(undefined);
  const [showAddTagModal, setShowAddTagModal] = useState<boolean>(false);
  // eslint-disable-next-line no-unused-vars
  const [addTagCallbackFnc, setAddTagCallbackFnc] = useState<(tagId: number, tag: string) => void>(() => { });
  const [selectAddress, setSelectAddress] = useState<{ [address: string]: boolean }>({});
  const [showManageTagModal, setShowManageTagModal] = useState<boolean>(false);
  const [isFetching, setIsFetching] = useState<boolean>(true);
  const [txsCount, setTxsCount] = useState<number>(0);
  const [filteredDate, setFilteredDate] = useState<{ startDate: Date, endDate: Date } | undefined>(undefined);
  const [filteredTags, setFilteredTags] = useState<number[]>([]);
  const [page, setPage] = useState<number>(1);
  const [openCopiedSnackBar, setOpenCopiedSnackBar] = useState<boolean>(false);
  const [showAddAddressModal, setShowAddAddressModal] = useState<boolean>(false);
  const [features, fetchFeatures] = useFeatureStore((state) => [state.features, state.fetchFeatures]);
  const [assetHolding, setAssetHolding] = useState<UsdBalanceTs | undefined>();
  const [selectedSortingOption, setSelectedSortingOption] = useState<SortingOption>(SortingOption.DateNewToOld);

  useEffect(() => {
    fetchFeatures();

    if (window.Tawk_API && window.Tawk_API.hideWidget) window.Tawk_API.hideWidget();
    return function cleanup() {
      if (window.Tawk_API && window.Tawk_API.showWidget) window.Tawk_API.showWidget();
    };
  }, []);

  useEffect(() => {
    let b: ReturnType<typeof setTimeout>;
    if (trackMyAddress) {
      setIsFetching(true);
      const a = () => setTimeout(() => {
        (async () => {
          await fetchTxs(true);
          if (count !== txsCount) {
            b = a();
            setTxsCount(count);
          } else {
            setIsFetching(false);
          }
        })();
      }, 60000);
      b = a();
    }
    return () => clearTimeout(b);
  }, [user]);

  useEffect(() => {
    if (features?.trackMyAddress === true) {
      const addresses = walletInfos
        ?.filter((wi) => wi.status === 'Active')
        .map((wi) => wi.address ?? []).flat() ?? [];
      setTrackAddress(addresses);
      setSelectAddress(Object.fromEntries(addresses.map((a) => [a.toLowerCase(), true])));
    }
  }, [walletInfos, wallet, features]);

  useEffect(() => {
    if (txs && txs.length > 0) {
      const df = new DataFrame(txs, ['ts', 'chainName', 'status', 'coinId', 'ticker', 'quantity', 'usdAmount',
        'fromAddress', 'toAddress', 'txHash', 'link', 'direction', 'isGas', 'tags', 'reason', 'walletId']);
      const notesDf = new DataFrame(notes, ['note', 'txHash', 'walletId']);
      const joinedDF = df.join(notesDf, ['txHash', 'walletId'], 'left');
      setDataFrame(joinedDF);
    }
  }, [txs, notes]);

  useEffect(() => {
    if (dataFrame != null) {
      const selectAddresses = Object.keys(selectAddress).filter((k) => selectAddress[k]);
      const endDateForFiltering = moment(filteredDate?.endDate).add(1, 'd').toDate();
      const filteredAddresses = filteredAddressBooks.map((addressBook) => addressBook.address);

      const sortingConfig = {
        [SortingOption.DateNewToOld]: { column: 'ts', reverse: true },
        [SortingOption.DateOldToNew]: { column: 'ts', reverse: false },
        [SortingOption.AmountHighToLow]: { column: 'usdAmount', reverse: true },
        [SortingOption.AmountLowToHigh]: { column: 'usdAmount', reverse: false },
      };

      const selectedSortingConfig = sortingConfig[selectedSortingOption];

      const fdf: DataFrame = (dataFrame as any)
        .map((row: any) => row.set(
          'tags',
          row
            .get('tags')
            .concat(row.get('isGas') === true ? [gasFeeTag] : [])
            .concat(row.get('reason') === 'rewards' ? [rewardTag] : [])
            .sort((a: Tag, b: Tag) => a.tagId - b.tagId),
        ))
        .filter((row: any) => (selectAddresses
          .includes(row.get('fromAddress')) || selectAddresses.includes(row.get('toAddress')))
          && (filteredAddresses.includes(row.get('fromAddress')) || filteredAddresses.includes(row.get('toAddress'))
            || filteredAddressBooks.length === 0))
        .filter((row: any) => {
          const txDate = new Date(row.get('ts'));
          return filteredDate == null
            ? true : txDate > filteredDate.startDate && txDate < endDateForFiltering;
        })
        .filter((row: any) => {
          if (filteredTags.length === 0) return true;
          const ft = new Set(filteredTags);
          return new Set(
            [...row.get('tags')].filter((x) => ft.has(x.tagId)),
          ).size > 0;
        })
        .sortBy(selectedSortingConfig.column, selectedSortingConfig.reverse);

      setFilteredDataFrame(fdf);
      setFilteredTxs(fdf.toCollection());
    }
  }, [dataFrame, filteredDate, selectAddress, filteredAddressBooks, filteredTags, notes, selectedSortingOption]);

  useEffect(() => {
    setPage(1);
  }, [selectAddress, filteredDate]);

  useEffect(() => {
    if (allowFetchingData) {
      (async () => {
        await Promise.allSettled([fetchTxs(true), fetchCoins(), fetchWallet(false), fetchRebalancingStatus()]);
      })();
    }
  }, [allowFetchingData]);

  useEffect(() => {
    if (user != null && features?.trackMyAddress === true) {
      const addresses = walletInfos
        ?.filter((wi) => wi.status === 'Active')
        .map((wi) => wi.address ?? []).flat() ?? [];
      setSelectAddress(Object.fromEntries(addresses.map((a) => [a.toLowerCase(), true])));
    }
  }, [user]);

  useEffect(() => {
    if (walletInfos !== undefined) {
      const walletAddressNameMap = convertWalletInfoToAddressBooks(walletInfos);
      const mapFromWalletInfo = trackMyAddress ? {} : walletAddressNameMap;
      setAddressBookMap({ ...mapFromWalletInfo, ...addressNameMap });
    }
  }, [walletInfos, addressNameMap]);

  useEffect(() => {
    (async () => {
      const res = await getWalletUsdBalance(Date.now());
      setAssetHolding(res);
    })();
  }, []);

  const tableMemo = React.useMemo(() => (
    <table className={classes.table}>
      <thead>
        <tr className={classes.columnTitleWrapper}>
          <th className={classes.columnTitleWithSubtitle}>
            <Typography variant="h4" palette="grey" paletteColor={500}>Events</Typography>
            <Typography variant="h6" className={classes.columnSubtitle}>
              {filteredTxs.length} transactions {trackMyAddress && isFetching && (
                <><CircularProgress size={14} className={classes.circularProgress} /> Fetching data...</>)}
            </Typography>
          </th>
          <Hidden smDown>
            <th>
              <Typography variant="h4" palette="grey" paletteColor={500}>Tag</Typography>
            </th>
            <th>
              <Typography variant="h4" palette="grey" paletteColor={500}>Status</Typography>
            </th>
            <th>
              <Typography variant="h4" palette="grey" paletteColor={500}>Date</Typography>
            </th>
          </Hidden>
          <th>
            <Typography variant="h4" palette="grey" paletteColor={500} className={classes.amountTitle}>
              Amount
            </Typography>
          </th>
        </tr>
      </thead>
      {filteredTxs && filteredTxs.length > 0 && (
        <tbody>
          {filteredTxs && filteredTxs
            .slice(txPerPage * (page - 1), txPerPage * (page - 1) + txPerPage)
            .map((tx, key) => (
              <TxRow
                fromAddressBooks={addressBookMap[tx.fromAddress ?? '']}
                toAddressBooks={addressBookMap[tx.toAddress ?? '']}
                dataFrame={dataFrame}
                setAddTagCallbackFnc={setAddTagCallbackFnc}
                setDataFrame={setDataFrame}
                setFilteredTxs={setFilteredTxs}
                setInitAddress={setInitAddress}
                setInitAddressName={setInitAddressName}
                setInitWalletId={setInitWalletId}
                setInitTx={setInitTx}
                setOpenCopiedSnackBar={setOpenCopiedSnackBar}
                setShowAddAddressBookModal={setShowAddAddressBookModal}
                setShowAddTagModal={setShowAddTagModal}
                setShowTxDetails={setShowTxDetails}
                setTxDetailsContent={setTxDetailsContent}
                tags={tags}
                tx={tx}
                key={key}
              />
            ))}
        </tbody>
      )}
    </table>
  ), [filteredTxs, page, isMobile, addressBookMap, isFetching, coins]);

  const exportDataFrame = (df: DataFrame) => {
    const coinDf = new DataFrame(Object.values(coins), ['coinId', 'symbol']);
    let dfd: DataFrame = (df as any)
      .join(coinDf, 'coinId')
      .drop('coinId')
      .drop('direction')
      .rename('isGas', 'type')
      .map((row: any) => row.set('type', row.get('type') === true ? 'gas' : 'transaction'))
      .map((row: any) => row.set('status', row.get('status') === true ? 'success' : 'fail'))
      .sortBy('ts', true)
      .select('ts', 'chainName', 'symbol', 'type', 'status', 'quantity', 'usdAmount', 'fromAddress',
        'toAddress', 'txHash', 'link', 'tags');

    // eslint-disable-next-line no-shadow
    const tags = Array.from(new Set(dfd.toArray('tags').flat()));
    tags.forEach((tag) => {
      dfd = (dfd as any).map((row: any) => row.set(tag.tag, row.get('tags').map((t: any) => t.tagId).indexOf(tag.tagId) > -1));
    });
    return dfd.drop('tags');
  };

  const generateCsv = async () => {
    if (filteredDataFrame != null) {
      const csvFormatDataFrame = exportDataFrame(filteredDataFrame);

      downloadText('export.csv', csvFormatDataFrame.toCSV(true));
    }
  };

  const generateXlsx = async () => {
    if (filteredDataFrame != null) {
      const df = exportDataFrame(filteredDataFrame);

      const wb = utils.book_new();
      const ws = utils.json_to_sheet(df.toCollection());
      utils.book_append_sheet(wb, ws);

      downloadBase64('export.xlsx', writeXLSX(wb, { type: 'base64' }));
    }
  };

  const downloadText = (filename: string, text: string) => {
    const element = document.createElement('a');
    element.setAttribute('href', `data:text/plain;charset=utf-8,${encodeURIComponent(text)}`);
    element.setAttribute('download', filename);

    element.style.display = 'none';
    document.body.appendChild(element);

    element.click();

    document.body.removeChild(element);
  };

  const downloadBase64 = (filename: string, base64: string) => {
    const element = document.createElement('a');
    element.setAttribute('href', `data:text/plain;base64,${base64}`);
    element.setAttribute('download', filename);

    element.style.display = 'none';
    document.body.appendChild(element);

    element.click();

    document.body.removeChild(element);
  };

  const portfolioValue = React.useMemo(() => {
    const selectedAddress = Object.entries(selectAddress)
      .filter(([, isSelected]) => isSelected)
      .map(([address]) => address);
    const value = assetHolding
      ?.usdBalances
      .filter((b) => selectedAddress.includes(b.address))
      .reduce((prev, curr) => (prev + curr.balance), 0);
    return (
      <PortfolioValueCard
        className={classes.portfolioValue}
        disableButtons
        value={value ?? 0}
        title="Total Holdings in Addresses"
        isLoading={assetHolding === undefined}
      />
    );
  }, [selectAddress, assetHolding]);

  const filterCard = React.useMemo(() => (
    <Card className={classes.filterCard}>
      <FilterAndSorting
        filteredAddressBooks={filteredAddressBooks}
        filteredDate={filteredDate}
        filteredTags={filteredTags}
        setFilteredAddressBooks={setFilteredAddressBooks}
        setFilteredDate={setFilteredDate}
        setFilteredTags={setFilteredTags}
        generateCsv={generateCsv}
        generateXlsx={generateXlsx}
        onEditAddressBook={() => { setShowAddressBookModal(true); }}
        onEditTag={() => { setShowManageTagModal(true); }}
        selectedSortingOption={selectedSortingOption}
        setSelectedSortingOption={setSelectedSortingOption}
      />
    </Card>
  ), [filteredDate, filteredTags, filteredAddressBooks, filteredDataFrame, selectedSortingOption]);

  if (txs === undefined && allowFetchingData) return (<Loading className={classes.loading} />);

  return (
    <Box className={classes.root}>
      <Box className={classes.main}>
        {shouldShowRebalancingStatus(rebalancingStatus) && rebalancingStatus === 'reporting-address' && (
          <RebalancingStatusComponent status={rebalancingStatus} />
        )}
        <Box className={classes.headerRow}>
          <Card className={classes.card} gridGap={12} minWidth={0}>
            <Typography variant="h5" paletteColor={700}>Addresses to view transactions</Typography>
            <AddressInput
              selectAddress={selectAddress}
              setSelectAddress={setSelectAddress}
              setShowAddAddressModal={setShowAddAddressBookModal}
              setTrackAddress={setTrackAddress}
              trackAddress={trackAddress}
              trackMyAddress={trackMyAddress}
            />
            <Hidden mdUp>
              {portfolioValue}
            </Hidden>
          </Card>
          <Hidden smDown>
            {portfolioValue}
          </Hidden>
        </Box>
        <Box className={classes.details}>
          <Hidden smDown>
            {filterCard}
          </Hidden>
          <Card noPadding overflow="hidden" className={classes.card} flexGrow={1}>
            <SummarySection
              filteredTxs={filteredTxs}
              filteredDate={filteredDate}
              selectAddress={selectAddress}
            />
            {tableMemo}
            {filteredTxs.length === 0 && (
              <Box className={classes.noDataRoot}>
                <Typography variant="h1" paletteColor={300}>No Data</Typography>
              </Box>
            )}
            <Pagination
              color="primary"
              className={classes.pagination}
              count={Math.ceil((filteredTxs.length) / txPerPage)}
              page={page}
              onChange={(ce, p) => {
                setPage(p);
                window.scrollTo({
                  left: 0,
                  top: 0,
                });
              }}
            />
          </Card>
        </Box>
        <Hidden mdUp>
          {filterCard}
        </Hidden>
      </Box>
      <TxDetails
        showTxDetails={showTxDetails}
        setShowTxDetails={setShowTxDetails}
        tx={txDetailsContent}
        allowFetchingData={allowFetchingData}
        trackMyAddress={trackMyAddress}
      />
      <AddAddressBookModal
        showModal={showAddAddressBookModal}
        setShowModal={setShowAddAddressBookModal}
        initAddress={initAddress}
        setInitAddress={setInitAddress}
        initTag={initAddressName}
        setInitTag={setInitAddressName}
        editAddressBookId={editAddressBookId}
        setEditAddressBookId={setEditAddressBookId}
        initWalletId={initWalletId}
        setInitWalletId={setInitWalletId}
      />
      <AddressBookModal
        showModal={showAddressBookModal}
        setShowModal={setShowAddressBookModal}
        showAddAddressBookModal={() => { setShowAddAddressBookModal(true); }}
        setInitAddress={setInitAddress}
        setInitTag={setInitAddressName}
        setEditAddressBookId={setEditAddressBookId}
        setInitWalletId={setInitWalletId}
        allowFetchingData={allowFetchingData}
        setOpenCopiedSnackBar={setOpenCopiedSnackBar}
      />
      <AddTagModal
        showModal={showAddTagModal}
        setShowModal={setShowAddTagModal}
        initTx={initTx}
        setInitTx={setInitTx}
        callbackFnc={addTagCallbackFnc}
        setShowManageTagModal={setShowManageTagModal}
      />
      <ManageTagModal
        showModal={showManageTagModal}
        setShowModal={setShowManageTagModal}
        allowFetchingData={allowFetchingData}
      />
      {trackMyAddress && <AddAddressModal showModal={showAddAddressModal} setShowModal={setShowAddAddressModal} />}
      <Snackbar open={openCopiedSnackBar} autoHideDuration={3000} onClose={() => { setOpenCopiedSnackBar(false); }}>
        <Box className={classes.copied}>
          <Box width={20} height={20} flexShrink={0} display="flex">
            <SVG src="/icons/reporting/ic_check.svg" width={20} height={20} />
          </Box>
          <Typography variant="h6" paletteColor={700}>Copied</Typography>
        </Box>
      </Snackbar>
    </Box>
  );
};

const Reporting = () => (<ReportingComponent allowFetchingData />);

Reporting.routePath = ReportingRoute;
Reporting.loginRequired = true;
Reporting.displayName = 'Reporting';
Reporting.feature = 'reporting';
Reporting.redirect = 'reportingRedirect';

export { Reporting, ReportingComponent };
