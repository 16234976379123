import { Box, createStyles, makeStyles } from '@material-ui/core';
import { Loading } from 'components';
import { shouldShowRebalancingStatus } from 'components/RebalancingStatus';
import ManagePortfolioNavBar from 'elements/ManagePortfolioNavBar';
import React, { useEffect, useState } from 'react';
import { PortfolioSettingRoute } from 'routes';
import { useStore as useBundleStore } from 'store/zustand/Bundle';
import { useStore as useCoinStore } from 'store/zustand/Coin';
import { useStore as useFeatureStore } from 'store/zustand/Feature';
import { PortfolioSettingPage, useStore as usePortfolioSettingStore } from 'store/zustand/PortfolioSetting';
import { useStore as useWalletStore } from 'store/zustand/Wallet';

const useStyles = makeStyles(() => createStyles({
  root: {
    display: 'flex',
    width: '100%',
    justifyContent: 'center',
    height: '100%',
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    flexGrow: 1,
  },
  loading: {
    width: '100%',
    position: 'absolute',
    top: 0,
    left: 0,
    zIndex: 2,
    background: 'white',
  },
}));

const CustomBundle = React.lazy(() => import('./Bundle/CustomBundle'));
const ReadyMadeBundle = React.lazy(() => import('./Bundle/ReadyMadeBundle'));
const RebalancingStatus = React.lazy(() => import('components/RebalancingStatus'));

const PortfolioSetting = () => {
  const [isLoading, setIsLoading] = useState(true);

  const [page, goTo] = usePortfolioSettingStore((state) => [
    state.currentPage,
    state.goTo,
  ]);
  const [fetchCoins] = useCoinStore((state) => [state.fetchCoins]);
  const [fetchFeatures] = useFeatureStore((state) => [state.fetchFeatures]);
  const [bundles, fetchBundles, currentBundle, fetchCurrentBundle] = useBundleStore(
    (state) => [state.bundles, state.fetchBundles, state.currentBundle, state.fetchCurrentBundle],
  );
  const [
    fetchWallet, isDefi, readyWallet, rebalancingStatus, isRebalancingWallet,
    activeWalletBalanceNonSwappable, fetchRebalancingStatus, fetchActiveWalletBalance,
  ] = useWalletStore((state) => [
    state.fetchWallet, state.isDefi, state.readyWallet, state.rebalancingStatus, state.isRebalancingWallet,
    state.activeWalletBalanceNonSwappable, state.fetchRebalancingStatus, state.fetchLatestWalletBalances,
  ]);

  useEffect(() => {
    if (window.Tawk_API && window.Tawk_API.hideWidget) window.Tawk_API.hideWidget();
    return function cleanup() {
      if (window.Tawk_API && window.Tawk_API.showWidget) window.Tawk_API.showWidget();
    };
  }, []);

  useEffect(() => {
    (async () => {
      await Promise.allSettled([fetchRebalancingStatus(), fetchActiveWalletBalance(), fetchFeatures()]);
    })();
  }, []);

  useEffect(() => {
    const selectedPredefineBundle = bundles.some((bundle) => bundle.bundleId === currentBundle?.bundleId);
    if (!selectedPredefineBundle || isRebalancingWallet === false) {
      goTo(PortfolioSettingPage.Custom);
    } else if (!isDefi) {
      goTo(PortfolioSettingPage.ReadyMade);
    }
  }, [bundles, currentBundle, isRebalancingWallet]);

  useEffect(() => {
    if (readyWallet) fetchData();
  }, [readyWallet]);

  const fetchData = async () => {
    setIsLoading(true);
    try {
      await Promise.all([
        fetchCurrentBundle(false), fetchBundles(), fetchCoins(), fetchWallet(false),
      ]);
    } finally {
      setIsLoading(false);
    }
  };

  const classes = useStyles();

  if (isLoading) return (<Loading className={classes.loading} />);
  return (
    <>
      {!isLoading && (
        <ManagePortfolioNavBar hasNonSwappable={(activeWalletBalanceNonSwappable || []).length > 0} />
      )}

      <Box className={classes.root}>
        <Box className={classes.content}>
          {shouldShowRebalancingStatus(rebalancingStatus) && rebalancingStatus !== 'reporting-address' ? (
            <React.Suspense fallback={<></>}>
              <RebalancingStatus status={rebalancingStatus} />
            </React.Suspense>
          ) : (
            <>
              <React.Suspense fallback={<></>}>
                {page === PortfolioSettingPage.Custom && isDefi && (<CustomBundle />)}
              </React.Suspense>
              <React.Suspense fallback={<></>}>
                {page === PortfolioSettingPage.ReadyMade && (<ReadyMadeBundle />)}
              </React.Suspense>
            </>
          )}
        </Box>
      </Box>
    </>
  );
};

PortfolioSetting.routePath = PortfolioSettingRoute;
PortfolioSetting.loginRequired = true;
PortfolioSetting.redirect = 'portfolioSettingRedirect';

export default PortfolioSetting;
