import React, { Component } from "react";
import { Footer } from "../../components/Footer.js";
import { InternalNavbar } from "../../components/navbar.js";
import AuthService from "../../services/auth-service.js";
import { Container, Row, Col } from 'react-bootstrap'
import Loader from "../../components/Loader.js";
import RiskProfile from "./RiskProfile.js";
import { WelcomeIllustration } from "../../components/Icons.js";
import Logger from "js-logger";

export default class Welcome extends Component {
  static routePath = '/welcome';
  static loginRequired = true;

  constructor(props) {
    super(props);

    this.videoSetup = this.videoSetup.bind(this)
    this.onGetStarted = this.onGetStarted.bind(this);

    this.state = {
      loading: true
    }
  }

  componentDidMount() {
    this.setState({ loading: false })
  }

  onGetStarted(e) {
    e.preventDefault();
    AuthService.updateUserStep().then(resp => {
      this.props.history.push(RiskProfile.routePath)
    });
  }

  videoSetup() {
    if(!this.state.video){
      // document.body.style.position = "fixed";
      document.body.style.height = "100%";
      document.body.style.overflowX = "hidden";
      document.getElementById("welcome-video").play()
      document.getElementsByClassName("play-btn")[0].style.display = "none"
    }else{
      // document.body.style.position = "";
      document.body.style.height = "";
      document.body.style.overflowX = "";
    }
    this.setState({video: !this.state.video})
  }

  render() {

    Logger.log(this.state)

    return (
      <>
        <Loader loading={this.state.loading} shouldRemoveLoader={false}/>

        <InternalNavbar history={this.props.history} />

        <Container className="mt-3 px-4">
          <Row>
            <Col className="text-center mt-4">
              <WelcomeIllustration />
            </Col>
          </Row>
          <Row>
            <Col>
              <h5 className="green">Welcome to Coinbag!</h5>
              <p>Investing in cryptocurrency can be both exciting and intimidating. We’re happy you chose us to help!</p>
            </Col>
          </Row>
        </Container>
        <Container>
          <Row className="mt-4">
            <Col>
              <button className="btn-first btn-block" onClick={this.onGetStarted}>Begin</button>
            </Col>
          </Row>
        </Container>

        <Footer />
      </>
    );
  }
}
