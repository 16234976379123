import React, {Component} from "react";
import StatsService from "../../services/stats.service";
import moment from "moment-timezone";
import "./HighLevelStats.css";
import MaterialTable from "material-table";

export default class HighLevelStats extends Component {
  static routePath = '/high-level-stats';
  static loginRequired = true;
  static firstUserCreationTime = new Date("2021-03-17 21:00:00Z")
  static launchTime = new Date("2021-06-05 08:00:00Z");

  constructor(props) {
    super(props);

    this.renderUsersByStep = this.renderUsersByStep.bind(this)
    this.state = {
      isLoading: true,
    };
  }

  componentDidMount() {
    const sinceFirst = StatsService.getHighLevelStats(HighLevelStats.firstUserCreationTime.getTime());
    const sinceLaunchTime = StatsService.getHighLevelStats(HighLevelStats.launchTime.getTime());

    Promise.all([sinceFirst, sinceLaunchTime]).then((values) => {
      let first = values[0].data;
      first.sinceTs = moment(new Date(first.sinceTs));
      let launch = values[1].data;
      launch.sinceTs = moment(new Date(launch.sinceTs));

      this.setState({
        first: first,
        launch: values[1].data,
        isLoading: false
      });
    });
  }

  renderUsersByStep(){
    var stepFirst = this.state.first.usersByStep
    var stepLaunch = this.state.launch.usersByStep
    var l = stepFirst.length
    var c = stepFirst.map(function(e, i) {
      return [e, stepLaunch[i]];
    });
    return c.map((e) => {
      return (<div className="divTableHighLevelStatsRow">
        <div className="divTableHighLevelStatsCell"> &nbsp;&nbsp;Users Step {e[0][0]}</div>
        <div className="divTableHighLevelStatsCell">{e[0][1]}</div>
        <div className="divTableHighLevelStatsCell">{e[1][1]}</div>
      </div>)
    })
  }

  render() {
    if (this.state.isLoading) {
      return <p>Loading ...</p>;
    }
    console.log(this.state);

    return (
      <div>
        <section id="exchange">
          <div className="container">
            <div className="row">
              <div className="divTableHighLevelStats">
                <div className="divTableHighLevelStatsBody">
                  <div className="divTableHighLevelStatsRow">
                    <div className="divTableHighLevelStatsCell">Since</div>
                    <div className="divTableHighLevelStatsCell">First
                      user: {this.state.first.sinceTs.format("D MMM YYYY, HH:mm")}</div>
                    <div
                      className="divTableHighLevelStatsCell">Launch: {this.state.launch.sinceTs.format("D MMM YYYY, HH:mm")}</div>
                  </div>
                  <div className="divTableHighLevelStatsRow">
                    <div className="divTableHighLevelStatsCell">Users</div>
                    <div className="divTableHighLevelStatsCell">{this.state.first.users}</div>
                    <div className="divTableHighLevelStatsCell">{this.state.launch.users}</div>
                  </div>
                  <div className="divTableHighLevelStatsRow">
                    <div className="divTableHighLevelStatsCell">Verified Users</div>
                    <div className="divTableHighLevelStatsCell">{this.state.first.verifiedUsers}</div>
                    <div className="divTableHighLevelStatsCell">{this.state.launch.verifiedUsers}</div>
                  </div>
                  {this.renderUsersByStep()}
                  <div className="divTableHighLevelStatsRow">
                    <div className="divTableHighLevelStatsCell">Enabled Wallets</div>
                    <div className="divTableHighLevelStatsCell">{this.state.first.enabledWallets}</div>
                    <div className="divTableHighLevelStatsCell">{this.state.launch.enabledWallets}</div>
                  </div>
                  <div className="divTableHighLevelStatsRow">
                    <div className="divTableHighLevelStatsCell">&nbsp;&nbsp;CeFi</div>
                    <div className="divTableHighLevelStatsCell">{this.state.first.cefi}</div>
                    <div className="divTableHighLevelStatsCell">{this.state.launch.cefi}</div>
                  </div>
                  <div className="divTableHighLevelStatsRow">
                    <div className="divTableHighLevelStatsCell">&nbsp;&nbsp;&nbsp;&nbsp;Balance &gt; 10$</div>
                    <div className="divTableHighLevelStatsCell">{this.state.first.cefiWithBalance}</div>
                    <div className="divTableHighLevelStatsCell">{this.state.launch.cefiWithBalance}</div>
                  </div>
                  <div className="divTableHighLevelStatsRow">
                    <div className="divTableHighLevelStatsCell">&nbsp;&nbsp;DeFi</div>
                    <div className="divTableHighLevelStatsCell">{this.state.first.defi}</div>
                    <div className="divTableHighLevelStatsCell">{this.state.launch.defi}</div>
                  </div>
                  <div className="divTableHighLevelStatsRow">
                    <div className="divTableHighLevelStatsCell">&nbsp;&nbsp;&nbsp;&nbsp;Balance &gt; 10$</div>
                    <div className="divTableHighLevelStatsCell">{this.state.first.defiWithBalance}</div>
                    <div className="divTableHighLevelStatsCell">{this.state.launch.defiWithBalance}</div>
                  </div>
                  <div className="divTableHighLevelStatsRow">
                    <div className="divTableHighLevelStatsCell">Min</div>
                    <div
                      className="divTableHighLevelStatsCell">{this.state.first.assetsUnderManagement.min.toFixed(2)}</div>
                    <div
                      className="divTableHighLevelStatsCell">{this.state.launch.assetsUnderManagement.min.toFixed(2)}</div>
                  </div>
                  <div className="divTableHighLevelStatsRow">
                    <div className="divTableHighLevelStatsCell">Max</div>
                    <div
                      className="divTableHighLevelStatsCell">{this.state.first.assetsUnderManagement.max.toFixed(2)}</div>
                    <div
                      className="divTableHighLevelStatsCell">{this.state.launch.assetsUnderManagement.max.toFixed(2)}</div>
                  </div>
                  <div className="divTableHighLevelStatsRow">
                    <div className="divTableHighLevelStatsCell">Average</div>
                    <div
                      className="divTableHighLevelStatsCell">{this.state.first.assetsUnderManagement.average.toFixed(2)}</div>
                    <div
                      className="divTableHighLevelStatsCell">{this.state.launch.assetsUnderManagement.average.toFixed(2)}</div>
                  </div>
                  <div className="divTableHighLevelStatsRow">
                    <div className="divTableHighLevelStatsCell">Median</div>
                    <div
                      className="divTableHighLevelStatsCell">{this.state.first.assetsUnderManagement.median.toFixed(2)}</div>
                    <div
                      className="divTableHighLevelStatsCell">{this.state.first.assetsUnderManagement.median.toFixed(2)}</div>
                  </div>
                  <div className="divTableHighLevelStatsRow">
                    <div className="divTableHighLevelStatsCell">Total</div>
                    <div
                      className="divTableHighLevelStatsCell">{this.state.first.assetsUnderManagement.total.toFixed(2)}</div>
                    <div
                      className="divTableHighLevelStatsCell">{this.state.launch.assetsUnderManagement.total.toFixed(2)}</div>
                  </div>
                </div>
                <div>
                  <div className="col-md-8 col-sm-6">Number of rebalancings: {this.state.first.rebalancingStats.nonEmptyRebalancings}</div>
                  <div className="col-md-8 col-sm-6">Total USDT value involved in rebalancing: {this.state.first.rebalancingStats.totalUSDTValueInvolvedInRebalancing.toFixed(2)} USDT</div>
                </div>
              </div>
            </div>
            <div className="row">
              <MaterialTable
                columns={[
                  {title: "Name", field: "name"},
                  {title: "Bundle id", field: "bundleId"},
                  {title: "Risk score", field: "riskScore"},
                  {title: "Usage", field: "count", sorting: true, defaultSort: 'desc'}
                ]}
                data={this.state.first.bundleUsageList}
                title="Bundles"
                options={{
                  sorting: true,
                  pageSize: 20,
                  maxBodyHeight: "600px",
                  maxBodyWidth: "600px",
                  search: false,
                  showTitle: false,
                  toolbar: false,
                  paging: false,
                  headerStyle: {
                    position: "sticky",
                    top: "0",
                  },
                  cellStyle: {
                    minWidth: 50
                  },
                }}
              />
            </div>
          </div>
        </section>
      </div>
    )
  }
}
