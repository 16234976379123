/* eslint-disable no-unused-vars */
import { createStyles, makeStyles } from '@material-ui/core';
import { InfoBanner } from 'elements/InfoBanner';
import React, { FC } from 'react';

interface DepositLowLiquidityBanner {
  selectedMasterCoin: string;
  selectedNetwork: string;
}

const useStyles = makeStyles(() => createStyles({
  warningBanner: {
    background: '#FFFAFA',
    lineHeight: '16px',
    color: '#E62179',
  },
}));

export const DepositLowLiquidityBanner: FC<DepositLowLiquidityBanner> = (props) => {
  const classes = useStyles();
  return (
    <InfoBanner
      modifier={classes.warningBanner}
      title={`${props.selectedMasterCoin} is a low-liquidity token.
      Depositing will automatically disable any rebalancing for this wallet.`}
      description="If you want to keep rebalancing, you can create new wallet(s) to hold low liquidity tokens."
      icon="icons/withdraw/warning_icon_red.svg"
    />
  );
};
