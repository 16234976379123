import { Box, Hidden, Link, makeStyles, Typography } from '@material-ui/core';
import { Button } from 'elements';
import React, { FC, memo } from 'react';
import { SignUpInstitutionalInvestorsRoute } from 'routes';

import { FeatureCarousel } from './Hero/FeatureCarousel';

const useStyles = makeStyles((theme) => ({
  root: {
    zIndex: 0,
    position: 'relative',

    display: 'flex',
    flexDirection: 'column-reverse',
    alignItems: 'center',
    paddingTop: 24,
    width: '100%',

    [theme.breakpoints.up('md')]: {
      flexDirection: 'row',
      height: 440,
    },

    [theme.breakpoints.up('lg')]: {
      height: 680,
    },

    [theme.breakpoints.up('xl')]: {
      width: '100%',
    },

    '& p': {
      fontSize: 16,
      fontWeight: 500,
      lineHeight: '150%',

      [theme.breakpoints.up('lg')]: {
        fontSize: 20,
        lineHeight: '140%',
      },
    },
  },

  content: {
    position: 'relative',
    display: 'flex',
    alignItems: 'center',

    margin: '0 auto',
    padding: '0 16px',
    maxWidth: 1080,
    width: '100%',

    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column-reverse',
      maxWidth: 640,
    },

    [theme.breakpoints.up('xl')]: {
      maxWidth: 1366,
    },
  },

  titleColumn: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'start',
    flexShrink: 0,
    width: '100%',

    [theme.breakpoints.down('sm')]: {
      marginTop: 24,
    },

    [theme.breakpoints.between('md', 'lg')]: {
      width: '50%',
    },

    [theme.breakpoints.up('lg')]: {
      width: '55%',
    },

    [theme.breakpoints.up('xl')]: {
      width: 'calc(100% * 5 / 12)',
    },
  },

  button: {
    marginTop: 16,
    color: 'white',
    fontSize: 16,
    lineHeight: '175%',

    [theme.breakpoints.up('lg')]: {
      marginTop: 24,
      fontSize: 18,
    },
  },

  mockupGroup: {
    [theme.breakpoints.down('sm')]: {
      position: 'relative',
      display: 'flex',
      flexWrap: 'nowrap',
      justifyContent: 'start',

      paddingLeft: 16,
      maxWidth: '100vw',
      width: '100%',
    },

    [theme.breakpoints.up('md')]: {
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translateX(15%) translateY(-50%)',
    },

    [theme.breakpoints.up('lg')]: {
      left: '55%',
    },

    [theme.breakpoints.up('xl')]: {
      left: 'initial',
      right: 16,
      width: 'min(50%, calc(1366px / 2))',
      transform: 'translateY(-50%)',
    },
  },

  gradient: {
    zIndex: 0,
    position: 'absolute',
    left: '50%',
    top: '50%',

    height: '80vh',
    width: '160vw',
    transform: 'translateX(-50%) translateY(-50%) translateZ(0)',

    background: 'radial-gradient(48.42% 48.42% at 50% 50%, rgba(110, 158, 254, 0.48) 0%, rgba(235, 255, 252, 0) 100%)',
    filter: 'blur(33.9499px)',
    pointerEvents: 'none',
  },

  mockupContainer: {
    padding: 6,
    background: 'rgba(255, 255, 255, 0.2)',
    backdropFilter: 'blur(2.5px)',

    borderRadius: 10,
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: 'rgba(187, 196, 221, 0.1)',

    [theme.breakpoints.down('xs')]: {
      height: 212,
    },

    [theme.breakpoints.between('sm', 'lg')]: {
      height: 290,
    },

    [theme.breakpoints.up('lg')]: {
      padding: 16,
      borderRadius: 18,
      borderWidth: 2,
    },
  },

  desktopMockup: {
    [theme.breakpoints.down('sm')]: {
      marginLeft: 12,
    },

    [theme.breakpoints.between('md', 'lg')]: {
      height: 320,
    },

    [theme.breakpoints.up('lg')]: {
      height: 440,
    },

    [theme.breakpoints.up('xl')]: {
      height: 'initial',
    },

    '& img': {
      [theme.breakpoints.up('xl')]: {
        maxHeight: 'none',
        width: '100%',
      },
    },
  },

  mobileMockup: {
    zIndex: 1,
    aspectRatio: '168/344',

    [theme.breakpoints.up('md')]: {
      position: 'absolute',
      bottom: 0,
      height: '75%',
      transform: 'translateX(-50%) translateY(10%)',
    },

    [theme.breakpoints.up('lg')]: {
      padding: 12,
    },

    [theme.breakpoints.up('xl')]: {
      transform: 'translateX(-30%) translateY(12%)',
    },
  },

  mockupImage: {
    height: '100%',
    maxHeight: '100%',
    objectFit: 'contain',

    borderRadius: 6,
    borderWidth: 0.5,
    borderStyle: 'solid',
    borderColor: 'rgba(144, 156, 189, 0.2)',

    [theme.breakpoints.up('lg')]: {
      borderWidth: 1,
      borderRadius: 10,
    },
  },
  mobileMockupImage: {
    maxWidth: '100%',
    width: '100%',
  },

  demoLink: {
    marginTop: 24,
    marginInline: 'auto',

    color: theme.palette.blue[600],
    textDecoration: 'underline',

    '&:active': { color: theme.palette.blue[700] },
    '&:hover': { color: theme.palette.blue[700] },
  },
}));

const srcSet = (variant: string) => [1, 2, 3]
  .map((i) => `/images/home-v2/hero/hero-${variant}@${i}x.png ${i}x`)
  .join(', ');

const Hero: FC = memo(() => {
  const classes = useStyles();
  return (
    <section className={classes.root}>
      <Box className={classes.content}>
        <Box className={classes.titleColumn}>
          <Typography variant="h1">
            Digital asset operations,{' '}<br /><em>simplified.</em>
          </Typography>

          <Box marginTop="24px" />
          <FeatureCarousel />

          <Hidden lgUp>
            <Box marginTop="32px">
              <Typography variant="body1">
                <em>Create your business account today!</em>
              </Typography>
            </Box>
          </Hidden>

          <Button className={classes.button} color="blue" fullWidth href={SignUpInstitutionalInvestorsRoute}>
            <Hidden lgUp>Create account</Hidden>
            <Hidden mdDown>Create your business account today!</Hidden>
          </Button>

          <Link
            className={classes.demoLink}
            href="https://meetings.hubspot.com/sales-coinbag"
            target="_blank"
            rel="noopener noreferrer"
          >
            Schedule a 15 minute demo
          </Link>
        </Box>

        <Hidden lgDown>
          <Box className={classes.mockupGroup}>
            <Box className={classes.gradient} />

            <Box className={`${classes.mockupContainer} ${classes.mobileMockup}`}>
              <img
                className={`${classes.mockupImage} ${classes.mobileMockupImage}`}
                alt="Mobile mockup"
                src="/images/home-v2/hero/hero-mobile@1x.png"
                srcSet={srcSet('mobile')}
              />
            </Box>

            <Box className={`${classes.mockupContainer} ${classes.desktopMockup}`}>
              <img
                className={classes.mockupImage}
                alt="Desktop mockup"
                src="/images/home-v2/hero/hero-desktop@1x.png"
                srcSet={srcSet('desktop')}
              />
            </Box>
          </Box>
        </Hidden>
      </Box>

      <Hidden xlUp>
        <Box className={classes.mockupGroup}>
          <Box className={classes.gradient} />

          <Box className={`${classes.mockupContainer} ${classes.mobileMockup}`}>
            <img
              className={`${classes.mockupImage} ${classes.mobileMockupImage}`}
              alt="Mobile mockup"
              src="/images/home-v2/hero/hero-mobile@1x.png"
              srcSet={srcSet('mobile')}
            />
          </Box>

          <Box className={`${classes.mockupContainer} ${classes.desktopMockup}`}>
            <img
              className={classes.mockupImage}
              alt="Desktop mockup"
              src="/images/home-v2/hero/hero-desktop@1x.png"
              srcSet={srcSet('desktop')}
            />
          </Box>
        </Box>
      </Hidden>
    </section>
  );
});

Hero.displayName = 'Hero';
export default Hero;
