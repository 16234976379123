import { Box, makeStyles } from '@material-ui/core';
import { Typography } from 'elements';
import React, { FC } from 'react';

type ActualVsTargetProps = { className?: string };

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'baseline',
  },
  vsText: {
    margin: '0 12px',
    color: theme.palette.grey[400],
    fontSize: 16,
    fontWeight: 400,
  },
  colorDot: {
    width: 12,
    height: 12,
    borderRadius: 6,
    flexShrink: 0,
    marginRight: 4,
    '&#actual': {
      backgroundColor: theme.palette.green[500],
    },
    '&#target': {
      backgroundColor: theme.palette.purple[500],
    },
  },
}));

const ActualVsTarget: FC<ActualVsTargetProps> = ({ className }) => {
  const classes = useStyles();
  return (
    <Box className={`${classes.root} ${className}`}>
      <Box className={classes.colorDot} id="actual" />
      <Typography paletteColor={700} variant="h5">Actual</Typography>
      <Typography className={classes.vsText}>vs.</Typography>
      <Box className={classes.colorDot} id="target" />
      <Typography paletteColor={700} variant="h5">Target</Typography>
    </Box>
  );
};

ActualVsTarget.displayName = 'ActualVsTarget';
export { ActualVsTarget };
