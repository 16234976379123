import { Box, createStyles,Hidden } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { LightModal, Typography } from 'elements';
import { Scrollable } from 'elements/Scrollable';
import { AddOrUpdateAddressBookPendingAction, RemoveAddressBookPendingAction } from 'models/Features';
import React, { useEffect, useMemo, useState } from 'react';
import SVG from 'react-inlinesvg';
import { WorkItemName } from 'services/types/workItem';
import { useStore as useFeatureStore } from 'store/zustand/Feature';
import { useStore as useTransactionStore } from 'store/zustand/Transaction';
import { useStore as useWalletStore } from 'store/zustand/Wallet';

import { RemoveItem } from '../RemoveItem';
import { AddressBookModalOption, AddressBookRow } from './AddressBookModalRow';

interface Props {
  showModal: boolean;
  // eslint-disable-next-line no-unused-vars
  setShowModal: (value: boolean) => void;
  showAddAddressBookModal: () => void;
  // eslint-disable-next-line no-unused-vars
  setInitAddress: (value: string | undefined) => void;
  // eslint-disable-next-line no-unused-vars
  setInitTag: (value: string | undefined) => void;
  // eslint-disable-next-line react/no-unused-prop-types, no-unused-vars
  setInitWalletId: (value?: number) => void;
  // eslint-disable-next-line no-unused-vars
  setEditAddressBookId: (value?: number) => void;
  allowFetchingData: boolean;
  // eslint-disable-next-line no-unused-vars
  setOpenCopiedSnackBar: (value: boolean) => void;
}

const useStyles = makeStyles((theme) => createStyles({
  modal: {
    gap: 0,
  },
  root: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    minHeight: 'max(400px, 50vh)',
  },
  header: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    gap: 8,
    flexGrow: 1,
    '& h2': {
      fontSize: 16,
      lineHeight: '24px',
      fontWeight: 500,
      textAlign: 'center',
      color: theme.palette.grey[800],
    },
    [theme.breakpoints.up('sm')]: {
      borderBottom: `1px solid ${theme.palette.grey[200]}`,
      padding: 16,
    },
  },
  removeItemHeader: {
    borderBottom: 'unset',
  },
  headerGroup: {
    display: 'flex',
    gap: 8,
    alignItems: 'center',
  },
  addAddress: {
    cursor: 'pointer',
    display: 'flex',
    flexWrap: 'nowrap',
    gap: 8,
    alignItems: 'center',
    '& path': {
      stroke: theme.palette.green[600],
    },
  },
  closeButton: {
    display: 'flex',
    flexShrink: 0,
    cursor: 'pointer',
    width: 20,
    height: 20,
    '& path': {
      fill: theme.palette.grey[300],
    },
  },
  noAddress: {
    padding: '24px 8px',
  },
}));

enum ModalState {
  ManageAddressBook,
  RemoveAddressBook,
}

export const AddressBookModal = ({ showModal, setShowModal, showAddAddressBookModal, setInitAddress, setInitTag,
  setInitWalletId, allowFetchingData, setEditAddressBookId, setOpenCopiedSnackBar }: Props) => {
  const classes = useStyles();

  const [addressBooks, fetchAddressBooks, removeAddressBook] = useTransactionStore((state) => [
    state.addressBooks, state.fetchAddressBooks, state.removeAddressBook,
  ]);
  const [walletInfos, isAllWallets] = useWalletStore((state) => [state.walletInfos, state.isAllWallets]);
  const [fetchFeatures, features, findPendingAction] = useFeatureStore((state) => [
    state.fetchFeatures, state.features, state.findPendingAction,
  ]);

  const [modalState, setModalState] = useState<ModalState>(ModalState.ManageAddressBook);
  const [addressBookIdToRemove, setAddressBookIdToRemove] = useState<number | undefined>();
  const [disableButtons, setDisableButtons] = useState<boolean>(false);

  const addressBookOptions = useMemo(() => {
    const fromWAddressBooks = addressBooks
      ?.reduce((prev: Record<string, AddressBookModalOption[]>, curr) => (
        { ...prev, [curr.address]: [...(prev[curr.address] ?? []), curr] }), {});
    const fromWalletInfo = walletInfos
      ?.map((wi) => (wi.address
        ?.map((address): AddressBookModalOption => ({
          address,
          tag: wi.name ?? wi.walletProvider,
          walletId: wi.walletId!,
        })) ?? []
      ))
      .flat()
      ?.reduce((prev: Record<string, AddressBookModalOption[]>, curr) => (
        { ...prev, [curr.address]: [...(prev[curr.address] ?? []), curr] }), {});
    const fromBoth = { ...(fromWalletInfo ?? {}), ...(fromWAddressBooks ?? {}) };
    return Object.entries(fromBoth)
      .map(([, options]) => (options))
      .flat();
  }, [addressBooks]);

  const awaitingEdits = React.useMemo(() => (
    findPendingAction(WorkItemName.AddOrUpdateAddressBook)
      ?.map((i) => i as AddOrUpdateAddressBookPendingAction)
      .filter((i) => {
        const existingInAddressBookOption = addressBookOptions
          .filter((ab) => ab.address === i.new.address.toLowerCase() && ab.walletId === i.new.walletId);
        return i.old !== null || existingInAddressBookOption.length > 0;
      })
  ), [features, addressBookOptions]);

  const awaitingAdds = React.useMemo(() => (
    findPendingAction(WorkItemName.AddOrUpdateAddressBook)
      ?.map((i) => i as AddOrUpdateAddressBookPendingAction)
      .filter((i) => {
        const existingInAddressBookOption = addressBookOptions
          .filter((ab) => ab.address === i.new.address.toLowerCase() && ab.walletId === i.new.walletId);
        return i.old === null && existingInAddressBookOption.length <= 0;
      })
  ), [features, addressBookOptions]);

  const awaitingRemoves = React.useMemo(() => (
    findPendingAction(WorkItemName.RemoveAddressBook)?.map((i) => i as RemoveAddressBookPendingAction)
  ), [features]);

  useEffect(() => {
    if (allowFetchingData) {
      (async () => {
        await fetchAddressBooks(false);
      })();
    }
  }, [allowFetchingData]);

  const handleClickAdd = () => {
    setShowModal(false);
    showAddAddressBookModal();
  };

  const handleClickRemoveOnRow = (addressBookId: number) => {
    setAddressBookIdToRemove(addressBookId);
    setModalState(ModalState.RemoveAddressBook);
  };

  const callbackChangeAddressBook = (option: AddressBookModalOption) => {
    if (option.addressBookId !== undefined) {
      setEditAddressBookId(option.addressBookId);
    } else {
      setInitAddress(option.address);
      setInitTag(option.tag);
    }
    if (isAllWallets()) setInitWalletId(option.walletId);
    showAddAddressBookModal();
  };

  const manageAddressBooks = (
    <Scrollable>
      {(addressBookOptions.length + (awaitingAdds?.length ?? 0)) > 0 ? (
        <>
          {addressBookOptions.map((addressBook, index) => (
            <AddressBookRow
              name={addressBook.tag}
              address={addressBook.address}
              id={addressBook.addressBookId}
              walletId={addressBook.walletId}
              awaitingAdd={false}
              awaitingEdit={!!awaitingEdits?.find((i) => i.old?.addressBookId === addressBook.addressBookId)}
              awaitingRemove={!!awaitingRemoves?.find((i) => i.addressBookId === addressBook.addressBookId)}
              callbackChangeAddressBook={callbackChangeAddressBook}
              key={index}
              setOpenCopiedSnackBar={setOpenCopiedSnackBar}
              onRemove={handleClickRemoveOnRow}
            />
          ))}
          {awaitingAdds?.map((addressBook, index) => (
            <AddressBookRow
              name={addressBook.new.name}
              address={addressBook.new.address}
              walletId={addressBook.new.walletId}
              awaitingAdd
              awaitingEdit={false}
              awaitingRemove={false}
              key={`awaiting ${index}`}
              setOpenCopiedSnackBar={setOpenCopiedSnackBar}
            />
          ))}
        </>
      ) : (
        <Typography className={classes.noAddress} variant="h2" paletteColor={300} align="center">
          No Address
        </Typography>
      )}
    </Scrollable>
  );

  const onRemoveItem = async () => {
    setDisableButtons(true);
    if (addressBookIdToRemove) await removeAddressBook(addressBookIdToRemove);
    await fetchFeatures(true);
    setDisableButtons(false);
    setAddressBookIdToRemove(undefined);
    setModalState(ModalState.ManageAddressBook);
  };

  const onCancelRemoveItem = () => {
    setAddressBookIdToRemove(undefined);
    setModalState(ModalState.ManageAddressBook);
  };

  const removeAddressBookContent = (
    <RemoveItem
      header="Are you sure you want to remove this address book?"
      tagLabel={addressBookOptions.find((ab) => ab.addressBookId === addressBookIdToRemove)?.tag}
      onRemoveItem={onRemoveItem}
      onCancel={onCancelRemoveItem}
      disableButtons={disableButtons}
    />
  );

  const handleOnClose = () => {
    setShowModal(false);
    setDisableButtons(false);
    setAddressBookIdToRemove(undefined);
    setModalState(ModalState.ManageAddressBook);
  };

  return (
    <LightModal
      open={showModal}
      setOpen={setShowModal}
      onClose={handleOnClose}
      maxWidth={680}
      className={classes.modal}
      modalTitle={(
        <Box
          className={`${classes.header} ${modalState === ModalState.RemoveAddressBook ? classes.removeItemHeader : ''}`}
        >
          <Hidden xsDown>
            <Box className={classes.headerGroup}>
              <Box width={24} height={24} display="flex" flexShrink={0}>
                <SVG src="icons/reporting/ic_address_book.svg" width={24} height={24} />
              </Box>
              <Typography variant="h2">Address book manager</Typography>
            </Box>
          </Hidden>
          <Hidden smUp>
            <Typography variant="h2">Address book</Typography>
          </Hidden>
          <Box className={classes.headerGroup}>
            {modalState === ModalState.ManageAddressBook && (
              <Box className={classes.addAddress} onClick={handleClickAdd}>
                <Typography variant="h4" palette="green" paletteColor={600}>Add address</Typography>
                <Box width={20} height={20} display="flex" flexShrink={0}>
                  <SVG src="/icons/reporting/ic_plus.svg" width={20} height={20} />
                </Box>
              </Box>
            )}
            <Hidden xsDown>
              <Box className={classes.closeButton} onClick={handleOnClose}>
                <SVG
                  width={20}
                  height={20}
                  src="icons/dashboard/ic_cross.svg"
                />
              </Box>
            </Hidden>
          </Box>
        </Box>
      )}
      noPadding
      fullScreenOnMobile
    >
      <Box className={classes.root}>
        {modalState === ModalState.ManageAddressBook && manageAddressBooks}
        {modalState === ModalState.RemoveAddressBook && addressBookIdToRemove !== undefined && removeAddressBookContent}
      </Box>
    </LightModal>
  );
};
