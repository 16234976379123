import { Hidden, makeStyles, useMediaQuery } from '@material-ui/core';
import { Card, Typography } from 'elements';
import * as React from 'react';
import { Redirect } from 'react-router-dom';
import { DashboardRoute, SignInRoute } from 'routes';
// eslint-disable-next-line import/extensions
import authService from 'services/auth-service';
import { FA } from 'services/types/wallet';
import { breakpoints } from 'theme/base';

import SubmitWithFA from '../../../components/VerificationCode';
import { useStore as useUserStore } from '../../../store/zustand/User';
import BackgroundIcons from '../Authentication/Components/BackgroundIcons';
import SignInForm from './SignInForm';
import { LoginSteps, VerificationStatus } from './types/SignIn';

export const focusHeadline: Record<LoginSteps, string> = {
  0: 'Sign In ',
  1: '6 Digit Code ',
};

export const headline: Record<LoginSteps, string> = {
  0: 'To Your Account',
  1: 'From Your 2FA App',
};

export const description: Record<LoginSteps, string> = {
  0: 'Check your portfolio earnings and adjust your goals. Deposit and withdraw funds in minutes.',
  1: 'To securely login to  your account, please enter the 6 digit code from your authenticator app.',
};

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
    color: theme.palette.grey[700],
    '& em': {
      color: theme.palette.green[600],
      fontStyle: 'normal',
    },
  },
  content: {
    display: 'flex',
    flexWrap: 'nowrap',
    gap: 32,
    width: '100%',
    maxWidth: 1080,
    alignItems: 'center',
    padding: 16,
    paddingBottom: 32,
    minHeight: '70vh',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      justifyContent: 'center',
      maxWidth: 640,
      gap: 16,
    },
    '&::before': {
      content: '" "',
      width: '1600px',
      height: '1200px',
      top: '-400px',
      left: '40%',
      position: 'absolute',
      backfaceVisibility: 'hidden',
      perspective: 1000,
      transform: 'translateZ(0)',
      background: 'radial-gradient(50% 50% at 50% 50%, rgba(46, 255, 218, 0.315) 0%, rgba(255, 255, 255, 0) 100%)',
      zIndex: 0,
      filter: 'blur(80px)',
      [theme.breakpoints.down('sm')]: {
        display: 'none',
      },
    },
  },
  card: {
    display: 'flex',
    flexDirection: 'column',
    gap: 16,
    padding: 32,
    background: 'rgba(255, 255, 255, 0.9)',
    backdropFilter: 'blur(8px)',
    borderRadius: 32,
    overflow: 'hidden',
    backfaceVisibility: 'hidden',
    perspective: 1000,
    transform: 'translateZ(0)',
    [theme.breakpoints.up('md')]: {
      maxWidth: 450,
    },
    [theme.breakpoints.down('sm')]: {
      borderRadius: 24,
      gap: 20,
      padding: 24,
    },
  },
  titleWrapper: {
    display: 'flex',
    flexDirection: 'column',
    gap: 20,
    width: '100%',
    zIndex: 1,
    [theme.breakpoints.down('sm')]: {
      gap: 12,
    },
  },
  title: {
    fontSize: 40,
    lineHeight: '80px',
    fontWeight: 700,
    [theme.breakpoints.down('sm')]: {
      fontSize: 24,
      fontWeight: 600,
      lineHeight: '32px',
      textAlign: 'center',
    },
  },
  cardTitle: {
    color: theme.palette.grey[700],
    fontSize: 28,
    lineHeight: '44px',
    fontWeight: 600,
    textAlign: 'center',
    [theme.breakpoints.down('sm')]: {
      fontSize: 24,
      lineHeight: '32px',
    },
  },
  subTitle: {
    color: theme.palette.grey[700],
    fontSize: 14,
    lineHeight: '24px',
    fontWeight: 400,
    textAlign: 'center',
    [theme.breakpoints.down('sm')]: {
      fontSize: 12,
      lineHeight: '20px',
    },
  },
}));

const SignIn = () => {
  const classes = useStyles();
  const isMobile = useMediaQuery(breakpoints.down('sm'));
  const [isProcessing, setIsProcessing] = React.useState(false);
  const [currentStep, setCurrentStep] = React.useState<LoginSteps>(LoginSteps.Form);

  const [email, setEmail] = React.useState<string>('');
  const [password, setPassword] = React.useState<string>('');
  const [robot, setRobot] = React.useState<string | null>(null);
  const [user, fetchUser] = useUserStore((state) => [state.user, state.fetchUser]);

  const [verificationStatus, setVerificationStatus] = React.useState<VerificationStatus>(VerificationStatus.None);

  React.useEffect(() => {
    if (window.Tawk_API && window.Tawk_API.hideWidget) window.Tawk_API.hideWidget();
    return function cleanup() {
      if (window.Tawk_API && window.Tawk_API.showWidget) window.Tawk_API.showWidget();
    };
  });

  React.useEffect(() => {
    (async () => { fetchUser(true); })();
  }, []);

  const handleClickSubmit = React.useCallback(
    async (fa: FA) => {
      setIsProcessing(true);
      return authService.signin(email, password, null, fa)
        .then((res: any) => {
          if (res.authenticatorStatus === 1) {
            fetchUser(true);
          }
          setIsProcessing(false);
          return res;
        })
        .catch((res: any) => {
          setIsProcessing(false);
          return res;
        });
    },
    [email, password],
  );

  if (user && !user.isTmp) {
    const query = new URLSearchParams(window.location.search);
    const redirectUrl = query.get('redirect');
    if (redirectUrl) return (<Redirect to={redirectUrl} />);
    return (<Redirect to={{ pathname: DashboardRoute, search: window.location.search }} />);
  }

  return (
    <main className={classes.root}>
      <section itemScope className={classes.content}>
        <BackgroundIcons />
        {!(currentStep === LoginSteps.TwoFactorAuthentication && isMobile) && (
          <article className={classes.titleWrapper}>
            <Typography className={classes.title} variant="h1"><em>Sign in</em> to your account</Typography>
            <Hidden smDown>
              <Typography variant="h2">
                Welcome back to Coinbag! Sign in to manage your crypto portfolio. Buy, Send, Earn and Track. One hub, all your digital assets.
              </Typography>
            </Hidden>
          </article>
        )}
        <Card className={classes.card}>
          {currentStep === LoginSteps.Form && (
            <SignInForm
              email={email}
              setEmail={setEmail}
              password={password}
              setPassword={setPassword}
              isProcessing={isProcessing}
              setIsProcessing={setIsProcessing}
              setCurrentStep={setCurrentStep}
              verificationStatus={verificationStatus}
              setVerificationStatus={setVerificationStatus}
              robot={robot}
              setRobot={(value) => setRobot(value)}
            />
          )}
          {currentStep === LoginSteps.TwoFactorAuthentication && (
            <>
              <article className={classes.titleWrapper}>
                <Typography className={classes.cardTitle} variant="h1"><em>2FA</em> confirmation</Typography>
                <Typography className={classes.subTitle} variant="h2">
                  For security, please enter your 2FA code(s)
                </Typography>
              </article>
              <SubmitWithFA
                authenticator
                onSubmit={handleClickSubmit}
                submitButtonText="Confirm"
              />
            </>
          )}
        </Card>
      </section>
    </main>
  );
};

SignIn.routePath = SignInRoute;
SignIn.loginRequired = false;
SignIn.allowedForAccessRevokedUser = true;
SignIn.displayName = 'Login';
export default SignIn;
