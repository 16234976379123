import { isValidPhoneNumber } from 'libphonenumber-js';

export const isValidString = (value?: string) => value && value?.length > 0;
export const isValidDate = (value?: Date) => value && value < new Date();
export const isValidPhone = (value?: string) => {
  if (!value) return true;
  return isValidPhoneNumber(`+${value}`);
};

export const dateIsValid = (date: string) => !Number.isNaN(new Date(date).getTime());
