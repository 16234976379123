import axios from "axios";
import Headers from './headers';
import * as moment from 'moment-timezone';
import tryParseJSONObject from "../utils/tryParseJSONObject";
import {getChart, getChartPoints, getDepositAndWithdrawalV2} from "./wallet-service";
import { custodialTxsFilter } from './OnboardService';

const API_URL = "/api/wallet/";

moment.tz.setDefault('Etc/UTC');
class WalletService {

  addWallet(exchangeId, apikey, secret) {
    return axios.post(API_URL + 'add-wallet',
      {
        exchangeId,
        apikey,
        secret
      },
      { headers: { ...Headers.def(), ...Headers.auth() } });
  }

  setRebalancingMode(rebalancingMode) {
    return axios.post(API_URL + 'setRebalancingMode',
      {
        rebalancingMode
      },
      { headers: { ...Headers.def(), ...Headers.auth() } });
  }

  addExchangeId(exchangeId) {
    return axios.post(API_URL + 'addExchangeId',
      {
        exchangeId
      },
      { headers: { ...Headers.def(), ...Headers.auth() } });
  }

  addExchangeIdSignUp(exchangeId) {
    return axios.post(API_URL + 'addExchangeIdSignUp',
      {
        exchangeId
      },
      { headers: { ...Headers.def(), ...Headers.auth() } });
  }

  removeExchangeId() {
    return axios.post(API_URL + 'removeExchangeId',
      {},
      { headers: { ...Headers.def(), ...Headers.auth() } });
  }

  liquidatePortfolio(walletId, bundleStateId){
    return axios.post(API_URL + 'liquidate-portfolio',
      {
        walletId,
        bundleStateId
      },
      { headers: { ...Headers.def(), ...Headers.auth() } });
  }

  enableWallet(walletId) {
    return axios.post(API_URL + 'enable-wallet',
      {
        walletId
      },
      { headers: { ...Headers.def(), ...Headers.auth() } });
  }

  disableWallet(walletId) {
    return axios.post(API_URL + 'disable-wallet',
      {
        walletId
      },
      { headers: { ...Headers.def(), ...Headers.auth() } });
  }

  getDexWallets(){
    return axios.get(API_URL + 'getDexWallets', { headers: { ...Headers.def(), ...Headers.auth() } });
  }

  getWalletStatus(){
    return axios.get(API_URL + 'getWalletStatus', { headers: { ...Headers.def(), ...Headers.auth() } }).then(txStatusData => {
      return txStatusData.data.map(txStatus => {
        if(txStatus.wallet.chainId === "56"){
          txStatus.coin = "BNB"
        }else{
          txStatus.coin = txStatus.coin.name
        }

        var balance = txStatus.balance + " " + txStatus.coin + " (~" + txStatus.usdtBalance?.toFixed(2) + " USDC)"
        if (txStatus.balance > 0 && txStatus.usdtBalance == 0) { //it could be that the exchange rate is not available
          balance = txStatus.balance + " " + txStatus.coin;
        }
        txStatus.balanceInt = txStatus.balance
        txStatus.balance = balance

        txStatus.depositStatus = "No deposits or transfers in progress. It may take a few minutes to appear, otherwise, check " + txStatus.coin + " deposit amounts below.."
        if (txStatus.pendingTxs.length > 0) {
          txStatus.depositStatus = "Transaction Pending";
        } else if (txStatus.pendingTxs.length == 0 && txStatus.usdtBalance > 0 && txStatus.usdtBalance < 100) {
          txStatus.depositStatus = "No deposits or transfers in progress. Transfer at least $100 in " + txStatus.coin + " if you are trying to activate DeFi";
        }

        return txStatus
      })
    })
  }

  getWalletDepositEvents(){
    return axios.get(API_URL + 'getWalletDepositEvents', { headers: { ...Headers.def(), ...Headers.auth() } });
  }

  getDepositsAndWithdrawalHistory(){
    return axios.get(API_URL + 'getDepositAndWithdrawalHistory', { headers: { ...Headers.def(), ...Headers.auth() } });
  }

  getReferralStats(){
    return axios.get(API_URL + 'getReferralStats', { headers: { ...Headers.def(), ...Headers.auth() } });
  }

  testConnection(exchangeId, apikey, secret) {
    return axios.post(API_URL + 'test-connection',
      {
        exchangeId,
        apikey,
        secret
      },
      { headers: { ...Headers.def(), ...Headers.auth() } });
  }

  testWalletConnection(walletId) {
    return axios.post(API_URL + 'test-wallet-connection',
      {
        walletId
      },
      { headers: { ...Headers.def(), ...Headers.auth() } });
  }

  getWallet() {
    return axios.get(API_URL + 'getWallet', { headers: { ...Headers.def(), ...Headers.auth() } });
  }

  getActiveId() {
    return axios.get(API_URL + 'getActiveId', { headers: {...Headers.def(),...Headers.auth() } });
  }

  getAllExchanges() {
    return axios.get(API_URL + 'all-exchange', { headers: { ...Headers.def(), ...Headers.auth() } });
  }

  getPerformancePeriod(walletId, startTimeUtc, endTimeUtc, lookbackIntervalInMinutes, removeDuplicatedEnabled, nDataPoints) {
    return axios.get(API_URL + 'performance-period/' +
      `${walletId}/${startTimeUtc}/${endTimeUtc}/${lookbackIntervalInMinutes}/${removeDuplicatedEnabled}/${nDataPoints}`,
      { headers: { ...Headers.def(), ...Headers.auth() } });
  }

  getChart(startTimeUtc, endTimeUtc) {
    return axios.get(`/api/chart/get70TotalAssetValues/${startTimeUtc}/${endTimeUtc}`,
      { headers: {...Headers.def(),...Headers.auth() } });
  }

  getBoxesChartPoints() {
    return axios.get(API_URL + 'getBoxesChartPoints', { headers: {...Headers.def(),...Headers.auth() } });
  }

  getDepositAndWithdrawalV2(startTimeUtc, endTimeUtc) {
    return axios.get(`${API_URL}getDepositsAndWithdrawalsV2/${startTimeUtc}/${endTimeUtc}`,
      { headers: {...Headers.def(),...Headers.auth() } });
  }

  getWalletsUsdBalance(ts) {
    return axios.get(`${API_URL}usdBalance/${ts}`, { headers: {...Headers.auth() }}).then((res) => res.data)
  }

  getPerformance(walletId, utcTimeList, lookbackIntervalInMinutes, removeDuplicatedEnabled) {
    return axios.post(API_URL + 'getPerformance/',
      { walletId, lookbackIntervalInMinutes, removeDuplicatedEnabled, utcTimeList },
      { headers: { ...Headers.def(), ...Headers.auth() } });
  }

  getMinMaxTs(walletId) {
    return axios.get(API_URL + 'min-max-ts/' + `${walletId}`,
      { headers: { ...Headers.def(), ...Headers.auth() } });
  }

  getLatestRiskScore(prioritizeQuestions) {
    return axios.get(API_URL + 'getLatestRiskScore/' + `${prioritizeQuestions}`,
      { headers: { ...Headers.def(), ...Headers.auth() } });
  }

  getOAuthState() {
    return axios.get(API_URL + 'getOAuthState', { headers: { ...Headers.def(), ...Headers.auth() } });
  }

  getSendwyreUrl() {
    return axios.get(API_URL + 'getSendwyreUrl', { headers: { ...Headers.def(), ...Headers.auth() } });
  }

  getTransakParams() {
    return axios.get(API_URL + 'getTransakParams', { headers: { ...Headers.def(), ...Headers.auth() } });
  }

  getMoonpayUrl(coinId) {
    return axios.get(API_URL + `getMoonpayUrl/${coinId}`, { headers: { ...Headers.def(), ...Headers.auth() } });
  }

  testOAuthConnection(code, state) {
    return axios.post(API_URL + 'testOAuthConnection', {code, state} , { headers: { ...Headers.def(), ...Headers.auth() } });
  }

  stringifyTs(dateTimes) {
    return dateTimes.join(",")
  }

  addRiskScore(riskScore){
    return axios.post(API_URL + 'addRiskScore',
      {
        riskScore
      },
      { headers: { ...Headers.def(), ...Headers.auth() } });
  }

  getLatestExchangeRate(coinId)  {
    return axios.get(API_URL + 'get-latest-exchange-rate/' + `${coinId}`,
      { headers: { ...Headers.def(), ...Headers.auth() } });
  }

  convertFromMasterCoin(fromMasterCoinId, toCoinId, qtyFrom)  {
    return axios.get(API_URL + `convertFromMasterCoin/${fromMasterCoinId}/${toCoinId}/${qtyFrom}`,
      { headers: { ...Headers.def(), ...Headers.auth() } })
      .then((response) => response.data);
  }

  convert(fromCoin, toMasterCoinId, qtyFrom)  {
    return axios.get(API_URL + `convert/${fromCoin}/${toMasterCoinId}/${qtyFrom}`,
      { headers: { ...Headers.def(), ...Headers.auth() } })
      .then((response) => response.data);
  }

  withdraw(fa, percentage, toAddress, coinId, memo)  {
    return axios.post(API_URL + 'withdraw',
      {
        ...fa,
        percentage,
        toAddress,
        coinId,
        memo: memo === undefined ? "" : memo,
      },
      { headers: { ...Headers.def(), ...Headers.auth() } });
  }

  withdrawToken = custodialTxsFilter((amount, toAddress, coinId, fa, memo) =>  {
    return axios.post(API_URL + 'withdraw-token',
      {
        ...fa,
        amount,
        toAddress,
        coinId,
        memo: memo === undefined ? "" : memo,
      },
      { headers: { ...Headers.def(), ...Headers.auth() } }).then((res) => res.data);
  })

  estimateWithdrawToken(amount, coinId, toAddress, cancelToken)  {
    return axios.post(API_URL + 'withdraw-token/estimate',
      {
        amount,
        coinId,
        toAddress
      },
      {
        headers: { ...Headers.def(), ...Headers.auth() },
        cancelToken,
      })
      .then((response) => response.data);
  }

  getWithdrawalStatus() {
    return axios.get(API_URL + 'getRebalancingStatus', { headers: { ...Headers.def(), ...Headers.auth() } })
      .then((response) => response.data?.status?.filter(x => x.name === 'withdrawal').length > 0);
  }

  getRebalancingStatus() {
    return axios.get(API_URL + 'getRebalancingStatus',
      { headers: { ...Headers.def(), ...Headers.auth() } })
      .then((response) => response.data || undefined);
  }

  getEstimateRebalance(walletId) {
    if (walletId) {
      return axios.get(API_URL + 'estimateRebalance/' + walletId,
        {headers: {...Headers.def(), ...Headers.auth()}})
        .then((response) => response.data);
    } else {
      return axios.get(API_URL + 'estimateRebalance',
        {headers: {...Headers.def(), ...Headers.auth()}})
        .then((response) => response.data);
    }
  }


  getRebalancingLogTransactions() {
    return axios.get(API_URL + 'getRebalancingLogTransactions', { headers: { ...Headers.def(), ...Headers.auth() } })
      .then((response) => response.data);
  }

  dexEstimateRebalancingCost(walletId, bundleStateId, walletAddress){
    return axios.post(API_URL + 'dex/estimateRebalancingCost',
      {
        walletId,
        bundleStateId,
        walletAddress
      },
      { headers: { ...Headers.def(), ...Headers.auth() } });
  }

  dexOneTimeRebalancing(walletId, bundleStateId, exchangeId, chainId){
    return axios.post(API_URL + 'dex/dexOneTimeRebalancing',
      {
        walletId,
        bundleStateId,
        exchangeId,
        chainId
      },
      { headers: { ...Headers.def(), ...Headers.auth() } });
  }

  getTotalInterest(){
    return axios.get(API_URL + 'interest',
      { headers: { ...Headers.def(), ...Headers.auth() } } ).then( response => {
        return response.data
    })
  }

  getTerraWalletKey() {
    return axios.get(API_URL + 'terraWalletKey',
      { headers: { ...Headers.def(), ...Headers.auth() } } ).then( response => {
      return response.data
    })
  }

  triggerRebalance() {
    return axios.post(`${API_URL}triggerRebalance`, {},
      {headers: {...Headers.def(), ...Headers.auth()}});
  }

  updateWalletEarningConfig(earningConfig = {}) {
    return axios.post(`${API_URL}updateWalletEarningConfig`, earningConfig,
      {headers: {...Headers.def(), ...Headers.auth()}})
  }

  getWallets() {
    return axios.get(API_URL + 'getWallets', { headers: { ...Headers.def(), ...Headers.auth() } });
  }

  createWallet(name, walletProvider, address) {
    return axios.post(API_URL + 'createWallet', { name, walletProvider, address },{ headers: { ...Headers.def(), ...Headers.auth() } });
  }

  getLatestWalletBalances() {
    return axios.get(API_URL + 'getLatestWalletBalances', { headers: { ...Headers.def(), ...Headers.auth() } }).then( response => {
      return response.data
    });
  }

  createTmpUserWallet(addresses, cloudFlareToken) {
    return axios.post(API_URL + 'createTmpUserWallet', { addresses, cloudFlareToken },{ headers: { ...Headers.auth() } })
  }

  setBasicWallet() {
    return axios.post(API_URL + 'setBasicWallet', undefined, { headers: { ...Headers.auth() }})
  }

  getSelectedMasterCoins(walletId) {
    return axios.get(API_URL + 'selectedMasterCoins ', { headers: { ...Headers.auth() } }).then((res) => res.data)
  }

  setIsNonCustodian(walletIds) {
    return axios.post(API_URL + 'setIsNonCustodian', walletIds,{ headers: { ...Headers.auth() }});
  }
}

export default new WalletService();
