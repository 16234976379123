// @flow
import { Box, createStyles, Hidden, useMediaQuery, useTheme } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Loading } from 'components';
import { PortfolioValueCard } from 'components/dashboard';
import { TokenItem } from 'components/DepositAndWithdraw/Components/TokenItem';
import { calculateWithdrawableBalance } from 'components/DepositAndWithdraw/utils';
import { Typography } from 'elements';
import { Scrollable } from 'elements/Scrollable';
import React, { useEffect, useMemo, useState } from 'react';
import SVG from 'react-inlinesvg';
import { useStore as useCoinStore } from 'store/zustand/Coin';
import { useStore as useWalletStore } from 'store/zustand/Wallet';

const useStyles = makeStyles((theme) => createStyles({
  root: {
    position: 'relative',
    width: '100%',
    [theme.breakpoints.up('md')]: {
      maxWidth: 368,
      boxShadow: '0px 4px 32px rgba(58, 60, 77, 0.04)',
      borderRadius: 16,
    },
  },
  content: {
    position: 'absolute',
    padding: 16,
    display: 'flex',
    flexDirection: 'column',
    gap: 16,
    width: '100%',
    paddingBottom: 0,
    [theme.breakpoints.down('sm')]: {
      position: 'relative',
    },
  },
  portfolioCardWrapper: {
    display: 'flex',
    width: '100%',
    height: 144,
  },
  availableBalanceHeader: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    backgroundColor: '#FAFBFF',
    borderTop: '1px solid',
    borderTopColor: theme.palette.grey[200],
    padding: '10px 16px',
    [theme.breakpoints.down('sm')]: {
      borderTop: 'unset',
      borderRadius: '8px 8px 0 0',
    },
  },
  lockedBalanceHeader: {
    display: 'flex',
    flexDirection: 'column',
    gap: 4,
    backgroundColor: '#FAFBFF',
    borderTop: '1px solid',
    borderTopColor: theme.palette.grey[200],
    padding: '10px 16px',
  },
  tokenItemWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    padding: '8px 16px',
    borderTop: '1px solid',
    borderTopColor: theme.palette.grey[200],
  },
  unAvailableTokenItem: {
    filter: 'grayscale(1)',
  },
  balanceDetailsWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  showTokensWrapper: {
    display: 'flex',
    gap: 8,
    alignItems: 'center',
    '&:hover': {
      cursor: 'pointer',
    },
  },
  showTokensArrow: {
    display: 'flex',
    '& path': {
      fill: theme.palette.green[600],
    },
    '&.show': {
      '& path': {
        fill: theme.palette.grey[500],
      },
      transform: 'rotate(180deg)',
    },
  },
  arrowIconWrapper: {
    width: 18,
    height: 18,
  },
  balancesWrapper: {
    display: 'flex',
    flexDirection: 'column',
    [theme.breakpoints.up('md')]: {
      margin: '0 -16px',
    },
    [theme.breakpoints.down('sm')]: {
      border: '1px solid',
      borderColor: theme.palette.grey[200],
      borderRadius: 8,
    },
  },
  smallText: {
    fontSize: 10,
    fontWeight: 400,
  },
  lockedBalanceHeaderWrapper: {
    display: 'flex',
    alignItems: 'center',
    gap: 4,
  },
  lockIcon: {
    '& path:first-child': {
      stroke: theme.palette.yellow[500],
    },
    '& path:last-child': {
      fill: theme.palette.yellow[500],
    },
  },
  noData: {
    color: theme.palette.grey[500],
    fontSize: 12,
    fontWeight: 400,
    lineHeight: '24px',
    textAlign: 'center',
    alignSelf: 'center',
    justifySelf: 'center',
    padding: 16,
    width: '100%',
    borderTop: '1px solid',
    borderTopColor: theme.palette.grey[200],
  },
  loading: {
    position: 'absolute',
    top: 0,
    left: 0,
    zIndex: 2,
    backdropFilter: 'blur(12px)',
    background: 'rgba(255, 255, 255, 0.6)',
  },
}));

export const AccountDetailsSection = () => {
  const classes = useStyles();
  const [showTokens, setShowTokens] = useState<boolean>(false);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const isMD = useMediaQuery(theme.breakpoints.down('md'));
  const [masterCoinsFromName, coins, fetchCoins] = useCoinStore((state) => [
    state.masterCoinsFromName,
    state.coins,
    state.fetchCoins,
  ]);
  const [
    activeWalletBalance,
    maxPerf,
    fetchMinMaxPerf,
    fetchActiveWalletBalance,
    walletBalances,
    walletsTs,
  ] = useWalletStore(
    (state) => ([
      state.activeWalletBalance,
      state.maxPerf,
      state.fetchMinMaxPerf,
      state.fetchLatestWalletBalances,
      state.walletBalances,
      state.walletsTs,
    ]),
  );

  const [isLoading, setIsLoading] = useState<boolean>(true);

  useEffect(() => {
    (async () => {
      await Promise.allSettled([
        fetchCoins(), fetchMinMaxPerf(true), fetchActiveWalletBalance(),
      ]);
      setIsLoading(false);
    })();
  }, []);

  const { available, locked } = useMemo(() => (
    calculateWithdrawableBalance(masterCoinsFromName, coins, activeWalletBalance, maxPerf)
  ), [maxPerf, masterCoinsFromName, activeWalletBalance]);

  const handleShowTokens = () => { setShowTokens((prev) => !prev); };
  if (isMD && !isMobile) return null;
  return (
    <Scrollable className={classes.root}>
      <Hidden smDown>
        {isLoading && <Loading className={classes.loading} />}
      </Hidden>
      <Box className={classes.content}>
        <PortfolioValueCard
          className={classes.portfolioCardWrapper}
          value={walletBalances ?? 0}
          disableButtons
          lastUpdate={walletsTs}
        />
        <Hidden mdUp>
          <Box className={classes.balanceDetailsWrapper}>
            <Typography variant="h6" paletteColor={700}>
              {available.length > 0 ? `${available.length.toLocaleString()} Tokens` : 'No token'} available to withdraw
            </Typography>
            {available.length > 0 && (
              <Box className={classes.showTokensWrapper} onClick={handleShowTokens}>
                <Typography
                  variant="h6"
                  palette={showTokens ? 'grey' : 'green'}
                  paletteColor={showTokens ? 600 : 500}
                  align="right"
                >
                  {showTokens ? 'Hide' : 'Show tokens'}
                </Typography>
                <Box className={`${classes.showTokensArrow} ${showTokens ? 'show' : ''} `}>
                  <SVG src="/icons/dashboard/ic_arrow_down.svg" width={18} height={18} />
                </Box>
              </Box>
            )}
          </Box>
        </Hidden>
        {(!isMobile || showTokens) && (
          <Box className={classes.balancesWrapper}>
            <Box className={classes.availableBalanceHeader}>
              <Typography variant="h6" paletteColor={700}>Available to withdraw</Typography>
              <Typography variant="h6" paletteColor={500} strong={false}>
                Supported Networks
              </Typography>
            </Box>
            {available.length > 0 ? available.map((coin) => (
              <Box key={coin.id} className={classes.tokenItemWrapper}>
                <TokenItem {...coin} isSvg useSymbol />
              </Box>
            )) : (
              <Typography className={classes.noData}>No available token</Typography>
            )}
            {locked.length > 0 && (
              <Box className={classes.lockedBalanceHeader}>
                <Box className={classes.lockedBalanceHeaderWrapper}>
                  <Typography variant="h6" paletteColor={700}>Not available</Typography>
                  <Typography variant="h6" paletteColor={500}>Currently earning interest</Typography>
                  <SVG src="icons/ic_lock.svg" width={16} height={16} className={classes.lockIcon} />
                </Box>
                <Typography paletteColor={500} className={classes.smallText}>
                  Unstake on “Interest earnings” page to make available
                </Typography>
              </Box>
            )}
            {locked.map((coin) => (
              <Box key={coin.id} className={`${classes.tokenItemWrapper} ${classes.unAvailableTokenItem}`}>
                <TokenItem {...coin} isSvg useSymbol />
              </Box>
            ))}
          </Box>
        )}
      </Box>
    </Scrollable>
  );
};
