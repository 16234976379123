import { Box, withStyles } from '@material-ui/core';
import * as React from 'react';

interface Props {
  children: string;
}

const GreenBadgeStyled = withStyles({
  root: {
    color: '#1A9071',
    border: '1.5px solid #1A9071',
    borderRadius: '2px',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '3px 6px',
    marginBottom: '10px !important',
    display: 'inline-flex',
    fontSize: '12px',
    lineHeight: '14px',
    fontWeight: 500,
  },
})(Box);

const GreenBadge: React.FC<Props> = ({ children }: Props) => {
  return (
    <GreenBadgeStyled>
      {children}
    </GreenBadgeStyled>
  );
};

GreenBadge.displayName = 'GreenBadge';

export default GreenBadge;
