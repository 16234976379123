import React from "react";
import { Redirect } from "react-router-dom";
import { Footer } from "../../components/Footer.js";
import { InternalNavbar } from "../../components/navbar.js";
import { Container, Row, Col, ProgressBar, Modal } from 'react-bootstrap'
import Loader from "../../components/Loader.js";
import SharedStateComponent from "../../components/SharedStateComponent.js";
import QuestionService from "../../services/question.service.js";
import { FIELD, sleep } from "../../common.js";
import Question from "../../components/Question.js";
import { ExitGreyIcon, LeftArrowIcon } from "../../components/Icons.js";
import AuthService from "../../services/auth-service.js";
import { QuestionIcon } from "../../components/Icons.js";
import Logger from "js-logger";


export default class RiskAssessment extends SharedStateComponent {
  static routePath = '/risk-assessment';
  static loginRequired = true;

  constructor(props) {
    super(props);

    this.submitAnswer = this.submitAnswer.bind(this);
    this.questionsTransition = this.questionsTransition.bind(this);

    this.state = {
      questions: [],
      changed: false,
      from: this.props.location.state?.from ? this.props.location.state?.from : ''
    };
  }

  componentDidMount() {
    QuestionService.getLatestQuestionsWithAnswers().then(resp => {
      var attemptId = 1
      if (resp.data.length > 0) {
        const attempts = resp.data.map(item => (item.attemptId)).filter(x => x)
        attemptId = Math.max(...attempts, 0) + 1
      }

      resp.data.map(q => {
        q.userAnswer = undefined
      })

      this.setState({
        attemptId,
        questions: resp.data,
        currentQuestion: 0,
        justMounted: true
      })
    })
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.question?.value !== this.state.question?.value && this.state.question?.value !== undefined && this.state.currentQuestion === prevState.currentQuestion) {
      this.setState({
        error: false,
        changed: true
      })
    }

    if (this.state.clear === true && this.state.currentQuestion === 0) {
      this.props.history.push({
        pathname: this.state.from,//ManageRisk.routePath,
        state: { prioritizeQuestions: true }
      });
    }
  }

  static getDerivedStateFromProps(props, state) {
    Logger.log("PROPS", props.location.state)
    Logger.log("STATE", state)
    Logger.log(props.location.state?.currentQuestion)
    Logger.log(props.location.state?.currentQuestion !== state.currentQuestion)

    if (state.justMounted) {
      props.location.state = {}
      return { justMounted: false }
    } else if (props.location.state?.currentQuestion !== undefined &&
      props.location.state.currentQuestion !== state.currentQuestion && state.justMounted !== true) {
      Logger.log("RETURNING PROPS")
      return {
        currentQuestion: props.location.state.currentQuestion,
        questions: state.questions,
        question: FIELD(state.questions[props.location.state.currentQuestion]?.userAnswer),
        changed: props.location.state.changed
      }
    } else {
      Logger.log("RETURNING STATE")
      return {}
    }
  }

  nextQuestion(questions) {
    if (this.state.currentQuestion >= questions.length - 1) {
      return -1
    } else {
      return this.state.currentQuestion + 1
    }
  }

  submitAnswer(e) {
    e.preventDefault();

    var eventId = e.currentTarget.dataset.id
    Logger.log("CHANGED", this.state.changed)
    this.setState({ error: false })

    if (eventId === "next" && this.state.question?.value === undefined && this.state.questions[this.state.currentQuestion].compulsory) {
      return
    }

    if (!this.state.changed) {
      this.questionsTransition(eventId, this.state.questions[this.state.currentQuestion].userAnswer, this.state.changed)
      return
    }

    QuestionService.addAnswer(this.state.attemptId, this.state.questions[this.state.currentQuestion].questionId, this.state.question.value).then(resp => {
      if (resp.data == -1) {
        Logger.log("BAD")
      } else {
        this.questionsTransition(eventId, resp.data, this.state.changed)
      }
    })
  }

  questionsTransition(eventId, answer, changed) {
    const q = [...this.state.questions]
    q[this.state.currentQuestion].userAnswer = answer

    // if (changed) {
    var state = {
      questions: q,
      currentQuestion: this.state.currentQuestion,
      question: this.state.question,
      changed: false
    }
    window.history.replaceState({ state }, "")
    // }

    const nextQuestion = this.nextQuestion(q)

    if (eventId === "back") {
      this.props.history.goBack();
    } else if (nextQuestion !== -1) {
      var state = {
        questions: q,
        currentQuestion: nextQuestion,
        question: FIELD(q[nextQuestion].userAnswer),
        changed: false
      }
      this.props.history.push({ state })

    } else {
      this.setState({
        clear: true
      })
      AuthService.updateUserStep().then(resp => { });
      sleep(5000).then(() => {
        this.props.history.go(-(this.state.questions.length - 1))
      })
    }
  }

  renderWithSharedState() {
    if (this.state.redirect) {
      return <Redirect push to={{
        pathname: this.state.redirect,
        state: this.state.redirectState
      }} />
    }


    const canRender = this.state.questions.length > 0
    Logger.log("STATE AFTER", this.state);

    if (!canRender || this.state.clear) {
      var text = undefined
      if (this.state.clear){
        text = "One moment as we match your answers with the most suitable risk profile..."
        return (<><Loader loading={true} shouldRemoveLoader={false} text={text} progress={5000} /></>)
      }else{
        return (<><Loader loading={true} shouldRemoveLoader={false} /></>)
      }
    }

    const isLastQuestion = this.state.currentQuestion === this.state.questions.length - 1
    const isCompulsory = this.state.questions[this.state.currentQuestion].compulsory

    return (
      <>
        <div style={{ minHeight: "calc( 100vh - 1.5rem - 163px - 60px)" }}>
          <Loader loading={false} shouldRemoveLoader={false} />

          <InternalNavbar history={this.props.history} />

          <Container className="px-4 py-3">
            <Row>
              <Col data-id="back" onClick={this.submitAnswer}>
                <LeftArrowIcon />
              </Col>
            </Row>
            <Row>
              <Col>
                <p className="p4">Question {this.state.currentQuestion + 1} of {this.state.questions.length}</p>
                <ProgressBar now={100 / this.state.questions.length * (this.state.currentQuestion + 1)} variant="custom" />
              </Col>
            </Row>
            <Row>
              <Col>
                <Container className="content-card p-4">
                  <Row className="mt-0">
                    <Col>
                      <Question name="question" data={this.state.questions[this.state.currentQuestion]} />
                      {this.state.error && (
                        <p className="error-red">This question is mandatory</p>
                      )}
                    </Col>
                  </Row>
                  <Row className="mt-2">
                    <Col>
                      <p className="p6 grey mb-0" onClick={() => this.setState({whyneed:true})}>Why do we need to know this? <QuestionIcon /></p>
                    </Col>
                  </Row>
                  {isLastQuestion && (
                    <Row className="text-right mt-4">
                      <Col data-id="next" onClick={this.submitAnswer}>
                        <p className={("p3 ") + (!this.state.question.value && isCompulsory ? "grey" : "green")}>See risk level results </p>
                      </Col>
                    </Row>
                  )}
                  {!isLastQuestion && isCompulsory && (
                    <Row className="justify-content-end mt-4">
                      <Col data-id="next" xs={3} onClick={this.submitAnswer}>
                        <p className={("p3 ") + (this.state.question?.value ? "green" : "grey")}>Next</p>
                      </Col>
                    </Row>
                  )}
                  {!isLastQuestion && !isCompulsory && (
                    <Row className="justify-content-end mt-4">
                      <Col data-id="skip" xs={3} className="text-right" onClick={this.submitAnswer}>
                        <p className="p3">Skip</p>
                      </Col>
                      <Col data-id="next" xs={3} onClick={this.submitAnswer}>
                        <p className="p3 green">Next</p>
                      </Col>
                    </Row>
                  )}
                </Container>
              </Col>
            </Row>
          </Container>

          <Modal centered show={this.state.whyneed} onHide={()=>this.setState({whyneed:false})}>
            <Modal.Body className="p-4">
              <Container>
                <Row className="mt-0 justify-content-end">
                  <Col className="text-right pr-0" xs={3} onClick={()=>this.setState({whyneed:false})}>
                    <ExitGreyIcon />
                  </Col>
                </Row>
                <Row>
                <p>Every investor has a different entry point, purpose and objective for investing, especially in cryptocurrency. By helping us understand more about you, we can provide the best recommendations to help you achieve your goals.</p>
                </Row>
              </Container>
            </Modal.Body>
          </Modal>

        </div>
        <Footer />
      </>
    );
  }
}
