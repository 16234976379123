import { Box, makeStyles } from '@material-ui/core';
import { Typography } from 'elements';
import React from 'react';
import { DisclaimerRoute } from 'routes';

const useStyles = makeStyles((theme) => ({
  disclaimerContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'stretch',
    marginTop: 32,

    [theme.breakpoints.up('md')]: {
      margin: '32px auto 0',
      maxWidth: 720,
    },
  },

  updateText: {
    color: theme.palette.grey[400],
    fontSize: 14,
    lineHeight: '24px',
    textTransform: 'uppercase',
  },
  disclaimerText: {
    fontSize: 12,
    fontWeight: 500,
    lineHeight: '20px',
    color: theme.palette.grey[700],

    [theme.breakpoints.up('md')]: {
      fontSize: 16,
      lineHeight: '24px',
    },
  },
}));

const Disclaimer = () => {
  const classes = useStyles();
  return (
    <>
      <Typography variant="h1">
        <em>Coinbag</em> Disclaimer
      </Typography>

      <Box className={classes.disclaimerContainer}>
        <Typography className={classes.updateText}>
          Last updated: May 23, 2021
        </Typography>

        <Typography className={classes.disclaimerText}>
          <br />
          Coinbag puts transparency first. If at any time, you have any questions regarding our terms of use or
          privacy policy, please contact us directly at{' '}
          <a href="mailto:admin@coinbag.finance">admin@coinbag.finance</a> and we will respond within 1 working day.

          <br /><br />
          Coinbag is not a broker dealer, transactional intermediary, counterparty or investment advisor. Coinbag is
          not registered with the Securities and Exchange Commission (SEC) as an Investment Advisor. Coinbag does not
          provide investment or trading advice. Any investment decision a user of the Coinbag platform may make is
          solely at his or her own discretion and risk. Trading in financial instruments is risky and can result in
          losses greater than the trader’s initial deposit. Hypothetical back-testing and real-time track records
          should not be relied upon in predicting future performance. Coinbag and its agents, brokers, affiliates or
          employees do not prepare and cannot be responsible for data and data compilation contained in or derived
          from back-testing and simulation features.
        </Typography>
      </Box>
    </>
  );
};

Disclaimer.routePath = DisclaimerRoute;
Disclaimer.loginRequired = false;
export default Disclaimer;
