import 'pure-react-carousel/dist/react-carousel.es.css';

import { Box, makeStyles } from '@material-ui/core';
import { ButtonBack, ButtonNext,CarouselProvider, Slide, Slider } from 'pure-react-carousel';
import React, { memo } from 'react';
import SVG from 'react-inlinesvg';

import { FeatureSlide } from './FeatureSlide';

const useStyles = makeStyles((theme) => ({
  root: { width: '100%' },

  button: {
    display: 'none',
    padding: 0,
    width: 24,
    height: 24,

    background: theme.palette.blue[500],
    borderRadius: 9999,
    border: 0,

    [theme.breakpoints.up('sm')]: {
      display: 'flex',
      position: 'absolute',
      top: '50%',
    },

    '& > svg': {
      color: 'white',
    },
  },

  backButton: {
    left: 0,
    transform: 'translateX(-50%) translateY(-50%)',
    '& > svg': {
      transform: 'rotate(90deg)',
    },
  },
  nextButton: {
    right: 0,
    transform: 'translateX(50%) translateY(-50%)',
    '& > svg': {
      transform: 'rotate(-90deg)',
    },
  },

  slide: {
    paddingBottom: '0px !important',
    height: 92,
  },
  innerSlide: {
    display: 'flex',
    alignItems: 'center',
  },
}));

const features = [{
  title: <><em>Multi-Wallet</em><br />management</>,
  icon: '/images/home-v2/features/multi-wallet.png',
}, {
  title: <><em>Team access</em><br />management</>,
  icon: '/images/home-v2/features/team-access.png',
}, {
  title: <><em>Multi-level</em><br />approval controls</>,
  icon: '/images/home-v2/features/multi-level.png',
}, {
  title: <>Batch<br /><em>payroll</em></>,
  icon: '/images/home-v2/features/batch-payroll.png',
}, {
  title: <>Batch<br /><em>airdrops</em></>,
  icon: '/images/home-v2/features/batch-airdrop.png',
}, {
  title: <><em>Reporting</em><br />and <em>tracking</em></>,
  icon: '/images/home-v2/features/reporting.png',
}, {
  title: <>and <em>many</em><br /><em>more</em> features</>,
  icon: '/images/home-v2/features/more.png',
}];

const FeatureCarousel = memo(() => {
  const classes = useStyles();
  return (
    <Box className={classes.root}>
      <CarouselProvider
        naturalSlideWidth={136}
        naturalSlideHeight={92}
        totalSlides={features.length}
        visibleSlides={3}
        interval={2000}
        // isPlaying
        infinite
      >
        <Box position="relative">
          <Slider>
            {features.map((f, index) => (
              <Slide key={index} index={index} className={classes.slide} innerClassName={classes.innerSlide}>
                <FeatureSlide index={index} {...f} />
              </Slide>
            ))}
          </Slider>

          <ButtonBack className={`${classes.button} ${classes.backButton}`}>
            <SVG src="/icons/icon_chevron.svg" />
          </ButtonBack>
          <ButtonNext className={`${classes.button} ${classes.nextButton}`}>
            <SVG src="/icons/icon_chevron.svg" />
          </ButtonNext>
        </Box>
      </CarouselProvider>
    </Box>
  );
});

FeatureCarousel.displayName = 'FeatureCarousel';
export { FeatureCarousel };
