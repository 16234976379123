/* eslint-disable no-param-reassign */
import { Box, makeStyles } from '@material-ui/core';
import { Card, Typography } from 'elements';
import { Period, PeriodLabel } from 'models';
import moment from 'moment';
import React from 'react';
import { DepositWithdrawV2, PointV2, PointV2Response } from 'services/types/wallet';
import walletService from 'services/wallet-service';
import { useStore as useWalletStore } from 'store/zustand/Wallet';
import { palette } from 'theme';

moment.tz.setDefault('Etc/UTC');

type PeriodItem = {
  periodLabel: PeriodLabel;
  depositEvents: DepositWithdrawV2[]
  points: PointV2[];
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexDirection: 'column',
    alignItems: 'center',
    rowGap: 20,
    padding: '20px 16px',
    [theme.breakpoints.down('xs')]: {
      rowGap: 8,
      padding: 16,
    },
  },
  subsectionColumn: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    rowGap: 8,
    width: '100%',
  },
  subsectionTitle: {
    color: theme.palette.grey['500'],
    fontSize: 12,
    fontWeight: 400,
    lineHeight: '16px',
  },
  periodsGrid: {
    display: 'grid',
    gridTemplateColumns: 'repeat(3, minmax(0, 1fr))',
    columnGap: 18,
    width: '100%',
    [theme.breakpoints.down('xs')]: {
      columnGap: 8,
    },
  },
  periodBox: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    rowGap: 12,

    padding: '20px 12px',
    border: '1px solid',
    borderColor: theme.palette.grey['200'],
    borderRadius: 8,

    textAlign: 'center',
    cursor: 'pointer',
    [theme.breakpoints.down('xs')]: {
      rowGap: 4,
      padding: 12,
    },
  },
  periodBoxActive: {
    background: theme.palette.green['50'],
    borderColor: theme.palette.green['400'],
  },
  periodGainRow: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    gap: 4,
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column',
    },
  },
  periodGain: {
    color: 'inherit',
    fontSize: 14,
    fontWeight: 600,
    lineHeight: '20px',
  },
  periodGainPercentage: {
    color: 'inherit',
    fontSize: 12,
    fontWeight: 500,
    lineHeight: '20px',
  },
  periodLabel: {
    fontSize: 12,
    fontWeight: 600,
    lineHeight: '16px',
    color: theme.palette.grey[600],
    textTransform: 'uppercase',
  },
}));

const EarningsOverviewChart = React.lazy(() => import('./EarningsOverviewChart'));

export const EarningsOverviewSection = () => {
  const classes = useStyles();

  const [fetchActiveWalletBalance, walletsTs, latestWalletBalances] = useWalletStore((state) => [
    state.fetchLatestWalletBalances,
    state.walletsTs,
    state.latestWalletBalances,
  ]);
  const [periods, setPeriods] = React.useState<Period[]>([
    { ts: moment(fromNowTo(0, 'YTD')).valueOf(), label: 'YTD', gain: '--', gainPercentage: '', class: 'zero' },
    { ts: moment(fromNowTo(-30)).valueOf(), label: '30D', gain: '--', gainPercentage: '', class: 'zero' },
  ]);
  const [periodsData, setPeriodsData] = React.useState<PeriodItem[]>([
    { periodLabel: 'All time', depositEvents: [], points: [] },
    { periodLabel: 'YTD', depositEvents: [], points: [] },
    { periodLabel: '30D', depositEvents: [], points: [] },
  ]);
  const [selectedPeriod, setSelectedPeriod] = React.useState(0);
  const [loading, setLoading] = React.useState(false);

  React.useEffect(() => {
    setLoading(true);

    (async () => {
      setPeriods([
        {
          ts: 0,
          label: 'All time',
          gain: '--',
          gainPercentage: '',
          class: 'zero',
        },
        ...periods,
      ]);

      await fetchActiveWalletBalance();
    })();
  }, []);

  React.useEffect(() => {
    if (latestWalletBalances) {
      updateCharts();
    }
  }, [selectedPeriod, latestWalletBalances]);

  const updateCharts = async () => {
    if (!walletsTs) {
      if (latestWalletBalances) {
        setLoading(false);
      }
      return;
    }

    const currentSelectedPeriod = periods[selectedPeriod];

    let fBoxes;
    try {
      fBoxes = await walletService.getBoxesChartPoints();
    } catch {
      setLoading(false);
      return;
    }

    const [fCharts, fDepositWithdraw] = await Promise.all([
      walletService.getChart(Math.max(currentSelectedPeriod.ts, fBoxes.data[0]?.ts ? Date.parse(fBoxes.data[0].ts) : 0), walletsTs.valueOf()),
      walletService.getDepositAndWithdrawalV2(currentSelectedPeriod.ts, walletsTs.valueOf()),
    ]);

    // Convert from response to PointV2
    const charts = fCharts.data.map(
      (d) => ({ ...d, ts: Date.parse((d.ts as string)) }),
    ).sort((a, b) => a.ts - b.ts) as PointV2[];
    const depositWithdraw = fDepositWithdraw.data.map(
      (d) => ({ ...d, ts: Date.parse((d.ts as unknown as string)) }),
    ).sort((a, b) => a.ts - b.ts) as DepositWithdrawV2[];
    const boxes = fBoxes;

    periodsData[selectedPeriod] = {
      periodLabel: currentSelectedPeriod.label,
      depositEvents: depositWithdraw,
      points: charts,
    };
    setPeriodsData(periodsData);

    const [startValue, currentValue, days30Value, ytdValue] = boxes.data;
    const calculateAndSetPeriod = (start: PointV2Response, end: PointV2Response, index: number) => {
      if (end?.totalAssetValue != null && start?.totalAssetValue != null) {
        const depositWithdrawValue = periodsData[0].depositEvents
          .filter((dw) => dw.ts > Date.parse(start.ts))
          .reduce((a, b) => (b.pointType === 'deposit' ? a + (b.transferredValue ?? 0) : a - (b.transferredValue ?? 0)), 0);
        const normalizedCurrentAssetValue = end.totalAssetValue - depositWithdrawValue;
        const gain = normalizedCurrentAssetValue - start.totalAssetValue;
        const symbol = gain >= 0 ? '+' : '';

        const numeratorPercentage = (normalizedCurrentAssetValue - (start.totalAssetValue ?? 0));
        const denominatorPercentage = ((start.totalAssetValue ?? 0) + depositWithdrawValue);

        if (denominatorPercentage !== 0) {
          periods[index] = {
            ...periods[index],
            gain: symbol + gain.toLocaleString(undefined, { maximumFractionDigits: 2, minimumFractionDigits: 2 }),
            gainPercentage: `${((numeratorPercentage / denominatorPercentage) * 100).toFixed(2)}%`,
            class: gain < 0 ? 'negative' : 'positive',
          };
        }
      }
    };

    calculateAndSetPeriod(startValue, currentValue, 0);
    calculateAndSetPeriod(ytdValue, currentValue, 1);
    calculateAndSetPeriod(days30Value, currentValue, 2);

    setPeriods(periods);
    setLoading(false);
  };

  const onChangeTimePeriod = (id: number) => {
    setSelectedPeriod(id);
    setLoading(true);
  };

  return (
    <Card className={classes.root} isSection isLoading={loading}>
      <Typography variant="h2" paletteColor={700} align="center">Earnings Overview</Typography>
      <Box className={classes.subsectionColumn}>
        <Typography className={classes.subsectionTitle}>Filter by timeframe</Typography>
        <Box className={classes.periodsGrid}>
          {periods.map((period, index) => (
            <Box
              key={index}
              className={`${classes.periodBox} ${index === selectedPeriod && classes.periodBoxActive}`}
              onClick={() => onChangeTimePeriod(index)}
            >
              <Box
                className={classes.periodGainRow}
                color={period.class === 'negative' ? palette.red['600'] : palette.green['600']}
              >
                <Typography className={classes.periodGain}>{period.gain}</Typography>
                <Typography className={classes.periodGainPercentage}>{period.gainPercentage}</Typography>
              </Box>
              <Typography className={classes.periodLabel}>{period.label}</Typography>
            </Box>
          ))}
        </Box>
      </Box>
      <React.Suspense fallback={<></>}>
        <EarningsOverviewChart
          points={periodsData[selectedPeriod].points}
          deposits={periodsData[selectedPeriod].depositEvents}
        />
      </React.Suspense>
    </Card>
  );
};

const fromNowTo = (to = 0, type = 'D') => {
  const from = new Date();
  if (type === 'YTD') {
    return new Date(from.getFullYear(), 0, 1).getTime();
  } if (type === 'D') {
    return from.setDate(from.getDate() + to);
  } if (type === 'H') {
    return from.setHours(from.getHours() + to);
  }
  return undefined;
};
