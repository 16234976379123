import React from 'react';
import SharedStateComponent from '../components/SharedStateComponent.js';

import { Container, Row, Col, Modal } from 'react-bootstrap';
import { ExitGreyIcon } from './Icons';

export default class GenericModal extends SharedStateComponent {
  constructor(props) {
    super(props);
  }

  render() {
    const handleClose = () => {
      this.props.hide ? this.props.hide() : this.updateState(false);
    };

    return (
      <Modal
        centered
        contentClassName={this.props.transparent ? 'transparent' : ''}
        show={this.props.show ? this.props.show : this.getState()}
        onHide={handleClose}
      >
        <Modal.Body className={this.props.fullScreen ? 'p-0' : 'p-4'}>
          <Container className={this.props.fullScreen ? 'm-0 p-0' : ''}>
            {this.props.close !== false && (
              <Row className="mt-0 justify-content-end">
                <Col className="text-right pr-0" xs={3} onClick={handleClose}>
                  <ExitGreyIcon className="pointer" />
                </Col>
              </Row>
            )}
            <Row className={this.props.fullScreen ? 'mt-0' : ''}>
              {this.props.children}
            </Row>
          </Container>
        </Modal.Body>
      </Modal>
    );
  }
}
