// @flow
import { Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Button, Typography } from 'elements';
import LightModal from 'elements/LightModal';
import * as React from 'react';
import { useStore as usePayrollStore } from 'store/zustand/Payroll';

interface RemoveModalProps {
  id?: number;
  // eslint-disable-next-line no-unused-vars
  setShowModal: (value: boolean) => void;
  showModal: boolean;
  onClose: () => void;
}

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    gap: 8,
    width: '100%',
    textAlign: 'center',
  },
  subTitle: {
    fontSize: 14,
    lineHeight: '24px',
  },
  buttonRow: {
    display: 'flex',
    justifyContent: 'space-between',
    gap: 64,
    marginTop: 16,
  },
}));

export const RemoveModal = ({ id, setShowModal, showModal, onClose }: RemoveModalProps) => {
  const classes = useStyles();
  const [deletePayroll] = usePayrollStore((state) => [state.delete]);
  return (
    <LightModal
      open={showModal}
      setOpen={setShowModal}
      maxWidth={328}
      onClose={() => { setShowModal(false); onClose(); }}
      showCloseButton
    >
      <Box className={classes.root}>
        <Typography paletteColor={800} variant="h5">Remove address</Typography>
        <Typography paletteColor={700} variant="h4">
          Are you sure you want to remove the address?
        </Typography>
        <Box className={classes.buttonRow}>
          <Button fullWidth variant="secondary" size="md" onClick={() => { setShowModal(false); onClose(); }}>
            Cancel
          </Button>
          <Button
            fullWidth
            color="red"
            variant="primary"
            size="md"
            onClick={async () => { if (id) { await deletePayroll(id); setShowModal(false); onClose(); } }
            }>
            Remove
          </Button>
        </Box>
      </Box>
    </LightModal>
  );
};

export default RemoveModal;
