import axios, { AxiosResponse } from 'axios';
import {
  WorkItem,
  WorkItemApproverStatus,
  WorkItemAutomationType,
  WorkItemStatus,
} from 'services/types/workItem';

import tryParseJSONObject from '../utils/tryParseJSONObject';
import Headers from './headers';
import { FAResponseStatus } from './types/wallet';

const API_URL = '/api/workItems/';

const getWorkItems = (): Promise<WorkItem[]> => axios.get(`${API_URL}getWorkItems`,
  { headers: { ...Headers.def(), ...Headers.auth() } })
  .then((response: AxiosResponse<WorkItem[]>) => {
    const { data } = response;
    return data.map((x: WorkItem) => ({
      ...x,
      parameters: tryParseJSONObject(x.parameters) || x.parameters,
      data: tryParseJSONObject(x.data) || x.data,
    }));
  });

const getWorkItemsForCurrentUser = (): Promise<WorkItem[]> => axios.get(`${API_URL}getWorkItemsForCurrentUser`,
  { headers: { ...Headers.def(), ...Headers.auth() } })
  .then((response: AxiosResponse<WorkItem[]>) => {
    const { data } = response;
    return data.map((x: WorkItem) => ({
      ...x,
      parameters: tryParseJSONObject(x.parameters) || x.parameters,
      data: tryParseJSONObject(x.data) || x.data,
    }));
  });

const updateWorkItem = (
  workItemId: number,
  automationType: WorkItemAutomationType,
  status: WorkItemStatus,
): Promise<WorkItem> => axios.post(`${API_URL}updateWorkItem`,
  {
    workItemId,
    automationType,
    status,
  },
  { headers: { ...Headers.def(), ...Headers.auth() } })
  .then((response: AxiosResponse<WorkItem>) => response.data);

const approveWorkItem = (
  workItemId: number,
  emailCode?: string,
): Promise<FAResponseStatus | undefined> => axios.post(`${API_URL}updateWorkItemApproveStatus`,
  {
    workItemId,
    status: WorkItemApproverStatus.Approved,
    emailCode,
  },
  { headers: { ...Headers.def(), ...Headers.auth() } })
  .then((response) => response.data);

const rejectWorkItem = (
  workItemId: number,
): Promise<boolean> => axios.post(`${API_URL}updateWorkItemApproveStatus`,
  {
    workItemId,
    status: WorkItemApproverStatus.Rejected,
  },
  { headers: { ...Headers.def(), ...Headers.auth() } })
  .then((response: AxiosResponse<boolean>) => response.data);

const checkDelayed = (openedTs: Date) => {
  const now = new Date();
  return now.getTime() - openedTs.getTime() > 3600000;
};

export default {
  getWorkItems,
  getWorkItemsForCurrentUser,
  updateWorkItem,
  checkDelayed,
  approveWorkItem,
  rejectWorkItem,
};
