import React from "react";
import { Footer } from "../../components/Footer.js";
import { InternalNavbar } from "../../components/navbar.js";
import { Container, Row, Col, Modal } from 'react-bootstrap'
import Loader from "../../components/Loader.js";
import SharedStateComponent from "../../components/SharedStateComponent.js";
import { FIELD} from "../../common.js";
import { RadioGreen, ExitGreyIcon, LogoAndBinanceIcon } from "../../components/Icons.js";
import WalletService from "../../services/wallet-service.js";
import Logger from "js-logger";


export default class EditExchange extends SharedStateComponent {
  static routePath = '/edit-exchange';
  static loginRequired = true;

  constructor(props) {
    super(props);

    this.selectExchange = this.selectExchange.bind(this)

    this.state = {
      form: {}
    }
  }

  componentDidMount() {
    WalletService.getWallet().then(wallet => {
      if (wallet.data.walletId) {
        const form = {
          apiKey: FIELD(wallet.data.apiKey),
          apiSecret: FIELD(wallet.data.apiSecret),
          exchangeId: FIELD(wallet.data.exchangeId),
          modify: false
        }

        this.setState({
          wallet: wallet.data,
          form
        });
      }
    });
  }

  selectExchange(e) {
    e.preventDefault();

    var exchangeId = 1 // Binance
    if(this.state.form.exchangeId?.value !== undefined){
      WalletService.removeExchangeId().then(resp =>
        this.updateState({ form: { exchangeId: FIELD(undefined) } })
      );
    }else{
      WalletService.addExchangeId(exchangeId).then(resp =>
        this.updateState({ form: { exchangeId: FIELD(exchangeId) } })
      );
    }
  }

  render() {
    Logger.log(this.state)
    if (this.state.form.exchangeId === undefined) {
      return (<><Loader loading={true} /></>)
    }
    return (
      <>
        <div style={{ minHeight: "calc( 100vh - 1.5rem - 163px - 120px)" }}>
        <Loader loading={false} />

        <InternalNavbar history={this.props.history} title="Exchange management"/>

        <Container className="px-4 py-3">
          <Row>
            <Col>
              <p className="mb-2">
                We connect to exchanges in order to buy/sell cryptocurrencies on your behalf
              </p>
            </Col>
          </Row>
          <Row>
            <Col>
              <Container className="content-card" onClick={this.state.form.exchangeId?.value ? ()=>{} : this.selectExchange}>
                <Row className="mt-0">
                  <Col xs={10}>
                    <p className="p2">Binance.com</p>
                  </Col>
                  <Col xs={2}>
                    <RadioGreen active={this.state.form.exchangeId.value === 1} />
                  </Col>
                </Row>
                <Row className="mt-0">
                  <Container className="risk-stats">
                    <Row>
                      <Col><p className="p5">Restrictions:</p></Col>
                      <Col xs={6} className="text-right"><p className="p5">Non-U.S. Residents only</p></Col>
                    </Row>
                    <Row>
                      <Col><p className="p5">Trust score:</p></Col>
                      <Col xs={6} className="text-right"><p className="p5">High</p></Col>
                    </Row>
                    <Row>
                      <Col className="pr-0"><p className="p5">Transaction fees:</p></Col>
                      <Col xs={6} className="text-right"><p className="p5">0.075%</p></Col>
                    </Row>
                  </Container>
                </Row>
                { this.state.form.exchangeId?.value === undefined ?
                  <Row>
                    <Col xs={4}> <p className="p8 inverted mb-0" style={{ height: "22px" }}>Suitable for</p></Col>
                  </Row>
                  :
                  <Row>
                    <Col className="text-center">
                    <a className="p0" onClick={this.selectExchange}>Remove connection</a>
                    </Col>
                  </Row>
                }
              </Container>
            </Col>
          </Row>
          <Row>
            <Col>
              <Container className="content-card">
                <Row className="mt-0">
                  <Col>
                    <p className="p2">More Coming Soon...</p>
                  </Col>
                </Row>
                <Row className="mt-0">
                  <Container className="risk-stats">
                    <Row>
                      <Col><p className="p5">We are working for you to provide easy access to a large variety of centralized and decentralized options</p></Col>
                    </Row>
                  </Container>
                </Row>
              </Container>
            </Col>
          </Row>
        </Container>

        <Modal centered show={this.state.newaccount} onHide={() => this.setState({ newaccount: false })}>
          <Modal.Body className="p-4">
            <Container>
              <Row className="mt-0 justify-content-end">
                <Col className="text-right pr-0" xs={3} onClick={() => this.setState({ newaccount: false })}>
                  <ExitGreyIcon />
                </Col>
              </Row>
              <Row>
                <Col className="text-center">
                <LogoAndBinanceIcon />
                </Col>
              </Row>
              <Row className="mt-4">
                <Col>
                <h5 className="text-center mb-3">You’ll be redirected to Binance.com to create an account</h5>
                  <p>We use Binance to securely buy and sell cryptocurrencies for your portfolio.
                  This means you will need to create an account on Binance, then connect said account to Coinbag.</p>
                  <p className="mb-0"><b>On Binance, you will:</b></p>
                  <ol type="1">
                    <li>Create an account (it’s free)</li>
                    <li>Add or transfer funds to this account</li>
                    <li>Come back to Coinbag to finish set up
                  </li>
                  </ol>
                </Col>
              </Row>
              <Row>
                <Col>
                  <button class="btn-inverse btn-block" onClick={this.goToBinance}>
                    Proceed to Binance
                </button>
                </Col>
              </Row>
            </Container>
          </Modal.Body>
        </Modal>

        </div>
        <Footer />

      </>
    );
  }
}
