import { Box, createStyles, makeStyles, Typography } from '@material-ui/core';
import React, { ReactNode } from 'react';

interface InfoBannerProps {
  modifier: string;
  title?: string;
  description?: string;
  subDescription?: string;
  icon: string | ReactNode;
  children?: ReactNode;
}

const useStyles = makeStyles(() => createStyles({
  infoBannerBox: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-start',
    width: '100%',
    borderRadius: '8px',
    padding: '0.5em',
    gap: '4px',
  },
  infoBannerIcon: {
    width: '1em',
    height: '1em',
    marginTop: '0.1em',
    userSelect: 'none',
    userDrag: 'none',
    flexShrink: 0,
  },
  nonBold: {
    fontWeight: 300,
  },
}));

export const InfoBanner = ({
  modifier,
  title,
  description,
  subDescription,
  icon,
  children,
}: InfoBannerProps) => {
  const classes = useStyles();
  return (
    <Box className={`${classes.infoBannerBox} ${modifier}`}>
      {typeof icon === 'string' ? <img src={icon} alt="icon" className={classes.infoBannerIcon} /> : icon}
      <Box flexGrow={1}>
        {title && <Typography variant="h6">{title}</Typography>}
        {description && (
          <Typography variant="h6" className={classes.nonBold}>
            {description}
          </Typography>
        )}
        {subDescription && (
          <Typography variant="h6" className={classes.nonBold}>
            {subDescription}
          </Typography>
        )}
        {children}
      </Box>
    </Box>
  );
};
