import { Box, createStyles, makeStyles } from '@material-ui/core';
import { Button } from 'elements';
import { InputWithTitle } from 'elements/InputWithTitle';
import LightModal from 'elements/LightModal';
import { LightTextField } from 'elements/LightTextField';
import { Typography } from 'elements/Typography';
import { QRCodeSVG } from 'qrcode.react';
import React from 'react';
import SVG from 'react-inlinesvg';
import authService from 'services/auth-service';
import { useStore as use2FaStore } from 'store/zustand/2fa';
import { useStore as useAccountSettingStore } from 'store/zustand/AccountSetting';
import { useStore as useUserStore } from 'store/zustand/User';
import { palette } from 'theme';

const useStyles = makeStyles((theme) => createStyles({
  rootModal: {
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    rowGap: 12,
    '& svg': {
      alignSelf: 'center',
      margin: '8px 0 18px',
    },
  },
  submitGroup: {
    display: 'flex',
    gap: '8px',
    justifyContent: 'space-between',
    width: '100%',
    marginTop: '12px',
  },
  modalContent: {
    display: 'flex',
    flexDirection: 'column',
    textAlign: 'center',
    gap: '4px',
    color: theme.palette.grey[800],
  },
  code: {
    color: palette.green[600],
    cursor: 'pointer',
    paddingBottom: '24px',
    borderBottom: '1px solid #DEE3EF',
    marginBottom: '12px',
  },
  textField: {
    width: '100%',
  },
  title: {
    color: theme.palette.green[500],
    paddingBottom: '4px',
  },
  description: {
    fontWeight: 400,
    color: theme.palette.grey[500],
  },
  buttonContent: {
    display: 'flex',
    gap: '4px',
    flexWrap: 'nowrap',
  },
  editIcon: {
    cursor: 'pointer',
    width: '16px',
    height: '16px',
    '& path': {
      fill: theme.palette.green[500],
    },
    '& path:last-of-type': {
      stroke: theme.palette.green[500],
      fill: 'none',
    },
  },
}));

export const TwoFaButton = () => {
  const classes = useStyles();
  const user = useUserStore((state) => state.user);
  const { isAuthenticatorConfigured } = useAccountSettingStore((state) => state);

  const {
    getSecretKey,
    secretKey,
    resetSecretKey,
    onCodeChange,
    create2FA,
    error,
    errorText,
    enable2FaButton,
    code,
  } = use2FaStore();

  return (
    <InputWithTitle
      title="2FA Verification"
      titleRightItem={isAuthenticatorConfigured && <SVG onClick={getSecretKey} className={classes.editIcon} src="icons/icon_edit.svg" />}
    >
      <Button onClick={getSecretKey} variant={isAuthenticatorConfigured ? 'light' : 'primary'}>
        <Box className={classes.buttonContent}>
          {isAuthenticatorConfigured && <img src="icons/account_details/icon_shield.svg" alt="shield" />}
          <Typography variant="h4">{isAuthenticatorConfigured ? '2FA added' : 'Add 2FA'}</Typography>
        </Box>
      </Button>
      <LightModal maxWidth={370} open={secretKey != null} setOpen={resetSecretKey} showCloseButton>
        <Box className={classes.modalContent}>
          <Box className={classes.rootModal}>
            <Typography className={classes.title} variant="h5">Activate Two-Factor Authentication</Typography>
            <Typography variant="h4">
              Please install Google Authenticator on your mobile device and scan the QR code.
            </Typography>
            {secretKey && (
              <QRCodeSVG
                value={authService.getOTPAuth(user?.email ?? '', secretKey)}
              />
            )}
            {secretKey && (
              <>
                <Typography className={classes.description} variant="h6">
                  Or manually enter the following code below into your authenticator.
                </Typography>
                <Typography
                  variant="h4"
                  className={classes.code}
                  onClick={async () => { await navigator.clipboard.writeText(secretKey); }}
                >
                  {secretKey}
                </Typography>
              </>
            )}

            <InputWithTitle
              title="Enter the 6 digit 2FA code to enable"
              titleRightItem={errorText.length > 0 && <Typography palette="red" paletteColor={500} variant="h6">
                {errorText}
              </Typography>}
            >
              <LightTextField
                className={classes.textField}
                type="number"
                onKeyDown={(e) => (e.key === 'e' || e.key === '.') && e.preventDefault()}
                error={error}
                onChange={(c) => onCodeChange(c.target.value)}
                value={code}
                placeholder="Enter 2FA"
              />
            </InputWithTitle>
            <Box className={classes.submitGroup}>
              <Button size="sm" variant="secondary" onClick={resetSecretKey}>
                <Typography variant="h6">Cancel</Typography>
              </Button>
              <Button size="sm" disabled={!code || !enable2FaButton} onClick={create2FA}>
                <Typography variant="h6">Submit</Typography>
              </Button>
            </Box>
          </Box>
        </Box>
      </LightModal>
    </InputWithTitle>
  );
};
