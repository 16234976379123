import { Box, makeStyles, Popover, useMediaQuery, useTheme } from '@material-ui/core';
import { Loading } from 'components';
import ManageAccountNavBar from 'elements/ManageAccountNavBar';
import { Scrollable } from 'elements/Scrollable';
import { UserType } from 'models';
import * as React from 'react';
import { PermissionsRoute } from 'routes';
import { Permission } from 'services/types/role';
import { useStore as useFeatureStore } from 'store/zustand/Feature';
import { useStore as useRoleStore } from 'store/zustand/Role';
import { useStore as useUserStore } from 'store/zustand/User';

import PermissionCategory from './PermissionCategory';
import PermissionRow from './PermissionRow';
import SelectApproverPopover from './SelectApproverPopover';
import SelectMemberPopover from './SelectMemberPopover';
import { ApproveMember } from './type';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    padding: 24,
    height: '100%',
    gap: 24,
    [theme.breakpoints.down('xs')]: {
      padding: 0,
      gap: 0,
    },
  },
  paperProps: {
    borderRadius: 8,
    width: 216,
    boxShadow: '0px 4px 32px rgba(105, 107, 122, 0.24)',
    [theme.breakpoints.down('xs')]: {
      width: 'calc(100% - 32px)',
    },
  },
  loading: {
    position: 'absolute',
    left: 0,
    top: 0,
    zIndex: 1,
    backdropFilter: 'blur(12px)',
    background: 'rgba(255, 255, 255, 0.6)',
  },
}));

const PermissionPage = () => {
  const classes = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('xs'));

  const [allowedUsers, fetchAllowedUsers, fetchUser, user] = useUserStore((state) => [
    state.allowedUsers, state.fetchAllowedUsers, state.fetchUser, state.user,
  ]);
  const [permissions, fetchPermissions] = useRoleStore((state) => [state.permissions, state.fetchPermissions]);
  const [fetchFeatures] = useFeatureStore((state) => [state.fetchFeatures]);

  const [memberAnchorEl, setMemberAnchorEl] = React.useState<HTMLElement | null>(null);
  const [approverAnchorEl, setApproverAnchorEl] = React.useState<HTMLElement | null>(null);
  const [selectedPermission, setSelectedPermission] = React.useState<Permission | undefined>(undefined);

  React.useEffect(() => {
    fetchAllowedUsers(false);
    fetchPermissions(true);
    fetchUser(false);
    fetchFeatures();

    if (window.Tawk_API && window.Tawk_API.hideWidget) window.Tawk_API.hideWidget();
    return function cleanup() {
      if (window.Tawk_API && window.Tawk_API.showWidget) window.Tawk_API.showWidget();
    };
  }, []);

  const members: ApproveMember[] = React.useMemo(() => {
    const result = (allowedUsers
      ?.map((u) => ({
        firstName: u.firstName,
        lastName: u.lastName,
        email: u.email,
        selected: true,
        id: u.userId,
        isAdmin: u.userType === UserType.Institutional,
      }))
      .sort((a, b) => (b.isAdmin ? 1 : 0) - (a.isAdmin ? 1 : 0)) ?? []);
    return result;
  }, [allowedUsers, user]);

  return (
    <>
      <ManageAccountNavBar />
      <Box className={classes.root}>
        {permissions ? permissions.map((pc) => (
          <PermissionCategory
            name={pc.name}
            count={pc.permissions.length}
            key={pc.permissionCategoryId}
          >
            {pc.permissions.map((p) => (
              <PermissionRow
                id={p.permissionId}
                name={p.name}
                key={p.permissionId}
                approvedMembers={p.approvers}
                requiredApprovers={p.requiredApprovers}
                onSelectMember={(event) => { setSelectedPermission(p); setMemberAnchorEl(event.currentTarget); }}
                onSelectApprover={(event) => { setSelectedPermission(p); setApproverAnchorEl(event.currentTarget); }}
              />
            ))}
          </PermissionCategory>
        )) : <Loading className={classes.loading} />}
      </Box>
      {selectedPermission && (
        <>
          <Popover
            transformOrigin={{ vertical: -8, horizontal: isMobile ? 'right' : 'left' }}
            anchorOrigin={{ vertical: 'bottom', horizontal: isMobile ? 'right' : 'left' }}
            anchorEl={memberAnchorEl}
            open={memberAnchorEl !== null}
            PaperProps={
              { className: classes.paperProps }
            }
            onClose={() => setMemberAnchorEl(null)}
          >
            <Scrollable>
              <SelectMemberPopover
                permission={selectedPermission}
                members={members}
                onClose={() => setMemberAnchorEl(null)}
              />
            </Scrollable>
          </Popover>
          <Popover
            transformOrigin={{ vertical: -8, horizontal: isMobile ? 'right' : 'left' }}
            anchorOrigin={{ vertical: 'bottom', horizontal: isMobile ? 'right' : 'left' }}
            anchorEl={approverAnchorEl}
            open={approverAnchorEl !== null}
            PaperProps={
              { className: classes.paperProps }
            }
            onClose={() => setApproverAnchorEl(null)}
          >
            <Scrollable>
              <SelectApproverPopover permission={selectedPermission} onClose={() => setApproverAnchorEl(null)} />
            </Scrollable>
          </Popover>
        </>
      )}
    </>
  );
};

PermissionPage.displayName = 'Permissions';
PermissionPage.routePath = PermissionsRoute;
PermissionPage.feature = 'teamAccess';
PermissionPage.redirect = 'permissionRedirect';
PermissionPage.title = 'Permissions';
PermissionPage.loginRequired = true;

export default PermissionPage;
