import { Box, Hidden, makeStyles } from '@material-ui/core';
import { Typography } from 'elements';
import * as React from 'react';
import SVG from 'react-inlinesvg';
import { PermissionApprover } from 'services/types/role';
import { useStore as useRoleStore } from 'store/zustand/Role';
import { useStore as useUserStore } from 'store/zustand/User';

interface PermissionRowProps {
  // eslint-disable-next-line no-unused-vars
  onSelectMember: (e: any) => void;
  // eslint-disable-next-line no-unused-vars
  onSelectApprover: (e: any) => void;
  approvedMembers: PermissionApprover[];
  requiredApprovers: number;
  name: string;
  id: number;
}

const useStyles = makeStyles((theme) => ({
  approving: {
    display: 'flex',
    alignSelf: 'flex-start',
    flexDirection: 'column',
    gap: 4,
    alignItems: 'stretch',
  },
  row: {
    display: 'flex',
    justifyContent: 'space-between',
    gap: 8,
  },
  select: {
    display: 'flex',
    flexWrap: 'nowrap',
    gap: 8,
    cursor: 'pointer',
    alignItems: 'center',
    alignSelf: 'flex-start',
    '& svg': {
      color: theme.palette.grey[400],
    },
  },
  removeButton: {
    display: 'flex',
    alignItems: 'center',
    flexShrink: 0,
    cursor: 'pointer',
    width: 16,
    height: 16,
  },
  title: {
    fontSize: 12,
    fontWeight: 400,
    lineHeight: '20px',
    color: theme.palette.grey[500],
  },
  name: {
    color: theme.palette.grey[700],
    [theme.breakpoints.up('sm')]: {
      fontSize: 12,
      lineHeight: '20px',
      fontWeight: 500,
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: 14,
      lineHeight: '24px',
      fontWeight: 500,
      borderTop: `1px solid ${theme.palette.grey[200]}`,
      borderBottom: `1px solid ${theme.palette.grey[200]}`,
    },
  },
  member: {
    display: 'flex',
    alignItems: 'center',
    '& svg': {
      '& path': {
        stroke: theme.palette.grey[400],
      },
    },
  },
  memberList: {
    display: 'flex',
    flexWrap: 'wrap',
    columnGap: 12,
    rowGap: 4,
    [theme.breakpoints.down('xs')]: {
      padding: 12,
      borderRadius: 8,
      marginTop: 12,
      border: `1px solid ${theme.palette.grey[200]}`,
    },
  },
}));

const PermissionRow = (props: PermissionRowProps) => {
  const classes = useStyles();

  const [allowedUsers] = useUserStore((state) => [state.allowedUsers]);
  const [removePermissionApprover] = useRoleStore((state) => [state.removePermissionApprover]);

  const requiredApprovers = React.useMemo(() => {
    switch (Math.min(props.requiredApprovers, props.approvedMembers.length)) {
      case 0: return 'Not required';
      case props.approvedMembers.length: return 'All approving members';
      case 1: return '1 approver';
      default: return `${props.requiredApprovers} approvers`;
    }
  }, [props.requiredApprovers, props.approvedMembers]);

  const approvedMembers = React.useMemo(() => {
    if (allowedUsers) {
      switch (props.approvedMembers.length) {
        case 0: return 'No approver';
        case allowedUsers.length: return 'All approving members';
        default: return 'Selected members';
      }
    }
    return '';
  }, [props.approvedMembers, allowedUsers]);

  return (
    <>
      <Typography className={classes.name}>
        {props.name}
      </Typography>
      <Box className={classes.approving} id="member">
        <Box className={classes.row}>
          <Hidden smUp>
            <Typography className={classes.title}>Approving members</Typography>
          </Hidden>
          <Box className={classes.select} onClick={props.onSelectMember}>
            <Typography align="right" variant="h6" paletteColor={700}>
              {approvedMembers}
            </Typography>
            <SVG src="/icons/icon_chevron.svg" width={16} height={16} />
          </Box>
        </Box>
        {props.approvedMembers.length > 0 && allowedUsers && props.approvedMembers.length !== allowedUsers.length && (
          <Box className={classes.memberList}>
            {props.approvedMembers.map((m, key) => (
              <Box className={classes.member} key={key}>
                <Typography variant="h6" palette="green" paletteColor={600} component="span">
                  {m.email ?? 'User'}
                </Typography>
                <Box
                  className={classes.removeButton}
                  onClick={() => removePermissionApprover(props.id, m.userId)}
                >
                  <SVG src="/icons/icon_cross.svg" width={16} height={16} />
                </Box>
              </Box>
            ))}
          </Box>
        )}
      </Box>
      <Box className={classes.row} id="approver">
        <Hidden smUp>
          <Typography className={classes.title}>Required approvers</Typography>
        </Hidden>
        <Box className={classes.select} onClick={props.onSelectApprover}>
          <Typography align="right" variant="h6" paletteColor={700}>
            {requiredApprovers}
          </Typography>
          <SVG src="/icons/icon_chevron.svg" width={16} height={16} />
        </Box>
      </Box>
    </>
  );
};

export default PermissionRow;
