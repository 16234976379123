import {
  Box,
  Color, makeStyles,   Typography as MuiTypography,
  TypographyProps as MuiTypographyProps } from '@material-ui/core';
import React from 'react';

import { palette as Palette } from '../theme';

type TypographyProps = MuiTypographyProps & {
  palette?: keyof Omit<typeof Palette, 'primary' | 'error'>;
  paletteColor?: keyof Color;
  strong?: boolean;
  uppercase?: boolean;
  component?: React.ElementType;
  ml?: number,
  mr?: number,
  middleTruncation?: boolean;
  ellipsis?: boolean;
}

const useStyles = makeStyles((theme) => ({
  typography: {
    fontWeight: ({ strong }: TypographyProps) => (strong === undefined ? undefined : strong ? 500 : 400),
    color: ({ palette, paletteColor }: TypographyProps) => ((paletteColor === undefined && palette === undefined) ? undefined : theme.palette[palette ?? 'grey'][paletteColor ?? 500]),
    textTransform: ({ uppercase }: TypographyProps) => (uppercase ? 'uppercase' : undefined),
    marginLeft: ({ ml, middleTruncation }: TypographyProps) => (middleTruncation ? undefined : ml),
    marginRight: ({ mr, middleTruncation }: TypographyProps) => (middleTruncation ? undefined : mr),
    textOverflow: ({ ellipsis, middleTruncation }: TypographyProps) => (!middleTruncation && ellipsis ? 'ellipsis' : undefined),
  },
  container: {
    display: 'inline-flex',
    flexWrap: 'nowrap',
    overflow: 'hidden',
    justifyContent: ({ align }: TypographyProps) => (align === 'right' ? 'flex-end' : 'flex-start'),
    marginLeft: ({ ml }: TypographyProps) => ml,
    marginRight: ({ mr }: TypographyProps) => mr,
  },
}));

const Typography = (props: TypographyProps) => {
  const {
    palette, paletteColor, strong, uppercase, ml, mr, middleTruncation, className, children, align, ellipsis, ...rest
  } = props;
  const classes = useStyles({ palette, paletteColor, strong, uppercase, ml, mr, middleTruncation, align, ellipsis });
  if (props.middleTruncation && typeof children === 'string') {
    return (
      <Box className={classes.container}>
        <MuiTypography
          className={`${classes.typography} ${className || ''}`}
          {...rest}
          style={{ overflow: 'hidden', maxWidth: '55%' }}
        >
          {children.substring(0, children.length - children.length / 2)}
        </MuiTypography>
        <MuiTypography
          className={`${classes.typography} ${className || ''}`}
          {...rest}
          style={{
            whiteSpace: 'nowrap',
            maxWidth: '55%',
            textOverflow: 'ellipsis',
            overflow: 'hidden',
            direction: 'rtl',
          }}
        >
          {children.substring(children.length - children.length / 2)}
        </MuiTypography>
      </Box>
    );
  }
  return (
    <MuiTypography
      className={`${classes.typography} ${className || ''}`}
      align={align}
      {...rest}
    >
      {children}
    </MuiTypography>
  );
};

export { Typography };
