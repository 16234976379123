import { Box, makeStyles } from '@material-ui/core';
import { Typography } from 'elements';
import * as React from 'react';
import SVG from 'react-inlinesvg';

interface TeamMemberPopoverProps {
  onClose: () => void;
  // eslint-disable-next-line no-unused-vars
  setShowRemoveModal: (value: boolean) => void;
}

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    '& div:first-child': {
      borderTop: 'none',
    },
  },
  row: {
    display: 'flex',
    cursor: 'pointer',
    padding: '8px 12px',
    alignItems: 'center',
    justifyContent: 'space-between',
    borderTop: `1px solid ${theme.palette.grey[200]}`,
    '&:hover': {
      backgroundColor: theme.palette.green[50],
    },
    '& svg': {
      flexShrink: 0,
    },
  },
}));

const TeamMemberPopover = (props: TeamMemberPopoverProps) => {
  const classes = useStyles();

  const onRemove = () => {
    props.setShowRemoveModal(true);
    props.onClose();
  };

  return (
    <>
      <Box className={classes.root}>
        <Box className={classes.row} onClick={onRemove}>
          <Typography variant="h6" paletteColor={600}>Remove</Typography>
          <SVG src="/icons/dashboard/ic_trash.svg" width={16} height={16} />
        </Box>
      </Box>
    </>
  );
};

export default TeamMemberPopover;
