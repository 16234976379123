import { Box, makeStyles } from '@material-ui/core';
import { Typography } from 'elements';
import React, { memo } from 'react';

type MediaBannerProps = {
  className?: string;
}

const useStyles = makeStyles((theme) => ({
  root: {
    zIndex: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },

  title: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
  },
  titleLine: {
    flexGrow: 1,
    borderTop: '1.5px solid',
    borderColor: theme.palette.grey[300],
  },
  titleText: {
    margin: '0 auto',
    padding: '0 16px',
    width: 'fit-content',

    color: theme.palette.grey[600],
    fontSize: 12,
  },

  iconsRow: {
    display: 'grid',
    gridTemplateColumns: 'repeat(2, minmax(0, 1fr))',
    gap: 14,
    width: '100%',
    marginTop: 16,

    [theme.breakpoints.up('md')]: {
      gridTemplateColumns: 'repeat(4, minmax(0, 1fr))',
      gap: 24,
      marginTop: 32,
    },
  },

  iconCard: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',

    width: '100%',
    height: 100,

    background: 'white',
    borderRadius: 16,
    boxShadow: '0px 4px 20px rgba(144, 156, 189, 0.12)',
    cursor: 'pointer',

    transitionProperty: 'transform',
    transitionTimingFunction: 'cubic-bezier(0.4, 0, 0.2, 1)',
    transitionDuration: '150ms',

    [theme.breakpoints.up('md')]: {
      height: 100,
      boxShadow: '0px 4px 44px rgba(144, 156, 189, 0.2)',
    },

    [theme.breakpoints.up('lg')]: {
      height: 140,
    },

    '&:hover': {
      transform: 'scale(1.05)',
    },
  },

  icons: {
    maxWidth: 126,
    width: '100%',
    height: '100%',
    objectFit: 'contain',
  },
  horizontalIcons: {
    maxWidth: '80%',
    width: '80%',
  },

  others: {
    marginTop: 20,
    fontSize: 16,
    fontWeight: 700,

    [theme.breakpoints.up('md')]: {
      marginTop: 24,
      fontSize: 28,
    },
  },
}));

const MediaBanner = memo(({ className }: MediaBannerProps) => {
  const classes = useStyles();
  return (
    <Box className={`${classes.root} ${className}`}>
      <Box className={classes.title}>
        <Box className={classes.titleLine} />
        <Typography variant="body1" className={classes.titleText}>AS SEEN ON</Typography>
        <Box className={classes.titleLine} />
      </Box>

      <Box className={classes.iconsRow}>
        <a
          className={classes.iconCard}
          href="https://www.forbeschina.com/innovation/64124"
          target="_blank"
          rel="noreferrer"
        >
          <img
            className={classes.icons}
            alt="Featured on Forbes"
            src="/images/media/forbes.webp"
          />
        </a>

        <a
          className={classes.iconCard}
          href="http://finance.azcentral.com/azcentral/news/article/marketersmedia-2023-4-11-coinbag-launches-institutional-grade-tools-for-businesses-looking-to-self-manage-and-de-risk-their-digital-asset-investments"
          target="_blank"
          rel="noreferrer"
        >
          <img
            className={`${classes.icons} ${classes.horizontalIcons}`}
            alt="Featured on USA Today"
            src="/images/media/usa-today.webp"
          />
        </a>

        <a
          className={classes.iconCard}
          href="http://markets.chroniclejournal.com/chroniclejournal/news/article/marketersmedia-2023-4-11-coinbag-launches-institutional-grade-tools-for-businesses-looking-to-self-manage-and-de-risk-their-digital-asset-investments"
          target="_blank"
          rel="noreferrer"
        >
          <img
            className={`${classes.icons} ${classes.horizontalIcons}`}
            alt="Featured on The Chronicle Journal"
            src="/images/media/the-chronicle-journal.webp"
          />
        </a>

        <a
          className={classes.iconCard}
          href="http://finance.minyanville.com/minyanville/news/article/marketersmedia-2023-4-11-coinbag-launches-institutional-grade-tools-for-businesses-looking-to-self-manage-and-de-risk-their-digital-asset-investments"
          target="_blank"
          rel="noreferrer"
        >
          <img
            className={`${classes.icons} ${classes.horizontalIcons}`}
            alt="Featured on Minyanville"
            src="/images/media/minyanville.webp"
          />
        </a>
      </Box>

      <Typography className={classes.others}>And over <em>400</em> others!</Typography>
    </Box>
  );
});

MediaBanner.displayName = 'MediaBanner';
export {
  MediaBanner,
};
