import { Box, createStyles, makeStyles } from '@material-ui/core';
import { useConnectWallet } from '@web3-onboard/react';
import { Typography } from 'elements';
import React, { useMemo } from 'react';
import SVG from 'react-inlinesvg';
import { isAddressSupported, WalletStatus } from 'services/OnboardService';
import { WalletProvider } from 'services/types/wallet';
import generateRandomString from 'utils/generateRandomString';

import { getWalletProviderIcon } from './WalletTypes';

const useStyles = makeStyles((theme) => createStyles({
  root: {
    display: 'flex',
    width: '100%',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: 16,
    flexWrap: 'nowrap',
    gap: 8,
    [theme.breakpoints.up('md')]: {
      borderRadius: 8,
      border: `1px solid ${theme.palette.grey[200]}`,
    },
    [theme.breakpoints.down('sm')]: {
      margin: '0 -16px',
      width: 'calc(100% + 32px)',
      borderTop: `1px solid ${theme.palette.grey[200]}`,
    },
  },
  titleWrapper: {
    display: 'flex',
    gap: 4,
    alignItems: 'center',
  },
  title: {
    fontSize: 14,
    lineHeight: '24px',
    fontWeight: 500,
    color: theme.palette.grey[700],
  },
  name: {
    fontSize: 14,
    lineHeight: '24px',
    fontWeight: 400,
    color: theme.palette.grey[500],
  },
  number: {
    color: 'white',
    padding: '0 4px',
    backgroundColor: theme.palette.blue[500],
    fontSize: 12,
    lineHeight: '18px',
    textAlign: 'center',
    fontWeight: 500,
    borderRadius: 1000,
    flexShrink: 0,
    height: 18,
    minWidth: 18,
  },
  statusWrapper: {
    display: 'flex',
    flexWrap: 'nowrap',
    gap: 4,
  },
  status: {
    fontSize: 12,
    lineHeight: '16px',
    textAlign: 'right',
    color: theme.palette.green[500],
  },
  icon: {
    flexShrink: 0,
    width: 24,
    height: 24,
    borderRadius: 4,
    boxShadow: '0px 4px 12px rgba(110, 123, 162, 0.12)',
    marginRight: 8,
    userDrag: 'none',
    userSelect: 'none',
    display: 'flex',
    position: 'relative',
    '& img': {
      borderRadius: 4,
    },
  },
  divider: {
    margin: '0 4px',
    height: 12,
    borderRight: `1px solid ${theme.palette.grey[300]}`,
  },
  pending: {
    color: theme.palette.yellow[600],
  },
  walletConnected: {
    width: 7,
    height: 7,
    position: 'absolute',
    top: -1,
    right: -1,
    backgroundColor: theme.palette.green[500],
    border: '1px solid white',
    borderRadius: 4,
  },
}));

export interface WalletItemProps {
  status: 'connected' | 'disconnected' | 'pending';
  provider: WalletProvider;
  name: string;
  order: number;
  isNonCustodian?: boolean;
  address?: string;
}

const WalletItem = ({ status, provider, name, order, isNonCustodian, address }: WalletItemProps) => {
  const classes = useStyles();
  const [{wallet}] = useConnectWallet();

  const iconUrl = React.useMemo(() => getWalletProviderIcon(provider, isNonCustodian), [provider, isNonCustodian]);

  const statusItem = React.useMemo(() => {
    switch (status.toLocaleLowerCase()) {
      case 'connected':
        return (
          <>
            <SVG src="/icons/wallet_setting/ic_connected.svg" />
            <Typography className={classes.status}>Connected</Typography>
          </>
        );
      case 'pending':
      default:
        return (
          <>
            <SVG src="/icons/wallet_setting/ic_pending.svg" />
            <Typography className={`${classes.status} ${classes.pending}`}>Pending</Typography>
          </>
        );
    }
  }, [status]);

  const isConnected = useMemo(() => isNonCustodian && address && isAddressSupported(address) !== WalletStatus.No, [address, isNonCustodian, wallet]);

  return (
    <Box className={classes.root}>
      <Box className={classes.titleWrapper}>
        <Box className={classes.icon}>
          <img src={
            `/icons/wallet_setting/${iconUrl}_sm.svg?${generateRandomString()}`
          } width={24} height={24} alt={provider} />
          {isConnected && (<div className={classes.walletConnected} />)}
        </Box>
        <Typography className={classes.title}>
          { provider !== 'Reporting' ? provider
            : isNonCustodian ? 'Self-custody' : 'Read-only'
          }
        </Typography>
        <Box className={classes.divider} />
        {name ? <Typography className={classes.name}>{name}</Typography> : undefined}
        {order ? <><Typography className={classes.name}>#</Typography><Typography className={classes.number}>{order}</Typography></> : undefined}
      </Box>
      <Box className={classes.statusWrapper}>
        {statusItem}
      </Box>
    </Box>
  );
};

export default WalletItem;
