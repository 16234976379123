import React from "react";
import { Redirect } from "react-router-dom";
import { InternalNavbar } from "../../components/navbar.js";
import WalletService from "../../services/wallet-service";
import SharedStateComponent from "../../components/SharedStateComponent.js";
import Loader from "../../components/Loader.js";
import { Container, Row, Col } from 'react-bootstrap';
import { Footer } from "../../components/Footer.js";
import { PencilGreenIcon} from "../../components/Icons.js";
import { FIELD} from "../../common.js";
import Welcome from "./Welcome.js";
import EditConnection from "./EditConnection.js";
import EditExchange from "./EditExchange.js";
import Logger from "js-logger";
import { DashboardRoute } from "routes";

export default class ManageExchange extends SharedStateComponent {
  static routePath = '/manage-exchange';
  static loginRequired = true;

  constructor(props) {
    super(props);

    this.handleTestConnection = this.handleTestConnection.bind(this);

    this.state = {
      form: {},
      exchanges: []
    };
  }

  componentDidMount() {
    WalletService.getAllExchanges().then(exchanges => {
      this.setState({ exchanges: exchanges.data });
    })

    WalletService.getWallet().then(wallet => {
      if (wallet.data.walletId) {
        const form = {
          apiKey: FIELD(wallet.data.apiKey),
          apiSecret: FIELD(wallet.data.apiSecret),
          exchangeId: FIELD(wallet.data.exchangeId),
          modify: false
        }

        this.setState({
          wallet: wallet.data,
          form
        });
        if (this.state.form?.exchangeId?.value) {
          this.handleTestConnection();
        }
      } else {
        this.props.history.push({ pathname: Welcome.routePath })
      }
    });
  }

  handleTestConnection = (e) => {
    if (e)
      e.preventDefault();

    WalletService.testWalletConnection(this.state.wallet.walletId).then(
      resp => {
        this.updateState({
          form: resp.data,
          connectionTested: true,
          loading: false
        })
      },
      error => {
        this.updateState({
          form: { connected: undefined, canTrade: undefined, canWithdraw: undefined },
          connectionTested: true,
          loading: false
        })
      }
    );
  }

  renderWithSharedState() {
    if (this.state.redirect) {
      return <Redirect to={{
        pathname: this.state.redirect,
        redirectState: this.state.redirectState
      }} />
    }

    Logger.log(this.state);

    var yourExchange = this.state.exchanges?.find(x => x.exchangeId === Number.parseInt(this.state.form?.exchangeId?.value))?.name
    yourExchange = yourExchange ? yourExchange : "Not selected"

    return (
      <>
      <div style={{ minHeight: "calc( 100vh - 1.5rem - 163px - 120px)" }}>
        <Loader loading={false} />

        <InternalNavbar history={this.props.history} title="Exchange management"/>

        <Container className="px-4 pb-3">
          <Row className="pt-4">
            <Col>
              <Container className="content-card p-4">
                <Row className="mt-0">
                  <Col><p className="p3 mb-0">Your exchange:</p></Col>
                  <Col className="text-right">
                    <p className="p2 mb-0" onClick={() => this.props.history.push({ pathname: EditExchange.routePath })}>
                      {yourExchange} <PencilGreenIcon />
                    </p>
                  </Col>
                </Row>
              </Container>
            </Col>
          </Row>
          <Row>
            <Col>
              <Container className="content-card p-4">
                <Row className="mt-0">
                  <Col><p className="p9 mb-0">API key:</p></Col>
                </Row>
                <Row className="mt-2">
                  <Col><p className="p5 mb-0 grey">{this.state.form.apiKey?.value ? this.state.form.apiKey.value : "--"}</p></Col>
                </Row>
                <Row className="mt-4">
                  <Col><p className="p9 mb-0">SECRET key:</p></Col>
                </Row>
                <Row className="mt-2">
                  <Col><p className="p5 mb-0 grey">{this.state.form.apiSecret?.value === "" ? "**************************************" : "--"}</p></Col>
                </Row>
                {yourExchange !== "Not selected" && (
                  <Row className="text-center mt-4">
                    <Col>
                      <a onClick={() => this.props.history.push(EditConnection.routePath)}>Edit </a>
                    </Col>
                  </Row>
                )}
              </Container>
            </Col>
          </Row>

          <Row className="mt-4 text-center">
            <Col>
              <a onClick={() => this.props.history.push(DashboardRoute)}>
                Back to dashboard
              </a>
            </Col>
          </Row>
        </Container>
        </div>
        <Footer />
      </>
    );
  }
}
