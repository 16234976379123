import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import { Footer } from "../../components/Footer.js";
import { InternalNavbar } from "../../components/navbar.js";
import { Container, Row, Col } from 'react-bootstrap'
import Loader from "../../components/Loader.js";
import SharedStateComponent from "../../components/SharedStateComponent.js";
import QuestionService from "../../services/question.service.js";
import ManageRisk from "./ManageRisk.js";
import { FIELD, loading } from "../../common.js";
import { StepNav } from "../../components/StepNav.js";
import {RightArrowGreenIcon, ManLadderIcon, ManPenIcon, JugglingWoman} from "../../components/Icons.js";
import RiskAssessment from "./RiskAssessment.js";
import AuthService from "../../services/auth-service.js";
import GreenBadge from "../../components/GreenBadge";
import walletService from "../../services/wallet-service";
import ChooseExchange from "../responsive/ChooseExchange";


export default class RiskProfile extends SharedStateComponent {
  static routePath = '/risk-profile';
  static loginRequired = true;

  constructor(props) {
    super(props);

    this.goToManageRisk = this.goToManageRisk.bind(this);

    this.state = {}
  }

  goToManageRisk(e) {
    e.preventDefault();
    AuthService.updateUserStep().then(_ => {
      this.props.history.push(ManageRisk.routePath)
    });
  }

  submitScore(e) {
    e.preventDefault();
    walletService.addRiskScore(0).then(_ => {
      AuthService.updateUserStep().then(_ => this.props.history.push(ChooseExchange.routePath))
    });
  }

  render() {

    return (
      <>
        <Redirect to={ManageRisk.routePath} />
        <Loader loading={false} />

        <InternalNavbar history={this.props.history} />

        <StepNav active={1} />

        <Container className="px-4 pb-3">
          <Row>
            <Col>
              <h5>Setting up your risk profile</h5>
              <p className="mb-0">
                Once we determine the risk/reward ratio best suited to your investment goals, our algorithm chooses and invests in the best cryptos for your objectives.
            </p>
            </Col>
          </Row>
          <Row>
            <Col>
              <Container className="content-card" onClick={this.goToManageRisk}>
                <Row className="mt-0">
                  <Col xs={12}>
                    <GreenBadge>Best for investors with experience in crypto</GreenBadge>
                  </Col>
                </Row>
                <Row className="mt-0">
                  <Col xs={8}>
                    <p className="p2">I know my risk level</p>
                    <p>Choose your risk level and see how we'd set up your portfolio</p>
                    <RightArrowGreenIcon />
                  </Col>
                  <Col xs={4} className="align-self-end" style={{bottom:"-16px", left:"-16px"}}>
                  <ManLadderIcon />
                  </Col>
                </Row>
              </Container>
            </Col>
          </Row>
          <Row>
            <Col>
              <Container className="content-card" onClick={() => this.props.history.push({pathname: RiskAssessment.routePath, state: {from: ManageRisk.routePath}})}>
                <Row className="mt-0">
                  <Col xs={12}>
                    <GreenBadge>Best for investors new to crypto</GreenBadge>
                  </Col>
                </Row>
                <Row className="mt-0">
                  <Col xs={8}>
                    <p className="p2">I don't know my risk level</p>
                    <p>Take our questionnaire and let us recommend the best portfolio setup for you</p>
                    <RightArrowGreenIcon />
                  </Col>
                  <Col xs={4} className="align-self-end" style={{bottom:"-16px", left:"-16px"}}>
                  <ManPenIcon />
                  </Col>
                </Row>
              </Container>
            </Col>
          </Row>
          <Row>
            <Col>
              <Container className="content-card pointer" onClick={e => this.submitScore(e)}>
                <Row className="mt-0">
                  <Col xs={12}>
                    <GreenBadge>Best for investors seeking stable income</GreenBadge>
                  </Col>
                </Row>
                <Row className="mt-0">
                  <Col xs={8}>
                    <p className="p2">I just want 19% yearly income</p>
                    <p>Earn a stable passive income of 19% yearly interest by holding 100% non-volatile stablecoins.</p>
                    <RightArrowGreenIcon />
                  </Col>
                  <Col xs={4} className="align-self-end" style={{bottom:"-16px", left:"-16px", textAlign:"right"}}>
                    <JugglingWoman />
                  </Col>
                </Row>
              </Container>
            </Col>
          </Row>
        </Container>

        <Footer />
      </>
    );
  }
}
