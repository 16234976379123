import { Box, Grid, makeStyles } from '@material-ui/core';
import { Card, Typography } from 'elements';
import { Scrollable } from 'elements/Scrollable';
import { Coin, DepositEvent } from 'models';
import React, { useEffect, useState } from 'react';
import SVG from 'react-inlinesvg';
import { CoinsWithMasterCoins } from 'services/types/coin';
import { WorkItemStatus } from 'services/types/workItem';
import { useStore as useWalletStore } from 'store/zustand/Wallet';

const useStyles = makeStyles((theme) => ({
  root: {
    background: 'white',
    boxShadow: '0px 4px 32px rgba(43, 43, 48, 0.04)',
    width: '100%',
    height: '100%',
    overflow: 'hidden',
    padding: 0,
    [theme.breakpoints.down('sm')]: {
      minHeight: 100,
      borderRadius: 0,
      flexGrow: 1,
    },
  },
  titleBox: {
    display: 'flex',
    flexWrap: 'nowrap',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '12px 16px',
    width: '100%',
    borderBottom: `1px solid ${theme.palette.grey[100]}`,
  },
  container: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
  },
  updateWithdrawBox: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  lastUpdatedTimeText: {
    fontSize: 12,
    fontWeight: 400,
    lineHeight: '24px',
    textAlign: 'right',
    color: theme.palette.grey[500],
    flexGrow: 1,
  },
  refreshIcon: {
    margin: 0,
    width: 24,
    height: 24,
    filter: 'invert(69%) sepia(36%) saturate(5074%) hue-rotate(125deg) brightness(99%) contrast(95%)',
  },
  refreshButton: {
    paddingLeft: 8,
    margin: 0,
    minWidth: 0,
    cursor: 'pointer',
  },
  transactionBox: {
    width: '100%',
    padding: '16px 0',
    borderBottom: `1px solid ${theme.palette.grey[200]}`,
  },
  quantityAndPriceBox: {
    display: 'flex',
  },
  quantityAndPriceColumn: {
    display: 'flex',
  },
  quantity: {
    color: theme.palette.grey[700],
    display: 'inline',
    '& img': {
      display: 'inline',
    },
  },
  dateAndStatusBox: {
    textAlign: 'right',
    flexGrow: 1,
    alignItems: 'center',
    flexShrink: 0,
  },
  transactionTs: {
    color: theme.palette.grey[500],
  },
  withdrawUsd: {
    color: theme.palette.grey[700],
  },
  depositIcon: {
    width: '16px',
    height: '16px',
    display: 'flex',
  },
  withdrawIcon: {
    width: '16px',
    height: '16px',
    display: 'flex',
    filter: 'invert(81%) sepia(55%) saturate(679%) hue-rotate(332deg) brightness(100%) contrast(95%)',
  },
  failedIcon: {
    width: '16px',
    height: '16px',
    display: 'flex',
    filter: 'invert(65%) sepia(20%) saturate(827%) hue-rotate(299deg) brightness(103%) contrast(92%)',
  },
  pendingIcon: {
    width: '16px',
    height: '16px',
    marginRight: '0.2em',
  },
  sentIcon: {
    width: '16px',
    height: '16px',
    marginRight: '0.2em',
  },
  sentText: {
    color: '#02BD9F',
    fontWeight: 400,
    display: 'inline-block',
    verticalAlign: 'middle',
  },
  inProgressText: {
    color: '#DBA400',
    fontWeight: 400,
    display: 'inline-block',
    verticalAlign: 'middle',
  },
  failedText: {
    color: '#FF6187',
    fontWeight: 400,
    display: 'inline-block',
    verticalAlign: 'middle',
  },
  tokenIcon: {
    width: 40,
    height: 40,
    marginRight: 8,
    borderRadius: 20,
  },
  tokenName: {
    backgroundColor: theme.palette.grey[100],
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  scrollable: {
    position: 'relative',
    flexGrow: 1,
    flexDirection: 'column',
    display: 'flex',
  },
  scrollContent: {
    position: 'absolute',
    display: 'flex',
    flexDirection: 'column',
    padding: '0 16px',
    width: '100%',
    '& >div:last-child': {
      borderBottom: 0,
    },
    [theme.breakpoints.down('sm')]: {
      position: 'relative',
    },
  },
  noData: {
    color: theme.palette.grey[500],
    fontSize: 12,
    fontWeight: 400,
    lineHeight: '24px',
    textAlign: 'center',
    margin: 'auto',
    alignSelf: 'center',
    justifySelf: 'center',
  },
}));

interface WithdrawHistoryProps {
  coinsWithMasterCoins: CoinsWithMasterCoins;
  withdraw?: boolean;
}

export const DepositAndWithdrawHistory = (props: WithdrawHistoryProps) => {
  // Define constants
  const [msInDay, msInHour, msInMin, msInSec] = [86400000, 3600000, 60000, 1000];

  const classes = useStyles();
  const [withdrawEvents, setWithdrawEvents] = useState<DepositEvent[]>([]);
  const [lastUpdatedTime, setLastUpdatedTime] = useState<number | null>(null);
  const [loading, setLoading] = useState(true);
  const [timeSinceLastUpdate, setTimeSinceLastUpdate] = useState<number>(0);

  const [fetchDepositAndWithdrawalHistory] = useWalletStore((state) => [state.fetchDepositAndWithdrawalHistory]);

  const updateWithdrawEvents = async () => {
    setLoading(true);
    try {
      const { deposits, withdrawals } = await fetchDepositAndWithdrawalHistory(true);

      const events = props.withdraw ? withdrawals : deposits;
      const eventsToDisplay = events.map((event) => {
        const eventTs = new Date(event.ts);
        return {
          ...event,
          coins: event.coins.map((coin) => ({
            ...coin,
          })),
          ts: `${eventTs.getDate()}.${eventTs.getMonth() + 1}.${eventTs.getFullYear()},\
         ${eventTs.getHours()}:${eventTs.getMinutes() < 10 ? '0' : ''}${eventTs.getMinutes()}`,
        };
      });

      setWithdrawEvents(eventsToDisplay.reverse());
    } catch {
      setWithdrawEvents([]);
    }
    setLastUpdatedTime(Date.now());
    setLoading(false);
  };

  useEffect(() => {
    updateWithdrawEvents();
  }, []);

  useEffect(() => {
    const intervalAction = setInterval(() => {
      const currentTime = Date.now();
      // Date.now() -> /1000 to convert to seconds
      setTimeSinceLastUpdate(currentTime - (lastUpdatedTime || currentTime));
    }, 500);
    return () => clearInterval(intervalAction);
  });

  const getEventTitle = (event: DepositEvent, coin: Coin) => {
    if (event.workItemStatus === WorkItemStatus.Pending || event.workItemStatus === WorkItemStatus.InProgress)
      return 'Transaction in progress';
    if (event.workItemStatus === WorkItemStatus.WaitingForApproval) return 'Transaction waiting for approval';
    if (event.workItemStatus === WorkItemStatus.Rejected) return 'Transaction rejected by approver';
    if (!event.success) return 'Transaction failed';
    return coin.quantity.toFixed(0).length > 11
      ? coin.quantity.toLocaleString(
        undefined,
        { unitDisplay: 'narrow', notation: 'compact', minimumFractionDigits: 2 }
      )
      : coin.quantity.toLocaleString(undefined, { minimumSignificantDigits: 2, maximumSignificantDigits: 8 });
  };

  return (
    <Card className={classes.root} isLoading={loading}>
      <Box className={classes.container}>
        <Box className={classes.titleBox}>
          <Typography palette="grey" paletteColor={600} variant="h4">
            Last {props.withdraw ? 'withdrawals' : 'deposits'}
          </Typography>
          <Box className={classes.updateWithdrawBox}>
            <Typography variant="h6" className={classes.lastUpdatedTimeText}>
              Updated {(timeSinceLastUpdate > msInDay) ? `${Math.floor(timeSinceLastUpdate / msInDay)} d`
                : (timeSinceLastUpdate > msInHour) ? `${Math.floor(timeSinceLastUpdate / msInHour)} h`
                  : (timeSinceLastUpdate > msInMin) ? `${Math.floor(timeSinceLastUpdate / msInMin)} min`
                    : `${Math.floor(timeSinceLastUpdate / msInSec)} sec`} ago
            </Typography>
            <Box className={classes.refreshButton} onClick={updateWithdrawEvents}>
              <img src="icons/dashboard/ic_refresh.svg" alt="refresh button" className={classes.refreshIcon} />
            </Box>
          </Box>
        </Box>
        <Scrollable className={classes.scrollable}>
          {(withdrawEvents.length <= 0 && !loading) && <Typography className={classes.noData}>No data</Typography>}
          <Box className={classes.scrollContent}>
            {withdrawEvents.map((event) => event.coins.map((coin) => (
              <Grid item key={`${event.ts}${coin.name}`} className={classes.transactionBox}>
                <Grid container direction="row" wrap="nowrap" alignItems="center">
                  <Grid item>
                    {props.coinsWithMasterCoins[coin.coinId.toString()]
                    && props.coinsWithMasterCoins[coin.coinId.toString()].masterCoin.icon
                      ? (
                        <SVG
                          className={classes.tokenIcon}
                          src={props.coinsWithMasterCoins[coin.coinId.toString()].masterCoin.icon}
                        />
                      ) : (
                        <Box className={`${classes.tokenIcon} ${classes.tokenName}`}>
                          <Typography variant="h6" paletteColor={500} align="center">{coin.name}</Typography>
                        </Box>
                      )}
                  </Grid>
                  <Grid item className={classes.quantityAndPriceBox}>
                    <Grid container direction="column" className={classes.quantityAndPriceColumn}>
                      <Grid item className={classes.quantityAndPriceColumn}>
                        <Typography variant={event.success ? 'h4' : 'h6'} className={classes.quantity}>
                          {getEventTitle(event, coin)}&nbsp;
                          {!props.withdraw ? (
                            <img
                              src="icons/deposit/deposit_icon.svg"
                              alt="Deposit icon"
                              className={classes.depositIcon}
                            />
                          ) : (
                            <img
                              src="/icons/dashboard/ic_withdraw_2.svg"
                              alt="Withdraw icon"
                              className={`${event.success ? classes.withdrawIcon : classes.pendingIcon}`}
                            />
                          )}
                        </Typography>
                      </Grid>
                      <Grid item>
                        <Typography variant="h6" className={classes.withdrawUsd}>
                          {!event.success ? ''
                            : coin.price !== undefined ? `≈ $${coin.price.toLocaleString(
                              undefined,
                              { minimumFractionDigits: 2, maximumFractionDigits: 2 }
                            )}`
                              : ''}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item className={classes.dateAndStatusBox}>
                    <Grid container direction="column">
                      <Grid item>
                        <Typography variant="h6" className={classes.transactionTs}>{event.ts}</Typography>
                      </Grid>
                      <Grid item>{
                        (event.workItemStatus === WorkItemStatus.Pending
                          || event.workItemStatus === WorkItemStatus.InProgress
                          || event.workItemStatus === WorkItemStatus.WaitingForApproval
                        ) ? (
                            <>
                              <img
                                src="icons/withdraw/pending_status_icon.svg"
                                alt="Pending icon"
                                className={classes.pendingIcon}
                              />
                              <Typography variant="h6" className={classes.inProgressText}>
                                {event.workItemStatus === WorkItemStatus.WaitingForApproval
                                  ? 'Waiting For Approval'
                                  : 'In Progress'}
                              </Typography>
                            </>
                          ) : !event.success ? (
                            <>
                              <img
                                src="icons/dashboard/ic_red_fail.svg"
                                alt="Cancelled icon"
                                className={classes.pendingIcon}
                              />
                              <Typography variant="h6" className={classes.failedText}>Failed</Typography>
                            </>
                          ) : event.isConfirmed ? (
                            <>
                              <img src="icons/withdraw/check_icon.svg" alt="Sent icon" className={classes.sentIcon} />
                              <Typography variant="h6" className={classes.sentText}>Sent</Typography>
                            </>
                          ) : (
                            <>
                              <img
                                src="icons/withdraw/pending_status_icon.svg"
                                alt="Pending icon"
                                className={classes.pendingIcon}
                              />
                              <Typography variant="h6" className={classes.inProgressText}>In Progress</Typography>
                            </>
                          )}
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            )))}
          </Box>
        </Scrollable>
      </Box>
    </Card>
  );
};
