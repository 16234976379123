import React, { Component } from "react";
import { sleep } from "../common";
import { Container, Row, Col, ProgressBar } from 'react-bootstrap'
import { GrowthIllustration } from "./Icons";

export default class Loader extends Component {
  constructor(props) {
    super(props);
    this.removeLoader = this.removeLoader.bind(this)

    this.shouldRemoveLoader = true
    if (this.props.shouldRemoveLoader !== undefined) {
      this.shouldRemoveLoader = this.props.shouldRemoveLoader
    }

    this.state= {
      timeElapsed: 500,
      id: this.props.id ? this.props.id : "loader"
    }

  }

  componentDidUpdate() {
    this.removeLoader();

    if(this.props.progress && !this.interval){
      this.interval = setInterval(() => {
        this.setState({timeElapsed:this.state.timeElapsed + 500})
      }, 500)
    }
  }

  componentDidMount() {
    this.removeLoader();

    if(this.props.progress && !this.interval){
      this.interval = setInterval(() => {
        this.setState({timeElapsed:this.state.timeElapsed + 500})
      }, 500)
    }
  }

  componentWillUnmount() {
    if(this.props.progress){
      clearInterval(this.interval);
    }
 }

  removeLoader() {
    if (!this.props.loading) {
      const element = document.querySelector("#"+this.state.id)
      if (element.firstChild) {
        sleep(100).then(() => {
          element.classList.remove("fade-in");
        })
        if (this.shouldRemoveLoader) {
          sleep(500).then(() => {
            if (element.firstChild)
              element.removeChild(element.firstChild)
          })
        }
      }
    } else {
      const element = document.querySelector("#"+this.state.id)
      if (element.firstChild) {
        sleep(100).then(() => {
          element.classList.add("fade-in");
        })
      }
    }
  }

  render() {
    return (
      <>
        <div id={this.state.id} className="overlay-loading fade-in">
          {this.props.progress ?
          <div id="svg-container">
            <Container className="text-center px-4">
              <Row>
                <Col>
                  <GrowthIllustration /></Col>
              </Row>
              <Row>
                <Col>
                <ProgressBar now={this.state.timeElapsed / this.props.progress * 100} variant="custom" />
                </Col>
              </Row>
              <Row>
                <Col>
                  <p>{this.props.text}</p>
                </Col>
              </Row>
            </Container>
          </div>
            :
            <div className="loading-animation-container">
              <img className="loading-animation" src="/images/loading.gif" />
            </div>
          }
          {this.props.text && !this.props.progress && (
            <Container className="loading-text px-5">
              <Row>
                <Col>
                  <p>{this.props.text}</p>
                </Col>
              </Row>
            </Container>
          )}
        </div>
      </>
    )
  }
}
