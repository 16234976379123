import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import { RootState } from '../store';

type LanguageState = string;

const initialState: LanguageState = 'en-us'

export const languageSlice = createSlice({
  name: 'translations',
  initialState,
  reducers: {
    languageChanged: (state: LanguageState, action: PayloadAction<string>) => {
      return action.payload;
    },
  },
})

export const { languageChanged } = languageSlice.actions

export const selectLanguage = (state: RootState) => state.language;

export default languageSlice.reducer
