import 'pure-react-carousel/dist/react-carousel.es.css';

import { Box, Hidden, makeStyles } from '@material-ui/core';
import { Typography } from 'elements';
import { PortfolioCoin } from 'models';
import { CarouselProvider, Slide, Slider } from 'pure-react-carousel';
import React, { FC } from 'react';
import SVG from 'react-inlinesvg';
import { prettyNumber } from 'utils';

import { CoinPercentageRow } from './elements/CoinPercentageRow';

type PortfolioPieProps = {
  className?: string;
  valueMode: 'percentage' | 'actual';
  hasTarget: boolean;
  totalValue: number;
  portfolio: PortfolioCoin[];
  showOthers?: boolean;
  toggleShowOthers: () => void;
};

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    [theme.breakpoints.up('md')]: {
      flexDirection: 'row',
    },
  },
  containter: {
    display: 'grid',
    gridTemplateColumns: 'repeat(auto-fit, minmax(200px, 1fr))',
    width: '100%',
    columnGap: 24,
    rowGap: 40,
    transition: '300ms',
  },
  fullWidth: {
    gridTemplateColumns: '1fr',
    '& $tokensColumn': {
      display: 'grid',
      gridTemplateColumns: 'repeat(auto-fill, minmax(200px, 1fr))',
      columnGap: 48,
    },
    '& $chartColumn': {
      width: '100%',
    },
    '& $chartRow': {
      width: '100%',
      marginTop: 24,
      [theme.breakpoints.up('md')]: {
        marginRight: 12,
      },
    },
  },
  headerRow: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    columnGap: 8,
    marginTop: 24,
    width: '100%',
    [theme.breakpoints.up('md')]: {
      marginTop: 0,
      justifyContent: 'start',
    },
  },
  headerText: {
    color: theme.palette.grey[500],
    fontSize: 14,
    fontWeight: 400,
    lineHeight: '16px',
  },
  tokensColumn: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    rowGap: 16,
    marginTop: 16,
    transition: '300ms',
  },
  chartColumn: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  chartRow: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'start',
    marginTop: 12,
    minHeight: 220,
    columnGap: 36,
  },
  showMore: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: 32,
    height: 32,
    borderRadius: 16,
    backgroundColor: theme.palette.common.white,
    border: `1px solid ${theme.palette.grey[300]}`,
    bottom: 0,
    color: theme.palette.grey[400],
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: theme.palette.grey[50],
    },
    '& svg': {
      transition: '400ms',
    },
    '&#expanded': {
      '& svg': {
        transform: 'rotate(180deg)',
      },
    },
    [theme.breakpoints.up('md')]: {
      left: '50%',
      transform: 'translateX(-50%)',
      boxShadow: '0px 2px 4px rgba(134, 139, 185, 0.34)',
      position: 'absolute',
    },
    [theme.breakpoints.down('sm')]: {
      alignSelf: 'center',
      marginTop: 12,
    },
  },
  chart: {
    position: 'relative',
    margin: '0 -16px 0 -32px',
    minWidth: 200,
    minHeight: 200,
    [theme.breakpoints.down('sm')]: {
      margin: 0,
      width: '100%',
    },
  },
  chartActual: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',

    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
}));

const PortfolioPieChart = React.lazy(() => import('./PortfolioPieChart'));

const PortfolioPie: FC<PortfolioPieProps> = ({
  className, valueMode, totalValue, portfolio, hasTarget, showOthers, toggleShowOthers,
}) => {
  const classes = useStyles();
  return (
    <Box className={`${classes.root} ${className}`}>
      <Hidden mdUp>
        <CarouselProvider
          naturalSlideWidth={100}
          naturalSlideHeight={80}
          totalSlides={2}
        >
          <Slider>
            <Slide index={0}>
              <Box className={classes.chart}>
                <React.Suspense fallback={<></>}>
                  <PortfolioPieChart hasTarget={hasTarget} portfolio={portfolio} type="actual" />
                </React.Suspense>
                <Box className={classes.chartActual}>
                  <Typography variant="h6">Actual</Typography>
                  <Typography variant="h1" paletteColor={500} palette="green">
                    {prettyNumber(totalValue, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
                  </Typography>
                  <Typography variant="h6" paletteColor={400}>USD</Typography>
                </Box>
              </Box>
            </Slide>
            <Slide index={1}>
              <React.Suspense fallback={<></>}>
                <PortfolioPieChart hasTarget={hasTarget} portfolio={portfolio} type="target" />
              </React.Suspense>
            </Slide>
          </Slider>
        </CarouselProvider>
        <Box className={classes.headerRow}>
          <Typography className={classes.headerText}>TOKENS</Typography>
          {valueMode === 'percentage' ? (
            <Typography className={classes.headerText}>
              <Hidden xsDown>Actual % and USDT vs Target</Hidden>
              <Hidden smUp>{portfolio?.[0]?.actualPercentage.toFixed(0)}/100% max</Hidden>
            </Typography>
          ) : (
            <Typography className={classes.headerText}>
              USDT
            </Typography>
          )}
        </Box>
        <Box className={classes.tokensColumn}>
          {portfolio.map((p, index) => (
            <CoinPercentageRow hasTarget={hasTarget} key={index} valueMode={valueMode} coin={p} showColor />
          ))}
        </Box>
        {showOthers !== undefined && (
          <Box className={classes.showMore} onClick={toggleShowOthers} id={showOthers ? 'expanded' : ''}>
            <SVG src="/icons/dashboard/ic_arrow_down.svg" width={32} height={32} />
          </Box>
        )}
      </Hidden>
      <Hidden smDown>
        <Box className={`${classes.containter} ${showOthers ? classes.fullWidth : ''}`}>
          <Box className={classes.chartColumn}>
            {hasTarget && (
              <Typography variant="h5" paletteColor={600} align="center">
                Actual Portfolio allocation
                <Typography className={classes.headerText} ml={8} component="span">
                  ({valueMode === 'percentage' ? 'Actual vs Target' : 'USDT'})
                </Typography>
              </Typography>
            )}
            <Box className={classes.chartRow}>
              <Box className={classes.chart}>
                <React.Suspense fallback={<></>}>
                  <PortfolioPieChart hasTarget={hasTarget} portfolio={portfolio} type="actual" />
                </React.Suspense>
                <Box className={classes.chartActual}>
                  {hasTarget && (<Typography variant="h6" paletteColor={700}>Actual</Typography>)}
                  <Typography variant="h1" paletteColor={500} palette="green">
                    {prettyNumber(totalValue, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
                  </Typography>
                  <Typography variant="h6" paletteColor={400}>USD</Typography>
                </Box>
                {showOthers !== undefined && (
                  <Box className={classes.showMore} onClick={toggleShowOthers} id={showOthers ? 'expanded' : ''}>
                    <SVG src="/icons/dashboard/ic_arrow_down.svg" width={32} height={32} />
                  </Box>
                )}
              </Box>
              <Box className={classes.tokensColumn}>
                {portfolio.filter((p) => p.actualPercentage).map((p, index) => (
                  <CoinPercentageRow hasTarget={hasTarget} key={index} valueMode={valueMode} coin={p} showColor />
                ))}
              </Box>
            </Box>
          </Box>
          {hasTarget && (
            <Box className={classes.chartColumn}>
              <Typography variant="h5" align="center" paletteColor={600}>
                Target Portfolio allocation
                <Typography className={classes.headerText} ml={8} component="span">
                  ({valueMode === 'percentage' ? 'Actual vs Target' : 'USDT'})
                </Typography>
              </Typography>
              <Box className={classes.chartRow}>
                <Box className={classes.chart}>
                  <React.Suspense fallback={<></>}>
                    <PortfolioPieChart hasTarget={hasTarget} portfolio={portfolio} type="target" />
                  </React.Suspense>
                </Box>
                <Box className={classes.tokensColumn}>
                  {portfolio.filter((p) => p.targetPercentage).map((p, index) => (
                    <CoinPercentageRow hasTarget={hasTarget} key={index} valueMode={valueMode} coin={p} showColor />
                  ))}
                </Box>
              </Box>
            </Box>
          )}
        </Box>
      </Hidden>
    </Box>
  );
};

PortfolioPie.displayName = 'PortfolioPie';
export { PortfolioPie };
