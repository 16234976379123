import { Box, makeStyles } from '@material-ui/core';
import { HomePageLayoutFooter } from 'components/Layout/Footer/HomePageLayoutFooter';
import React, { FC, ReactNode, useMemo } from 'react';
import { SignInRoute, SignUpRoute } from 'routes';

import { FullPageLayoutHeader } from '../Layout/Header/FullPageLayoutHeader';

interface FullPageLayoutProps {
  route: string;
  children: ReactNode;
  hideFooter?: boolean;
}

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    minHeight: '100vh',
    background: 'white',
  },
}));

export const FullPageLayout: FC<FullPageLayoutProps> = ({ route, hideFooter, children }) => {
  const classes = useStyles();

  const headerButtonRoute = useMemo(() => {
    if ([SignInRoute].includes(route)) return SignUpRoute;
    return undefined;
  }, [route]);
  const headerButtonText = useMemo(() => {
    if ([SignInRoute].includes(route)) return 'Sign Up';
    return undefined;
  }, [route]);

  return (
    <Box className={classes.root}>
      <FullPageLayoutHeader buttonRoute={headerButtonRoute} buttonText={headerButtonText} />
      {children}
      {!hideFooter && <HomePageLayoutFooter />}
    </Box>
  );
};
