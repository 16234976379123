import { Box, makeStyles } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { Loading } from 'components';
import AccountProtectionBadge from 'components/AccountProtectionBadge';
import { Button } from 'elements';
import { InputWithTitle } from 'elements/InputWithTitle';
import { LightTextField } from 'elements/LightTextField';
import { Typography } from 'elements/Typography';
import * as React from 'react';
// eslint-disable-next-line import/no-named-as-default
import ReCAPTCHA from 'react-google-recaptcha';
import { Link } from 'react-router-dom';
import { DashboardRoute, ForgetPasswordRoute, SignUpRoute } from 'routes';
import AuthService from 'services/auth-service';
import UserService from 'services/user-service';
import { useStore as useUserStore } from 'store/zustand/User';

import { LoginSteps, VerificationStatus } from './types/SignIn';

export enum Error {
  None,
  InvalidEmailOrPassword,
  AllFieldsMandatory,
  ProveRobot,
  GenericError,
}

interface Props {
  email: string;
  // eslint-disable-next-line no-unused-vars
  setEmail: (email: string) => void;
  password: string;
  // eslint-disable-next-line no-unused-vars
  setPassword: (password: string) => void;
  isProcessing: boolean;
  // eslint-disable-next-line no-unused-vars
  setIsProcessing: (isProcessing: boolean) => void;
  // eslint-disable-next-line no-unused-vars
  setCurrentStep: (step: LoginSteps) => void;
  verificationStatus: VerificationStatus;
  // eslint-disable-next-line no-unused-vars
  setVerificationStatus: (verificationStatus: VerificationStatus) => void;
  robot: string | null;
  // eslint-disable-next-line no-unused-vars
  setRobot: (robot: string | null) => void;
}

const useStyles = makeStyles((theme) => ({
  inputWrapper: {
    display: 'flex',
    flexDirection: 'column',
    gap: 12,
  },
  link: {
    '& a': {
      color: theme.palette.green[600],
      '&:hover': {
        color: theme.palette.green[700],
      },
    },
  },
  captcha: {
    alignSelf: 'center',
    height: 74,
    marginBottom: 4,
  },
  loading: {
    position: 'absolute',
    top: 0,
    left: 0,
    zIndex: 2,
    backdropFilter: 'blur(12px)',
    background: 'rgba(255, 255, 255, 0.6)',
  },
}));

const SignInForm = ({
  email,
  password,
  setCurrentStep,
  setEmail,
  isProcessing,
  setIsProcessing,
  setPassword,
  verificationStatus,
  robot,
  setRobot,
}: Props) => {
  const classes = useStyles();
  const [error, setError] = React.useState(Error.None);
  const [width, setWidth] = React.useState(window.innerWidth);
  const [isSubmitted, setIsSubmitted] = React.useState(false);
  const recaptchaRef = React.useRef<ReCAPTCHA>(null);
  const [fetchUser] = useUserStore((state) => [state.fetchUser]);
  const handleWindowSizeChange = () => {
    setWidth(window.innerWidth);
  };

  React.useEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange);
    return () => {
      window.removeEventListener('resize', handleWindowSizeChange);
    };
  }, []);

  React.useEffect(() => {
    (async () => { await fetchUser(true); })();
  }, []);

  const handleClickSignIn = React.useCallback(
    async (e) => {
      e.preventDefault();
      setIsProcessing(true);
      setIsSubmitted(true);

      if (!email || !password) {
        setIsProcessing(false);
        setError(Error.AllFieldsMandatory);
        return;
      }

      let isTestMachine;
      try {
        isTestMachine = await UserService.detectTestMachine();
      } catch {
        isTestMachine = false;
      }

      if (!isTestMachine && !robot) {
        setIsProcessing(false);
        setError(Error.ProveRobot);
        return;
      }

      setError(Error.None);

      try {
        const response = await AuthService.signin(
          email,
          password,
          robot,
          { authenticatorCode: '', emailCode: '', phoneCode: '' },
        );

        if (response.payload !== '') {
          const query = new URLSearchParams(window.location.search);
          const redirectUrl = query.get('redirect');
          if (redirectUrl) window.location.href = redirectUrl;
          else window.location.pathname = DashboardRoute;
        } else {
          setEmail(email);
          setPassword(password);
          setCurrentStep(LoginSteps.TwoFactorAuthentication);
        }
      } catch (error: unknown) {
        recaptchaRef.current?.reset();
        setPassword('');
        setError(Error.InvalidEmailOrPassword);
      }

      setIsProcessing(false);
    },
    [setIsProcessing, email, password, robot, setEmail, setPassword, setCurrentStep],
  );

  return (
    <>
      {isProcessing ? (<Loading className={classes.loading} />) : undefined}
      {verificationStatus === VerificationStatus.Verified && (
        <Box mb="20px">
          <Alert severity="success">
            <Typography variant="body2">Thank you! Your account is active. You can now
              <strong>sign in</strong> below.
            </Typography>
          </Alert>
        </Box>
      )}
      {verificationStatus === VerificationStatus.ExpiredOrAlreadyVerified && (
        <Box mb="20px">
          <Alert severity="warning">
            <Typography variant="body2">Your link is expired or your account is already verified.
              Try to
            </Typography>
            <Link to="/forgot-password">reset your password</Link>
            <Typography variant="body2"> if you cannot <strong>Login</strong> below.</Typography>
          </Alert>
        </Box>
      )}
      <form onSubmit={handleClickSignIn} className={classes.inputWrapper}>
        <InputWithTitle title="Email">
          <LightTextField
            type="email"
            name="email"
            value={email}
            size="medium"
            autoComplete="email"
            error={error === Error.AllFieldsMandatory}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => setEmail(e.target.value)}
            placeholder="name@example.com"
          />
        </InputWithTitle>
        <InputWithTitle title="Password">
          <LightTextField
            type="password"
            data-tip=""
            data-for="password"
            data-place="left"
            data-event="keydown"
            data-event-off="focusout"
            size="medium"
            name="password"
            autoComplete="current-password"
            error={error === Error.AllFieldsMandatory}
            value={password}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => setPassword(e.target.value)}
            placeholder="Your password"
          />
        </InputWithTitle>
        <Button type="submit" hidden />
      </form>
      <Typography className={classes.link} variant="h6">
        <a href={ForgetPasswordRoute}>Forgot password?</a>
      </Typography>
      <Box className={classes.captcha}>
        <ReCAPTCHA
          ref={recaptchaRef}
          sitekey="6LcN4q4bAAAAAEcQ--UY9JFgwdr754qtapu0Ovdp"
          onChange={(token: string | null) => setRobot(token)}
          size={width > 360 ? 'normal' : 'compact'}
        />
      </Box>
      {(error !== Error.None || (isSubmitted && !robot)) ? (
        <Box textAlign="left">
          {error === Error.InvalidEmailOrPassword && (
            <Typography variant="h6" color="error">
              Your email and/or password is incorrect. Please try again.
            </Typography>
          )}
          {error === Error.AllFieldsMandatory && (
            <Typography variant="h6" color="error">All fields are mandatory</Typography>)}
          {error === Error.GenericError && (
            <Typography variant="h6" color="error">Something went wrong. Please try again.</Typography>)}
          {((isSubmitted && !robot) || Error.ProveRobot) && (
            <Typography variant="h6" color="error">Please prove you are not a robot</Typography>
          )}
        </Box>
      ) : null}
      <Button
        id="submit-signin"
        fullWidth
        name="sign-in"
        onClick={handleClickSignIn}
        size="xmd"
      >
        Sign in
      </Button>
      <Typography className={classes.link} align="center" variant="h6" palette="grey" paletteColor={500}>
        Don't have an account? <a href={SignUpRoute}>Sign up</a>
      </Typography>
      <AccountProtectionBadge />
    </>
  );
};

SignInForm.displayName = 'SignInForm';

export default SignInForm;
