import { Box, createStyles, Hidden, MenuItem } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Button, Typography } from 'elements';
import { LightFrenchFries } from 'elements/LightFrenchFries';
import { LightMenu } from 'elements/LightMenu';
import React, { useState } from 'react';
import SVG from 'react-inlinesvg';
import { AddressBook } from 'services/types/address-book';

interface Props {
  name: string;
  address: string;
  id?: number;
  walletId: number;
  // eslint-disable-next-line no-unused-vars
  callbackChangeAddressBook?: (value: AddressBookModalOption) => void;
  // eslint-disable-next-line no-unused-vars
  setOpenCopiedSnackBar: (value: boolean) => void;
  // eslint-disable-next-line no-unused-vars
  onRemove?: (value: number) => void;
  awaitingAdd: boolean;
  awaitingEdit: boolean;
  awaitingRemove: boolean;
}

const useStyles = makeStyles((theme) => createStyles({
  addressBookRow: {
    display: 'flex',
    padding: '12px 16px',
    justifyContent: 'space-between',
    alignItems: 'center',
    borderBottom: '1px solid',
    borderBottomColor: theme.palette.grey[200],
    gap: 8,
  },
  addressWrapper: {
    display: 'flex',
    alignItems: 'center',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    gap: 8,
  },
  menuItem: {
    display: 'flex',
    width: '100%',
    alignItems: 'center',
    justifyContent: 'space-between',
    minWidth: 164,
    backgroundColor: 'white',
    borderTop: '1px solid',
    borderTopColor: theme.palette.grey[200],
    '&:hover': {
      backgroundColor: theme.palette.grey[100],
    },
  },
  iconWrapper: {
    display: 'flex',
    width: 24,
    height: 24,
    cursor: 'pointer',
  },
  changeIcon: {
    width: '100%',
    height: '100%',
    '& path': {
      fill: theme.palette.grey[400],
    },
    '& path:last-child': {
      fill: 'unset',
      stroke: theme.palette.grey[400],
    },
  },
  dotsMenu: {
    width: 24,
    height: 24,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    '&#active': {
      border: `1px solid ${theme.palette.green[200]}`,
      boxShadow: '0px 4px 5px rgba(110, 123, 162, 0.08)',
      borderRadius: 4,
      '& circle, ellipse': {
        fill: theme.palette.green[500],
      },
    },
  },
  menuGroup: {
    display: 'flex',
    alignItems: 'center',
    gap: 12,
    flexWrap: 'nowrap',
    flexShrink: 0,
  },
  copyIconWrapper: {
    '&:hover': {
      '& path': {
        fill: theme.palette.green[600],
      },
      '& rect': {
        fill: theme.palette.green[600],
      },
    },
  },
  editIconWrapper: {
    '&:hover': {
      '& path:first-child': {
        fill: theme.palette.green[600],
      },
      '& path:nth-child(2)': {
        fill: theme.palette.green[600],
      },
      '& path:last-child': {
        stroke: theme.palette.green[600],
      },
    },
    '& #disabled': {
      '& path:first-child': {
        fill: theme.palette.grey[200],
      },
      '& path:nth-child(2)': {
        fill: theme.palette.grey[200],
      },
      '& path:last-child': {
        stroke: theme.palette.grey[200],
      },
    },
  },
  removeIconWrapper: {
    '&:hover': {
      '& path:first-child': {
        fill: theme.palette.red[600],
      },
      '& path:last-child': {
        stroke: theme.palette.red[600],
      },
    },
    '& #disabled': {
      '& path:first-child': {
        fill: theme.palette.grey[200],
      },
      '& path:last-child': {
        stroke: theme.palette.grey[200],
      },
    },
  },
}));

export const AddressBookRow = React.memo(({
  name, address, id, walletId, callbackChangeAddressBook, setOpenCopiedSnackBar,
  onRemove, awaitingAdd, awaitingEdit, awaitingRemove,
}: Props) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const classes = useStyles();

  const handleClickChange = () => {
    if (callbackChangeAddressBook) callbackChangeAddressBook({ address, walletId, tag: name, addressBookId: id });
  };

  const handleClickCopy = async () => {
    navigator.clipboard.writeText(address);
    setIsMenuOpen(false);
    setOpenCopiedSnackBar(true);
  };

  const handleClickRemove = () => { if (id && onRemove) onRemove(id); };

  return (
    <Box key={address} className={classes.addressBookRow}>
      <Box className={classes.addressWrapper}>
        <LightFrenchFries label={name} autoAssignColor noShrink disabled={awaitingAdd} />
        <Typography
          variant="h6"
          paletteColor={500}
          middleTruncation
        >
          {address}
        </Typography>
      </Box>
      {awaitingAdd ? (
        <Typography
          variant="h6"
          paletteColor={500}
          strong={false}
        >
          <SVG width={16} height={16} src="/icons/ic_time.svg" /> Awaiting for approval
        </Typography>
      ) : (
        <>
          <Hidden smDown>
            <Box className={classes.menuGroup}>
              <Button
                variant="icon"
                className={`${classes.iconWrapper} ${classes.copyIconWrapper}`}
                onClick={handleClickCopy}
              >
                <SVG src="/icons/reporting/ic_copy.svg" className={classes.changeIcon} />
              </Button>
              <Button
                tooltip={awaitingEdit ? {
                  title: (
                    <Box display="flex" gridGap={4} alignItems="center">
                      <SVG width={12} height={12} src="/icons/ic_time.svg" />
                      <Typography variant="h6" paletteColor={600}>Previous change is awaiting for approval</Typography>
                    </Box>
                  ),
                  enterTouchDelay: 1,
                  placement: 'top-end',
                  arrow: true,
                } : undefined}
                variant="icon"
                disabled={awaitingEdit}
                className={`${classes.iconWrapper} ${classes.editIconWrapper}`}
                onClick={handleClickChange}
              >
                <SVG
                  src="/icons/dashboard/ic_edit.svg"
                  className={classes.changeIcon}
                  id={awaitingEdit ? 'disabled' : undefined}
                />
              </Button>
              <Button
                tooltip={(awaitingRemove || !id) ? {
                  title: !id ? 'Cannot remove reporting address' : (
                    <Box display="flex" gridGap={4} alignItems="center">
                      <SVG width={12} height={12} src="/icons/ic_time.svg" />
                      <Typography variant="h6" paletteColor={600}>Previous change is awaiting for approval</Typography>
                    </Box>
                  ),
                  enterTouchDelay: 1,
                  placement: 'top-end',
                  arrow: true,
                } : undefined}
                variant="icon"
                disabled={awaitingRemove || !id}
                className={`${classes.iconWrapper} ${classes.removeIconWrapper}`}
                onClick={handleClickRemove}
              >
                <SVG
                  src="/icons/reporting/ic_remove.svg"
                  className={classes.changeIcon}
                  id={(awaitingRemove || !id) ? 'disabled' : undefined}
                />
              </Button>
            </Box>
          </Hidden>
          <Hidden mdUp>
            <LightMenu
              parent={(
                <Box className={classes.dotsMenu} id={isMenuOpen ? 'active' : ''}>
                  <SVG src="/icons/ic_dots.svg" width={24} height={24} />
                </Box>
              )}
              open={isMenuOpen}
              setOpen={setIsMenuOpen}
            >
              <MenuItem className={classes.menuItem} onClick={handleClickCopy}>
                <Typography variant="h4" palette="grey" paletteColor={600}>Copy</Typography>
                <Box className={classes.iconWrapper}>
                  <SVG src="/icons/reporting/ic_copy.svg" className={classes.changeIcon} />
                </Box>
              </MenuItem>
              {!awaitingEdit && (
                <MenuItem className={classes.menuItem} onClick={handleClickChange}>
                  <Typography variant="h4" palette="grey" paletteColor={600}>Change</Typography>
                  <Box className={classes.iconWrapper}>
                    <SVG src="/icons/dashboard/ic_edit.svg" className={classes.changeIcon} />
                  </Box>
                </MenuItem>
              )}
              {id && !awaitingRemove && (
                <MenuItem className={classes.menuItem} onClick={handleClickRemove}>
                  <Typography variant="h4" palette="grey" paletteColor={600}>Remove</Typography>
                  <Box className={classes.iconWrapper}>
                    <SVG src="/icons/reporting/ic_remove.svg" className={classes.changeIcon} />
                  </Box>
                </MenuItem>
              )}
            </LightMenu>
          </Hidden>
        </>
      )}
    </Box>
  );
});

AddressBookRow.displayName = 'AddressBookRow';

export type AddressBookModalOption = Omit<AddressBook, 'addressBookId'> & { addressBookId?: number }
