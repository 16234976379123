// @flow
import { Box, createStyles } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Typography } from 'elements';
import React from 'react';
import SVG from 'react-inlinesvg';

import { AddPayrollModal } from './AddPayrollModal';
import RemoveModal from './RemoveModal';

const useStyles = makeStyles(() => createStyles({
  root: {
    display: 'flex',
    flexDirection: 'column',
    width: 164,
  },
  item: {
    cursor: 'pointer',
    display: 'flex',
    flexWrap: 'nowrap',
    padding: '8px 12px',
    justifyContent: 'space-between',
  },
}));

interface OptionMenuProps {
  id?: number;
  // eslint-disable-next-line no-unused-vars
  setIsConfirmationModalOpen: () => void;
  onClose: () => void;
  payrollInProgress: boolean;
  haveEnoughFund: boolean;
}

export const OptionMenu = ({
  id,
  setIsConfirmationModalOpen,
  onClose,
  payrollInProgress,
  haveEnoughFund,
}: OptionMenuProps) => {
  const classes = useStyles();

  const [showRemoveModal, setShowRemoveModal] = React.useState(false);
  const [showEditModal, setShowEditModal] = React.useState(false);

  const getItemMenu = (name: string, icon: string, onClick: () => void) => (
    <Box className={classes.item} onClick={onClick}>
      <Typography variant="h4" paletteColor={600}>{name}</Typography>
      <SVG src={icon} />
    </Box>
  );

  return (
    <>
      <Box className={classes.root}>
        {getItemMenu('Edit', '/icons/batch_payroll/ic_edit.svg', () => { setShowEditModal(true); })}
        {!payrollInProgress && haveEnoughFund && getItemMenu('Pay now', '/icons/batch_payroll/ic_paynow.svg', () => { setIsConfirmationModalOpen(); })}
        {/* {getItemMenu('View history', '/icons/batch_payroll/ic_history.svg', () => { })} */}
        {getItemMenu('Remove', '/icons/batch_payroll/ic_remove.svg', () => { setShowRemoveModal(true); })}
      </Box>
      {showRemoveModal && <RemoveModal id={id} showModal={showRemoveModal} setShowModal={setShowRemoveModal} onClose={onClose} />}
      {showEditModal && <AddPayrollModal open={showEditModal} setOpen={setShowEditModal} editPayrollId={id} onClose={onClose} />}
    </>
  );
};
