// eslint-disable-file max-len

import { RebalanceConfigMode } from 'services/types/wallet-enum';

export type RebalancingMethod = {
  name: string;
  icon: string;
  description: string;
  shortDescription: string;
};

export const RebalancingMethods: Record<string, RebalancingMethod> = {
  [RebalanceConfigMode.manual]: {
    name: 'Manual',
    icon: '/icons/rebalancing/ic_manual.png',
    // eslint-disable-next-line max-len
    description: 'Manual rebalancing allows you to choose when your portfolio gets rebalanced. This will require you to manually initiate any rebalancing on your portfolio.',
    shortDescription: 'Manual rebalancing allows you to choose when your portfolio gets rebalanced.',
  },
  [RebalanceConfigMode.static]: {
    name: 'Static',
    icon: '/icons/rebalancing/ic_static.png',
    // eslint-disable-next-line max-len
    description: 'Static rebalancing is time based and it is triggered approximately once a month.\nThis ensures that your wallet is aligned with your target portfolio and enables you to take profits on that time cadence.',
    shortDescription: 'Static rebalancing is time based and it is triggered approximately once a month.',
  },
  [RebalanceConfigMode.dynamic]: {
    name: 'Dynamic',
    icon: '/icons/rebalancing/ic_dynamic.png',
    // eslint-disable-next-line max-len
    description: 'Dynamic rebalancing is reactive to events and gets triggered when your wallet substantially deviates from your target portfolio. This ensures that your wallet is promptly aligned with your target portfolio and enables profit taking during swings in volatile periods.',
    // eslint-disable-next-line max-len
    shortDescription: 'Reactive to events and gets triggered when your wallet substantially deviates from your target portfolio.',
  },
};
