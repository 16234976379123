import { Box, CircularProgress, createStyles, makeStyles } from '@material-ui/core';
import React, { useCallback, useEffect, useState } from 'react';
import { AccessRevokedRoute } from 'routes';
import { breakpoints } from 'theme/base';

import AuthService, { UserInfo } from '../../../services/auth-service';
import AccountRemoved from './AccountRemoved';
import { RevokeAccessBanner } from './RevokeAccessBanner';

const useStyles = makeStyles(() => createStyles({
  root: {
    display: 'grid',
    gridTemplateColumns: '176px 1fr 176px',
    width: '100%',
    gap: '100px',

    [breakpoints.down('sm')]: {
      gridTemplateColumns: '1fr',
      gap: '24px',
    },
  },
  superRoot: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    padding: '24px',
    gap: '24px',
    flex: 1,
    alignItems: 'center',
    [breakpoints.down('sm')]: {
      padding: '16px',
    },
  },
  accountRemoved: {
    display: 'flex',
    flexGrow: 1,
    marginTop: '100px',
    [breakpoints.down('sm')]: {
      marginTop: 0,
      alignItems: 'center',
    },
  },
}));

const AccessRevoked = () => {
  const classes = useStyles();
  const [userInfo, setUserInfo] = useState<UserInfo | null>(null);

  useEffect(() => {
    (async () => {
      await refreshUserInfo();
    })();
  }, []);

  useEffect(() => {
    if (window.Tawk_API && window.Tawk_API.hideWidget) window.Tawk_API.hideWidget();
    return function cleanup() {
      if (window.Tawk_API && window.Tawk_API.showWidget) window.Tawk_API.showWidget();
    };
  });

  const refreshUserInfo = useCallback(async () => {
    const response = await AuthService.getUserInfo();
    if (response?.data) {
      setUserInfo(response.data);
    }
  }, []);

  if (!userInfo) {
    return (
      <Box className={classes.superRoot} margin="10px auto" textAlign="center">
        <CircularProgress color="primary" />
      </Box>
    );
  }

  return (
    <>
      <Box className={classes.superRoot}>
        <RevokeAccessBanner userInfo={userInfo} />
        <Box className={classes.accountRemoved}>
          <AccountRemoved />
        </Box>
      </Box>
    </>
  );
};

AccessRevoked.routePath = AccessRevokedRoute;
AccessRevoked.loginRequired = true;
AccessRevoked.allowedForAccessRevokedUser = true;

export default AccessRevoked;
