import { Box, makeStyles } from '@material-ui/core';
import { Button, Card } from 'elements';
import { InputWithTitle } from 'elements/InputWithTitle';
import { LightTextField } from 'elements/LightTextField';
import { Typography } from 'elements/Typography';
import { useTimedButton } from 'hooks/useTimedButton';
import * as React from 'react';
import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { ForgetPasswordRoute, SignInRoute } from 'routes';
import AuthService from 'services/auth-service';
import isEmail from 'validator/lib/isEmail';

import BackgroundIcons from '../Authentication/Components/BackgroundIcons';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    width: '100%',
    justifyContent: 'center',
    '& em': {
      color: theme.palette.green[600],
      fontStyle: 'normal',
    },
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    minHeight: '70vh',
    backgroundColor: 'white',
    padding: 16,
    '&::before': {
      content: '" "',
      width: '1600px',
      height: '1200px',
      top: '-400px',
      left: '40%',
      position: 'absolute',
      background: 'radial-gradient(48.42% 48.42% at 50% 50%, rgba(46, 255, 218, 0.315) 0%, rgba(235, 255, 252, 0) 100%)',
      zIndex: 0,
      filter: 'blur(80px)',
      [theme.breakpoints.down('sm')]: {
        display: 'none',
      },
    },
  },
  card: {
    maxWidth: 490,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    rowGap: 16,
    width: 'auto',
    textAlign: 'center',
    padding: 24,
    background: 'rgba(255, 255, 255, 0.9)',
    backdropFilter: 'blur(8px)',
    [theme.breakpoints.down('sm')]: {
      padding: 24,
    },
  },
  title: {
    color: theme.palette.grey[700],
    fontSize: 28,
    lineHeight: '44px',
    fontWeight: 700,
    [theme.breakpoints.down('sm')]: {
      fontSize: 24,
      lineHeight: '32px',
    },
  },
  subTitle: {
    color: theme.palette.grey[700],
    fontSize: 14,
    lineHeight: '24px',
    fontWeight: 400,
    [theme.breakpoints.down('sm')]: {
      fontSize: 12,
      lineHeight: '20px',
    },
  },
  input: {
    width: '100%',
    margin: '4px 0',
  },
}));

const Forgot = () => {
  const classes = useStyles();
  const history = useHistory();

  const [email, setEmail] = useState('');
  const [emailError, setEmailError] = React.useState('');

  const { disableButton, buttonText, showSuccess, onClick } = useTimedButton(
    'Send link',
    'Resend',
    async () => {
      await clickForgetPassword();
    },
  );

  const submitButton = async () => {
    setEmailError('');
    if (isEmail(email)) {
      await onClick();
    } else {
      setEmailError('Please enter a valid email address');
    }
  };

  const submitForm = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    await submitButton();
  };

  const clickForgetPassword = async () => {
    try {
      await AuthService.forgot(email);
    } catch (e) {
      // Ignore this error
    }
  };

  return (
    <Box className={classes.root}>
      <Box className={classes.content}>
        <BackgroundIcons />
        <Card className={classes.card} borderRadius={32}>
          <Typography className={classes.title} variant="h1">Forgot <em>password?</em></Typography>
          <Typography className={classes.subTitle} variant="h2" strong={false} palette="grey" paletteColor={700}>
            We will send you an email with the link to set new password
          </Typography>
          <form onSubmit={submitForm}>
            <Box className={classes.input}>
              <InputWithTitle title="Email">
                <LightTextField
                  type="email"
                  placeholder="Enter your email address"
                  name="email"
                  value={email}
                  size="medium"
                  onChange={(e) => {
                    setEmail(e.target.value);
                  }}
                />
              </InputWithTitle>
              {emailError && (
                <Typography style={{ textAlign: 'left' }} variant="h6" palette="red" paletteColor={500} strong={false}>
                  {emailError}
                </Typography>
              )}
            </Box>
          </form>
          {showSuccess && (
            <Box>
              <Typography variant="h6" strong={false} palette="green" paletteColor={500}>
                You will be receiving an email shortly if this email is associated to a Coinbag account.
              </Typography>
            </Box>
          )}
          <Button
            variant="primary"
            size="xlg"
            disabled={disableButton}
            onClick={submitButton}
            fullWidth
          >
            {buttonText}
          </Button>
          <Button
            variant="secondary"
            size="xlg"
            onClick={() => {
              history.push(SignInRoute);
            }}
            fullWidth
          >
            Cancel
          </Button>
        </Card>
      </Box>
    </Box>
  );
};
Forgot.routePath = ForgetPasswordRoute;
Forgot.displayName = 'Forgot';
Forgot.logoutRequired = true;

export default Forgot;
