import { MasterCoinStatus } from '../../../services/coin-enum';
import { MasterCoin } from '../../../services/types/coin';
import { BundleCoin } from './Bundle/BundleCard';

const getAvailableTokens = (getMasterCoins: () => Record<string, MasterCoin>) => Object.values(getMasterCoins())
  .filter((coin) => coin.status !== MasterCoinStatus.UiDisabled)
  .reduce((sum, masterCoin) => {
    // eslint-disable-next-line no-param-reassign
    sum[masterCoin.ticker] = {
      icon: masterCoin.icon,
      name: masterCoin.ticker,
      percentage: 0,
      color: masterCoin.metadata?.color ?? '#BBC4DD',
      id: masterCoin.masterCoinId,
      type: masterCoin.metadata?.marketCap?.category || 'OthersCaps',
    } as BundleCoin;
    return sum;
  }, {} as { [key: string]: BundleCoin });

export default getAvailableTokens;
