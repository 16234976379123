import { Box, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import * as React from 'react';
import SVG from 'react-inlinesvg';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    alignItems: 'flex-start',
    gap: 4,
    padding: 8,
    backgroundColor: theme.palette.yellow['100'],
    borderRadius: 8,
  },
  contentBox: {
    display: 'flex',
    gap: 8,
    alignItems: 'center',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
    },
  },
  icon: {
    width: 16,
    height: 16,
    alignSelf: 'flex-start',
  },
  iconWrapper: {
    display: 'flex',
    width: 16,
    height: 16,
    flexShrink: 0,
  },
  bannerTitle: {
    lineHeight: '16px',
    alignSelf: 'flex-start',
    color: theme.palette.yellow[600],
  },
  bannerText: {
    '& em': {
      fontWeight: 700,
      fontStyle: 'normal',
    },
    '& br': {
      margin: '4px 0',
    },
    lineHeight: '16px',
    fontWeight: 300,
    color: theme.palette.yellow[700],
  },
  textWrapper: {
    display: 'flex',
    flexDirection: 'column',
    gap: 8,
  },
}));

export const CautionBanner = () => {
  const classes = useStyles();

  return (
    <Box className={classes.root}>
      <Box className={classes.iconWrapper}>
        <SVG src="icons/dashboard/ic_attention_yellow.svg" className={classes.icon} />
      </Box>
      <Box className={classes.contentBox}>
        <Typography variant="h6" className={classes.bannerTitle}>Disclaimer:</Typography>
        <Box className={classes.textWrapper}>
          <Typography variant="h6" className={classes.bannerText}>
            <em>Lending</em> using 3rd party decentralized protocols poses significant risks to your digital assets. It is solely your responsibility to understand the risks/rewards before lending your assets.
          </Typography>
          <Typography variant="h6" className={classes.bannerText}>
            <em>Staking</em> of assets may subject your assets to a lock-up period set by the blockchain. This means you will need to wait the designated amount of days before your cryptos can be withdrawn.
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};
