import React from 'react';
import { Redirect } from 'react-router-dom';
import Logger from 'js-logger';

import AuthService from '../../services/auth-service.js';
import bundleService from '../../services/bundle.service';
import walletService from '../../services/wallet-service';

import { Container, Row, Col } from 'react-bootstrap';
import SharedStateComponent from '../../components/SharedStateComponent.js';

import { InternalNavbar } from '../../components/navbar.js';
import { StepNav } from '../../components/StepNav.js';
import { Footer } from '../../components/Footer.js';
import Loader from '../../components/Loader.js';
import Risk from '../../components/Risk';

import ChooseExchange from '../responsive/ChooseExchange.js';

export default class ManageRisk extends SharedStateComponent {
  static routePath = '/manage-risk';
  static loginRequired = false;

  constructor(props) {
    super(props);

    this.submitScore = this.submitScore.bind(this);
    this.riskComponent = React.createRef();

    var prioritizeQuestions = false;
    if (props?.location?.state?.prioritizeQuestions !== undefined) {
      prioritizeQuestions = props.location.state.prioritizeQuestions;
    }

    this.state = {
      bundles: [],
      prioritizeQuestions
    };
  }

  componentDidMount() {
    let bundles = bundleService.getActiveBundles();
    let wallet = walletService.getWallet();
    let riskScore = walletService.getLatestRiskScore(this.state.prioritizeQuestions);

    Promise.all([bundles, wallet, riskScore])
      .then((arr) => {
        var bundles = arr[0].data;
        var wallet = arr[1].data;
        var riskScore = arr[2].data != null ? arr[2].data : 50;

        if (wallet.exchangeId) {
          bundles = bundleService.filterBundles(bundles, wallet.exchangeId);
        } else {
          bundles = bundleService
            .filterBundles(bundles, 1)
            .concat(bundles.filter((bundle) => bundle.riskScore === 0));
        }

        this.setState({
          wallet: wallet,
          initRiskScore: wallet.riskScore,
          bundles,
          riskScore
        });
      });
  }

  submitScore(e) {
    e.preventDefault();
    walletService
      .addRiskScore(this.state.riskScore.value)
      .then(
        () => {
          AuthService.updateUserStep()
            .then(() => this.props.history.push(ChooseExchange.routePath));
        },
        (error) => {
          Logger.log(error);
        });
  }

  renderWithSharedState() {
    if (this.state.redirect) {
      return <Redirect to={{
        pathname: this.state.redirect,
        redirectState: this.state.redirectState
      }}/>;
    }

    const canRender = this.state.bundles?.length > 0 && 'riskScore' in this.state;
    if (!canRender) {
      return (<Loader loading={true}/>);
    }

    return (
      <>
        <Loader loading={false}/>
        <InternalNavbar history={this.props.history}/>

        <StepNav active={2}/>
        <div className="desktop-stepnav">
          <Container fluid className="px-4 pb-3">
            <Row className="align-items-center">
              <Col className="px-1">
                <h4>Setting up your investment level</h4>
                <p className="mt-3 mb-4">
                  Let us know which level best matches your goals. Once ready, you can proceed to the next
                  step of connecting to an exchange.
                </p>

                <button className="btn-first btn-block mt-4" onClick={this.submitScore}>
                  Proceed with this level
                </button>

              </Col>

              <Col xs={7}>
                <Container className="content-card p-4">
                  <Risk name="riskScore" value={this.state.riskScore} bundles={this.state.bundles} editMode={true}/>
                </Container>
              </Col>
            </Row>
          </Container>
        </div>

        <Footer/>
      </>
    );
  }
}
