import { Box, makeStyles, Typography } from '@material-ui/core';
import { Button } from 'elements';
import React, { FC, useCallback, useState } from 'react';
import SVG from 'react-inlinesvg';
import Cookies from 'universal-cookie';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
    background: theme.palette.blue['600'],
  },
  content: {
    display: 'flex',
    alignItems: 'start',

    padding: '8px 16px',
    maxWidth: 1080,
    width: '100%',

    [theme.breakpoints.up('md')]: {
      alignItems: 'center',
    },
  },
  contentLeft: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'start',
    rowGap: 4,

    marginRight: 4,
    width: '100%',

    [theme.breakpoints.up('md')]: {
      flexDirection: 'row',
      alignItems: 'center',
    },
  },

  text: {
    color: 'white',
    fontSize: 12,
    lineHeight: '24px',
    fontWeight: 500,

    [theme.breakpoints.up('md')]: {
      fontSize: 16,
      fontWeight: 700,
      lineHeight: '32px',
    },
  },

  learnButton: {
    flexShrink: 0,
    padding: 0,
    minHeight: 0,

    fontSize: 12,

    '&:hover': {
      color: 'white',
      background: 'transparent',
    },

    [theme.breakpoints.up('md')]: {
      padding: '4px 12px',

      color: theme.palette.blue['600'],
      fontWeight: 600,
      background: 'white',

      '&:hover': {
        color: theme.palette.blue['600'],
        background: theme.palette.grey['100'],
      },
    },
  },
  closeButton: {
    width: 24,
    height: 24,
  },
}));

const LearnBanner: FC = () => {
  const classes = useStyles();

  const cookies = new Cookies();
  const [closed, setClosed] = useState(cookies.get('learn-banner') ?? false);
  const onClose = useCallback(() => {
    setClosed(true);
    cookies.set('learn-banner', true);
  }, [setClosed]);

  if (closed) return null;
  return (
    <Box className={classes.root}>
      <Box className={classes.content}>
        <Box className={classes.contentLeft}>
          <Typography className={classes.text}>
            Your wallets. Your control. Learn how we help you manage your digital assets <u>the right way</u>.
          </Typography>
          <Button className={classes.learnButton} color="blue" href="#risk-mitigation">
            LEARN MORE <Box width={12}><SVG src="/icons/icon_arrow_down.svg" height={12} /></Box>
          </Button>
        </Box>
        <Button className={classes.closeButton} variant="icon" onClick={onClose}>
          <SVG src="/icons/icon_cross.svg" color="white" />
        </Button>
      </Box>
    </Box>
  );
};

LearnBanner.displayName = 'LearnBanner';
export { LearnBanner };
