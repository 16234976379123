import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { User } from '../../models';

type UserState = {
  user?: User
}

const initialState: UserState = { user: undefined };

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    updateUser: (state: UserState, action: PayloadAction<User>) => ({ user: { ...state.user, ...action.payload } }),
  },
});

export const { updateUser } = userSlice.actions;
