import { Box, Button, createStyles, makeStyles, TextField, Typography } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import { Content } from 'components/Content';
import { LightTooltip } from 'elements/LightTooltip';
import * as React from 'react';
import { CreateBundleRoute } from 'routes';
import { BundleV2 } from 'services/types/bundle';

import adminBundleService from '../../../services/adminbundle.service';
import userBundleService from '../../../services/userbundle-service';
import { useStore as useStoreCoin } from '../../../store/zustand/Coin';
import { BundleCoin } from './Bundle/BundleCard';
import { CoinBar } from './Elements/CoinBar';
import TokenSelector from './Elements/TokenSelector';
import getAvailableTokens from './getAvailableTokens';

const useStyles = makeStyles(() => createStyles({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: '16px',
    width: '100%',
    padding: '2em 12px',
  },
  title: {
    color: '#283149',
  },
  subTitle: {
    color: '#38425E',
    textAlign: 'center',
  },
  card: {
    border: '1px solid #DEE3EF',
    borderRadius: '8px',
    width: '100%',
    background: 'white',
    display: 'flex',
    flexDirection: 'column',
  },
  fieldGroup: {
    display: 'flex',
    flexDirection: 'column',
    gap: '12px',
    padding: '12px',
  },
  tokenInput: {
    fontSize: '12px',
    background: 'white',
    paddingTop: '0',
    paddingBottom: '0',
    borderRadius: '8px',
  },
  footer: {
    display: 'flex',
    flexDirection: 'column',
    overflow: 'hidden',
  },
  removeButton: {
    width: '20px',
    height: '20px',
  },
  footerTextWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '12px 12px 0',
  },
  footerCoinbarWrapper: {
    padding: '0 12px 12px',
  },
  footerText: {
    fontSize: '12px',
    fontWeight: 500,
    lineHeight: '20px',
    color: '#38425E',
  },
  footerTextAvailable: {
    fontSize: '12px',
    fontWeight: 400,
    lineHeight: '20px',
    color: '#BBC4DD',
    display: 'inline',
  },
  footerTextValue: {
    fontSize: '12px',
    fontWeight: 500,
    lineHeight: '20px',
    color: '#6E7BA1',
    display: 'inline',
    paddingLeft: '4px',
  },
  footerActionWrapper: {
    borderTop: '1px solid #DEE3EF',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '16px 12px',
  },
  footerClear: {
    fontSize: '14px',
    fontWeight: 500,
    lineHeight: '24px',
    color: '#38425E',
    display: 'flex',
    gap: 8,
    flexWrap: 'nowrap',
    cursor: 'pointer',
    alignItems: 'center',
  },
  footerApplyButton: {
    color: 'white',
    maxWidth: '140px',
    textTransform: 'none',
    width: '100%',
  },
}));

const CreateOrEditBundle = () => {
  const classes = useStyles();

  const [availableTokens, setAvailableTokens] = React.useState<BundleCoin[]>([]);
  const [selectedTokens, setSelectedTokens] = React.useState<BundleCoin[]>([]);
  const [name, setName] = React.useState('');
  const [description, setDescription] = React.useState('');
  const [imageUrl, setImageUrl] = React.useState('');
  const [bundleId, setBundleId] = React.useState<number | undefined>(undefined);
  const [getMasterCoins, fetchCoins] = useStoreCoin((state) => [state.getMasterCoins, state.fetchCoins]);
  const [editableBundles, setEditableBundles] = React.useState<BundleV2[]>([]);

  React.useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    await Promise.all([fetchCoins(), fetchEditableBundle()]);
    const tokens = getAvailableTokens(getMasterCoins);
    setAvailableTokens(Object.values(tokens));
  };

  const fetchEditableBundle = async () => {
    const bundles = await userBundleService.getUserEditableBundles();
    if (bundles.data.length > 0) {
      setEditableBundles(bundles.data);
    }
  };

  const totalSelectedPercentage = React.useMemo(() => selectedTokens
    .reduce((total, token) => total + token.percentage, 0), [selectedTokens]);

  const clearAllTokens = () => {
    selectedTokens.forEach((token) => {
      // eslint-disable-next-line no-param-reassign
      token.percentage = 0;
    });
    setSelectedTokens([]);
  };

  const onCreateBundle = async () => {
    if (name.length <= 0) {
      alert('name is require');
      return;
    }
    if (description.length <= 0) {
      alert('description is require');
      return;
    }
    if (imageUrl.length <= 0) {
      alert('image url is require');
      return;
    }

    const coins = selectedTokens.filter((c) => c.percentage > 0).map((c) => [c.id, c.percentage]);
    if (bundleId) {
      const response = await adminBundleService.editBundle(bundleId, name, description, coins, imageUrl);
      if (response.status === 200) {
        alert(`bundle edited {id: ${response.data.bundleId}, stateId: ${response.data.bundleStateId}}`);
      } else {
        alert('fail edit bundle');
      }
    } else {
      const response = await adminBundleService.createBundle(name, description, coins, imageUrl);
      if (response.status === 200) {
        alert(`bundle created {id: ${response.data.bundleId}, stateId: ${response.data.bundleStateId}}`);
      } else {
        alert('fail crate bundle');
      }
    }
  };

  const onChangeBundle = (bundle: BundleV2 | null) => {
    setBundleId(bundle?.bundleId);
    setName(bundle?.name || '');
    setDescription(bundle?.description || '');
    setImageUrl(bundle?.ImageUrl || '');
    availableTokens.forEach((token) => {
      const selectedPercentage = bundle?.bundleMasterCoins.find((b) => b.id === token.id)?.percentage || 0;
      // eslint-disable-next-line no-param-reassign
      token.percentage = selectedPercentage;
    });
    setSelectedTokens(availableTokens.filter((token) => token.percentage > 0));
  };

  return (
    <Content>
      <Box className={classes.root}>
        <Typography className={classes.title} variant="h2">Create/Edit bundle</Typography>
        <Typography className={classes.subTitle} variant="h5">Admin tool</Typography>
        <Box className={classes.card}>
          <Box className={classes.fieldGroup}>
            <Autocomplete
              style={{ background: 'white', zIndex: 1 }}
              options={editableBundles}
              onChange={(e, v) => { onChangeBundle(v); }}
              getOptionLabel={(option) => `[${option.bundleId}] ${option.name}`}
              renderInput={(params) => (
                <TextField
                  {...params}
                  size="small"
                  variant="outlined"
                  fullWidth
                  placeholder="Create new or select bundle to edit"
                  InputProps={{
                    ...params.InputProps,
                    classes: { root: classes.tokenInput },
                  }}
                />
              )}
            />
            <TextField
              size="small"
              variant="outlined"
              fullWidth
              label="Bundle Name"
              value={name}
              onChange={(e) => setName(e.target.value)}
              InputProps={{
                classes: { root: classes.tokenInput },
              }}
            />
            <TextField
              size="small"
              variant="outlined"
              fullWidth
              multiline
              label="Description"
              value={description}
              onChange={(e) => setDescription(e.target.value)}
              InputProps={{
                classes: { root: classes.tokenInput },
              }}
            />
            <TextField
              size="small"
              variant="outlined"
              fullWidth
              label="Image url"
              value={imageUrl}
              onChange={(e) => setImageUrl(e.target.value)}
              InputProps={{
                classes: { root: classes.tokenInput },
              }}
            />
          </Box>
          <TokenSelector
            availableTokens={availableTokens}
            selectedTokens={selectedTokens}
            setSelectedTokens={setSelectedTokens}
          />
          <Box className={classes.footer}>
            <Box className={classes.footerTextWrapper}>
              <Typography className={classes.footerText}>Token allocations must be equal exactly 100%</Typography>
              <Box className={classes.footerTextWrapper}>
                <Typography className={classes.footerTextAvailable}>Available</Typography>
                <Typography
                  className={classes.footerTextValue}
                >{(100 - totalSelectedPercentage).toFixed(1)}/100%
                </Typography>
              </Box>
            </Box>
            <Box className={classes.footerCoinbarWrapper}>
              <CoinBar coins={selectedTokens.map((token) => ({ ...token, percentage: (token.percentage / 100) }))} />
            </Box>
            <Box className={classes.footerActionWrapper}>
              <Box className={classes.footerClear} onClick={clearAllTokens}>
                <img className={classes.removeButton} src="/icons/bundle/cross.svg" alt="remove" />Clear
              </Box>
              <LightTooltip
                placement="bottom-end"
                title={totalSelectedPercentage < 100 ? 'You should fill all inputs and reach 100% to proceed' : ''}
              >
                <Box className={classes.footerApplyButton}>
                  <Button
                    fullWidth
                    disabled={totalSelectedPercentage < 100}
                    onClick={onCreateBundle}
                    className={classes.footerApplyButton}
                  >
                    Submit
                  </Button>
                </Box>
              </LightTooltip>
            </Box>
          </Box>
        </Box>
      </Box>
    </Content>
  );
};

CreateOrEditBundle.routePath = CreateBundleRoute;
CreateOrEditBundle.adminRequire = true;
CreateOrEditBundle.loginRequire = true;
export default CreateOrEditBundle;
