/* eslint-disable no-unused-vars */
/* eslint-disable no-shadow */
export enum RebalanceConfigMode {
  manual = 'manual',
  static = 'static',
  dynamic = 'dynamic',
}

export enum FAStatus {
  Unknown,
  Success,
  IncorrectOrExpired,
  AllFieldsMandatory,
  GenericError,
}

export enum WalletLevel {
  Green = 'Green level',
  Bronze = 'Bronze level',
  Silver = 'Silver level',
  Gold = 'Gold level',
  Private = 'Private client'
}

export const WalletLevelOrder: Record<WalletLevel, number> = {
  [WalletLevel.Green]: 1,
  [WalletLevel.Bronze]: 2,
  [WalletLevel.Silver]: 3,
  [WalletLevel.Gold]: 4,
  [WalletLevel.Private]: 5,
};
