import { Box, LinearProgress } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Typography } from 'elements';
import React from 'react';

interface DepositProgressBannerProps {
  portfolioValue: number;
  minimumAmount: number;
}

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    padding: 12,
    boxSizing: 'border-box',
    border: '2px dashed #DEE3EF',
    borderRadius: 8,
    [theme.breakpoints.up('md')]: {
      padding: 16,
    },
  },
  noteBox: {
    marginBottom: 12,
    display: 'flex',
    flexWrap: 'nowrap',
  },
  lightBulb: {
    width: 24,
    height: 24,
    marginRight: 8,
    flexShrink: 0,
    [theme.breakpoints.down('sm')]: {
      width: 16,
      height: 16,
    },
  },
  noteText: {
    color: theme.palette.grey[500],
    [theme.breakpoints.down('sm')]: {
      fontSize: 12,
      lineHeight: '20px',
    },
  },
  noteMessage: {
    color: theme.palette.grey[700],
    paddingLeft: 8,

    [theme.breakpoints.down('sm')]: {
      fontSize: 12,
      lineHeight: '20px',
      paddingLeft: 4,
    },
  },
  progressBarText: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-end',
    marginBottom: 4,
  },
  amount: {
    color: theme.palette.green[500],
  },
  progressBar: {
    width: '100%',
    height: '4px',
    borderRadius: '8px',
  },
}));

export const DepositProgressBanner = ({ portfolioValue, minimumAmount }: DepositProgressBannerProps) => {
  const classes = useStyles();

  const progress = (portfolioValue / minimumAmount) * 100;
  return (
    <Box className={classes.root}>
      <Box className={classes.noteBox}>
        <img src="icons/ic_green_light_bulb.svg" alt="Green light bulb" className={classes.lightBulb} />
        <Typography variant="h4" component="span" className={classes.noteText}>
          Note:
          <Typography variant="h4" component="span" className={classes.noteMessage}>
            To earn on investments you need ${minimumAmount}+ on your account
          </Typography>
        </Typography>
      </Box>
      <Box className={classes.progressBarText}>
        <Typography palette="grey" paletteColor={500} variant="h6">Deposit more to start earning</Typography>
        <Typography variant="h6" className={classes.amount}>${portfolioValue.toFixed(0)}/$350</Typography>
      </Box>
      <LinearProgress variant="determinate" className={classes.progressBar} value={progress} />
    </Box>
  );
};
