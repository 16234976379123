import React from 'react';
import FormField from './FormField';

import { Col, Container, Row } from 'react-bootstrap';
import ProgressRing from './ProgressRing';

import { DownArrowGreenIcon, MinusIcon, PlusIcon, UpArrowGreenIcon } from './Icons';

class Risk extends FormField {
  constructor(props) {
    super(props);
    this.onChange = this.onChange.bind(this);
  }

  onChange(e) {
    e.preventDefault();

    let lowerBound = 10;
    if (Math.min(...this.props.bundles.map(bundle => bundle.riskScore)) < lowerBound) {
      lowerBound = 0;
    }

    if (e.currentTarget.dataset.id === '-') {
      if (this.value > lowerBound) this.updateValue(this.value - 5);
    } else if (this.value < 100) {
      this.updateValue(this.value + 5);
    }
  }

  renderCoinBar(coinBar, i) {
    coinBar.percentage *= 100;
    if (coinBar.percentage >= 10) {
      coinBar.percentage = coinBar.percentage.toFixed(1);
    } else if (isNaN(coinBar.percentage)) {
      coinBar.percentage = (0.0).toFixed(1);
    } else {
      coinBar.percentage = coinBar.percentage.toFixed(1);
    }

    return (
      <Row key={i}>
        <Col xs={6} className="pr-0">
          <p className="p5 m-0">{coinBar.title}</p>
          <p className="p7 grey m-0">{coinBar.sub}</p>
        </Col>

        <Col xs={6} className="text-right">
          {coinBar.percentage === '0.0'
            ? <p className="dark-green p4 p-1 mb-0">{coinBar.percentage}%</p>
            : <>
              <div className="risk-coins p4">{coinBar.percentage}%</div>
              <div className="risk-coins-bar"
                   style={{ width: (45 + Number.parseInt(coinBar.percentage)).toString() + 'px' }}/>
            </>
          }
        </Col>
      </Row>
    );
  }

  coinsByCap(bundle) {
    const stableCoins = new Set(['USDC', 'USDT', 'DAI', 'UST', 'BSC-USD', 'aUST', 'BUSD']);

    const coinsMarketCap = bundle.coins.map(coin => {
      let coinSymbol = coin.metadata?.masterCoin ? coin.metadata.masterCoin : coin?.symbol
      var cat = bundle.performance?.coinToMarketCap[coinSymbol]?.category
      if (coin.symbol == "BTC" && !cat){
          cat = bundle.performance?.coinToMarketCap["BTCB"]?.category
      }

      cat = stableCoins?.has(coinSymbol) ? "Stable" : cat
      if (["LINK", "UNI"].includes(coinSymbol)) {
        cat = "MidCap"
      }
      return {
        weight: coin.weight / 100,
        category: cat
      };
    });

    return coinsMarketCap.reduce((prev, next) => {
      if (next.category in prev) {
        prev[next.category] += next.weight;
      } else {
        prev[next.category] = next.weight;
      }
      return prev;
    }, {});
  }

  render() {
    const { bundles, editMode } = this.props;

    const nearestBundle = bundles.sort((b1, b2) => b1.riskScore > b2.riskScore ? 1 : -1)
      .reduce((b1, b2) => {
        return Math.abs(this.value - b1.riskScore) < Math.abs(this.value - b2.riskScore) ? b1 : b2;
      });

    const byCap = this.coinsByCap(nearestBundle);
    const coinBars = [
      {
        title: 'Stable Coins',
        sub: '(e.g. USDC, USDT, UST)',
        percentage: byCap['Stable']
      },
      {
        title: 'Large cap coins',
        sub: '(e.g. Bitcoin, Ethereum)',
        percentage: byCap['LargeCap']
      },
      {
        title: 'Mid cap coins',
        sub: '(e.g. VeChain, Terra, Elrond)',
        percentage: byCap['MidCap']
      },
      {
        title: 'Small cap coins',
        sub: '(e.g. Harmony, 1INCH)',
        percentage: byCap['SmallCap']
      },
      {
        title: 'Micro cap coins',
        sub: '(e.g. Reserve Rights, COTI)',
        percentage: byCap['MicroCap']
      }
    ];

    const currentYear = new Date().getFullYear();
    const previousYear = currentYear - 1;
    const previous2Year = currentYear - 2;

    return (
      <>
        <Row className="risk-top">
          {editMode && (
            <Col xs={4} className="align-self-end" data-id="-" onClick={this.onChange}>
              <MinusIcon className="pointer"/>
              <p className="p6 mt-2 mb-0">Lower level</p>
              <div style={{ marginTop: '-3px' }}><DownArrowGreenIcon/> <span className="p6 green">Reward</span></div>
            </Col>
          )}
          <Col xs={4} className="text-center px-0 mx-auto">
            <ProgressRing
              radius={53}
              stroke={5}
              progress={this.value}
            />
          </Col>
          {editMode && (
            <Col xs={4} className="text-right align-self-end" data-id="+" onClick={this.onChange}>
              <PlusIcon className="pointer"/>
              <p className="p6 mt-2 mb-0">Higher level</p>
              <div style={{ marginTop: '-3px' }}><UpArrowGreenIcon/> <span className="p6 green">Reward</span></div>
            </Col>
          )}
        </Row>

        <Row>
          <Col className="text-center">
            <p className="p2">Investment strategy</p>
            <p className="p5 grey">{nearestBundle.description}</p>
          </Col>
        </Row>

        <Row>
          <Col>
            {nearestBundle.performance && (
              <Container className="px-0 risk-stats">
                {this.value === 0 && (
                  <Container className="px-0 risk-stats">
                    <Row>
                      <Col xs={7}><p className="p5">Expected APY:</p></Col>
                      <Col className="text-right"><p className="p4">19%</p></Col>
                    </Row>
                  </Container>
                )}

                {this.value !== 0 && (
                  <Row>
                    <Col xs={7}><p className="p5">{previous2Year} returns:</p></Col>
                    <Col className="text-right"><p
                      className="p4">{nearestBundle.performance?.twoYearsAgo?.toFixed(2)}%</p></Col>
                  </Row>
                )}

                <Row>
                  <Col xs={7}><p className="p5">{previousYear} returns:</p></Col>
                  <Col className="text-right">
                    <p className="p4">
                      {this.value !== 0 ? nearestBundle.performance?.oneYearAgo?.toFixed(2) : 19.52}%
                    </p>
                  </Col>
                </Row>

                <Row>
                  <Col xs={7}><p className="p5">YTD returns:</p></Col>
                  <Col className="text-right"><p className="p4">{nearestBundle.performance?.ytdPerf?.toFixed(2)}%</p>
                  </Col>
                </Row>

                <Row>
                  <Col><p className="p5">Best day:</p></Col>
                  <Col className="text-right"><p
                    className="p4">{nearestBundle.performance?.bestDate?.performance.toFixed(2)}%</p></Col>
                </Row>

                <Row>
                  <Col><p className="p5">Worst day:</p></Col>
                  <Col className="text-right"><p
                    className="p4">{nearestBundle.performance?.worstDate?.performance.toFixed(2)}%</p></Col>
                </Row>

                <Row>
                  <Col className="pr-0"><p className="p5">Minimum investment:</p></Col>
                  <Col className="text-right"><p className="p4">350 USD (equivalent)</p></Col>
                </Row>
              </Container>
            )}

            <Container className="risk-coins-container">
              {coinBars.map((coinBar, i) => {
                return this.renderCoinBar(coinBar, i);
              })}
            </Container>
          </Col>
        </Row>
      </>
    );
  };
}

export default Risk;
