import 'react-date-range/dist/styles.css'; // main css file
import 'react-date-range/dist/theme/default.css';

import { Box, createStyles } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Button, Typography } from 'elements'; // theme css file
import React, { useState } from 'react';
import { DateRange, Range } from 'react-date-range';

interface Props {
  filteredDate?: { startDate: Date, endDate: Date };
  // eslint-disable-next-line no-unused-vars
  setFilteredDate: (a: { startDate: Date, endDate: Date } | undefined) => void;
  // eslint-disable-next-line no-unused-vars
  setCalendarOpen: (value: boolean) => void;
  clear: () => void;
}

const useStyles = makeStyles((theme) => createStyles({
  overlay: {
    width: '100%',
    height: '100%',
    position: 'fixed',
    top: 0,
    left: 0,
    opacity: '0',
    zIndex: 1,
  },
  root: {
    display: 'flex',
    flexDirection: 'column',
    gap: 8,
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 2,
    '& div': { backgroundColor: 'white' },
    '& .rdrDateDisplay': { color: `${theme.palette.green[400]} !important` },
    '& .rdrDateDisplayItem': {
      boxShadow: 'none',
      borderRadius: 8,
    },
    '& .rdrNextPrevButton': {
      borderRadius: 12,
    },
    '& .rdrMonthAndYearWrapper': {
      paddingTop: 0,
    },
    '& span:after': {
      backgroundColor: `${theme.palette.green[600]} !important`,
    },
    '& .rdrDayToday:not(.rdrDayPassive) .rdrInRange ~ .rdrDayNumber span:after, .rdrDayToday:not(.rdrDayPassive) .rdrStartEdge ~ .rdrDayNumber span:after, .rdrDayToday:not(.rdrDayPassive) .rdrEndEdge ~ .rdrDayNumber span:after, .rdrDayToday:not(.rdrDayPassive) .rdrSelected ~ .rdrDayNumber span:after': {
      backgroundColor: 'white !important',
    },
  },
  buttonWrapper: { display: 'flex', width: '100%', gap: 8 },
}));

export const Calendar = ({ filteredDate, setFilteredDate, setCalendarOpen, clear }: Props) => {
  const classes = useStyles();

  const CALENDAR_KEY = 'selection';
  const [calendarState, setCalendarState] = useState<Range[]>(
    [{ key: CALENDAR_KEY, startDate: filteredDate ? filteredDate.startDate : new Date(), endDate: filteredDate ? filteredDate.endDate : new Date() }],
  );

  const clearData = () => { setCalendarState([{ key: CALENDAR_KEY, startDate: new Date(), endDate: new Date() }]); clear(); };
  const handleApplyButton = () => {
    setFilteredDate({ startDate: calendarState[0].startDate!, endDate: calendarState[0].endDate! });
    setCalendarOpen(false);
  };

  return (
    <>
      <Box className={classes.root}>
        <DateRange
          editableDateInputs
          ranges={calendarState}
          onChange={(items) => {
            setCalendarState(Object.entries(items).map((a) => a[1]));
          }}
          rangeColors={['#02BD9F']}
          color="#02BD9F"
        />
        <Box className={classes.buttonWrapper}>
          <Box className={classes.buttonWrapper}>
            <Button onClick={clearData} variant="secondary" fullWidth>
              <Typography variant="h4">Clear</Typography>
            </Button>
          </Box>
          <Box className={classes.buttonWrapper}>
            <Button onClick={handleApplyButton} variant="primary" fullWidth>
              <Typography variant="h4" palette="grey" paletteColor={100}>Apply</Typography>
            </Button>
          </Box>
        </Box>
      </Box>
      <Box className={classes.overlay} onClick={() => setCalendarOpen(false)} />
    </>
  );
};
