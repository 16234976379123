import React from "react";
import Chart from "react-apexcharts";
import * as moment from 'moment-timezone';
import { dateInUtcToUserDate, stableCoinNameMap } from "../common";

moment.tz.setDefault('Etc/UTC');

const mapLabel = coin => {
  // TODO hacky. move this to dashboard.js, 49708183 = aUST
  if (coin.coinId === 49708183) {
    return 'Stablecoin (UST: +19% APY)';
  }

  return coin.name in stableCoinNameMap ? stableCoinNameMap[coin.name] : coin.name;
};

export const LineChart = class LineChart extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      depositOption: true,
      withdrawalOption: true
    }
  }

  static getAnnotations(events, points, state){
    const tss = points.map((point) => point.openTime)
    const minTs = Math.min(...tss)
    const maxTs = Math.max(...tss)

    var filteredEvents = events
    if(!state.withdrawalOption){
      filteredEvents = filteredEvents.filter(el => el.price >= 0)
    }

    if(!state.depositOption){
      filteredEvents = filteredEvents.filter(el => el.price < 0)
    }

    return filteredEvents.map((event) => {
      const creation = dateInUtcToUserDate(moment(event.perf.openTime).valueOf())

      if(event.perf.openTime > minTs && event.perf.openTime < maxTs){
        var strokeColor = "#10AC84"
        if(event.price < 0){
          strokeColor = "#E86161"
        }
        return {
          x: creation,
          y: event.perf.totalAssetValue,
          marker: {
            size: 4,
            radius: 2,
            strokeColor,
          }
        }
      }
    }).filter((el) => el !== undefined)
  }

  static getDerivedStateFromProps(props, prevState) {
    let points = props.points.filter(element => element.totalAssetValue)
    const events = LineChart.getAnnotations(props.annotations || [], points, prevState)
    var dataPoints = []
    if (!points.every(element => !element.totalAssetValue)) {
      dataPoints = points.map(item => {
        if (!!item.totalAssetValue) return Number.parseFloat(item.totalAssetValue.toFixed(2));
        else return null;
      })
    }

    return {
      series: [{
        data: dataPoints
      }],
      options: {
        annotations:{
          points: events
        },
        noData: {
          text: "Data unavailable at the moment",
        },
        chart: {
          fontFamily: 'Poppins',
          type: 'line',
          id: 'areachart-2',
          animations: {
            enabled: false
          },
          toolbar: {
            show: false
          },
          zoom: {
            enabled: false
          }
        },
        colors: ["#10AC84"],
        dataLabels: {
          enabled: false
        },
        stroke: {
          width: 3,
          curve: 'smooth'
        },
        grid: {
          padding: {
            right: 30,
            left: 20
          }
        },
        labels: points.map(item => dateInUtcToUserDate(item.openTime)),
        xaxis: {
          type: 'datetime'
        },
        yaxis: {
          showAlways: false,
          labels: {
            formatter: function (value) {
              return "$" + value.toFixed(2);
            }
          }
        },
        tooltip: {
          x: {
            format: "dd/MM/yyyy HH:mm"
          },
          y: {
            formatter: undefined,
            title: {
              formatter: (seriesName) => "Total Asset",
            },
          },
        },
        responsive: [{
          breakpoint: 768,
          options: {
            grid: {
              padding: {
                right: 10,
                left: 10
              }
            },
            stroke: {
              width: 2
            },
            yaxis: {
              show: false,
              labels: {
                formatter: function (value) {
                  return value.toFixed(0) + "$";
                }
              },
            },
          }
        }]
      }
    };
  }

  render() {
    return (
      <div id="chart">
        <div className="text-center" style={{marginBottom:"-20px", zIndex:"8", position:"relative"}}>
        <div className="pointer d-inline-block mr-3" onClick={() => this.setState({depositOption: !this.state.depositOption})}>
          <span style={{border: "2px solid #10ac84", backgroundColor: "#fff", position:"relative", display: "inline-block", height: "12px", width: "12px", top: "1px", borderRadius: "12px"}}></span>
          <span className={("p6 pl-1") + (!this.state.depositOption ? " grey" : "")}>Deposit</span>
        </div>
        <div className="pointer d-inline-block" onClick={() => this.setState({withdrawalOption: !this.state.withdrawalOption})}>
          <span style={{border: "2px solid #E86161", backgroundColor: "#fff", position:"relative", display: "inline-block", height: "12px", width: "12px", top: "1px", borderRadius: "12px"}}></span>
          <span className={("p6 pl-1") + (!this.state.withdrawalOption ? " grey" : "")}>Withdrawal</span>
        </div>
        </div>
        <Chart
          height={350}
          options={this.state.options}
          series={this.state.series}
        />
      </div>
    );
  }
}

export const PieChart = class PieChart extends React.Component {
  constructor(props) {
    super(props);

    this.state = {

      series: this.props.coins.map(coin => coin.weight),
      options: {
        chart: {
          type: 'pie',
          animations: {
            enabled: false
          }
        },
        legend: {
          show: true,
          position: 'bottom'
        },
        colors: this.props.coins.map(coin => coin.color),
        // colors: ['#00876c','#b6cf8b','#f5ba75','#d43d51','#2f4b7c','#665191','#a05195','#d45087','#f95d6a','#ff7c43','#ffa600'],
        tooltip: {
          y: {
            formatter: function (value) {
              return value + "%";
            }
          }
        },
        labels: this.props.coins.map(mapLabel),
        responsive: [{
          breakpoint: 600,
          options: {
            chart: {
              height: "358px"
            },
            legend: {
              position: 'bottom'
            }
          }
        }]
      },
    };
  }

  render() {
    return (
        <Chart
          options={this.state.options}
          series={this.state.series}
          type="pie"
          width="100%"
          height={this.props.height}
        />
    );
  }
}


export const DonutChart = class DonutChart extends React.Component {
  constructor(props) {
    super(props);

    this.setCoins = this.setCoins.bind(this)
  }

  setCoins(){
    var allCoins = this.props.coins.map(coin => {
      return {
        ...coin,
        totalPrice: coin.price + (coin.lockedPrice ? coin.lockedPrice : 0),
        totalQuantity: coin.quantity + (coin.lockedQuantity ? coin.lockedQuantity : 0)
      }
    })
    var coins = allCoins.filter(coin => !coin.other)
    var others = allCoins.filter(coin => coin.other)
    if (others.length > 0) {
      var other = {
        totalPrice: others.reduce((a, b) => a + b.totalPrice, 0),
        totalQuantity: "~" + others.reduce((a, b) => a + b.totalPrice, 0).toFixed(2) + " USDT",
        color: others[0].color,
        name: "Other"
      }

      coins.push(other);
    }
    let coinMap = {}
    coins.map(coin => {
      if(!coinMap[coin.name]) coinMap[coin.name] = {...coin}
      else {
        coinMap[coin.name].lockedPrice += coin.lockedPrice
        coinMap[coin.name].lockedQuantity += coin.lockedQuantity
        coinMap[coin.name].price += coin.price
        coinMap[coin.name].quantity += coin.quantity
        coinMap[coin.name].totalPrice += coin.totalPrice
        coinMap[coin.name].totalQuantity += coin.totalQuantity
      }
    })
    coins = Object.keys(coinMap).map(key => coinMap[key])
    this.coins = coins;
  }

  render() {
    this.setCoins();

    this.state = {
      series: this.coins.map(coin => Number.parseFloat(coin.totalPrice)),
      options: {
        chart: {
          animations: {
            enabled: false
          }
        },
        legend: {
          show: true,
          position: 'bottom'
        },
        colors: this.coins.map(coin => coin.color),
        // colors: ["#99B5AF", "#3E958F", "#2385AC", "#0065EA", "#0054C2", "#F4D249", "#C04C64", "#D8002B", "#8E418E", "#7B6677"],
        // colors: ['#00876c','#b6cf8b','#f5ba75','#d43d51','#2f4b7c','#665191','#a05195','#d45087','#f95d6a','#ff7c43','#ffa600'],
        noData: {
          text: "Data unavailable at the moment",
        },
        extra: this.coins,
        labels: this.coins.map(mapLabel),
        responsive: [{
          breakpoint: 600,
          options: {
            // chart: {
            //   width: 200
            // },
            legend: {
              show: true,
              position: 'bottom'
            }
          }
        }],
        //                  dataLabels: {
        //                      formatter: function (val, opts) {
        //                          return opts.w.config.extra[opts.seriesIndex].quantity
        //                      },
        //                    },
        tooltip: {
          y: {
            formatter: function (value, opts) {
              return opts.config.extra[opts.seriesIndex].totalQuantity;
            }
          }
        },
        plotOptions: {
          pie: {
            donut: {
              labels: {
                show: false
              }
            }
          }
        },
      }
    }

    return (
        <Chart
          options={this.state.options}
          series={this.state.series}
          type="donut"
          width="100%"
          height={this.props.height ? this.props.height : "auto"}
        />
    );
  }
}

export const DonutChart1 = class DonutChart1 extends React.Component {

  render() {
    this.state = {
      series: this.props.series,
      options: {
        chart: {
          animations: {
            enabled: false
          }
        },
        colors: ["#1D2129", "#327261", "#10AC84", "#38DFB4"],
        noData: {
          text: "Data unavailable at the moment",
        },
        labels: ["Stablecoins", "Large cap", "Small cap", "Mid cap"],
        legend: {
          position: 'bottom'
        },

        plotOptions: {
          pie: {
            donut: {
              labels: {
                show: true,
                total: {
                  showAlways: true,
                  show: true,
                  label: "Balance (USDT)",
                }
              }
            }
          }
        },
      }
    }

    return (
      <div>
        <Chart
          options={this.state.options}
          series={this.state.series}
          type="donut"
          width="100%"
          height="350px"
        />
      </div>
    );
  }
}
