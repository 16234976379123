import axios, { AxiosResponse } from 'axios';

import Headers from './headers'; 
import { BundleV2 } from './types/bundle';

const API_URL = '/api/bundle/';

const getUserVisibleBundles = (): Promise<AxiosResponse<BundleV2[]>> => {
  return axios.get(`${API_URL}getUserVisibleBundles`, { headers: { ...Headers.def(), ...Headers.auth() } });
};

const getUserEditableBundles = (): Promise<AxiosResponse<BundleV2[]>> => {
  return axios.get(`${API_URL}getUserEditableBundles`, { headers: { ...Headers.def(), ...Headers.auth() } });
};

const getUserOwnBundle = (): Promise<AxiosResponse<BundleV2>> => {
  return axios.get(`${API_URL}getUserOwnBundle`, { headers: { ...Headers.def(), ...Headers.auth() } });
};

const createOrEditUserBundle = (
  bundleName: string, bundleDescription: string, bundle: number[][]
): Promise<AxiosResponse<void>> => {
  return axios.post(
    `${API_URL}createOrEditUserBundle`,
    { bundleName, bundleDescription, bundle },
    { headers: { ...Headers.def(), ...Headers.auth() } },
  );
};

const addUserBundle = (bundleId: number): Promise<AxiosResponse<void>> => {
  return axios.post(
    `${API_URL}user/add-bundle/`,
    { bundleId },
    { headers: { ...Headers.def(), ...Headers.auth() } },
  );
};

const getUserCurrentBundle = (): Promise<AxiosResponse<BundleV2>> => {
  return axios.get(`${API_URL}getUserCurrentBundle`, { headers: { ...Headers.def(), ...Headers.auth() } });
};

export default {
  getUserVisibleBundles,
  getUserEditableBundles,
  getUserOwnBundle,
  createOrEditUserBundle,
  addUserBundle,
  getUserCurrentBundle,
};
