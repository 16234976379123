import { CoinsWithMasterCoins, CoinWithBalance, MasterCoin } from '../../services/types/coin';
import { Metadatum, Point } from '../../services/types/wallet';
import { TokenItemProps } from './Components/TokenItem';
import { chainIdMapper, supportedChainIds } from './mappers';

export const calculateWithdrawableBalance = (
  masterCoinsFromName: Record<string, MasterCoin>,
  allCoins: CoinsWithMasterCoins,
  activeWalletBalance?: CoinWithBalance[],
  maxPerf?: { point: Point, metadata: Metadatum[] },
) => {
  if (!Object.keys(masterCoinsFromName).length) return { available: [], locked: [] };

  let tokenItemProps: TokenItemProps[];
  if (activeWalletBalance) {
    tokenItemProps = activeWalletBalance.map((c) => ({
      id: c.coinId,
      icon: c.masterCoin?.icon,
      name: c.name,
      symbol: c.symbol,
      networkIds: [c.chainId ?? ''],
      amount: Number((Math.floor(c.balance * 100000000) / 100000000).toFixed(8)),
      value: c.usdtValue,
      locked: (c.metadata?.lending ?? '').length > 0,
      masterCoinId: c.masterCoin?.ticker,
    }));
  } else {
    tokenItemProps = (maxPerf?.point?.coins || [])
      .map((c): TokenItemProps => {
        const metadata = maxPerf?.metadata.find((m) => m.coinId === c.coinId);
        const coin = allCoins[metadata?.coinId?.toString() ?? ''];
        return {
          id: c.coinId,
          icon: coin?.masterCoin?.icon ?? '',
          name: c.name,
          symbol: coin?.symbol,
          networkIds: [metadata?.chainId ?? ''],
          amount: c.quantity,
          value: c.price,
          locked: (metadata?.metadata?.lending ?? '').length > 0,
          masterCoinId: coin?.masterCoin?.ticker,
        };
      });
  }

  const coins = tokenItemProps
    .filter((c) => (c.value && c.value > 0) 
    && Object.keys(chainIdMapper).findIndex((key) => key === c.networkIds[0]) >= 0
    && supportedChainIds.includes(c.networkIds[0]))
    .sort((a, b) => (a.value && b.value ? b.value - a.value : 0));
  const locked = (coins ?? []).filter((c) => c.locked);
  const available = (coins ?? []).filter((c) => !c.locked);

  return { available, locked };
};
