import { Box, createStyles, makeStyles, useMediaQuery, useTheme } from '@material-ui/core';
import { Button, Card, Typography } from 'elements';
import React, { useMemo, useState } from 'react';
import SVG from 'react-inlinesvg';
import { useHistory } from 'react-router-dom';
import { DepositRoute } from 'routes';
import { WalletLevel } from 'services/types/wallet-enum';
import { useStore as useWalletStore } from 'store/zustand/Wallet';

import { cardBackground } from './card-background';
import { LevelBadge } from './LevelBadge';
import { ProgressBar, resolveWalletLevelCardProgressBarProps } from './ProgressBar';
import { WalletLevelModal } from './WalletLevelModal';

interface Props {
  portfolioValue: number;
}

type useStylesProp = { level?: WalletLevel }

const useStyles = makeStyles((theme) => createStyles({
  root: {
    background: ({ level }: useStylesProp) => ((level && cardBackground[level]) || theme.palette.grey[200]),
    padding: 16,
    minHeight: 160,
    height: '100%',
  },
  container: {
    display: 'flex',
    height: '100%',
    width: '100%',
    flexDirection: 'column',
    justifyContent: 'space-between',
    color: 'white',
    gap: 12,
  },
  rowContainer: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  headerText: {
    fontSize: 14,
    fontWeight: 500,
    lineHeight: '20px',
    [theme.breakpoints.up('lg')]: {
      fontSize: 16,
      lineHeight: '24px',
    },
  },
  buttonsContainer: {
    display: 'grid',
    gridTemplateColumns: ({ level }: useStylesProp) => (level === WalletLevel.Private ? 'repeat(1, 1fr)' : 'repeat(2, 1fr)'),
    justifyContent: 'space-between',
    gap: 12,
    marginTop: 12,
    [theme.breakpoints.down('sm')]: {
      marginTop: 4,
    },
  },
  buttonTypography: {
    color: ({ level }: useStylesProp) => (
      level === WalletLevel.Green ? theme.palette.green[600]
        : level === WalletLevel.Bronze ? '#715C54'
          : level === WalletLevel.Silver ? theme.palette.grey[600]
            : level === WalletLevel.Gold ? '#B68917'
              : theme.palette.grey[700]
    ),
  },
  walletLevelButton: { minWidth: 140 },
  upgradeButton: { display: 'flex', gap: 6, alignItems: 'center' },
  lockIcon: {
    '& path:last-child': {
      fill: ({ level }: useStylesProp) => (level === WalletLevel.Green ? theme.palette.green[600]
        : level === WalletLevel.Bronze ? '#715C54'
          : level === WalletLevel.Silver ? theme.palette.grey[600]
            : level === WalletLevel.Gold ? '#B68917'
              : theme.palette.grey[700]
      ),
    },
    '& path:first-child': {
      stroke: ({ level }: useStylesProp) => (level === WalletLevel.Green ? theme.palette.green[600]
        : level === WalletLevel.Bronze ? '#715C54'
          : level === WalletLevel.Silver ? theme.palette.grey[600]
            : level === WalletLevel.Gold ? '#B68917'
              : theme.palette.grey[700]
      ),
    },
  },
}));

export const WalletLevelCard = ({ portfolioValue }: Props) => {
  const [wallet, readyWallet] = useWalletStore((state) => [state.wallet, state.readyWallet]);
  const classes = useStyles({ level: readyWallet ? wallet?.walletLevel || WalletLevel.Green : undefined });

  const [showModal, setShowModal] = useState<boolean>(false);
  const history = useHistory();

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const isXm = useMediaQuery(theme.breakpoints.up('xm'));
  const isMd = useMediaQuery(theme.breakpoints.up('md'));

  const progressBar = useMemo(() => (wallet && (
    <ProgressBar {...resolveWalletLevelCardProgressBarProps(wallet.walletLevel, true,
      portfolioValue, [3000, 10000, 25000], 'dashboard-card', 0)}
    />
  )), [wallet, portfolioValue]);

  return (
    <Card isLoading={wallet === undefined} borderRadius={8} className={classes.root}>
      <Box className={classes.container}>
        <Box className={classes.rowContainer}>
          <Typography variant={isMobile ? 'h5' : 'h6'} className={classes.headerText}>Member level</Typography>
          <LevelBadge level={readyWallet ? wallet?.walletLevel || WalletLevel.Green : undefined} isActive size={isMobile ? 'md' : 'sm'} />
        </Box>
        {progressBar}
        <Box className={classes.buttonsContainer}>
          {wallet?.exchangeId !== 1 && wallet?.walletLevel !== WalletLevel.Private
            && (
              <Button
                size="md"
                variant="secondary"
                fullWidth
                onClick={() => history.push(DepositRoute)}
              >
                <Box className={classes.upgradeButton}>
                  {(isXm || !isMd) && (
                    <SVG src="/icons/earning/ic_lock.svg" className={classes.lockIcon} />
                  )}
                  <Typography variant="h5" palette="green" paletteColor={600} className={classes.buttonTypography}>
                    Upgrade
                  </Typography>
                </Box>
              </Button>
            )}
          <Button
            size="md"
            fullWidth
            variant="translucent"
            className={classes.walletLevelButton}
            onClick={() => setShowModal(true)}
          >
            <Typography variant="h5">Level details</Typography>
          </Button>
        </Box>
      </Box>
      <WalletLevelModal setShowModal={setShowModal} showModal={showModal} portfolioValue={portfolioValue} />
    </Card>
  );
};
