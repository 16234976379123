import {Box, Button, Divider, Typography, withStyles} from '@material-ui/core';
import * as React from 'react';

interface ChooseExchangeCoinbagCardProps {
  handleSubmit: () => void;
}

const ContainerBox = withStyles({
  root: {
    overflow: 'hidden',
    position: 'relative',
    display: 'flex',

    flexDirection: 'column',
    justifyContent: 'flex-start',
    flexGrow: 1,

    height: '100%',
    width: '100%',

    background: '#FFFFFF',
    boxShadow: '3px 4px 20px rgba(0, 0, 0, 0.15)',
    border: '2px solid #10AC84',
    borderRadius: 10,
  },
})(Box);

const RecommendedBox = withStyles({
  root: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',

    padding: '8px 12px',
    background: '#10AC84',
  },
})(Box);

const RecommendedTitle = withStyles({
  root: {
    color: '#FFFFFF',
    fontSize: 14,
    fontWeight: 600,
    lineHeight: '24px',
  },
})(Typography);

const FreeCryptoBox = withStyles({
  root: {
    padding: '2px 10px',

    color: '#1A9071',
    fontSize: 12,
    fontWeight: 400,
    lineHeight: '24px',

    background: '#E0F4EF',
    borderRadius: 36,
  },
})(Box);

const WalletBox = withStyles({
  root: {
    display: 'flex',
    alignItems: 'baseline',

    columnGap: 12,
    padding: '12px 12px',
  },
})(Box);

const WalletTitle = withStyles({
  root: {
    marginTop: 8,

    color: '#2A2A2E',
    fontSize: 14,
    fontWeight: 500,
    lineHeight: '21px',
  },
})(Typography);

const WalletSubtitle = withStyles({
  root: {
    marginTop: 4,

    color: '#327261',
    fontSize: 12,
    fontWeight: 400,
    lineHeight: '20px',
  },
})(Typography);

const BenefitBox = withStyles({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'start',

    rowGap: 8,
    margin: '12px 16px 0',
  },
})(Box);

const GreenSemiboldText = withStyles({
  root: {
    display: 'inline',
    color: '#1A9071',
    fontSize: 16,
    fontWeight: 600,
    lineHeight: '16px',
  },
})(Typography);

const BlackSemiboldText = withStyles({
  root: {
    color: '#000000',
    fontSize: 12,
    fontWeight: 600,
    lineHeight: '20px',
  },
})(Typography);

const BlackRegularText = withStyles({
  root: {
    color: '#000000',
    fontSize: 12,
    fontWeight: 400,
    lineHeight: '20px',
  },
})(Typography);

const FilledButton = withStyles(() => ({
  root: {
    boxSizing: 'border-box',
    width: '100%',
    height: '40px',

    color: '#FFFFFF',
    fontSize: '14px',
    lineHeight: '16px',

    background: '#10AC84',
    border: '3px solid #10AC84',
    borderRadius: '36px',
    boxShadow: 'none',

    '&:hover': {
      backgroundColor: '#1A9071',
      border: '3px solid #1A9071',
      boxShadow: 'none',
    },
  },
}))(Button);

const ListItem = ({children}: any) => (
  <Box sx={{display: 'flex', alignItems: 'baseline'}}>
    <Box sx={{width: 16, height: 16, marginRight: 4}}>
      <img alt="Bullet icon" src="/icons/ic_bullet_fill_green.svg" height={16} width={16}/>
    </Box>
    {children}
  </Box>
);

const ChooseExchangeCoinbagCard: React.FC<ChooseExchangeCoinbagCardProps> = ({handleSubmit}: ChooseExchangeCoinbagCardProps) => (
  <ContainerBox>
    <RecommendedBox>
      <RecommendedTitle>Recommended</RecommendedTitle>
      <FreeCryptoBox>earn interest</FreeCryptoBox>
    </RecommendedBox>

    <WalletBox>
      <Box sx={{width: 18, height: 18}}>
        <img alt="Coinbag logo" src="/images/logo_coinbag.svg" height={18} width={18}/>
      </Box>

      <WalletTitle>Fund your Coinbag wallet</WalletTitle>
      <WalletSubtitle>crypto+interest</WalletSubtitle>
    </WalletBox>

    <Divider/>

    <BenefitBox>
      <Box sx={{display: 'flex', alignItems: 'baseline'}}>
        <Box sx={{width: 20, height: 20, marginRight: 4}}>
          <img alt="Benefit icon" src="/icons/ic_favorite_fill_yellow.svg" height={20} width={20}/>
        </Box>
        <BlackSemiboldText>Benefits:</BlackSemiboldText>
      </Box>

      <ListItem>
        <BlackRegularText>All portfolio management features</BlackRegularText>
      </ListItem>

      <ListItem>
        <BlackRegularText>Deposit/Withdraw local currencies</BlackRegularText>
      </ListItem>

      <ListItem>
        <BlackRegularText>Earn interest on stablecoins</BlackRegularText>
      </ListItem>

      <ListItem>
        <BlackRegularText>Up to <GreenSemiboldText>50%</GreenSemiboldText> APY through staking
          services</BlackRegularText>
      </ListItem>

      <ListItem>
        <BlackRegularText>Even more exciting features to come!</BlackRegularText>
      </ListItem>
    </BenefitBox>

    <BenefitBox>
      <Box sx={{display: 'flex', alignItems: 'baseline'}}>
        <Box sx={{width: 20, height: 20, marginRight: 4}}>
          <img alt="Benefit icon" src="/icons/ic_subtract_fill_yellow.svg" height={20} width={20}/>
        </Box>
        <BlackSemiboldText>Drawbacks:</BlackSemiboldText>
      </Box>

      <ListItem>
        <BlackRegularText>Higher minimum investment amount</BlackRegularText>
      </ListItem>
    </BenefitBox>

    <BenefitBox>
      <Box sx={{display: 'flex', alignItems: 'baseline'}}>
        <Box sx={{width: 20, height: 20, marginRight: 4}}>
          <img alt="Benefit icon" src="/icons/ic_paper_fill_yellow.svg" height={20} width={20}/>
        </Box>
        <BlackSemiboldText>Terms:</BlackSemiboldText>
      </Box>

      <ListItem>
        <BlackRegularText>Minimum investment: <GreenSemiboldText>350
          USD</GreenSemiboldText> equivalent</BlackRegularText>
      </ListItem>

      <ListItem>
        <BlackRegularText>Fees: <GreenSemiboldText>25%</GreenSemiboldText> of interest earned</BlackRegularText>
      </ListItem>

      <ListItem>
        <BlackRegularText>Deposit/Withdrawal at any time, no penalties</BlackRegularText>
      </ListItem>

      <ListItem>
        <BlackRegularText>Account protected with military grade encryption</BlackRegularText>
      </ListItem>
    </BenefitBox>

    <Box sx={{margin: 16}}>
      <FilledButton onClick={handleSubmit}>Create a new Wallet</FilledButton>
    </Box>
  </ContainerBox>
);

ChooseExchangeCoinbagCard.displayName = 'ChooseExchangeCoinbagCard';
export {ChooseExchangeCoinbagCard};
