import axios from 'axios';

import Headers from './headers';
import { Protocol } from './types/protocol';
import { CoinConfig, EarningConfig } from './types/wallet';

const API_URL = '/api/protocol/';

export const getProtocols = (): Promise<Protocol[]> => axios.get(`${API_URL}getProtocols`,
  { headers: { ...Headers.def(), ...Headers.auth() } })
  .then((response) => response.data);

export const getCurrentProtocol = (): Promise<EarningConfig> => axios.get(`${API_URL}getCurrentProtocol`,
  { headers: { ...Headers.def(), ...Headers.auth() } })
  .then((response) => response.data);

export const getHighestInterestRate = (): Promise<number> => axios.get(`${API_URL}getHighestInterestRate`,
  { headers: { ...Headers.def(), ...Headers.auth() } })
  .then((response) => response.data);

export const coinConfigToMap = (configs: CoinConfig[]) => {
  const result = configs.reduce((p, c) => { p[c.masterCoinId] = c.protocolId; return p; }, {} as Record<number, number>);
  return result;
};

export const mapToCoinConfig = (map: Record<number, number>): CoinConfig[] => {
  const coinIds = Object.keys(map);
  return coinIds.map((coinId) => ({ masterCoinId: +coinId, protocolId: map[+coinId] }));
};
