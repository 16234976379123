import { WorkItem } from 'services/types/workItem';
import workItemService from 'services/workItem-service';
import { create } from 'zustand';

interface WorkItemStore {
  workItems?: WorkItem[];
  // eslint-disable-next-line no-unused-vars
  fetchWorkItems: (forceReload: boolean) => Promise<WorkItem[]>;
}

export const useStore = create<WorkItemStore>((set, get) => ({
  workItems: undefined,
  fetchWorkItems: async (forceReload: boolean) => {
    if (get().workItems && !forceReload) return get().workItems!;
    const items = await workItemService.getWorkItemsForCurrentUser();
    set({ workItems: items });
    return items;
  },
}));
