import { Box, createStyles, makeStyles } from '@material-ui/core';
import { Typography } from 'elements';
import React from 'react';
import SVG from 'react-inlinesvg';

const useStyles = makeStyles((theme) => createStyles({
  basicBundle: {
    display: 'flex',
    width: '100%',
    gap: 4,
    marginBottom: 4,
    justifyContent: 'center',
    alignItems: 'center',
  },
  divider: {
    height: '12px',
    borderColor: theme.palette.grey['200'],
    alignSelf: 'center',
  },
}));

export const SetBasicWalletDetail = () => {
  const classes = useStyles();

  return (
    <>
      <Box display="flex" alignItems="center" gridRowGap="16px" width="100%">
        <Box className={classes.basicBundle}>
          <Box display="flex" width={36} height={36}>
            <SVG src="icons/custom_portfolio/ic_non_rebalancing-wallets.svg" width={36} height={36} />
          </Box>
          <Typography
            variant="h4"
            palette="green"
            paletteColor={600}
            align="center"
          >
            Basic wallet
          </Typography>
        </Box>
      </Box>
    </>
  );
};
