// @flow
import { Box, createStyles } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Button, Typography } from 'elements';
import LightModal from 'elements/LightModal';
import { Scrollable } from 'elements/Scrollable';
import React from 'react';
import { useHistory } from 'react-router-dom';
import { DepositRoute } from 'routes';
import { WalletLevel, WalletLevelOrder } from 'services/types/wallet-enum';
import { useStore as useWalletStore } from 'store/zustand/Wallet';

import { WalletLevelDetailsCard } from './WalletLevelDetailsCard';

interface Props {
  // eslint-disable-next-line no-unused-vars
  setShowModal: (bool: boolean) => void;
  showModal: boolean;
  portfolioValue: number;
}

const useStyles = makeStyles((theme) => createStyles({
  root: {
    gap: 0,
    alignItems: 'center',
  },
  headerContainer: {
    display: 'flex',
    color: theme.palette.grey[800],
    padding: '24px 0px 12px',
  },
  cardContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: 20,
    flexGrow: 1,

    width: '100%',
    padding: '12px 40px',
    [theme.breakpoints.down('sm')]: {
      padding: '12px 16px',
    },
  },
  footerContainer: {
    display: 'flex',
    justifyContent: 'center',
    flexShrink: 0,

    width: '100%',
    padding: '24px 40px',
    borderTop: '1px solid',
    borderTopColor: theme.palette.grey[200],
    [theme.breakpoints.down('sm')]: {
      padding: '20px 16px',
    },
  },
  buttonWrapper: { width: '100%' },
}));

export const WalletLevelModal = ({ portfolioValue, showModal, setShowModal }: Props) => {
  const classes = useStyles();
  const [wallet] = useWalletStore((state) => [state.wallet]);

  const history = useHistory();

  return (
    <LightModal
      className={classes.root}
      open={showModal}
      setOpen={setShowModal}
      maxWidth={564}
      onClose={() => setShowModal(false)}
      noPadding
      showCloseButton
    >
      <Box className={classes.headerContainer}>
        <Typography variant="h3">Member Level</Typography>
      </Box>
      <Scrollable className={classes.cardContainer}>
        {wallet && Object.entries(WalletLevelOrder)
          .sort((a, b) => (a[1] - b[1]))
          .map(([level]) => (
            <WalletLevelDetailsCard
              level={level as WalletLevel}
              isActive={level as WalletLevel === wallet.walletLevel}
              portfolioValue={portfolioValue}
              key={level}
            />
          ))}
      </Scrollable>
      <Box className={classes.footerContainer}>
        <Box className={classes.buttonWrapper}>
          <Button fullWidth size="xmd" onClick={() => history.push(DepositRoute)}>
            <Typography variant="h5">Upgrade</Typography>
          </Button>
        </Box>
      </Box>
    </LightModal>
  );
};
