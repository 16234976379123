import { Box, makeStyles, Typography } from '@material-ui/core';
import { CarouselContext } from 'pure-react-carousel';
import React, { memo, ReactNode, useContext, useEffect, useState } from 'react';

type FeatureSlideProps = {
  index: number;
  title: ReactNode;
  icon: string;
};

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%',

    '& > em': {
      color: theme.palette.blue[600],
    },
  },

  icon: {
    width: 40,
    height: 40,
    userDrag: 'none',
    userSelect: 'none',
    objectFit: 'contain',
  },

  title: {
    color: theme.palette.grey[700],
    fontSize: '12px !important',
    fontWeight: 500,
    textAlign: 'center',

    [theme.breakpoints.up('md')]: {
      fontSize: '14px !important',
    },
  },
}));

const FeatureSlide= memo(({ index, title, icon }: FeatureSlideProps) => {
  const context = useContext(CarouselContext);
  const [slide, setSlide] = useState(context.state.currentSlide);
  useEffect(() => {
    const onChange = () => setSlide(context.state.currentSlide);
    context.subscribe(onChange);
    return () => context.unsubscribe(onChange);
  }, [context]);

  const classes = useStyles();
  return (
    <>
      <Box className={classes.root}>
        <img className={classes.icon} alt="" src={icon} />
        <Box mt="12px">
          <Typography className={classes.title}>{title}</Typography>
        </Box>
      </Box>

      {index >= slide && index <= slide + 1 && <Box height="40%" borderRight="1px solid #C9DBFF" />}
    </>
  );
});

FeatureSlide.displayName = 'FeatureSlide';
export { FeatureSlide };
