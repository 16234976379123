import { Checkbox, Typography } from 'elements';
import { LightFrenchFries } from 'elements/LightFrenchFries';
import React from 'react';

interface AddressOptionRowProps {
  address: string;
  checked: boolean;
  tags: string[];
}

export const AddressOptionRow = React.memo(({ address, checked, tags }: AddressOptionRowProps) => (
  <>
    <Checkbox checked={checked} />
    {tags.map((tag, key) => (
      <LightFrenchFries key={key} label={tag} autoAssignColor noShrink />
    ))}
    <Typography variant="h6" paletteColor={500} middleTruncation>{address}</Typography>
  </>
));

AddressOptionRow.displayName = 'AddressOptionRow';
