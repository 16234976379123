import { Box, makeStyles, MenuItem, Typography } from '@material-ui/core';
import { LightSelect } from 'elements/LightSelect';
import React, { FC } from 'react';

import { chainIdIconMapper, chainIdMapper } from '../mappers';
import { MoonPayTag } from './MoonPayTag';

interface SelectNetworkProps {
  selectableNetworks: [number, string][];
  selectedNetwork: string;
  setSelectedNetwork: (network: string) => void;
  disable?: boolean;
  placeholder?: string;
  moonPaySupportedByChainId?: Record<string, boolean>
}

const useStyles = makeStyles((theme) => ({
  menuItem: {
    padding: 8,
    '&.Mui-selected': {
      backgroundColor: theme.palette.green[100],
      '&:hover': {
        backgroundColor: theme.palette.green[100],
      },
    },
    borderBottom: `1px solid ${theme.palette.grey[200]}`,
  },
  item: {
    alignItems: 'center',
    display: 'flex',
    flexWrap: 'nowrap',
    gap: 8,
    '& object': {
      width: 20,
      height: 20,
    },
  },
  networkText: {
    color: theme.palette.grey[700],
    fontSize: 12,
    lineHeight: '16px',
    fontWeight: 500,
  },
}));

const SelectNetwork: FC<SelectNetworkProps> = ({
  selectableNetworks,
  selectedNetwork,
  setSelectedNetwork,
  disable,
  placeholder,
  moonPaySupportedByChainId,
}) => {
  const classes = useStyles();

  const item = React.useCallback((chainId: string, showMoonPayTag?: boolean) => (
    <Box className={classes.item}>
      <object data={chainIdIconMapper[chainId]} type="image/svg+xml" aria-label={chainId} />
      <Typography variant="h6" className={classes.networkText}>
        {chainIdMapper[chainId] ? chainIdMapper[chainId][1] : ''}
      </Typography>
      {showMoonPayTag && moonPaySupportedByChainId && moonPaySupportedByChainId[chainId] && (<MoonPayTag />)}
    </Box>
  ), []);

  return (
    <LightSelect
      value={selectedNetwork ? chainIdMapper[selectedNetwork][1] : ''}
      renderValue={selectedNetwork ? () => (item(selectedNetwork)) : undefined}
      placeholder={placeholder || 'Select network'}
      disabled={disable}
      displayEmpty
    >
      {selectableNetworks.map(([, chainId], index) => (
        <MenuItem
          key={index}
          value={chainIdMapper[chainId][1]}
          className={classes.menuItem}
          onClick={() => {
            setSelectedNetwork(chainId);
          }}
        >
          {item(chainId, true)}
        </MenuItem>
      ))}
    </LightSelect>
  );
};

SelectNetwork.displayName = 'SelectNetwork';
export { SelectNetwork };
