import { Box, Drawer, makeStyles, Theme, Typography } from '@material-ui/core';
import { PlainAccordion, PlainAccordionDetails, PlainAccordionSummary } from 'elements';
import React, { FC, useMemo, useState } from 'react';
import SVG from 'react-inlinesvg';
import { Link, useLocation } from 'react-router-dom';
import { HashLink } from 'react-router-hash-link';
import { DisclaimerRoute, PrivacyPolicyRoute, TermsOfUseRoute } from 'routes';

const useStyles = makeStyles<Theme, { open: boolean, termsOpen: boolean }>((theme) => ({
  banner: {
    display: 'flex',
    justifyContent: 'center',
    background: theme.palette.green['600'],
    cursor: 'pointer',
  },
  bannerContent: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',

    padding: '8px 16px',
    width: '100%',
  },

  pageTitle: {
    color: 'white',
    fontSize: 14,
    lineHeight: '32px',
    fontWeight: 600,
  },
  chevron: {
    color: 'white',
    transform: ({ open }) => (open ? 'rotate(180deg)' : ''),
    transitionProperty: 'transform',
    transitionDuration: '150ms',
    transitionTimingFunction: 'ease-in-out',
  },

  drawer: {
    overflowY: 'auto',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'stretch',

    rowGap: 24,
    padding: '132px 24px 24px',
  },
  pageLink: {
    color: theme.palette.grey[500],
    fontSize: 24,
    fontWeight: 600,
    lineHeight: '32px',
  },
  subpageLink: {
    color: theme.palette.grey[500],
    fontSize: 16,
    fontWeight: 500,
    lineHeight: '24px',
  },

  accordionSummary: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',

    margin: 0,
    width: '100%',

    '& $expanded': {
      margin: 0,
    },

    '& svg': {
      color: theme.palette.grey[400],
      transform: ({ termsOpen }) => (termsOpen ? 'rotate(180deg)' : ''),
      transitionProperty: 'transform',
      transitionDuration: '150ms',
      transitionTimingFunction: 'ease-in-out',
    },
  },
  accordionDetails: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'start',
    rowGap: 24,
    paddingTop: 20,
  },

  active: { color: theme.palette.green[600] },
}));

const TermsOfUseSubpages = [
  { title: 'Definitions', hash: 'definitions' },
  { title: 'General terms', hash: 'general-terms' },
  { title: 'Establishing an account', hash: 'establishing-an-account' },
  { title: 'Coinbag services', hash: 'coinbag-services' },
  { title: 'License', hash: 'license' },
  { title: 'Using the services', hash: 'using-the-services' },
  { title: 'Service fees and pricing', hash: 'service-fees-and-pricing' },
  { title: 'Invoicing and payment', hash: 'invoicing-and-payment' },
  { title: 'Disclaimers', hash: 'disclaimers' },
  { title: 'Indemnity', hash: 'indemnity' },
  { title: 'Termination', hash: 'termination' },
  { title: 'Services are not financial advice', hash: 'services-are-not-financial-advice' },
  { title: 'Risk disclosure', hash: 'risk-disclosure' },
  { title: 'Compliance', hash: 'compliance' },
  { title: 'Privacy policy', hash: 'privacy-policy' },
  { title: 'Dispute resolution', hash: 'dispute-resolution' },
  { title: 'Miscellaneous', hash: 'miscellaneous' },
];

const PolicyNavigation: FC = () => {
  const { pathname, hash } = useLocation();

  const [open, setOpen] = useState(false);
  const currentPage = useMemo(() => (
    pathname === PrivacyPolicyRoute ? 'Privacy Policy'
      : pathname === DisclaimerRoute ? 'Disclaimer'
        : 'Terms of use'
  ), [pathname]);

  const [termsOpen, setTermsOpen] = useState(pathname === TermsOfUseRoute);

  const classes = useStyles({ open, termsOpen });
  return (
    <>
      <Box className={classes.banner} onClick={() => setOpen(!open)}>
        <Box className={classes.bannerContent}>
          <Typography className={classes.pageTitle}>
            {currentPage}
          </Typography>
          <SVG className={classes.chevron} src="/icons/icon_chevron.svg" />
        </Box>
      </Box>

      <Drawer
        classes={{ paper: classes.drawer }}
        variant="temporary"
        elevation={0}
        anchor="top"
        open={open}
        onClose={() => setOpen(false)}
      >
        <Link
          className={`${classes.pageLink} ${pathname === PrivacyPolicyRoute && classes.active}`}
          to={PrivacyPolicyRoute}
        >
          Privacy Policy
        </Link>

        <Link
          className={`${classes.pageLink} ${pathname === DisclaimerRoute && classes.active}`}
          to={DisclaimerRoute}
        >
          Disclaimer
        </Link>

        <PlainAccordion
          elevation={0}
          expanded={termsOpen}
          onChange={(_, expanded) => setTermsOpen(expanded)}
        >
          <PlainAccordionSummary classes={{ content: `${classes.accordionSummary} ${classes.pageLink}` }}>
            Terms & Policies
            <SVG style={{ transform: termsOpen ? 'rotate(180deg)' : '' }} src="/icons/icon_chevron.svg" />
          </PlainAccordionSummary>

          <PlainAccordionDetails className={classes.accordionDetails}>
            <Link
              className={`${classes.subpageLink} ${pathname === TermsOfUseRoute && !hash && classes.active}`}
              to={TermsOfUseRoute}
            >
              Terms of use
            </Link>

            {TermsOfUseSubpages.map((subpage, index) => (
              <HashLink
                key={index}
                className={`${classes.subpageLink} ${pathname === TermsOfUseRoute && hash === `#${subpage.hash}` && classes.active}`}
                to={{
                  pathname: TermsOfUseRoute,
                  hash: subpage.hash,
                }}
                onClick={() => setOpen(false)}
              >
                {subpage.title}
              </HashLink>
            ))}
          </PlainAccordionDetails>
        </PlainAccordion>
      </Drawer>
    </>
  );
};

PolicyNavigation.displayName = 'PolicyNavigation';
export { PolicyNavigation, TermsOfUseSubpages };
