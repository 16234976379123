import { createStyles } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import { makeStyles } from '@material-ui/core/styles';
import { useTimedButton } from 'hooks/useTimedButton';
import * as React from 'react';
import AuthService from 'services/auth-service';
import { EmailCodePurpose } from 'services/types/email-code-purpose';

const useStyles = makeStyles((theme) => createStyles({
  root: {
    width: '75px',
    height: '28px',
    margin: '4px',
    padding: '2px',
    borderRadius: '6px',

    fontSize: '12px',
    backgroundColor: theme.palette.green[600],
    '& span': {
      color: 'white',
    },
    '&:hover': {
      backgroundColor: theme.palette.green[700],
    },
  },
  clicked: {
    backgroundColor: 'white',
    '& span': {
      color: theme.palette.green['500'],
    },
    '&:hover': {
      backgroundColor: 'white',
    },
  },
}));

interface Props {
  purpose: EmailCodePurpose
  emailParams?: Record<string, string>
}

export const GetCodeButton = ({ purpose = EmailCodePurpose.Withdrawal, emailParams } : Props) => {
  const classes = useStyles();
  const { disableButton, buttonText, onClick } = useTimedButton(
    'Get code',
    'Resend',
    async () => {
      await AuthService.getEmailCode(purpose, emailParams);
    },
    5,
  );

  return (
    <IconButton
      className={`${disableButton && classes.clicked} ${classes.root}`}
      onClick={onClick}
      disabled={disableButton}
    > {buttonText}
    </IconButton>
  );
};
