import { Box, createStyles, makeStyles, Typography } from '@material-ui/core';
import { Button } from 'elements';
import React, { useEffect, useState } from 'react';
import { AccountDeleteRoute, DashboardRoute, SignUpRoute } from 'routes';
import { isLoggedIn } from 'services';
import { useStore as useUserStore } from 'store/zustand/User';

import UserService from '../../services/user-service';

enum State {
  INIT,
  REMOVED,
  FAILED
}

const useStyles = makeStyles((theme) => createStyles({
  root: {
    display: 'flex',
    minHeight: '70vh',
    width: '100%',
    padding: '16px',
    gap: '100px',
    textAlign: 'center',
    alignItems: 'center',
    justifyContent: 'center',
    flexGrow: 1,
    color: theme.palette.grey[600],
  },
  innerBox: {
    display: 'flex',
    flexDirection: 'column',
    gap: '24px',
  },
  error: {
    color: theme.palette.red[500],
  },
}));

const AccountDeleted = () => {
  const classes = useStyles();
  const confirm = (new URL(document.location.href)).searchParams.get('confirm');
  const [status, setStatus] = useState(State.INIT);
  const { logout } = useUserStore();

  useEffect(() => {
    (async () => {
      try {
        if (isLoggedIn()) {
          logout('', false);
        }
      } finally {
        if (confirm) {
          try {
            await UserService.confirmDeleteAccount(confirm);
            setStatus(State.REMOVED);
          } catch (e) {
            setStatus(State.FAILED);
          }
        } else {
          window.location.href = DashboardRoute;
        }
      }
    })();
  }, [confirm]);

  const handleSignOut = () => {
    logout(SignUpRoute);
  };

  return (
    <Box className={classes.root}>
      {status === State.INIT && (
        <>
          <Typography variant="h6" className={classes.error}>Deleting private keys...</Typography>
        </>
      )}

      {status === State.REMOVED && (
        <Box className={classes.innerBox}>
          <Typography variant="h3">Your Coinbag account is no longer available.</Typography>
          <Typography variant="h5">
            You have successfully revoked access. Private keys were deleted from Coinbag databases.
          </Typography>
          <Typography variant="h5">To use Coinbag again you need to create a new account.</Typography>
          <Button size="sm" onClick={handleSignOut}>
            <Typography variant="h6">Create a new account</Typography>
          </Button>
        </Box>
      )}

      {status === State.FAILED && (
        <>
          <Typography variant="h6" className={classes.error}>
            Unable to delete private keys. Please contact
            <a href="mailto:support@coinbag.finance">support@coinbag.finance</a>
          </Typography>
        </>
      )}
    </Box>
  );
};

AccountDeleted.routePath = AccountDeleteRoute;
AccountDeleted.allowedForAccessRevokedUser = true;

export default AccountDeleted;
