import * as React from 'react';
import { Redirect, RouteComponentProps, useParams } from 'react-router-dom';
import { SignUpIndividualInvestorsRoute, SignUpRoute } from 'routes';

import Seo from '../Seo';
import ReferralStorage from './ReferralStorage';

export const fixedLandingMap: Record<string, string> = {
  '/swiss': 'CE2E346C5A4CB03B',
  '/ch': '1949E6550B5F2FDA',
  '/fahmai': '761629B835ACEFA7',
  '/fahmaiwhiskey': '761629B835ACEFA7',
  '/abag_akash': 'CC914D4B85C19196',
  '/socialmediayou': 'CC914D4B85C19196',
};

export const LandingPageRoute: Record<string, string> = {
  fahmaiwhiskey: SignUpIndividualInvestorsRoute,
  fahmai: SignUpIndividualInvestorsRoute,
};

interface ReferralParams {
  rid: string;
}

const ReferralLanding = ({ history }: RouteComponentProps) => {
  let { rid } = useParams<ReferralParams>();
  const path = window.location.pathname.split('/')[1];

  try {
    if (!rid) {
      if (path === 'rid') {
        rid = window.location.pathname.split('/')[2];
      } else {
        rid = fixedLandingMap[`/${path}`] || '';
      }
    }

    if (rid) {
      if (ReferralStorage.storageAvailable('localStorage')) {
        ReferralStorage.set(rid);
        setTimeout(() => {
          history.push(LandingPageRoute[path] || SignUpRoute);
        }, 1000);
        return renderLoader();
      }
      renderLoader();
      setTimeout(() => {
        history.push(`${LandingPageRoute[path] || SignUpRoute}?rid=${rid}`);
      }, 1000);
      return renderLoader();
    }
  } catch {
    setTimeout(() => {
      history.push('/home#');
    }, 1000);
  }

  return <Redirect to="/notfound" />;
};

const renderLoader = () => (
  <>
    <Seo route={`/${window.location.pathname.split('/')[1]}`} />
    <div style={{ height: '100vh', width: '100vw', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
      <div className="loading-animation-container">
        <img className="loading-animation" alt="Loading..." src="/images/loading.gif" />
      </div>
    </div>
  </>
);

ReferralLanding.displayName = 'ReferralLanding';
export default ReferralLanding;
