import { Box, createStyles, Grid, Hidden, makeStyles, useMediaQuery, useTheme } from '@material-ui/core';
import { DepositProviderCard } from 'components/DepositProvider';
import { Button, InputWithTitle, LightModal, Typography } from 'elements';
import React, { useEffect, useMemo, useState } from 'react';
import SVG from 'react-inlinesvg';
import { CoinsWithMasterCoins, CoinWithMasterCoinInfo } from 'services/types/coin';
import WalletService from 'services/wallet-service';
import { useStore as useAccountSettingStore } from 'store/zustand/AccountSetting';
import { useStore as useCoinStore } from 'store/zustand/Coin';
import { useStore as useUserStore } from 'store/zustand/User';
import { useStore as useWalletStore } from 'store/zustand/Wallet';

import { KycBanner, KycModal } from '../KYC';
import { DepositLowLiquidityBanner } from './Components/DepositLowLiquidityBanner';
import { DepositWarningBanner } from './Components/DepositWarningBanner';
import { SelectNetwork } from './Components/SelectNetwork';
import { SelectToken } from './Components/SelectToken';
import { SelectWalletField } from './Components/SelectWalletField';
import { DepositProgressBanner } from './DepositProgressBanner';

interface DepositSectionProps {
  depositableCoins: CoinsWithMasterCoins;
  portfolioValue: number | null;
  walletAddresses: Record<string, string>;
}

const useStyles = makeStyles((theme) => createStyles({
  container: {
    width: '100%',
    display: 'flex',
    gap: 12,
    flexDirection: 'column',
  },
  blur: {
    filter: 'blur(2px)',
    pointerEvents: 'none',
  },
  form: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    gap: 16,
    marginTop: 4,
  },
  walletAddressBox: {
    display: 'flex',
    flexDirection: 'row',
    border: '1.2px solid',
    borderColor: theme.palette.grey['200'],
    borderRadius: '8px',
    height: '2.5em',
    padding: '0.2em 0em 0.2em 0.6em',
    overflowWrap: 'break-word',
    wordWrap: 'break-word',
    wordBreak: 'break-word',
    '&:hover': {
      border: '1.2px solid',
      borderColor: theme.palette.green['500'],
    },
  },
  walletAddressText: {
    display: 'flex',
    flexGrow: 1,
    alignItems: 'center',
    marginRight: '0.5em',
    color: theme.palette.grey['600'],
  },
  copyButton: {
    width: '98px',
    background: theme.palette.green[500],
    margin: '0px 4px',
    padding: '0em 0.5em',
    borderRadius: '6px',
    minWidth: 'fit-content',
    minHeight: 32,
    '&:hover': {
      background: theme.palette.green['400'],
    },
  },
  copyButtonText: {
    color: '#FFFFFF',
    textTransform: 'none',
  },
  copied: {
    background: theme.palette.green['600'],
    '&:hover': {
      background: theme.palette.green['600'],
    },
  },
  divider: {
    margin: '16px 0',
    color: theme.palette.grey[400],
    fontSize: 12,
    textAlign: 'center',
  },
  notesText: {
    marginTop: 8,
    color: theme.palette.grey[500],
    fontSize: 12,
  },
  providerCard: {
    marginTop: 16,
  },
  greenText: { color: theme.palette.green[500] },
  yellowText: { color: theme.palette.yellow[600] },

  cancelRoot: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: 8,
    width: '100%',
    '& h6': {
      marginBottom: 8,
    },
  },
  cancelButtonsRow: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    gap: 16,
  },
  approachTitle: {
    margin: '16px 8px 8px',
  },
}));

export const DepositSection = ({ depositableCoins, portfolioValue, walletAddresses }: DepositSectionProps) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('xs'));
  const [user, fetchUser] = useUserStore((state) => [state.user, state.fetchUser]);
  const [wallet] = useWalletStore((state) => [state.wallet]);
  const [coinsGroupByMasterCoinName] = useCoinStore((state) => [state.coinsGroupByMasterCoinName]);
  const [kycOpen, setKycOpen] = useState(false);

  // Periodically sync user's KYC result if not yet GREEN
  useEffect(() => {
    const interval = setInterval(() => {
      if (user?.kycResult !== 'GREEN') {
        fetchUser(true);
      }
    }, 10000);
    return () => {
      clearInterval(interval);
    };
  }, []);

  const [depositableTokens, setDepositableTokens] = useState<Record<string, CoinWithMasterCoinInfo[]>>({});
  const [selectedMasterCoin, setSelectedMasterCoin] = useState('');
  const [selectableNetworks, setSelectableNetworks] = useState<[number, string][]>([]);

  const selectedWalletInfo = React.useMemo(
    () => (user?.walletInfo.find((w) => w.walletId === wallet?.walletId)), [user, wallet],
  );

  useEffect(() => {
    if (!selectedMasterCoin) return;

    const networks: [number, string][] = depositableTokens[selectedMasterCoin]
      .map((coin) => ([coin.coinId, coin.chainId]));
    setSelectableNetworks(networks);

    // If there is only a network available, select that network by default.
    if (networks.length === 1) setSelectedNetwork(networks[0][1]);
  }, [selectedMasterCoin]);

  // Fetch depositableTokens
  useEffect(() => {
    const tokens = Object.values(depositableCoins);
    const masterCoins = tokens.reduce<Record<string, CoinWithMasterCoinInfo[]>>((coin, token) => ({
      ...coin,
      [token.masterCoin.ticker]: [...coin[token.masterCoin.ticker] || [], token],
    }), {});
    setDepositableTokens(masterCoins);
  }, [depositableCoins]);

  const [selectedNetwork, setSelectedNetwork] = useState('');

  const [isCopied, setIsCopied] = useState(false);
  const handleCopyAddress = () => {
    navigator.clipboard.writeText(walletAddresses[selectedNetwork]);
    setIsCopied(true);
  };

  const { syncUserData } = useAccountSettingStore((state) => state);
  useEffect(() => syncUserData(user), [user]);

  const [showMoonpay, setShowMoonpay] = useState(false);
  const [moonpayUrl, setMoonpayUrl] = useState('');
  const [showCancelFund, setShowCancelFund] = useState(false);

  const minimumAmountToEarn = 350; // Minimum amount to earn is $350

  const selectedCoin = useMemo(() => {
    const coins = coinsGroupByMasterCoinName[selectedMasterCoin];
    return coins
      ?.find((c) => (c.chainId === selectedNetwork));
  }, [coinsGroupByMasterCoinName, selectedNetwork, selectedMasterCoin]);

  const showMoonPay = useMemo(
    () => selectedCoin && selectedCoin.metadata.moonPayCurrencyCode !== undefined,
    [selectedCoin]
  );

  const handleFundMoonPay = async () => {
    if (selectedCoin) {
      const response = await WalletService.getMoonpayUrl(selectedCoin.coinId);
      setMoonpayUrl(response.data.url);
      setShowMoonpay(true);
    }
  };

  const moonPaySupportedByMasterCoinName = useMemo(() => Object.entries(coinsGroupByMasterCoinName)
    .reduce((prev: Record<string, boolean>, [ticker, coins]) => (
      { ...prev, [ticker]: coins.some((c) => c.metadata.moonPayCurrencyCode !== undefined) }), {}),
  [coinsGroupByMasterCoinName]);

  const moonPaySupportedByChainId = useMemo(() => coinsGroupByMasterCoinName[selectedMasterCoin]
    ?.reduce((prev: Record<string, boolean>, curr) => (
      { ...prev, [curr.chainId]: curr.metadata.moonPayCurrencyCode !== undefined }), {}),
  [coinsGroupByMasterCoinName, selectedMasterCoin]);

  const classes = useStyles();
  return (
    <>
      <SelectWalletField />
      <KycModal open={kycOpen} onClose={() => setKycOpen(false)} />
      {selectedWalletInfo !== undefined && !selectedWalletInfo.isVerified && (
        <KycBanner onVerify={() => setKycOpen(true)} isOwnWallet={selectedWalletInfo?.isOwn ?? false} />
      )}
      <Box className={`${!selectedWalletInfo?.isVerified ? classes.blur : ''} ${classes.container}`}>
        {portfolioValue != null && portfolioValue < minimumAmountToEarn && (
          <DepositProgressBanner portfolioValue={portfolioValue} minimumAmount={minimumAmountToEarn} />
        )}
        <Box className={classes.form}>
          <InputWithTitle title="Select token to deposit:">
            <SelectToken
              type="Deposit"
              setSelectedMasterCoin={setSelectedMasterCoin}
              tokens={depositableTokens}
              moonPaySupportedByMasterCoinName={moonPaySupportedByMasterCoinName}
            />
          </InputWithTitle>
          {selectedMasterCoin && depositableTokens[selectedMasterCoin]?.some((x) => x.metadata.nonSwappable) && (
            <DepositLowLiquidityBanner
              selectedMasterCoin={selectedMasterCoin}
              selectedNetwork={selectedNetwork}
            />
          )}
          {selectedMasterCoin && (
            <InputWithTitle title="Select Network:">
              <SelectNetwork
                selectedNetwork={selectedNetwork}
                setSelectedNetwork={setSelectedNetwork}
                selectableNetworks={selectableNetworks}
                moonPaySupportedByChainId={moonPaySupportedByChainId}
              />
            </InputWithTitle>
          )}
          {showMoonPay && (
            <Typography
              variant={isMobile ? 'h4' : 'h2'}
              paletteColor={800} align="center"
              className={classes.approachTitle}
            >
              Transfer to your wallet address
            </Typography>
          )}
          {selectedNetwork && (
            <InputWithTitle title="Wallet address:">
              <Box className={classes.walletAddressBox}>
                <Typography id="wallet-address" variant="h6" className={classes.walletAddressText}>
                  {walletAddresses[selectedNetwork] ? walletAddresses[selectedNetwork] : ''}
                </Typography>
                <Button className={`${isCopied && classes.copied} ${classes.copyButton}`} onClick={handleCopyAddress}>
                  <Typography variant="h6" className={classes.copyButtonText}>
                    {isCopied ? (
                      <>
                        <img src="icons/dashboard/ic_check_2.svg" alt="Check" />
                      &nbsp;Copied
                      </>
                    ) : 'Copy address'}
                  </Typography>
                </Button>
              </Box>
            </InputWithTitle>
          )}
          {selectedMasterCoin && selectedNetwork && (
            <DepositWarningBanner
              selectedMasterCoin={selectedMasterCoin}
              selectedNetwork={selectedNetwork}
            />
          )}
          {showMoonPay && (
            <>
              <Grid container justifyContent="center" alignItems="center">
                <Grid item xs={5}>
                  <hr />
                </Grid>
                <Grid item xs={2}>
                  <Typography className={classes.divider}>or</Typography>
                </Grid>
                <Grid item xs={5}>
                  <hr />
                </Grid>
              </Grid>
              <Typography variant={isMobile ? 'h4' : 'h2'} align="center" paletteColor={800}>
                Purchase crypto with Bank Transfer,
                <Hidden smDown><br /></Hidden> Debit or Credit Card
              </Typography>
              <Typography className={classes.notesText} align="center" paletteColor={800}>
                *Requires additional user verification
              </Typography>
              <DepositProviderCard
                className={classes.providerCard}
                onFund={handleFundMoonPay}
                headerSrc="/images/deposit/moonpay-header.svg"
                name="MoonPay"
                tag="Best for Beginners"
                iconSrc="/logos/moon-pay.svg"
                perks={[
                  {
                    title: 'deposit fee:',
                    icon: <SVG src="/logos/coinbag.svg" width={24} height={24} />,
                    value: (
                      <span className={classes.greenText}>
                        <SVG src="/icons/icon_label.svg" width={20} height={20} /> 3-4%
                      </span>
                    ),
                  },
                  {
                    title: 'account verification:',
                    value: (
                      <span className={classes.yellowText}>
                        <SVG src="/icons/deposit/ic_date_yellow.svg" width={20} height={20} /> 1-2 days
                      </span>
                    ),
                  },
                ]}
              />
            </>
          )}
        </Box>
      </Box>
      <LightModal
        maxWidth={370}
        setOpen={setShowMoonpay}
        open={showMoonpay}
        onClose={() => setShowCancelFund(true)}
        noPadding
      >
        <iframe
          title="MoonPay"
          frameBorder="no"
          allow="accelerometer; autoplay; camera; gyroscope; payment"
          style={{ borderRadius: '10px', height: '90vh', maxHeight: '600px', width: '100%', maxWidth: '100%' }}
          src={moonpayUrl}
        />
      </LightModal>

      <LightModal
        maxWidth={300}
        open={showCancelFund}
        setOpen={setShowCancelFund}
        onClose={() => setShowCancelFund(false)}
      >
        <Box className={classes.cancelRoot}>
          <Typography variant="h5" paletteColor={800}>Cancel your purchase?</Typography>
          <Typography variant="h6" paletteColor={700}>You will need to start again</Typography>
          <Button
            fullWidth
            size="md"
            color="red"
            onClick={() => {
              setShowCancelFund(false);
              setShowMoonpay(false);
            }}
          >
            Cancel purchase
          </Button>
          <Button
            variant="secondary"
            fullWidth
            size="md"
            onClick={() => setShowCancelFund(false)}
          >
            Not now
          </Button>
        </Box>
      </LightModal>
    </>
  );
};
