import 'pure-react-carousel/dist/react-carousel.es.css';

import { Box, makeStyles } from '@material-ui/core';
import {
  EarningsOverviewSection,
  InterestTotalCard,
  ManagePortfolioSection,
  NotificationBanner,
  PortfolioSection,
  PortfolioValueCard,
  ReferralSection,
  Terra2Holdings,
} from 'components/dashboard';
import { SignUpFlow } from 'components/dashboard/SignupFlow/SignUpFlow';
import RebalanceConfirmModal from 'components/RebalanceConfirmModal';
import RebalancingStatus from 'components/RebalancingStatus';
import { WalletLevelCard } from 'components/WalletLevel/WalletLevelCard';
import { Page } from 'elements';
import React, { useMemo } from 'react';
import { DashboardRoute, HelpConnectBinanceRoute } from 'routes';
import { Wallet, WalletConnection } from 'services/types/wallet';
import { WorkItemName } from 'services/types/workItem';
import WalletService from 'services/wallet-service';
import { useStore as useBundleStore } from 'store/zustand/Bundle';
import { useStore as useFeatureStore } from 'store/zustand/Feature';
import { useStore as useWalletStore } from 'store/zustand/Wallet';

import { PageParameter } from '../../../App';

const useStyles = makeStyles((theme) => ({
  mobileSection: {
    margin: '0 16px',
    [theme.breakpoints.up('sm')]: {
      margin: 0,
    },
  },
  overviewGrid: {
    display: 'grid',
    gridTemplateColumns: 'minmax(0, 1fr)',
    gridTemplateRows: 'repeat(3, minmax(0, 1fr))',
    rowGap: 16,
    [theme.breakpoints.up('md')]: {
      gridTemplateRows: 'minmax(0, 1fr)',
      gridTemplateColumns: 'repeat(3, minmax(0, 1fr))',
      columnGap: 24,
    },
    [theme.breakpoints.up('lg')]: { columnGap: 16 },
    [theme.breakpoints.up('xl')]: { columnGap: 24 },
  },
  rebalancingStatus: {
    [theme.breakpoints.down('sm')]: {
      padding: '0 16px',
    },
  },
}));

const Dashboard: React.NamedExoticComponent<object>  = React.memo(() => {
  const classes = useStyles();
  const [
    wallet,
    fetchWallet,
    rebalancingStatus,
    fetchRebalancingStatus,
    deposits,
    fetchDepositAndWithdrawalHistory,
    walletsBalance,
    walletsTs,
    fetchActiveWalletBalance,
    activeWalletBalance,
    latestWalletBalances,
    isRebalancingWallet,
  ] = useWalletStore((state) => [
    state.wallet,
    state.fetchWallet,
    state.rebalancingStatus,
    state.fetchRebalancingStatus,
    state.deposits,
    state.fetchDepositAndWithdrawalHistory,
    state.walletBalances,
    state.walletsTs,
    state.fetchLatestWalletBalances,
    state.activeWalletBalance,
    state.latestWalletBalances,
    state.isRebalancingWallet,
  ]);
  const [currentBundle, fetchCurrentBundle] = useBundleStore((state) => [
    state.currentBundle,
    state.fetchCurrentBundle,
  ]);

  const [features, findPendingAction] = useFeatureStore((state) => [state.features, state.findPendingAction]);
  const [loading, setLoading] = React.useState(true);
  const [showRebalanceModal, setShowRebalanceModal] = React.useState(false);
  const [walletConnection, setWalletConnection] = React.useState<WalletConnection | undefined>(undefined);

  const permissionsMissing = React.useMemo(() => (
    walletConnection?.canTrade === false || walletConnection?.connected === false
  ), [walletConnection]);

  const dateMessage = React.useMemo(() => {
    if (!rebalancingStatus && permissionsMissing) {
      return 'Rebalancing paused.Check your Binance API permissions.';
    }
    return '';
  }, [rebalancingStatus, permissionsMissing]);

  const getTotalUsdtValue = () => (activeWalletBalance?.map((a) => a.usdtValue).reduce((a, b) => a + b, 0) ?? 0);

  const showNotes = React.useMemo(() => (
    dateMessage
    && getTotalUsdtValue() > 0
    && walletConnection?.canTrade !== false
    && walletConnection?.connected !== false
  ), [walletConnection, dateMessage, activeWalletBalance]);

  const showInactiveBundleWarning = React.useMemo(() => ((getTotalUsdtValue()) > 100
    && currentBundle?.status === 'inactive'), [activeWalletBalance, currentBundle]);

  const lunaCoin = React.useMemo(() =>
    latestWalletBalances?.find((c) => c.coin.symbol === 'LUNA'),
  [latestWalletBalances]
  );

  React.useEffect(() => {
    const fetchData = async () => {
      const [walletResponse] = await Promise.allSettled([
        fetchWallet(true),
        fetchCurrentBundle(true),
        fetchDepositAndWithdrawalHistory(true),
        fetchActiveWalletBalance(),
      ]);
      setLoading(false);
      const walletData = (walletResponse as PromiseFulfilledResult<Wallet | undefined>).value;
      if (walletData?.walletId !== undefined && walletData?.exchangeId !== 4) {
        WalletService.testWalletConnection(walletData.walletId)
          .then((resp) => setWalletConnection(resp.data))
          .catch(() => setWalletConnection(undefined));
      }
    };
    fetchData();
    const rebalancingInterval = setInterval(() => fetchRebalancingStatus(), 15000);
    return () => {
      clearInterval(rebalancingInterval);
    };
  }, []);

  const handleRebalancingModalConfirm = () => {
    setShowRebalanceModal(false);
    triggerRebalancing();
  };

  const triggerRebalancing = async () => {
    await WalletService.triggerRebalance();
    window.location.reload();
  };

  const showRebalancingBanner = useMemo(() => {
    const workItemList = [
      ...(findPendingAction(WorkItemName.AddUserBundle) ?? []),
      ...(findPendingAction(WorkItemName.CreateOrEditBundle) ?? []),
      ...(findPendingAction(WorkItemName.SetBasicWallet) ?? []),
      ...(findPendingAction(WorkItemName.SetEarningConfig) ?? []),
      ...(findPendingAction(WorkItemName.SetRebalancingMode) ?? []),
    ];
    return workItemList.length === 0 && isRebalancingWallet;
  }, [findPendingAction, isRebalancingWallet]);

  return (
    <Page>
      {features?.signUpFlow && <SignUpFlow deposits={deposits} />}
      {permissionsMissing && (
        <Box className={classes.mobileSection}>
          <NotificationBanner
            description={`Your Binance API seem to be missing the following permissions:${!walletConnection?.connected ?
              ' \'Enable Reading\'' : null}${!walletConnection?.canTrade ? ' \'Enable Spot & Margin Trading\'' : null}`
            }
            actionText="How to Fix"
            actionLink={HelpConnectBinanceRoute}
          />
        </Box>
      )}
      {showInactiveBundleWarning && showRebalancingBanner && (
        <Box className={classes.mobileSection}>
          <NotificationBanner
            description={'Your portfolio has deviated from target portfolio. ' +
              'Trigger a rebalancing to bring it back into alignment.'}
            actionText="Trigger Rebalancing"
            actionClicked={() => setShowRebalanceModal(true)}
          />
        </Box>
      )}
      {(rebalancingStatus && rebalancingStatus !== 'unknown') && (
        <Box className={classes.rebalancingStatus}>
          <RebalancingStatus status={rebalancingStatus} />
        </Box>
      )}
      {showNotes && (
        <Box className={classes.mobileSection}>
          <NotificationBanner description={dateMessage} showNote />
        </Box>
      )}
      <Box className={`${classes.mobileSection} ${classes.overviewGrid}`}>
        <PortfolioValueCard
          isLoading={loading}
          value={walletsBalance ?? 0}
          lastUpdate={walletsTs}
        />
        <InterestTotalCard
          isLoading={loading}
        />
        <WalletLevelCard portfolioValue={getTotalUsdtValue()} />
      </Box>
      <EarningsOverviewSection />
      {features?.dashboardActions && <ManagePortfolioSection rebalancingStatus={rebalancingStatus} />}
      <PortfolioSection
        portfolio={activeWalletBalance?.map((x) => ({
          coinId: x.coinId, name: x.name, quantity: x.balance, price: x.usdtValue,
        })) ?? []}
        totalAssetValue={walletsBalance}
      />
      {wallet?.exchangeId === 4 && (
        <>
          <Terra2Holdings
            showLearnMore
            lockedPrice={lunaCoin?.convertedQty?.locked ?? 0}
            lockedQuantity={lunaCoin?.qty.locked}
            price={lunaCoin?.convertedQty?.free ?? 0}
            quantity={lunaCoin?.qty.free}
          />
          <ReferralSection />
        </>
      )}
      <RebalanceConfirmModal
        showModal={showRebalanceModal}
        action={handleRebalancingModalConfirm}
        setShowModal={setShowRebalanceModal}
      />
    </Page>
  );
});
Dashboard.displayName = 'Dashboard';
(Dashboard as unknown as PageParameter).routePath = DashboardRoute;
(Dashboard as unknown as PageParameter).loginRequired = true;

export default Dashboard;
