import { Box, createStyles, makeStyles } from '@material-ui/core';
import React from 'react';
import { useStore as useUserStore } from 'store/zustand/User';

const useStyles = makeStyles(() => createStyles({
  root: {
    display: 'flex',
    flexDirection: 'column',
    gap: '26px',
    alignItems: 'center',
  },
  image: {
    width: '176px',
    height: '176px',
    borderRadius: '100px',
  },
  logout: {
    display: 'flex',
    flexWrap: 'nowrap',
    gap: '8px',
    alignItems: 'center',
  },
}));

const AccountDetailsLeftPanel = () => {
  const classes = useStyles();
  const { user } = useUserStore();

  return (
    <Box className={classes.root}>
      <img className={classes.image} src={user?.avatar ?? `/api/images/ape?user=${user?.id}`} alt="avatar" />
    </Box>
  );
};
export default AccountDetailsLeftPanel;
