import axios from 'axios';

import Headers from './headers';
import { InterestByCoin } from './types/interest';

const API_URL = '/api/interest/v2';

const getInterestEarned = (): Promise<InterestByCoin[]> => axios.get(`${API_URL}/interestEarned`,
  { headers: { ...Headers.auth() } }).then((res) => res.data);

const getNumberOfInterestCoins = (): Promise<number> => axios.get(`${API_URL}/numLendingCoins`,
  { headers: { ...Headers.auth() } }).then((res) => res.data);

export default {
  getInterestEarned,
  getNumberOfInterestCoins,
};
