import axios, { AxiosResponse } from 'axios';

import Headers from './headers';

const API_URL = '/api/bundle/admin/';

interface CreateBundleResponse {
  bundleId: number;
  bundleStateId: number;
}

const createBundle = (bundleName: string, bundleDescription: string, bundle: number[][],
  imageUrl: string | null): Promise<AxiosResponse<CreateBundleResponse>> => axios.post(
  `${API_URL}createBundle`,
  { bundleName, bundleDescription, bundle, imageUrl },
  { headers: { ...Headers.def(), ...Headers.auth() } },
);

const editBundle = (bundleId: number, bundleName: string, bundleDescription: string, bundle: number[][],
  imageUrl: string | null): Promise<AxiosResponse<CreateBundleResponse>> => axios.post(
  `${API_URL}editBundle`,
  { bundleId, bundleName, bundleDescription, bundle, imageUrl },
  { headers: { ...Headers.def(), ...Headers.auth() } },
);

export default {
  createBundle,
  editBundle,
};
