import { Box, createStyles, Hidden, makeStyles, useMediaQuery, useTheme } from '@material-ui/core';
import { Typography } from 'elements';
import * as React from 'react';
import SVG from 'react-inlinesvg';
import { useStore as usePortfolioSettingStore } from 'store/zustand/PortfolioSetting';

import CoinEarningItem, { CoinEarningItemProps } from './CoinEarningItem';

export interface CoinEarningRowProps {
  icon: string;
  name: string;
  coinId: number;
  items: CoinEarningItemProps[];
}

const useStyles = makeStyles((theme) => createStyles({
  root: {
    display: 'grid',
    gridTemplateColumns: '168px repeat(3, 1fr) 120px',
    border: '1px solid #DEE3EF',
    borderRadius: 8,
    padding: 8,
    gap: 8,
    transition: 'all ease-in-out 0.2s',
    [theme.breakpoints.down('sm')]: {
      gridTemplateColumns: '1fr',
      borderRadius: 0,
      borderRight: 0,
      borderLeft: 0,
      borderBottom: 0,
      paddingLeft: 16,
      paddingRight: 16,
      paddingTop: 16,
    },
  },
  expanded: {
    gridTemplateColumns: '1fr 108px 128px 86px 120px 32px',
    padding: '16px 8px 8px',
    '& $option': {
      gridColumn: 6,
    },
  },
  title: {
    display: 'flex',
    flexWrap: 'nowrap',
    alignItems: 'center',
    gap: 8,
    marginLeft: 8,
    [theme.breakpoints.down('sm')]: {
      marginLeft: 0,
    },
  },
  icon: {
    borderRadius: 36,
    width: 36,
    height: 36,
    overflow: 'hidden',
    flexShrink: 0,
    userDrag: 'none',
    userSelect: 'none',
    '& svg': {
      width: 36,
      height: 36,
    },
  },
  name: {
    color: theme.palette.grey[700],
    fontSize: 16,
    lineHeight: '24px',
    fontWeight: 500,
    [theme.breakpoints.down('sm')]: {
      fontSize: 14,
    },
  },
  option: {
    display: 'flex',
    flexWrap: 'nowrap',
    gap: 12,
    cursor: 'pointer',
    alignSelf: 'center',
    justifySelf: 'end',
    alignItems: 'center',
    justifyContent: 'flex-end',
    gridColumn: 5,
  },
  optionText: {
    color: theme.palette.green[500],
    textTransform: 'uppercase',
    fontSize: 12,
    lineHeight: '20px',
    fontWeight: 500,
  },
  arrow: {
    width: 32,
    height: 32,
    '& svg': {
      width: 32,
      height: 32,
    },
    '& path': {
      fill: theme.palette.grey[400],
    },
  },
  arrowUp: {
    rotate: '180deg',
  },
  tableHeader: {
    fontSize: 12,
    lineHeight: '20px',
    fontWeight: 500,
    color: theme.palette.grey[500],
    alignSelf: 'center',
  },
}));

const CoinEarningRow = ({ icon, name, items, coinId }: CoinEarningRowProps) => {
  const classes = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [isExpanded, setIsExpanded] = React.useState(false);
  const [selectedCoinConfig] = usePortfolioSettingStore((state) => [state.selectedCoinConfig]);
  const expanded = React.useMemo(() => (!isMobile && isExpanded), [isExpanded, isMobile]);
  const selectedItemIndex = React.useMemo(() => {
    const selectedId = (selectedCoinConfig || {})[coinId];
    if (selectedId && items.length > 0) {
      return items.findIndex((item) => item.protocolId === selectedId);
    }
    return -1;
  }, [selectedCoinConfig, items]);

  const moreOption = React.useCallback((count: number) => (
    <Box className={classes.option} onClick={() => { setIsExpanded(!isExpanded); }}>
      {count > 0 && !expanded && <Typography className={classes.optionText}>{`+${count} options`}</Typography>}
      <Box className={`${classes.arrow} ${expanded ? classes.arrowUp : ''}`}>
        <SVG src="/icons/dashboard/ic_arrow_down.svg" />
      </Box>
    </Box>
  ), [expanded]);

  return (
    <Box className={`${classes.root} ${expanded ? classes.expanded : ''}`}>
      <Box className={classes.title}>
        <Box className={classes.icon}>
          <SVG id={name} src={icon} />
        </Box>
        <Typography className={classes.name}>{name}</Typography>
      </Box>
      {expanded && (
        <>
          <Typography className={classes.tableHeader}>Base Chain</Typography>
          <Typography className={classes.tableHeader}>30-Day Avg. APY</Typography>
          <Typography className={classes.tableHeader}>Audited</Typography>
          <Typography className={classes.tableHeader}>Unstake period</Typography>
          {moreOption(1)}
        </>
      )}
      {
        items
          .slice(0, expanded || isMobile ? undefined : (selectedItemIndex > 2 ? 2 : 3))
          .concat(!expanded && !isMobile && selectedItemIndex > 2 ? items[selectedItemIndex] : [])
          .map((item, index) => <CoinEarningItem key={index} {...item} isExpanded={expanded} />)
      }
      <Hidden smDown>
        {!expanded && moreOption(items.length - 3)}
      </Hidden>
    </Box>
  );
};

export default CoinEarningRow;
