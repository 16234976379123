import React from 'react';
import { AccountDetailsRoute, PermissionsRoute, TeamAccessRoute } from 'routes';
import { useStore as useFeatureStore } from 'store/zustand/Feature';
import { useStore as useUserStore } from 'store/zustand/User';

import { UserType } from '../models';
import { PagesNavBar } from './PagesNavBar';

const ManageAccountNavBar = () => {
  const [user, fetchUser] = useUserStore((state) => [state.user, state.fetchUser]);
  const [features, fetchFeatures] = useFeatureStore((state) => [state.features, state.fetchFeatures]);
  const items = React.useMemo(() => {
    const i = [{ icon: '/icons/ic_bag.svg', text: user?.userType === UserType.Institutional ? 'Company Information' : 'Personal Information', route: AccountDetailsRoute }];
    if (features?.teamAccess) {
      i.push({ icon: '/icons/ic_member.svg', text: 'Team members', route: TeamAccessRoute });
      i.push({ icon: '/icons/ic_lock.svg', text: 'Permissions', route: PermissionsRoute });
    }
    return i;
  }, [user, features]);

  React.useEffect(() => {
    const fetchAll = async () => {
      await Promise.allSettled([
        fetchUser(true),
        fetchFeatures(),
      ]);
    };

    fetchAll();
  }, []);
  return (<PagesNavBar items={items} />);
};

export default ManageAccountNavBar;
