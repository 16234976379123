import { Box, createStyles, makeStyles, Theme } from '@material-ui/core';
import { Card, Typography } from 'elements';
import * as React from 'react';
import { palette } from 'theme';

const useStyles = makeStyles<Theme, Partial<FeatureCardProps>>((theme: Theme) => createStyles({
  root: {
    display: 'flex',
    justifyContent: 'flex-start',
    width: '100%',
    flexDirection: 'column',
    gap: 16,
    padding: '20px 20px 32px',
    height: '100%',
    color: theme.palette.grey[700],
    boxShadow: '0px 4px 32px rgba(135, 138, 165, 0.24)',
    borderRadius: 32,
    '& h4': {
      fontSize: 24,
      lineHeight: '32px',
      fontWeight: 600,
      color: ({ color }) => theme.palette[color || 'green'][600],
      [theme.breakpoints.down('sm')]: {
        fontSize: 14,
        lineHeight: '28px',
      },
    },
    '& h5': {
      color: theme.palette.grey[700],
      fontSize: 20,
      lineHeight: '32px',
      fontWeight: 500,
      [theme.breakpoints.down('sm')]: {
        fontSize: 12,
        lineHeight: '20px',
      },
    },
    [theme.breakpoints.down('sm')]: {
      padding: 12,
      borderRadius: 16,
      gap: 4,
    },
  },
  icon: {
    userDrag: 'none',
    userSelect: 'none',
    width: 60,
    height: 60,
    [theme.breakpoints.down('sm')]: {
      width: 28,
      height: 28,
    },
  },
  title: {
    display: 'flex',
    flexWrap: 'nowrap',
    gap: 12,
    alignItems: 'center',
    [theme.breakpoints.down('sm')]: {
      gap: 4,
    },
  },
}));

interface FeatureCardProps {
  icon: string;
  title: string;
  desc: string;
  color: keyof Omit<typeof palette, 'primary' | 'error'>;
}

export const ourStoryCards: FeatureCardProps[] = [
  {
    icon: 'ic_os_1',
    title: 'Security',
    desc: 'Customers hold and control their digital assets in customer-specific Multi-Party Computation (MPC), ISO-270001 certified, and audited wallet(s). Any movement of digital assets must be initiated and approved by the customer and has a secondary layer of multi-signatory approval with two-factor authentication. Additionally, military-grade AES-256 encryption protects your account details.',
    color: 'green',
  },
  {
    icon: 'ic_os_2',
    title: 'Self-Custody',
    desc: 'Your wallets. Your keys. Your digital assets. Not Coinbag\'s. Blockchain enables customers to own and control their assets at all times, and Coinbag makes it simple for you to do so. MPC technology splits your private keys into multiple key shares, making it possible to recover them if lost. Self-custody protects you from 3rd party use (or misuse) of your funds, meaning you are always in control of your assets.',
    color: 'blue',
  },
  {
    icon: 'ic_os_3',
    title: 'Risk Management',
    desc: 'Just as risk-averse companies distribute their fiat assets (i.e., USD, EUR, and more) across multiple banks and bank accounts to ring-fence their asset exposure, Coinbag enables you to connect to various licensed custodians and self-custodial wallets, all from a single place. Doing so eliminates any single point of failure without the operational nightmare of managing multiple wallets, private keys, and counterparties.',
    color: 'purple',
  },
  {
    icon: 'ic_os_4',
    title: 'Ease-of-Use',
    desc: 'Self-managing and de-risking your digital assets can be daunting, but it doesn\'t need to be. Coinbag\'s simple solutions allow you and your team to use and manage your digital assets efficiently. Whether paying payroll, tracking incoming/outgoing expenses, or choosing to earn interest with native staking and other interest methods, our simple-to-use products only take a few minutes to learn, enabling you to focus on the growth of your business, not your digital asset operations.',
    color: 'green',
  },
];

export const notCentralizedCards: FeatureCardProps[] = [
  {
    icon: 'ic_nc_1',
    title: 'Control',
    desc: 'Investors choose the cryptos they want to hold in their portfolio and exactly if, how, and where they earn interest.',
    color: 'blue',
  },
  {
    icon: 'ic_nc_2',
    title: 'Transparency',
    desc: 'Investors can view exactly where/how their cryptos are earning interest while staying up-to-date on their portfolio earnings.',
    color: 'green',
  },
  {
    icon: 'ic_nc_3',
    title: 'Automation',
    desc: 'Automated cross-chain algorithms minimize transaction fees, keeping more money in the investor\'s wallet.',
    color: 'green',
  },
  {
    icon: 'ic_nc_4',
    title: 'Protection',
    desc: 'Unlike other platforms, every investor has their own segregated wallet, which can protect them in the event of bankruptcy.',
    color: 'purple',
  },
];

export const flexibilityCards: FeatureCardProps[] = [
  {
    icon: 'ic_fc_1',
    title: 'Referral',
    desc: 'You’ll get a unique referral link that attributes every client that enters Coinbag’s funnel through your marketing efforts',
    color: 'green',
  },
  {
    icon: 'ic_fc_2',
    title: 'Integration',
    desc: 'Seamlessly integrate specific backend services to your pre-existing product via API, so your front end customer experience remains unchanged',
    color: 'blue',
  },
  {
    icon: 'ic_fc_3',
    title: 'Hybrid',
    desc: 'We’ll provide you with a whitelabel user interface that matches your style and a unique URL for a branded customer experience',
    color: 'purple',
  },
];

const FeatureCard = (props: FeatureCardProps) => {
  const classes = useStyles({ color: props.color });
  return (
    <Card className={classes.root}>
      <Box className={classes.title}>
        <picture>
          <source srcSet={`/icons/marketing/${props.icon}@1x.png 1x, /icons/marketing/${props.icon}@2x.png 2x, /icons/marketing/${props.icon}@3x.png 3x`} media="(min-width: 900px)" />
          <source srcSet={`/icons/marketing/${props.icon}_mob@1x.png 1x, /icons/marketing/${props.icon}_mob@2x.png 2x, /icons/marketing/${props.icon}_mob@3x.png 3x`} media="(min-width: 0px)" />
          <img
            className={classes.icon}
            src={`/icons/marketing/${props.icon}@1x.png`}
            alt={props.title}
          />
        </picture>
        <Typography variant="h4">{props.title}</Typography>
      </Box>
      <Typography variant="h5">{props.desc}</Typography>
    </Card>
  );
};

export default FeatureCard;
