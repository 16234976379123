import { Box } from '@material-ui/core';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import { Typography } from 'elements';
import React from 'react';
import { WalletLevel } from 'services/types/wallet-enum';

interface Props {
  min: number;
  max?: number;
  levels?: number[]
  portfolioValue: number;
  milestoneValue?: number;

  isActive?: boolean
  backgroundColor?: string;
  labelColor: string;
  borderColor: string;
  barColor: string;
  milestoneColor?: string;
  maxPlus?: boolean;
}

type UseStylesProps = {
  progress: number;
  milestoneLabelPos?: number;
} & Pick<Props, 'barColor' | 'isActive' | 'backgroundColor' | 'borderColor' | 'labelColor' | 'milestoneColor'>

const useStyles = makeStyles((theme) => createStyles({
  root: {
    display: 'flex',
    width: '100%',
  },
  container: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    gap: 4,
    alignItems: 'center',

    width: '100%',
  },
  barWrapper: {
    display: 'flex',
    alignItems: 'center',

    width: '100%',
    height: 8,

    padding: '0px 2px',
    borderRadius: 4,
    border: '1px solid',
    borderColor: ({ isActive, borderColor }: UseStylesProps) => (isActive ? borderColor : theme.palette.grey['200']),
    backgroundColor: ({ isActive, backgroundColor }: UseStylesProps) => (isActive ? backgroundColor : theme.palette.grey['100']),
  },
  bar: {
    display: 'flex',
    alignItems: 'center',
    width: ({ progress }: UseStylesProps) => (`${progress}%`),
    height: 4,

    borderRadius: 2,
    backgroundColor: ({ barColor }: UseStylesProps) => barColor,
  },
  labelContainer: { display: 'flex', width: '100%', justifyContent: 'space-between' },
  labelMin: { color: ({ labelColor, isActive }: UseStylesProps) => (isActive ? labelColor : theme.palette.grey['500']) },
  labelMax: { color: ({ isActive }: UseStylesProps) => (isActive ? 'white' : theme.palette.grey['700']) },
  milestoneLabel: {
    color: ({ labelColor }: UseStylesProps) => (labelColor),
    position: 'absolute',
    left: ({ milestoneLabelPos }: UseStylesProps) => (`${milestoneLabelPos}%`),
    transform: 'translate(-50%, 0%)',
  },
  milestone: {
    position: 'absolute',
    transform: 'translateX(-2px)',
    width: 4,
    height: 4,
    borderRadius: 2,
    backgroundColor: ({ milestoneColor }: UseStylesProps) => (milestoneColor && milestoneColor),
  },
  achieved: { backgroundColor: 'white !important', width: 2, height: 2, transform: 'translateX(-1px)' },
}));

export const ProgressBar = ({
  min, max, maxPlus, levels, portfolioValue, isActive, milestoneValue, barColor,
  labelColor, borderColor, backgroundColor, milestoneColor,
}: Props) => {
  const milestones = max !== undefined && levels !== undefined ? levels.map((level) => (((level - min) / max) * 100)) : [];
  const progress = max === undefined && isActive && portfolioValue >= min ? 10
    : max === undefined && isActive && portfolioValue < min ? 0
      : max === undefined && !isActive ? 0 : portfolioValue > max! ? 100
        : portfolioValue < min ? 0 : ((portfolioValue - min) / max!) * 100;
  const milestoneLabelPos = max !== undefined && milestoneValue ? ((milestoneValue - min) / max) * 100 : 0;
  const classes = useStyles({ progress, milestoneLabelPos, barColor, isActive, labelColor, borderColor, backgroundColor, milestoneColor });

  return (
    <Box className={classes.root}>
      <Box className={classes.container}>
        <Box className={classes.labelContainer}>
          <Typography variant="h6" className={classes.labelMin}>
            ${max !== undefined ? min.toLocaleString() : `${min.toLocaleString()}+`}
          </Typography>
          {max !== undefined &&
            (<Typography variant="h6" className={classes.labelMax}>
              {`$${max.toLocaleString()}${maxPlus ? '+' : ''}`}
            </Typography>)}
          {milestoneValue !== undefined && max !== undefined && milestoneValue < max &&
            (<Typography
              variant="h6"
              className={classes.milestoneLabel}
            >
              ${milestoneValue.toLocaleString()}
            </Typography>)}
        </Box>
        <Box className={classes.barWrapper}>
          {milestones.map((milestone, key) => (
            milestone < 100 &&
            (<Box
              className={`${progress > milestone && classes.achieved} ${classes.milestone}`}
              left={`${milestone}%`}
              key={key}
            />)
          ))}
          <Box className={classes.bar} />
        </Box>
      </Box>
    </Box>
  );
};

export const resolveWalletLevelCardProgressBarProps = (level: WalletLevel, isActive: boolean, portfolioValue: number,
  levels: number[] = [], cardType: 'dashboard-card' | 'detail-card' = 'detail-card', min?: number, max?: number): Props => {
  const resolveColor = (): Pick<Props, 'backgroundColor' | 'labelColor' | 'borderColor' | 'barColor' | 'min' | 'max'> => {
    switch (level) {
      case WalletLevel.Bronze:
        return { backgroundColor: '#F4EFEC', labelColor: '#EEE6E1', borderColor: '#AB9992',
          barColor: '#715C54', min: min !== undefined ? min : 3000, max: max !== undefined ? max : 10000 };
      case WalletLevel.Silver:
        return { backgroundColor: '#F7F8FD', labelColor: '#DEE3EF', borderColor: '#BBC4DD',
          barColor: '#6E7BA1', min: min !== undefined ? min : 10000, max: max !== undefined ? max : 25000 };
      case WalletLevel.Gold:
        return { backgroundColor: '#FFFBEC', labelColor: '#FFFBEC', borderColor: '#FFF0A0',
          barColor: '#B68917', min: min !== undefined ? min : 25000, max: max !== undefined ? max : 100000 };
      case WalletLevel.Private:
        return { backgroundColor: '#F7F8FD', labelColor: '#FFFBEC', borderColor: '#BBC4DD',
          barColor: '#6E7BA1', min: min !== undefined ? min : 100000, max };
      case WalletLevel.Green:
      default:
        return { backgroundColor: '#EBFFFC', labelColor: '#D6FFF8', borderColor: '#D6FFF8',
          barColor: '#02BD9F', min: min !== undefined ? min : 0, max: max !== undefined ? max : 3000 };
    }
  };

  const resolveDashboardCardAttr = (): Pick<Props, 'max' | 'milestoneColor' | 'maxPlus' | 'milestoneValue'> => {
    switch (level) {
      case WalletLevel.Green: return { max: 3000, milestoneColor: '#37E2C3', milestoneValue: 3000, maxPlus: true };
      case WalletLevel.Bronze: return { max: 10000, milestoneColor: '#A78F84', milestoneValue: 10000, maxPlus: true };
      case WalletLevel.Silver: return { max: 25000, milestoneColor: '#909CBD', milestoneValue: 25000, maxPlus: true };
      case WalletLevel.Private: return { max: 100000 };
      default: return { max: 100000, maxPlus: true };
    }
  };

  const dashboardMax = cardType === 'dashboard-card' ? resolveDashboardCardAttr() : {};

  return {
    ...resolveColor(),
    ...dashboardMax,
    isActive,
    portfolioValue,
    levels,
  };
};
