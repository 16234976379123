import { Box, createStyles, makeStyles } from '@material-ui/core';
import { Typography } from 'elements';
import React from 'react';

const useStyles = makeStyles((theme) => createStyles({
  placeholder: {
    display: 'flex',
    alignItems: 'center',
    gap: 4,
    fontWeight: 400,
    '& .image': {
      width: 20,
      height: 20,
      '& img': {
        width: 20,
        height: 20,
      },
    },
  },
  selectHeader: {
    color: theme.palette.grey[400],
    fontWeight: 400,
    fontSize: 12,
    lineHeight: '20px',
  },
}));

export const CoinItemPlaceHolder = () => {
  const classes = useStyles();
  return (
    <Box className={classes.placeholder}>
      <Typography variant="h6" className={classes.selectHeader}>
        Select crypto
      </Typography>
    </Box>
  );
// eslint-disable-next-line semi
}
