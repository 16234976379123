import axios, { AxiosResponse } from 'axios';

import Headers from './headers';

const API_URL = '/api/session/';

const check = (): Promise<string> => axios.get(`${API_URL}check`,
  { headers: { ...Headers.def(), ...Headers.auth() } })
  .then((response: AxiosResponse<string>) => {
    const { data } = response;
    return data;
  });

export default {
  check,
};
