// @flow
import { Box } from '@material-ui/core';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import { Typography } from 'elements';
import { List } from 'elements/List';
import React, { useMemo } from 'react';
import { WalletLevel } from 'services/types/wallet-enum';

import { cardBackground } from './card-background';
import { LevelBadge } from './LevelBadge';
import { ProgressBar, resolveWalletLevelCardProgressBarProps } from './ProgressBar';

interface Props {
  level: WalletLevel;
  isActive: boolean;
  portfolioValue: number;
  benefits?: string[];
}
type UseStylesProp = Pick<Props, 'isActive'> & { background: string }

const useStyles = makeStyles((theme) => createStyles({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'left',
    gap: 12,

    width: '100%',
    padding: 20,
    paddingBottom: 16,

    border: ({ isActive }: UseStylesProp) => (!isActive ? `2px dashed ${theme.palette.grey[200]}` : undefined),
    background: ({ isActive, background }: UseStylesProp) => (isActive && background ? background : undefined),
    borderRadius: 8,
  },
  listItemHeader: {
    fontWeight: 400,
    color: ({ isActive }: UseStylesProp) => (isActive ? 'white' : theme.palette.grey[600]),
  },
  listItemContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  listItem: { color: ({ isActive }: UseStylesProp) => (isActive ? 'white' : theme.palette.grey[700]), paddingBottom: 2 },
}));

export const WalletLevelDetailsCard = ({ level, isActive, portfolioValue }: Props) => {
  const classes = useStyles({ isActive, background: cardBackground[level] });

  const progressBar = useMemo(() => (
    <ProgressBar {...resolveWalletLevelCardProgressBarProps(level, isActive, portfolioValue)} />), [level, isActive, portfolioValue]);

  return (
    <Box className={classes.root}>
      <LevelBadge level={level} isActive={isActive} size="xmd" />
      {progressBar}
      <Box>
        <Typography variant="h6" className={classes.listItemHeader}>{isActive ? 'This level' : 'Benefits on this level'}:</Typography>
        <List
          markerSize={15}
          color="grey"
          colorVarient={isActive ? 100 : 400}
          items={walletLevelBenefits[level].map((item, key) => (
            <Typography variant="h4" className={classes.listItem} key={key}>{item}</Typography>
          ))}
        />
      </Box>
    </Box>
  );
};

const walletLevelBenefits: Record<WalletLevel, string[]> = {
  [WalletLevel.Green]: ['Basic bundles', 'Basic interest methods', 'Manual rebalancing'],
  [WalletLevel.Bronze]: ['Custom bundles', 'All theme-based portfolios', 'Custom interest-earning methods',
    'Automated rebalancing'],
  [WalletLevel.Silver]: ['Custom bundles', 'All theme-based portfolios', 'Custom interest-earning methods',
    'Automated rebalancing', 'First month free'],
  [WalletLevel.Gold]: ['Custom bundles', 'All theme-based portfolios', 'Custom interest-earning methods',
    'Automated rebalancing', 'First 3 months free', 'Waived annual wallet fee', 'Priority account support'],
  [WalletLevel.Private]: ['Custom bundles', 'All theme-based portfolios', 'Custom interest-earning methods',
    'Automated rebalancing', 'First 3 months free', 'Waived annual wallet fee', '24/7 dedicated account manager',
    'Connect to off-chain interest funds'],
};
