import { createStyles, makeStyles } from '@material-ui/core';
import * as React from 'react';

import { LightFrenchFries } from '../../../elements/LightFrenchFries';

const useStyles = makeStyles((theme) => createStyles({
  moonPayTag: {
    backgroundColor: theme.palette.purple[500],
    color: theme.palette.common.white,
    '& h6': {
      fontSize: 10,
      lineHeight: '12px',
    },
  },
}));

export const MoonPayTag = () => {
  const classes = useStyles();
  return (
    <LightFrenchFries
      className={classes.moonPayTag}
      label="MoonPay available"
    />
  );
};
