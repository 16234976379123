import React, { Component } from 'react';
import FormField from './FormField';
import cloneDeep from 'lodash.clonedeep';

export default class InputField extends FormField {
    constructor(props){
        super(props);

        this.propagatePropsWithOnChange();
    }

    propagatePropsWithOnChange(){
        if (this.props.onChange !== undefined){
            this.propsOnChange = this.props.onChange
        }
        this.propsCopy = cloneDeep(this.props)
        this.propsCopy.onChange = this.onChange.bind(this);
    }

    onChange(e){
        e.preventDefault();
        this.updateValue(e.target.value);
    }

    render(){
        this.propagatePropsWithOnChange();

        return (
            <>
            <input {...this.propsCopy} value={this.value} />
            </>
        );
    };
}
