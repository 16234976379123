import { Box, createStyles, InputAdornment, makeStyles, Typography } from '@material-ui/core';
import { Button, LightTextField } from 'elements';
import { InputWithTitle } from 'elements/InputWithTitle';
import React, { FC, useState } from 'react';
import { EmailCodePurpose } from 'services/types/email-code-purpose';
import { FA, FAResponseStatus } from 'services/types/wallet';
import { FAStatus as Status } from 'services/types/wallet-enum';

import { GetCodeButton } from './GetCodeButton';

interface SubmitWithFAProps {
  email?: boolean;
  authenticator?: boolean;
  emailParams?: Record<string, string>;
  // eslint-disable-next-line no-unused-vars
  onSubmit: (fa: FA) => Promise<FAResponseStatus>;
  submitButtonText: React.ReactNode;
  isSubmitting?: boolean;
  purpose?: EmailCodePurpose;
}

const useStyles = makeStyles((theme) => createStyles({
  faInput: {
    border: '1px solid',
    borderColor: theme.palette.grey['200'],
    borderRadius: '8px',
    width: '100%',
    '& input': {
      padding: '0em 0em 0em 1em',
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: '12px',
    },
  },
  fillingFaText: {
    width: '100%',
    fontWeight: 400,
    textAlign: 'left',
  },
  errorText: {
    color: theme.palette.red ? theme.palette.red[500] : theme.palette.error.main,
    fontSize: 12,
    lineHeight: '20px',
    fontWeight: 400,
    textAlign: 'right',
  },
  loadingOverlay: {
    position: 'absolute',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  textFieldButton: {
    marginRight: '-10px',
  },
  content: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    gap: 16,
    color: theme.palette.grey[700],
  },
}));

const SubmitWithFA: FC<SubmitWithFAProps> = ({
  authenticator = false,
  email = false,
  onSubmit,
  submitButtonText,
  emailParams,
  isSubmitting = false,
  purpose = EmailCodePurpose.Withdrawal,
}) => {
  const classes = useStyles();

  const [emailCode, setEmailCode] = useState<string>('');
  const [emailStatus, setEmailStatus] = useState<Status>(Status.Unknown);

  const [authenticatorCode, setAuthenticatorCode] = useState<string>('');
  const [authenticatorStatus, setAuthenticatorStatus] = useState<Status>(Status.Unknown);

  const errorHandler = (status: Status) => {
    if (status === Status.IncorrectOrExpired) return 'The code is incorrect or expired. Please try again';
    if (status === Status.AllFieldsMandatory) return 'Please enter the 6 digits code';
    if (status === Status.GenericError) return 'Something went wrong. Please try again.';
    return '';
  };

  const handleSubmit = async (e: any) => {
    e.preventDefault();
    try {
      let anyEmpty = false;
      if (email && emailCode === '') {
        setEmailStatus(Status.AllFieldsMandatory);
        anyEmpty = true;
      }
      if (authenticator && authenticatorCode === '') {
        setAuthenticatorStatus(Status.AllFieldsMandatory);
        anyEmpty = true;
      }

      if (anyEmpty) return;
      // eslint-disable-next-line no-shadow
      const { emailStatus, authenticatorStatus } = await onSubmit({ authenticatorCode, emailCode, phoneCode: '' });

      setEmailStatus(emailStatus);
      setAuthenticatorStatus(authenticatorStatus);

      if (emailStatus === Status.Success || authenticatorStatus === Status.Success) {
        window.location.reload();
      }
    } catch (error: any) {
      if (email && emailCode !== '') setEmailStatus(Status.GenericError);
      if (authenticator && authenticatorCode !== '') setAuthenticatorStatus(Status.GenericError);
    }
  };

  const handleEmailChange = React.useCallback((e: any) => {
    setEmailCode(e.target.value);
    setEmailStatus(Status.Unknown);
  }, []);
  const handleAuthenticatorChange = React.useCallback((e: any) => {
    setAuthenticatorCode(e.target.value);
    setAuthenticatorStatus(Status.Unknown);
  }, []);

  return (
    <form className={classes.content} onSubmit={handleSubmit}>
      {email && (
        <InputWithTitle
          title="Email 2FA code"
          titleRightItem={
            emailStatus !== Status.Success && emailStatus !== Status.Unknown && (
              <Typography className={classes.errorText}>{errorHandler(emailStatus)}</Typography>
            )
          }
        >
          <LightTextField
            type="text"
            placeholder="e.g. 666000"
            name="email"
            value={emailCode}
            onChange={handleEmailChange}
            size="medium"
            error={Status.Success && emailStatus !== Status.Unknown}
            InputProps={{
              endAdornment: (
                <InputAdornment className={classes.textFieldButton} position="end" onClick={() => { }}>
                  <GetCodeButton emailParams={emailParams} purpose={purpose} />
                </InputAdornment>
              ),
            }}
          />
        </InputWithTitle>
      )}
      {authenticator && (
        <InputWithTitle
          title="Authenticator app 2FA code"
          titleRightItem={
            authenticatorStatus !== Status.Success && authenticatorStatus !== Status.Unknown && (
              <Typography className={classes.errorText}>{errorHandler(authenticatorStatus)}</Typography>
            )
          }
        >
          <LightTextField
            error={authenticatorStatus !== Status.Success && authenticatorStatus !== Status.Unknown}
            size="medium"
            type="text"
            placeholder="e.g. 666000"
            name="authenticator"
            value={authenticatorCode}
            onChange={handleAuthenticatorChange}
          />
        </InputWithTitle>
      )}

      <Button
        fullWidth
        disabled={isSubmitting}
        size="xmd"
        onClick={handleSubmit}
        type="submit"
      >
        {submitButtonText}
        {isSubmitting && (
          <Box className={classes.loadingOverlay}>
            <Box className="sk-spinner-pulse" />
          </Box>
        )}
      </Button>
    </form>
  );
};

SubmitWithFA.displayName = 'SubmitWithFA';
export default SubmitWithFA;
