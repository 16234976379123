import React from "react";
import { Footer } from "./Footer.js";
import { InternalNavbar } from "./navbar.js";
import { Container, Row, Col, InputGroup } from 'react-bootstrap'
import Loader from "./Loader.js";
import SharedStateComponent from "./SharedStateComponent.js";
import { FIELD, isDesktop } from "../common.js";
import { StepNav } from "./StepNav.js";
import { PencilGreenIcon, WrongIcon, GoodIcon, CopyIcon, QuestionIcon } from "./Icons.js";
import FormHandler from "./FormHandler.js";
import InputField from "./InputField.js";
import WalletService from "../services/wallet-service";
import ProfileReview from "../pages/mobile/ProfileReview.js";
import Welcome from "../pages/mobile/Welcome.js";
import ChooseExchange from "../pages/responsive/ChooseExchange.js";
import AuthService from "../services/auth-service.js";
import StepByStep from "../pages/mobile/StepByStep.js";
import BinanceModal from "./BinanceModal.js";
import Logger from "js-logger";
import Headers from '../services/headers';
import axios from "axios";
import ReactTooltip from "react-tooltip";
import GenericModal from "./GenericModal.js";

export default class BinanceConnect extends SharedStateComponent {

  constructor(props) {
    super(props);

    this.testNewConnection = this.testNewConnection.bind(this);
    this.testWalletConnection = this.testWalletConnection.bind(this);
    this.addWalletConnection = this.addWalletConnection.bind(this);
    this.renderConnectionButton = this.renderConnectionButton.bind(this);

    this.state = {
      form: {},
      connectionTested: false,
      savedConnection: true,
      loading: true
    }
  }

  componentDidMount() {
    const exc = WalletService.getAllExchanges()
    const getWallet = WalletService.getWallet()

    Promise.all([exc, getWallet]).then(arr => {
      var exchanges = arr[0].data
      var wallet = arr[1].data

      if (!wallet.walletId) {
        this.props.history.push(Welcome.routePath)
      } else if (!wallet.exchangeId) {
        this.props.history.push(ChooseExchange.routePath)
      } else {
        const form = {
          apiKey: FIELD(wallet.apiKey),
          apiSecret: FIELD(wallet.apiSecret === undefined ? "" : "********************************"),
          exchangeId: FIELD(wallet.exchangeId),
          modify: false
        }

        this.setState({
          form,
          exchanges,
          wallet
        }, this.testWalletConnection);

        this.props.onLoadComplete()

      }
    })
  }

  testWalletConnection = () => {
    var firstConnection = this.state.firstConnection === undefined ? true : false

    WalletService.testWalletConnection(this.state.wallet.walletId).then(
      resp => {
        this.updateState({
          form: resp.data,
          connectionTested: true,
          firstConnection
        })
      },
      error => {
        this.updateState({
          form: { canRead: undefined, canTrade: undefined, canWithdraw: undefined, ipRestrict: undefined },
          connectionTested: true,
          firstConnection
        })
      }
    );
  }

  testNewConnection = (e) => {
    if (e)
      e.preventDefault();

    var firstConnection = this.state.firstConnection === undefined ? true : false

    WalletService.testConnection(Number.parseInt(this.state.form.exchangeId.value), this.state.form.apiKey.value, this.state.form.apiSecret.value).then(
      resp => {
        this.updateState({
          form: resp.data,
          connectionTested: true,
          firstConnection
        })
      },
      error => {
        this.updateState({
          form: { canRead: undefined, canTrade: undefined, canWithdraw: undefined, ipRestrict: undefined },
          connectionTested: true,
          firstConnection
        })
      }
    );
  }

  addWalletConnection = (e) => {
    e.preventDefault();

    var firstConnection = this.state.firstConnection === undefined ? true : false

    WalletService.addWallet(Number.parseInt(this.state.form.exchangeId.value), this.state.form.apiKey.value, this.state.form.apiSecret.value).then(
      resp => {
        this.updateState({
          form: resp.data,
          connectionTested: true,
          firstConnection,
          savedConnection:true
        })
      },
      error => {
        this.updateState({
          form: { canRead: undefined, canTrade: undefined, canWithdraw: undefined, ipRestrict: undefined },
          connectionTested: true,
          firstConnection
        })
      }
    );
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.form !== undefined) {
      if (["apiKey", "apiSecret", "exchangeId"].filter(x => prevState.form[x] !== undefined)
        .filter(x => this.state.form[x].value !== prevState.form[x].value).length > 0) {
        this.updateState({
          form: { canRead: undefined, canTrade: undefined, canWithdraw: undefined, ipRestrict: undefined },
          connectionTested: false,
          savedConnection: false
        })
      }
    }
  }

  renderConnectionButton() {
    var text = "Test Connection"
    var disable = true
    var clazz = " btn-inverse"
    var onClick = () => { }
    if (this.props.editMode) {
      if (this.state.form.canTrade) {
        text = "Save Connection"
        disable = false
        clazz = " btn-first"
        if (this.state.savedConnection) {
          disable = true
          clazz = " btn-inverse"
        }
        onClick = this.addWalletConnection
      } else if (this.state.form.apiKey.value !== "" && this.state.form.apiKey.value !== undefined &&
        this.state.form.apiSecret.value !== "" && this.state.form.apiSecret.value !== undefined) {
        disable = false
        onClick = this.testNewConnection
      }
    } else {
      if (this.state.form.canRead && this.state.form.canTrade && this.state.form.ipRestrict) {
        text = "Go to final review"
        disable = false
        clazz = " btn-first"
        onClick = () => {
          AuthService.updateUserStep().then(resp => {
            this.props.history.push({ pathname: ProfileReview.routePath })
          });
        }
      } else if (this.state.form.apiKey.value !== "" && this.state.form.apiKey.value !== undefined &&
        this.state.form.apiSecret.value !== "" && this.state.form.apiSecret.value !== undefined) {
        disable = false
        if (this.state.form.apiSecret.value === "********************************") {
          onClick = this.testWalletConnection
        } else {
          onClick = this.addWalletConnection
        }
      }
    }

    return (
      <>
        <button disabled={disable} className={"btn-block" + clazz} onClick={onClick}>
          {text}
        </button>
      </>
    )
  }

  renderWithSharedState() {

    Logger.log(this.state)
    if (!this.state.exchanges || !this.state.form) {
      return <></>
    }


    const badConnection = !this.state.firstConnection && this.state.connectionTested && !this.state.form.canRead
    const badSettings = (!this.state.form.canRead || !this.state.form.canTrade || !this.state.form.ipRestrict) && !this.state.firstConnection && !badConnection && this.state.connectionTested

    return (
      <>
        <Container className="px-3 pb-3">
          <Row className="my-3 text-center">
            <Col>
              <a onClick={() => this.props.history.push({ pathname: StepByStep.routePath, state: { from: this.props.location.pathname } })}>
                Step-by-step guide
              </a>
            </Col>
          </Row>
          <Row>
            <Col>
              <Container className="content-card p-4">
                <Row className="mt-0">
                  <Col><p className="p3">Your exchange:</p></Col>
                  <Col className="text-right">
                    {this.props.editExchange === false ?
                    <p className="p2">
                      {this.state.exchanges?.find(x => x.exchangeId === Number.parseInt(this.state.form.exchangeId.value)).name}
                    </p>
                    :
                    <p className="p2 pointer" onClick={() => this.props.history.push({ pathname: ChooseExchange.routePath })}>
                      {this.state.exchanges?.find(x => x.exchangeId === Number.parseInt(this.state.form.exchangeId.value)).name} <PencilGreenIcon />
                    </p>
                    }
                  </Col>
                </Row>
                <Row className="mt-2">
                  <FormHandler autoComplete="off" name="form">
                    <Container>
                      <Row className="mt-0">
                        <Col>
                          <InputField autoComplete="off" className={badConnection ? "bad" : ""} placeholder="Paste API Key from Binance" type="text" name="apiKey" />
                        </Col>
                      </Row>
                      <Row className="mt-2">
                        <Col>
                          <InputField autoComplete="off" className={badConnection ? "bad" : ""} placeholder="Paste SECRET key from Binance" type="password" name="apiSecret" />
                        </Col>
                      </Row>
                      {badConnection && (
                        <Row className="mt-2 text-center">
                          <Col><p className="p0 red mb-0">
                            {this.state.form.apiKey?.value.includes("@") ?
                              <>
                                Whoops! Looks like you entered exchange login credentials. We need API keys to connect.
                                See how to get them here: <a href={StepByStep.routePath}>Step by step guide</a></>
                              : "Connection failed. Check API key and secret."
                            }
                          </p></Col>
                        </Row>
                      )}
                      {badSettings && (
                        <Row className="mt-2 text-center">
                          <Col><p className="p0 red mb-0">
                            {
                              "Check your API settings on Binance"
                            }
                          </p></Col>
                        </Row>
                      )}
                    </Container>
                  </FormHandler>
                </Row>
                {badConnection && (
                  <Row>
                    <Col className="text-center">
                      <a onClick={() => this.setState({ binanceModal: true })}>Get API keys from Binance</a>
                    </Col>
                  </Row>
                )}
                <Row className="mt-4">
                  <Col>
                    <p className="p0 mb-2">Required API permissions:</p>
                  </Col>
                </Row>
                <Row className="mt-0">
                  <Col xs={1}>{this.state.form.canRead ? <GoodIcon width="12" height="12" /> : <WrongIcon />}</Col>
                  <Col className="pl-1"><p className={("p0 mb-1") + (this.state.form.canRead ? "" : " grey")}>Enable reading</p></Col>
                </Row>
                <Row className="mt-0">
                  <Col xs={1}>{this.state.form.canTrade ? <GoodIcon width="12" height="12" /> : <WrongIcon />}</Col>
                  <Col className="pl-1"><p className={("p0 mb-1") + (this.state.form.canTrade ? "" : " grey")}>Enable spot and margin trading</p></Col>
                </Row>
                <Row className="mt-0">
                  <Col xs={1}>{this.state.form.ipRestrict ? <GoodIcon width="12" height="12" /> : <WrongIcon />}</Col>
                  <Col className="pl-1">
                  <ReactTooltip effect='solid' place="top" type="dark">
                      Copied to clipboard
                    </ReactTooltip>

                    <p className={("p0 mb-1") + (this.state.form.ipRestrict ? "" : " grey")}>Copy and paste this trusted IP on Binance:
                      <a onClick={ () => navigator.clipboard.writeText('34.87.76.151')}>
                        &nbsp;34.87.76.151 <CopyIcon style={{ width: "12px", height: "12px" }} fill="#10AC84" />
                      </a> <QuestionIcon className="pointer" onClick={() => this.setState({ trustedModal: true })} style={{ position: "relative", left: "10px" }} /></p></Col>

                </Row>
                <Row>
                  <Col>
                    {this.renderConnectionButton()}
                  </Col>
                </Row>
              </Container>
            </Col>
          </Row>
        </Container>

        <BinanceModal name="binanceModal" dismissOnClick={true} history={this.props.history} />
        <GenericModal name="trustedModal">
          <h5 className="mb-3">API Management Binance</h5>
          <img src={isDesktop() ? "images/BinanceApiDesktop.png" : "images/BinanceApiMobile.png"} width="100%" height="100%" />
        </GenericModal>

      </>
    );
  }
}
