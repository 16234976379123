import { Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import React from 'react';

interface ContentProps {
  children: React.ReactNode
}

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    alignItems: 'center',
    paddingTop: '30px',
    backgroundColor: 'white',
  },
  contentBox: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    alignItems: 'center',
    gap: '16px',
    maxWidth: '780px',
    width: '100%',
  },
}));

export const Content = (props: ContentProps) => {
  const classes = useStyles();
  return (
    <Box className={classes.root}>
      <Box className={classes.contentBox}>
        {props.children}
      </Box>
    </Box>
  );
};
