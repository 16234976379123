import { Box, makeStyles } from '@material-ui/core';
import { Card, LightToggleGroup, Typography } from 'elements';
import { Coin, CoinWithMasterCoin, PortfolioCoin } from 'models';
import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { BundleCoinWithMasterCoin, BundleMasterCoin } from 'services/types/bundle';
import { useStore as useBundleStore } from 'store/zustand/Bundle';
import { useStore as useCoinStore } from 'store/zustand/Coin';
import { useStore as useFeatureStore } from 'store/zustand/Feature';
import { useStore as useWalletStore } from 'store/zustand/Wallet';
import Cookies from 'universal-cookie';

import { PortfolioBar } from './PortfolioBar';
import { PortfolioPie } from './PortfolioPie';

type PortfolioAllocationSectionProps = {
  className?: string;
  portfolio: Coin[];
  totalAssetValue?: number;
};

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'stretch',
  },
  titleColumn: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    rowGap: 12,
  },
  valueText: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'baseline',
    columnGap: 8,
    color: theme.palette.green[500],
  },
  valueLabelText: {
    color: theme.palette.grey[500],
  },
  toggleGroup: {
    display: 'flex',
    alignItems: 'center',
    columnGap: 16,
    [theme.breakpoints.up('sm')]: {
      position: 'absolute',
      right: 0,
    },
  },
  toggleRoot: {
    [theme.breakpoints.up('sm')]: {
      position: 'absolute',
      right: 0,
    },
  },
  chart: {
    marginTop: 16,
  },
}));

const PortfolioSection: FC<PortfolioAllocationSectionProps> = ({ className, portfolio, totalAssetValue }) => {
  const classes = useStyles();
  const cookies = new Cookies();

  const [bundle, fetchCurrentBundle] = useBundleStore((state) => [state.currentBundle, state.fetchCurrentBundle]);
  const [masterCoins, getMasterCoin, fetchCoins] = useCoinStore((state) => [
    state.masterCoins, state.getMasterCoin, state.fetchCoins,
  ]);
  const [features, fetchFeatures] = useFeatureStore((state) => [state.features, state.fetchFeatures]);
  const [isRebalancingWallet] = useWalletStore((state) => [state.isRebalancingWallet]);

  const [loading, setLoading] = React.useState(true);
  const [showOthers, setShowOthers] = React.useState<boolean | undefined>(undefined);

  useEffect(() => {
    fetchFeatures();
    const fetch = async () => {
      try {
        await Promise.all([fetchCurrentBundle(false), fetchCoins()]);
      } finally {
        setLoading(false);
      }
    };
    fetch();
  }, []);

  const target: BundleCoinWithMasterCoin[] | undefined = useMemo(() => {
    if (isRebalancingWallet === false) return [];
    if (!masterCoins) return undefined;
    return (bundle?.bundleMasterCoins ?? [])
      .map((c: BundleMasterCoin) => ({ ...c, ...masterCoins[c.id.toString()] }))
      .sort((a: BundleMasterCoin, b: BundleMasterCoin) => b.percentage - a.percentage);
  }, [bundle, masterCoins, isRebalancingWallet]);

  const targetMap: { [_: string]: BundleCoinWithMasterCoin } | undefined = useMemo(() => {
    if (!target) return undefined;
    return target.reduce((map, t) => ({ ...map, [t.ticker ?? t.name]: t }), {});
  }, [target]);

  const actual: CoinWithMasterCoin[] | undefined = useMemo(() => {
    if (!portfolio || !portfolio.length || !masterCoins) return undefined;
    const mappedPortfolio = Object.values(portfolio
      .map((c) => ({ ...c, ...getMasterCoin(c.coinId.toString()) }))
      .filter((c) => (c.price ?? 0) + (c.lockedPrice ?? 0) >= 0)
      .reduce((map, c) => {
        if (!map[c.ticker ?? c.name]) {
          return {
            ...map,
            [c.ticker ?? c.name]: {
              ...c,
              ticker: c.ticker ?? c.name,
              quantity: 0,
              price: (c.price ?? 0) + (c.lockedPrice ?? 0),
            },
          };
        }
        const coin = { ...c, ...map[c.ticker ?? c.name] };
        coin.ticker = c.ticker ?? c.name;
        coin.price = (coin.price ?? 0) + (c.price ?? 0) + (c.lockedPrice ?? 0);
        return { ...map, [c.ticker ?? c.name]: coin };
      }, {} as { [_: string]: CoinWithMasterCoin }));
    return mappedPortfolio.sort((a, b) => b.price! - a.price!);
  }, [portfolio, masterCoins]);

  const actualMap: { [_: string]: CoinWithMasterCoin } | undefined = useMemo(() => {
    if (!actual) return undefined;
    return actual.reduce((map, a) => ({ ...map, [a.ticker]: a }), {});
  }, [actual]);

  const portfolioCoins: PortfolioCoin[] = useMemo(() => {
    if (!target || !targetMap || !actual || !actualMap) return [];
    const totalValue = (actual ?? []).reduce((total, coin) => total + (coin.price ?? 0), 0);
    const union = actual.filter((a) => targetMap[a.ticker]);
    const onlyActual = actual.filter((a) => !targetMap[a.ticker]);
    const onlyTarget = target.filter((t) => !actualMap[t.ticker ?? t.name]);

    const allCoins: PortfolioCoin[] = [
      ...union.map((u) => ({
        icon: u.icon,
        name: u.ticker,
        color: u.metadata?.color,
        price: u.price ?? 0,
        amount: u.quantity,
        actualPercentage: ((u.price ?? 0) / totalValue) * 100,
        targetPercentage: targetMap[u.ticker].percentage,
      })),
      ...onlyActual.map((u) => ({
        icon: u.icon,
        name: u.ticker,
        color: u.metadata?.color,
        price: u.price ?? 0,
        amount: u.quantity,
        actualPercentage: ((u.price ?? 0) / totalValue) * 100,
        targetPercentage: 0,
      })),
      ...onlyTarget.map((u) => ({
        icon: u.icon,
        name: u.ticker,
        color: u.metadata?.color,
        price: 0,
        amount: 0,
        actualPercentage: 0,
        targetPercentage: targetMap[u.ticker ?? u.name].percentage,
      })),
    ];
    const displayCoins = allCoins.filter((p) => Math.round(p.actualPercentage) > 0 || p.targetPercentage > 0);
    const smallAmountCoins = allCoins.filter((p) => Math.round(p.actualPercentage) === 0 && p.actualPercentage !== 0 && p.targetPercentage <= 0);
    if (smallAmountCoins.length > 1 && showOthers === undefined) setShowOthers(false);
    if (showOthers || smallAmountCoins.length <= 1) {
      return displayCoins.concat(...smallAmountCoins);
    }
    return displayCoins.concat({
      name: `${smallAmountCoins.length} more`,
      color: '#BBC4DD',
      actualPercentage: smallAmountCoins.reduce((total, c) => (total + c.actualPercentage), 0),
      targetPercentage: smallAmountCoins.reduce((total, c) => (total + c.targetPercentage), 0),
      amount: 0,
      price: smallAmountCoins.reduce((total, c) => (total + c.price), 0),
      onClick: () => setShowOthers(true),
    });
  }, [targetMap, actualMap, showOthers]);

  const [mode, setMode] = useState(cookies.get('portfolio-chart-mode') ?? 'bar');
  const changeMode = useCallback((e, newMode: string) => {
    if (newMode === null) return;
    setMode(newMode);
    cookies.set('portfolio-chart-mode', newMode);
  }, [setMode]);

  const [valueMode, setValueMode] = useState<'percentage' | 'actual'>(
    cookies.get('portfolio-chart-value-mode') ?? 'percentage',
  );
  const changeValueMode = useCallback((e, newMode: 'percentage' | 'actual' | null) => {
    if (newMode === null) return;
    setValueMode(newMode);
    cookies.set('portfolio-chart-value-mode', newMode);
  }, [setValueMode]);

  if (!features?.dashboardPortfolio) return null;

  return (
    <Card
      className={`${classes.root} ${className}`}
      isLoading={loading}
      isSection
    >
      <Box className={classes.titleColumn}>
        <Typography variant="h2" align="center" paletteColor={700}>Portfolio allocation</Typography>
        <Typography variant="h2" className={classes.valueText}>
          {(totalAssetValue?.toLocaleString(undefined, {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          })) || 0} USDT{' '}
        </Typography>
        <Typography variant="h5" className={classes.valueLabelText}>Total value</Typography>
        {portfolioCoins.length > 0 && (
          <Box className={classes.toggleGroup}>
            <LightToggleGroup
              value={mode}
              onChange={changeMode}
              values={[{ value: 'bar', title: 'Bar chart' }, { value: 'pie', title: 'Pie chart' }]}
              exclusive
            />
            <LightToggleGroup
              value={valueMode}
              onChange={changeValueMode}
              values={[{ value: 'percentage', title: '%' }, { value: 'actual', title: '$' }]}
              exclusive
            />
          </Box>
        )}
      </Box>

      {portfolioCoins.length > 0 && (mode === 'bar' ? (
        <PortfolioBar
          className={classes.chart}
          valueMode={valueMode}
          portfolio={portfolioCoins}
          hasTarget={(target?.length ?? 0) > 0}
          showOthers={showOthers}
          toggleShowOthers={() => setShowOthers((prev) => !prev)}
        />
      ) : (
        <PortfolioPie
          showOthers={showOthers}
          hasTarget={(target?.length ?? 0) > 0}
          className={classes.chart}
          valueMode={valueMode}
          totalValue={totalAssetValue || 0}
          portfolio={portfolioCoins}
          toggleShowOthers={() => setShowOthers((prev) => !prev)}
        />
      ))}
    </Card>
  );
};

PortfolioSection.displayName = 'PortfolioSection';
export { PortfolioSection };
