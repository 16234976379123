import React from "react";
import { InternalNavbar } from "../../components/navbar.js";
import WalletService from "../../services/wallet-service";
import SharedStateComponent from "../../components/SharedStateComponent.js";
import Loader from "../../components/Loader.js";
import { Container, Row, Col, InputGroup, FormControl } from 'react-bootstrap';
import { Footer } from "../../components/Footer.js";
import { BinanceAndLogoIcon } from "../../components/Icons.js";
import { BnbLiquidationBundleStateId, sleep } from "../../common.js";
import Logger from "js-logger";
import ReviewDeposit from "../responsive/ReviewDeposit";
import GenericModal from "../../components/GenericModal.js";
import moment from "moment";
import bundleService from "../../services/bundle.service.js";
import { DashboardRoute } from "routes";

export default class Decentralized extends SharedStateComponent {
  static routePath = '/decentralized';
  static loginRequired = true;

  constructor(props) {
    super(props);

    this.changeButton = this.changeButton.bind(this);
    this.convertToBNB = this.convertToBNB.bind(this);

    this.state = {
      buttonText: "Copy my wallet address"
    };
  }

  componentDidMount() {
    WalletService.getDexWallets().then(wallets => {
      this.setState({ wallets: wallets.data, address: wallets.data.find(wallet => wallet.chainId === "56").address });
    });

    const wallet = WalletService.getWallet();
    const userBundle = bundleService.getUserBundleState();

    Promise.all([wallet, userBundle]).then(arr => {
      let wallet = arr[0].data
      let bundle = arr[1].data

      this.setState({
        wallet,
        bundle
      })
    });

    this.fetchWalletInterval = setInterval(() => {
      WalletService.getWallet().then(resp => this.setState( { wallet: resp.data}));
    }, 30 * 1000);
  }

  componentWillUnmount() {
    clearInterval(this.fetchWalletInterval);
  }

  changeButton(e) {
    e.preventDefault();
    navigator.clipboard.writeText(this.state.address)
    if (this.state.buttonText !== "Copied!")
      this.setState({ buttonText: "Copied!" })
    sleep(2000).then(x => this.setState({ buttonText: "Copy my wallet address" }))
  }

  convertToBNB() {
    WalletService.liquidatePortfolio(this.state.wallet.walletId, BnbLiquidationBundleStateId).then(resp => {
      window.location.reload();
    });
  }

  renderWithSharedState() {

    Logger.log(this.state)

    if (!this.state.wallets || !this.state.wallet) {
      return (<><Loader loading={true} /></>)
    }

    const currentRebalancing = moment(new Date(this.state.wallet.nextRebalancingTime)).valueOf() - 300000 <= moment().valueOf()

    return (
      <>

        <Loader loading={false} />

        <InternalNavbar history={this.props.history} title="Switch to Coinbag DeFi" />

        <div className="desktop">
          <Container className="px-4 pb-3">
            <Row>
              <Col>
                <h4>Review transfer status</h4>
              </Col>
            </Row>
            <Row className="mt-0">
              <Col >
              <p>To switch to our decentralized solution, you need to transfer your Crypto from
                  Binance to Coinbag. We'll show you how below!</p>
              </Col>
            </Row>
            <Row>
              <Col>
                <Container className="content-card">
                  <Row className="mt-0">
                    <Col>
                      <div className="text-center mb-4"><BinanceAndLogoIcon /></div>
                      <p className="p6 grey text-center mb-0">Step 1</p>
                      <p className="p9 text-center mb-2">Convert all your cryptos to BNB</p>
                      <p className="grey">We can automatically do of this for you, and is done to make the transfer simple. We will rebalance for you again after the transfer.</p>
                      {this.state.bundle.bundleStateId === BnbLiquidationBundleStateId ?
                        <button className="btn-inverse btn-block py-2" disabled={true}
                          onClick={() => this.setState({ liquidateModal: true })}>
                          {currentRebalancing ? "Converting to BNB now.." : "Success! Please follow step 2"}
                        </button>
                        :
                        <button className="btn-inverse btn-block py-2"
                          onClick={() => this.setState({ liquidateModal: true })}>
                          Convert now
                        </button>
                      }
                      <p className="p6 grey text-center mb-0 mt-4">Step 2</p>
                      <p className="p9 text-center mb-0">Transfer BNB from Binance to Coinbag</p>
                      <ol className="my-2" >
                        <li className="grey">
                          Login to Binance, go to <b>Wallets</b> {">"} <b>Overview</b> {">"} <b>Withdraw</b> {">"} <b>Crypto</b> {">"} Choose <b>BNB</b>
                        </li>
                        <li className="grey">
                          Copy and paste your secure Coinbag wallet address (below) into Binance
                        </li>
                      </ol>
                      <InputGroup className="mb-2">
                        <FormControl className="text-center"
                          value={this.state.address}
                          aria-describedby="basic-addon1"
                          disabled={true}
                        />
                      </InputGroup>
                      <div className="text-center pointer p5 green mb-3" onClick={this.changeButton}>
                        {this.state.buttonText}
                      </div>
                      <ol start="3">
                        <li className="grey">Ensure <b>BEP20 (BSC)</b> network is selected</li>
                        <li className="grey">Click <b>Max</b> {">"} Click <b>Withdraw</b>
                          <ol style={{ listStyleType: "lower-alpha" }}>
                            <li className="grey">This will move your full balance from Binance to your Coinbag wallet</li>
                          </ol>
                        </li>
                        <li className="grey">Sign out {">"} <b>Come back to Coinbag</b></li>
                      </ol>
                    </Col>
                  </Row>
                </Container>
              </Col>
            </Row>
            <Row>
              <Col>
                <button className="btn-block btn-first" onClick={() => this.props.history.push(ReviewDeposit.routePath)}>Step 3: Check transfer status
                </button>
              </Col>
            </Row>

            <Row className="mt-3 text-center">
              <Col>
                <a onClick={() => this.props.history.push(DashboardRoute)}>
                  Back to dashboard
                </a>
              </Col>
            </Row>
          </Container>
        </div>

        <GenericModal name="liquidateModal">
          <div>
            <h5 className="text-center">Ready to convert?</h5>
            <p className="mx-auto grey">
              We’ll temporarily transfer all of your coins to BNB as that is the supported token for transfer to your Coinbag wallet.
            </p>
            <p className="mx-auto grey mb-4">
              It will take a few minutes, then you will transfer this BNB to your Coinbag wallet (step 2), and then we will automatically rebalance your portfolio again.
            </p>
          </div>
          <button className="btn-inverse btn-block" onClick={this.convertToBNB}>Yes, convert now</button>
        </GenericModal>
        <Footer />
      </>
    );
  }
}
