import { createStyles, makeStyles, Theme } from '@material-ui/core';
import * as React from 'react';
import SVG from 'react-inlinesvg';

const useStyles = makeStyles((theme: Theme) => createStyles({
  bgIcon: {
    position: 'absolute',
    '&.a': {
      top: 130,
      left: '10%',
      [theme.breakpoints.down('sm')]: {
        top: 40,
        right: -22,
        left: 'unset',
      },
    },
    '&.b': {
      top: 220,
      left: '52%',
      [theme.breakpoints.down('sm')]: {
        top: 'min(500px, 55%)',
        right: -32,
        left: 'unset',
      },
    },
    '&.c': {
      top: 250,
      right: -45,
      [theme.breakpoints.down('sm')]: {
        display: 'none',
      },
    },
    '&.d': {
      top: 450,
      left: -35,
      [theme.breakpoints.down('sm')]: {
        top: 'min(240px, 8%)',
      },
    },
    '&.e': {
      top: 620,
      right: '10%',
      [theme.breakpoints.down('sm')]: {
        display: 'none',
      },
    },
    '&.f': {
      [theme.breakpoints.up('md')]: {
        display: 'none',
      },
      [theme.breakpoints.down('sm')]: {
        top: 'min(50%, 850px)',
        left: -24,
      },
    },
  },
}));

const BackgroundIcons = () => {
  const classes = useStyles();

  return (
    <>
      <SVG className={`${classes.bgIcon} a`} src="/icons/signup/ic_bg_1.svg" width={46} height={46} />
      <SVG className={`${classes.bgIcon} b`} src="/icons/signup/ic_bg_2.svg" width={75} height={75} />
      <SVG className={`${classes.bgIcon} c`} src="/icons/signup/ic_bg_3.svg" width={124} height={124} />
      <SVG className={`${classes.bgIcon} d`} src="/icons/signup/ic_bg_4.svg" width={96} height={96} />
      <SVG className={`${classes.bgIcon} e`} src="/icons/signup/ic_bg_5.svg" width={75} height={75} />
      <SVG className={`${classes.bgIcon} f`} src="/icons/signup/ic_bg_6.svg" width={70} height={70} />
    </>
  );
};

export default BackgroundIcons;
