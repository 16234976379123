import { Box, createStyles, makeStyles, useMediaQuery } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import { Loading } from 'components';
import { Button, PageAction, Typography } from 'elements';
import ManagePortfolioNavBar from 'elements/ManagePortfolioNavBar';
import * as React from 'react';
import { useEffect } from 'react';
import SVG from 'react-inlinesvg';
import { InterestSettingRoute } from 'routes';
import { mapToCoinConfig } from 'services/protocol-service';
import { WorkItemName } from 'services/types/workItem';
import WalletService from 'services/wallet-service';
import { useStore as useCoinStore } from 'store/zustand/Coin';
import { useStore as useFeatureStore } from 'store/zustand/Feature';
import { EarningType, isEqualCoinConfig, useStore as useInterestEarningStore } from 'store/zustand/InterestEarning';
import { useStore as usePortfolioSettingStore } from 'store/zustand/PortfolioSetting';
import { useStore as useWalletStore } from 'store/zustand/Wallet';

import { shouldShowRebalancingStatus } from '../../../components/RebalancingStatus';
import CoinEarningList from './CoinEarning/CoinEarningList';
import InterestEarningTypeItem from './InterestEarningTypeItem';

const useStyles = makeStyles((theme) => createStyles({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%',
    flexGrow: 1,
    position: 'relative',
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: 8,
    width: '100%',
    padding: '16px',
    maxWidth: '940px',
    flexGrow: 1,
    [theme.breakpoints.down('sm')]: {
      alignItems: 'flex-start',
    },
  },
  title: {
    color: theme.palette.grey[800],
    fontSize: 28,
    lineHeight: '44px',
    fontWeight: 500,
    paddingTop: 8,
    textAlign: 'center',

    [theme.breakpoints.down('sm')]: {
      fontSize: 16,
      lineHeight: '24px',
      textAlign: 'start',
    },
  },
  subTitle: {
    maxWidth: 700,
    color: theme.palette.grey[700],
    textAlign: 'center',
    fontSize: 12,
    lineHeight: '20px',
    fontWeight: 500,
    [theme.breakpoints.down('sm')]: {
      textAlign: 'start',
      marginTop: 4,
    },
  },
  optionGroup: {
    padding: '8px 0',
    [theme.breakpoints.down('sm')]: {
      width: '100vw',
      alignSelf: 'center',
    },
  },
  optionBeginner: {
    '& .highlight': {
      color: '#06D2AE',
      background: '#EBFFFC',
    },
  },
  optionAdvance: {
    '& .highlight': {
      color: '#6E9EFE',
      background: '#F9FBFF',
    },
  },
  modalWrapper: {
    display: 'flex',
    flexDirection: 'column',
    gap: '8px',
    alignItems: 'center',
  },
  saveButton: {
    display: 'flex',
    flexGrow: 1,
    justifyContent: 'flex-end',
  },
  informationText: {
    marginTop: '16px',
  },
  loading: {
    width: '100%',
    position: 'absolute',
    top: 0,
    left: 0,
    zIndex: 2,
    background: 'white',
  },
}));

const initCarousel = (container: React.MutableRefObject<null>, selectedIndex: number) => {
  const { jQuery } = window as any;
  jQuery(container.current).owlCarousel({
    center: true,
    nav: false,
    smartSpeed: 1000,
    startPosition: 0,
    dots: false,
    responsive: {
      0: {
        items: 1,
        stagePadding: 36,
        startPosition: selectedIndex,
      },
      600: {
        mouseDrag: false,
        touchDrag: false,
        startPosition: 1,
        items: 3,
      },
    },
  });
};

const moveToSlide = (selectedIndex: number) => {
  const { jQuery } = window as any;
  try {
    jQuery('.owl-carousel').trigger('to.owl.carousel', [selectedIndex, 500, true]);
    // eslint-disable-next-line no-empty
  } catch {
  }
};

const tryInitCarousel = (container: React.MutableRefObject<null>, selectedIndex: number) => {
  setTimeout(() => {
    try {
      initCarousel(container, selectedIndex);
    } catch (e) {
      setTimeout(() => tryInitCarousel(container, selectedIndex), 1000);
    }
  }, 0);
};

const PortfolioConfirmationModal = React.lazy(() => import('../PortfolioConfirmationModal/PortfolioConfirmationModal'));
const LendingProtocolList = React.lazy(() => import('./PortfolioEarning/ProtocolList'));
const ConsentModal = React.lazy(() => import('./ConsentModal'));
const CoinEarningConfirmationModal = React.lazy(() => import('./CoinEarning/CoinEarningConfirmationModal'));
const RebalancingStatus = React.lazy(() => import('components/RebalancingStatus'));

const InterestSetting = () => {
  const classes = useStyles();
  const container = React.useRef(null);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [showSelectModal, setShowSelectModal] = React.useState<boolean>(false);
  const [showConsentModal, setShowConsentModal] = React.useState<boolean>(false);
  const [loading, setLoading] = React.useState<boolean>(false);
  const [showCoinEarningConfirmationModal, setShowCoinEarningConfirmationModal] = React.useState<boolean>(false);
  const [isLoading, setIsLoading] = React.useState<boolean>(true);
  const [
    fetchCurrentProtocol,
    currentProtocol,
    currentEarningType,
    currentCoinConfig,
  ] = useInterestEarningStore((state) => [
    state.fetchCurrentProtocol,
    state.currentProtocol,
    state.currentEarningType,
    state.currentCoinConfig,
  ]);
  const [features, fetchFeatures, findPendingAction] = useFeatureStore((state) => [
    state.features, state.fetchFeatures, state.findPendingAction]);
  const [fetchCoins] = useCoinStore((state) => [state.fetchCoins]);
  const [
    selectedEarningType,
    setSelectedEarningType,
    selectedProtocolId,
    setSelectedProtocolId,
    selectedCoinConfig,
    setSelectedCoinConfig,
  ] = usePortfolioSettingStore((state) => [
    state.selectedEarningType,
    state.setSelectedEarningType,
    state.selectedProtocolId,
    state.setSelectedProtocolId,
    state.selectedCoinConfig,
    state.setSelectedCoinConfig,
  ]);
  const [
    ready,
    wallet,
    rebalancingStatus,
    activeWalletBalanceNonSwappable,
    activeWalletInfo,
    fetchRebalancingStatus,
    fetchActiveWalletBalance,
    fetchWallet,
  ] = useWalletStore((state) => [
    state.readyWallet,
    state.wallet,
    state.rebalancingStatus,
    state.activeWalletBalanceNonSwappable,
    state.activeWalletInfo,
    state.fetchRebalancingStatus,
    state.fetchLatestWalletBalances,
    state.fetchWallet,
  ]);

  useEffect(() => {
    (async () => {
      await Promise.allSettled([fetchRebalancingStatus(), fetchActiveWalletBalance(), fetchFeatures()]);
    })();
  }, []);

  const updateProtocol = async () => {
    if (selectedEarningType === EarningType.Basic && selectedProtocolId) {
      await WalletService.updateWalletEarningConfig({ protocolId: selectedProtocolId });
      setShowSelectModal(true);
    } else if (selectedEarningType === EarningType.Advance && selectedCoinConfig) {
      await WalletService.updateWalletEarningConfig({ coinConfig: mapToCoinConfig(selectedCoinConfig) });
      setShowSelectModal(true);
    } else if (selectedEarningType === EarningType.None) {
      await WalletService.updateWalletEarningConfig({});
      setShowSelectModal(true);
    }
    await Promise.allSettled([fetchFeatures(true), fetchWallet(true)]);
    setLoading(false);
  };

  const handleSaveButton = async () => {
    setLoading(true);
    if (selectedEarningType !== EarningType.None) {
      setShowConsentModal(true);
      return;
    }
    await updateProtocol();
    setLoading(false);
  };

  const handleConsentButton = async () => {
    if (selectedEarningType === EarningType.Advance) {
      setShowCoinEarningConfirmationModal(true);
      return;
    }
    await updateProtocol();
    setLoading(false);
  };

  React.useEffect(() => {
    if (ready) {
      (async () => {
        setIsLoading(true);
        fetchCoins();
        fetchCurrentProtocol(false).then(() => {
          setIsLoading(false);
        });
      })();
    }
  }, [ready]);

  React.useEffect(() => {
    if (currentEarningType !== undefined) {
      setSelectedEarningType(currentEarningType);
      setSelectedProtocolId(currentProtocol?.protocolId);
      setSelectedCoinConfig(currentCoinConfig);
      setIsLoading(false);
    }
  }, [currentEarningType, currentProtocol, currentCoinConfig]);

  React.useEffect(() => {
    tryInitCarousel(container, selectedEarningType ? +(selectedEarningType) : 0);
  }, [container]);

  React.useEffect(() => {
    if (isMobile && selectedEarningType !== undefined) moveToSlide(+selectedEarningType);
  }, [selectedEarningType, isMobile]);

  React.useEffect(() => {
    if (window.Tawk_API && window.Tawk_API.hideWidget) window.Tawk_API.hideWidget();
    return function cleanup() {
      if (window.Tawk_API && window.Tawk_API.showWidget) window.Tawk_API.showWidget();
    };
  });

  const isReadyToSave = React.useMemo(() => {
    if (currentEarningType === selectedEarningType) {
      if (
        (currentEarningType === EarningType.Basic
          && selectedProtocolId
          && currentProtocol?.protocolId !== selectedProtocolId)
        || (currentEarningType === EarningType.Advance
          && selectedCoinConfig
          && !isEqualCoinConfig(currentCoinConfig ?? [], selectedCoinConfig ?? {}))
      ) {
        return true;
      }
      return false;
    }
    if (
      (selectedEarningType === EarningType.Basic
        && selectedProtocolId)
      || (selectedEarningType === EarningType.Advance
        && selectedCoinConfig)
      || selectedEarningType === EarningType.None
    ) {
      return true;
    }
    return false;
  }, [
    currentEarningType,
    selectedEarningType,
    currentProtocol,
    selectedProtocolId,
    currentCoinConfig,
    selectedCoinConfig,
  ]);

  const isAwaitingApproval = React.useMemo(() => (
    findPendingAction(WorkItemName.SetEarningConfig)?.length ?? 0) > 0, [features]);

  return (
    <>
      {(!isLoading) && (<ManagePortfolioNavBar hasNonSwappable={(activeWalletBalanceNonSwappable || []).length > 0} />)}
      <Box className={classes.root}>
        {(isLoading || !ready) && <Loading className={classes.loading} />}
        {shouldShowRebalancingStatus(rebalancingStatus) && rebalancingStatus !== 'reporting-address' ? (
          <Box className={classes.content}>
            <React.Suspense fallback={<></>}>
              <RebalancingStatus status={rebalancingStatus} />
            </React.Suspense>
          </Box>
        ) : (
          <>
            <Box className={classes.content}>
              <Typography className={classes.title} variant="h1">Manage your interest methods</Typography>
              <Typography className={classes.subTitle} variant="h2">With many ways to earn interest interest, you’re in
                control if, how and where your cryptos earn interest. Based on your selection(s), Coinbag will connect
                your cryptos automatically. Do your own research before choosing your interest methods.
              </Typography>
              <div className={`${classes.optionGroup} owl-carousel`} ref={container}>
                <InterestEarningTypeItem
                  type={EarningType.Basic}
                  image="/icons/earning/ic_basic"
                  title="Basic interest methods"
                  subTitle="Choose from various DeFi protocols for your portfolio to earn interest."
                  className={classes.optionBeginner}
                  disabled={!activeWalletInfo?.isRebalancingWallet}
                />
                <InterestEarningTypeItem
                  type={EarningType.Advance}
                  image="/icons/earning/ic_advance"
                  title="Advanced interest methods"
                  subTitle="Choose how and where to earn interest for each crypto in your portfolio."
                  className={classes.optionAdvance}
                  disabled={!activeWalletInfo?.isRebalancingWallet}
                />
                <InterestEarningTypeItem
                  type={EarningType.None}
                  image="/icons/earning/ic_none"
                  title="Don’t earn interest"
                  subTitle="I just want to hold crypto in my wallet and not earn interest."
                />
              </div>
              {selectedEarningType === EarningType.Basic
                && <React.Suspense fallback={<></>}><LendingProtocolList /></React.Suspense>}
              {selectedEarningType === EarningType.Advance
                && <React.Suspense fallback={<></>}><CoinEarningList /></React.Suspense>}
              {selectedEarningType === EarningType.None
                && <Typography className={classes.informationText} variant="h4">No protocols will be connected</Typography>}
            </Box>
          </>
        )}
        <PageAction>
          <Box className={classes.saveButton}>
            <Button
              tooltip={isAwaitingApproval ? {
                title: (
                  <Box display="flex" gridGap={4} alignItems="center">
                    <SVG width={16} height={16} src="/icons/ic_time.svg" />
                    <Typography variant="h6" paletteColor={600}>Previous change is awaiting for approval</Typography>
                  </Box>
                ),
                enterTouchDelay: 1,
                placement: 'top-end',
                arrow: true,
              } : undefined}
              disabled={(!isReadyToSave && wallet?.registerStep?.interest) || loading || isAwaitingApproval}
              fullWidth={isMobile}
              onClick={handleSaveButton}
              variant="primary"
            >
              Apply
            </Button>
          </Box>
        </PageAction>
        {showSelectModal && (
          <React.Suspense fallback={<></>}>
            <PortfolioConfirmationModal setShowSelectModal={setShowSelectModal} showSelectModal={showSelectModal} />
          </React.Suspense>
        )}
        {showConsentModal && (
          <React.Suspense fallback={<></>}>
            <ConsentModal
              showModal={showConsentModal}
              setShowModal={setShowConsentModal}
              callbackFnc={handleConsentButton}
              onCancel={() => {
                setLoading(false);
              }}
            />
          </React.Suspense>
        )}
        {showCoinEarningConfirmationModal && (
          <React.Suspense fallback={<></>}>
            <CoinEarningConfirmationModal
              showModal={showCoinEarningConfirmationModal}
              setShowModal={setShowCoinEarningConfirmationModal}
              callbackFnc={updateProtocol}
              onCancel={() => {
                setLoading(false);
              }}
            />
          </React.Suspense>
        )}
      </Box>
    </>
  );
};

InterestSetting.routePath = InterestSettingRoute;
InterestSetting.loginRequired = true;
InterestSetting.redirect = 'interestSettingRedirect';

export default InterestSetting;
