import { Box, Hidden, makeStyles } from '@material-ui/core';
import { Typography } from 'elements';
import { PortfolioCoin } from 'models';
import React, { FC, useMemo } from 'react';
import SVG from 'react-inlinesvg';
import { prettyNumber, significantNumber } from 'utils';

import { ActualVsTarget } from './elements';

type PortfolioBarProps = {
  className?: string;
  hasTarget: boolean;
  valueMode: 'percentage' | 'actual';
  portfolio: PortfolioCoin[];
  showOthers?: boolean;
  toggleShowOthers: () => void;
};

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  legend: {
    [theme.breakpoints.up('sm')]: {
      position: 'absolute',
      top: '50%',
      right: 0,
      transform: 'translateY(-50%)',
    },
  },
  headerRow: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    columnGap: 8,
    marginTop: 24,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      marginTop: 0,
      justifyContent: 'start',
    },
  },
  headerText: {
    color: theme.palette.grey['500'],
    fontSize: 14,
    fontWeight: 400,
    lineHeight: '16px',
  },
  tokenHeaderText: {
    [theme.breakpoints.down('xs')]: {
      textTransform: 'uppercase',
    },
  },
  mobileColumn: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'stretch',
    rowGap: 16,

    marginTop: 12,
    width: '100%',
  },
  mobileItem: {
    width: '100%',
  },
  desktopRow: {
    display: 'flex',
    justifyContent: 'start',
    flexDirection: 'row',
    columnGap: 8,

    marginTop: 24,
    width: '100%',
  },
  tokenColumn: {
    display: 'flex',
    flexDirection: 'column',
    rowGap: 20,
    width: 120,
  },
  tokenItem: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    columnGap: 8,
    overflow: 'hidden',
    '& svg': {
      flexShrink: 0,
      borderRadius: 50,
    },
    '& h5': {
      textOverflow: 'ellipsis',
    },
    '&#click': {
      cursor: 'pointer',
    },
  },
  versusColumn: {
    display: 'flex',
    flexDirection: 'column',
    rowGap: 20,
    alignItems: 'flex-end',
    '&#percentage': {
      alignItems: 'center',
    },
  },
  versusItem: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    columnGap: 4,
  },
  versusItemText: {
    fontSize: 12,
    fontWeight: 500,
    lineHeight: '24px',
  },
  percentageWidth: { width: 'fit-content' },
  actualWidth: { width: 'fit-content' },
  chartColumn: {
    flexGrow: 1,
    minWidth: '50%',
    position: 'relative',
    margin: '-40px 0 -36px -24px',
    width: '100%',
  },
  showMore: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    left: '50%',
    width: 32,
    height: 32,
    borderRadius: 16,
    backgroundColor: theme.palette.common.white,
    border: `1px solid ${theme.palette.grey[300]}`,
    marginTop: 16,
    bottom: 0,
    color: theme.palette.grey[400],
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: theme.palette.grey[50],
    },
    '& svg': {
      transition: '400ms',
    },
    '&#expanded': {
      '& svg': {
        transform: 'rotate(180deg)',
      },
    },
  },
}));

const PortfolioBarChart = React.lazy(() => import('./PortfolioBarChart'));
const PortfolioBarItem = React.lazy(() => import('./elements/PortfolioBarItem'));

const PortfolioBar: FC<PortfolioBarProps> = ({
  className, valueMode, portfolio, hasTarget, showOthers, toggleShowOthers,
}) => {
  const classes = useStyles();
  const maxValue = useMemo(
    () => {
      if (!portfolio.length) return 0;
      return portfolio[0].actualPercentage > portfolio[0].targetPercentage
        ? portfolio[0].actualPercentage
        : portfolio[0].targetPercentage;
    },
    [portfolio],
  );

  return (
    <Box className={`${classes.root} ${className}`}>
      {hasTarget && (
        <Hidden smUp>
          <ActualVsTarget />
        </Hidden>
      )}
      <Box className={classes.headerRow}>
        <Typography className={`${classes.headerText} ${classes.tokenHeaderText} ${classes.tokenColumn}`}>
          Token<Hidden smUp>s</Hidden>
        </Typography>
        {valueMode === 'percentage' ? (
          <Typography className={classes.headerText}>
            <Hidden xsDown>Actual %{hasTarget ? ' vs Target' : ''}</Hidden>
            <Hidden smUp>{portfolio?.[0]?.actualPercentage.toFixed(0)}/100% max</Hidden>
          </Typography>
        ) : (
          <Typography className={classes.headerText}>
            USDT
          </Typography>
        )}
        {hasTarget && (
          <Hidden xsDown>
            <ActualVsTarget className={classes.legend} />
          </Hidden>
        )}
      </Box>
      <Hidden smUp>
        <Box className={classes.mobileColumn}>
          <React.Suspense fallback={<></>}>
            {portfolio.map((p, index) => (
              <PortfolioBarItem
                key={index}
                className={classes.mobileItem}
                valueMode={valueMode}
                coin={p}
                maxValue={maxValue}
                hasTarget={hasTarget}
              />
            ))}
          </React.Suspense>
        </Box>
      </Hidden>
      <Hidden xsDown>
        <Box className={classes.desktopRow}>
          <Box className={classes.tokenColumn}>
            {portfolio.map((p, index) => (
              <Box key={index} className={classes.tokenItem} onClick={p.onClick} id={p.onClick ? 'click' : ''}>
                <SVG src={p.icon ?? '/icons/coin_icon/fallback-1.svg'} width={24} height={24} />
                <Typography paletteColor={800} noWrap variant="h5">{p.name}</Typography>
              </Box>
            ))}
          </Box>
          <Box className={classes.versusColumn} id={valueMode === 'percentage' ? 'percentage' : ''}>
            {portfolio.map((p, index) => (
              <Box key={index} className={classes.versusItem}>
                <Box className={valueMode === 'percentage' ? classes.percentageWidth : classes.actualWidth}>
                  <Typography
                    className={classes.versusItemText}
                    palette="green"
                    paletteColor={500}
                  >
                    {valueMode === 'percentage' ? (
                      <>{prettyNumber(p.actualPercentage, { maximumFractionDigits: 2 })}%</>
                    ) : (
                      <></>
                    )}
                  </Typography>
                </Box>
                <Typography className={classes.versusItemText}>
                  {(valueMode === 'percentage' && hasTarget) ? 'vs.' : ''}
                </Typography>
                <Box className={valueMode === 'percentage' ? classes.percentageWidth : classes.actualWidth}>
                  <Typography
                    paletteColor={500}
                    palette={valueMode === 'percentage' ? 'purple' : 'grey'}
                    className={classes.versusItemText}
                  >
                    {valueMode === 'percentage' ? (
                      hasTarget ? <>{prettyNumber(p.targetPercentage, { maximumFractionDigits: 2 })}%</> : <></>
                    ) : (
                      <>{significantNumber(p.price, { maximumFractionDigits: 2 })}</>
                    )}
                  </Typography>
                </Box>
              </Box>
            ))}
          </Box>
          <Box className={classes.chartColumn}>
            <React.Suspense fallback={<></>}>
              <PortfolioBarChart portfolio={portfolio} hasTarget={hasTarget} />
            </React.Suspense>
          </Box>
        </Box>
      </Hidden>
      {showOthers !== undefined && (
        <Box className={classes.showMore} onClick={toggleShowOthers} id={showOthers ? 'expanded' : ''}>
          <SVG src="/icons/dashboard/ic_arrow_down.svg" width={32} height={32} />
        </Box>
      )}
    </Box>
  );
};

PortfolioBar.displayName = 'PortfolioBar';
export { PortfolioBar };
