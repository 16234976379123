import { Box, BoxProps, createStyles, makeStyles, Theme } from '@material-ui/core';
import React from 'react';

export type CardProps = BoxProps & {
  isLoading?: boolean,
  isSection?: boolean,
  noPadding?: boolean,
}

const useStyles = makeStyles<Theme, CardProps>((theme) => createStyles({
  root: {
    position: 'relative',
    display: 'flex',
    padding: ({ noPadding }) => (noPadding ? undefined : '20px 16px'),
    width: '100%',
    height: 'fit-content',
    boxShadow: '0px 4px 32px rgba(58, 60, 77, 0.04)',
    background: theme.palette.common.white,
    borderRadius: ({ borderRadius }) => (borderRadius || 8),

    [theme.breakpoints.down('xs')]: {
      borderRadius: ({ isSection, borderRadius }) => (borderRadius || (isSection ? 0 : 8)),
    },
  },
  overlay: {
    zIndex: 5,
    position: 'absolute',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,

    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',

    background: theme.palette.common.white,
    borderRadius: 8,
    opacity: 0.8,
  },
}));

const Card = (props: CardProps) => {
  const { isLoading, isSection, borderRadius, noPadding, className, children, ...rest } = props;
  const classes = useStyles({ isSection, borderRadius, noPadding });

  return (
    <Box className={`${classes.root} ${className}`} {...rest}>
      {children}
      {isLoading && (
        <Box className={classes.overlay}>
          <Box className="sk-spinner-pulse" />
        </Box>
      )}
    </Box>
  );
};

Card.defaultProps = {
  isLoading: false,
  isSection: false,
};

export { Card };
