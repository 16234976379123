import { Box, makeStyles } from '@material-ui/core';
import { Typography } from 'elements';
import React, { useCallback } from 'react';
import { Link } from 'react-router-dom';
import { PrivacyPolicyRoute } from 'routes';

const useStyles = makeStyles((theme) => ({
  privacyPolicyContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'stretch',
    marginTop: 32,

    [theme.breakpoints.up('md')]: {
      margin: '32px auto 0',
      maxWidth: 720,
    },

    '& h2': {
      fontSize: 16,
      fontWeight: 600,
      lineHeight: '24px',

      [theme.breakpoints.up('md')]: {
        fontSize: 20,
        lineHeight: '32px',
      },
    },

    '& span': {
      '&#indent': {
        display: 'block',
        marginLeft: 12,
        [theme.breakpoints.up('md')]: {
          marginLeft: 20,
        },
      },
    },
  },

  updateText: {
    color: theme.palette.grey[400],
    fontSize: 14,
    lineHeight: '24px',
    textTransform: 'uppercase',
  },
  privacyPolicyText: {
    fontSize: 12,
    fontWeight: 500,
    lineHeight: '20px',

    [theme.breakpoints.up('md')]: {
      fontSize: 16,
      lineHeight: '24px',
    },
  },

  listItem: {
    display: 'flex',
    alignItems: 'start',
    columnGap: 10,
  },
  listNumber: {
    flexShrink: 0,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',

    width: 20,
    height: 20,

    color: 'white',
    fontSize: 12,
    fontWeight: 500,
    lineHeight: '18px',

    background: theme.palette.green[600],
    borderRadius: 9999,
  },
}));

const PrivacyPolicy = () => {
  const classes = useStyles();

  const ListItem = useCallback(({ num, children }: any) => (
    <Box className={classes.listItem}>
      <Box className={classes.listNumber}>{num}</Box>
      {children}
    </Box>
  ), []);

  return (
    <>
      <Typography variant="h1">
        <em>Coinbag</em> Privacy Policy
      </Typography>

      <Box className={classes.privacyPolicyContainer}>
        <Typography className={classes.updateText}>
          Last updated: September 16, 2022
        </Typography>

        <Typography className={classes.privacyPolicyText}>
          <br />
          Coinbag puts transparency first. If at any time, you have any questions regarding our terms of use or
          privacy policy, please contact us directly at{' '}
          <a href="mailto:admin@coinbag.finance">admin@coinbag.finance</a> and we will respond within 1 working day.

          <br /><br />
          This Privacy Policy describes your privacy rights concerning our collection, use, storage, sharing, and
          protection of your personal information in accordance with the Terms of Use by virtue of which Privacy terms
          are incorporated by reference. The full Terms of Use are accessible at the following link{' '}
          <Link to="/terms-of-use">https://www.coinbag.finance/terms-of-use</Link>. It applies to the Coinbag website
          (site) and any related sites, applications, services and tools where this policy is referenced, regardless
          of how you access or use them, including on mobile devices.

          <br /><br />
          By using Coinbag.com and its related sites, applications, services and tools, and/or registering for a
          Coinbag account, you expressly consent to our collection, use, disclosure, and retention of your personal
          information as described in this Privacy Policy, the Terms of USe and in any subsequent user agreement.

          <br /><br />
          You are contracting with Coinbag, and this Privacy Policy forms part of the Terms of Use.

          <br /><br />
          Throughout this Privacy Policy we use the term “personal information” to describe information that can be
          associated with a specific person and could be used to identify that person. We do not consider personal
          information to include information that has been made anonymous or aggregated so that it can no longer be
          used, whether in combination with other information or otherwise, to identify a specific person.

          <br /><br />
          We may amend this Privacy Policy at any time by posting the amended terms on this site. All amended terms
          automatically take effect 30 days after they are initially posted on the site. In addition, we will notify you
          via email of any material changes to this Privacy Policy.

          <br /><br />
          We may offer you the ability to connect with our site(s), or use our applications, services and tools using a
          mobile device, either through a mobile application or via a mobile optimised website. The provisions of this
          Privacy Policy apply to all such mobile access and use of mobile devices.

          <br /><br />
          You can browse our site without telling us who you are or revealing any personal information about yourself.
          Personal information is only associated to your account when you become a registered user via login and
          password. If you register for an account with us, you give us your personal information, and you are not
          anonymous to us. If you provide us with personal information, you consent to us storing that information on
          our servers.

          <br /><br />
          We collect the following types of personal information in order to provide you with access to and use of our
          site(s), applications, services and tools, and to help us personalise and improve your experience:

          <br /><br />
          <Typography variant="h2">Information we collect automatically:</Typography>

          <br />
          We collect and store any information you enter on our site or you provide to us in the context of using our
          site, applications, services or tools. This may include, but is not limited to:

          <br /><br />
          <span id="indent">
            <em>Mobile:</em> When you download or use any Mobile Applications, or access our mobile optimised site, we
            may receive information about your location and your mobile device, including a unique identifier for your
            device.  We may use this information to provide you with location based services, such as advertising and
            other personalised content. Most mobile devices enable you to control or disable location services in the
            device’s settings menu. If you have questions about how to disable your device’s location services, we
            recommend you contact your mobile service carrier or the manufacturer of your device.
          </span>

          <br />
          <span id="indent">
            <em>Information from other sources:</em> We may receive or collect supplemental information about you from
            third party sources and add it to your account information. This may include, but not limited to,
            demographic, navigation information, additional contact information, credit check information, and
            additional information about you from other sources, as permitted by law.
          </span>

          <br />
          The primary purpose in collecting personal information is to provide you with access to our site, and/or use
          our services, applications and tools, provide you with customer support and relevant information about your
          account and our services, and to provide you with a safe, smooth, efficient and customised experience.

          <br /><br />
          By continuing to access our site, applications, services and tools, you agree that we may use your personal
          information as follows:

          <br /><br />
          <ListItem num={1}>
            Enable you to control how and when you receive notifications from us about your buying and selling
            activities through the site.
          </ListItem>

          <br />
          <ListItem num={2}>
            We do not sell or rent your personal information to third parties. We may combine your information with
            information collected from other customers and sources and use it to improve and personalise our services,
            content and advertising (if any).
          </ListItem>

          <br />
          <ListItem num={3}>
            We may disclose personal information to respond to legal requirements, enforce our policies, or to protect
            anyone’s rights, property or safety. Such information shall be disclosed in accordance with this Privacy
            Policy and applicable laws and regulations.
          </ListItem>

          <br />
          <ListItem num={4}>
            To help detect and prevent potentially illegal acts, violations of our policies, fraud, and/or data security
            breaches, and to guide decisions about our products, site, applications, services and tools and
            communications.
          </ListItem>

          <br />
          <ListItem num={5}>
            Service providers under contract who help our business operations, such as but not limited to, fraud
            investigations, site operations etc).
          </ListItem>

          <br />
          <ListItem num={6}>
            Law enforcement, governmental agencies, or authorised third parties in response to a verified request
            relating to a criminal investigation or alleged illegal activity or any other activity that may expose us,
            you or any other registered user to legal liability. In such events, we will only disclose information
            relevant and necessary to the investigation or inquiry, such as name, city, state, zip code, telephone
            number, email address, user ID history, IP address, complaints, transaction history and anything else we may
            deem relevant to the investigation.
          </ListItem>

          <br />
          <ListItem num={7}>
            Other business entities should we plan to merge with or be acquired by that business entity. Should such a
            transaction occur, we will require that the new combined entity follow this Privacy Policy with respect to
            your personal information. If your personal information will be used contrary to this Privacy Policy, you
            will receive prior notice.
          </ListItem>

          <br />
          Without limiting the above, in an effort to respect your privacy, we will not otherwise disclose your personal
          information to law enforcement, other government officials, or other third parties without a subpoena, court
          order or substantially similar legal procedure, except when we believe in good faith that the disclosure of
          information is necessary to prevent imminent physical harm, financial loss or to report suspected illegal
          activity.

          <br /><br />
          It is not recommended that you access our site, applications, services or tools from a shared computer or a
          computer in an internet café or similar. If you do, certain information about you may also be visible to other
          individuals who use the same computer after you.

          <br /><br />
          When you visit or interact with our site, services, applications and tools, we or our authorised service
          providers may use cookies, web beacons, and other similar technologies for storing information to help provide
          you with a better, faster and safer experience.

          <br /><br />
          Your password is the key to your account. Establish a password in accordance with the site requirements and do
          not disclose it to anyone. If you do share your password or your personal information with others, you are
          responsible for all actions taken in the name of your account. If you lose control of your password, you may
          lose control over your personal information and may be subject to legal binding actions taken on your behalf.
          Therefore, if your password has been compromised for any reason, you should immediately notify us and change
          your password.

          <br /><br />
          You can see, review and change most of your personal information by signing on to your account. Generally, we
          will not manually modify your personal information because it is very difficult to verify your identity
          remotely. You must promptly update your personal information if it changes or is inaccurate. Upon your
          request, we will close your account and remove your personal information as soon as reasonably possible in
          accordance with applicable law. We do retain personal information from closed accounts to comply with law,
          prevent fraud, collect any fees owed, resolve disputes, troubleshoot problems, assist with any investigations,
          enforce our User Agreement, and take other actions otherwise permitted by law.

          <br /><br />
          We store and process your personal information on our servers. We protect your information using technical and
          administrative security measures to reduce the risk of loss, misuse, unauthorised access, disclosure and
          alteration. Some of the safeguards we use are firewalls and data encryption, physical access controls to our
          data centers and information access authorisation controls.

          <br /><br />
          Except as otherwise expressly included in this Privacy Policy, this document addresses only the use and
          disclosure of information we collect from you. If you disclose your information to others, different rules may
          apply to their use or disclosure of the information you provide to them.

          <br /><br />
          If you have any queries concerning this Privacy Policy you may email us at{' '}
          <a href="mailto:admin@coinbag.finance">admin@coinbag.finance</a> and we will respond within 1 working day.
        </Typography>
      </Box>
    </>
  );
};

PrivacyPolicy.routePath = PrivacyPolicyRoute;
PrivacyPolicy.loginRequired = false;
export default PrivacyPolicy;
