import { Box, BoxProps, createStyles, makeStyles, Theme, Typography, useMediaQuery } from '@material-ui/core';
import React from 'react';
import { Link } from 'react-router-dom';

import { Card } from '../../elements';
import { breakpoints } from '../../theme/base';

type NotesBannerProps = BoxProps & {
  description: string
  showNote?: boolean

  actionText?: string
  actionLink?: string
  actionClicked?: () => void
}

const useStyles = makeStyles<Theme, NotesBannerProps>((theme) => createStyles({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',

    padding: 12,
    width: '100%',

    border: '1px solid',
    borderColor: theme.palette.grey['200'],

    textAlign: 'center',

    [breakpoints.up('sm')]: {
      textAlign: 'left',
      padding: 24,
      flexDirection: 'row',
    },
  },

  lampIcon: {
    filter: 'invert(89%) sepia(29%) saturate(5605%) hue-rotate(297deg) brightness(104%) contrast(119%)',
    margin: '0 0 5px 0',
    [breakpoints.up('sm')]: {
      margin: 'inherit',
    },
  },

  noteText: {
    marginLeft: 8,
    color: theme.palette.grey['500'],
  },
  descriptionText: {
    marginRight: 8,
    marginLeft: ({ showNote }) => (showNote ? 4 : 8),
  },

  actionLink: {
    flexShrink: 0,
    margin: '5px auto 0',
    [breakpoints.up('sm')]: {
      margin: '0 0 0 auto',
    },

    color: theme.palette.green['600'],
    cursor: 'pointer',
  },
}));

const NotificationBanner = (props: NotesBannerProps) => {
  const { description, showNote, actionText, actionLink, actionClicked, className, ...rest } = props;

  const classes = useStyles(props);
  const isLarge = useMediaQuery(breakpoints.up('xl'));

  const isExternal = actionLink && actionLink.startsWith('http');

  return (
    <Card className={`${classes.root} ${className}`} {...rest}>
      <img className={classes.lampIcon} alt="" src="/icons/dashboard/ic_lamp.svg" />

      {showNote && <Typography variant={isLarge ? 'h5' : 'h6'} className={classes.noteText}>Note: </Typography>}
      <Typography variant={isLarge ? 'h5' : 'h6'} className={classes.descriptionText}>{description}</Typography>

      {actionLink && !isExternal && (
        <Link className={classes.actionLink} to={actionLink}>
          <Typography variant={isLarge ? 'h5' : 'h6'}>{actionText}</Typography>
        </Link>
      )}

      {actionLink && isExternal && (
        <a className={classes.actionLink} href={actionLink} target="_blank" rel="noreferrer">
          <Typography variant={isLarge ? 'h5' : 'h6'}>{actionText}</Typography>
        </a>
      )}

      {actionClicked && (
        <Box className={classes.actionLink} onClick={actionClicked}>
          <Typography variant={isLarge ? 'h5' : 'h6'}>{actionText}</Typography>
        </Box>
      )}
    </Card>
  );
};

NotificationBanner.defaultProps = {
  showNote: false,
};

export { NotificationBanner };
