import { Box, createStyles, makeStyles, Typography } from '@material-ui/core';
import { Button } from 'elements';
import LightModal from 'elements/LightModal';
import React, { useCallback, useState } from 'react';

import RevokeAccessDialog from './RevokeAccessDialog';

const useStyles = makeStyles(() => createStyles({
  button: {
    display: 'flex',
    flexWrap: 'nowrap',
    gap: '8px',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer',
    '& img': {
      width: '18px',
      height: '18px',
    },
  },
}));

export const RevokeAccessButton = () => {
  const classes = useStyles();

  const [showModal, setShowModal] = useState(false);
  const handleOnClose = useCallback(() => setShowModal(false), [setShowModal]);

  return (
    <>
      <Button variant="primary" size="sm" color="red" onClick={() => setShowModal(true)}>
        <Box className={classes.button}>
          <img src="icons/account_details/icon_revoke.svg" alt="revoke" />
          <Typography variant="h6">Revoke Access</Typography>
        </Box>
      </Button>
      <LightModal maxWidth={380} open={showModal} setOpen={setShowModal} showCloseButton noPadding>
        <RevokeAccessDialog onClose={handleOnClose} />
      </LightModal>
    </>
  );
};
