import { Box, makeStyles } from '@material-ui/core';
import { useWallets } from '@web3-onboard/react';
import { Typography } from 'elements';
import { getWalletProviderIcon } from 'pages/responsive/WalletSetting/components/WalletTypes';
import React, { FC, useMemo } from 'react';
import SVG from 'react-inlinesvg';
import { isAddressSupported, WalletStatus } from 'services/OnboardService';
import { WalletInfo } from 'services/types/wallet';
import generateRandomString from 'utils/generateRandomString';

type WalletRowProps = {
  selected?: boolean;
  walletInfo: WalletInfo;
  onClick?: () => void;
  className?: string;
};

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    columnGap: 4,
    cursor: 'pointer',
  },
  walletIcon: {
    width: 24,
    height: 24,
    borderRadius: 4,
    boxShadow: '0px 4px 12px rgba(110, 123, 162, 0.12)',
    position: 'relative',
    overflow: 'hidden',
    '& img': {
      borderRadius: 4,
      width: 24,
      height: 24,
    },
    '& object': {
      borderRadius: 4,
      width: 24,
      height: 24,
    },
  },
  walletOrder: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: 16,
    height: 16,
    color: theme.palette.common.white,
    fontSize: 12,
    fontWeight: 500,
    background: theme.palette.blue[500],
    borderRadius: 10,
  },
  selectedBadge: {
    flexShrink: 0,
    marginLeft: 'auto',
    width: 20,
    height: 20,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    '& svg': {
      flexShrink: 0,
      margin: -2,
      '& path': {
        stroke: theme.palette.blue[500],
      },
    },
  },
  divider: { color: theme.palette.grey[300], fontSize: 12 },
  hashtag: { fontSize: 12 },
  walletConnected: {
    width: 7,
    height: 7,
    position: 'absolute',
    top: 0,
    right: -1,
    backgroundColor: theme.palette.green[500],
    border: '1px solid white',
    borderRadius: 3,
  },
}));

const WalletRow: FC<WalletRowProps> = ({ selected, walletInfo, onClick, className }) => {
  const classes = useStyles();
  const connectedWallets = useWallets();

  const walletIcon = React.useMemo(() => {
    return getWalletProviderIcon(walletInfo?.walletProvider, walletInfo?.isNonCustodian);
  }, [walletInfo, connectedWallets]);

  const isWalletConnected = useMemo(() => (
    walletInfo.isNonCustodian
    && walletInfo.address
    && isAddressSupported(walletInfo.address[0] ?? '') !== WalletStatus.No), [walletInfo, connectedWallets]);

  return (
    <Box
      className={`${classes.root} ${className || ''}`}
      onClick={onClick}
    >
      <Box className={classes.walletIcon}>
        <img
          alt={walletInfo.name}
          src={`/icons/wallet_setting/${walletIcon}_sm.svg?${generateRandomString()}`}
        />
        {isWalletConnected && (<div className={classes.walletConnected} />)}
      </Box>
      <Typography variant="h4">{walletInfo?.name ?? walletInfo?.walletProvider}</Typography>
      <Typography className={classes.divider}>|</Typography>
      <Typography className={classes.hashtag}>#</Typography>
      <Typography className={classes.walletOrder}>{walletInfo?.order}</Typography>
      {selected && (
        <Box className={classes.selectedBadge}>
          <SVG src="/icons/icon_check.svg" width={24} height={24} />
        </Box>
      )}
    </Box>
  );
};

WalletRow.displayName = 'WalletRow';
export { WalletRow };
