import { WalletProvider } from 'services/types/wallet';

export interface WalletItem {
  type: WalletProvider;
  name: string;
  available: boolean;
}

export const walletItems: WalletItem[] = [
  {
    type: 'Cybavo',
    name: 'Cybavo',
    available: true,
  },
  {
    type: 'Atato',
    name: 'Atato',
    available: true,
  },
  {
    type: 'Reporting',
    name: 'Read-only',
    available: true,
  },
  {
    type: 'MPC',
    name: 'MPC',
    available: false,
  },
  {
    type: 'Other',
    name: 'Other',
    available: false,
  },
];

export const getWalletProviderIcon = (type?: WalletProvider, isNonCustodian?: boolean) => {
  switch (type) {
    case 'Cybavo':
      return 'ic_cybavo';
    case 'Atato':
      return 'ic_atato';
    case 'MPC':
      return 'ic_mpc';
    case 'Reporting':
      return isNonCustodian ? 'ic_self_custody' : 'ic_reporting';
    case 'Other':
      return 'ic_more';
    default:
      return 'ic_other';
  }
};

export const LicensedCustodians = [{
  title: 'Cybavo' as WalletProvider,
  icons: ['/icons/wallet_setting/ic_cybavo.svg'],
  comingSoon: false,
}, {
  title: 'Atato' as WalletProvider,
  icons: ['/icons/wallet_setting/ic_atato.svg'],
  comingSoon: true,
}];
