import { Box, createStyles, Hidden, makeStyles, Theme, useMediaQuery } from '@material-ui/core';
import { Button, Card, Typography } from 'elements';
import React, { FC, useCallback, useState } from 'react';
import SVG from 'react-inlinesvg';
import { breakpoints } from 'theme/base';

type ClientsProps = {
  type?: 'individual' | 'institutional'
}

const useStyles = makeStyles<Theme, ClientsProps>((theme: Theme) => createStyles({
  root: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    gap: 20,
    width: '100%',
    color: theme.palette.grey[700],

    [theme.breakpoints.up('md')]: {
      gap: 40,
      '&::after': {
        content: '" "',
        position: 'absolute',
        width: 1500,
        height: 600,

        background: ({ type }) => (
          type === 'individual'
            ? 'radial-gradient(50% 50% at 50% 50%, rgba(46, 255, 218, 0.315) 0%, rgba(255, 255, 255, 0) 100%)'
            : 'radial-gradient(50% 50% at 50% 50%, rgba(110, 158, 254, 0.315) 0%, rgba(255, 255, 255, 0) 100%)'
        ),
        filter: 'blur(54px)',
        backfaceVisibility: 'hidden',
        perspective: 1000,
        transform: 'translateZ(0)',
      },
    },
    '& em': {
      color: ({ type }) => (type === 'individual' ? theme.palette.green[600] : theme.palette.blue[500]),
      fontStyle: 'normal',
    },
  },
  title: {
    zIndex: 1,
    fontSize: 44,
    fontWeight: 700,
    lineHeight: '66px',
    textAlign: 'center',
    [theme.breakpoints.down('sm')]: {
      fontSize: 24,
      lineHeight: '44px',
    },
  },
  clientsWrapper: {
    zIndex: 1,
    display: 'grid',
    gridTemplateColumns: '1fr 1fr 1fr',
    columnGap: 24,
    rowGap: 40,
    width: '100%',

    [theme.breakpoints.down('sm')]: {
      gridTemplateColumns: '1fr',
      gap: 16,
    },
  },
  clientCard: {
    display: 'flex',
    flexDirection: 'column',
    gap: 16,

    padding: 18,
    height: '100%',
    width: '100%',
    background: 'white',
    borderRadius: 32,
    boxShadow: '0px 4px 32px rgba(135, 138, 165, 0.24)',
    [theme.breakpoints.down('sm')]: {
      borderRadius: 24,
      padding: 14,
    },
  },
  clientImage: {
    flexShrink: 0,
    width: 52,
    height: 52,
    objectFit: 'cover',
    userDrag: 'none',
    userSelect: 'none',
    borderRadius: 12,
    filter: 'drop-shadow(0px 4px 32px rgba(135, 138, 165, 0.24))',
    [theme.breakpoints.down('sm')]: {
      width: 48,
      height: 48,
    },
  },
  clientAvatar: {
    display: 'flex',
    flexWrap: 'nowrap',
    gap: 12,
    alignItems: 'center',
  },
  clientInfo: {
    display: 'flex',
    flexDirection: 'column',
    gap: 8,
  },
  clientName: {
    fontSize: 20,
    fontWeight: 500,
    lineHeight: '28px',
    [theme.breakpoints.down('sm')]: {
      fontSize: 16,
      lineHeight: '24px',
    },
  },
  clientCompany: {
    color: theme.palette.grey[500],
    fontSize: 12,
    lineHeight: '16px',
    textTransform: 'uppercase',
  },
  clientReview: {
    color: theme.palette.grey[700],
    fontSize: 16,
    fontWeight: 500,
    lineHeight: '28px',
  },
  clientMessage: {
    color: theme.palette.grey[600],
    fontSize: 12,
    fontWeight: 500,
    lineHeight: '20px',
  },
  showMore: {
    marginTop: 8,
    color: ({ type }) => (type === 'individual' ? theme.palette.green[600] : theme.palette.blue[500]),
    fontSize: 14,
    fontWeight: 500,
    lineHeight: '24px',
    textAlign: 'center',
  },
  rotate: { transform: 'rotate(180deg)' },
}));

interface ClientInfo {
  name: string;
  position: string;
  title: string;
  detail: string;
}

const clientsInfos: ClientInfo[] = [
  {
    name: 'Mitch',
    position: 'Business Owner',
    title: 'I\'ll be a Coinbagger for years to come!!',
    detail: 'I know a bit about crypto, but not enough to confidently invest significant sums. Coinbag allows me to set my risk level, then automatically diversifies my crypto holdings based on it. It provides everything for the 95% of us who don\'t spend every second learning about crypto and watching the markets. I\'m extremely happy with every aspect of the platform so far.',
  },
  {
    name: 'Vitaly',
    position: 'IT Project Manager',
    title: 'Great app to get started with crypto',
    detail: 'A friend recommended Coinbag as a way to invest in crypto without needing to dig deep into the technical stuff. I found the set up super easy. I like the clean look of the dashboard showing only the necessary stuff. Keep up the good work guys.',
  },
  {
    name: 'Radka',
    position: 'Finance Manager',
    title: 'Stress-free crypto investments',
    detail: 'Coinbag allows me to take a hands off approach to my investments with the safety of knowing that my portfolio will keep performing or even outperforming my portfolio goals. Simple and easy to use with intuitive dashboard allowing you to keep track of your investments.',
  },
  {
    name: 'Thanyathorn',
    position: 'Business Owner',
    title: 'Perfect platform for crypto newbies',
    detail: 'This platform is perfect for whoever wants to get into crypto but doesn’t know what to buy or where to start. In a few months, my portfolio earned over half of the original money I put in. Thank you coinbag!',
  },
  {
    name: 'Ratchapol',
    position: 'Senior Software Developer',
    title: 'Coinbag helps me achieve my investment goals',
    detail: 'Coinbag helps me achieve my investment goals without spending time trading by myself. Coinbag also receives my feedback and made an improvement based on it. Just try it and you will love it.',
  },
  {
    name: 'Zack',
    position: 'Baseball Coach',
    title: '10/10 Would recommend to a friend',
    detail: 'I was always intimidated to invest in crypto. Someone I know and trust has been investing in crypto for a while and recommended Coinbag as a simple and safe way to invest. The setup was easy and I felt that my information was secure the whole time. Now I simply add more money to my account each month, and I am finally in the crypto game :)',
  },
];

const Clients: FC<ClientsProps> = ({ type = 'individual' }) => {
  const classes = useStyles({ type });
  const [showMore, setShowMore] = useState(false);
  const isMobile = useMediaQuery(breakpoints.down('sm'));

  const ClientCard = useCallback(({ name, position, title, detail }: ClientInfo) => (
    <Card className={classes.clientCard} component="article">
      <figure className={classes.clientAvatar}>
        <img className={classes.clientImage} src={`/images/testimonials/${name}.jpg`} alt={`Coinbag client (${name})`} />
        <Box className={classes.clientInfo}>
          <Typography className={classes.clientName} variant="h3">{name}</Typography>
          <Typography className={classes.clientCompany} strong={false}>{position}</Typography>
        </Box>
      </figure>
      <Box className={classes.clientInfo}>
        <Typography variant="caption" className={classes.clientReview}>{title}</Typography>
        <Typography variant="body2" className={classes.clientMessage}>{detail}</Typography>
      </Box>
    </Card>
  ), []);

  return (
    <section itemScope className={classes.root}>
      <Typography className={classes.title} variant="h2" align="center">
        <em>Trusted</em> by our clients
      </Typography>

      <Box className={classes.clientsWrapper}>
        {clientsInfos.slice(0, isMobile && !showMore ? 3 : 6).map((info, index) => (
          <ClientCard {...info} key={index} />
        ))}
      </Box>
      <Hidden mdUp>
        <Button onClick={() => setShowMore((prev) => !prev)} variant="icon">
          <Typography className={classes.showMore}>
            {showMore ? 'Hide' : 'Show more'} testimonials{' '}
            <SVG className={showMore ? classes.rotate : ''} src="/icons/icon_arrow_down.svg" height={24} width={24} />
          </Typography>
        </Button>
      </Hidden>
    </section>
  );
};

export default Clients;
