import { coinConfigToMap, getCurrentProtocol, getProtocols } from 'services/protocol-service';
import { Protocol } from 'services/types/protocol';
import { CoinConfig } from 'services/types/wallet';
import { create } from 'zustand';

export enum EarningType {
  Basic,
  Advance,
  None,
}

interface InterestEarningStore {
  protocols: Protocol[];
  fetchProtocols: () => Promise<Protocol[]>;
  currentProtocol?: Protocol;
  currentProtocolId?: number;
  currentCoinConfig?: CoinConfig[];
  currentEarningType?: EarningType;
  // eslint-disable-next-line no-unused-vars
  fetchCurrentProtocolIds: (forceReload: boolean) => Promise<{
    currentProtocolId?: number,
    currentCoinConfig?: CoinConfig[],
    currentEarningType?: EarningType,
  }>;
  fetchCurrentProtocol: (forceReload: boolean) => Promise<Protocol | undefined>;
  clearCurrentProtocol: () => void;
}

export const useStore = create<InterestEarningStore>((set, get) => ({
  protocols: [],
  currentProtocolId: undefined,
  currentCoinConfig: undefined,
  currentProtocolType: undefined,

  fetchProtocols: async () => {
    if (get().protocols.length === 0) {
      const protocols = await getProtocols();
      set({ protocols });
      return protocols;
    }
    return get().protocols;
  },
  fetchCurrentProtocolIds: async (forceReload = false) => {
    const { currentProtocolId, currentCoinConfig, currentEarningType } = get();
    if (
      (currentProtocolId === undefined && currentCoinConfig === undefined && currentEarningType === undefined)
      || forceReload
    ) {
      const { protocolId, coinConfig } = await getCurrentProtocol();
      set({ currentProtocolId: protocolId, currentCoinConfig: coinConfig });

      // set type
      let type;
      if (protocolId !== undefined) {
        type = EarningType.Basic;
      } else if (coinConfig !== undefined) {
        type = EarningType.Advance;
      } else {
        type = EarningType.None;
      }
      set({ currentEarningType: type });
      return { currentProtocolId: protocolId, currentCoinConfig: coinConfig, currentEarningType: type };
    }
    return { currentProtocolId, currentCoinConfig, currentEarningType };
  },
  fetchCurrentProtocol: async (forceReload = false) => {
    const { currentProtocol, fetchCurrentProtocolIds, fetchProtocols } = get();
    if (currentProtocol !== undefined && !forceReload) return currentProtocol;
    const protocols = await fetchProtocols();
    const { currentProtocolId } = (await fetchCurrentProtocolIds(forceReload));
    // eslint-disable-next-line max-len
    const fetchedCurrentProtocol = currentProtocolId ? protocols.find((p) => p.protocolId === currentProtocolId) : undefined;
    set({ currentProtocol: fetchedCurrentProtocol });
    return fetchedCurrentProtocol;
  },
  clearCurrentProtocol: () => {
    set({ currentProtocol: undefined, currentProtocolId: undefined, currentCoinConfig: undefined, currentEarningType: undefined });
  },
}));

export const isEqualCoinConfig = (current: CoinConfig[], select: Record<number, number>) => {
  if (current.length !== Object.keys(select).length) return false;
  const currentMap = coinConfigToMap(current);

  for (let i = 0; i < current.length; i += 1) {
    const key = current[i].masterCoinId;
    if (currentMap[key] !== select[key]) return false;
  }
  return true;
};
