import { Box, createStyles, Hidden, Popover, useMediaQuery } from '@material-ui/core';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { useConnectWallet } from '@web3-onboard/react';
import { Loading } from 'components';
import { Button, LightTooltip, Typography } from 'elements';
import { Scrollable } from 'elements/Scrollable';
import React, { useEffect, useMemo, useState } from 'react';
import SVG from 'react-inlinesvg';
import { isAddressSupported, showAccountCenter, WalletStatus } from 'services/OnboardService';
import { WorkItemName, WorkItemStatus } from 'services/types/workItem';
import { useStore as useCoinsStore } from 'store/zustand/Coin';
import { useStore as usePayrollStore } from 'store/zustand/Payroll';
import { useStore as useTransactionStore } from 'store/zustand/Transaction';
import { useStore as useWalletStore } from 'store/zustand/Wallet';
import { useStore as useWorkItemStore } from 'store/zustand/WorkItem';

import { AddPayrollModal } from './AddPayrollModal';
import { checkInsufficientGas, ConfirmationModal } from './ConfirmationModal';
import { EstimateFee, findTotalFeeUsd, groupUsdFeesByChain } from './EstimateFee';
import { OptionMenu } from './OptionMenu';
import { PayrollHeader } from './PayrollHeader';
import { PayrollRow } from './PayrollRow';

const useStyles = makeStyles((theme) => createStyles({
  root: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    boxShadow: '0px 4px 32px rgba(58, 60, 77, 0.04)',
    flexGrow: 1,
    [theme.breakpoints.up('md')]: {
      borderRadius: 16,
      position: 'relative',
    },
  },
  headerText: {
    [theme.breakpoints.up('md')]: {
      gridColumn: '1 / span 5',
      padding: '18px 0 18px 16px',
      display: 'flex',
      alignItems: 'center',
      '& h2': {
        fontWeight: 500,
        fontSize: 16,
        lineHeight: '24px',
        color: theme.palette.grey[600],
      },
    },
    [theme.breakpoints.down('sm')]: {
      gridColumn: 2,
      '& h2': {
        fontSize: 14,
        lineHeight: '24px',
        color: theme.palette.grey[500],
      },
    },
  },
  addAddress: {
    padding: 16,
    display: 'flex',
    gridColumn: '6 / end',
    justifyContent: 'flex-end',
    alignItems: 'center',
    gap: 20,
  },
  addAddressButton: {
    display: 'flex',
    gap: 8,
    alignItems: 'center',
    padding: '2px 8px 2px 10px',
  },
  plusIconWrapper: {
    display: 'flex',
    width: 18,
    height: 18,
    marginLeft: 8,
    flexShrink: 0,
    '& path': {
      stroke: theme.palette.grey[500],
      [theme.breakpoints.down('sm')]: {
        stroke: theme.palette.common.white,
      },
    },
  },
  dataGrid: {
    display: 'grid',
    width: '100%',
    gridTemplateColumns: '40px 3fr 1.2fr 80px 1fr 28px 0.7fr 56px',
    [theme.breakpoints.down('sm')]: {
      gridTemplateColumns: '40px auto auto 56px',
      gridTemplateAreas: `
      "i a a b"
      "c c d d"
      "e e f f"
      `,
    },
  },
  columnHeader: {
    display: 'flex',
    alignItems: 'center',
    alignSelf: 'center',
    background: '#FBFCFF',
    padding: '6px 0 6px 8px',
    borderTop: '1px solid',
    borderTopColor: theme.palette.grey[200],
    height: '100%',
    width: '100%',
    '&#checkbox': {
      [theme.breakpoints.up('md')]: {
        padding: '8px 0 8px 16px',
      },
      [theme.breakpoints.down('sm')]: {
        gridColumn: 1,
        gridRow: 1,
        padding: '14px 0 14px 12px',
      },
    },
    '&#amount': {
      justifyContent: 'flex-end',
      width: '100%',
    },
    '&#usdt': {
      gridColumn: 'span 2',
      justifyContent: 'flex-end',
      width: '100%',
      [theme.breakpoints.up('md')]: {
        paddingRight: 56,
      },
    },
    '&#addAddress': {
      padding: '12px 16px 12px 0',
      gridColumn: '3 / span 2',
    },
  },
  gridCell: {
    display: 'flex',
    gap: 8,
    alignItems: 'center',
    borderTop: '1px solid',
    borderTopColor: theme.palette.grey[200],
    padding: '14px 0 14px 8px',
    height: '100%',
    minWidth: 0,
    overflow: 'hidden',
    [theme.breakpoints.down('sm')]: {
      padding: '6px 0 6px 8px',
    },
    '&#checkbox': {
      [theme.breakpoints.up('md')]: {
        paddingLeft: 16,
      },
      [theme.breakpoints.down('sm')]: {
        paddingTop: 8,
        paddingLeft: 12,
        gridArea: 'i',
      },
    },
    '&#name': {
      [theme.breakpoints.up('md')]: {
        paddingRight: 16,
      },
      [theme.breakpoints.down('sm')]: {
        gridArea: 'a',
        paddingTop: 8,
      },
    },
    '&#chain': {
      [theme.breakpoints.down('sm')]: {
        gridArea: 'c',
        borderTop: 'none',
        paddingLeft: 16,
      },
      '& h4': {
        overflow: 'hidden',
        textOverflow: 'ellipsis',
      },
    },
    '&#address': {
      [theme.breakpoints.down('sm')]: {
        justifyContent: 'flex-end',
        gridArea: 'd',
        paddingRight: 16,
        borderTop: 'none',
      },
    },
    '&#coin': {
      [theme.breakpoints.down('sm')]: {
        gridArea: 'e',
        borderTop: 'none',
        paddingLeft: 16,
        paddingBottom: 16,
      },
    },
    '&#coin_value': {
      justifyContent: 'flex-end',
      [theme.breakpoints.down('sm')]: {
        gridArea: 'f',
        borderTop: 'none',
        paddingRight: 16,
        paddingBottom: 12,
      },
    },
    '&#equal': {
      justifyContent: 'center',
    },
    '&#usdt_value': {
      justifyContent: 'flex-end',
    },
    '&#menu': {
      justifyContent: 'center',
      alignItems: 'center',
      [theme.breakpoints.up('md')]: {
        padding: '14px 16px',
      },
      [theme.breakpoints.down('sm')]: {
        gridArea: 'b',
        paddingTop: 8,
      },
    },
  },
  dataGridHeaderWrapper: {
    display: 'flex',
    width: '100%',
  },
  dataGridWrapper: {
    display: 'flex',
    width: '100%',
    flexGrow: 1,
  },
  scrollable: {
    flexDirection: 'column',
    display: 'flex',
    width: '100%',
    position: 'relative',
    minHeight: 300,
  },
  scrollContent: {
    width: '100%',
  },
  summarySection: {
    borderTop: '1px solid',
    borderTopColor: theme.palette.grey[200],
    padding: '16px 16px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    background: '#FBFCFF',
    [theme.breakpoints.down('sm')]: {
      background: theme.palette.common.white,
      position: 'sticky',
      bottom: 0,
    },
  },
  estimateFeeWrapper: {
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap',
    columnGap: 8,
    rowGap: 4,
  },
  greenText: {
    color: theme.palette.green[600],
  },
  highlightedAmount: {
    color: theme.palette.grey[700],
  },
  payNowActionWrapper: {
    display: 'flex',
    alignItems: 'center',
    gap: 8,
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      justifyContent: 'space-between',
    },
    '& button': {
      flexShrink: 0,
    },
  },
  popover: {
    borderRadius: 8,
    boxShadow: '0px 4px 32px rgba(105, 107, 122, 0.24)',
  },
  loading: {
    position: 'absolute',
    top: 0,
    left: 0,
    zIndex: 2,
    backdropFilter: 'blur(12px)',
    background: 'rgba(255, 255, 255, 0.6)',
    [theme.breakpoints.up('md')]: {
      borderRadius: 16,
    },
  },
  quantityWrapper: {
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap',
    columnGap: 4,
    rowGap: 8,
  },
  noData: {
    width: '100%',
    padding: 24,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderTop: '1px solid',
    borderTopColor: theme.palette.grey[200],
  },
}));

export const PaymentSection = () => {
  const classes = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const [
    payrolls,
    fetchPayroll,
    togglePayroll,
    estimateFees,
    estimatedFees,
  ] = usePayrollStore((state) => [
    state.payrolls, state.fetch, state.toggle, state.estimateFees, state.estimatedFees,
  ]);
  const [addressBooks, fetchAddressBooks] = useTransactionStore((state) => [
    state.addressBooks, state.fetchAddressBooks,
  ]);
  const [activeWalletBalance, fetchActiveWalletBalance, activeWalletInfo] = useWalletStore((state) => [
    state.activeWalletBalance, state.fetchLatestWalletBalances, state.activeWalletInfo,
  ]);
  const [{ wallet }, connect] = useConnectWallet();
  const [workItems] = useWorkItemStore((state) => [state.workItems]);
  const [coins, fetchCoins] = useCoinsStore((state) => [state.coins, state.fetchCoins]);
  const [activePayrollId, setActivePayrollId] = React.useState<number | undefined>(undefined);
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);
  const [isAddPayrollModalOpen, setIsAddPayrollModalOpen] = useState<boolean>(false);
  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [payrollIdToPay, setPayrollIdToPay] = useState<number | undefined>(undefined);
  const [isPayNowDisabled, setIsPayNowDisabled] = useState<boolean>(false);

  useEffect(() => {
    (async () => {
      await Promise.allSettled([
        fetchPayroll(false),
        fetchAddressBooks(false),
        fetchCoins(),
        fetchActiveWalletBalance(),
        estimateFees(),
      ]);
      setIsLoading(false);
    })();
  }, []);

  const handleOnToggleAllPayroll = (enable: boolean) => async () => {
    setIsPayNowDisabled(true);
    await togglePayroll(
      payrolls
        ?.map((p) => ({ payrollId: p.payrollId, addressBookId: p.addressBookId, enable })) ?? [],
    );
    setIsPayNowDisabled(false);
  };

  const handleOnTogglePayroll = (payrollId: number, addressBookId: number, enable: boolean) => async () => {
    setIsPayNowDisabled(true);
    await togglePayroll([{ payrollId, addressBookId, enable }]);
    setIsPayNowDisabled(false);
  };

  const handleClickOption = (event: React.MouseEvent<HTMLButtonElement>) => {
    if (anchorEl === null) {
      setAnchorEl(event.currentTarget);
      setActivePayrollId(+event.currentTarget.id);
    }
  };

  const handleCloseOption = () => {
    setAnchorEl(null);
    setActivePayrollId(undefined);
  };

  const numEnabledPayroll = useMemo(() => payrolls
    ?.filter((pr) => pr.enable)?.length ?? 0, [payrolls]);

  const totalUsdtAmount = useMemo(() => payrolls
    ?.filter((pr) => pr.enable)
    .reduce((prev, curr) => prev + (curr.usdAmount ?? 0), 0), [payrolls]);

  const coinIdBalanceMap = React.useMemo(() => (activeWalletBalance
    ? Object.fromEntries(activeWalletBalance.map((wb) => [wb.coinId, wb.balance]))
    : {}), [activeWalletBalance]);

  const { notEnoughFundCoinMap, notEnoughFund } = useMemo(() => {
    const coinIdSumPayrollQuantityMap = payrolls
      ?.filter((pr) => pr.enable)
      .reduce((prev: Record<number, number>, curr) => {
        const updatedData = { [curr.coinId]: (curr.quantity ?? 0) + (prev[curr.coinId] ?? 0) };
        return { ...prev, ...updatedData };
      }, {});
    // eslint-disable-next-line no-shadow
    const notEnoughFundCoinMap: Record<number, boolean> = Object.entries(coinIdSumPayrollQuantityMap ?? [])
      .reduce((prev: Record<number, boolean>, [coinId, quantity]) => ({
        ...prev,
        [coinId]: (coinIdBalanceMap[coinId] ?? 0) < quantity,
      }), {});
    // eslint-disable-next-line no-shadow
    const notEnoughFund: boolean = Object.entries(notEnoughFundCoinMap)
      .reduce((prev: boolean, [, value]) => prev || value, false);
    return { notEnoughFundCoinMap, notEnoughFund };
  }, [coinIdBalanceMap, activeWalletBalance, payrolls]);

  const groupedFeeByChainId = useMemo(() => groupUsdFeesByChain(coins, estimatedFees, payrolls),
    [coins, estimatedFees, payrolls]);

  const totalFeeUsd = useMemo(() => findTotalFeeUsd(groupedFeeByChainId), [groupedFeeByChainId]);

  const isGasEstError = useMemo(() => (
    estimatedFees === undefined ? false
      : Object.entries(estimatedFees.fees)
        .reduce((prev, [, { fee }]) => fee < 0 || prev, false)
  ), [estimatedFees]);

  const walletAddress = useMemo(() => {
    return activeWalletInfo?.address?.filter((a) => a.startsWith('0x'))?.at(0)
  }, [activeWalletInfo]);

  const walletStatus = useMemo(() => {
    return activeWalletInfo?.walletProvider === 'Reporting' && walletAddress ? isAddressSupported(walletAddress) : WalletStatus.Ok
  }, [activeWalletInfo, walletAddress, wallet])

  const showNonCustodialWalletError = useMemo(() => {
    return wallet != null && walletStatus === WalletStatus.No;
  }, [wallet, walletStatus])

  const showConnectWalletButton = React.useMemo(() => {
    return activeWalletInfo?.walletProvider === 'Reporting' && (wallet === null || walletStatus === WalletStatus.No || walletStatus === WalletStatus.OkManual)
  },
  [activeWalletInfo, wallet, walletStatus])

  const payrollInProgress = useMemo(() => {
    if (!workItems || workItems.length === 0) return undefined;
    return workItems.find(
      (item) => (item.name === WorkItemName.Payroll || item.name === WorkItemName.PayrollBatch)
        && (item.status === WorkItemStatus.InProgress || item.status === WorkItemStatus.Pending
          || item.status === WorkItemStatus.WaitingForApproval),
    );
  }, [workItems]);

  return (
    <>
      <Box className={classes.root}>
        {isLoading && <Loading className={classes.loading} />}
        <Box className={classes.dataGridHeaderWrapper}>
          <Box className={classes.dataGrid}>
            <Box className={`${classes.headerText} ${isMobile ? classes.columnHeader : ''}`} id="header">
              <Typography variant="h2">Payment addresses</Typography>
            </Box>
            <Box className={`${classes.addAddress} ${isMobile ? classes.columnHeader : ''}`} id="addAddress">
              <Button
                variant={isMobile ? 'primary' : 'secondary'}
                size="sm"
                className={classes.addAddressButton}
                onClick={() => setIsAddPayrollModalOpen(true)}
              >
                Add
                <Box className={classes.plusIconWrapper}>
                  <SVG src="icons/reporting/ic_plus.svg" width={18} height={18} />
                </Box>
              </Button>
            </Box>
            <PayrollHeader
              cellClass={classes.columnHeader}
              selectedPayrolls={numEnabledPayroll}
              allPayrolls={payrolls?.length}
              checkBoxProps={{
                indeterminate: (numEnabledPayroll ?? 0) > 0 && numEnabledPayroll !== payrolls?.length,
                checked: payrolls !== undefined && numEnabledPayroll === payrolls.length,
                onChange: handleOnToggleAllPayroll(numEnabledPayroll !== payrolls?.length),
              }}
            />
          </Box>
        </Box>
        <Box className={classes.dataGridWrapper}>
          <Scrollable className={classes.scrollable}>
            {(payrolls !== undefined && payrolls.length > 0 && addressBooks !== undefined) ? (
              <Box className={classes.scrollContent} position={isMobile ? 'relative' : 'absolute'}>
                {payrolls.map((payroll) => (
                  <PayrollRow
                    {...payroll}
                    key={payroll.payrollId}
                    cellClassName={classes.gridCell}
                    className={classes.dataGrid}
                    handleMenu={handleClickOption}
                    notEnoughFund={notEnoughFundCoinMap[payroll.coinId]}
                    isMenuActive={anchorEl !== null && activePayrollId === payroll.payrollId}
                    handleCheckbox={handleOnTogglePayroll(payroll.payrollId, payroll.addressBookId, !payroll.enable)}
                  />
                ))}
              </Box>
            ) : (
              <Box className={classes.noData}>
                <Typography variant="h1" palette="grey" paletteColor={300}>No Address</Typography>
              </Box>
            )}
          </Scrollable>
        </Box>
        <Box className={classes.summarySection}>
          <Hidden smDown>
            <Box className={classes.estimateFeeWrapper}>
              {showNonCustodialWalletError ? (
                <Typography variant="h6" palette="red" paletteColor={800}>
                  {`Please select and connect your wallet address
                  ${walletAddress ? ': ' + walletAddress.slice(0, 2) + '...' + walletAddress.slice(-6) : ''}`}
                </Typography>
              )
                : numEnabledPayroll > 0 && (
                  <>
                    <Typography variant="h6" paletteColor={500} strong={false}>
                    Estimated fees:
                    </Typography>
                    <EstimateFee
                      estimateFees={estimateFees}
                      updatedTs={estimatedFees?.updatedTs}
                      totalFeeUsd={totalFeeUsd}
                      isGasEstError={isGasEstError}
                      isInsufficientGas={checkInsufficientGas(
                        coins,
                        undefined,
                        undefined,
                        activeWalletBalance,
                        payrolls,
                        estimatedFees,
                      )}
                    />
                  </>
                )}
            </Box>
          </Hidden>
          <Box className={classes.payNowActionWrapper}>
            {!showNonCustodialWalletError && !showConnectWalletButton && (<Typography
              variant="h6"
              component="span"
              paletteColor={500}
              strong={false}
              className={classes.quantityWrapper}
            >
              <Hidden smDown>total USDT amount:&nbsp;</Hidden>
              <Typography
                component="span"
                variant="h4"
                palette={notEnoughFund ? 'red' : 'grey'}
                paletteColor={notEnoughFund ? 600 : 700}
                noWrap
              >
                ≈ ${(totalUsdtAmount ?? 0).toLocaleString()}
              </Typography>
              {notEnoughFund && (
                <LightTooltip
                  arrow
                  placement="bottom"
                  enterTouchDelay={0}
                  title="Not enough fund to proceed selected payments"
                >
                  <SVG src="icons/batch_payroll/ic_warning.svg" width={16} height={16} />
                </LightTooltip>
              )}
              <Hidden mdUp>
                &nbsp; Total USDT
              </Hidden>
            </Typography>)}
            <LightTooltip
              arrow
              enterTouchDelay={0}
              placement="top-end"
              title={payrollInProgress ? 'Payroll is in progress' : ''}
            >
              <Box>
                { showNonCustodialWalletError ? (
                  <Button
                    variant="primary"
                    color="red"
                    onClick={(e) => {
                      showAccountCenter()
                      e.stopPropagation()
                    }}
                  >
                    Manage connected wallet
                  </Button>
                ) : showConnectWalletButton ? (
                  <Button onClick={async () => {
                    await connect();
                  }}>
                    Connect wallet
                  </Button>
                ) : (
                  <Button
                    size="md"
                    onClick={() => {
                      setIsConfirmationModalOpen(true);
                    }}
                    disabled={notEnoughFund || numEnabledPayroll <= 0 || isGasEstError || payrollInProgress !== undefined
                      || isPayNowDisabled}
                  >
                    Pay now
                  </Button>
                )}
              </Box>
            </LightTooltip>
          </Box>
        </Box>
      </Box>
      <Popover
        open={!!anchorEl}
        onClose={handleCloseOption}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 32,
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        PaperProps={{
          className: classes.popover,
        }}
      >
        <OptionMenu
          id={activePayrollId}
          setIsConfirmationModalOpen={() => { setPayrollIdToPay(activePayrollId); setIsConfirmationModalOpen(true); }}
          onClose={() => setAnchorEl(null)}
          payrollInProgress={payrollInProgress !== undefined}
          haveEnoughFund={coinIdBalanceMap[payrolls
            ?.find((p) => p.payrollId === activePayrollId)?.coinId ?? 0] >= (payrolls?.find((p) => p.payrollId === activePayrollId)?.quantity ?? 0)}
        />
      </Popover>
      <AddPayrollModal open={isAddPayrollModalOpen} setOpen={setIsAddPayrollModalOpen} />
      <ConfirmationModal
        open={isConfirmationModalOpen}
        setOpen={setIsConfirmationModalOpen}
        payrollId={payrollIdToPay}
        setPayrollId={setPayrollIdToPay}
      />
    </>
  );
};
