import { useWallets, Web3OnboardProvider as Web3Provider } from '@web3-onboard/react';
import React, { ReactNode } from 'react';

import { isAddressSupported, onBoardApi, WalletStatus } from '../services/OnboardService';
import { useStore as useUserStore } from '../store/zustand/User';
import { useStore as useWalletStore } from '../store/zustand/Wallet';

interface Props {
  children: ReactNode
}

const AutoUpdateSelfCustodian = ({ children }: Props) => {
  const onboardWallets = useWallets();
  const [user, fetchUser] = useUserStore((state) => [state.user, state.fetchUser]);
  const [setIsNonCustodian] = useWalletStore((state) => [state.setIsNonCustodian]);
  React.useEffect(() => {
    (async () => {
      const walletIdsToUpdate = user?.walletInfo
        ?.filter((w) => w.walletProvider === 'Reporting' && !w.isNonCustodian)
        .filter((w) => w.address && w.address.some((a) => isAddressSupported(a) !== WalletStatus.No))
        .map((w) => w.walletId)
        .filter((w) => w !== undefined) as number[];
      if (walletIdsToUpdate?.length > 0) {
        await setIsNonCustodian(walletIdsToUpdate);
        await fetchUser(true);
      }
    })();
  }, [user?.walletInfo, onboardWallets]);
  return (
    <>
      { children }
    </>
  )
}

export const Web3OnboardProvider = ({ children }: Props) => {
  return (
    <Web3Provider web3Onboard={onBoardApi}>
      <AutoUpdateSelfCustodian>
        {children}
      </AutoUpdateSelfCustodian>
    </Web3Provider>
  )
};
