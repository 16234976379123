import { Box, makeStyles, Typography } from '@material-ui/core';
import { Card, ChartColors, LineChartLegend } from 'elements';
import React, { FC, useEffect } from 'react';
import SVG from 'react-inlinesvg';
import { AverageInterestPercentage } from 'services/types/interest';
import { useStore as useUserStore } from 'store/zustand/User';

import NoData from '../NoData';

interface Props {
  className?: string;
  data: AverageInterestPercentage[] | null;
  selectedCoins: string[];
}

const useStyles = makeStyles((theme) => ({
  root: {
    flex: '1',
    display: 'flex',
    flexDirection: 'column',
  },
  chart: { width: '100%' },

  legendsRow: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    alignItems: 'center',
    columnGap: 8,

    marginTop: 4,
    marginLeft: 12,
  },
  legend: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'start',
    alignItems: 'center',
    columnGap: 4,

    marginTop: 4,
    marginLeft: 12,
  },
  legendIcon: {
    width: 8,
    filter: 'invert(52%) sepia(49%) saturate(2382%) hue-rotate(134deg) brightness(97%) contrast(98%)',
  },
  legendLabel: {
    color: theme.palette.grey['600'],
    fontSize: 12,
    fontWeight: 400,
    lineHeight: '16px',
  },
}));

const DailyAverageInterestPerCoinChart = React.lazy(() => import('./DailyAverageInterestPerCoinChart'));

const DailyAverageInterestPerCoinSection: FC<Props> = ({ data, className, selectedCoins }) => {
  const classes = useStyles();

  const { user, fetchUser } = useUserStore();
  useEffect(() => {
    fetchUser(false);
  }, []);

  if (!user) return null;

  return (
    <Card className={`${classes.root} ${className}`}>
      <Typography variant="h3">
        Average daily interest rate per coin
      </Typography>

      {!data || data.length === 0 || selectedCoins.length === 0 ? (
        <NoData hasSelectedCoins={data?.length !== 0} />
      ) : (
        <>
          <Box className={classes.chart}>
            <React.Suspense fallback={<></>}>
              <DailyAverageInterestPerCoinChart data={data} selectedCoins={selectedCoins} />
            </React.Suspense>
          </Box>

          <Box className={classes.legendsRow}>
            {data
              .filter((i) => i.coin.metadata.masterCoin !== 'UST')
              .filter((i) => i.portfolioPercentage > 0.02)
              .filter((i) => selectedCoins.indexOf(i.coin.metadata?.masterCoin ?? i.coin.symbol) >= 0 ?? true)
              .reduce<AverageInterestPercentage[]>((acc, curr) =>
                (acc.map((a) =>
                  a.coin.masterCoin.ticker).includes(curr.coin.masterCoin.ticker) ? acc : [...acc, curr]), [])
              .map((d, index) => (
                <Box key={index} className={classes.legend}>
                  <LineChartLegend color={ChartColors[index]} />
                  <SVG src={d.coin.masterCoin?.icon ?? '/icons/coin_icon/bitcoin.svg'} height={12} width={12} />

                  <Typography className={classes.legendLabel}>
                    {d.coin.metadata?.masterCoin || d.coin.symbol}
                  </Typography>
                </Box>
              ))}
          </Box>
        </>
      )}
    </Card>
  );
};

DailyAverageInterestPerCoinSection.displayName = 'DailyAverageInterestPerCoinSection';
export default DailyAverageInterestPerCoinSection;
