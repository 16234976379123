// ChainId -> [Short name, Full name, Detail(optional)]
export const chainIdMapper: Record<string, Array<string>> = {
  binance: ['BEP2', 'Binance Chain (BEP2)'],
  1: ['ERC20', 'Ethereum Chain (ERC20)'],
  10: ['OP', 'Optimism'],
  56: ['BEP20 (BSC)', 'Binance Smart Chain (BEP20)'],
  137: ['MATIC', 'Polygon'],
  43114: ['AVAX', 'Avalanche'],
  'terra-mainnet': ['Terra Classic', 'Terra Classic'],
  'terra2-mainnet': ['Terra2 Mainnet', 'Terra2 Mainnet'],
  'chainlink-oracle': ['Chainlink Oracle', 'Chainlink Oracle'],
  bnbbeacon: ['BEP2', 'BNB Beacon Chain (BEP2)'],
};

export const chainHexIdMapper: Record<string, string> = {
  1: '0x1',
  10: '0xa',
  56: '0x38',
  137: '0x89',
  43114: '0xa86a',
};

export const chainIdIconMapper: Record<string, string> = {
  binance: '/icons/chain/bep2.svg',
  1: '/icons/chain/erc20.svg',
  10: '/icons/chain/op.svg',
  56: '/icons/chain/bep20.svg',
  137: '/icons/chain/matic.svg',
  43114: '/icons/chain/avax.svg',
  'terra-mainnet': '/icons/chain/terra.svg',
  'chainlink-oracle': '/icons/chain/chainlink.svg',
  bnbbeacon: '/icons/chain/bep2.svg',
};

export const walletPattern: Record<string, RegExp> = {
  1: /^0x[0-9a-fA-F]{40}$/,
  10: /^0x[0-9a-fA-F]{40}$/,
  56: /^0x[0-9a-fA-F]{40}$/,
  137: /^0x[0-9a-fA-F]{40}$/,
  'terra-mainnet': /^(terra1[a-z0-9]{38})$/,
};

export const placeholderMapper: Record<string, string> = {
  1: '0x....',
  10: '0x....',
  56: '0x....',
  137: '0x....',
  'terra-mainnet': 'terra...',
};

export const supportedChainIds = ['1', '56', '137'];

export const chainIdNativeCoinSymbolMapper: Record<string, string> = {
  1: 'ETH',
  56: 'BNB',
  137: 'MATIC',
};

export const chainIdNativeCoinIdMapper: Record<string, number> = {
  1: 341158505,
  56: 8011979,
  137: 341159068,
};
