import { Box, Typography, useMediaQuery, useTheme } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import React from 'react';

interface Props {
  numStep: number;
  currentStep: number;
  delayed: boolean;
}

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    padding: '6px 0',
    [theme.breakpoints.down('sm')]: { padding: '5px 0' },
  },
  bar: {
    width: '100%',
    height: 8,
    borderRadius: 8,
    position: 'relative',

    backgroundColor: ({ delayed}: Props) => (delayed ? theme.palette.yellow[500] : 'unset'),
    // eslint-disable-next-line max-len
    backgroundImage: ({ delayed }: Props) => (delayed ? 'unset' : `linear-gradient(${theme.palette.blue[500]}, ${theme.palette.blue[500]}), linear-gradient(${theme.palette.blue[200]}, ${theme.palette.blue[200]})`),
    // eslint-disable-next-line max-len
    backgroundSize: ({ currentStep, numStep, delayed }: Props) => (delayed ? 'unset' : `${((currentStep - 1) / (numStep - 1)) * 100}% 100%, 100% 100%`),
    backgroundRepeat: 'no-repeat',
  },
  mileStone: {
    width: 20,
    height: 20,
    border: '2px solid',
    borderColor: theme.palette.blue[200],
    borderRadius: 10,
    color: theme.palette.blue[600],

    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',

    position: 'absolute',
    top: -6,
    backgroundColor: 'white',
    transform: 'translate(-50%, 0%)',

    [theme.breakpoints.down('sm')]: { width: 18, height: 18, top: -5 },
  },
  active: { color: 'white', backgroundColor: theme.palette.blue[600], borderColor: theme.palette.blue[600] },
  achieved: { color: 'white', backgroundColor: theme.palette.blue[500], borderColor: theme.palette.blue[500] },

}));

export const WithdrawalProgressBar = ({ numStep, currentStep, delayed } : Props) => {
  const classes = useStyles({ numStep, currentStep, delayed });

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const offset = isMobile ? 18 : 20;

  return (
    <Box className={classes.root}>
      <Box className={classes.bar}>
        { !delayed && Array.from({ length: numStep }, (_, i) => i + 1).map((i) => (
          <Box
            key={i}
            className={
              `${i === currentStep && classes.active} ${i < currentStep && classes.achieved} ${classes.mileStone}`
            }
            left={`calc((100% - ${offset}px) * ${(i - 1) / (numStep - 1)} + ${offset / 2}px)`}
          >
            <Typography variant="h6">{i}</Typography>
          </Box>
        ))}
      </Box>
    </Box>
  );
};
