import { Tooltip, withStyles } from '@material-ui/core';

export const LightTooltip = withStyles({
  arrow: {
    color: 'white',
  },
  tooltip: {
    backgroundColor: 'white',
    color: '#38425E',
    borderRadius: '8px',
    filter: 'drop-shadow(0px 4px 32px rgba(105, 107, 122, 0.24))',
    fontSize: '12px',
    fontWeight: 500,
    lineHeight: '20px',
  },
})(Tooltip);
