import { Box, createStyles, Hidden, makeStyles, useMediaQuery, useTheme } from '@material-ui/core';
import { CustomRadioButton } from 'components/CustomRadioButton';
import { Typography } from 'elements';
import * as React from 'react';
import SVG from 'react-inlinesvg';
import { useStore as usePortfolioSettingStore } from 'store/zustand/PortfolioSetting';

export interface CoinEarningItemProps {
  name: string;
  icon: string;
  protocolId: number;
  coinId: number;
  currentApy: number;
  avgApy: number;
  lockPeriod?: number;
  tvl?: string;
  chain?: string;
  chainId?: string;
  audit?: boolean;
  isExpanded?: boolean
}

const useStyles = makeStyles((theme) => createStyles({
  root: {
    display: 'grid',
    border: '1px solid #DEE3EF',
    borderRadius: 8,
    gap: 8,
    padding: '6px 8px',
    cursor: 'pointer',
    transition: 'all ease-in-out 0.1s',
    gridTemplateColumns: '1fr',
  },
  expanded: {
    [theme.breakpoints.up('md')]: {
      gridColumn: '1 / all',
      gridTemplateColumns: '1fr 128px 120px 86px 120px 32px',
    },
  },
  title: {
    display: 'flex',
    flexWrap: 'nowrap',
    gap: 8,
    alignItems: 'center',
    [theme.breakpoints.down('sm')]: {
      display: 'grid',
      gridTemplateColumns: '20px 1fr 24px 16px',
    },
  },
  icon: {
    width: 40,
    height: 40,
    borderRadius: 40,
    overflow: 'hidden',
    flexShrink: 0,
    display: 'flex',
    [theme.breakpoints.down('sm')]: {
      width: 20,
      height: 20,
    },
    '& img': {
      userDrag: 'none',
      userSelect: 'none',
      width: 40,
      height: 40,
      [theme.breakpoints.down('sm')]: {
        width: 20,
        height: 20,
      },
    },
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'row',
      flexWrap: 'nowrap',
      gap: 4,
      alignItems: 'center',
    },
  },
  name: {
    fontSize: 12,
    lineHeight: '20px',
    fontWeight: 500,
    color: theme.palette.grey[800],
  },
  interest: {
    display: 'flex',
    alignItems: 'center',
  },
  interestRate: {
    fontSize: 12,
    lineHeight: '20px',
    fontWeight: 500,
    color: theme.palette.blue[500],
  },
  grey: {
    color: theme.palette.grey[300],
  },
  green: {
    color: theme.palette.green[500],
  },
  lockPeriod: {
    color: theme.palette.yellow[500],
    fontSize: 12,
    lineHeight: '20px',
    fontWeight: 500,
    paddingRight: 4,
  },
  divider: {
    borderLeft: '1px solid #BBC4DD',
    margin: '4px 8px',
    height: 12,
  },
  detailRow: {
    display: 'flex',
    justifyContent: 'space-between',
    gap: 4,
  },
  detailTitle: {
    color: theme.palette.grey[500],
    fontSize: 12,
    fontWeight: 500,
    lineHeight: '20px',
  },
  detailValue: {
    color: theme.palette.grey[600],
    lineHeight: '20px',
    fontWeight: 500,
    fontSize: 12,
    alignSelf: 'center',
  },
  staking: {
    display: 'flex',
    flexWrap: 'nowrap',
    gap: 4,
    alignItems: 'center',
  },
  arrow: {
    width: 20,
    height: 20,
    display: 'flex',
    alignSelf: 'center',
    '& svg': {
      width: 20,
      height: 20,
    },
    '& path': {
      fill: theme.palette.grey[400],
    },
  },
  arrowUp: {
    rotate: '180deg',
  },
  radio: {
    alignSelf: 'center',
    justifySelf: 'center',
  },
  selected: {
    border: '2px solid',
    borderColor: theme.palette.green[400],
    padding: '5px 7px',
  },
}));

const CoinEarningItem = ({
  icon,
  name,
  currentApy,
  avgApy,
  lockPeriod,
  chain,
  tvl,
  audit,
  isExpanded,
  protocolId,
  coinId,
}: CoinEarningItemProps) => {
  const classes = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const [showDetail, setShowDetail] = React.useState(false);
  const [selectedCoinConfig, addSelectedCoinConfig] = usePortfolioSettingStore((state) => [state.selectedCoinConfig, state.addSelectedCoinConfig]);
  const expanded = React.useMemo(() => (isMobile ? showDetail : isExpanded), [isExpanded, showDetail, isMobile]);
  const isSelected = React.useMemo(
    () => (selectedCoinConfig || {})[coinId] === protocolId,
    [coinId, protocolId, selectedCoinConfig],
  );

  return (
    <Box
      className={`${classes.root} ${expanded ? classes.expanded : ''} ${isSelected ? classes.selected : ''}`}
      onClick={() => addSelectedCoinConfig(coinId, protocolId)}
    >
      <Box className={classes.title}>
        <Box className={classes.icon}>
          <img alt={name} src={icon} />
        </Box>
        <Box className={classes.content}>
          <Typography className={classes.name}>{name}</Typography>
          <Box className={classes.interest}>
            <Typography
              className={`${classes.interestRate} ${lockPeriod ? classes.green : ''}`}
            >
              {currentApy === 0 ? undefined : `${(currentApy * 100).toFixed(2)}%`}
            </Typography>
            {lockPeriod && (
              <>
                <Box className={classes.divider} />
                <Typography className={classes.lockPeriod}>{lockPeriod}d</Typography>
                <SVG src="/icons/earning/ic_lock.svg" width={16} height={16} />
              </>
            )}
          </Box>
        </Box>
        <Hidden mdUp>
          {
            (chain || tvl || audit || lockPeriod) ? (
              <Box
                className={`${classes.arrow} ${expanded ? classes.arrowUp : ''}`}
                onClick={(e) => { setShowDetail(!showDetail); e.stopPropagation(); }}
              >
                <SVG src="/icons/dashboard/ic_arrow_down.svg" />
              </Box>
            ) : <Box />
          }
          <CustomRadioButton innerCircleRadius={5} outterCircleRadius={8} color="#06D2AE" isSelected={isSelected} />
        </Hidden>
      </Box>
      {
        expanded ? (
          <>
            {chain ? (
              <Box className={classes.detailRow}>
                {isMobile ? <Typography className={classes.detailTitle}>Base Chain</Typography> : undefined}
                <Typography className={classes.detailValue}>{chain}</Typography>
              </Box>
            ) : !isMobile ? <Typography className={classes.detailValue}>-</Typography> : undefined}
            {avgApy ? (
              <Box className={classes.detailRow}>
                {isMobile ? <Typography className={classes.detailTitle}>30-Day Avg. APY</Typography> : undefined}
                <Typography className={classes.detailValue}>{`${(avgApy * 100).toFixed(2)}%`}</Typography>
              </Box>
            ) : !isMobile ? <Typography className={classes.detailValue}>-</Typography> : undefined}
            {audit ? (
              <Box className={classes.detailRow}>
                {isMobile ? <Typography className={classes.detailTitle}>Audited</Typography> : undefined}
                <Typography className={classes.detailValue}>Yes</Typography>
              </Box>
            ) : !isMobile ? <Typography className={classes.detailValue}>-</Typography> : undefined}
            {lockPeriod ? (
              <Box className={classes.detailRow}>
                {isMobile ? (
                  <Box className={classes.staking}>
                    <Typography className={classes.detailTitle}>Unstake Period</Typography>
                    <SVG src="/icons/earning/ic_lock.svg" width={16} height={16} />
                  </Box>
                ) : undefined}
                <Typography className={classes.detailValue}>{lockPeriod} Days</Typography>
              </Box>
            ) : !isMobile ? <Typography className={classes.detailValue}>-</Typography> : undefined}
            <Hidden smDown>
              <CustomRadioButton
                className={classes.radio}
                innerCircleRadius={5}
                outterCircleRadius={8}
                color="#06D2AE"
                isSelected={isSelected}
              />
            </Hidden>
          </>
        ) : undefined
      }
    </Box>
  );
};

export default CoinEarningItem;
