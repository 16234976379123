import { Box, withStyles } from '@material-ui/core';
import React from 'react';
interface LoadingProps {
  className?: string;
}

const Container = withStyles((theme) => ({
  root: {
    display: 'flex',
    flexGrow: 1,
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',

    width: '100%',
    height: '100%',

    background: theme.palette.grey['100'],
  },
}))(Box);

export const Loading = ({ className }: LoadingProps) => (
  <Container id="loading" className={className || ''}>
    <img alt="" src="/images/loading.gif" width={96} />
  </Container>
);
