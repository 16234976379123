import { Box, Link, makeStyles, Typography } from '@material-ui/core';
import { Terra2Holdings } from 'components/dashboard';
import { BackButton, Card, Page } from 'elements';
import React, { useEffect,useState } from 'react';
import { DashboardRoute, DashboardUstRoute } from 'routes';
import { Coin } from 'services/types/wallet';
import WalletService from 'services/wallet-service';
import { useStore as useWalletStore } from 'store/zustand/Wallet';

const useStyles = makeStyles((theme) => ({
  root: {
    alignItems: 'center',
  },
  mobileSection: {
    margin: '0 16px',
    [theme.breakpoints.up('sm')]: {
      margin: 0,
    },
  },
  contentCard: {
    borderRadius: '0 !important',
    [theme.breakpoints.up('sm')]: {
      borderRadius: '4px !important',
    },
  },
  titleRow: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'center',
    [theme.breakpoints.up('md')]: {
      flexDirection: 'row',
    },
  },
  ustCard: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    rowGap: 12,
  },
  ustQuantityRow: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'baseline',
  },

  messageCard: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'start',
    rowGap: 24,
  },
  messageSection: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'start',
    rowGap: 10,
  },
  messageText: {
    fontSize: 14,
  },
}));

const DashboardUst = () => {
  const classes = useStyles();
  const [lunaCoin, setLunaCoin] = useState<Coin | null>(null);
  const [fetchWallet, fetchMinMaxTs] = useWalletStore((state) => [
    state.fetchWallet,
    state.fetchMinMaxTs,
  ]);
  useEffect(() => {
    const fetchPortfolioValue = async () => {
      const [wallet, minMaxTs] = await Promise.all([fetchWallet(false), fetchMinMaxTs(false)]);
      const maxTs = minMaxTs?.performanceTime ? [minMaxTs?.performanceTime.maxTs] : [];
      if (wallet.walletId === undefined) return;
      const perf = await WalletService.getPerformance(
        wallet.walletId,
        WalletService.stringifyTs(maxTs),
        0,
        false,
      );
      const luna = perf.data[0].points[0].coins.find((c: Coin) => c.name === 'LUNA') ?? null;

      setLunaCoin(luna);
    };

    fetchPortfolioValue();
  }, []);

  return (
    <Page>
      <BackButton className={classes.mobileSection} href={DashboardRoute} />

      <Box className={`${classes.titleRow} ${classes.mobileSection}`}>
        <Typography variant="h2">Terra UST Status Updates</Typography>
        <Typography variant="h4">Last updated: May 28, 2022</Typography>
      </Box>

      <Terra2Holdings
        className={classes.contentCard}
        showLearnMore={false}
        lockedPrice={lunaCoin?.lockedPrice}
        lockedQuantity={lunaCoin?.lockedQuantity}
        price={lunaCoin?.price}
        quantity={lunaCoin?.quantity}
      />

      <Card className={`${classes.messageSection} ${classes.contentCard}`}>
        <Box>
          <Typography variant="h4">For the latest, follow @terra_money on Twitter:</Typography>
          <Link
            className={classes.messageText}
            href="https://twitter.com/terra_money"
            target="_blank"
            rel="noreferrer"
          >
            https://twitter.com/terra_money
          </Link>
        </Box>

        <Box>
          <Typography variant="h4">Airdrop calculation logic:</Typography>
          <Link
            className={classes.messageText}
            href="https://medium.com/terra-money/terra-2-0-luna-airdrop-calculation-logic-3eb752c25837"
            target="_blank"
            rel="noreferrer"
          >
            https://medium.com/terra-money/terra-2-0-luna-airdrop-calculation-logic-3eb752c25837
          </Link>
        </Box>

        <Box>
          <Typography variant="h4">Airdrop details:</Typography>
          <Link
            className={classes.messageText}
            href="https://medium.com/terra-money/terra-2-0-luna-airdrop-cd08a6d9cfcd"
            target="_blank"
            rel="noreferrer"
          >
            https://medium.com/terra-money/terra-2-0-luna-airdrop-cd08a6d9cfcd
          </Link>
        </Box>

        <Box>
          <Typography variant="h4">Terra's final proposal:</Typography>
          <Link
            className={classes.messageText}
            href="https://agora.terra.money/t/terra-ecosystem-revival-plan-2-updated-and-final/18498"
            target="_blank"
            rel="noreferrer"
          >
            https://agora.terra.money/t/terra-ecosystem-revival-plan-2-updated-and-final/18498
          </Link>
        </Box>
      </Card>

      <Card className={`${classes.messageCard} ${classes.contentCard}`}>
        <Box className={classes.messageSection}>
          <Typography variant="h4">A message from Coinbag, May 28th 2022</Typography>
          <Typography className={classes.messageText}>
            Coinbag will support the Terra 2.0 airdrop for customers holding UST during the de-pegging event.
          </Typography>
        </Box>

        <Box className={classes.messageSection}>
          <Typography variant="h4">What this means for you</Typography>
          <Typography className={classes.messageText}>
            Terra 2.0 will be providing airdrops of their new network token, LUNA, for customers holding aUST and UST at
            the time of the de-pegging event. They intend to airdrop starting on May 28th, at 06:00 AM UTC (subject to
            delays).
            <br /><br />
            We are working diligently to display your LUNA balance and allow self-support withdrawals or swaps of this
            LUNA token directly into your portfolio. Even though it might not appear on your dashboard at first, rest
            assured, eligible users will receive the airdrop directly to their Coinbag address.
            <br /><br />
            After May 28th at 06:00 AM UTC (or when the airdrop officially occurs), if you wish to withdraw or know your
            airdrop balance, please get in touch with us directly at support@coinbag.finance or chat with us on our
            site, and we will gladly assist you.
            <br /><br />
            Withdrawals and airdrops are subject to the Terra 2.0 network availability and airdrop plan and may have
            delays out of our control.
          </Typography>
        </Box>
      </Card>

      <Card className={`${classes.messageCard} ${classes.contentCard}`}>
        <Box className={classes.messageSection}>
          <Typography variant="h4">A message from Coinbag, May 19th 2022</Typography>
          <Typography className={classes.messageText}>
            From 11/05/22 at 10:38AM (UTC), Coinbag temporarily suspended deposits and withdrawals due to congestion of
            the Terra network.<br /><br />

            These exceptional circumstances warranted swift action to protect Coinbag customers from the risk of lost
            transactions, which could have resulted in unrecoverable crypto. For any inconvenience caused, we will waive
            all Coinbag fees for the month of May.<br /><br />

            For those who would like more context, the Terra UST stablecoin experienced a drop of its intended value of
            1 UST to 1 USD. This appears to be a black swan event. In an unprecedented move, the Terra ecosystem halted
            all transactions twice within one week. The Luna Foundation Guard, which is heavily involved in the Terra
            ecosystem, has put in place a plan to reimburse holders of UST tokens before the de-peg event.
          </Typography>
        </Box>

        <Box className={classes.messageSection}>
          <Typography variant="h4">A quick summary of Terra’s plan to reimburse customers:</Typography>
          <Typography className={classes.messageText}>
            <ol style={{ marginBottom: 0 }}>
              <li>Terra will create a fork (or exact copy of the existing network), to continue development.</li>
              <li>Customers who were holding UST before the de-pegging event will get an Airdrop of the new LUNA (the
                exact amount is not disclosed).
              </li>
              <li>The airdrop will release 30% of your new tokens at the launch of the new fork, then the remaining 70%
                over 2 years, starting at month 6.
              </li>
            </ol>
          </Typography>
        </Box>

        <Box className={classes.messageSection}>
          <Typography variant="h4">How does this impact you?</Typography>
          <Typography className={classes.messageText}>
            The airdrops will be delivered directly to your wallet as they get released. You can access these airdrops
            directly from your Coinbag account. If you wish at any time to manage these funds on your own, Coinbag can
            work with you to provide direct access to your wallet, allowing you to manage these funds directly.
          </Typography>
        </Box>

        <Box className={classes.messageSection}>
          <Typography variant="h4">
            I don’t want the airdrop. I just want to sell my UST at the current market price.
          </Typography>

          <Typography className={classes.messageText}>
            It is your funds, so it is your choice! Please reach out to us (details below) and we can coordinate how to
            send the UST directly to your exchange. Due to Terra network limitations, Coinbag may not be able to help
            you sell them on-chain at this time, though we will do our best to help.
          </Typography>
        </Box>
      </Card>

      <Card className={`${classes.messageCard} ${classes.contentCard}`}>
        <Box className={classes.messageSection}>
          <Typography variant="h4">How to contact us</Typography>
          <Typography className={classes.messageText}>
            You can chat with us on your site or send us an email at{' '}
            <Link href="mailto:support@coinbag.finance">support@coinbag.finance</Link>
          </Typography>
        </Box>
      </Card>
    </Page>
  );
};

DashboardUst.routePath = DashboardUstRoute;
DashboardUst.loginRequired = true;

export { DashboardUst };
