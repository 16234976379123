import { Box, Hidden, makeStyles } from '@material-ui/core';
import { Typography } from 'elements';
import React, { FC } from 'react';
import SVG from 'react-inlinesvg';

import { chainIdIconMapper, chainIdMapper } from '../mappers';
import { MoonPayTag } from './MoonPayTag';

const useStyles = makeStyles((theme) => ({
  item: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    gap: 12,
    width: '100%',
    '& .left': {
      display: 'flex',
      flexWrap: 'nowrap',
      alignItems: 'center',
      gap: 8,
      '& .name': {
        color: theme.palette.grey[700],
        fontSize: 16,
        lineHeight: '24px',
        fontWeight: 500,
      },
      '& .amount': {
        color: theme.palette.grey[600],
        fontSize: 14,
        lineHeight: '20px',
        fontWeight: 500,
        display: 'inline',
      },
      '& .equal': {
        display: 'inline',
        margin: '0 4px',
        paddingBottom: 2,
        color: theme.palette.grey[500],
        fontSize: 12,
        lineHeight: '20px',
        fontWeight: 500,
      },
      '& .value': {
        display: 'inline',
        color: theme.palette.grey[500],
        fontSize: 12,
        lineHeight: '20px',
        fontWeight: 500,
      },
    },
    '& .right': {
      display: 'flex',
      justifyContent: 'flex-end',
      flexWrap: 'wrap',
      gap: 8,
      [theme.breakpoints.down('xs')]: {
        padding: '0 2px',
        gap: 0,
      },
      '& span': {
        color: theme.palette.grey[700],
        fontSize: 12,
        lineHeight: '16px',
        fontWeight: 500,
      },
    },
  },
  icon: {
    width: 24,
    height: 24,
    '& svg': {
      width: 24,
      height: 24,
      borderRadius: 12,
    },
    display: 'flex',
    alignItems: 'center',
  },
  networkItem: {
    display: 'flex',
    flexWrap: 'nowrap',
    alignItems: 'center',
    gap: 4,
    [theme.breakpoints.down('xs')]: {
      margin: '0 -2px',
    },
  },
  networkIcon: {
    width: 16,
    height: 16,
  },
  tokenIcon: {
    width: 24,
    height: 24,
    borderRadius: 20,
    backgroundColor: theme.palette.grey[100],
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  tokenName: {
    overflow: 'hidden',
    fontSize: 8,
    lineHeight: '20px',
    textAlign: 'center',
    textOverflow: 'ellipsis',
  },
}));

export interface TokenItemProps {
  id: number;
  masterCoinId?: string;
  icon: string;
  name: string;
  symbol?: string;
  amount?: number;
  value?: number;
  networkIds: string[];
  locked?: boolean;
  useSymbol?: boolean;
  isSvg?: boolean;
  hideValue?: boolean;
  moonPaySupported?: boolean;
}

export const TokenItem: FC<TokenItemProps> = ({
  icon, name, symbol, amount, value, id
  , networkIds, isSvg = true, useSymbol
  , hideValue, moonPaySupported,
}) => {
  const classes = useStyles();
  return (
    <Box key={id} className={classes.item}>
      <Box className="left">
        <Box className={classes.icon}>
          {icon ? (
            <SVG src={icon} />
          ) : (
            <Box className={classes.tokenIcon}>
              <Typography className={classes.tokenName} paletteColor={500} align="center">{symbol}</Typography>
            </Box>
          )}
        </Box>
        <Typography className="name" component="span">{useSymbol && symbol ? symbol : name}</Typography>
        {moonPaySupported && (
          <MoonPayTag />
        )}
        {amount !== undefined && !hideValue && (
          <Typography className="amount" component="span">
            {(amount).toFixed(0).length > 11
              ? (amount).toLocaleString(
                undefined,
                { unitDisplay: 'narrow', notation: 'compact', minimumFractionDigits: 2 },
              )
              : (amount).toLocaleString(
                undefined,
                { minimumSignificantDigits: 2, maximumSignificantDigits: 5 },
              )}
            {value && (
              <>
                <Typography className="equal" component="span">  ≈  </Typography>
                <Typography className="value" component="span">
                  {value.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
                </Typography>
              </>
            )}
          </Typography>
        )}
      </Box>
      <Box className="right">
        {networkIds.map((networkId) => (
          <Box key={networkId} className={classes.networkItem}>
            <Hidden xsDown>
              <Typography component="span" align="right">
                {chainIdMapper[networkId] ? chainIdMapper[networkId][0] : ''}
              </Typography>
            </Hidden>
            {chainIdIconMapper[networkId]
              ? (isSvg
                ? <SVG src={chainIdIconMapper[networkId]} className={classes.networkIcon} />
                : <object className={classes.networkIcon} data={
                  chainIdIconMapper[networkId]
                } type="image/svg+xml" aria-label={networkId} />)
              : undefined}
          </Box>
        ))}
      </Box>
    </Box>
  );
};
