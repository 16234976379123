import React, { Component } from 'react';
import SharedStateComponent from './SharedStateComponent';

export default class FormHandler extends SharedStateComponent {
    constructor(props){
        super(props);
    }

    renderWithSharedState() {
        return (
            <form autoComplete={this.props.autoComplete} onSubmit = {this.props.onSubmit ? this.props.onSubmit : null} name = {this.props.name}>
            {this.props.children}
            </form>
        );
    }
}

