import { Box, createStyles, Hidden, makeStyles } from '@material-ui/core';
import React, { useEffect, useRef } from 'react';
import SVG from 'react-inlinesvg';
import { useHistory } from 'react-router-dom';

import { Typography } from './Typography';

export interface PagesNavBarItemProps {
  text: string;
  icon?: string;
  route?: string,
  onSelect?: () => void;
  selected?: boolean;
}

export interface PagesNavBarProps {
  className?: string;
  items: PagesNavBarItemProps[];
  onClickBack?: () => void;
}

const useStyles = makeStyles((theme) => createStyles({
  root: {
    display: 'grid',
    gridTemplateColumns: '1fr',
    width: '100%',
    height: '48px',
    borderTop: `1px solid ${theme.palette.grey[200]}`,
    zIndex: 101,
    boxShadow: '0px 6px 8px rgba(110, 123, 162, 0.1)',
    background: 'white',
    flexShrink: 0,
    position: 'sticky',
    top: '56px',
    [theme.breakpoints.up('sm')]: {
      '&.withBack': {
        gridTemplateColumns: '100px 1fr 100px',
      },
    },
    [theme.breakpoints.down('md')]: {
      top: '60px',
    },
  },
  content: {
    display: 'grid',
    gridAutoColumns: '1fr',
    gridAutoFlow: 'column',
    maxWidth: '940px',
    padding: '0 16px',
    flexGrow: 1,
    height: '100%',
    gap: '4px',
    overflowX: 'scroll',
    justifySelf: 'center',
    minWidth: '50%',
    '&::-webkit-scrollbar': {
      display: 'none',
    },
  },
  item: {
    color: theme.palette.grey[800],
    display: 'flex',
    gap: '8px',
    flexWrap: 'nowrap',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer',
    whiteSpace: 'nowrap',
    padding: '0 8px',
    '& img': {
      userDrag: 'none',
      userSelect: 'none',
      width: 16,
      height: 16,
    },
    '&:hover': {
      color: theme.palette.green[600],
      '& img': {
        filter: 'invert(49%) sepia(95%) saturate(401%) hue-rotate(121deg) brightness(98%) contrast(102%)',
      },
    },
  },
  selected: {
    color: theme.palette.green[600],
    position: 'relative',
    '& img': {
      filter: 'invert(49%) sepia(95%) saturate(401%) hue-rotate(121deg) brightness(98%) contrast(102%)',
    },
    '&:after': {
      content: '""',
      background: theme.palette.green[600],
      display: 'block',
      height: '4px',
      width: '100%',
      position: 'absolute',
      bottom: 0,
      borderRadius: '4px 4px 0 0',
    },
  },
  text: {
    [theme.breakpoints.down('sm')]: {
      fontSize: '12px',
      lineHeight: '20px',
    },
  },
  backButton: {
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    gap: 8,
    marginLeft: 24,
  },
}));

export const PagesNavBar = (props: PagesNavBarProps) => {
  const classes = useStyles();
  const history = useHistory();
  const ref = useRef(null);

  useEffect(() => {
    if (ref.current) {
      (ref.current as any).scrollIntoView({
        behavior: 'smooth',
        block: 'nearest',
      });
    }
  }, [ref.current]);

  const navBarItem = React.useCallback(({ icon, text, onSelect, route, selected }: PagesNavBarItemProps) => {
    const isSelected = selected !== undefined
      ? selected : route !== undefined && window.location.pathname.startsWith(route);
    return (
      <div
        key={text}
        ref={isSelected ? ref : undefined}
        className={`${classes.item} ${isSelected && classes.selected}`}
        onClick={() => {
          if (onSelect) { onSelect(); } else if (route) { history.push(route ?? ''); }
        }}
      >
        {icon && <img src={icon} alt={text} />}
        <Typography className={classes.text} variant="h5">{text}</Typography>
      </div>
    );
  }, []);

  if (props.items.length < 2) return null;

  return (
    <Box className={`${classes.root} ${props.onClickBack ? 'withBack' : ''} ${props.className}`}>
      {props.onClickBack && (
        <Hidden xsDown>
          <Box className={classes.backButton} onClick={props.onClickBack}>
            <Box width={24} height={24} display="flex">
              <SVG src="/icons/dashboard/ic_arrow_left.svg" width={24} height={24} />
            </Box>
            <Typography variant="h5" paletteColor={500}>Back</Typography>
          </Box>
        </Hidden>
      )}
      <Box className={classes.content}>
        {props.items.map((i) => (navBarItem(i)))}
      </Box>
    </Box>
  );
};
