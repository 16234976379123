import { Box, createStyles, makeStyles } from '@material-ui/core';
import { Button, Typography } from 'elements';
import { LightFrenchFries } from 'elements/LightFrenchFries';
import * as React from 'react';
import SVG from 'react-inlinesvg';
import { useStore as useTransactionStore } from 'store/zustand/Transaction';

interface Props {
  tagName: string;
  tagId?: number;
  onRemove?: () => void;
  onEdit?: () => void;
  awaitingAdd: boolean;
  awaitingEdit: boolean;
  awaitingRemove: boolean;
}

const useStyles = makeStyles((theme) => createStyles({
  tagInfoWrapper: {
    display: 'flex',
    alignItems: 'center',
    gap: 8,
    minWidth: 0,
  },
  menuGroup: {
    display: 'flex',
    alignItems: 'center',
    gap: 12,
  },
  editIconWrapper: {
    '& path': {
      fill: theme.palette.grey[400],
    },
    '& path:last-child': {
      fill: 'unset',
      stroke: theme.palette.grey[400],
    },
    '&:hover': {
      '& path:first-child': {
        fill: theme.palette.green[600],
      },
      '& path:nth-child(2)': {
        fill: theme.palette.green[600],
      },
      '& path:last-child': {
        stroke: theme.palette.green[600],
      },
    },
    '&#disabled': {
      '& path': {
        fill: theme.palette.grey[200],
      },
      '& path:last-child': {
        fill: 'unset',
        stroke: theme.palette.grey[200],
      },
    },
  },
  removeIconWrapper: {
    '&#disabled': {
      '& path:first-child': {
        fill: theme.palette.grey[200],
      },
      '& path:last-child': {
        stroke: theme.palette.grey[200],
      },
    },
    '&:hover': {
      '& path:first-child': {
        fill: theme.palette.red[600],
      },
      '& path:last-child': {
        stroke: theme.palette.red[600],
      },
    },
  },
}));
export const ManageTagRow = ({
  tagName, tagId, onRemove, onEdit, awaitingAdd, awaitingEdit, awaitingRemove,
}: Props) => {
  const classes = useStyles();
  const [tagsWithCounts] = useTransactionStore((state) => [state.tagsWithCounts]);

  return (
    <>
      <Box className={classes.tagInfoWrapper}>
        <LightFrenchFries
          noShrink
          label={tagName}
          autoAssignColor={!(awaitingAdd || awaitingRemove)}
          disabled={awaitingAdd || awaitingRemove}
        />
        <Typography
          variant="h6"
          paletteColor={500}
          strong={false}
        >
          {!awaitingAdd && `${(tagId && tagsWithCounts && tagsWithCounts[tagId]) ?? 0} transactions`}
        </Typography>
      </Box>
      {awaitingAdd ? (
        <Typography
          variant="h6"
          paletteColor={500}
          strong={false}
        >
          <SVG width={16} height={16} src="/icons/ic_time.svg" /> Awaiting for approval
        </Typography>
      ) : (
        <Box className={classes.menuGroup}>
          <Button
            tooltip={awaitingEdit ? {
              title: (
                <Box display="flex" gridGap={4} alignItems="center">
                  <SVG width={12} height={12} src="/icons/ic_time.svg" />
                  <Typography variant="h6" paletteColor={600}>Previous change is awaiting for approval</Typography>
                </Box>
              ),
              enterTouchDelay: 1,
              placement: 'top-end',
              arrow: true,
            } : undefined}
            disabled={awaitingEdit}
            variant="icon"
            onClick={onEdit}
          >
            <SVG
              className={classes.editIconWrapper}
              id={awaitingEdit ? 'disabled' : undefined}
              src="/icons/dashboard/ic_edit.svg"
              width={20}
              height={20}
            />
          </Button>
          <Button
            tooltip={awaitingRemove ? {
              title: (
                <Box display="flex" gridGap={4} alignItems="center">
                  <SVG width={12} height={12} src="/icons/ic_time.svg" />
                  <Typography variant="h6" paletteColor={600}>Previous change is awaiting for approval</Typography>
                </Box>
              ),
              enterTouchDelay: 1,
              placement: 'top-end',
              arrow: true,
            } : undefined}
            disabled={awaitingRemove}
            variant="icon"
            className={classes.removeIconWrapper}
            onClick={onRemove}
          >
            <SVG
              className={classes.removeIconWrapper}
              id={awaitingRemove ? 'disabled' : undefined}
              src="icons/dashboard/ic_trash.svg"
              width={20}
              height={20}
            />
          </Button>
        </Box >
      )}
    </>
  );
};
