import { createStyles } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import React from 'react';
import SVG from 'react-inlinesvg';

const useStyles = makeStyles((theme) => createStyles({
  bgIcon: {
    position: 'absolute',
    '&.usdc': {
      top: 130,
      left: '10%',
      width: 46,
      height: 46,
      [theme.breakpoints.down('sm')]: {
        width: 28,
        height: 28,
        top: -8,
        right: -14,
        left: 'unset',
      },
    },
    '&.coinbag': {
      top: 250,
      right: -45,
      width: 124,
      height: 124,
      [theme.breakpoints.down('sm')]: {
        display: 'none',
      },
    },
    '&.btc': {
      top: 450,
      left: -35,
      width: 96,
      height: 96,
      [theme.breakpoints.down('sm')]: {
        top: 'min(228px, 40%)',
      },
    },
    '&.eth': {
      top: 620,
      right: '10%',
      width: 70,
      height: 70,
      [theme.breakpoints.down('sm')]: {
        display: 'none',
      },
    },
    '&.bnb': {
      width: 44,
      height: 44,
      [theme.breakpoints.up('md')]: {
        display: 'none',
      },
      [theme.breakpoints.down('sm')]: {
        top: 'min(60%, 439px)',
        right: -20,
      },
    },
    '&.paxos': {
      width: 36,
      height: 36,
      top: 328,
      left: 'max(504px, 45%)',
      [theme.breakpoints.down('sm')]: {
        display: 'none',
      },
    },
    '&.vai': {
      width: 36,
      height: 36,
      bottom: -12,
      left: 'max(408px, 40%)',
      [theme.breakpoints.down('sm')]: {
        display: 'none',
      },
    },
  },
}));

export const Background = () => {
  const classes = useStyles();
  return (
    <>
      {/* USDC */}
      <SVG className={`${classes.bgIcon} usdc`} src="/icons/wallet_reporting/ic_bg_1.svg" />
      {/* Coinbag */}
      <SVG className={`${classes.bgIcon} coinbag`} src="/icons/wallet_reporting/ic_bg_2.svg" />
      {/* BTC */}
      <SVG className={`${classes.bgIcon} btc`} src="/icons/wallet_reporting/ic_bg_3.svg" />
      {/* BNB */}
      <SVG className={`${classes.bgIcon} bnb`} src="/icons/wallet_reporting/ic_bg_4.svg" />
      {/* ETH */}
      <SVG className={`${classes.bgIcon} eth`} src="/icons/wallet_reporting/ic_bg_5.svg" />
      <SVG className={`${classes.bgIcon} paxos`} src="/icons/wallet_reporting/ic_paxos.svg" />
      <SVG className={`${classes.bgIcon} vai`} src="icons/wallet_reporting/ic_vai.svg" />
    </>
  );
};
