import { Box } from '@material-ui/core';
import { Button, LightModal, Typography } from 'elements';
import React from 'react';

import { DashboardRoute, ReportingRoute } from '../../routes';

interface Props {
  open: boolean;
  setOpen: (value: boolean) => void;
  action: string;
}

export const TransactionCreatedModal = ({ open, setOpen, action }: Props) => {


  return (
    <LightModal
      open={open}
      setOpen={setOpen}
      maxWidth={380}
      modalTitle="Transaction created"
      showCloseButton
    >
      <Typography variant="h6" paletteColor={800} align="center">
        Please sign your transaction to proceed the {action}.
      </Typography>
      <Box display="flex" flexDirection="column" width="100%" gridRowGap={8}>
        <Button href={DashboardRoute}>
          Back to dashboard
        </Button>
        <Button href={ReportingRoute} variant="secondary">
          View on reporting
        </Button>
      </Box>
    </LightModal>
  );
};
