export enum LoginSteps {
  Form,
  TwoFactorAuthentication,
}

export enum VerificationStatus {
  None,
  Verified,
  ExpiredOrAlreadyVerified
}
