import React from 'react';
import SharedStateComponent from '../components/SharedStateComponent.js';

import AuthService from '../services/auth-service.js';

import { Container, Row, Col, Modal } from 'react-bootstrap';
import { ExitGreyIcon, LogoAndBinanceIcon } from './Icons';

export default class BinanceModal extends SharedStateComponent {
  constructor(props) {
    super(props);
    this.goToBinance = this.goToBinance.bind(this);
  }

  goToBinance() {
    const win = window.open('https://accounts.binance.com/en/register?ref=LKWS38TB', '_blank');
    win.focus();

    if (this.props.next) {
      AuthService.updateUserStep()
        .then(() => {
          this.props.history.push(this.props.next);
        });
    } else if (this.props.dismissOnClick) {
      this.props.hide ? this.props.hide() : this.updateState(false);
    }
  }

  render() {
    const handleClose = () => {
      this.props.hide ? this.props.hide() : this.updateState(false);
    };

    return (
      <Modal
        centered
        show={this.props.show ? this.props.show : this.getState()}
        onHide={handleClose}
      >
        <Modal.Body className="p-4">
          <Container>
            <Row className="mt-0 justify-content-end">
              <Col className="text-right pr-0" xs={3} onClick={handleClose}>
                <ExitGreyIcon className="pointer"/>
              </Col>
            </Row>

            <Row><Col className="text-center">
              <LogoAndBinanceIcon/>
            </Col></Row>

            <Row className="mt-4">
              <Col>
                <h5 className="text-center mb-3">
                  You’ll be redirected to Binance.com to create an account
                </h5>

                <p>
                  We use Binance to securely buy and sell cryptocurrencies for your portfolio.
                  This means you will need to create an account on Binance, then connect said account to Coinbag.
                </p>

                <p className="mb-0"><b>On Binance, you will:</b></p>
                <ol type="1">
                  <li>Create an account (it’s free)</li>
                  <li>Add or transfer funds to this account</li>
                  <li>Come back to Coinbag to finish set up
                  </li>
                </ol>
              </Col>
            </Row>

            <Row><Col>
              <button className="btn-inverse btn-block" onClick={this.goToBinance}>
                Proceed to Binance
              </button>
            </Col></Row>
          </Container>
        </Modal.Body>
      </Modal>
    );
  }
}
