import { Box, createStyles, MenuItem } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { WalletRow } from 'components/dashboard/MultiWallets/WalletRow';
import { Typography } from 'elements';
import { LightSelect } from 'elements/LightSelect';
import { UserType } from 'models';
import React, { useEffect } from 'react';
import { useStore as useUserStore } from 'store/zustand/User';
import { useStore as useWalletStore } from 'store/zustand/Wallet';

interface Props {
  selectedWalletId?: number;
  // eslint-disable-next-line no-unused-vars
  setSelectedWalletId: (value?: number) => void;
  disabled?: boolean;
}

const useStyles = makeStyles((theme) => createStyles({
  placeholder: {
    display: 'flex',
    alignItems: 'center',
    gap: 4,
    fontWeight: 400,
  },
  selectHeader: {
    color: theme.palette.grey[500],
    fontWeight: 400,
    fontSize: 12,
    lineHeight: '20px',
  },
  menuItem: {
    borderBottom: `1px solid ${theme.palette.grey[200]}`,
    '&.Mui-selected': {
      backgroundColor: theme.palette.green[100],
      '&:hover': {
        backgroundColor: theme.palette.green[100],
      },
    },
  },
}));

export const SelectWallet = ({ selectedWalletId, setSelectedWalletId, disabled }: Props) => {
  const classes = useStyles();
  const [walletInfos, fetchWallet] = useWalletStore((state) => [state.walletInfos, state.fetchWallet]);
  const [user, fetchUser] = useUserStore((state) => [state.user, state.fetchUser]);

  useEffect(() => {
    (async () => { await Promise.all([fetchWallet(false), fetchUser(false)]); })();
  }, []);

  const Placeholder = () => (
    <Box className={classes.placeholder}>
      <Typography variant="h6" className={classes.selectHeader}>
        Select wallet
      </Typography>
    </Box>
  );

  return (
    <LightSelect
      disabled={disabled}
      renderValue={() => {
        const walletInfo = walletInfos?.find((info) => info.walletId === selectedWalletId);
        return (walletInfo ? (
          <WalletRow
            selected={false}
            walletInfo={walletInfo}
          />
        ) : <Placeholder />);
      }}
      value={selectedWalletId ?? -1}
      onChange={(event) => { setSelectedWalletId(event.target.value as number); }}
    >
      {walletInfos && walletInfos
        .filter((wi) => (user?.userType === UserType.Individual && !wi.isOwn)
        || (user?.userType === UserType.Institutional && wi.isOwn))
        .map((wi) => (
          <MenuItem
            value={wi.walletId}
            key={wi.walletId}
            className={classes.menuItem}
          >
            <WalletRow selected={wi.walletId === selectedWalletId} walletInfo={wi} />
          </MenuItem>
        ))}
    </LightSelect>
  );
};
