import { createStyles } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import React, { useEffect, useState } from 'react';

import { KycAdminRoute } from '../../routes';
import { UserInfo } from '../../services/auth-service';
import UserService from '../../services/user-service';

const useStyles = makeStyles(() => createStyles({
  root: {
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
  },
  table: {display: 'flex'},
  tableCell: {
    border: '1px solid black',
  },
}));

const KycAdmin = () => {
  const [users, setUsers] = useState<UserInfo[] | null>(null);

  const classes = useStyles();

  useEffect(() => {
    (async () => {
      const userlist = await UserService.getUnfinishedKYCUserList();
      setUsers(userlist);
    })();
  }, []);
  if (users == null) return null;
  return (
    <div className={classes.root}>
      <table>
        <tr>
          <th className={classes.tableCell}>User Id</th>
          <th className={classes.tableCell}>Name</th>
          <th className={classes.tableCell}>Email Address</th>
        </tr>
        { users && users.map((user) => (
          <>
            <tr key={user.userId}>
              <td className={classes.tableCell}>{user.userId}</td>
              <td className={classes.tableCell}>{`${user.firstName ? user.firstName : ''} ${user.lastName ? user.lastName : ''}`}</td>
              <td className={classes.tableCell}>{user.email}</td>
            </tr>
          </>
        ))}
      </table>

    </div>
  );
};

KycAdmin.routePath = KycAdminRoute;
KycAdmin.adminRequire = true;
KycAdmin.loginRequire = true;

export default KycAdmin;
