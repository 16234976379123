import { Box, makeStyles } from '@material-ui/core';
import React, { FC, useEffect } from 'react';
import { useStore as useUserStore } from 'store/zustand/User';
import { useStore as useWalletStore } from 'store/zustand/Wallet';

import { RebalancingStatus } from '../../../services/types/wallet';

type ManagePortfolioSectionProps = {
  className?: string;
  rebalancingStatus: RebalancingStatus
}

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr 1fr',
    columnGap: 16,

    [theme.breakpoints.down('sm')]: {
      gridTemplateColumns: '1fr',
      rowGap: 16,
    },
  },
}));

const BundleAction = React.lazy(() => import('./BundleAction'));
const InterestAction = React.lazy(() => import('./InterestAction'));
const RebalancingAction = React.lazy(() => import('./RebalancingAction'));

const ManagePortfolioSection: FC<ManagePortfolioSectionProps> = ({ className, rebalancingStatus }) => {
  const classes = useStyles();
  const { user, fetchUser } = useUserStore();
  const [wallet] = useWalletStore((state) => [state.wallet]);

  useEffect(() => {
    fetchUser(false);
  }, []);

  if (!user || user.isReadOnly || wallet?.walletId === undefined) return null;

  return (
    <Box className={`${classes.root} ${className}`}>
      <React.Suspense fallback={<></>}>
        <BundleAction />
        <InterestAction />
        <RebalancingAction rebalancingStatus={rebalancingStatus} />
      </React.Suspense>
    </Box>
  );
};

ManagePortfolioSection.displayName = 'ManagePortfolioSection';
export { ManagePortfolioSection };
