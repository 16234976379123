import { Box, createStyles, Hidden, makeStyles, useMediaQuery, useTheme } from '@material-ui/core';
import { Loading } from 'components';
import NotificationItem from 'components/Notifications/NotificationItem';
import { TransactionApprovalItemProps } from 'components/Notifications/TransactionApprovalItem';
import { UserEventItemProps } from 'components/Notifications/UserEventItem';
import TransactionApprovalModal from 'components/TransactionApproval/TransactionApprovalModal';
import { Typography } from 'elements';
import { PagesNavBar, PagesNavBarItemProps } from 'elements/PagesNavBar';
import { Scrollable } from 'elements/Scrollable';
import React from 'react';
import SVG from 'react-inlinesvg';
import { useHistory } from 'react-router-dom';
import { DashboardRoute, NotificationsAndRequestsRoute } from 'routes';
import { isApproverWorkItem } from 'services/types/workItem';
import { useStore as useCoinStore } from 'store/zustand/Coin';
import { useStore as useUserStore } from 'store/zustand/User';
import { useStore as useWorkItemStore } from 'store/zustand/WorkItem';

const useStyles = makeStyles((theme) => createStyles({
  root: {
    display: 'flex',
    width: '100%',
    justifyContent: 'stretch',
    height: '100%',
    flexDirection: 'column',
  },
  content: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    padding: 24,
    flexGrow: 1,
  },
  mobileContent: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
  },
  mobileHeader: {
    position: 'sticky',
    background: theme.palette.common.white,
    zIndex: 2,
    top: 60,
    padding: 16,
    display: 'flex',
    gap: 4,
    alignItems: 'center',
    borderBottom: `1px solid ${theme.palette.grey[200]}`,
  },
  table: {
    maxWidth: 1136,
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    boxShadow: '0px 4px 32px 0px rgba(58, 60, 77, 0.04)',
    borderRadius: 8,
    flexGrow: 1,
    position: 'relative',
  },
  tableHeader: {
    padding: '12px 16px',
    borderBottom: `1px solid ${theme.palette.grey[200]}`,
    '&#requests': {
      display: 'grid',
      gridTemplateColumns: '1fr 120px 140px 120px',
    },
    '&#notifications': {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
    },
  },
  scroll: {
    position: 'relative',
    flexGrow: 1,
    minHeight: 0,
  },
  loading: {
    position: 'absolute',
    top: 0,
    left: 0,
    zIndex: 2,
    backdropFilter: 'blur(12px)',
    background: 'rgba(255, 255, 255, 0.6)',
  },
  noData: {
    color: theme.palette.grey[500],
    fontSize: 18,
    lineHeight: '24px',
    textAlign: 'center',
    margin: 'auto',
    alignSelf: 'center',
    justifySelf: 'center',
  },
}));

const NotificationsAndRequests = () => {
  const classes = useStyles();
  const history = useHistory();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('xs'));

  const [user, userEvents, fetchEvents] = useUserStore((state) => [state.user, state.userEvents, state.fetchEvents]);
  const [workItems, fetchWorkItems] = useWorkItemStore((state) => [state.workItems, state.fetchWorkItems]);
  const [fetchCoins] = useCoinStore((state) => [state.fetchCoins]);

  const [displayMode, setDisplayMode] = React.useState<'requests' | 'notifications'>('requests');
  const [modalItem, setModalItem] = React.useState<number | undefined>(undefined);
  const [modalOpen, setModalOpen] = React.useState(false);

  React.useEffect(() => {
    fetchWorkItems(true);
    fetchEvents(true);
    fetchCoins();
  }, []);

  const navItems = React.useMemo(() => ([
    {
      icon: '/icons/notifications/ic_requests.svg',
      text: 'Requests',
      onSelect: () => { setDisplayMode('requests'); },
      selected: displayMode === 'requests',
    },
    {
      icon: '/icons/notifications/ic_notifications.svg',
      text: 'Notifications',
      onSelect: () => { setDisplayMode('notifications'); },
      selected: displayMode === 'notifications',
    },
  ] as PagesNavBarItemProps[]), [displayMode]);

  const approvals = React.useMemo(() => (
    workItems
      ?.filter((item) => (
        isApproverWorkItem(item.name)
      ))
      .map((item): TransactionApprovalItemProps => ({
        item,
        creation: new Date(item.lastUpdatedTs),
        type: 'transactionApproval',
        onSeeDetail: () => {
          setModalItem(item.workItemId);
          setModalOpen(true);
        },
      }))
      .sort((a, b) => (b.creation.getTime() - a.creation.getTime()))
  ), [workItems, user]);

  const events = React.useMemo(() => (
    userEvents
      ?.map((event): UserEventItemProps => ({
        title: event.title,
        summary: event.summary,
        creation: new Date(event.timestamp),
        eventType: event.eventType,
        coin: event.coin,
        type: 'UserEvent',
      }))
      .sort((a, b) => (b.creation.getTime() - a.creation.getTime()))
  ), [workItems]);

  return (
    <Box className={classes.root}>
      <Hidden smUp>
        <Box className={classes.mobileContent}>
          <Box className={classes.mobileHeader}>
            <Box
              width={24}
              height={24}
              display="flex"
              onClick={() => history.push(DashboardRoute)}
              style={{ cursor: 'pointer' }}
            >
              <SVG src="/icons/dashboard/ic_arrow_left.svg" width={24} height={24} />
            </Box>
            <Typography variant="h4" paletteColor={700}>Notifications & Requests</Typography>
          </Box>
          <Scrollable className={classes.scroll}>
            {(approvals === undefined || events === undefined)
              && <Loading className={classes.loading} />}
            {approvals?.length === 0 && events?.length === 0
              && <Typography className={classes.noData}>No data</Typography>}
            {approvals?.map((notification, index) => (
              <NotificationItem key={index} {...notification} />
            ))}
            {events?.map((notification, index) => (
              <NotificationItem key={index} {...notification} />
            ))}
          </Scrollable>
        </Box>
      </Hidden>
      <Hidden xsDown>
        <PagesNavBar items={navItems} onClickBack={() => history.push(DashboardRoute)} />
        <Box className={classes.content}>
          <Box className={classes.table}>
            <Box className={classes.tableHeader} id={displayMode}>
              {((displayMode === 'requests' && approvals === undefined)
                || (displayMode === 'notifications' && events === undefined))
                && <Loading className={classes.loading} />}
              {displayMode === 'requests' && (
                <>
                  <Typography variant="h4" paletteColor={400}>Request</Typography>
                  <Typography variant="h4" paletteColor={400}>Status</Typography>
                  <Typography variant="h4" paletteColor={400}>Date</Typography>
                </>
              )}
              {displayMode === 'notifications' && (
                <>
                  <Typography variant="h4" paletteColor={400}>Event and description</Typography>
                  <Typography variant="h4" paletteColor={400}>Date</Typography>
                </>
              )}
            </Box>
            {((displayMode === 'requests' && approvals?.length === 0)
              || (displayMode === 'notifications' && events?.length === 0))
              && <Typography className={classes.noData}>No data</Typography>}
            {displayMode === 'requests' && approvals?.map((notification, index) => (
              <NotificationItem key={index} {...notification} fullSize={!isMobile} />
            ))}
            {displayMode === 'notifications' && events?.map((notification, index) => (
              <NotificationItem key={index} {...notification} fullSize={!isMobile} />
            ))}
          </Box>
        </Box>
      </Hidden>
      <TransactionApprovalModal
        open={modalOpen}
        setOpen={setModalOpen}
        id={modalItem}
        isWorkItemAvailable
        openNotificationBox={() => { setModalOpen(false); }}
      />
    </Box>
  );
};

NotificationsAndRequests.title = 'Notifications & Requests';
NotificationsAndRequests.routePath = NotificationsAndRequestsRoute;
NotificationsAndRequests.loginRequired = true;

export default NotificationsAndRequests;
