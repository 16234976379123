import { Box, makeStyles } from '@material-ui/core';
import { Typography } from 'elements/Typography';
import { DepositEvent } from 'models';
import React, { FC } from 'react';
import { useHistory } from 'react-router-dom';
import { DepositRoute, InterestSettingRoute, PortfolioSettingRoute, RebalanceSettingRoute } from 'routes';
import { WorkItemName } from 'services/types/workItem';
import { useStore as useBundleStore } from 'store/zustand/Bundle';
import { useStore as useFeatureStore } from 'store/zustand/Feature';
import { useStore as useInterestEarningStore } from 'store/zustand/InterestEarning';
import { useStore as useUserStore } from 'store/zustand/User';
import { useStore as useWalletStore } from 'store/zustand/Wallet';

import { SignUpFlowStep, SignUpFlowStepProps, StateStep } from './SignUpFlowStep';

const useStyles = makeStyles((theme) => ({
  root: {
    background: '#F7F8FD',
    display: 'flex',
    flexDirection: 'column',
    marginTop: '-20px',
    [theme.breakpoints.up('sm')]: {
      margin: '-24px -24px 0',
    },
  },
  header: {
    background: theme.palette.blue[400],
    boxShadow: '0px 8px 24px rgba(64, 120, 232, 0.22)',
    borderBottomLeftRadius: '36px',
    borderBottomRightRadius: '36px',
    padding: '24px',
    paddingBottom: '136px',
    gap: '20px',
    display: 'flex',
    flexDirection: 'column',
    overflow: 'hidden',
    position: 'relative',
  },
  title: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'nowrap',
    color: 'white',
    alignItems: 'center',
    gap: '8px',
    width: '100%',
    justifyContent: 'space-between',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      alignItems: 'flex-start',
      '& h1': {
        fontSize: 24,
      },
      '& h5': {
        fontSize: 14,
      },
    },
  },
  stepWrapper: {
    marginTop: '-116px',
    position: 'relative',
    '& .owl-stage-outer': {
      overflow: 'visible',
    },
    '& .owl-item': {
      '&:first-of-type': {
        marginLeft: '8px',
        [theme.breakpoints.down('md')]: {
          marginLeft: '16px',
        },
        [theme.breakpoints.down('sm')]: {
          marginLeft: '0px',
        },
        '& .dashline:first-of-type': {
          visibility: 'hidden',
        },
      },
      '&:last-of-type': {
        marginRight: '4px',
        [theme.breakpoints.down('md')]: {
          marginRight: '8px',
        },
        [theme.breakpoints.down('sm')]: {
          marginRight: '-4px',
        },
        '& .dashline:last-of-type': {
          visibility: 'hidden',
        },
      },
    },
  },
  bg: {
    position: 'absolute',
    '&#bg1': {
      right: '0px',
      top: '-48px',
    },
    '&#bg2': {
      right: '-16px',
      top: '-72px',
    },
  },
  oldWelcome: {
    margin: '0 16px',
    [theme.breakpoints.up('sm')]: {
      margin: 0,
    },
  },
}));

const initCarousel = (container: React.MutableRefObject<null>, selectedIndex: number, totalSteps: number) => {
  const { jQuery } = window as any;
  jQuery(container.current).owlCarousel({
    nav: false,
    smartSpeed: 1000,
    startPosition: 0,
    dots: false,
    responsive: {
      0: {
        center: true,
        items: 1,
        stagePadding: 36,
        startPosition: selectedIndex,
      },
      600: {
        center: false,
        mouseDrag: false,
        touchDrag: false,
        startPosition: 0,
        items: totalSteps,
      },
    },
  });
};

const tryInitCarousel = (container: React.MutableRefObject<null>, selectedIndex: number, totalSteps: number) => {
  setTimeout(() => {
    try {
      initCarousel(container, selectedIndex, totalSteps);
    } catch (e) {
      setTimeout(() => tryInitCarousel(container, selectedIndex, totalSteps), 1000);
    }
  }, 0);
};

interface SignUpFlowProps {
  deposits?: DepositEvent[];
}

export const SignUpFlow: FC<SignUpFlowProps> = ({ deposits }) => {
  const classes = useStyles();
  const history = useHistory();
  const container = React.useRef(null);

  const [user] = useUserStore((state) => [state.user]);
  const [
    fetchCurrentProtocol,
    currentProtocol,
  ] = useInterestEarningStore((state) => [
    state.fetchCurrentProtocol,
    state.currentProtocol,
  ]);
  const [wallet, isRebalancingWallet] = useWalletStore((state) => [
    state.wallet, state.isRebalancingWallet]);
  const [
    fetchCurrentBundle,
    currentBundle,
  ] = useBundleStore((state) => [
    state.fetchCurrentBundle,
    state.currentBundle,
  ]);

  const [findPendingAction, features] = useFeatureStore((state) => [state.findPendingAction, state.features]);

  const hasDeposited = (deposits ?? []).length > 0;

  const pendingBundleSettingAction = React.useMemo(() => ([
    ...(findPendingAction(WorkItemName.AddUserBundle) ?? []),
    ...(findPendingAction(WorkItemName.CreateOrEditBundle) ?? []),
    ...(findPendingAction(WorkItemName.SetBasicWallet) ?? []),
  ].length > 0), [features]);

  const pendingInterestSettingAction = React.useMemo(() => (
    (findPendingAction(WorkItemName.SetEarningConfig) ?? []).length > 0
  ), [features]);

  const pendingRebalanceSettingAction = React.useMemo(() => (
    (findPendingAction(WorkItemName.SetRebalancingMode) ?? []).length > 0
  ), [features]);

  const registerStep = React.useMemo(() => (
    hasDeposited
      ? (wallet?.registerStep?.crypto || currentBundle || pendingBundleSettingAction)
        ? (wallet?.registerStep?.interest || currentProtocol || pendingInterestSettingAction)
          ? (!isRebalancingWallet || wallet?.registerStep?.rebalance || pendingRebalanceSettingAction)
            ? 5 : 4 : 3 : 2 : 1
  ), [wallet, deposits, isRebalancingWallet, pendingInterestSettingAction, pendingBundleSettingAction, pendingRebalanceSettingAction]);

  React.useEffect(() => {
    const fetchData = async () => {
      if (user) {
        await fetchCurrentProtocol(true);
        await fetchCurrentBundle(true);
      }
    };

    fetchData();
  }, [user]);

  const steps: SignUpFlowStepProps[] = React.useMemo(() => {
    const value = [
      {
        stepTitle: 'Step 1',
        icon: '/icons/dashboard/signupstep/ic_step4.png',
        title: 'Make first deposit',
        time: '2-15 min',
        state: hasDeposited ? StateStep.DONE : StateStep.ACTIVE,
        buttonAction: () => {
          history.push(DepositRoute);
        },
      },
      {
        stepTitle: 'Step 2',
        icon: '/icons/dashboard/signupstep/ic_step1.png',
        title: 'Choose wallet type',
        time: '3-5 min',
        state: (wallet?.registerStep?.crypto || currentBundle || pendingBundleSettingAction)
          ? StateStep.DONE : StateStep.ACTIVE,
        buttonAction: () => {
          history.push(PortfolioSettingRoute);
        },
      },
      {
        stepTitle: 'Step 3',
        icon: '/icons/dashboard/signupstep/ic_step2.png',
        title: 'Set interest methods',
        time: '1 min',
        state: (wallet?.registerStep?.interest || currentProtocol || pendingInterestSettingAction)
          ? StateStep.DONE : StateStep.ACTIVE,
        buttonAction: () => {
          history.push(InterestSettingRoute);
        },
      },
    ];
    if (isRebalancingWallet) {
      value.push(
        {
          stepTitle: 'Step 4',
          icon: '/icons/dashboard/signupstep/ic_step3.png',
          title: 'Set rebalancing cadence',
          time: '1 min',
          state: (wallet?.registerStep?.rebalance || pendingRebalanceSettingAction)
            ? StateStep.DONE : StateStep.ACTIVE,
          buttonAction: () => {
            history.push(RebalanceSettingRoute);
          },
        },
      );
    }
    return value;
  }, [
    wallet, deposits, isRebalancingWallet,
    pendingBundleSettingAction, pendingInterestSettingAction, pendingRebalanceSettingAction]);

  React.useEffect(() => {
    if (container.current && wallet) {
      tryInitCarousel(container, registerStep - 1, steps.length);
    }
  }, [wallet, container.current, steps]);

  if (user === undefined
    || wallet === undefined
    || deposits === undefined
    || registerStep === 5
    || user?.isReadOnly
    || wallet.exchangeId === 1) {
    return (
      <Box className={classes.oldWelcome}>
        <Typography variant="h2">Welcome to your Coinbag!</Typography>
      </Box>
    );
  }
  return (
    <Box className={classes.root}>
      <Box className={classes.header}>
        <img className={classes.bg} src="/icons/dashboard/signupstep/bg1.svg" id="bg1" alt="bg1" />
        <img className={classes.bg} src="/icons/dashboard/signupstep/bg1.svg" id="bg2" alt="bg2" />
        <Box className={classes.title}>
          <Typography variant="h1">Welcome to your Coinbag!</Typography>
          <Typography variant="h5">Complete the following steps to get started</Typography>
        </Box>
      </Box>
      <div className={`${classes.stepWrapper} owl-carousel`} ref={container}>
        {steps.map((step) => (
          <SignUpFlowStep
            key={step.title}
            {...step}
          />
        ))}
      </div>
    </Box>
  );
};
