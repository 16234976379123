import { Box, createStyles } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Button, InputWithTitle, LightModal, Typography } from 'elements';
import { LightTextField } from 'elements/LightTextField';
import React, { useEffect, useState } from 'react';
import { useStore as useFeatureStore } from 'store/zustand/Feature';
import { useStore as useTransactionStore } from 'store/zustand/Transaction';
import { useStore as useWalletStore } from 'store/zustand/Wallet';

import { SelectWallet } from '../BatchPayroll/SelectWallet';

const EVM_ADDRESS_FORMAT = /\b0x([a-z, A-Z\d]){40}\b/;
const TERRA_ADDRESS_FORMAT = /\bterra([a-z,A-Z\d]){39}\b/;

interface Props {
  showModal: boolean
  // eslint-disable-next-line no-unused-vars
  setShowModal: (value: boolean) => void;
  initAddress?: string;
  // eslint-disable-next-line no-unused-vars
  setInitAddress: (value: string | undefined) => void;
  initTag?: string;
  initWalletId?: number;
  // eslint-disable-next-line no-unused-vars
  setInitWalletId: (value: number | undefined) => void;
  // eslint-disable-next-line no-unused-vars
  setInitTag: (value: string | undefined) => void;
  editAddressBookId?: number;
  // eslint-disable-next-line no-unused-vars
  setEditAddressBookId: (value?: number) => void;
}

const useStyles = makeStyles(() => createStyles({
  root: {
    display: 'flex',
    flexDirection: 'column',
    position: 'relative',
    gap: 16,
    width: '100%',
    height: '100%',
  },
  buttonWrapper: {
    display: 'flex',
    width: '100%',
    justifyContent: 'space-between',
  },
  button: {
    minWidth: 84,
  },
  errorText: {
    fontSize: 12,
    fontWeight: 400,
    textAlign: 'end',
  },
}));

export const AddAddressBookModal = ({
  showModal, setShowModal, initAddress, setInitAddress, initTag, setInitTag, editAddressBookId, setEditAddressBookId,
  initWalletId, setInitWalletId,
}: Props) => {
  const classes = useStyles();

  const [addAddressBook, addressBooks] = useTransactionStore((state) => [state.addAddressBook, state.addressBooks]);
  const [isAllWallets] = useWalletStore((state) => [state.isAllWallets]);
  const [fetchFeatures] = useFeatureStore((state) => [state.fetchFeatures]);

  const [address, setAddress] = useState<string>(initAddress ?? '');
  const [tag, setTag] = useState<string>(initTag ?? '');
  const [addressError, setAddressError] = useState<boolean>(false);
  const [tagError, setTagError] = useState<boolean>(false);
  const [disableButtons, setDisableButtons] = useState<boolean>(false);
  const [selectedWalletId, setSelectedWalletId] = useState<number | undefined>();

  const handleAddressInput = (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    if (e.target.value.match(EVM_ADDRESS_FORMAT) || e.target.value.match(TERRA_ADDRESS_FORMAT)) setAddressError(false);
    setAddress(e.target.value);
  };

  const handleAddressBlur = () => {
    if (!address.match(EVM_ADDRESS_FORMAT) && !address.match(TERRA_ADDRESS_FORMAT)) {
      setAddressError(true);
    } else setAddressError(false);
  };

  const handleTagInput = (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    setTag(e.target.value);
    if (e.target.value !== '') setTagError(false);
  };

  const handleTagBlur = () => {
    if (tag === '') {
      setTagError(true);
    }
  };

  const handleOnClose = () => {
    setShowModal(false);
    setTimeout(() => {
      setTag('');
      setAddress('');
      setAddressError(false);
      setTagError(false);
      setInitAddress(undefined);
      setInitTag(undefined);
      setEditAddressBookId(undefined);
      setInitWalletId(undefined);
    }, 300);
  };

  const handleSave = async () => {
    setDisableButtons(true);
    const walletId = isAllWallets() ? selectedWalletId : undefined;
    await addAddressBook(address.trim(), tag, editAddressBookId, walletId);
    await fetchFeatures(true);
    handleOnClose();
    setDisableButtons(false);
  };

  useEffect(() => {
    setAddress(initAddress ?? '');
  }, [initAddress]);

  useEffect(() => {
    setTag(initTag ?? '');
  }, [initTag]);

  useEffect(() => {
    setSelectedWalletId(initWalletId);
  }, [initWalletId]);

  useEffect(() => {
    const addressBook = addressBooks?.find((ab) => ab.addressBookId === editAddressBookId);
    if (addressBook) {
      setAddress(addressBook.address);
      setTag(addressBook.tag);
      setSelectedWalletId(addressBook.walletId);
    }
  }, [editAddressBookId]);

  return (
    <LightModal
      open={showModal}
      setOpen={setShowModal}
      maxWidth={372}
      onClose={handleOnClose}
      showCloseButton
      modalTitle={editAddressBookId ? 'Edit address book' : 'Add address'}
    >
      <Box className={classes.root}>
        <InputWithTitle
          title="Address"
          titleRightItem={addressError && (
            <Typography className={classes.errorText} palette="red" paletteColor={600}>
              Invalid wallet address
            </Typography>
          )}
        >
          <LightTextField
            multiline
            value={address}
            onChange={handleAddressInput}
            onBlur={handleAddressBlur}
            error={addressError}
            placeholder="Wallet address"
          />
        </InputWithTitle>
        <InputWithTitle
          title="Address Name"
          titleRightItem={tagError && (
            <Typography className={classes.errorText} palette="red" paletteColor={600}>Invalid name</Typography>
          )}
        >
          <LightTextField
            value={tag}
            onChange={handleTagInput}
            placeholder="Name your address"
            onBlur={handleTagBlur}
            error={tagError}
          />
        </InputWithTitle>
        {isAllWallets() && (
          <InputWithTitle title="Save to">
            <SelectWallet
              selectedWalletId={selectedWalletId}
              setSelectedWalletId={setSelectedWalletId}
              disabled={editAddressBookId !== undefined}
            />
          </InputWithTitle>
        )}
        <Box className={classes.buttonWrapper}>
          <Button
            variant="secondary"
            size="md"
            disabled={disableButtons}
            onClick={handleOnClose}
            className={classes.button}
          >
            Cancel
          </Button>
          <Button
            size="md"
            onClick={handleSave}
            disabled={tagError || addressError || !tag || !address || disableButtons || (isAllWallets() && !selectedWalletId)}
            className={classes.button}
          >
            Save
          </Button>
        </Box>
      </Box>
    </LightModal>
  );
};
