import React from "react";

import ReactTooltip from "react-tooltip";
import { Col, Container, Row } from 'react-bootstrap';

import SharedStateComponent from "../../components/SharedStateComponent.js";
import { InternalNavbar } from "../../components/navbar.js";
import { Footer } from "../../components/Footer.js";
import Loader from "../../components/Loader.js";

import { CopyIcon } from "../../components/Icons.js";

import { HelpConnectBinanceRoute } from '../../routes';
import ConnectExchange from "./ConnectExchange.js";
import EditConnection from "./EditConnection.js";

export default class StepByStep extends SharedStateComponent {
  static routePath = HelpConnectBinanceRoute;
  static loginRequired = true;

  constructor(props) {
    super(props);

    this.videoSetup = this.videoSetup.bind(this)
    this.goBackToExchangeConnection = this.goBackToExchangeConnection.bind(this)

    this.state = {
      form: {},
    }
  }

  videoSetup() {
    if (!this.state.video) {
      document.body.style.height = "100%";
    } else {
      document.body.style.height = "";
    }
    this.setState({ video: !this.state.video })
  }

  goBackToExchangeConnection() {
    const allowedBack = new Set([EditConnection.routePath, ConnectExchange.routePath])
    if (allowedBack.has(this.props.location.state?.from)) {
      this.props.history.goBack()
    } else {
      this.props.history.push(EditConnection.routePath)
    }
  }

  render() {
    return (
      <>
        <Loader loading={false} />

        <InternalNavbar history={this.props.history} title="Step-by-step guide" />

        <div className="desktop">
          <Container fluid className={this.state.video ? "px-0" : ""}>
            <Row className="mt-0">
              <Col className="pt-4">
                <div className={("fade modal-backdrop") + (this.state.video ? " show" : " invisible")}
                     onClick={this.videoSetup}>
                </div>
                <video autoPlay
                       controls={this.state.video}
                       controlsList="nodownload nofullscreen"
                       disablePictureInPicture
                       muted={!this.state.video}
                       className={!this.state.video ? "step-video" : "full-step-video"}
                       onClick={this.videoSetup}>
                  <source src="../videos/step-by-step-desktop.mp4" type="video/mp4" />
                  Your browser does not support the video tag.
                </video>
              </Col>
            </Row>
          </Container>
          <Container fluid className="naked">
            <Row className="mt-0">
              <Col>
                <p className="mb-0"><b>Step 1 - Log in/Create account</b></p>
                <ol>
                  <li>Go to binance.com</li>
                  <li>Log in OR create a free account</li>
                </ol>
              </Col>
            </Row>
            <Row className="mt-3">
              <Col>
                <p className="mb-0"><b>Step 2 - Create API</b></p>
                <ol>
                  <li>On binance, find and click API Management</li>
                  <li>At the top enter API name (ex. coinbag) and click Create API</li>
                  <li>Find an enter your verification code to create the API</li>
                </ol>
              </Col>
            </Row>
            <Row className="mt-3">
              <Col>
                <p className="mb-0"><b>Step 3 - Ensure proper API settings</b></p>
                <ol>
                  <li>Click to expand the API information</li>
                  <li>Ensure "Enable Read Access" and "Enable Spot & Margin Trading" are checked</li>
                  <li>Click on "Restrict access to trusted IPs only (Recommended)"</li>
                  <li>Copy and paste this IP:
                    <ReactTooltip id="why" effect="solid" place="top" type="dark">
                      Copy to clipboard
                    </ReactTooltip>
                    <a type="why" data-tip="" data-for="why"
                       onClick={() => navigator.clipboard.writeText('34.87.76.151')}>
                      &nbsp;34.87.76.151 <CopyIcon style={{ width: "12px", height: "12px" }} fill="#10AC84" />
                    </a>
                  </li>
                  <li>Click Save</li>
                </ol>
              </Col>
            </Row>
            <Row className="mt-3">
              <Col>
                <p className="mb-0"><b>Step 4 - Add API to Coinbag</b></p>
                <ol>
                  <li>Copy and paste your "API Key" and "Secret Key" into your Coinbag API setup fields</li>
                  <li>Click “Test Connection” on Coinbag</li>
                </ol>
              </Col>
            </Row>
            <Row className="mt-4 text-center">
              <Col>
                <button className="btn-inverse btn-block" onClick={this.goBackToExchangeConnection}>
                  Back to exchange connection
                </button>
              </Col>
            </Row>
          </Container>

        </div>

        <Footer />

      </>
    );
  }
}
