import axios from 'axios';
import { User } from 'models';

import AuthService from './auth-service';
import Headers from './headers';

const AUTH_API = '/api/auth';

export interface CountryListItem {
  iso2: string;
  name: string;
  currencyIso3: string;
}

export const getUserInfo = (): Promise<User> => axios.get(
  `${AUTH_API}/getUserInfo`,
  { headers: { ...Headers.def(), ...Headers.auth() } },
).then((res) => {
  const body = res.data;
  return {
    userId: body.userId,
    email: body.email,
    firstName: body.firstName,
    phoneNumber: body.phoneNumber,
    lastName: body.lastName,
    birthDate: body.birthDate ? body.birthDate : null,
    gender: body.gender,
    residenceCountry: body.residenceCountryIso2,
    role: body.role,
    kycResult: body.kycResult,
    isActive: body.isActive,
    isVerified: body.isVerified,
    deactivationAt: body.deactivationAt,
    secret_2fa: body.secret_2fa,
    isCustodial: body.isCustodial,
    consentTermsAndConditions: body.consentTermsAndConditions,
    isReadOnly: body.isReadOnly,
    walletInfo: body.walletInfo,
    userType: body.userType,
    isTmp: body.isTmp,
    companyName: body.companyName,
  };
});

export const consentTermsAndConditions = (country: string) => axios.post(`${AUTH_API}/consentTermsAndConditions`,
  { country }, { headers: { ...Headers.def(), ...Headers.auth() } });

export const isLoggedIn = () => !!localStorage.getItem('user');

export const getCountryList = (): Promise<CountryListItem[]> => axios.get(
  `${AUTH_API}/getCountryList`,
  { headers: { ...Headers.def() } },
).then((res) => res.data);

export const selectNonCustodial = (): Promise<boolean> => axios.post(`${AUTH_API}/selectNonCustodial`,
  {},
  { headers: { ...Headers.def(), ...Headers.auth() } });

export const partnerRegister = (text: string, robot: string) => axios.post(
  `${AUTH_API}/partnerRegister`,
  { text, robot },
  { headers: { ...Headers.def(), ...Headers.auth() } },
);

export const createTmpUser = async (cloudFlareToken: string, address: string) => {
  const res = await axios.post(`${AUTH_API}/createTmpUser`, { cloudFlareToken, address });
  AuthService.getAccessToken(res);
};
