import { Box, Hidden, makeStyles, Typography, useMediaQuery } from '@material-ui/core';
import { Button, Card, CardProps } from 'elements';
import React, { useMemo } from 'react';
import SVG from 'react-inlinesvg';
import { Link } from 'react-router-dom';
import { DepositRoute, WithdrawRoute } from 'routes';
import { breakpoints } from 'theme/base';
import { numDigits, shortenNumber } from 'utils';

type PortfolioValueCardProps = CardProps & {
  value: number;
  lastUpdate?: Date;
  disableButtons?: boolean;
  title?: string;
}

const useStyles = makeStyles((theme) => ({
  root: {
    overflow: 'hidden',
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    rowGap: 8,

    padding: '12px 16px',
    height: '100%',
    width: '100%',

    color: theme.palette.common.white,
    background: 'radial-gradient(100% 143.62% at 0% 50.3%, #4078E8 0%, #99BBFF 100%)',

    [theme.breakpoints.up('sm')]: {
      padding: 16,
    },
  },
  titleContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    columnGap: 16,
    width: '100%',
  },
  title: {
    flexShrink: 0,
    fontSize: 14,
    fontWeight: 500,
    lineHeight: '20px',
    [theme.breakpoints.up('lg')]: {
      fontSize: 16,
      lineHeight: '24px',
    },
  },
  lastUpdate: { color: 'white', fontSize: 11, fontWeight: 300 },
  lastUpdateValue: { fontWeight: 500 },
  value: {
    display: 'inline',
    verticalAlign: 'middle',
    columnGap: 8,
    marginTop: 4,
    fontSize: 20,
    fontWeight: 500,
    color: theme.palette.common.white,
    [theme.breakpoints.up('sm')]: {
      verticalAlign: 'baseline',
      columnGap: 12,
      fontSize: 24,
      fontWeight: 600,
    },
  },
  unit: {
    display: 'inline',
    verticalAlign: 'middle',
    marginLeft: '0.3em',
    fontSize: 20,
    fontWeight: 300,
    color: theme.palette.common.white,
    [theme.breakpoints.up('sm')]: {
      verticalAlign: 'baseline',
      fontSize: 24,
      fontWeight: 500,
    },
  },
  equivalent: {
    display: 'inline',
    color: theme.palette.blue[200],
    marginLeft: '0.3em',
    fontSize: '0.7em',
    fontWeight: 500,
    verticalAlign: 'middle',
    [theme.breakpoints.up('sm')]: {
      verticalAlign: 'baseline',
      fontSize: '0.6em',
    },
  },
  buttonsRow: {
    display: 'grid',
    gridTemplateColumns: 'repeat(2, minmax(0, 1fr))',
    alignItems: 'center',
    columnGap: 12,
    width: '100%',
  },
  button: {
    width: '100%',
    boxShadow: '0px 4px 12px rgba(34, 92, 207, 0.32)',
  },
  buttonContent: {
    display: 'flex',
    flexDirection: 'row',
    columnGap: 4,
  },
  depositButton: {
    color: theme.palette.blue['600'],
  },
  depositIcon: {
    filter: 'invert(37%) sepia(96%) saturate(803%) hue-rotate(194deg) brightness(95%) contrast(91%)',
  },
  withdrawIcon: {
    '& path': {
      fill: 'white',
    },
  },
}));

export const PortfolioValueCard = (props: PortfolioValueCardProps) => {
  const { className, value, lastUpdate, disableButtons, ...rest } = props;
  const classes = useStyles();

  const isXm = useMediaQuery(breakpoints.up('xm'));
  const isMd = useMediaQuery(breakpoints.up('md'));

  const formattedDate = useMemo(() => {
    if (!lastUpdate) return null;
    const pad = (num: number) => num.toString().padStart(2, '0');
    return `${pad(lastUpdate.getDate())}.${
      pad(lastUpdate.getMonth() + 1)
    }.${lastUpdate.getFullYear()}, ${pad(lastUpdate.getHours())}:${pad(lastUpdate.getMinutes())}`;
  }, [lastUpdate]);

  return (
    <Card className={`${classes.root} ${className}`} {...rest}>
      <Box className={classes.titleContainer}>
        <Typography variant="h5" className={classes.title}>{props.title || 'Portfolio value'}</Typography>

        {formattedDate && (
          <Typography className={classes.lastUpdate}>
            Last updated:{' '}<Hidden xlUp><br /></Hidden>
            <span className={classes.lastUpdateValue}>{formattedDate}</span>
          </Typography>
        )}
      </Box>

      <Box>
        {(numDigits(parseFloat(value.toFixed(0)))) > 5 ? (
          <Typography id="portfolio-value" className={classes.value}>
            {shortenNumber(parseFloat(value.toFixed(2)), 2).toLocaleString()}
          </Typography>
        ) : (
          <Typography id="portfolio-value" className={classes.value}>
            {parseFloat(value.toFixed(2)).toLocaleString()}
          </Typography>
        )}
        <Typography className={classes.unit}>USDT</Typography>
        {(isXm || !isMd) && (
          <Typography className={classes.equivalent}>equivalent</Typography>
        )}
      </Box>
      {!disableButtons && (
        <Box className={classes.buttonsRow}>
          <Link to={DepositRoute}>
            <Button variant="secondary" className={classes.button}>
              <Box className={`${classes.buttonContent} ${classes.depositButton}`}>
                <SVG className={classes.depositIcon} src="/icons/dashboard/ic_deposit_2.svg" width={16} />
                <Typography variant="h5">{' '}Deposit</Typography>
              </Box>
            </Button>
          </Link>

          <Link to={WithdrawRoute}>
            <Button variant="translucent" className={classes.button}>
              <Box className={classes.buttonContent}>
                <SVG className={classes.withdrawIcon} src="/icons/dashboard/ic_withdraw_2.svg" width={16} />
                <Typography variant="h5">{' '}Withdraw</Typography>
              </Box>
            </Button>
          </Link>
        </Box>
      )}
    </Card>
  );
};
