import { Box, createStyles } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { AxiosError } from 'axios';
import { Loading } from 'components';
import { Button, InputWithTitle, LightTextField, Typography } from 'elements';
import React, { useState } from 'react';
import Turnstile from 'react-turnstile';
import { createTmpUser } from 'services';
import { JwtUser } from 'services/types/jwt';
import WalletService from 'services/wallet-service';
import { useStore as useTransactionStore } from 'store/zustand/Transaction';

import { EVM_ADDRESS_FORMAT } from '../utils/ReportingUtils';

const useStyles = makeStyles((theme) => createStyles({
  root: {
    width: '100%',
    backgroundColor: 'white',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    gap: 16,
    [theme.breakpoints.down('sm')]: { padding: 0 },
  },
  content: {
    width: '100%',
    gap: 8,
  },
  loading: {
    width: '100%',
    position: 'absolute',
    top: 0,
    left: 0,
    zIndex: 2,
    background: 'white',
  },
  errorText: {
    fontSize: 12,
    fontWeight: 400,
    textAlign: 'end',
  },
  input: {
    width: '100%',
  },
  errorWrapper: {
    display: 'flex',
    width: '100%',
  },
  buttonWrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: 4,
    width: '100%',
  },
}));

interface Props {
  callbackFnc: () => void;
}

export const Front = ({ callbackFnc }: Props) => {
  const classes = useStyles();
  const [address, setAddress] = useState<string>('');
  const [isInputError, setIsInputError] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [enableButton, setEnableButton] = useState<boolean>(false);
  const [inputWalletError, setInputWalletError] = useState<boolean>(false);
  const [cloudFlareToken, setClouldFlareToken] = useState<string | undefined>(undefined);
  const fetchTxs = useTransactionStore((state) => state.fetchTxs);
  const handleOnSubmit = async () => {
    if (address.match(EVM_ADDRESS_FORMAT)) {
      setInputWalletError(false);
      setIsLoading(true);
      const userData = localStorage.getItem('user');
      const user = userData ? JSON.parse(userData) as JwtUser : undefined;
      try {
        if (!user) await createTmpUser(cloudFlareToken ?? '', address);
        else {
          await WalletService.createTmpUserWallet([address], cloudFlareToken ?? '');
          fetchTxs(true);
        }
        callbackFnc();
      } catch (e) {
        const err = e as AxiosError;
        if (err?.response?.status === 406) setInputWalletError(true);
      }
      setIsLoading(false);
    } else setIsInputError(true);
  };

  if (isLoading) {
    return <Loading className={classes.loading} />;
  }

  return (
    <>
      <Box className={classes.root}>
        <Box className={classes.content}>
          <InputWithTitle
            title="Address"
            className={classes.input}
            titleRightItem={isInputError && <Typography className={classes.errorText} palette="red" paletteColor={600}>Invalid wallet address</Typography>}
          >
            <LightTextField
              placeholder="Enter your address"
              value={address}
              onChange={(e) => {
                setIsInputError(false);
                setAddress(e.target.value);
              }}
              error={isInputError}
            />
          </InputWithTitle>
          {inputWalletError && (
            <Typography
              palette="red"
              paletteColor={500}
              variant="h6"
            >
              This address has more than 5000 transactions. Please go to <a href="https://coinbag.finance">coinbag website</a>
            </Typography>
          )}
        </Box>
        <Box className={classes.buttonWrapper}>
          <Turnstile
            sitekey="0x4AAAAAAADm0aD1aDjFfif4"
            theme="light"
            appearance="interaction-only"
            onVerify={(token) => {
              setClouldFlareToken(token);
              setEnableButton(true);
            }}
          />
          <Button
            onClick={handleOnSubmit}
            disabled={!enableButton}
            fullWidth
            size="xmd"
          >
            Start reporting
          </Button>
        </Box>
      </Box>
    </>
  );
};
