import { Box, createStyles, makeStyles, Theme } from '@material-ui/core';
import { Typography } from 'elements';
import { List } from 'elements/List';
import * as React from 'react';

import { CustomRadioButton } from '../../../components/CustomRadioButton';
import { DashboardTheme, palette } from '../../../theme';

export interface RebalanceOptionProps {
  icon: string;
  name: string;
  description: string;
  bestFor: React.ReactNode[];
  isSelected: boolean;
  setSelected: () => void;
  isEnabled: boolean;
  color?: keyof Omit<typeof palette, 'primary' | 'error'>;
  nextRebalance?: string;
}

const useStyles = makeStyles<Theme, RebalanceOptionProps>((theme) => createStyles({
  root: {
    position: 'relative',
    flexGrow: 0,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    gap: 8,
    padding: '12px 16px',
    width: '100%',
    background: 'white',
    border: '1px solid',
    borderColor: theme.palette.grey['200'],
    borderRadius: 8,
    cursor: ({ isEnabled }) => (isEnabled ? 'pointer' : 'not-allowed'),
    opacity: ({ isEnabled }) => (isEnabled ? 1 : 0.6),
  },
  isSelected: {
    padding: '11px 15px',
    border: '2px solid',
    borderColor: theme.palette.green['400'],
  },
  radio: {
    position: 'absolute',
    top: '16px',
    right: '16px',
  },
  info: {
    position: 'absolute',
    top: '16px',
    right: '16px',
    width: 20,
    height: 20,
    cursor: 'help',
  },
  title: {
    display: 'flex',
    flexWrap: 'nowrap',
    gap: 12,
    width: '100%',
    alignItems: 'center',
  },
  icon: {
    width: 56,
    height: 56,
    userDrag: 'none',
    userSelect: 'none',
  },
  name: {
    color: theme.palette.grey[800],
    textAlign: 'center',
    fontSize: 20,
    lineHeight: '32px',
  },
  bestFor: {
    display: 'flex',
    flexDirection: 'column',
    color: theme.palette.grey[700],
    gap: 4,
    '& span': {
      fontWeight: 500,
      fontSize: 14,
      lineHeight: '24px',
    },
    '& li:first-child': {
      '&::marker': {
      },
      '& span': {
        color: theme.palette.grey[500],
        fontSize: 12,
        lineHeight: '20px',
      },
    },
    '& em': {
      color: ({ color }) => theme.palette[color || 'green'][600],
      fontSize: 14,
      lineHeight: '24px',
      fontStyle: 'normal',
      padding: '2px 4px',
      borderRadius: 8,
      fontWeight: 500,
      background: ({ color }) => theme.palette[color || 'green'][200],
    },
  },
  description: {
    color: theme.palette.grey[700],
    fontWeight: 400,
    fontSize: 12,
    lineHeight: '20px',
  },
  nextRebalance: {
    color: theme.palette.grey[500],
    fontWeight: 400,
    fontSize: 12,
    lineHeight: '20px',
    '& em': {
      fontStyle: 'normal',
      color: theme.palette.blue[600],
      paddingLeft: 4,
    },
  },
}));

export const RebalanceOption = (props: RebalanceOptionProps) => {
  const classes = useStyles(props);

  return (
    <Box
      className={`${props.isSelected && classes.isSelected} ${classes.root}`}
      onClick={() => props.isEnabled && props.setSelected()}
    >
      <Box className={classes.radio}>
        <CustomRadioButton
          color={`${DashboardTheme.palette.green['500']}`}
          outterCircleRadius={8}
          innerCircleRadius={5}
          isSelected={props.isSelected}
        />
      </Box>
      <Box className={classes.title}>
        <img
          className={classes.icon}
          src={props.icon}
          alt="icon"
        />
        <Typography variant="h4" className={classes.name}>{props.name}</Typography>
      </Box>
      <Box className={classes.bestFor}>
        <Typography variant="h6" palette="grey" paletteColor={500}>Best for:</Typography>
        <List
          items={props.bestFor.map((detail, index) => (
            <Typography key={index} variant="caption" palette="grey" paletteColor={700}>{detail}</Typography>
          ))}
          color={props.color}
        />
      </Box>
      <Typography variant="h6" className={classes.description}>{props.description}</Typography>
      {props.nextRebalance && <Typography variant="h6" className={classes.description}>Next rebalance on: {props.nextRebalance}</Typography>}
    </Box>
  );
};
