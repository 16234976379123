import { Box, makeStyles, Typography, useMediaQuery } from '@material-ui/core';
import React, { FC, useState } from 'react';

import { Button } from '../../../elements';
import { palette } from '../../../theme';
import { breakpoints } from '../../../theme/base';
import { RightArrowIcon } from '../../Icons';

type ReferralLinkProps = {
  className?: string;
  link: string;
};

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',

    padding: 0,
    width: '100%',
    height: '100%',

    [theme.breakpoints.up('sm')]: {
      padding: 16,

      background: theme.palette.green['50'],
      border: '1px solid',
      borderColor: theme.palette.green['300'],
      borderRadius: 8,
    },
  },

  illustration: {
    width: 48,
    [theme.breakpoints.up('sm')]: { width: 64 },
    [theme.breakpoints.up('lg')]: { width: 92 },
  },
  description: {
    marginTop: 8,
    textAlign: 'center',
  },

  linkBox: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    columnGap: 4,

    marginTop: 8,
    padding: '4px 4px 4px 12px',
    width: '100%',

    background: 'white',
    border: '1px solid',
    borderColor: theme.palette.grey['200'],
    borderRadius: 8,
  },
  linkText: {
    overflow: 'hidden',
    width: '100%',

    color: theme.palette.grey['600'],
    fontSize: 12,
    fontWeight: 400,
    lineHeight: '20px',
    textOverflow: 'ellipsis',
  },
  linkButton: {
    flexShrink: 0,
    padding: '4px 6px',
    width: 96,
    borderRadius: 6,
  },

  detailsButton: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    columnGap: 4,

    marginTop: 12,
    color: theme.palette.grey['700'],
  },
}));

const ReferralLink: FC<ReferralLinkProps> = ({ className, link }) => {
  const classes = useStyles();
  const isMobile = useMediaQuery(breakpoints.down('xs'));

  const [copied, setCopied] = useState(false);
  const copy = () => {
    navigator.clipboard.writeText(link);
    setCopied(true);

    setTimeout(() => setCopied(false), 2000);
  };

  return (
    <Box className={`${classes.root} ${className}`}>
      <img
        className={classes.illustration}
        alt="Referral illustration"
        src="/icons/referral/ic_referral.png"
      />

      <Typography variant={isMobile ? 'h4' : 'h3'}>Limitless earning, paid monthly.</Typography>
      <Typography variant="h6" className={classes.description}>
        Earn up to 125 USD for every 10,000 USD referred to Coinbag.
      </Typography>

      <Box className={classes.linkBox}>
        <Typography className={classes.linkText}>{link}</Typography>
        <Button size="sm" className={classes.linkButton} onClick={copy}>
          {copied ? 'Copied!' : 'Copy Referral'}
        </Button>
      </Box>

      <a
        href="https://coinbag.tawk.help/article/coinbags-referral-system"
        target="_blank"
        rel="noreferrer"
      >
        <Box className={classes.detailsButton}>
          <Typography variant="h6">Program details</Typography>
          <RightArrowIcon fill={palette.green['600']} />
        </Box>
      </a>
    </Box>
  );
};

ReferralLink.displayName = 'ReferralLink';
export { ReferralLink };
