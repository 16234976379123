import { Box, createStyles } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Typography } from 'elements';
import React, { useEffect, useMemo, useState } from 'react';
import SVG from 'react-inlinesvg';
import { UserAtomicTransaction } from 'services/types/user-transaction';
import { UsdBalanceTs } from 'services/types/wallet';
import { useStore as useWalletStore } from 'store/zustand/Wallet';

interface Props {
  filteredTxs: UserAtomicTransaction[],
  filteredDate?: { startDate: Date, endDate: Date }
  selectAddress: { [address: string]: boolean };
}

interface SummaryItemProps {
  icon: string,
  text: string | React.ReactNode,
  amount: string,
  color: 'blue' | 'green' | 'grey' | 'red',
}

const useStyles = makeStyles((theme) => createStyles({
  root: {
    display: 'grid',
    gridTemplateColumns: 'repeat(5, 1fr)',
    flexWrap: 'nowrap',
    gap: 12,
    padding: 16,
    [theme.breakpoints.down('sm')]: {
      gap: 8,
      gridTemplateColumns: '1fr',
    },
  },
  item: {
    width: '100%',
    height: '100%',
    padding: '12px 8px 8px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'space-between',
    gap: 8,
    border: `1px solid ${theme.palette.grey[200]}`,
    borderRadius: 8,
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'row',
      padding: '12px 8px',
    },
  },
  titleWrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: 8,
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'row',
    },
  },
  icon: {
    width: 24,
    height: 24,
    display: 'flex',
    flexShrink: 0,
  },
  amount: {
    fontSize: 18,
    lineHeight: '24px',
    fontWeight: 500,
    textAlign: 'center',
    [theme.breakpoints.down('sm')]: {
      textAlign: 'right',
    },
  },
  text: {
    textAlign: 'center',
    [theme.breakpoints.down('sm')]: {
      textAlign: 'left',
    },
  },
}));

export const SummarySection = ({ filteredTxs, filteredDate, selectAddress }: Props) => {
  const classes = useStyles();
  const [startBalance, setStartBalance] = useState<UsdBalanceTs | undefined>();
  const [endBalance, setEndBalance] = useState<UsdBalanceTs>();
  const [getWalletUsdBalance] = useWalletStore((state) => [state.getWalletsUsdBalance]);

  useEffect(() => {
    (async () => {
      if (filteredDate || filteredTxs.length > 0) {
        const ts = filteredDate?.startDate ?? new Date(filteredTxs[filteredTxs.length - 1]?.ts ?? '');
        const balance = await getWalletUsdBalance(ts.valueOf());
        setStartBalance(balance);
      }
    })();
  }, [filteredDate?.startDate, filteredTxs]);

  useEffect(() => {
    (async () => {
      const ts = filteredDate?.endDate.valueOf() ?? Date.now();
      const balance = await getWalletUsdBalance(ts);
      setEndBalance(balance);
    })();
  }, [filteredDate?.endDate]);

  const selectedAddress = useMemo(() => Object
    .entries(selectAddress)
    .filter((address) => address[1])
    .map((address) => address[0]), [selectAddress]);

  const startDate = useMemo(() => {
    if (startBalance?.ts && filteredTxs.length > 0) {
      const balanceTs = new Date(startBalance.ts);
      const txsTs = new Date(filteredTxs[filteredTxs.length - 1].ts);
      return balanceTs.valueOf() > txsTs.valueOf() ? balanceTs : txsTs;
    }
    return filteredTxs.length > 0 ? new Date(filteredTxs[filteredTxs.length - 1].ts)
      : startBalance?.ts ? new Date(startBalance.ts)
        : new Date();
  }, [startBalance, filteredTxs]);

  const summaryItems: SummaryItemProps[] = React.useMemo(() => {
    const dateStringOptions: Intl.DateTimeFormatOptions = { year: 'numeric', month: 'long', day: 'numeric' };
    return [
      {
        icon: '/icons/reporting/ic_transactions.svg',
        text: 'Number of transactions',
        amount: filteredTxs.length.toLocaleString(),
        color: 'grey',
      },
      {
        icon: '/icons/reporting/ic_balance.svg',
        text: (
          <>
            Balance on:{' '}
            <Typography component="span" variant="h6" palette="blue" paletteColor={600}>
              {startDate.toLocaleDateString(undefined, dateStringOptions)}
            </Typography> <Typography component="span" variant="h6" paletteColor={400}>USDT</Typography>
          </>),
        amount: startBalance
          ?.usdBalances
          .filter((b) => selectedAddress.includes(b.address))
          .map((b) => b.balance)
          .reduce((prev, curr) => prev + curr, 0)
          .toLocaleString(undefined, { maximumFractionDigits: 2 }) ?? '-',
        color: 'blue',
      },
      {
        icon: '/icons/reporting/ic_balance.svg',
        text: (
          <>
            Balance on:{' '}
            <Typography component="span" variant="h6" palette="blue" paletteColor={600}>
              {(endBalance?.ts ? new Date(endBalance.ts) : new Date()).toLocaleDateString(undefined, dateStringOptions)}
            </Typography> <Typography component="span" variant="h6" paletteColor={400}>USDT</Typography>
          </>),
        amount: endBalance
          ?.usdBalances
          .filter((b) => selectedAddress.includes(b.address))
          .map((b) => b.balance)
          .reduce((prev, curr) => prev + curr, 0)
          .toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 }) ?? '-',
        color: 'blue',
      },
      {
        icon: '/icons/reporting/ic_transaction_in.svg',
        text: <>Transactions IN <Typography component="span" variant="h6" paletteColor={400}>USDT</Typography></>,
        amount: filteredTxs
          .filter((tx) => tx.direction === 'in')
          .reduce((prev, curr) => prev + (curr.usdAmount ?? 0), 0)
          .toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 }),
        color: 'green',
      },
      {
        icon: '/icons/reporting/ic_transaction_out.svg',
        text: <>Transactions OUT <Typography component="span" variant="h6" paletteColor={400}>USDT</Typography></>,
        amount: filteredTxs
          .filter((tx) => tx.direction === 'out')
          .reduce((prev, curr) => prev + (curr.usdAmount ?? 0), 0)
          .toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 }),
        color: 'red',
      },
    ];
  }, [filteredTxs, startBalance, endBalance, selectedAddress]);

  const getItem = ({ amount, color, icon, text }: SummaryItemProps, key: number) => (
    <Box className={classes.item} key={key}>
      <Box className={classes.titleWrapper}>
        <Box className={classes.icon}>
          <SVG src={icon} width={24} height={24} />
        </Box>
        <Typography className={classes.text} component="span" variant="h6" paletteColor={600}>{text}</Typography>
      </Box>
      <Typography className={classes.amount} palette={color} paletteColor={600}>{amount}</Typography>
    </Box>
  );

  return (
    <Box className={classes.root}>
      {summaryItems.map((item, index) => getItem(item, index))}
    </Box>
  );
};
