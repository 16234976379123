/* eslint-disable max-len */
import { Box, Hidden, makeStyles, Theme, Typography } from '@material-ui/core';
import { Card } from 'elements';
import React, { useCallback } from 'react';
import { palette } from 'theme';

interface FeatureCardProps {
  icon: string;
  title: string;
  description: string;
  iconMobile: string;
}

interface MainFeaturesProps {
  title: React.ReactNode;
  subTitle?: React.ReactNode;
  features: FeatureCardProps[];
  maxColumn?: number;
  color?: keyof Omit<typeof palette, 'primary' | 'error'>;
}

const useStyles = makeStyles<Theme, Partial<MainFeaturesProps>>((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'stretch',
    gap: 16,
    color: theme.palette.grey[700],
    [theme.breakpoints.up('md')]: {
      gap: 40,
    },
    '& h2': {
      fontSize: 24,
      fontWeight: 700,
      lineHeight: '44px',
      textAlign: 'center',
      [theme.breakpoints.up('md')]: {
        fontSize: 44,
        lineHeight: '64px',
      },
    },
    '& h3': {
      fontWeight: 500,
      textAlign: 'center',
      fontSize: 20,
      lineHeight: '32px',
      [theme.breakpoints.down('sm')]: {
        display: 'none',
      },
    },
    '& em': {
      color: ({ color }) => theme.palette[color || 'blue'][500],
      fontStyle: 'normal',
    },
  },

  featuresGrid: {
    position: 'relative',
    display: 'grid',
    gridTemplateColumns: '1fr',
    rowGap: 16,

    [theme.breakpoints.up('md')]: {
      gridTemplateColumns: ({ maxColumn }) => `repeat(${maxColumn || 2}, 1fr)`,
      columnGap: 24,
      rowGap: 32,
    },
  },
  featureCard: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'start',

    gap: 8,
    padding: 16,
    width: '100%',
    height: 'auto',

    background: 'white',
    borderRadius: 32,
    boxShadow: '0px 4px 32px rgba(135, 138, 165, 0.24)',

    [theme.breakpoints.up('md')]: {
      gap: 12,
      padding: 32,
    },

    '& h2': {
      color: ({ color }) => theme.palette[color || 'blue'][500],
      fontSize: 16,
      fontWeight: 500,
      lineHeight: '24px',
      textAlign: 'left',

      [theme.breakpoints.up('md')]: {
        fontSize: 20,
        fontWeight: 700,
        lineHeight: '32px',
      },
    },
    '& p': {
      fontSize: 12,
      fontWeight: 500,
      lineHeight: '20px',

      [theme.breakpoints.up('md')]: {
        fontSize: 16,
        lineHeight: '24px',
      },
    },
  },
  featureTitle: {
    display: 'flex',
    alignItems: 'center',
    gap: 8,

    [theme.breakpoints.up('md')]: {
      gap: 12,
    },
  },
  featureIcon: {
    width: 32,
    height: 32,

    [theme.breakpoints.up('md')]: {
      width: 40,
      height: 40,
    },
  },

  gradient: {
    position: 'absolute',
    top: '50%',
    left: '50%',

    height: '110%',
    width: '110%',
    backfaceVisibility: 'hidden',
    perspective: 1000,
    background: 'radial-gradient(50% 50% at 50% 50%, rgba(110, 158, 254, 0.315) 0%, rgba(255, 255, 255, 0) 100%)',
    filter: 'blur(76px)',
    transform: 'translateX(-50%) translateY(-50%) translateZ(0)',
  },
}));

export const institutionalFeatures: FeatureCardProps[] = [
  {
    icon: '/icons/marketing/ic_ins_feature_1',
    iconMobile: '/icons/marketing/ic_ins_feature_small_1',
    title: 'Dedicated account manager',
    description: 'Leverage one of Coinbag\'s own crypto experts to help you build a strategy best suited for your institutional needs.',
  },
  {
    icon: '/icons/marketing/ic_ins_feature_2',
    iconMobile: '/icons/marketing/ic_ins_feature_small_2',
    title: '24/7 customer support',
    description: 'Day or night, you can have the peace of mind to connect directly to a support member of the Coinbag team who can assist you.',
  },
  {
    icon: '/icons/marketing/ic_ins_feature_3',
    iconMobile: '/icons/marketing/ic_ins_feature_small_3',
    title: 'Security',
    description: 'Coinbag partners with best-in-class custodial solutions to ensure your assets are secure and protected at all times. Your assets are secured with Multi-Party Computation (i.e., MPC) technology that ensures no single point of access or failure.',
  },
  {
    icon: '/icons/marketing/ic_ins_feature_4',
    iconMobile: '/icons/marketing/ic_ins_feature_small_4',
    title: 'Institutional grade investments',
    description: 'Coinbag partners with institutional-grade interest earning products, including native staking wherein your crypto never leaves your wallets. You have full control and transparency over if, where and how your crypto is deployed.',
  },
];

export const partnerFeatures: FeatureCardProps[] = [
  {
    icon: '/icons/marketing/ic_par_feature_1',
    iconMobile: '/icons/marketing/ic_par_feature_1_small',
    title: 'Affiliates',
    description: 'Send your users directly to Coinbag and profit-share with Coinbag on earnings from assets referred.',
  },
  {
    icon: '/icons/marketing/ic_par_feature_2',
    iconMobile: '/icons/marketing/ic_par_feature_2_small',
    title: 'White-Labels/SDKs',
    description: 'Leverage all the UI/UX Coinbag has built, but with your logo and styles. A seamless way to integrate directly into your existing finance product. ',
  },
  {
    icon: '/icons/marketing/ic_par_feature_3',
    iconMobile: '/icons/marketing/ic_par_feature_3_small',
    title: 'API integration',
    description: 'Want to have even more customization? Use any of the Coinbag services, such as buying/selling, rebalancing, and interest-earning but with your own look-and-feel.',
  },
];

const MainFeatures = ({ title, subTitle, features, maxColumn, color }: MainFeaturesProps) => {
  const classes = useStyles({ maxColumn, color });

  const FeatureCard = useCallback(({ icon, iconMobile, title, description }: FeatureCardProps) => (
    <Card className={classes.featureCard} component="article">
      <Box className={classes.featureTitle}>
        <picture>
          <source srcSet={`${icon}@1x.png 1x, ${icon}@2x.png 2x, ${icon}@3x.png 3x`} media="(min-width: 900px)" />
          <source srcSet={`${iconMobile}@1x.png 1x, ${iconMobile}@2x.png 2x, ${iconMobile}@3x.png 3x`} media="(min-width: 0px)" />
          <img
            className={classes.featureIcon}
            src={`${icon}@1x.png`}
            srcSet={`${icon}@1x.png`}
            alt={title}
          />
        </picture>
        <Typography variant="h2">{title}</Typography>
      </Box>
      <Typography>{description}</Typography>
    </Card>
  ), []);

  return (
    <section itemScope className={classes.root}>
      <Typography variant="h2">
        {title}
      </Typography>
      {subTitle && (
        <Typography variant="h3">
          {subTitle}
        </Typography>
      )}
      <Box className={classes.featuresGrid}>
        <Hidden smDown>
          <Box className={classes.gradient} />
        </Hidden>
        {features.map((feature, index) => (
          <FeatureCard {...feature} key={index} />
        ))}
      </Box>
    </section>
  );
};

MainFeatures.displayName = 'MainFeatures';
export { MainFeatures };
