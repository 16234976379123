import { WorkItemName } from 'services/types/workItem';

export const getWorkItemNotificationTitle = (name: WorkItemName) => {
  switch (name) {
    case WorkItemName.Rebalancing:
      return 'Approve a rebalancing';
    case WorkItemName.Withdrawal:
    case WorkItemName.WithdrawalToken:
    case WorkItemName.PayrollBatch:
      return 'New transaction waiting for approval';
    case WorkItemName.AddUserBundle:
    case WorkItemName.CreateOrEditBundle:
    case WorkItemName.SetBasicWallet:
      return 'Approve portfolio update';
    case WorkItemName.SetRebalancingMode:
      return 'Approve change in rebalancing settings';
    case WorkItemName.RemoveTagName:
      return 'Approve tag removal';
    case WorkItemName.AddTagName:
      return 'Approve tag creation';
    case WorkItemName.UpdateTagName:
      return 'Approve tag modification';
    case WorkItemName.AddOrUpdateAddressBook:
      return 'Approve address book modification';
    case WorkItemName.RemoveAddressBook:
      return 'Approve address book removal';
    case WorkItemName.CreateWallet:
      return 'Approve wallet creation';
    default:
      return 'New change waiting for approval';
  }
};

export const getWorkItemShortName = (name: WorkItemName) => {
  switch (name) {
    case WorkItemName.Rebalancing:
      return 'rebalancing';
    case WorkItemName.Withdrawal:
    case WorkItemName.WithdrawalToken:
      return 'withdrawal';
    case WorkItemName.PayrollBatch:
      return 'payroll';
    case WorkItemName.AddUserBundle:
    case WorkItemName.CreateOrEditBundle:
      return 'portfolio settings';
    case WorkItemName.SetRebalancingMode:
      return 'rebalancing settings';
    case WorkItemName.RemoveTagName:
      return 'tag removal';
    case WorkItemName.AddTagName:
      return 'tag creation';
    case WorkItemName.UpdateTagName:
      return 'tag modification';
    case WorkItemName.AddOrUpdateAddressBook:
      return 'address book modification';
    case WorkItemName.RemoveAddressBook:
      return 'address book removal';
    case WorkItemName.CreateWallet:
      return 'wallet creation';
    default:
      return 'New change waiting for approval';
  }
};

export const getWorkItemIcon = (name: WorkItemName) => {
  switch (name) {
    case WorkItemName.Rebalancing:
      return '/icons/ic_rebalancing.svg';
    case WorkItemName.Withdrawal:
    case WorkItemName.WithdrawalToken:
    case WorkItemName.PayrollBatch:
      return '/icons/ic_transaction.svg';
    case WorkItemName.AddUserBundle:
    case WorkItemName.CreateOrEditBundle:
      return '/icons/ic_portfolio.svg';
    case WorkItemName.RemoveTagName:
    case WorkItemName.AddTagName:
    case WorkItemName.UpdateTagName:
      return '/icons/ic_tag.svg';
    case WorkItemName.AddOrUpdateAddressBook:
    case WorkItemName.RemoveAddressBook:
      return '/icons/ic_address_book.svg';
    default:
      return '/icons/ic_setting.svg';
  }
};
