import { Menu as OriginalMenu, withStyles } from '@material-ui/core';

export const Menu = withStyles((theme) => ({
  paper: {
    background: theme.palette.common.white,
    boxShadow: '0px 4px 32px rgba(43, 43, 48, 0.06)',
    borderRadius: '8px',
  },
  list: { padding: 0 },
}))(OriginalMenu);
