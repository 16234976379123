import { useEffect, useState } from 'react';

const COUNTDOWN_TIMER = 60;

export const useTimedButton = (
  defaultText: string,
  resendText: string,
  onClickButton: () => void,
  timer: number = COUNTDOWN_TIMER,
) => {
  const [disableButton, setDisableButton] = useState(false);
  const [buttonText, setButtonText] = useState(defaultText);
  const [showSuccess, setShowSuccess] = useState(false);
  const [countdown, setCountdown] = useState(-1);

  useEffect(() => {
    const to = setTimeout(() => {
      if (countdown > 0) {
        const newCountdown = countdown - 1;
        setCountdown(newCountdown);
        setButtonText(`${resendText} (${countdown})`);
      }
      if (countdown === 0) {
        setDisableButton(false);
        setButtonText(`${resendText}`);
      }
    }, 1000);

    return () => clearTimeout(to);
  }, [countdown]);

  const onClick = async () => {
    setCountdown(timer);
    setDisableButton(true);
    setButtonText(`${resendText} (${timer})`);
    await onClickButton();
    setShowSuccess(true);
  };

  return { disableButton, buttonText, showSuccess, onClick };
};
