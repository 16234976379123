import { Box, makeStyles, Typography } from '@material-ui/core';
import { Card } from 'elements';
import { PortfolioCoin } from 'models';
import React, { FC } from 'react';
import { palette } from 'theme';
import { breakpoints } from 'theme/base';
import { prettyNumber } from 'utils';

type PortfolioChartTooltipProps = {
  coin: PortfolioCoin;
  hasTarget: boolean;
};

const useStyles = makeStyles({
  root: {
    display: 'flex',
    flexDirection: 'column',
    padding: '8px 8px',
    borderRadius: 0,
    border: `1px solid ${palette.grey[100]}`,
    [breakpoints.down('sm')]: {
      borderRadius: 8,
    },
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    columnGap: 8,
    '& svg': {
      borderRadius: 20,
    },
  },
  ticker: {
    color: palette.grey[800],
    fontSize: 16,
    fontWeight: 500,
    lineHeight: '24px',
  },
  text: {
    color: palette.grey[700],
    fontSize: 12,
    fontWeight: 500,
    lineHeight: '16px',
  },
  greenText: { color: palette.green[500] },
  greyText: { color: palette.grey[500] },
});

const PortfolioChartTooltip: FC<PortfolioChartTooltipProps> = ({ coin, hasTarget }) => {
  const classes = useStyles();
  return (
    <Card className={classes.root}>
      <Box className={classes.row}>
        <img
          alt={coin.name}
          src={coin.icon ? `data:image/svg+xml;utf8,${encodeURIComponent(coin.icon)}` : '/icons/coin_icon/fallback-1.svg'}
          width={24}
          height={24}
        />
        <Typography className={classes.ticker}>{coin.name}</Typography>
        <Box width={40} display="flex" alignItems="center">
          <Typography className={`${classes.text} ${classes.greenText}`}>
            {prettyNumber(coin.actualPercentage, { maximumFractionDigits: 2 })}%
          </Typography>
        </Box>
        {hasTarget && (
          <>
            <Typography className={`${classes.text} ${classes.greyText}`}>vs.</Typography>
            <Box width={24}>
              <Typography className={classes.text}>
                {coin.targetPercentage.toFixed(0)}%
              </Typography>
            </Box>
          </>
        )}
      </Box>
      <Box margin="8px 0" width="100%" height="1px" bgcolor={palette.grey['200']} />
      <Box className={classes.row} justifyContent="center">
        <Typography className={`${classes.text} ${classes.greenText}`}>
          {prettyNumber(coin.price, { maximumFractionDigits: 2 })} USDT
        </Typography>
        {hasTarget && (
          <>
            <Typography className={`${classes.text} ${classes.greyText}`}>vs.</Typography>
            <Typography className={classes.text}>
              {prettyNumber(
                coin.actualPercentage > 0 ? (coin.price * (coin.targetPercentage / coin.actualPercentage)) : 0,
                { maximumFractionDigits: 2 },
              )} USDT
            </Typography>
          </>
        )}
      </Box>
    </Card>
  );
};

PortfolioChartTooltip.displayName = 'PortfolioChartTooltip';
export { PortfolioChartTooltip };
