import { AppBar, Box, Hidden, makeStyles } from '@material-ui/core';
import React, { FC, PropsWithChildren } from 'react';

import { HomePageLayoutFooter } from './Footer/HomePageLayoutFooter';
import { HomePageLayoutHeader } from './Header/HomePageLayoutHeader';
import { PolicyNavigation } from './Header/PolicyNavigation';

const useStyles = makeStyles((theme) => ({
  root: {
    overflow: 'clip',
    position: 'relative',

    display: 'flex',
    flexDirection: 'column',

    minHeight: '100vh',
    background: 'white',
  },
  appBar: {
    [theme.breakpoints.down('sm')]: {
      zIndex: 1400,
    },
  },

  page: {
    position: 'relative',
    overflow: 'clip',

    display: 'flex',
    justifyContent: 'center',
    width: '100%',

    color: theme.palette.grey[700],

    '& a': { color: theme.palette.green[600] },
    '& em': {
      color: theme.palette.green[600],
      fontStyle: 'normal',
    },
    '& h1': {
      fontSize: 24,
      fontWeight: 700,
      lineHeight: '44px',

      [theme.breakpoints.up('md')]: {
        fontSize: 44,
        lineHeight: '80px',
        textAlign: 'center',
      },
    },

    '&::before': {
      content: '" "',
      zIndex: 0,

      position: 'absolute',
      top: 0,

      width: '160vw',
      height: '40vh',

      opacity: 0.6,
      background: 'radial-gradient(48.42% 48.42% at 50% 50%, rgba(46, 255, 218, 0.63) 0%, rgba(235, 255, 252, 0) 100%)',
      filter: 'blur(80px)',

      transform: 'translateY(-50%)',
    },
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'stretch',
    zIndex: 1,

    padding: '48px 16px',
    width: '100%',
    maxWidth: 1080,
    minHeight: '70vh',

    [theme.breakpoints.down('sm')]: {
      padding: 16,
      maxWidth: 640,
    },

    [theme.breakpoints.up('xl')]: {
      maxWidth: 1366,
    },
  },
}));

const PolicyLayout: FC<PropsWithChildren<any>> = ({ children }) => {
  const classes = useStyles();
  return (
    <Box className={classes.root}>
      <AppBar className={classes.appBar} position="sticky" elevation={0}>
        <HomePageLayoutHeader />
        <Hidden mdUp>
          <PolicyNavigation />
        </Hidden>
      </AppBar>

      <main className={classes.page}>
        <section className={classes.content}>
          {children}
        </section>
      </main>

      <HomePageLayoutFooter />
    </Box>
  );
};

PolicyLayout.displayName = 'PolicyLayout';
export { PolicyLayout };
