import { Box, createStyles, makeStyles } from '@material-ui/core';
import { Typography } from 'elements';
import React from 'react';
import SVG from 'react-inlinesvg';
import { CoinWithMasterCoinInfo } from 'services/types/coin';

const useStyles = makeStyles((theme) => createStyles({
  cryptoItem: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    gap: 8,
  },
  coinIconWrapper: {
    display: 'flex',
    width: 20,
    height: 20,
  },
  cryptoItemText: {
    color: theme.palette.grey[700],
    fontSize: 12,
    lineHeight: '16px',
    fontWeight: 500,
  },
}));

export const CoinMenuItem = (coin: CoinWithMasterCoinInfo) => {
  const classes = useStyles();
  return (
    <Box className={classes.cryptoItem}>
      <Box className={classes.coinIconWrapper}>
        <SVG src={coin?.masterCoin?.icon ?? '/icons/coin_icon/fallback-1.svg'} width={20} height={20} />
      </Box>
      <Typography className={classes.cryptoItemText}>
        {coin.symbol}
      </Typography>
    </Box>
  );
};
