export const ChartColors = [
  '#00876c',
  '#b6cf8b',
  '#f5ba75',
  '#d43d51',
  '#2f4b7c',
  '#665191',
  '#a05195',
  '#d45087',
  '#f95d6a',
  '#db6c59',
  '#de936f',
  '#b2b2b2',
];
