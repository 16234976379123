import { Box, createStyles, makeStyles } from '@material-ui/core';
import { Button, Typography } from 'elements';
import { LightFrenchFries } from 'elements/LightFrenchFries';
import React from 'react';

interface Props {
  header: string,
  content?: string,
  tagLabel?: string,
  onCancel?: () => void;
  onRemoveItem?: () => void;
  disableButtons?: boolean;
}

const useStyles = makeStyles(() => createStyles({
  removeTagModalContentWrapper: {
    flexGrow: 1,
    justifyContent: 'center',
    maxWidth: 350,
    alignSelf: 'center',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    textAlign: 'center',
    gap: 8,
    padding: '0px 16px 16px 16px',
    marginTop: -4,
  },
  detailText: {
    fontWeight: 400,
    textAlign: 'start',
  },
  chipWrapper: {
    width: '100%',
    display: 'flex',
    justifyContent: 'flex-start',
    margin: '4px 0',
  },
  buttonsWrapper: {
    margin: '8px 0',
    display: 'flex',
    width: '100%',
    justifyContent: 'space-between',
  },
  button: {
    minWidth: 90,
  },
}));

export const RemoveItem = ({ header, content, tagLabel, onCancel, onRemoveItem, disableButtons }: Props) => {
  const classes = useStyles();
  return (
    <Box className={classes.removeTagModalContentWrapper}>
      <Typography variant="h6" palette="grey" paletteColor={800}>
        {header}
      </Typography>
      {content && (
        <Typography variant="h6" className={classes.detailText} palette="grey" paletteColor={600}>
          {content}
        </Typography>
      )}
      <Box className={classes.chipWrapper}>
        <LightFrenchFries label={tagLabel ?? ''} autoAssignColor />
      </Box>
      <Box className={classes.buttonsWrapper}>
        <Button
          variant="secondary"
          className={classes.button}
          disabled={disableButtons}
          onClick={onCancel}
        >
          <Typography variant="h6" palette="grey" paletteColor={700}>Cancel</Typography>
        </Button>
        <Button
          className={classes.button}
          color="red"
          colorVariant={500}
          disabled={disableButtons}
          onClick={onRemoveItem}
        >
          <Typography variant="h6" palette="grey" paletteColor={100}>Remove</Typography>
        </Button>
      </Box>
    </Box>
  );
};
