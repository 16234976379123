import axios from "axios";
import Headers from './headers';

const API_URL = "/api/bundle/";

class BundleService {
  constructor(apiUrl) {
    this.apiUrl = apiUrl;
  }

  createBundle(bundleName, bundleDescription, riskScore, isLiquidation, coinsWithWeights) {
    return axios.post(this.apiUrl + 'create-bundle',
      {
        bundleName,
        bundleDescription,
        riskScore,
        isLiquidation,
        coinsWithWeights,
      },
      { headers: { ...Headers.def(), ...Headers.auth() } });
  }

  modifyBundle(bundleId, bundleStateId, bundleDescription, riskScore, coinsWithWeights) {
    return axios.post(this.apiUrl + 'edit-bundle',
      {
        bundleId,
        bundleStateId,
        bundleDescription,
        riskScore,
        coinsWithWeights,
      },
      { headers: { ...Headers.def(), ...Headers.auth() } });
  }

  getBundle(bundleId, bundleStateId) {
    return axios.get(this.apiUrl + `${bundleId}/${bundleStateId}`,
      { headers: { ...Headers.def(), ...Headers.auth() } });
  }

  getActiveBundles() {
    return axios.get(this.apiUrl + 'active-bundles',
      { headers: { ...Headers.def(), ...Headers.auth() } })
      .then((response) => {
        response.data.map((bundle) => bundle.coins = bundle.coins.map((coin) => {
          return {
            ...coin.coin,
            weight: coin.weight
          }
        }));
        return response
      })
  }

  filterBundles(bundles, exchangeId) {
    if (exchangeId === 4) {
      return bundles.filter(el => el.coins.every(coin => coin.chainId !== "binance"));
    }
    else {
      return bundles.filter(el => el.coins.every(coin => coin.chainId === "binance"));
    }
  }

  getLiquidationBundles() {
    return axios.get(this.apiUrl + 'liquidation-bundles',
      { headers: { ...Headers.def(), ...Headers.auth() } });
  }

  getAllBundles() {
    return axios.get(this.apiUrl,
      { headers: { ...Headers.def(), ...Headers.auth() } });
  }

  getUserBundle() {
    return axios.get(this.apiUrl + "user/",
      { headers: { ...Headers.def(), ...Headers.auth() } });
  }

  getUserBundleState() {
    return axios.get(this.apiUrl + "user/state/",
      { headers: { ...Headers.def(), ...Headers.auth() } });
  }

  getUserBundleStateMetadata() {
    return axios.get(this.apiUrl + "getUserBundleStateMetadata",
      { headers: { ...Headers.def(), ...Headers.auth() } });
  }

  getPerformancePeriod(bundleId, bundleStateId, initialValue, initialTimeUtc, startTimeUtc, endTimeUtc, nDataPoints) {
    return axios.get(this.apiUrl + "performance-period/" +
      `${bundleId}/${bundleStateId}/${initialValue}/${initialTimeUtc}/${startTimeUtc}/${endTimeUtc}/${nDataPoints}`,
      { headers: { ...Headers.def(), ...Headers.auth() } });
  }

  getPerformance(bundleId, bundleStateId, initialValue, utcTimeList) {
    return axios.get(API_URL + 'performance/' +
      `${bundleId}/${bundleStateId}/${initialValue}/${utcTimeList}`,
      { headers: { ...Headers.def(), ...Headers.auth() } });
  }

  getMinMaxTs() {
    return axios.get(API_URL + 'getMinMaxTs',
      { headers: { ...Headers.def(), ...Headers.auth() } });
  }

  addUserBundle(bundleId) {
    return axios.post(this.apiUrl + "user/add-bundle/", { bundleId },
      { headers: { ...Headers.def(), ...Headers.auth() } });
  }

  activateBundle(bundleId) {
    return axios.post(this.apiUrl + "activate-bundle", { bundleId },
      { headers: { ...Headers.def(), ...Headers.auth() } });
  }

  nearestBundle(bundles, riskScore) {
    return bundles.sort((b1, b2) => b1.riskScore > b2.riskScore ? 1 : -1).reduce((b1, b2) => {
      return Math.abs(riskScore - b1.riskScore) < Math.abs(riskScore - b2.riskScore) ? b1 : b2
    });
  }

  getYtd(riskScore) {
    return axios.get(this.apiUrl + `get-ytd/${riskScore}`, { headers: Headers.def() });
  }

  getYtdVariable(riskScore, minusDays) {
    return axios.get(this.apiUrl + `get-ytd-variable/${riskScore}/${minusDays}`, { headers: { ...Headers.def(), ...Headers.auth() } });
  }

  getUserAvailableBundles() {
    return axios.get(this.apiUrl + "getUserAvailableBundles", { headers: {...Headers.def(), ...Headers.auth()} });
  }
}

export default new BundleService(API_URL);
