import axios, { AxiosResponse } from 'axios';

import { Translation } from '../store/translations/translation';
import Headers from './headers';

const API_URL = '/api/translation/';

const getAllTranslations = (): Promise<Translation[]> => {
  return axios.get(API_URL + 'getAllTranslations', { headers: Headers.def() })
    .then((response: AxiosResponse<Translation[]>) => {
      return response.data;
    });
};

const updateTranslation = (translationId: number, isoCode: string, copy: string): Promise<Translation> => {
  return axios.post(API_URL + 'updateTranslation',
    {
      translationId,
      isoCode,
      copy,
    },
    {headers: {...Headers.def(), ...Headers.auth()}})
    .then((response: AxiosResponse<Translation>) => {
      return response.data;
    });
  
};

export default {
  getAllTranslations,
  updateTranslation,
};
