import { WalletState } from '@web3-onboard/core';
import debounce from 'lodash.debounce';
import PayrollService from 'services/payroll-service';
import { MutateAPIResponse } from 'services/types/mutate-api-response';
import {
  PayrollEstimatedGasFee,
  PayrollId,
  PayrollToggleRequest,
  PayrollWithExchangeRate,
} from 'services/types/payroll';
import { FAResponseStatus } from 'services/types/wallet';
import { create } from 'zustand';

interface PayrollStore {
  payrolls?: PayrollWithExchangeRate[];
  // eslint-disable-next-line no-unused-vars
  fetch: (value: boolean) => Promise<void>;
  add: (
    // eslint-disable-next-line no-unused-vars
    coinId: number, address: string, addressName: string, enabled: boolean, tagIds: number[], quantity: number, payrollId?: number, walletId?: number
  ) => Promise<void>;
  // eslint-disable-next-line no-unused-vars
  delete: (payrollId: number) => Promise<void>;
  // eslint-disable-next-line no-unused-vars
  estimateFees: () => Promise<void>;
  estimatedFees?: EstimatedFees;
  // eslint-disable-next-line no-unused-vars
  pay: (wallet: WalletState | null | undefined,emailCode?: string) => Promise<FAResponseStatus | MutateAPIResponse>;
  // eslint-disable-next-line no-unused-vars
  singlePay: (wallet: WalletState | null | undefined, emailCode: string | undefined, payrollId: number) => Promise<FAResponseStatus>;
  // eslint-disable-next-line no-unused-vars
  toggle: (request: PayrollToggleRequest[]) => Promise<void>;
}

export const useStore = create<PayrollStore>((set, get) => ({
  payrolls: undefined,
  fetch: async (forceReload: boolean) => {
    if (get().payrolls === undefined || forceReload) {
      const payroll = await PayrollService.get();
      set({ payrolls: payroll });
    }
  },
  add: async (coinId: number, address: string, addressName: string, enable: boolean, tagIds: number[], quantity: number,
    payrollId?: number, walletId?: number) => {
    await PayrollService.post(coinId, address, addressName, enable, tagIds, quantity, payrollId, walletId);
  },
  delete: async (payrollId: number) => {
    const payroll = get().payrolls?.find((p) => p.payrollId === payrollId);
    if (payroll) {
      await PayrollService.remove(payrollId, payroll?.addressBookId);
      await get().fetch(true);
    }
  },
  estimateFees: debounce(async () => {
    const fees = await PayrollService.estimateFee();
    const updatedTs = Date.now();
    const estimatedFees = { updatedTs, fees };
    set({ estimatedFees });
  }, 1500, { leading: true, trailing: true }),
  pay: PayrollService.pay,
  singlePay: PayrollService.singlePay,
  toggle: async (requests: PayrollToggleRequest[]) => {
    const updatePayrollStore = (reverse: boolean) => {
      const { payrolls } = get();
      for (let i = 0; i < requests.length; i += 1) {
        if (payrolls !== undefined) {
          const payroll = payrolls.find((pr) => pr.payrollId === requests[i].payrollId);
          if (payroll !== undefined) payroll.enable = reverse ? !requests[i].enable : requests[i].enable;
        }
      }
      set({ payrolls: [...(payrolls ?? [])] });
    };

    try {
      updatePayrollStore(false);
      await PayrollService.toggle(requests);
      await get().estimateFees();
    } catch (e) {
      updatePayrollStore(true);
    }
  },
}));

export type EstimatedFees = { updatedTs: number, fees: Record<PayrollId, PayrollEstimatedGasFee> }
