import axios, { AxiosResponse } from 'axios';

import Headers from './headers';
import { AddressBook } from './types/address-book';
import { GetUserTransactionResponse, TransactionsNote } from './types/user-transaction';

const API_URL = '/api/transaction';

const getTransactions = (): Promise<GetUserTransactionResponse> => axios.get(API_URL,
  { headers: { ...Headers.auth() } }).then((res) => res.data);

const getAddressBooks = (): Promise<AddressBook[]> => axios.get(`${API_URL}/addressBook`,
  { headers: { ...Headers.auth() } }).then((res) => res.data);

const addAddressBook = (address: string, tag: string, addressBookId?: number, walletId?: number): Promise<AxiosResponse> => axios.post(
  `${API_URL}/addressBook`, { address, tag, addressBookId, walletId }, { headers: { ...Headers.auth() } },
);

const addTag = (txHash: string, tagId: number): Promise<AxiosResponse> => axios.post(
  `${API_URL}/tag`, { txHash, tagId }, { headers: { ...Headers.auth() } },
);

const getTags = () => axios.get(
  `${API_URL}/tag`,
  { headers: { ...Headers.auth() } },
).then((res) => res.data);

const removeTag = (txHash: string, tagId: number): Promise<AxiosResponse> => axios.delete(
  `${API_URL}/tag`, { data: { txHash, tagId }, headers: { ...Headers.auth() } },
);

const updateTag = (tagId: number, tag: string): Promise<AxiosResponse> => axios.put(
  `${API_URL}/tag`, { tagId, tag }, { headers: { ...Headers.auth() } },
);

const addTagName = (tag: string, walletId?: number): Promise<AxiosResponse<number | undefined>> => axios.post(
  `${API_URL}/tagName`, { tag, walletId }, { headers: { ...Headers.auth() } },
);

const removeTagName = (tagId: number): Promise<AxiosResponse> => axios.delete(
  `${API_URL}/tagName`, { data: { tagId }, headers: { ...Headers.auth() } },
);

const removeAddressBook = (addressBookId: number): Promise<AxiosResponse> => axios.delete(
  `${API_URL}/addressBook/${addressBookId}`, { headers: { ...Headers.auth() } },
);

const getNotes = (): Promise<TransactionsNote[]> => axios.get(
  `${API_URL}/note`, { headers: { ...Headers.auth() } },
).then((res) => res.data);

const addOrEditNote = (txHash: string, note: string, walletId: number): Promise<AxiosResponse> => axios.post(
  `${API_URL}/note`, { txHash, note, walletId }, { headers: { ...Headers.auth() } },
);

const removeNote = (txHash: string, walletId: number): Promise<AxiosResponse> => axios.delete(
  `${API_URL}/note/${walletId}/${txHash}`, { headers: { ...Headers.auth() } },
);

export default {
  getTransactions,
  getAddressBooks,
  addAddressBook,
  removeAddressBook,
  addTag,
  getTags,
  updateTag,
  removeTag,
  addTagName,
  removeTagName,
  getNotes,
  addOrEditNote,
  removeNote,
};
