import { Box, createStyles, makeStyles } from '@material-ui/core';
import { WalletRow } from 'components/dashboard/MultiWallets/WalletRow';
import { Typography } from 'elements';
import React from 'react';
import SVG from 'react-inlinesvg';
import { SetEarningConfigParameter } from 'services/types/workItemParameter';
import { useStore as useCoinStore } from 'store/zustand/Coin';
import { useStore as useInterestEarningStore } from 'store/zustand/InterestEarning';
import { useStore as useUserStore } from 'store/zustand/User';
import { useStore as useWalletStore } from 'store/zustand/Wallet';

const useStyles = makeStyles(() => createStyles({
  icon: {
    width: '40px !important',
    height: 40,
    userDrag: 'none',
    userSelect: 'none',
  },
  row: {
    display: 'flex',
    alignItems: 'center',
    gap: 4,
  },
  column: {
    gap: 4,
    display: 'flex',
    flexDirection: 'column',
  },
  walletRow: {
    display: 'flex',
    gap: 4,
    alignItems: 'center',
  },
  protocolList: {
    gap: 8,
    display: 'flex',
    flexDirection: 'column',
    margin: '4px 0 16px',
  },
  protocolIcon: {
    display: 'flex',
    alignItems: 'center',
    gap: 0,
  },
  protocolName: {
    display: 'flex',
    gap: 4,
  },
}));

const SetEarningConfigDetail = (props: SetEarningConfigParameter) => {
  const classes = useStyles();
  const [user] = useUserStore((state) => [state.user]);
  const [wallet] = useWalletStore((state) => [state.wallet]);
  const [fetchProtocols, protocols] = useInterestEarningStore((state) => [state.fetchProtocols, state.protocols]);
  const [fetchCoins, masterCoins] = useCoinStore((state) => [state.fetchCoins, state.masterCoins]);

  const selectedWalletInfo = React.useMemo(
    () => (user?.walletInfo.find((w) => w.walletId === props.walletId)), [user, wallet],
  );

  React.useEffect(() => {
    fetchProtocols();
    fetchCoins();
  }, []);

  const details = React.useMemo(() => {
    let image = '/icons/earning/ic_none';
    if (props.newConfig && props.newConfig.protocolId) {
      image = '/icons/earning/ic_basic';
      const protocol = protocols.find((p) => p.protocolId === props.newConfig?.protocolId);
      return (
        <Box className={classes.row} alignItems="center" justifyContent="center">
          <Box className={classes.row}>
            <img
              className={classes.icon}
              src={`${image}@1x.png`}
              srcSet={`${image}@1x.png 1x, ${image}@2x.png 2x, ${image}@3x.png 3x`}
              alt="none"
            />
            <Typography variant="h4" paletteColor={700}>
              Basic interest methods:
            </Typography>
          </Box>
          {protocol && (
            <Box className={classes.row}>
              <Box width={24} height={24} display="flex" flexShrink={0}>
                <SVG src={protocol.logo} width={24} height={24} />
              </Box>
              <Typography variant="h6" paletteColor={700}>{protocol.name}</Typography>
            </Box>
          )}
        </Box>
      );
    }
    if (props.newConfig && props.newConfig.coinConfig) {
      image = '/icons/earning/ic_advance';
      return (
        <Box className={classes.column} alignItems="center" justifyContent="center">
          <Box className={classes.row}>
            <img
              className={classes.icon}
              src={`${image}@1x.png`}
              srcSet={`${image}@1x.png 1x, ${image}@2x.png 2x, ${image}@3x.png 3x`}
              alt="none"
            />
            <Typography variant="h4" paletteColor={700}>Advanced interest methods:</Typography>
          </Box>
          <Box className={classes.protocolList}>
            {props.newConfig.coinConfig.map((config) => {
              const protocol = protocols.find((p) => p.protocolId === config.protocolId);
              const coin = masterCoins[`${config.masterCoinId}`];
              return protocol && coin ? (
                <Box key={coin.masterCoinId} gridColumnGap={16} className={classes.row} justifyContent="center">
                  <Box className={classes.protocolIcon}>
                    <Box width={24} height={24} display="flex" flexShrink={0} alignItems="center">
                      <SVG src={coin.icon} width={24} height={24} />
                    </Box>
                    <Box width={24} height={24} display="flex" flexShrink={0} marginLeft="-8px" alignItems="center">
                      <SVG src={protocol.logo} width={24} height={24} />
                    </Box>
                  </Box>
                  <Box className={classes.protocolName}>
                    <Typography variant="h6" paletteColor={700}>{coin.ticker}</Typography>
                    <Typography variant="h6" paletteColor={700}>{protocol.name}</Typography>
                  </Box>
                </Box>
              ) : <></>;
            })}
          </Box>
        </Box>
      );
    }
    return (
      <Box className={classes.row} justifyContent="center">
        <img
          className={classes.icon}
          src={`${image}@1x.png`}
          srcSet={`${image}@1x.png 1x, ${image}@2x.png 2x, ${image}@3x.png 3x`}
          alt="none"
        />
        <Typography variant="h4" paletteColor={700}>Don’t earn interest</Typography>
      </Box>
    );
  }, [props.newConfig, props.oldConfig, protocols, masterCoins]);

  return (
    <>
      <Typography variant="h6" paletteColor={800} align="center">
        Update interest earning
      </Typography>
      {details}
      {selectedWalletInfo && (
        <Box display="flex" alignItems="center" gridGap="12px" justifyContent="center">
          <Typography variant="h4" paletteColor={800} align="center">for wallet:</Typography>
          <WalletRow walletInfo={selectedWalletInfo} />
        </Box>
      )}
    </>
  );
};

export default SetEarningConfigDetail;
