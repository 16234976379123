import { Box, createStyles, makeStyles } from '@material-ui/core';
import { Button, LightTextField, Typography } from 'elements';
import React, { useEffect, useState } from 'react';
import SVG from 'react-inlinesvg';
import { Tag } from 'services/types/tag';
import { useStore as useFeatureStore } from 'store/zustand/Feature';
import { useStore as useTransactionStore } from 'store/zustand/Transaction';

interface Props {
  tag: Tag
  onClose: () => void;
  // eslint-disable-next-line no-unused-vars
  checkExistingTag: (name: string, walletId: number) => boolean;
}

const useStyles = makeStyles((theme) => createStyles({
  adornment: {
    display: 'flex',
    alignItems: 'center',
    gap: 12,
    margin: -8,
    '& svg': {
      cursor: 'pointer',
      '&#disabled': {
        '& circle': {
          fill: theme.palette.grey[200],
        },
      },
    },
  },
}));

export const RemoveTagRow = ({ tag, onClose, checkExistingTag }: Props) => {
  const classes = useStyles();

  const [value, setValue] = useState<string>('');
  const [updateTag] = useTransactionStore((state) => [state.updateTag]);
  const [fetchFeatures] = useFeatureStore((state) => [state.fetchFeatures]);

  useEffect(() => {
    setValue(tag.tag);
  }, [tag.tag]);

  const handleOnSave = async () => {
    if (value !== '') {
      await updateTag(tag.tagId, value);
      await fetchFeatures(true);
      onClose();
    }
  };

  const handleOnCancel = () => {
    onClose();
    setValue(tag.tag);
  };

  const editDisabled = React.useMemo(() => {
    if (value === tag.tag) return true;
    return checkExistingTag(value, tag.walletId);
  }, [checkExistingTag, value, tag]);

  const adornment = (
    <Box className={classes.adornment}>
      <Button
        variant="icon"
        onClick={handleOnSave}
        disabled={editDisabled}
        tooltip={(editDisabled && value !== tag.tag) ? {
          title: (
            <Box display="flex" gridGap={4} alignItems="center">
              <SVG width={12} height={12} src="/icons/circle_question_red.svg" />
              <Typography variant="h6" paletteColor={600}>
                Tag name already exists
              </Typography>
            </Box>
          ),
          enterTouchDelay: 1,
          placement: 'top-end',
          arrow: true,
        } : undefined}
      >
        <SVG src="icons/reporting/ic_check.svg" width={20} height={20} id={editDisabled ? 'disabled' : ''} />
      </Button>
      <Button variant="icon" onClick={handleOnCancel}>
        <SVG src="icons/reporting/ic_cross.svg" width={20} height={20} />
      </Button>
    </Box>
  );

  return (
    <>
      <LightTextField
        fullWidth
        InputProps={{
          endAdornment: adornment,
        }}
        value={value}
        onChange={(e) => setValue(e.target.value)}
        onKeyDown={async (e) => { if (e.key === 'Enter') await handleOnSave(); }}
      />
    </>
  );
};
