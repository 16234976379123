import debounce from 'lodash.debounce';
import { MasterCoinStatus } from 'services/coin-enum';
import { create } from 'zustand';

import { unsupportedChain } from '../../components/DepositAndWithdraw/UnsupportedChain';
import CoinService from '../../services/coin.service';
import { CoinsWithMasterCoins, CoinWithMasterCoinInfo, MasterCoin } from '../../services/types/coin';
import { unsupportedCoins } from '../../utils/unsupportedcoins';

interface CoinStore {
  ready: boolean;
  coins: CoinsWithMasterCoins;
  coinsGroupByMasterCoinName: Record<string, CoinWithMasterCoinInfo[]>;
  coinsGroupByMasterCoinId: Record<number, CoinWithMasterCoinInfo[]>;
  masterCoins: Record<string, MasterCoin>;
  masterCoinsFromName: Record<string, MasterCoin>;
  fetchCoins: () => Promise<CoinsWithMasterCoins>;
  getMasterCoins: () => Record<string, MasterCoin>;
  // eslint-disable-next-line no-unused-vars
  getMasterCoin: (coinId: string) => MasterCoin;
}

const DEBOUNCE_TIMEOUT = 5000;

export const useStore = create<CoinStore>((set, get) => ({
  ready: false,
  coins: {},
  masterCoins: {},
  masterCoinsFromName: {},
  coinsGroupByMasterCoinName: {},
  coinsGroupByMasterCoinId: {},
  getMasterCoin: (coinId: string) => get().coins[coinId]?.masterCoin,
  getMasterCoins: () => get().masterCoins,
  fetchCoins: debounce(async () => {
    if (Object.keys(get().coins).length === 0) {
      const coinsWithMasterCoins = await CoinService.getCoinsWithMasterCoins();
      const filteredCoinWithMasterCoins: CoinsWithMasterCoins = Object.fromEntries(Object.entries(coinsWithMasterCoins)
        .filter(([, coin]) => !unsupportedChain.includes(coin.chainId)));
      const masterCoins = getMasterCoins(filteredCoinWithMasterCoins);
      const masterCoinsFromName = getMasterCoinsFromName(filteredCoinWithMasterCoins);
      const coinsGroupByMasterCoin = Object.entries(coinsWithMasterCoins)
        .reduce((prev, c) => {
          if (!c[1].protocolId
            && !unsupportedChain.includes(c[1].chainId)
            && c[1].masterCoin.status !== MasterCoinStatus.UiDisabled) {
            if (prev[0][c[1].masterCoin.ticker]) {
              prev[0][c[1].masterCoin.ticker].push(c[1]);
            } else {
              // eslint-disable-next-line no-param-reassign
              prev[0][c[1].masterCoin.ticker] = [c[1]];
            }
            if (prev[1][c[1].masterCoin.masterCoinId]) {
              prev[1][c[1].masterCoin.masterCoinId].push(c[1]);
            } else {
              // eslint-disable-next-line no-param-reassign
              prev[1][c[1].masterCoin.masterCoinId] = [c[1]];
            }
          }
          return prev;
        }, [{}, {}] as [Record<string, CoinWithMasterCoinInfo[]>, Record<string, CoinWithMasterCoinInfo[]>]);
      set(() => ({
        masterCoins,
        masterCoinsFromName,
        coinsGroupByMasterCoinName: coinsGroupByMasterCoin[0],
        coinsGroupByMasterCoinId: coinsGroupByMasterCoin[1],
        coins: filteredCoinWithMasterCoins,
        ready: true,
      }));
      return filteredCoinWithMasterCoins;
    }
    return get().coins as CoinsWithMasterCoins;
  }, DEBOUNCE_TIMEOUT, { leading: true }),
}));

const getMasterCoins = (coins: CoinsWithMasterCoins) => {
  const masterCoins: Record<string, MasterCoin> = {};
  Object.values(coins)
    .filter((coin) => !unsupportedCoins.includes(coin.masterCoin.ticker))
    .forEach((coin) => { masterCoins[coin.masterCoin.masterCoinId.toString()] = coin.masterCoin; });
  return masterCoins;
};

const getMasterCoinsFromName = (coins: CoinsWithMasterCoins) => {
  const masterCoins: Record<string, MasterCoin> = {};
  Object.values(coins).filter((coin) => !unsupportedCoins.includes(coin.masterCoin.ticker)).forEach(
    (coin) => { masterCoins[coin.masterCoin.ticker.toString()] = coin.masterCoin; },
  );
  return masterCoins;
};
