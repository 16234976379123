import { Box, createStyles, makeStyles, useMediaQuery, useTheme } from '@material-ui/core';
import { Typography } from 'elements';
import React from 'react';
import SVG from 'react-inlinesvg';

const useStyles = makeStyles((theme) => createStyles({
  root: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    rowGap: 12,
    flexWrap: 'wrap',
    marginTop: -84,
    paddingTop: 84,
    [theme.breakpoints.down('sm')]: {
      rowGap: 4,
      marginTop: -64,
      paddingTop: 64,
    },
  },
  title: {
    zIndex: 1,
    fontSize: 44,
    lineHeight: '60px',
    fontWeight: 700,
    color: theme.palette.grey[700],
    width: '100%',
    textAlign: 'center',
    marginBottom: 4,
    '& em': {
      color: theme.palette.green[600],
      fontStyle: 'normal',
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: 24,
      lineHeight: '44px',
      textAlign: 'left',
      gridColumn: '1 / all',
    },
  },
  subTitle: {
    color: theme.palette.grey[700],
    fontSize: 20,
    lineHeight: '28px',
    fontWeight: 400,
    textAlign: 'center',
    [theme.breakpoints.down('sm')]: {
      fontSize: 14,
      lineHeight: '24px',
      fontWeight: 500,
      textAlign: 'left',
    },
  },
  image: {
    position: 'relative',
    '& svg': {
      position: 'relative',
      zIndex: 1,
      opacity: 0.99,
    },
    '&::before': {
      content: '" "',
      width: 1260,
      height: 700,
      left: '50%',
      top: '50%',
      transform: 'translateX(-50%) translateY(-50%) translateZ(0)',
      position: 'absolute',
      background: 'radial-gradient(30% 30% at 50% 50%, rgba(110, 158, 254, 0.28) 0%, rgba(235, 255, 252, 0) 100%)',
      filter: 'blur(64px)',
      overflow: 'visible',
      backfaceVisibility: 'hidden',
      perspective: 1000,
      [theme.breakpoints.down('sm')]: {
        width: 700,
        height: '120%',
      },
    },
  },
}));

interface DistributeRiskProps {
  institutional?: boolean;
}

const DistributeRisk = ({ institutional }: DistributeRiskProps) => {
  const classes = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  return (
    <section itemScope className={classes.root} id="distribute_risk">
      <Typography variant="h2" className={classes.title}>
        How we help you <em>distribute risk</em>
      </Typography>
      <Typography variant="h3" className={classes.subTitle}>
        <b>Don’t hold all your assets in one place.</b> Coinbag helps you connect and distribute your digital assets to multiple licensed custodians and self-managed wallets, with reporting and automated rebalancing in one secure and easy-to-manage platform.
      </Typography>
      <Box className={classes.image}>
        <SVG src={`/images/marketing/${!isMobile ? 'DistributeRisk' : 'DistributeRiskMob'}${institutional ? '_Ins' : ''}.svg`} />
      </Box>
    </section>
  );
};

export default DistributeRisk;
