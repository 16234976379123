import { AxiosResponse } from 'axios';
import debounce from 'lodash.debounce';
import { BundleMasterCoin, BundleV2 } from 'services/types/bundle';
import UserBundleService from 'services/userbundle-service';
import walletService from 'services/wallet-service';
import { create } from 'zustand';

interface BundleStore {
  ready: boolean;
  bundles: BundleV2[];
  fetchBundles: () => Promise<BundleV2[]>;
  clearBundles: () => void;
  tokens: string[];
  bundlesType: string[];
  currentBundle?: BundleV2;
  basicWalletCoinIds?: number[];

  fetchCurrentBundle: (forceReload: boolean) => Promise<void>;
  clearCurrentBundle: () => void;
  setBasicWallet: () => Promise<AxiosResponse<void>>;
  fetchBasicWalletCoinIds: (forceReload: boolean) => Promise<void>;
}

export const DEFAULT_BUNDLE_ID = 110;
const DEBOUNCE_TIMEOUT = 1000;

export const useStore = create<BundleStore>((set, get) => ({
  ready: false,
  bundles: [],
  fetchBundles: async () => {
    if (get().bundles.length === 0) {
      const bundlesData = await UserBundleService.getUserVisibleBundles();
      const bundles = bundlesData.data.filter(
        (bundle) => bundle.bundleMasterCoins.findIndex((coin: BundleMasterCoin) => coin.name === 'USTC') < 0,
      );
      const tokens = Array.from(new Set(bundles.map((b) => b.bundleMasterCoins.map((c) => c.name || '')).flat()));
      const bundlesType = Array.from(new Set(bundles.map((b) => b.bundleContent)));
      set(() => ({ bundles, bundlesType, tokens, ready: true }));
      return bundles;
    }
    return get().bundles;
  },
  clearBundles: () => {
    set(() => ({ bundles: [], bundlesType: [], tokens: [], ready: false }));
  },
  tokens: [],
  bundlesType: [],
  currentBundle: undefined,
  fetchCurrentBundle: debounce(async (forceReload: boolean = false): Promise<void> => {
    const { currentBundle } = get();
    if (!currentBundle || forceReload) {
      try {
        const response = await UserBundleService.getUserCurrentBundle();
        const bundle = response.data;
        if (bundle?.bundleId) {
          set(() => ({ currentBundle: bundle }));
        }
      } catch (e) {
        // Handled
      }
    }
  }, DEBOUNCE_TIMEOUT, { leading: true }),
  clearCurrentBundle: () => {
    set(() => ({ currentBundle: undefined }));
  },
  setBasicWallet: walletService.setBasicWallet,
  fetchBasicWalletCoinIds: debounce(async (forceReload: boolean) => {
    const { basicWalletCoinIds } = get();
    if (basicWalletCoinIds === undefined || forceReload) {
      const selectedMasterCoins = await walletService.getSelectedMasterCoins();
      const masterCoinIds = selectedMasterCoins
        .map((item) => item.masterCoinId)
        .reduce((prev: number[], curr) => [...prev, ...(prev.includes(curr) ? [] : [curr])], []);
      set({ basicWalletCoinIds: masterCoinIds });
    }
  }, DEBOUNCE_TIMEOUT, { leading: true }),
}));
