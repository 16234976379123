import { Box, makeStyles, Typography, useMediaQuery, useTheme } from '@material-ui/core';
import { Button, Card, CardProps } from 'elements';
import pluralize from 'pluralize';
import React, { ReactNode, useEffect } from 'react';
import SVG from 'react-inlinesvg';
import { Link } from 'react-router-dom';
import { DashboardInterestRoute, InterestSettingRoute } from 'routes';
import { useStore as useCoinStore } from 'store/zustand/Coin';
import { useStore as useFeatureStore } from 'store/zustand/Feature';
import { useStore as useInterestStore } from 'store/zustand/Interest';
import { useStore as useInterestEarningStore } from 'store/zustand/InterestEarning';
import { useStore as useUserStore } from 'store/zustand/User';
import { useStore as useWalletStore } from 'store/zustand/Wallet';

type InterestTotalCardProps = CardProps

const useStyles = makeStyles((theme) => ({
  root: {
    overflow: 'hidden',
    position: 'relative',

    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'space-between',
    rowGap: 8,

    padding: '12px 16px',
    height: '100%',
    width: '100%',

    [theme.breakpoints.up('sm')]: {
      padding: 16,
    },
  },
  defaultRoot: {
    color: theme.palette.common.white,
    background: 'radial-gradient(94.05% 134.3% at 5.95% 50.3%, #02BD9F 0%, #3DEACB 100%)',
  },
  actionRoot: {
    justifyContent: 'center',
    alignItems: 'center',
    rowGap: 16,
    background: theme.palette.common.white,
    border: '2px dashed',
    borderColor: theme.palette.grey['200'],
  },
  actionBackground: {
    userSelect: 'none',
    userDrag: 'none',
    position: 'absolute',
    right: 0,
    bottom: 0,
    height: '100%',
  },
  titleContainer: {
    display: 'flex',
    alignItems: 'baseline',
    justifyContent: 'space-between',
    columnGap: 16,
    width: '100%',
    whiteSpace: 'nowrap',
  },
  title: {
    flexShrink: 0,
    fontSize: 14,
    fontWeight: 500,
    lineHeight: '20px',
    [theme.breakpoints.up('lg')]: {
      fontSize: 16,
      lineHeight: '24px',
    },
  },
  details: {
    color: 'white',
    fontSize: 14,
    fontWeight: 600,
    textTransform: 'uppercase',
    letterSpacing: '0.46px',
    cursor: 'pointer',
  },
  value: {
    display: 'inline',
    verticalAlign: 'middle',
    columnGap: 8,
    marginTop: 4,

    fontSize: 20,
    fontWeight: 400,
    color: theme.palette.common.white,

    [theme.breakpoints.up('sm')]: {
      verticalAlign: 'baseline',
      columnGap: 12,

      fontSize: 24,
      fontWeight: 600,
    },
  },
  unit: {
    display: 'inline',
    verticalAlign: 'middle',
    margin: '0em 0em 0em 0.3em',

    fontSize: 20,
    fontWeight: 300,
    color: theme.palette.common.white,

    [theme.breakpoints.up('sm')]: {
      verticalAlign: 'baseline',
      fontSize: 20,
      fontWeight: 500,
    },
  },
  equivalent: {
    display: 'inline',
    fontSize: '0.7em',
    fontWeight: 500,
    color: theme.palette.green['200'],
    verticalAlign: 'middle',
    [theme.breakpoints.up('sm')]: {
      verticalAlign: 'baseline',
      fontSize: '0.6em',
    },
  },
  protocolName: {
    display: 'flex',
    alignItems: 'center',
    gap: 4,
  },
  protocolConnect: {
    color: theme.palette.common.white,
    fontSize: 10,
    fontWeight: 400,
  },
  disabledCard: {
    background: theme.palette.grey[300],
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    padding: 16,
    '& h5': {
      color: theme.palette.common.white,
    },
    '& h6': {
      color: theme.palette.common.white,
    },
  },
  greenText: { color: theme.palette.green['500'] },
}));

export const InterestTotalCard = (props: InterestTotalCardProps) => {
  const { className, isLoading, ...rest } = props;

  const classes = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const [
    currentProtocol,
    fetchCurrentProtocol,
  ] = useInterestEarningStore((state) => [
    state.currentProtocol,
    state.fetchCurrentProtocol,

  ]);
  const [numActiveLendingTokens, fetchNumActiveLendingTokens] = useInterestStore((state) => [
    state.numActiveLendingTokens, state.fetchNumActiveLendingTokens]);

  const [user] = useUserStore((state) => [state.user]);
  const [isDefi, readyWallet, wallet, activeWalletBalance, fetchActiveWalletBalance] = useWalletStore(
    (state) => [state.isDefi, state.readyWallet, state.wallet, state.activeWalletBalance, state.fetchLatestWalletBalances],
  );
  const [coins, fetchCoins] = useCoinStore((state) => [state.coins, state.fetchCoins]);

  const [features] = useFeatureStore((state) => [state.features]);

  const selectedWalletInfo = React.useMemo(() => (user?.walletInfo.find((w) => w.walletId === wallet?.walletId)), [user, wallet]);

  useEffect(() => {
    fetchCurrentProtocol(false);
    fetchNumActiveLendingTokens(false);
    if (activeWalletBalance === undefined) fetchActiveWalletBalance();
    if (Object.entries(coins).length === 0) fetchCoins();
  }, []);

  const ActionCard = (_props: { actionText: string, actionLink: string, children: ReactNode }) => (
    <Card className={`${classes.root} ${classes.actionRoot} ${className}`} {...rest}>
      <img className={classes.actionBackground} alt="" src="/images/dashboard/bg_interest_total.svg" />
      <Typography variant="h4" className={classes.title} align="center">
        {_props.children}
      </Typography>
      <Link to={_props.actionLink}>
        <Button variant="primary" size={isMobile ? 'sm' : 'md'}>{_props.actionText}</Button>
      </Link>
    </Card>
  );

  if (readyWallet && !isDefi) {
    return (
      <Card className={`${classes.root} ${classes.actionRoot} ${className}`} {...rest}>
        <img className={classes.actionBackground} alt="" src="/images/dashboard/bg_interest_total.svg" />
        <Typography variant="h4" className={classes.title} align="center">
          To start earning interest on your cryptos <br />
          we recommend you to switch to <span className={classes.greenText}>DeFi</span>
        </Typography>
      </Card>
    );
  }

  const notAvailable = 'This feature is not available for read-only wallets. ' +
    'Please switch your wallet in the top right to view this feature.';
  if (readyWallet && selectedWalletInfo?.walletProvider === 'Reporting') {
    return (
      <Card className={classes.disabledCard}>
        <Typography variant="h5">Earned with Coinbag</Typography>
        <Typography variant="h6">
          {notAvailable}
        </Typography>
      </Card>
    );
  }

  if (numActiveLendingTokens === 0 && !isLoading) {
    return (
      <ActionCard actionText="Settings" actionLink={InterestSettingRoute}>
        To start earning interest configure your interest setting here
      </ActionCard>
    );
  }

  return (
    <Card
      className={`${classes.root} ${classes.defaultRoot} ${className}`}
      isLoading={isLoading}
      {...rest}
    >
      <Box className={classes.titleContainer}>
        <Typography variant="h5" className={classes.title}>Earned with Coinbag</Typography>
        {features && !features.interestPageRedirect && (
          <Link className={classes.details} to={DashboardInterestRoute}>
            Details <SVG src="/icons/icon_out.svg" width={14} height={14} />
          </Link>
        )}
      </Box>

      <Box>
        <Typography className={classes.value}>
          {numActiveLendingTokens ?? 0}
        </Typography>
        <Typography className={classes.unit}>{pluralize('Token', numActiveLendingTokens ?? 0)} earned interest</Typography>
      </Box>

      {currentProtocol && (
        <Box className={classes.protocolName}>
          <SVG src={currentProtocol.logo} width={24} height={24} />
          <Typography variant="h5">{currentProtocol.name}</Typography>
          <Typography className={classes.protocolConnect}>Connected protocol</Typography>
        </Box>
      )}
    </Card>
  );
};
