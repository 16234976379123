import { Box, createStyles } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Button, InputWithTitle, LightModal, Typography } from 'elements';
import { LightTextField } from 'elements/LightTextField';
import { AddTagNamePendingAction } from 'models/Features';
import React, { useEffect, useState } from 'react';
import { UserAtomicTransaction } from 'services/types/user-transaction';
import { WorkItemName } from 'services/types/workItem';
import { useStore as useFeatureStore } from 'store/zustand/Feature';
import { useStore as useTransactionStore } from 'store/zustand/Transaction';
import { useStore as useWalletStore } from 'store/zustand/Wallet';

import { SelectWallet } from '../BatchPayroll/SelectWallet';

interface Props {
  showModal: boolean
  // eslint-disable-next-line no-unused-vars
  setShowModal: (value: boolean) => void;
  initTx?: UserAtomicTransaction;
  // eslint-disable-next-line no-unused-vars
  setInitTx?: (value: UserAtomicTransaction | undefined) => void;
  initTag?: string;
  // eslint-disable-next-line no-unused-vars
  setInitTag?: (value: string | undefined) => void;
  // eslint-disable-next-line no-unused-vars
  callbackFnc?: (tagId: number, tag: string) => void;
  // eslint-disable-next-line no-unused-vars
  setShowManageTagModal?: (value: boolean) => void;
}

const useStyles = makeStyles((theme) => createStyles({
  root: {
    display: 'flex',
    flexDirection: 'column',
    position: 'relative',
    gap: 16,
    width: '100%',
    height: '100%',
  },
  buttonWrapper: {
    display: 'flex',
    width: '100%',
    justifyContent: 'space-between',
  },
  button: {
    minWidth: 84,
  },
  saveText: {
    color: theme.palette.common.white,
  },
  errorText: {
    fontSize: 12,
    fontWeight: 400,
    textAlign: 'end',
  },
}));

export const AddTagModal = (
  { showModal, setShowModal, initTx, setInitTx, initTag, setInitTag, callbackFnc, setShowManageTagModal }: Props,
) => {
  const classes = useStyles();

  const [isAllWallets, activeId] = useWalletStore((state) => [state.isAllWallets, state.activeId]);
  const [tags, addTagName, addTag] = useTransactionStore(
    (state) => [state.tags, state.addTagName, state.addTag],
  );
  const [findPendingAction, features, fetchFeatures] = useFeatureStore((state) => [
    state.findPendingAction, state.features, state.fetchFeatures,
  ]);

  const [txHash, setTxHash] = useState<string>(initTx?.txHash ?? '');
  const [inputTag, setInputTag] = useState<string>(initTag ?? '');
  const [addressError, setAddressError] = useState<boolean>(false);
  const [tagError, setTagError] = useState<boolean>(false);
  const [disableButtons, setDisableButtons] = useState<boolean>(false);
  const [selectedWalletId, setSelectedWalletId] = useState<number | undefined>();

  const handleTagInput = (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    setInputTag(e.target.value);
    if (e.target.value !== '') setTagError(false);
  };

  const handleTagBlur = () => {
    if (inputTag === '') {
      setTagError(true);
    }
  };

  const handleOnClose = () => {
    setShowModal(false);
    setInputTag('');
    setTxHash('');
    setAddressError(false);
    setTagError(false);
    if (setInitTx) setInitTx(undefined);
    if (setInitTag) setInitTag(undefined);
  };

  const saveTag = async () => {
    try {
      const existingTag = tags.find((tag) => tag.tag === inputTag);
      const tagId = existingTag !== undefined
        ? existingTag.tagId
        : (inputTag && selectedWalletId !== undefined) && await addTagName(inputTag, selectedWalletId);
      if (typeof tagId === 'number') {
        if (txHash !== '') addTag(txHash, tagId);
        if (callbackFnc) callbackFnc(tagId, inputTag);
      } else if (setShowManageTagModal) setShowManageTagModal(true);
    } finally {
      // Ignore if API fails
    }
  };

  const handleSave = async () => {
    setDisableButtons(true);
    await saveTag();
    handleOnClose();
    setDisableButtons(false);
    setSelectedWalletId(undefined);
    fetchFeatures(true);
  };

  const awaitingAdds = React.useMemo(() => (
    findPendingAction(WorkItemName.AddTagName)?.map((i) => i as AddTagNamePendingAction)
  ), [features]);

  const alreadyExist = React.useMemo(() => {
    const existingTag = tags.filter((t) => t.tag === inputTag && t.walletId === selectedWalletId);
    const awaitingAdd = awaitingAdds?.filter((t) => t.tagName === inputTag && t.toWalletId === selectedWalletId) ?? [];
    return (existingTag.length + awaitingAdd.length) > 0;
  }, [tags, awaitingAdds, inputTag, selectedWalletId]);

  useEffect(() => {
    setTxHash(initTx?.txHash ?? '');
    if (initTx?.walletId !== undefined) {
      setSelectedWalletId(initTx?.walletId);
    } else if (activeId?.activeWalletId !== undefined) {
      setSelectedWalletId(activeId.activeWalletId);
    }
  }, [initTx, showModal, activeId]);

  useEffect(() => {
    setInputTag(initTag ?? '');
  }, [initTag, showModal]);

  return (
    <LightModal
      open={showModal}
      setOpen={setShowModal}
      maxWidth={372}
      onClose={handleOnClose}
      showCloseButton
      modalTitle="Add Tag"
    >
      <Box className={classes.root}>
        <InputWithTitle
          title="Tag name"
          titleRightItem={tagError && <Typography className={classes.errorText} palette="red" paletteColor={600}>Invalid name</Typography>}
        >
          <LightTextField
            value={inputTag}
            onChange={handleTagInput}
            placeholder="New tag"
            onBlur={handleTagBlur}
            error={tagError}
          />
        </InputWithTitle>
        {isAllWallets() && (
          <InputWithTitle title="Save to">
            <SelectWallet
              selectedWalletId={selectedWalletId}
              setSelectedWalletId={setSelectedWalletId}
              disabled={!!initTx?.walletId}
            />
          </InputWithTitle>
        )}
        {alreadyExist && (
          <Typography palette="red" paletteColor={500} align="center" variant="h6">
            Tag name already exists on selected wallet.
          </Typography>
        )}
        <Box className={classes.buttonWrapper}>
          <Button
            variant="secondary"
            size="md"
            disabled={disableButtons}
            onClick={handleOnClose}
            className={classes.button}
          >
            Cancel
          </Button>
          <Button
            size="md"
            onClick={handleSave}
            disabled={
              tagError
              || !inputTag
              || addressError
              || alreadyExist
              || disableButtons
              || (isAllWallets() && selectedWalletId === undefined)
            }
            className={classes.button}
          >
            Save
          </Button>
        </Box>
      </Box>
    </LightModal>
  );
};
