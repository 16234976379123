// @flow
import { Box, createStyles, Hidden, useMediaQuery } from '@material-ui/core';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { chainIdIconMapper, chainIdMapper } from 'components/DepositAndWithdraw/mappers';
import { Checkbox, LightTooltip, Typography } from 'elements';
import React from 'react';
import SVG from 'react-inlinesvg';
import { useStore as useCoinsStore } from 'store/zustand/Coin';

interface PayrollRowProps {
  className: string;
  cellClassName: string;
  tag?: string;
  address: string;
  enable?: boolean;
  quantity?: number;
  payrollId: number;
  usdAmount?: number;
  coinId: number;
  notEnoughFund?: boolean;
  handleCheckbox?: () => void;
  // eslint-disable-next-line no-unused-vars
  handleMenu?: (event: React.MouseEvent<HTMLButtonElement>) => void;
  isMenuActive?: boolean;
}

const useStyles = makeStyles((theme) => createStyles({
  coinIconWrapper: {
    width: 16,
    height: 16,
    display: 'flex',
  },
  threeDotsIconsWrapper: {
    width: 24,
    height: 24,
    display: 'flex',
    alignItems: 'center',
    '&:hover': {
      cursor: 'pointer',
    },
    '&.active': {
      border: `1px solid ${theme.palette.green[200]}`,
      borderRadius: 4,
      '& circle': {
        fill: theme.palette.green[500],
      },
    },
  },
  quantityWrapper: {
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap',
    columnGap: 4,
    rowGap: 8,
  },
  redCheck: {
    color: `${theme.palette.red[600]} !important`,
  },
  warnIcon: {
    cursor: 'pointer',
    display: 'flex',
  },
}));

export const PayrollRow = React.memo((props: PayrollRowProps) => {
  const classes = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const [coins] = useCoinsStore((state) => [state.coins]);

  if (!coins) return null;

  return (
    <Box className={props.className}>
      {props.handleCheckbox !== undefined && (
        <Box className={props.cellClassName} id="checkbox">
          <Checkbox
            checked={props.enable}
            onChange={props.handleCheckbox}
            className={props.enable && props.notEnoughFund ? classes.redCheck : ''}
          />
        </Box>
      )}
      <Box className={props.cellClassName} id="name">
        <Typography variant="h4" paletteColor={700} component="span">
          {props.tag}
        </Typography>
        <Hidden smDown>
          <Typography
            variant="h6"
            paletteColor={500}
            middleTruncation
          >
            {props.address}
          </Typography>
        </Hidden>
      </Box>
      <Box className={props.cellClassName} id="chain">
        <Box className={classes.coinIconWrapper}>
          <SVG
            src={chainIdIconMapper[coins[props.coinId?.toString() ?? '']?.chainId ?? '']
              ?? '/icons/coin_icon/fallback-1.svg'}
            width={16}
            height={16}
          />
        </Box>
        <Typography variant={isMobile ? 'h6' : 'h4'} paletteColor={700}>
          {(chainIdMapper[coins[props.coinId?.toString() ?? '']?.chainId ?? ''] ?? [])[0] ?? ''}
        </Typography>
      </Box>
      <Hidden mdUp>
        <Box className={props.cellClassName} id="address">
          <Typography variant="h6" paletteColor={500} component="span" middleTruncation>
            {props.address}
          </Typography>
        </Box>
      </Hidden>
      <Box className={props.cellClassName} id="coin">
        <Box className={classes.coinIconWrapper}>
          <SVG
            src={coins[props.coinId]?.masterCoin.icon ?? '/icons/coin_icon/fallback-1.svg'}
            height={16}
            width={16}
          />
        </Box>
        <Typography variant={isMobile ? 'h6' : 'h4'} paletteColor={700}>
          {coins[props.coinId]?.symbol ?? ''}
        </Typography>
      </Box>
      <Box className={props.cellClassName} id="coin_value">
        <Box className={classes.quantityWrapper}>
          <Typography
            variant="h4"
            paletteColor={props.enable && props.notEnoughFund ? 600 : 700}
            palette={props.enable && props.notEnoughFund ? 'red' : 'grey'}
            align="right"
          >
            {props.quantity?.toLocaleString(undefined, {
              maximumSignificantDigits: props.quantity > 1 ? undefined : 6,
              minimumFractionDigits: props.quantity > 1e8 ? 2 : undefined,
            }) ?? ''}
          </Typography>
          {props.enable && props.notEnoughFund && (
            <LightTooltip
              placement="bottom"
              arrow
              title={`Not enough ${coins[props.coinId.toString()]?.symbol} to proceed selected payments`}
            >
              <Box className={classes.warnIcon}>
                <SVG src="icons/batch_payroll/ic_warning.svg" width={16} height={16} />
              </Box>
            </LightTooltip>
          )}

        </Box>
        <Hidden mdUp>
          <Typography variant="h6" paletteColor={500}>≈</Typography>
          <Typography variant="h6" paletteColor={500} align="right">
            {props.usdAmount?.toLocaleString() ?? ''}
          </Typography>
        </Hidden>
      </Box>
      <Hidden smDown>
        <Box className={props.cellClassName} id="equal">
          <Typography variant="h6" paletteColor={500}>≈</Typography>
        </Box>
        <Box className={props.cellClassName} id="usdt_value">
          <Typography variant="h6" paletteColor={500} align="right">
            {props.usdAmount?.toLocaleString() ?? ''}
          </Typography>
        </Box>
      </Hidden>
      {props.handleMenu !== undefined && (
        <Box className={props.cellClassName} id="menu">
          <Box
            className={`${classes.threeDotsIconsWrapper} ${props.isMenuActive ? 'active' : ''}`}
            onClick={props.handleMenu}
            id={`${props.payrollId}`}
          >
            <SVG src="icons/batch_payroll/ic_three_dots.svg" width={24} height={24} />
          </Box>
        </Box>
      )}
    </Box>
  );
});

PayrollRow.displayName = 'PayrollRow';
