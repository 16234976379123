import React, { Component } from "react";
import { Footer } from "../../components/Footer.js";
import { InternalNavbar } from "../../components/navbar.js";
import { Container, Row, Col } from 'react-bootstrap'
import Loader from "../../components/Loader.js";
import { StepNav } from "../../components/StepNav.js";
import WalletService from "../../services/wallet-service";
import ProfileReview from "./ProfileReview.js";
import BinanceModal from "../../components/BinanceModal.js";
import GenericModal from "../../components/GenericModal.js";
import BinanceConnect from "../../components/BinanceConnect.js";
import FundWallet from "../responsive/FundWallet.js";

export default class ConnectExchange extends Component {
  static routePath = '/connect-exchange';
  static loginRequired = true;

  constructor(props) {
    super(props);
    this.state = {
      loading: true
    }

    WalletService.getWallet().then(wallet => {
      if(wallet.data.exchangeId !== 1){
        this.props.history.push(FundWallet.routePath)
      }
    })
  }

  render() {

    return (
      <>
        <Loader loading={this.state.loading} />

        <InternalNavbar history={this.props.history} />

        <StepNav active={3} />

        <Container className="px-3 pb-3">
            <Row>
              <Col>
                <h4>Connect exchange</h4>
                <p className="mb-0">
                  We use your API keys to securely connect to Binance and manage your portfolio. You may choose to skip any of these steps and come back to it later.
                </p>
              </Col>
            </Row>
        </Container>

        <BinanceConnect history={this.props.history} location={this.props.location} onLoadComplete={() => this.setState({ loading: false })} />

        <Container>
          <Row className="text-center">
            <Col>
              <a href="#" onClick={() => this.props.history.push({ pathname: ProfileReview.routePath })}>
                Skip this step
              </a>
            </Col>
          </Row>
        </Container>

        <BinanceModal name="binanceModal" dismissOnClick={true} history={this.props.history} />
        <GenericModal name="trustedModal">
          <h5 className="mb-3">API Management Binance</h5>
          <img src="images/BinanceApiDesktop.png" width="100%" height="100%" />
        </GenericModal>

        <Footer />
      </>
    );
  }
}
