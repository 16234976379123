import { Box,LinearProgress, Link, makeStyles } from '@material-ui/core';
import { Card, Typography } from 'elements';
import React from 'react';
import { DashboardUstRoute } from 'routes';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    rowGap: 12,
  },
  container: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  totalRow: {
    display: 'flex',
    flexWrap: 'nowrap',
    gap: 8,
    justifyContent: 'space-between',
    marginBottom: 8,
  },
  row: {
    marginTop: 20,
    display: 'flex',
    flexWrap: 'nowrap',
    gap: 12,
    alignItems: 'center',
  },
  rowDetail: {
    display: 'flex',
    flexWrap: 'nowrap',
    flexGrow: 1,
    alignItems: 'center',
    gap: 12,
    justifyContent: 'space-between',
    [theme.breakpoints.down('sm')]: {
      gap: 4,
      flexDirection: 'column',
      alignItems: 'flex-start',
    },
  },
  imageSize: {
    flexShrink: 0,
    height: 32,
    width: 32,
    [theme.breakpoints.down('sm')]: {
      height: 52,
      width: 52,
    },
  },
  explanationText: {
    color: theme.palette.green[600],
    fontWeight: 500,
    fontSize: 12,
    lineHeight: '20px',
    textTransform: 'uppercase',
    textAlign: 'center',
    marginTop: 4,
  },
  withdrawText: {
    fontWeight: 500,
    fontSize: 12,
    lineHeight: '20px',
    color: theme.palette.grey[800],
    marginTop: 12,
  },
  bar: {
    backgroundColor: theme.palette.blue[500],
  },
  progressBar: {
    height: 8,
    borderRadius: 4,
    backgroundColor: theme.palette.blue[200],
  },
  blueText: {
    color: theme.palette.blue[600],
  },
  usdText: {
    fontSize: 14,
    lineHeight: '20px',
    fontWeight: 500,
    color: theme.palette.grey[700],
    paddingLeft: 4,
  },
  totalText: {
    fontSize: 14,
    lineHeight: '28px',
    fontWeight: 600,
    color: theme.palette.grey[800],
  },
  lunaText: {
    fontSize: 14,
    lineHeight: '28px',
    fontWeight: 600,
    flexShrink: 0,
  },
  vestedText: {
    fontSize: 12,
    lineHeight: '28px',
    fontWeight: 500,
    marginLeft: 4,
  },
  priceText: {
    fontSize: 16,
    lineHeight: '20px',
    fontWeight: 600,
    color: theme.palette.grey[700],
    textAlign: 'right',
  },
}));

type Terra2HoldingsProps = {
  lockedPrice?: number;
  lockedQuantity?: number;
  price?: number;
  quantity?: number;
  className?: string;
  title?: React.ReactNode;
  showLearnMore: boolean;
}

export const Terra2Holdings = ({
  lockedPrice, lockedQuantity, price, quantity, className, showLearnMore, title,
}: Terra2HoldingsProps) => {
  const classes = useStyles();
  if (lockedPrice === undefined || lockedQuantity === undefined || price === undefined || quantity === undefined) return null;
  const vestedPrice = price;
  const vestedQuantity = quantity;
  const totalQuantity = quantity + lockedQuantity;
  const totalPrice = price + lockedPrice;

  return (
    <Card className={`${classes.root} ${className || ''}`}>
      {title || (<Typography palette="grey" paletteColor={800} variant="h2">Your Terra 2.0 Holdings</Typography>)}
      {showLearnMore && (
        <Link href={DashboardUstRoute} underline="none" align="center">
          <Typography display="inline" className={classes.explanationText}>
            Learn why this is separate & track the latest updates on Terra
            reimbursements
          </Typography>
        </Link>
      )}
      <Box className={classes.container}>
        <Box className={classes.totalRow}>
          <Typography className={classes.totalText}>
            TOTAL
          </Typography>
          <Typography component="span" className={classes.totalText} align="right">
            {(totalQuantity).toLocaleString(undefined, {
              maximumFractionDigits: 2, minimumFractionDigits: 2,
            })} LUNA ≈ {(totalPrice).toLocaleString(undefined, { maximumFractionDigits: 2, minimumFractionDigits: 2 })}
            <Typography component="span" className={classes.usdText}>USD</Typography>
          </Typography>
        </Box>
        <LinearProgress
          classes={{ bar: classes.bar }}
          className={classes.progressBar}
          variant="determinate"
          value={(vestedPrice / totalPrice) * 100}
        />
        <Box className={classes.row}>
          <img src="/icons/luna.svg" className={classes.imageSize} alt="luna unlocked" />
          <Box className={classes.rowDetail}>
            <Typography component="span" className={classes.lunaText} palette="blue" paletteColor={600}>
              LUNA
              <Typography component="span" className={classes.vestedText} palette="blue" paletteColor={500}>
                Vested
                <Typography className={classes.vestedText} component="span">
                  Available
                </Typography>
              </Typography>
            </Typography>
            <Typography component="span" className={classes.priceText}>
              {vestedQuantity.toLocaleString(
                undefined,
                { maximumFractionDigits: 2, minimumFractionDigits: 2 }
              )} LUNA ≈ {vestedPrice.toLocaleString(undefined, { maximumFractionDigits: 2, minimumFractionDigits: 2 })}
              <Typography component="span" className={classes.usdText}>USD</Typography>
            </Typography>
          </Box>
        </Box>
        <Box className={classes.row}>
          <img src="/icons/luna_lock.svg" className={classes.imageSize} alt="luna locked" />
          <Box className={classes.rowDetail}>
            <Typography component="span" className={classes.lunaText} palette="grey" paletteColor={700}>
              LUNA
              <Typography component="span" className={classes.vestedText} palette="grey" paletteColor={600}>
                Unvested
                <Typography className={classes.vestedText} component="span" palette="grey" paletteColor={400}>
                  Not Available
                </Typography>
              </Typography>
            </Typography>
            <Typography component="span" className={classes.priceText}>
              {lockedQuantity.toLocaleString(
                undefined,
                { maximumFractionDigits: 2, minimumFractionDigits: 2 }
              )} LUNA ≈ {lockedPrice.toLocaleString(undefined, { maximumFractionDigits: 2, minimumFractionDigits: 2 })}
              <Typography component="span" className={classes.usdText}>USD</Typography>
            </Typography>
          </Box>
        </Box>
      </Box>
      <Typography align="center" className={classes.withdrawText}>
        Chat with us or contact us at <Link href="mailto:support@coinbag.finance" underline="none">support@coinbag.finance</Link> to
        withdraw available assets.
      </Typography>
    </Card>
  );
};
