import axios from "axios";
import Headers from './headers';

const API_URL = "/api/stats/";

class StatsService {
  constructor(apiUrl) {
    this.apiUrl = apiUrl;
  }

  getHighLevelStats(since) {
    return axios.get(this.apiUrl + `high-level/${since}`,
      {headers: {...Headers.def(), ...Headers.auth()}});
  }
}

export default new StatsService(API_URL);
