import { Box, createStyles, makeStyles, useMediaQuery } from '@material-ui/core';
import { Button, Typography } from 'elements';
import * as React from 'react';
import SVG from 'react-inlinesvg';
import { DepositRoute } from 'routes';
import { breakpoints } from 'theme/base';

const useStyles = makeStyles((theme) => createStyles({
  root: {
    padding: '20px 24px',
    border: `2px dashed ${theme.palette.grey[200]}`,
    borderRadius: 8,
    position: 'relative',
    overflow: 'hidden',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    gap: 16,
    width: '100%',
    boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.12)',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      padding: 16,
      alignItems: 'flex-start',
    },
  },
  text: {
    color: theme.palette.grey[600],
    '& em': {
      color: theme.palette.green[600],
      fontStyle: 'normal',
      fontWeight: 600,
    },
  },
  button: {
    whiteSpace: 'nowrap',
    flexShrink: 0,
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
  bg: {
    position: 'absolute',
    '&#a': {
      width: 78,
      height: 78,
      left: 8,
      top: -40,
    },
    '&#b': {
      width: 78,
      height: 78,
      right: 70,
      bottom: -34,
    },
    '&#c': {
      width: 42,
      height: 42,
      top: 8,
      right: -8,
    },
  },
}));

const DepositMoreBanner = () => {
  const classes = useStyles();
  const isMobile = useMediaQuery(breakpoints.down('sm'));
  return (
    <Box
      className={classes.root}
    >
      <SVG src="/icons/rebalancing/ic_bg.svg" className={classes.bg} id="a" />
      <SVG src="/icons/rebalancing/ic_bg.svg" className={classes.bg} id="b" />
      <SVG src="/icons/rebalancing/ic_bg.svg" className={classes.bg} id="c" />
      <Typography variant="h4" className={classes.text}>
        To set Static or Dynamic rebalancing your account should have more than <em>{(3000).toLocaleString()} USD.</em> <br /> Deposit more to switch.
      </Typography>
      <Button size={isMobile ? 'sm' : 'xmd'} href={DepositRoute} className={classes.button}>
        Deposit more
      </Button>
    </Box>
  );
};

export default DepositMoreBanner;
