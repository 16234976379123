import React from 'react';
import { InterestSettingRoute, PortfolioSettingRoute, RebalanceSettingRoute } from 'routes';

import { PagesNavBar } from './PagesNavBar';

interface ManagePortfolioNavBarProps {
  hasNonSwappable: boolean
}

const ManagePortfolioNavBar = ({ hasNonSwappable }: ManagePortfolioNavBarProps) => {
  const items = [
    { icon: '/icons/dashboard/ic_portfolio_setting.svg', text: 'Wallet settings', route: PortfolioSettingRoute },
    { icon: '/icons/dashboard/ic_protocol_setting.svg', text: 'Interest settings', route: InterestSettingRoute },
  ];

  if (!hasNonSwappable) {
    items.push(
      { icon: '/icons/dashboard/ic_rebalance.svg', text: 'Rebalancing', route: RebalanceSettingRoute },
    );
  }

  return (<PagesNavBar items={items} />);
};

export default ManagePortfolioNavBar;
