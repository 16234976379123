import React from "react";
import { Footer } from "../../components/Footer.js";
import { InternalNavbar } from "../../components/navbar.js";
import { Container, Row, Col } from 'react-bootstrap'
import Loader from "../../components/Loader.js";
import SharedStateComponent from "../../components/SharedStateComponent.js";
import { FIELD, isDesktop } from "../../common.js";
import { StepNav } from "../../components/StepNav.js";
import WalletService from "../../services/wallet-service";
import ProfileReview from "../mobile/ProfileReview.js";
import AuthService from "../../services/auth-service.js";
import Logger from "js-logger";

export default class FundWallet extends SharedStateComponent {
  static routePath = '/fund-wallet';
  static loginRequired = true;

  constructor(props) {
    super(props);

    this.onUpdateOption = this.onUpdateOption.bind(this);

    this.state = {
    }
  }

  componentDidMount() {
    const userInfo = AuthService.getUserInfo().then(user => {
      user.data.email = AuthService.getCurrentUser().email;
      return user
    })
    const countryList = AuthService.getCountryList().then(countries => {
      countries.data.sort((a, b) => (a.name < b.name) ? -1 : 1)
      countries.data = countries.data.filter(c => c.name == "Ireland")
      return countries
    })
    const dexWallets = WalletService.getDexWallets()

    const wallet = WalletService.getWallet()

    Promise.all([dexWallets, userInfo, countryList, wallet]).then(arr => {
      let wallets = arr[0].data;
      let form = arr[1].data
      let countries = arr[2].data
      let wallet = arr[3].data

      var address;
      if(wallet.riskScore === 0){
        address = wallets.find(wallet => wallet.chainId === "terra-mainnet").address;
      } else {
        address = wallets.find(wallet => wallet.chainId === "56").address;
      }

      this.setState({
        wallets,
        address,
        form,
        countries
      }, this.getWalletsStatus);
    })
  }

  onUpdateOption(option) {
    if (option.name === "DEPOSIT") {
      this.setState({
        option: option.name
      })
    } else {
      this.setState({
        option: option.name
      })
    }
  }

  renderWithSharedState() {
    Logger.log(this.state)

    if (this.state.loading || !this.state.wallets) {
      return (<Loader loading={true} />)
    }

    return (
      <>
        <Loader loading={false} />

        <InternalNavbar history={this.props.history} />

        <StepNav active={isDesktop() ? 4 : 3} />

        <div className="responsive">

          <Container className="px-3 pb-3">
            <Row>
              <Col>
                <p className="text-center p1">Choose how to fund</p>
                <p className="mb-0">Options vary based on your residence. Please tell us where you live and we’ll give you the best options!</p>
              </Col>
            </Row>
            <Row>
              <Col>
              </Col>
            </Row>
            <Row className="text-center">
              <Col>
                {this.state.option == "DEPOSIT" ?
                  <button className="btn-first btn-block p3" onClick={() => this.props.history.push({ pathname: ProfileReview.routePath })}>
                    Next
                  </button>
                  :
                  (this.state.howtofund?.partnerModal == false && this.state.option == "FUND" ?
                  <button className="btn-first btn-block p3" onClick={() => this.props.history.push({ pathname: ProfileReview.routePath })}>
                    I bought my crypto!
                  </button>
                  :
                  <a href="#" onClick={() => this.props.history.push({ pathname: ProfileReview.routePath })}>
                    Skip this step
                  </a>
                  )
                }
              </Col>
            </Row>
          </Container>

        </div>

        <Footer />


      </>
    );
  }
}
