import { Features, PendingAction } from 'models/Features';
import { WorkItemName } from 'services/types/workItem';
import { create } from 'zustand';

import { getFeatures } from '../../services/feature.service';

interface FeatureStore {
  features?: Features;
  fetchFeatures: (forceReload?: boolean) => Promise<void>;
  findPendingAction: (name: WorkItemName) => PendingAction[] | undefined;
}

export const useStore = create<FeatureStore>((set, get) => ({
  features: undefined,
  fetchFeatures: async (forceReload: boolean = false): Promise<void> => {
    const { features } = get();
    if (!features || forceReload) {
      try {
        const fetchedFeatures = await getFeatures();

        set(() => ({ features: fetchedFeatures }));
      } catch (e) {
        // Handled
      }
    }
  },
  findPendingAction: (name: WorkItemName) => {
    const feature = get().features;
    return feature?.pendingActions.filter((a) => a.workItemName === name);
  },
}));
