import { OutlinedInput as MuiOutlinedInput, Theme, withStyles } from '@material-ui/core';

export const OutlinedInput = withStyles((theme: Theme) => ({
  root: {
    overflow: 'hidden',

    width: '100%',
    height: '2.5em',

    background: 'white !important',
    border: '1px solid',
    borderRadius: 8,
    borderColor: theme.palette.grey['200'],
    '&:focus-within': {
      borderColor: theme.palette.green['500'],
    },

    '& input::-webkit-clear-button, & input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button': {
      display: 'none',
    },
    '& input': {
      height: 'fit-content !important',

      fontSize: 12,
      fontWeight: 400,
      lineHeight: '20px',

      background: 'transparent',
    },
    '& input:focus': { border: 'none' },

    '& input:-webkit-autofill, & input:-webkit-autofill:hover, & input:-webkit-autofill:focus': {
      padding: 12,
    },

    '& fieldset': { border: 'none' },
  },
  error: {
    borderColor: theme.palette.red['400'],
    '&:focus-within': {
      borderColor: theme.palette.red['500'],
    },
  },
}))(MuiOutlinedInput);
