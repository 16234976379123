import { Box, Grid, ListSubheader, makeStyles, MenuItem, Typography } from '@material-ui/core';
import { LightSelect } from 'elements/LightSelect';
import React, { FC, useCallback, useMemo, useState } from 'react';
import { CoinWithMasterCoinInfo } from 'services/types/coin';

import { TokenItem } from './TokenItem';

const useStyles = makeStyles((theme) => ({
  headerItem: {
    padding: '10px 16px',
    background: '#FAFBFF',
    borderBottom: `1px solid ${theme.palette.grey[200]}`,
  },
  coinTypeHeader: {
    color: theme.palette.grey[700],
    fontSize: 12,
    lineHeight: '20px',
    fontWeight: 500,
  },
  menuItem: {
    alignItems: 'center',
    padding: '8px 16px',
    borderBottom: `1px solid ${theme.palette.grey[200]}`,
    '&.Mui-selected': {
      backgroundColor: theme.palette.green[100],
      '&:hover': {
        backgroundColor: theme.palette.green[100],
      },
    },
  },
  placeholder: {
    display: 'inline-flex',
    alignItems: 'center',
    gap: 4,
    fontWeight: 400,
    '& img': {
      width: 20,
      height: 20,
    },
  },
  selectHeader: {
    color: theme.palette.grey[500],
    fontWeight: 400,
    fontSize: 12,
    lineHeight: '20px',
  },
  supportedNetworkText: {
    textAlign: 'right',
    color: theme.palette.grey[500],
    fontWeight: 400,
    lineHeight: '20px',
  },
}));

interface SelectTokenProps {
  type: 'Deposit' | 'Withdraw';
  tokens: Record<string, CoinWithMasterCoinInfo[]>;
  // eslint-disable-next-line no-unused-vars
  setSelectedMasterCoin: (masterCoinTicker: string) => void;
  moonPaySupportedByMasterCoinName?: Record<string, boolean>
}

const SelectToken: FC<SelectTokenProps> = ({
  type,
  tokens,
  setSelectedMasterCoin,
  moonPaySupportedByMasterCoinName,
}) => {
  const classes = useStyles();
  const [selectedCoin, setSelectedCoin] = useState<CoinWithMasterCoinInfo[]>();

  const stableCoins: Map<string, CoinWithMasterCoinInfo[]> = useMemo(() => {
    if (!tokens) return new Map();
    return Object.entries(tokens).reduce<CoinWithMasterCoinInfo[]>((coins, [, masterCoin]) => (
      [...coins, ...masterCoin.filter(
        (coin) => coin.masterCoin.metadata?.stablecoin,
      )]
    ), []).reduce((coinMap: Map<string, CoinWithMasterCoinInfo[]>, e) => coinMap.set(
      e.masterCoin.ticker.toUpperCase(), [...coinMap.get(e.masterCoin.ticker) || [], e],
    ), new Map());
  }, [tokens]);

  const cryptoCoins: Map<string, CoinWithMasterCoinInfo[]> = useMemo(() => {
    if (!tokens) return new Map();
    return Object.entries(tokens).reduce<CoinWithMasterCoinInfo[]>((coins, [, masterCoin]) => (
      [...coins, ...masterCoin.filter(
        (coin) => !coin.masterCoin.metadata?.stablecoin,
      )]
    ), []).reduce((coinMap: Map<string, CoinWithMasterCoinInfo[]>, e) => coinMap.set(
      e.masterCoin.ticker.toUpperCase(), [...coinMap.get(e.masterCoin.ticker) || [], e],
    ), new Map());
  }, [tokens]);

  const Placeholder = useCallback(() => (
    <Box className={classes.placeholder}>
      <img src="icons/withdraw/ic_coin_placeholder.svg" alt="Placeholder" />
      <Typography variant="h6" className={classes.selectHeader}>Token</Typography>
    </Box>
  ), []);

  return (
    <LightSelect
      value={selectedCoin ? selectedCoin[0].coinId : 'placeholder'}
      renderValue={() => (selectedCoin ? (
        <Box marginTop={-2} marginBottom={-2} width="100%">
          <TokenItem
            isSvg={false}
            id={selectedCoin[0].coinId}
            icon={selectedCoin[0].masterCoin.icon}
            name={selectedCoin[0].masterCoin.ticker ?? selectedCoin[0].symbol}
            networkIds={selectedCoin.map((c) => (c.chainId))}
          />
        </Box>
      ) : <Placeholder />)}
    >
      <MenuItem value="placeholder" style={{ display: 'none' }} />
      {Object.keys(tokens).length === 0 && (
        <ListSubheader className={classes.headerItem}>
          <Typography variant="h6" className={classes.coinTypeHeader}>Loading...</Typography>
        </ListSubheader>
      )}
      {Object.keys(tokens).length !== 0 && stableCoins && stableCoins.size ? (
        <ListSubheader className={classes.headerItem}>
          <Grid container direction="row" justifyContent="space-between">
            <Typography variant="h6" className={classes.coinTypeHeader}>{type} to Stablecoins</Typography>
            <Typography variant="h6" className={classes.supportedNetworkText}>Supported Network(s)</Typography>
          </Grid>
        </ListSubheader>
      ) : null}
      {
        (Object.keys(tokens).length !== 0 && stableCoins && stableCoins.size)
          ? Array.from(stableCoins.entries(), ([key, coins]) => (
            <MenuItem
              key={key}
              value={coins[0].coinId}
              className={classes.menuItem}
              onClick={() => {
                setSelectedMasterCoin(coins[0].masterCoin.ticker);
                setSelectedCoin(coins);
              }}
            >
              <TokenItem
                isSvg
                id={coins[0].coinId}
                icon={coins[0].masterCoin.icon}
                name={coins[0].masterCoin.ticker ?? coins[0].symbol}
                networkIds={coins.map((c) => (c.chainId))}
                moonPaySupported={moonPaySupportedByMasterCoinName
                  ? moonPaySupportedByMasterCoinName[coins[0].masterCoin.ticker ?? coins[0].symbol]
                  : false}
              />
            </MenuItem>
          )) : null
      }
      {Object.keys(tokens).length !== 0 && cryptoCoins && cryptoCoins.size ? (
        <ListSubheader className={classes.headerItem}>
          <Grid container direction="row" justifyContent="space-between">
            <Typography variant="h6" className={classes.coinTypeHeader}>{type} to Cryptos</Typography>
            <Typography variant="h6" className={classes.supportedNetworkText}>Supported Network(s)</Typography>
          </Grid>
        </ListSubheader>
      ) : null}
      {
        (Object.keys(tokens).length !== 0 && cryptoCoins && cryptoCoins.size)
          ? Array.from(cryptoCoins.entries(), ([key, coins]) => (
            <MenuItem
              key={key}
              value={coins[0].coinId}
              className={classes.menuItem}
              onClick={() => {
                setSelectedMasterCoin(coins[0].masterCoin.ticker);
                setSelectedCoin(coins);
              }}
            >
              <TokenItem
                isSvg
                id={coins[0].coinId}
                icon={coins[0].masterCoin.icon}
                name={coins[0].masterCoin.ticker ?? coins[0].symbol}
                networkIds={coins.map((c) => (c.chainId))}
                moonPaySupported={moonPaySupportedByMasterCoinName
                  ? moonPaySupportedByMasterCoinName[coins[0].masterCoin.ticker ?? coins[0].symbol]
                  : false}
              />
            </MenuItem>
          ))
          : null
      }
    </LightSelect>
  );
};

SelectToken.displayName = 'SelectToken';
export { SelectToken };
