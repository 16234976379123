import { Box, Grid, Hidden, makeStyles, Typography } from '@material-ui/core';
import { UserEvent, UserEventType } from 'models';
import React, { FC, useCallback, useMemo } from 'react';
import SVG from 'react-inlinesvg';

type AccountHistoryItemProps = {
  className?: string
  event: UserEvent
}

const useStyle = makeStyles((theme) => ({
  root: {
    padding: '12px 16px',
    width: '100%',

    borderStyle: 'solid',
    borderColor: theme.palette.grey['200'],
    borderWidth: '0 0 1px',

    [theme.breakpoints.up('sm')]: {
      padding: '12px 0',
    },
  },

  event: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    rowGap: 4,

    [theme.breakpoints.up('sm')]: {
      flexDirection: 'row',
      alignItems: 'center',
      columnGap: 16,
    },
  },
  eventType: { color: theme.palette.grey['700'] },

  smallIcon: {
    marginLeft: 4,
    width: 16,
    height: 16,
  },
  depositIcon: {
    filter: 'invert(52%) sepia(36%) saturate(1791%) hue-rotate(130deg) brightness(106%) contrast(95%)',
  },
  withdrawalIcon: {
    filter: 'invert(80%) sepia(14%) saturate(4738%) hue-rotate(354deg) brightness(103%) contrast(101%)',
  },

  timestamp: {
    display: 'flex',
    alignItems: 'center',
    height: '100%',
  },
  eventTimestamp: {
    color: theme.palette.grey['400'],
    fontSize: 12,
    fontWeight: 400,
    lineHeight: '16px',

    [theme.breakpoints.up('sm')]: {
      color: theme.palette.grey['600'],
      fontSize: 14,
      fontWeight: 500,
      lineHeight: '24px',
    },
  },

  amount: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'flex-end',
    height: '100%',

    [theme.breakpoints.up('sm')]: {
      flexDirection: 'row',
      justifyContent: 'end',
      alignItems: 'center',
    },
  },
  eventPrice: {
    marginLeft: 8,

    color: theme.palette.grey['500'],
    fontSize: 12,
    fontWeight: 500,
    lineHeight: '16px',
  },

  smallCoin: {
    marginRight: 8,
    width: 16,
    height: 16,
  },
}));

const AccountHistoryItem: FC<AccountHistoryItemProps> = ({ className, event }) => {
  const classes = useStyle();

  const timestamp = useMemo(() => {
    const date = new Date(event.timestamp);
    const pad = (num: number) => num.toString().padStart(2, '0');
    return `${pad(date.getDate())}.${pad(date.getMonth() + 1)}.${date.getFullYear()},
      ${pad(date.getHours())}:${pad(date.getMinutes())}`;
  }, [event.timestamp]);

  const amount = useMemo(() => {
    if (event.amount === undefined) return '';

    const fractionDigits = event.amount < 1 && event.amount > -1 && event.amount !== 0
      ? Math.ceil(-Math.log10(Math.abs(event.amount))) : 0;
    return `${event.amount > 0 ? '+' : ''}${event.amount.toLocaleString('en-GB', {
      minimumFractionDigits: fractionDigits,
    })}`;
  }, [event.amount]);
  const price = useMemo(() => {
    if (event.price === undefined) return '';
    return `${event.eventType === UserEventType.Rebalanced ? (event.price ? '-' : '') : '≈ '}$${
      (event.price < 0 ? -event.price : event.price).toLocaleString()
    }`;
  }, [event.price]);

  // eslint-disable-next-line no-shadow
  const EventIcon = useCallback(({ className, size }) => (
    <img
      className={className}
      width={size}
      height={size}
      alt=""
      src={
        event.eventType === UserEventType.Fee ? '/icons/history/ic_fee.svg'
          : event.eventType === UserEventType.DefiSwitch ? '/icons/history/ic_defi_switch.svg'
            : event.eventType === UserEventType.Rebalanced ? '/icons/history/ic_rebalanced.svg'
              : event.eventType === UserEventType.StoppedRebalance ? '/icons/history/ic_stopped_rebalance.svg'
                : '/icons/history/ic_created.svg'
      }
    />
  ), [event]);

  return (
    <Box className={`${classes.root} ${className}`}>
      <Grid container spacing={1}>
        <Grid item xs={7} sm={6}>
          <Box className={classes.event}>
            <Hidden xsDown>
              {event.coin && event.eventType !== UserEventType.StoppedRebalance ? (
                <SVG src={event.coin?.masterCoin?.icon ?? '/icons/coin_icon/fallback-1.svg'} width={32} height={32} />
              ) : (
                <EventIcon size={32} />
              )}
            </Hidden>

            <Typography variant="h4" className={classes.eventType}>
              {event.title}
              {(event.eventType === UserEventType.Deposit || event.eventType === UserEventType.Withdrawal) ? (
                <img
                  className={`${classes.smallIcon} ${
                    event.eventType === UserEventType.Deposit ? classes.depositIcon : classes.withdrawalIcon
                  }`}
                  alt=""
                  src={
                    event.eventType === UserEventType.Deposit
                      ? '/icons/dashboard/ic_deposit_2.svg'
                      : '/icons/dashboard/ic_withdraw_2.svg'
                  }
                />
              ) : (
                <Hidden smUp>
                  <EventIcon className={classes.smallIcon} />
                </Hidden>
              )}
            </Typography>

            <Hidden smUp>
              <Typography className={classes.eventTimestamp}>{timestamp}</Typography>
            </Hidden>
          </Box>
        </Grid>

        <Hidden xsDown>
          <Grid item sm={3}>
            <Box className={classes.timestamp}>
              <Typography className={classes.eventTimestamp}>{timestamp}</Typography>
            </Box>
          </Grid>
        </Hidden>

        <Grid item xs={5} sm={3}>
          <Box className={classes.amount}>
            {amount && (
              <Typography variant="h4" align="right">
                {event.coin && event.coin.masterCoin && (
                  <Hidden smUp>
                    <SVG
                      className={classes.smallCoin}
                      src={event.coin.masterCoin.icon ?? '/icons/coin_icon/fallback-1.svg'}
                    />
                  </Hidden>
                )}
                {amount} {event.coin?.symbol ?? ''}
              </Typography>
            )}

            {price && (
              <Typography className={classes.eventPrice}>{price}</Typography>
            )}
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

AccountHistoryItem.displayName = 'AccountHistoryItem';
export { AccountHistoryItem };
