import { createStyles, makeStyles, Theme } from '@material-ui/core';
import React from 'react';
import { palette } from 'theme';

interface ListProps {
  items: React.ReactNode[];
  color?: keyof Omit<typeof palette, 'primary' | 'error'>;
  colorVarient?: 100 | 400 | 500 | 600;
  className?: string;
  markerSize?: number;
  gap?: number;
}

const useStyles = makeStyles<Theme, Partial<ListProps>>((theme) => createStyles({
  listItem: {
    '&::marker': {
      fontSize: ({ markerSize }) => markerSize || 20,
      color: ({ color, colorVarient }) => theme.palette[color ?? 'green'][colorVarient || 600],
      display: 'flex',
    },
  },
  list: {
    '& li:not(:last-child)': {
      paddingBottom: ({ gap }) => gap,
    },
    marginBottom: 0,
  },
}));

const List = (props: ListProps) => {
  const classes = useStyles(props);

  return (
    <ul className={`${classes.list} ${props.className || ''}`}>
      {props.items.map((item, index) => (
        <li key={index} className={classes.listItem}>{item}</li>
      ))}
    </ul>
  );
};

export { List };
