import { Box, makeStyles, Typography } from '@material-ui/core';
import { PortfolioValueCard, Terra2Holdings } from 'components/dashboard';
import { DepositAndWithdrawHistory } from 'components/DepositAndWithdraw/DepositAndWithdrawHistory';
import { unsupportedChain } from 'components/DepositAndWithdraw/UnsupportedChain';
import { WithdrawPercentSection } from 'components/DepositAndWithdraw/WithdrawPercentSection';
import { WithdrawStatus } from 'components/DepositAndWithdraw/WithdrawStatus';
import { WithdrawTokenSection } from 'components/DepositAndWithdraw/WithdrawTokenSection';
import RebalancingStatusComponent, { shouldShowRebalancingStatus } from 'components/RebalancingStatus';
import { Card } from 'elements';
import { DepositEvent } from 'models';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Redirect } from 'react-router-dom';
import { DashboardRoute, WithdrawRoute } from 'routes';
import { MasterCoinStatus } from 'services/coin-enum';
import { CoinsWithMasterCoins } from 'services/types/coin';
import { Coin } from 'services/types/wallet';
import { WorkItemName, WorkItemStatus } from 'services/types/workItem';
import { useStore as useChainStore } from 'store/zustand/Chain';
import { useStore as useCoinStore } from 'store/zustand/Coin';
import { useStore as useUserStore } from 'store/zustand/User';
import { useStore as useWalletStore } from 'store/zustand/Wallet';
import { useStore as useWorkItemStore } from 'store/zustand/WorkItem';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: 24,
    display: 'grid',
    gap: 16,
    gridTemplateColumns: '1fr 368px',
    gridTemplateRows: 'auto 1fr',
    [theme.breakpoints.down('sm')]: {
      gridTemplateColumns: '1fr',
      gridTemplateRows: 'auto auto 1fr',
      padding: 0,
    },
    flexGrow: 1,
  },
  section: {
    padding: 16,
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%',
    minHeight: '100%',
    overflow: 'hidden',
    boxShadow: '0px 4px 32px rgba(43, 43, 48, 0.04)',
    gap: 16,
    [theme.breakpoints.up('md')]: {
      gridRow: '1 / span 2',
      padding: '16px 32px',
    },
    [theme.breakpoints.down('sm')]: {
      borderRadius: 0,
      minHeight: 300,
      height: '100%',
    },
  },
  portfolioValueCard: {
    width: '100%',
    height: 144,
    boxShadow: '0px 4px 32px rgba(43, 43, 48, 0.04)',
    borderRadius: '8px',
    gridColumn: 2,
    [theme.breakpoints.down('sm')]: {
      gridColumn: 1,
      marginTop: 16,
      padding: '0 16px',
    },
  },
  terra2Wrapper: {
    width: '100%',
    borderTop: '1px solid #DEE3EF',
    padding: '24px 0 0',
    marginTop: 8,
    alignSelf: 'center',
    justifyContent: 'center',
    display: 'flex',
    '& > *': {
      maxWidth: 536,
    },
  },
  terra2: {
    boxShadow: 'none',
    borderRadius: 0,
    [theme.breakpoints.down('sm')]: {
      padding: 0,
    },
  },
  terra2Title: {
    textAlign: 'center',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: 16,
    marginTop: 4,
    '& h2': {
      fontSize: 22,
      fontWeight: 500,
      lineHeight: '32px',
      color: theme.palette.grey[800],
      [theme.breakpoints.down('sm')]: {
        lineHeight: '24px',
        fontSize: 16,
      },
    },
    '& h6': {
      fontSize: 12,
      fontWeight: 500,
      lineHeight: '20px',
      color: theme.palette.grey[700],
      textWrap: 'balance',
    },
  },
  history: {
    gridColumn: 2,
    [theme.breakpoints.down('sm')]: {
      gridColumn: 1,
    },
  },
  tabs: {
    alignSelf: 'stretch',
    borderBottom: `1px solid ${theme.palette.grey[200]}`,
    gap: 20,
    display: 'flex',
    [theme.breakpoints.down('xs')]: {
      justifyContent: 'center',
    },
  },
  tab: {
    transition: 'all 0.2s',
    fontSize: 16,
    lineHeight: '24px',
    fontWeight: 500,
    color: theme.palette.grey[600],
    cursor: 'pointer',
    position: 'relative',
    '&:hover': {
      color: theme.palette.grey[800],
    },
    paddingBottom: 8,
    '&::before': {
      transition: 'all 0.2s',
      content: '" "',
      zIndex: 0,
      position: 'absolute',
      bottom: 0,
      left: 0,
      background: theme.palette.green[600],
      width: '100%',
      height: 4,
      borderRadius: '4px 4px 0 0',
      opacity: 0,
    },
    [theme.breakpoints.down('xs')]: {
      width: '100%',
      textAlign: 'center',
    },
  },
  selected: {
    color: theme.palette.grey[800],
    '&::before': {
      opacity: 1,
    },
  },
}));

const Withdraw = () => {
  const classes = useStyles();

  const [
    readyWallet, isDefi, rebalancingStatus, fetchMinMaxPerf, fetchRebalancingStatus,
    walletBalances, walletsTs,
  ] = useWalletStore((state) => [
    state.readyWallet, state.isDefi, state.rebalancingStatus, state.fetchMinMaxPerf, state.fetchRebalancingStatus,
    state.walletBalances,
    state.walletsTs,
  ]);
  const { user, fetchUser } = useUserStore();
  const [fetchActiveWalletBalance, fetchDepositEvents] = useWalletStore((state) => [
    state.fetchLatestWalletBalances, state.fetchDepositEvents,
  ]);
  const [fetchChains] = useChainStore((state) => [
    state.fetchChains,
  ]);
  const [coins, fetchCoins] = useCoinStore((state) => [state.coins, state.fetchCoins]);
  const [workItems, fetchWorkItems] = useWorkItemStore((state) => [state.workItems, state.fetchWorkItems]);

  const [loading, setLoading] = useState(true);
  const [latestWithdrawal, setLatestWithdrawal] = useState<DepositEvent>();
  const [portfolioValue, setPortfolioValue] = useState<number | null>(null);
  const [lunaCoin, setLunaCoin] = useState<Coin | null>(null);
  const [withdrawableCoins, setWithdrawableCoins] = useState<CoinsWithMasterCoins>({});
  const [selectedTab, setSelectedTab] = useState('token');

  const withdrawWorkItem = useMemo(() => {
    if (!workItems || workItems.length === 0) return undefined;
    return workItems.find(
      (item) => (item.name === WorkItemName.Withdrawal || item.name === WorkItemName.WithdrawalToken)
        && (item.status === WorkItemStatus.InProgress
          || item.status === WorkItemStatus.Pending
          || item.status === WorkItemStatus.WaitingForApproval),
    );
  }, [workItems]);

  const fetchWithdrawalEvents = useCallback(async () => {
    const events = (await fetchDepositEvents(false))
      .map((e) => ({
        ...e,
        price: e.coins.reduce((total, coin) => total + (coin.price ?? 0), 0),
        ts: new Date(e.ts).getTime(),
      } as DepositEvent))
      .filter((e) => e.isConfirmed && e.price && e.price > 0)
      .sort((a, b) => Number(b.ts) - Number(a.ts));
    if (events.length) setLatestWithdrawal(events[0]);
  }, []);

  const fetchPortfolioValue = useCallback(async () => {
    try {
      const { maxPerf } = await fetchMinMaxPerf(false) ?? {};
      const luna = maxPerf?.point?.coins.find((c: Coin) => c.name === 'LUNA') ?? null;
      const freeValue = maxPerf?.point?.coins.reduce((p: number, c: Coin) => p + c.price, 0) ?? 0;

      setLunaCoin(luna);
      setPortfolioValue(freeValue);
      // eslint-disable-next-line no-empty
    } catch {
    }
  }, []);

  React.useEffect(() => {
    const fetchedWithdrawableCoins = Object.fromEntries(new Map(Object.entries(coins)
      .filter(([, coinInfo]) => !coinInfo.protocolId
        && ![...unsupportedChain, 'bnbbeacon'].includes(coinInfo.chainId)
        && coinInfo.masterCoin.status !== MasterCoinStatus.UiDisabled)));
    setWithdrawableCoins(fetchedWithdrawableCoins);
  }, [coins]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        await Promise.all([
          fetchUser(false),
          fetchRebalancingStatus(),
          fetchWithdrawalEvents(),
          fetchPortfolioValue(),
          fetchCoins(),
          fetchActiveWalletBalance(),
          fetchChains(),
          fetchWorkItems(false),
        ]);
        // eslint-disable-next-line no-empty
      } catch {
      }
      setLoading(false);
    };

    fetchData();
    const interval = setInterval(fetchData, 60 * 1000);
    return () => clearInterval(interval);
  }, [readyWallet]);

  const Terra2Section = useCallback(() => (
    <Box className={classes.terra2Wrapper}>
      <Terra2Holdings
        className={classes.terra2}
        showLearnMore
        price={lunaCoin?.price}
        lockedPrice={lunaCoin?.lockedPrice}
        lockedQuantity={lunaCoin?.lockedQuantity}
        quantity={lunaCoin?.quantity}
        title={(
          <Box className={classes.terra2Title}>
            <Typography variant="h2">Want to withdraw your LUNA?</Typography>
            <Typography variant="h6">
              Please note: Due to your LUNA holdings, your actual portfolio value will be higher
              than the available amount you can withdraw on this page. To withdraw your LUNA, please contact us.
            </Typography>
          </Box>
        )}
      />
    </Box>
  ), [lunaCoin]);

  if (readyWallet && !isDefi) return <Redirect to={DashboardRoute} />;

  return (
    <Box className={classes.root}>
      <Box className={classes.portfolioValueCard}>
        <PortfolioValueCard
          value={walletBalances ?? 0}
          disableButtons
          lastUpdate={walletsTs}
        />
      </Box>
      <Card className={classes.section} isLoading={loading}>
        {withdrawWorkItem ? (
          <WithdrawStatus coins={withdrawableCoins} event={latestWithdrawal} workItem={withdrawWorkItem} />
        ) : shouldShowRebalancingStatus(rebalancingStatus) && rebalancingStatus !== 'reporting-address' ? (
          <RebalancingStatusComponent status={rebalancingStatus} />
        ) : user?.isReadOnly ? (
          <Typography variant="h4" align="center" style={{ margin: 'auto' }}>
            Please send an email to <a href="mailto:support@coinbag.finance">support@coinbag.finance</a> to withdraw.
          </Typography>
        ) : (
          <>
            {shouldShowRebalancingStatus(rebalancingStatus) && rebalancingStatus === 'reporting-address' && (
              <RebalancingStatusComponent status={rebalancingStatus} />
            )}
            <Box className={classes.tabs}>
              <Box
                className={`${classes.tab} ${selectedTab === 'token' ? classes.selected : ''}`}
                onClick={() => setSelectedTab('token')}
              >
                Token based
              </Box>
              {/* <Box */}
              {/*  className={`${classes.tab} ${selectedTab === 'percentage' ? classes.selected : ''}`} */}
              {/*  onClick={() => setSelectedTab('percentage')} */}
              {/* > */}
              {/*  Percentage based */}
              {/* </Box> */}
            </Box>
            {selectedTab === 'token' ? (
              <WithdrawTokenSection
                loading={loading}
                terra2Holding={<Terra2Section />}
              />
            ) : (
              <WithdrawPercentSection
                withdrawableCoins={withdrawableCoins}
                portfolioValue={portfolioValue ?? 0}
                luna={lunaCoin}
                terra2Holding={<Terra2Section />}
              />
            )}
          </>
        )}
      </Card>
      <Box className={classes.history}>
        <DepositAndWithdrawHistory coinsWithMasterCoins={coins} withdraw />
      </Box>
    </Box>
  );
};

Withdraw.routePath = WithdrawRoute;
Withdraw.loginRequired = true;
Withdraw.redirect = 'withdrawalRedirect';

export default Withdraw;
