import { Avatar, Box, createStyles, makeStyles } from '@material-ui/core';
import { WalletRow } from 'components/dashboard/MultiWallets/WalletRow';
import { RebalancingMethods } from 'constants/rebalancing';
import { Typography } from 'elements';
import React from 'react';
// eslint-disable-next-line import/extensions
import { RebalanceConfigMode } from 'services/types/wallet-enum';
import { SetRebalancingModeParameter } from 'services/types/workItemParameter';
import { useStore as useUserStore } from 'store/zustand/User';
import { useStore as useWalletStore } from 'store/zustand/Wallet';

const useStyles = makeStyles(() => createStyles({
  root: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    gap: 16,
  },
  icon: {
    flexShrink: 0,
    width: 48,
    height: 48,
    borderRadius: 4,
  },
}));

const SetRebalancingModeDetail = (props: SetRebalancingModeParameter) => {
  const classes = useStyles();

  const [wallet] = useWalletStore((state) => [state.wallet]);
  const [user] = useUserStore((state) => [state.user]);

  const selectedWalletInfo = React.useMemo(
    () => (user?.walletInfo.find((w) => w.walletId === props.walletId)), [user, wallet],
  );

  const mode: RebalanceConfigMode = React.useMemo(() => {
    switch (props.rebalancingMode) {
      case 'dynamic':
      case 'dynamic-deposit':
        return RebalanceConfigMode.dynamic;
      case 'static':
      case 'static-deposit':
        return RebalanceConfigMode.static;
      case 'manual':
      case 'manual-deposit':
      default:
        return RebalanceConfigMode.manual;
    }
  }, [props.rebalancingMode]);

  return (
    <>
      <Box className={classes.root}>
        <Avatar
          className={classes.icon}
          src={RebalancingMethods[mode].icon}
        />
        <Box display="flex" flexDirection="column">
          <Typography variant="h4" paletteColor={700}>
            {RebalancingMethods[mode].name}
          </Typography>
          <Typography variant="h6" paletteColor={800}>
            auto rebalance when deposit: <b>{props.autoRebalanceFeat ? 'on' : 'off'}</b>
          </Typography>
        </Box>
      </Box>
      {selectedWalletInfo && (
        <Box display="flex" alignItems="center" gridGap="12px" justifyContent="center">
          <Typography variant="h4" paletteColor={800} align="center">for wallet:</Typography>
          <WalletRow walletInfo={selectedWalletInfo} />
        </Box>
      )}
    </>
  );
};

export default SetRebalancingModeDetail;
