import axios, { AxiosResponse } from 'axios';

import Headers from './headers';
import { ApprovalToken } from './types/approval-token';
import { PermissionApprover, PermissionCategory } from './types/role';

const API_URL = '/api/role/';

const invite = (firstName: string, lastName: string, email: string): Promise<string> => axios.post(`${API_URL}invite`,
  { firstName, lastName, email },
  { headers: { ...Headers.def(), ...Headers.auth() } })
  .then((response: AxiosResponse<string>) => response.data);

const getAllPermissionsWithApprovers = (): Promise<PermissionCategory[]> => axios.get(
  `${API_URL}getAllPermissionsWithApprovers`,
  { headers: { ...Headers.def(), ...Headers.auth() } },
)
  .then((response: AxiosResponse<PermissionCategory[]>) => response.data);

const getPermissionApproversByPermissionId = (permissionId: number): Promise<PermissionApprover> => axios.get(
  `${API_URL}getPermissionApproversByPermissionId/${permissionId}`,
  { headers: { ...Headers.def(), ...Headers.auth() } },
)
  .then((response: AxiosResponse<PermissionApprover>) => response.data);

const getPermissionApproversByPermissionTag = (permissionTag: string): Promise<PermissionApprover> => axios.get(
  `${API_URL}getPermissionApproversByPermissionTag/${permissionTag}`,
  { headers: { ...Headers.def(), ...Headers.auth() } },
)
  .then((response: AxiosResponse<PermissionApprover>) => response.data);

const getRequiredApproversByPermissionId = (permissionId: number): Promise<number> => axios.get(
  `${API_URL}getRequiredApproversByPermissionId/${permissionId}`,
  { headers: { ...Headers.def(), ...Headers.auth() } },
)
  .then((response: AxiosResponse<number>) => response.data);

const getRequiredApproversByPermissionTag = (permissionTag: string): Promise<number> => axios.get(
  `${API_URL}getRequiredApproversByPermissionTag/${permissionTag}`,
  { headers: { ...Headers.def(), ...Headers.auth() } },
)
  .then((response: AxiosResponse<number>) => response.data);

const addPermissionApprover = (permissionId: number, userId: number): Promise<number> => axios.post(
  `${API_URL}addPermissionApprover`,
  { permissionId, userId },
  { headers: { ...Headers.def(), ...Headers.auth() } },
).then((response: AxiosResponse<number>) => response.data);

const removePermissionApprover = (permissionId: number, userId: number): Promise<number> => axios.post(
  `${API_URL}removePermissionApprover`,
  { permissionId, userId },
  { headers: { ...Headers.def(), ...Headers.auth() } },
).then((response: AxiosResponse<number>) => response.data);

const updatePermissionApprovers = (permissionId: number, userIds: number[]): Promise<number> => axios.post(
  `${API_URL}updatePermissionApprovers`,
  { permissionId, userIds },
  { headers: { ...Headers.def(), ...Headers.auth() } },
).then((response: AxiosResponse<number>) => response.data);

const updateRequiredApprovers = (permissionId: number, requiredApprovers: number): Promise<number> => axios.post(
  `${API_URL}updateRequiredApprovers`,
  { permissionId, requiredApprovers },
  { headers: { ...Headers.def(), ...Headers.auth() } },
).then((response: AxiosResponse<number>) => response.data);

const removeUserFromInstitution = (userId: number): Promise<number> => axios.post(
  `${API_URL}removeUserFromInstitution`,
  { userId },
  { headers: { ...Headers.def(), ...Headers.auth() } },
).then((response: AxiosResponse<number>) => response.data);

const validateApprovalToken = (token: string): Promise<ApprovalToken> => axios.post(
  `${API_URL}validateToken`,
  { token },
  { headers: { ...Headers.def(), ...Headers.auth() } },
).then((response: AxiosResponse<ApprovalToken>) => response.data);

export default {
  invite,
  getAllPermissionsWithApprovers,
  getPermissionApproversByPermissionId,
  getPermissionApproversByPermissionTag,
  getRequiredApproversByPermissionId,
  getRequiredApproversByPermissionTag,
  addPermissionApprover,
  removePermissionApprover,
  updatePermissionApprovers,
  updateRequiredApprovers,
  removeUserFromInstitution,
  validateApprovalToken,
};
