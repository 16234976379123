import { Box, makeStyles, Theme, Typography } from '@material-ui/core';
import { Button } from 'elements';
import React, { FC } from 'react';

type CustodianItemProps = {
  title: string;
  subtitle: string;
  icons: string[];
  iconCircle?: boolean;
  actionText: string;
  actionColor: 'green' | 'blue' | 'purple';
  onAction: () => void;
  comingSoon?: boolean;
};

const useStyles = makeStyles<Theme, Partial<CustodianItemProps>>((theme) => ({
  root: {
    display: 'flex',
    alignItems: 'flex-start',
    columnGap: 8,
    padding: 12,
    borderRadius: 8,
    border: '1px solid',
    borderColor: theme.palette.grey[200],
    boxShadow: '0px 4px 32px 0px rgba(58, 60, 77, 0.04)',
  },
  icons: {
    display: 'inline-flex',
    flexDirection: 'row-reverse',
    userDrag: 'none',
    userSelect: 'none',
  },
  icon: {
    width: 36,
    height: 36,
    background: 'white',
    boxShadow: '0px 4px 12px 0px rgba(110, 123, 161, 0.12)',
    borderRadius: ({ iconCircle }) => (iconCircle ? 18 : 8),

    '&:not(:last-child)': {
      marginLeft: -16,
    },
  },
  titleColumn: {
    display: 'flex',
    flexGrow: 1,
    flexDirection: 'column',
    alignItems: 'start',
  },
  title: {
    fontSize: 12,
    lineHeight: '20px',
  },
  subtitle: {
    color: theme.palette.grey[400],
    fontSize: 12,
    lineHeight: '16px',
  },
  button: {
    width: 80,
    color: 'white',
    fontSize: 14,
    textTransform: 'none',
  },
}));

const CustodianItem: FC<CustodianItemProps> = ({
  title,
  subtitle,
  icons,
  iconCircle,
  actionText,
  actionColor,
  onAction,
  comingSoon,
}) => {
  const classes = useStyles({ iconCircle });
  return (
    <Box className={classes.root}>
      <Box className={classes.icons}>
        {icons.reverse().map((icon, i) => (
          <img key={i} className={classes.icon} alt="" src={icon} />
        ))}
      </Box>
      <Box className={classes.titleColumn}>
        <Typography className={classes.title}>{title}</Typography>
        <Typography className={classes.subtitle}>{subtitle}</Typography>
      </Box>
      {comingSoon ? (
        <Typography className={classes.subtitle}>Coming soon</Typography>
      ) : (
        <Button color={actionColor} size="md" className={classes.button} onClick={onAction}>
          {actionText}
        </Button>
      )}
    </Box>
  );
};

CustodianItem.displayName = 'CustodianItem';
export { CustodianItem };
