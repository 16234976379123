import { Box, makeStyles, Typography } from '@material-ui/core';
import { Button } from 'elements';
import React from 'react';
import SVG from 'react-inlinesvg';
import { Link } from 'react-router-dom';
import { NotFoundRoute } from 'routes';

const useStyles = makeStyles((theme) => ({
  root: {
    position: 'relative',
    overflowX: 'clip',
    userSelect: 'none',

    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',

    minHeight: 'calc(100vh - 80px)',
    background: 'white',
  },

  gradient: {
    position: 'absolute',
    width: '80vw',
    height: '80vh',

    opacity: 0.3,
    background: 'radial-gradient(48.42% 48.42% at 50% 50%, rgba(46, 255, 218, 0.63) 0%, rgba(235, 255, 252, 0) 100%)',
    filter: 'blur(78)',
  },
  gradientTop: {
    top: 0,
    left: 0,
    transform: 'translateX(-50%) translateY(-50%)',
  },
  gradientBottom: {
    bottom: 0,
    right: 0,
    transform: 'translateX(50%) translateY(50%)',
  },

  coin: {
    position: 'absolute',

    width: 32,
    height: 32,
    objectFit: 'contain',
    opacity: 0.6,

    borderRadius: 9999,
    filter: 'drop-shadow(0px 4px 16px rgba(110, 123, 162, 0.1))',
  },
  coin1: {
    top: '40%',
    left: -12,
    width: 44,
    height: 44,
    animation: '3s ease-in-out infinite $float',
  },
  coin2: {
    top: '15%',
    left: '10%',
    width: 52,
    height: 52,
    [theme.breakpoints.down('sm')]: {
      top: '18%',
      left: '7%',
    },
  },
  coin3: {
    top: '30%',
    left: '20%',
    [theme.breakpoints.down('xs')]: {
      display: 'none',
    },
  },
  coin4: {
    top: '10%',
    left: '40%',
    height: 112,
    width: 112,
    animation: '5s ease-in-out infinite $float',
    [theme.breakpoints.down('sm')]: {
      width: 84,
      height: 84,
      top: 0,
      left: '32%',
    },
  },
  coin5: {
    top: '30%',
    left: '60%',
    animation: '2s ease-in-out infinite $float',
  },
  coin6: {
    top: '10%',
    left: '70%',
    width: 44,
    height: 44,
    animation: '3s ease-in-out 1s infinite $float',
  },
  coin7: {
    top: '40%',
    left: '80%',
    animation: '3s ease-in-out 1s infinite $float',
    [theme.breakpoints.down('xs')]: {
      display: 'none',
    },
  },
  coin8: {
    top: '20%',
    left: '90%',
    width: 64,
    height: 64,
    animation: '5s ease-in-out 4s infinite $float',
  },
  coin9: {
    bottom: '25%',
    right: '90%',
  },
  coin10: {
    bottom: '40%',
    right: '80%',
    width: 52,
    height: 52,
    [theme.breakpoints.down('xs')]: {
      display: 'none',
    },
  },
  coin11: {
    bottom: '15%',
    right: '70%',
    width: 64,
    height: 64,
    animation: '5s ease-in-out 2s infinite $float',
    [theme.breakpoints.down('sm')]: {
      bottom: '8%',
    },
  },
  coin12: {
    bottom: '30%',
    right: '50%',
    width: 44,
    height: 44,
    animation: '3s ease-in-out infinite $float',
  },
  coin13: {
    bottom: '10%',
    right: '40%',
    width: 44,
    height: 44,
    animation: '3s ease-in-out 2s infinite $float',
    [theme.breakpoints.down('sm')]: {
      bottom: '4%',
    },
  },
  coin14: {
    bottom: '25%',
    right: '20%',
    width: 62,
    height: 62,
    [theme.breakpoints.down('sm')]: {
      bottom: '16%',
    },
  },
  coin15: {
    bottom: '15%',
    right: '15%',
    width: 52,
    height: 52,
    animation: '3s ease-in-out infinite $float',
    [theme.breakpoints.down('xs')]: {
      display: 'none',
    },
  },
  coin16: {
    bottom: '10%',
    right: -10,
    animation: '3s ease-in-out infinite $float',
  },

  content: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 1,
    padding: 16,
    width: '100%',
    maxWidth: 1080,

    color: theme.palette.grey[700],

    '& h1': {
      fontSize: 128,
      fontWeight: 800,
      lineHeight: '1em',

      [theme.breakpoints.up('md')]: {
        fontSize: 240,
        fontWeight: 700,
      },
    },

    '& h2': {
      fontSize: 20,
      fontWeight: 500,
      lineHeight: '1em',
    },
  },

  buttons: {
    display: 'grid',
    gridTemplateColumns: '1fr',
    rowGap: 12,

    marginTop: 16,
    width: '80%',

    [theme.breakpoints.up('md')]: {
      gridTemplateColumns: '1fr 1fr',
      columnGap: 16,

      marginTop: 24,
      width: '100%',
      maxWidth: 400,
    },
  },
  button: { padding: '12px 16px', width: '100%' },
  link: { width: '100%' },

  '@keyframes float': {
    '0%': { transform: 'translateY(0)' },
    '50%': { transform: 'translateY(-10%)' },
    '100%': { transform: 'translateY(0)' },
  },
}));

const NotFound = () => {
  const classes = useStyles();

  return (
    <Box className={classes.root}>
      <Box className={`${classes.gradient} ${classes.gradientTop}`} />
      <Box className={`${classes.gradient} ${classes.gradientBottom}`} />

      {['aic', 'aion', 'bch', 'btc', 'etc', 'hippo', 'fil', 'gusd', 'hcc', 'heco', 'eth', 'tmcn', 'udoo',
        'usdt', 'xra', 'yfbeta',
      ].map((coin, index) => (
        <SVG
          key={index}
          className={`${classes.coin} ${(classes as any)[`coin${index + 1}`]}`}
          src={`/icons/coins/${coin}.svg`}
        />
      ))}

      <Box className={classes.content}>
        <Typography variant="h1">404</Typography>
        <Typography variant="h2">page not found</Typography>

        <Box className={classes.buttons}>
          <Link className={classes.link} to="/">
            <Button variant="primary" className={classes.button}>Go Back</Button>
          </Link>
          <a className={classes.link} href="https://coinbag.tawk.help/" target="_blank" rel="noopener noreferrer">
            <Button variant="outline" className={classes.button}>Visit Help Center</Button>
          </a>
        </Box>
      </Box>
    </Box>
  );
};

NotFound.displayName = 'NotFound';
NotFound.routePath = NotFoundRoute;
NotFound.loginRequired = false;

export default NotFound;
