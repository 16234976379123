import { createStyles, makeStyles, Theme } from '@material-ui/core';
import * as React from 'react';
import SVG from 'react-inlinesvg';

import { BundleCoin } from '../Bundle/BundleCard';

const greyColor = '#DEE3EF';
const otherCoinPercentage = 0.06;

const useStyles = makeStyles<Theme, { small: boolean, coins: SortedCoin[][] }>(() => createStyles({
  outerProgress: {
    height: '10px',
    width: '100%',
    margin: ({ small }) => (small ? '5px 0 40px 0' : '5px 0 50px 0'),
    textAlign: 'center',
    borderRadius: '10px',
    position: 'relative',
    backgroundColor: greyColor,
  },
  progress: {
    height: '10px',
    width: '100%',
    marginBottom: ({ small }) => (small ? 40 : 50),
    textAlign: 'center',
    borderRadius: '10px',
    position: 'relative',
    backgroundImage: ({ coins }) => linearGradientImageGenerator(coins),
    backgroundSize: ({ coins }) => linearGradientSizeGenerator([...coins[1], ...coins[0]]),
    backgroundRepeat: 'no-repeat',
    transition: '0.3s',
  },
  progressDiv: {
    top: '-7px',
    position: 'absolute',
    textAlign: 'left',
    transition: '0.3s',
    '&#last': {
      direction: 'rtl',
      '& div': {
        textAlign: 'right',
      },
    },
    '& svg': {
      textAlign: 'left',
      direction: 'ltr',
    },
  },
  progressImg: {
    width: ({ small }) => (small ? '14px' : '20px'),
    height: ({ small }) => (small ? '14px' : '20px'),
    borderRadius: '10px',
    border: '1px solid #FFFFFF',
    background: 'white',
    filter: 'drop-shadow(0px 2px 4px rgba(144, 156, 189, 0.32))',
  },
  symbol: {
    fontWeight: 500,
    fontSize: ({ small }) => (small ? '10px' : '12px'),
    lineHeight: '20px',
    color: '#38425E',
  },
  symbolOther: {
    marginTop: '6px',
    fontWeight: 400,
    fontSize: '10px',
    lineHeight: '20px',
    textAlign: 'end',
    color: '#6E7BA1',
    whiteSpace: 'nowrap',
  },
  percentage: {
    fontWeight: 500,
    fontSize: ({ small }) => (small ? '10px' : '12px'),
    lineHeight: '20px',
    color: '#6E7BA1',
  },
  percentageOther: {
    fontWeight: 500,
    fontSize: ({ small }) => (small ? '10px' : '12px'),
    lineHeight: '20px',
    textAlign: 'end',
    color: '#909CBD',
  },
  otherIcon: {
    width: '10px',
    height: '12px',
    background: '#a9a9a9',
    marginTop: '6px',
    border: '1px solid #FFFFFF',
    borderTopLeftRadius: '6px',
    borderBottomLeftRadius: '6px',
    borderRight: 0,
    transition: '0.3s',
  },
  empty: {
    paddingTop: '17px',
    textAlign: 'left',
  },
}));

interface CoinBarProps {
  coins: BundleCoin[]
  small?: boolean
}

type SortedCoin = {
  nextPercentage: number,
  accPercentage: number,
  percentage: number,
  name: string,
  icon: string,
  color: string
};

const linearGradientImageGenerator = (coins: SortedCoin[][]) => {
  const [otherCoins, displayCoins] = coins;
  let formattedOtherCoins = otherCoins;
  if (otherCoins.length > 1) {
    formattedOtherCoins = otherCoins.map((c) => ({ ...c, color: '#a9a9a9' }));
  }

  const builder = [...displayCoins, ...formattedOtherCoins]
    .map(
      (c) => `linear-gradient(${c.color},${c.color})`,
    ).join(',');
  return `${builder}`;
};

const linearGradientSizeGenerator = (coins: SortedCoin[]) => {
  const builder = coins
    .map(
      (c) => `${percentageFormatter(c.nextPercentage, 1)} 100%`,
    ).join(',');
  return `${builder}`;
};

function getSortedCoins(coins: BundleCoin[]) {
  return coins
    .sort((a, b) => b.percentage - a.percentage)
    .map(((acc) => (c) => {
      const currentAcc = acc;
      // eslint-disable-next-line no-param-reassign
      acc += c.percentage;
      return { accPercentage: currentAcc, nextPercentage: acc, ...c };
    })(0));
}

export const CoinBar = ({ coins, small = false }: CoinBarProps) => {
  const sortedCoins: SortedCoin[] = getSortedCoins(coins).filter((c) => c.percentage > 0);

  const [otherCoins, displayCoins] = sortedCoins.reduce<[SortedCoin[], SortedCoin[]]>((result, sc) => {
    result[(sc.percentage < otherCoinPercentage || result[1].length >= 5) ? 0 : 1].push(sc);
    return result;
  }, [[], []]);

  if (otherCoins.length === 1) {
    displayCoins.push(otherCoins[0]);
  }

  const otherCoin = otherCoins.reduce((a, b) => a + b.percentage, 0);
  const otherCoinAcc = otherCoins.reduce((a, b) => Math.min(a, b.accPercentage), Number.MAX_VALUE);

  const classes = useStyles({ small, coins: [otherCoins, displayCoins] });
  return (
    <div className={classes.outerProgress}>
      <div className={classes.progress}>
        {displayCoins.map((sc, index) => (
          <div
            key={sc.name}
            className={classes.progressDiv}
            id={(index === displayCoins.length - 1 && sc.percentage < 0.05) ? 'last' : ''}
            style={{
              left: `calc(${percentageFormatter(sc.accPercentage, 1)} - 3px)`,
              right: (index === displayCoins.length - 1 && sc.percentage < 0.05) ? percentageFormatter((1 - (sc.accPercentage + sc.percentage)), 1) : undefined,
            }}
          >
            <SVG
              src={sc.icon}
              className={classes.progressImg}
            />
            <div className={classes.symbol}>{sc.name}</div>
            <div className={classes.percentage}>{percentageFormatter(sc.percentage, 1)}</div>
          </div>
        ))}
        {otherCoins.length > 1
          && (
            <div
              key="other"
              className={classes.progressDiv}
              style={{
                left: `calc(${percentageFormatter(otherCoinAcc, 1)} - 5px)`,
                right: percentageFormatter((1 - (otherCoinAcc + otherCoin)), 1),
              }}
            >
              <div className={classes.otherIcon} />
              <div className={classes.symbolOther}>Others {!small ? `+${otherCoins.length}` : ''}</div>
              <div className={classes.percentageOther}>{percentageFormatter(otherCoin, 1)}</div>
            </div>
          )}
        {displayCoins.length === 0 && otherCoins.length === 0 && (
          <div className={classes.empty}>
            <div className={classes.symbol}>Empty</div>
            <div className={classes.percentage}>100%</div>
          </div>
        )}
      </div>
    </div>
  );
};

const percentageFormatter = (percentage: number, digits: number = 0) => `${(percentage * 100).toFixed(digits)}%`;
