import { Box, createStyles, makeStyles, Typography } from '@material-ui/core';
import { Switch } from 'elements/Switch';
import React, { useMemo } from 'react';
import { RebalanceConfigMode } from 'services/types/wallet-enum';
import { useStore as useWalletStore } from 'store/zustand/Wallet';
import { breakpoints } from 'theme/base';

import { useStore as useFeaturesStore } from '../../../store/zustand/Feature';
import DepositMoreBanner from './DepositMoreBanner';
import { RebalanceOption, RebalanceOptionProps } from './RebalanceOption';

const useStyles = makeStyles((theme) => createStyles({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: 12,
  },
  title: {
    color: theme.palette.grey['800'],
    textAlign: 'center',
    paddingTop: 8,
    fontSize: 28,
    lineHeight: '44px',
    fontWeight: 500,
    [theme.breakpoints.down('sm')]: {
      fontSize: 16,
      lineHeight: '24px',
      textAlign: 'left',
      alignSelf: 'flex-start',
    },
  },
  subTitle: {
    color: theme.palette.grey['700'],
    textAlign: 'center',
    maxWidth: 800,
    [theme.breakpoints.down('sm')]: {
      fontSize: 12,
      lineHeight: '20px',
      alignSelf: 'flex-start',
      textAlign: 'left',
    },
  },
  autoRebalanceToggle: {
    marginTop: 4,
    display: 'flex',
    flexWrap: 'nowrap',
    alignItems: 'center',
    gap: '8px',
    fontSize: 14,
    lineHeight: '24px',
    color: theme.palette.grey['600'],
    [theme.breakpoints.down('sm')]: {
      marginBottom: 4,
    },
  },
  wrapper: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr 1fr',
    columnGap: 24,
    rowGap: 20,
    [breakpoints.down('sm')]: {
      gridTemplateColumns: '1fr',
    },
  },
  banner: {
    gridColumn: '1 / span 3',
    width: '100%',
    [breakpoints.down('sm')]: {
      gridColumn: 'unset',
      gridRow: 2,
    },
  },
}));

interface RebalancingMethodProps {
  isDeFi?: boolean;
  // eslint-disable-next-line no-unused-vars
  onChanged?: (method: RebalanceConfigMode) => void;
  autoRebalance?: boolean;
  // eslint-disable-next-line no-unused-vars
  setAutoRebalance: (isAuto: boolean) => void;
  nextRebalance?: string;
}

const RebalancingMethod = ({
  isDeFi = false,
  onChanged,
  autoRebalance,
  setAutoRebalance,
  nextRebalance,
}: RebalancingMethodProps) => {
  const classes = useStyles();

  const [features, fetchFeatures] = useFeaturesStore((state) => ([state.features, state.fetchFeatures]));

  const [selectedMethod, setSelectedMethod] = React.useState<RebalanceConfigMode | undefined>(undefined);
  const [wallet] = useWalletStore((state) => [state.wallet]);
  const onSelect = (mode: RebalanceConfigMode) => {
    setSelectedMethod(mode);
    if (onChanged) onChanged(mode);
  };

  React.useEffect(() => {
    fetchFeatures();
  }, []);

  React.useEffect(() => {
    if (wallet?.rebalanceConfig?.mode) setSelectedMethod(wallet.rebalanceConfig.mode);
  }, [wallet]);

  React.useEffect(() => {
    if (window.Tawk_API && window.Tawk_API.hideWidget) window.Tawk_API.hideWidget();
    return function cleanup() {
      if (window.Tawk_API && window.Tawk_API.showWidget) window.Tawk_API.showWidget();
    };
  }, []);

  const methods: RebalanceOptionProps[] = useMemo(() => [
    {
      name: 'Manual',
      icon: '/icons/rebalancing/ic_manual.png',
      bestFor: [
        <><em>{`<${(3000).toLocaleString()} USD`}</em> portfolio value</>,
        <>Rebalance when you want</>,
      ],
      // eslint-disable-next-line max-len
      description: 'Manual rebalancing allows you to choose when your portfolio gets rebalanced. This will require you to trigger rebalancing from your portfolio or will occur when you change your risk profile.',
      isSelected: selectedMethod === RebalanceConfigMode.manual,
      setSelected: () => onSelect(RebalanceConfigMode.manual),
      color: 'blue',
      isEnabled: features ? features.manualRebalance : false,
    },
    {
      name: 'Static',
      icon: '/icons/rebalancing/ic_static.png',
      bestFor: [
        <><em>{`>${(3000).toLocaleString()} USD`}</em> portfolio value</>,
        <>Automated portfolio rebalancing</>,
        <>Time-based</>,
      ],
      // eslint-disable-next-line max-len
      description: 'Static rebalancing is time based and it is triggered approximately once a month. This ensures that your wallet is aligned with your target portfolio and enables you to take profits on that time cadence.',
      isSelected: selectedMethod === RebalanceConfigMode.static,
      setSelected: () => onSelect(RebalanceConfigMode.static),
      isEnabled: (features?.autoRebalance ?? false) && isDeFi,
      nextRebalance,
    },
    {
      name: 'Dynamic',
      icon: '/icons/rebalancing/ic_dynamic.png',
      bestFor: [
        <><em>{`>${(3000).toLocaleString()} USD`}</em> portfolio value</>,
        <>Automated portfolio rebalancing</>,
        <>Volatility-based</>,
      ],
      // eslint-disable-next-line max-len
      description: 'Dynamic rebalancing is reactive to events and gets triggered when your wallet substantially deviates from your target portfolio. This ensures that your wallet is promptly aligned with your target portfolio and enables profit taking during swings in volatile periods.',
      isSelected: selectedMethod === RebalanceConfigMode.dynamic,
      setSelected: () => onSelect(RebalanceConfigMode.dynamic),
      isEnabled: (features?.autoRebalance ?? false) && isDeFi,
    },
  ], [selectedMethod, onSelect, features]);

  return (
    <Box className={classes.root}>
      <Typography variant="h2" className={classes.title}>Manage your rebalancing settings</Typography>
      <Typography variant="h6" className={classes.subTitle}>Choose the interest earning methods that align with your goals. Coinbag will work to maximize interest based on your chosen methods.</Typography>
      <Typography className={classes.autoRebalanceToggle}>
        Auto rebalance if I deposit funds:
        <Switch
          color="default"
          checked={autoRebalance}
          onChange={(e) => setAutoRebalance(e.target.checked)}
        />
      </Typography>
      <Box className={classes.wrapper}>
        {
          (features !== undefined && !features.autoRebalance && (
            <Box className={classes.banner}>
              <DepositMoreBanner />
            </Box>
          ))
        }
        {methods.map((method) => (
          <RebalanceOption key={method.name} {...method} />
        ))}
      </Box>
    </Box>
  );
};

export default RebalancingMethod;
