import { createTheme } from '@material-ui/core';

import base from './base';

export const LightTheme = createTheme({
  ...base,
  overrides: {
    ...base?.overrides,
    MuiButton: {
      ...base?.overrides?.MuiButton,
      contained: {
        ...base?.overrides?.MuiButton?.contained,
        backgroundColor: '#10AC84',
        borderColor: '#10AC84',
        color: '#fff',
        '&:hover': {
          backgroundColor: '#327261',
          borderColor: '#327261',
          color: '#fff',
        },
        '&:disabled': {
          backgroundColor: '#327261',
          color: '#fff',
          boxShadow: 'none',
          opacity: '.65',
        },
        '@media (hover: none)': {
          '&:hover': {
            backgroundColor: '#08452D !important',
            borderColor: '#08452D',
            color: '#fff',
          },
        },
      },
      outlined: {
        ...base?.overrides?.MuiButton?.outlined,
        backgroundColor: '#fff',
        borderColor: '#327261',
        color: '#327261',
        '&:hover': {
          backgroundColor: '#327261',
          borderColor: '#327261',
          color: '#fff',
        },
        '&:disabled': {
          backgroundColor: '#fff',
          color: '#327261',
          boxShadow: 'none',
          opacity: '.65',
        },
        '@media (hover: none)': {
          '&:hover': {
            backgroundColor: '#327261',
            borderColor: '#327261',
            color: '#fff',
          },
        },
      },
    },
    MuiLink: {
      ...base?.overrides?.MuiLink,
      root: {
        ...base?.overrides?.MuiLink?.root,
        color: '#10AC84 !important',
        '&:hover': {
          color: '#327261 !important',
        },
      },
    },
    MuiSwitch: {
      track: {
        backgroundColor: '#C4C4C4',
      },
      switchBase: {
        '&$checked .MuiSwitch-thumb': {
          backgroundColor: '#10AC84',
        },
      },
    },
  },
  palette: {
    type: 'light',
    primary: {
      main: '#327261',
      light: '#61a18e',
      dark: '#004637',
    },
    secondary: {
      main: '#ffffff',
    },
    text: {
      primary: '#212A27',
      secondary: '#535bd4',
    },
  },
});
