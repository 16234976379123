import { Box, CircularProgress, createStyles, makeStyles } from '@material-ui/core';
import { WalletRow } from 'components/dashboard/MultiWallets/WalletRow';
import { Typography } from 'elements';
import React from 'react';
import { RebalancingParameter } from 'services/types/workItemParameter';
import { useStore as useUserStore } from 'store/zustand/User';
import { useStore as useWalletStore } from 'store/zustand/Wallet';

const useStyles = makeStyles((theme) => createStyles({
  estimate: {
    fontSize: 14,
    lineHeight: '24px',
    fontWeight: 500,
    color: theme.palette.grey[600],
    marginBottom: 8,
    textAlign: 'center',
  },
  fee: {
    fontSize: 16,
    lineHeight: '24px',
    fontWeight: 700,
    color: theme.palette.grey[600],
    paddingLeft: 8,
  },
  progress: {
    marginLeft: 8,
    marginBottom: -2,
  },
}));

const RebalanceDetail = (props: RebalancingParameter) => {
  const classes = useStyles();
  const [fetchEstimateRebalance, estimateRebalance, wallet] = useWalletStore((state) => [
    state.fetchEstimateRebalance,
    state.estimateRebalance,
    state.wallet,
  ]);
  const [user] = useUserStore((state) => [state.user]);

  const selectedWalletInfo = React.useMemo(
    () => (user?.walletInfo.find((w) => w.walletId === props.walletId)), [user, wallet],
  );

  React.useEffect(() => {
    fetchEstimateRebalance(props.walletId);
  }, []);

  return (
    <>
      <Typography className={classes.estimate} component="span">
        Estimated gas fees:
        {
          estimateRebalance != null
            ? <Typography component="span" className={classes.fee}>{estimateRebalance.toFixed(2)} USD</Typography>
            : <CircularProgress className={classes.progress} color="primary" size="1rem" />
        }
      </Typography>
      {selectedWalletInfo && (
        <Box display="flex" alignItems="center" gridGap="12px" justifyContent="center">
          <Typography variant="h4" paletteColor={800} align="center">for wallet:</Typography>
          <WalletRow walletInfo={selectedWalletInfo} />
        </Box>
      )}
    </>
  );
};

export default RebalanceDetail;
