import React from "react";
import { Navbar, Nav, Container, Row, Col } from 'react-bootstrap'
import ProfileReview from "../pages/desktop/ProfileReview";
import ChooseExchange from "../pages/responsive/ChooseExchange";
import ConnectExchange from "../pages/mobile/ConnectExchange";
import Welcome from "../pages/mobile/Welcome";
import { withRouter } from 'react-router-dom';
import RiskProfile from "../pages/mobile/RiskProfile";

class StepNava extends React.Component {
  constructor(props){
    super(props);
  }

  renderNavLink(item, i, isLast) {
    const visibility = isLast ? "hidden" : "visible";
    return (
      <Nav.Link key={i} href="#" className={this.props.active === i + 1 ? "active-step" : ""}>
        <Container>
          <Row className="mt-0">
            <Col xs={2} className="dot">{i + 1}</Col>
            <Col xs={10} style={{paddingLeft:"12px", paddingRight:"12px", visibility}}><hr className="step-line" /></Col>
          </Row>
          <Row className="mt-0">
            <Col className="p-0"><p>{item.title} <br />
              <span>{item.sub}</span>
            </p>
            </Col>
          </Row>
        </Container>
      </Nav.Link>
    )
  }

  mobile(steps) {
    return (<Container className="px-4">
    <Row>
      <Col>
        <Navbar className="navbar-step" style={{marginLeft: ((this.props.active-1) * -180).toString() + "px"}}>
          <Nav>
            {steps.map((item,i) => this.renderNavLink(item,i,i === steps.length-1))}
          </Nav>
        </Navbar>
      </Col>
    </Row>
  </Container>)
  }

  desktop(steps){
    const desktopSteps = [{
      title: "Welcome",
      sub: "",
      route: Welcome.routePath
    }].concat(steps)

    return(
      <div className="pt-5 mb-0">
        <ul id="desktop-stepnav">
          {desktopSteps.map((item,i) =>
            <li
              key = {i}
              className={("text-center pointer ") + (this.props.active >= i+1 ? "active" : "")}
              onClick={() => this.props.history.push(item.route)}>
                <div className="before">{i+1}</div>
                <p className="mb-0">{item.title}</p>
                <span className="p6">{item.sub}</span>
            </li>
          )}
        </ul>
      </div>
    )
  }

  render() {

    const steps = [
      {
        title: "Investment level",
        sub: "3 mins",
        route: RiskProfile.routePath
      },
      {
        title: "Choose Exchange",
        sub: "2 mins",
        route: ChooseExchange.routePath
      },
      {
        title: "Connect Exchange / Fund wallet",
        sub: "4 mins",
        route: ConnectExchange.routePath
      },
      {
        title: "Final Review",
        sub: "4 mins",
        route: ProfileReview.routePath
      }
    ]

    const isDesktop = window.matchMedia('(min-width: 768px)').matches

    if(isDesktop){
      return this.desktop(steps);
    }else{
      return this.mobile(steps);
    }
  }
}

export const StepNav = withRouter(StepNava)
