import { Box, makeStyles, MenuItem, Typography, withStyles } from '@material-ui/core';
import React from 'react';

import { Button, Checkbox, Dropdown } from '../../elements';

const Container = withStyles({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    padding: '16px 24px',
  },
})(Box);

const Row = withStyles({
  root: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-start',
    columnGap: 8,
    marginBottom: 8,
    width: '100%',
  },
})(Box);

const useStyles = makeStyles({
  dropdownFixedWidth: {
    width: 160,
  },
});

const DashboardElements = () => {
  const classes = useStyles();

  const dropdownContent = (
    <Box>
      <MenuItem>Hello</MenuItem>
      <MenuItem>Guten Tag</MenuItem>
    </Box>
  );

  return (
    <Container>
      <Typography variant="h1">Buttons</Typography>
      <Row>
        <Button size="sm">Primary Small</Button>
        <Button>Primary Medium</Button>
        <Button size="lg">Primary Large</Button>
        <Button disabled>Primary Disabled</Button>
      </Row>

      <Row>
        <Button variant="secondary" size="sm">Secondary Small</Button>
        <Button variant="secondary">Secondary Medium</Button>
        <Button variant="secondary" size="lg">Secondary Large</Button>
        <Button variant="secondary" disabled>Secondary Disabled</Button>
      </Row>

      <Row>
        <Button variant="tertiary" size="sm">Tertiary Small</Button>
        <Button variant="tertiary">Tertiary Medium</Button>
        <Button variant="tertiary" size="lg">Tertiary Large</Button>
        <Button variant="tertiary" disabled>Tertiary Disabled</Button>
      </Row>

      <hr />

      <Typography variant="h1">Dropdowns</Typography>
      <Row>
        <Dropdown title="No Icon">{dropdownContent}</Dropdown>

        <Dropdown anchorPosition="left" title="Left" iconSrc="/icons/dashboard/ic_flag.svg">
          {dropdownContent}
        </Dropdown>
        <Dropdown title="Center" iconSrc="/icons/dashboard/ic_flag.svg">
          {dropdownContent}
        </Dropdown>
        <Dropdown anchorPosition="right" title="Right" iconSrc="/icons/dashboard/ic_flag.svg">
          {dropdownContent}
        </Dropdown>

        <Dropdown
          className={classes.dropdownFixedWidth}
          anchorPosition="right"
          title="Fixed Width"
          iconSrc="/icons/dashboard/ic_flag.svg"
        >
          {dropdownContent}
        </Dropdown>
      </Row>

      <hr />

      <Typography variant="h1">Checkbox</Typography>
      <Checkbox />
    </Container>
  );
};

DashboardElements.routePath = '/dev/dashboard-elements';
DashboardElements.loginRequired = false;
export { DashboardElements };
