import { IconButton } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import MaterialTable from 'material-table';
import * as React from 'react';
import { Link } from 'react-router-dom';

import MuiTableIcons from '../../../components/MuiTableIcons';
import coinService from '../../../services/coin.service';
import { Coin } from '../../../services/types/coin';

const ViewCoins = () => {
  const [isLoading, setIsLoading] = React.useState(true);
  const [coins, setCoins] = React.useState<Coin[]>([]);

  React.useEffect(() => {
    setIsLoading(true);
    coinService.getCoins().then(coins => {
      setCoins(coins?.data);
      setIsLoading(false);
    });
  }, []);

  if (isLoading) {
    return <div style={{ margin: '40px auto', width: '100px' }}>Loading ...</div>;
  }

  return (
    <div style={{ maxWidth: '100%', padding: '10px' }}>
      {!coins.length && <Alert severity="info">Loading...</Alert>}
      <MaterialTable
        icons={(MuiTableIcons) as any}
        columns={[
          { title: 'Id', field: 'coinId', type: 'numeric', editable: 'never', defaultFilter: new URLSearchParams(window.location.search).get('id') },
          { title: 'Name', field: 'name', editable: 'never' },
          { title: 'Symbol', field: 'symbol', editable: 'never' },
          { title: 'Chain', field: 'chainId', editable: 'never' },
          { title: 'Meta Data', field: 'metadata', render: (rowData: Coin) => {
            if (!rowData || !rowData.metadata) return;
            return JSON.stringify(rowData.metadata);
          },
          },
          {
            title: 'Edit', render: (rowData: Coin) =>
              rowData && (
                <Link to={`/edit-coin?id=${rowData.coinId}`}>
                  <IconButton color="primary">
                    <MuiTableIcons.Edit />
                  </IconButton>
                </Link>
              ),
          },
        ]}
        data={coins}
        title=""
        options={{
          sorting: true,
          filtering: true,
          showTitle: false,
          pageSize: 100,
          emptyRowsWhenPaging: false,
          pageSizeOptions: [20, 50, 100, 500, 1000],
          rowStyle: {
            fontSize: 12,
          },
          headerStyle: {
            fontSize: 14,
            fontWeight: 'bold',
          },
          searchFieldStyle: {
            fontSize: 14,
          },
          searchFieldAlignment: 'right',
        }}
      />
    </div>
  );
};


ViewCoins.routePath = '/view-coins';
ViewCoins.loginRequired = true;
ViewCoins.displayName = 'ViewCoins';

export default ViewCoins;
