import axios from "axios";
import Headers from './headers';

const API_URL = "/api/question/";

class QuestionService {

  getLatestQuestionsWithAnswers() {
    return axios.get(API_URL + 'getLatestQuestionsWithAnswers', {headers: {...Headers.def(), ...Headers.auth()}}).then(response => {
      response.data.forEach(x => {x.answer = JSON.parse(x.answer)});
      response.data.sort((a, b) => a.order > b.order ? 1 : -1)
      return response;
    });
  }

  addAnswer(attemptId, questionId, answer){
    return axios.post(API_URL + 'addAnswer', {attemptId, questionId, answer}, {headers: {...Headers.def(), ...Headers.auth()}});
  }

}

export default new QuestionService();
