import { Box, Divider, Grid, Hidden, makeStyles, Typography, useMediaQuery } from '@material-ui/core';
import React from 'react';

import { breakpoints } from '../../theme/base';
import { prettyNumber } from '../../utils';

type InterestOverviewSectionProps = {
  className?: string

  totalInterest?: number
  currentMonthInterest?: number
  portfolioPercentage?: number
  averageInterestRate?: number
};

const useStyles = makeStyles((theme) => ({
  root: {
    position: 'relative',
    justifyContent: 'space-evenly',
    width: 'auto',

    color: theme.palette.common.white,
    background: 'radial-gradient(70.86% 116.06% at 26.08% 20.83%, #06D2AE 0%, #3DEACB 100%)',
    borderRadius: 8,
  },
  background: {
    position: 'absolute',
    bottom: 0,
    left: 0,
    width: '100%',

    [theme.breakpoints.up('sm')]: {
      width: 'fit-content',
      height: '100%',
    },
  },
  background2: {
    position: 'absolute',
    top: 0,
    right: 0,
    width: '100%',
    transform: 'scale(-1, -1)',

    [theme.breakpoints.up('sm')]: {
      width: 'fit-content',
      height: '100%',
    },
  },

  dividerHorizontal: {
    margin: '0 32px',
    height: 1,
    background: theme.palette.green['300'],
  },
  dividerVertical: {
    margin: '24px 0',
    // Hack to insert vertical Divider in Grid
    marginRight: -1,
    width: 1,
    background: theme.palette.green['300'],
  },

  itemGrid: { padding: '16px 0' },
  itemColumn: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    rowGap: 8,

    margin: '0 16px',
    height: '100%',
    textAlign: 'center',
  },
  itemUnit: { fontWeight: 400 },
  itemSubtitle: {
    color: theme.palette.common.white,
    fontSize: 12,
    fontWeight: 500,
    lineHeight: '16px',
  },
}));

const InterestOverviewSection = ({
  className,
  totalInterest,
  currentMonthInterest,
  portfolioPercentage,
  averageInterestRate,
}: InterestOverviewSectionProps) => {
  const classes = useStyles();
  const isXs = useMediaQuery(breakpoints.down('xs'));
  const isLarge = useMediaQuery(breakpoints.up('md'));

  return (
    <Grid className={`${className} ${classes.root}`} container justifyContent="space-between">
      <img className={classes.background} alt="" src="/images/dashboard/bg_portfolio_value.svg" />
      <img className={classes.background2} alt="" src="/images/dashboard/bg_portfolio_value.svg" />

      <Grid className={classes.itemGrid} item xs={12} sm={6} md={3}>
        <Box className={classes.itemColumn}>
          <Typography variant="h3" className={totalInterest === undefined ? 'loading-pulse' : ''}>
            {totalInterest && '+'}{prettyNumber(totalInterest ?? 0, { maximumFractionDigits: 2 })}
            {' '}<span className={classes.itemUnit}>USDT</span>
          </Typography>
          {totalInterest && totalInterest >= 1000 && (
            <Typography className={classes.itemSubtitle}>{parseFloat(totalInterest.toFixed(2)).toLocaleString()}</Typography>
          )}
          <Typography className={classes.itemSubtitle}>Total interest earned with Coinbag</Typography>
        </Box>
      </Grid>

      <Hidden smUp>
        <Grid item xs={12}>
          <Divider className={classes.dividerHorizontal} />
        </Grid>
      </Hidden>

      <Hidden xsDown>
        <Divider orientation="vertical" flexItem className={classes.dividerVertical} />
      </Hidden>

      <Grid className={classes.itemGrid} item xs={12} sm={6} md={2}>
        <Box className={classes.itemColumn}>
          <Typography variant="h3">
            {(((portfolioPercentage) ?? 0) * 100).toFixed(2).toLocaleString()}
            <span className={classes.itemUnit}>%</span>
          </Typography>
          <Typography className={classes.itemSubtitle}>Of portfolio is earning interest</Typography>
        </Box>
      </Grid>

      <Hidden mdUp>
        <Grid item xs={12}>
          <Divider className={classes.dividerHorizontal} />
        </Grid>
      </Hidden>

      <Hidden smDown>
        <Divider orientation="vertical" flexItem className={classes.dividerVertical} />
      </Hidden>

      <Grid className={classes.itemGrid} item xs={12} sm={6} md={2}>
        <Box className={classes.itemColumn}>
          <Typography variant="h3">
            {isLarge || isXs ? (
              <>
                {((averageInterestRate ?? 0) * 100).toFixed(2).toLocaleString()}
                <span className={classes.itemUnit}>%</span>
              </>
            ) : (
              <>
                {currentMonthInterest ? '+' : undefined}{currentMonthInterest ? prettyNumber(currentMonthInterest ?? 0, { maximumFractionDigits: 3 }) : 'N/A'}
                {' '}<span className={classes.itemUnit}>USDT</span>
              </>
            )}
          </Typography>
          {!(isLarge || isXs) && currentMonthInterest && currentMonthInterest >= 1000 ? (
            <Typography className={classes.itemSubtitle}>{currentMonthInterest.toLocaleString()}</Typography>
          ) : undefined}
          <Typography className={classes.itemSubtitle}>
            {isLarge || isXs ? 'Average interest rate' : 'Combined interest earned this month'}
          </Typography>
        </Box>
      </Grid>

      <Hidden smUp>
        <Grid item xs={12}>
          <Divider className={classes.dividerHorizontal} />
        </Grid>
      </Hidden>

      <Hidden xsDown>
        <Divider orientation="vertical" flexItem className={classes.dividerVertical} />
      </Hidden>

      <Grid className={classes.itemGrid} item xs={12} sm={6} md={3}>
        <Box className={classes.itemColumn}>
          <Typography variant="h3">
            {isLarge || isXs ? (
              <>
                {currentMonthInterest ? '+' : undefined}{currentMonthInterest ? prettyNumber(currentMonthInterest ?? 0, { maximumFractionDigits: 3 }) : 'N/A'}
                {' '}<span className={classes.itemUnit}>USDT</span>
              </>
            ) : (
              <>
                {(((averageInterestRate) ?? 0) * 100).toFixed(2).toLocaleString()}
                <span className={classes.itemUnit}>%</span>
              </>
            )}
          </Typography>
          {(isLarge || isXs) && currentMonthInterest && currentMonthInterest >= 1000 ? (
            <Typography className={classes.itemSubtitle}>{currentMonthInterest.toLocaleString()}</Typography>
          ) : undefined}
          <Typography className={classes.itemSubtitle}>
            {isLarge || isXs ? 'Combined interest earned this month' : 'Average interest rate'}
          </Typography>
        </Box>
      </Grid>
    </Grid>
  );
};

InterestOverviewSection.displayName = 'InterestOverview';
export default InterestOverviewSection;
