import { Box, makeStyles } from '@material-ui/core';
import { PortfolioValueCard } from 'components/dashboard';
import { AccountVerificationTag } from 'components/DepositAndWithdraw/AccountVerificationTag';
import { DepositAndWithdrawHistory } from 'components/DepositAndWithdraw/DepositAndWithdrawHistory';
import { DepositSection } from 'components/DepositAndWithdraw/DepositSection';
import { unsupportedChain } from 'components/DepositAndWithdraw/UnsupportedChain';
import { shouldShowRebalancingStatus } from 'components/RebalancingStatus';
import { Card, Typography } from 'elements';
import React, { useEffect, useState } from 'react';
import { Redirect } from 'react-router-dom';
import { DashboardRoute, DepositRoute } from 'routes';
import { useStore as useCoinStore } from 'store/zustand/Coin';
import { useStore as useWalletStore } from 'store/zustand/Wallet';

import RebalancingStatusComponent from '../../components/RebalancingStatus';
import { MasterCoinStatus } from '../../services/coin-enum';
import { CoinsWithMasterCoins } from '../../services/types/coin';
import WalletService from '../../services/wallet-service';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: 24,
    display: 'grid',
    gap: 16,
    gridTemplateColumns: '1fr 368px',
    gridTemplateRows: 'auto 1fr',
    flexGrow: 1,
    [theme.breakpoints.down('sm')]: {
      gridTemplateColumns: '1fr',
      gridTemplateRows: 'auto auto 1fr',
      padding: 0,
    },
  },
  titleBox: {
    position: 'relative',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    width: '100%',
    marginBottom: 8,
    gap: 8,
    [theme.breakpoints.down('sm')]: {
      marginBottom: 4,
    },
  },
  titleText: {
    fontSize: 16,
    lineHeight: '24px',
    fontWeight: 500,
    textAlign: 'left',
    color: theme.palette.grey[800],
    whiteSpace: 'nowrap',
  },
  section: {
    padding: 16,
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%',
    minHeight: '100%',
    overflow: 'hidden',
    boxShadow: '0px 4px 32px rgba(43, 43, 48, 0.04)',
    gap: 12,
    [theme.breakpoints.up('md')]: {
      gridRow: '1 / span 2',
      padding: '20px 32px',
    },
    [theme.breakpoints.down('sm')]: {
      borderRadius: 0,
      minHeight: 300,
      gap: 16,
    },
  },
  userInfo: {
    width: '30em',
    display: 'flex',
    flexDirection: 'column',
    gap: '16px',
  },
  portfolioValueCard: {
    width: '100%',
    height: 144,
    boxShadow: '0px 4px 32px rgba(43, 43, 48, 0.04)',
    borderRadius: '8px',
    gridColumn: 2,
    [theme.breakpoints.down('sm')]: {
      gridColumn: 1,
      marginTop: 16,
      padding: '0 16px',
    },
  },
  history: {
    gridColumn: 2,
    [theme.breakpoints.down('sm')]: {
      gridColumn: 1,
    },
  },
  accountVerificationTag: {
    position: 'absolute',
    right: 0,
    [theme.breakpoints.down('sm')]: {
      position: 'initial',
      right: 'auto',
    },
  },
}));

const Deposit = () => {
  const classes = useStyles();

  // Redirect to dashboard if CeFi
  const [walletAddresses, setWalletAddresses] = useState<Record<string, string>>({});
  const [loading, setLoading] = useState(true);
  const [depositableCoins, setDepositableCoins] = useState<CoinsWithMasterCoins>({});
  const [
    readyWallet, isDefi, maxPerf, fetchMinMaxPerf, walletDeps, fetchWallet, rebalancingStatus, fetchRebalancingStatus,
    fetchActiveWalletBalance, walletBalances, walletsTs,
  ] = useWalletStore((state) => [
    state.readyWallet,
    state.isDefi,
    state.maxPerf,
    state.fetchMinMaxPerf,
    state.wallet,
    state.fetchWallet,
    state.rebalancingStatus,
    state.fetchRebalancingStatus,
    state.fetchLatestWalletBalances,
    state.walletBalances,
    state.walletsTs,
  ]);

  const [coins, fetchCoins] = useCoinStore((state) => [
    state.coins,
    state.fetchCoins,
  ]);

  useEffect(() => {
    fetchWallet(false);
    fetchActiveWalletBalance();
  }, []);

  useEffect(() => {
    let canceled = false;
    const fetchData = async () => {
      const fetchWithdrawableCoins = async () => {
        const [fetchedCoinsWithMasterCoins, dexWallets] = await Promise.all([
          fetchCoins(), WalletService.getDexWallets(),
        ]);
        const wallets = dexWallets.data.reduce<Record<string, string>>((walletCollections, wallet) => ({
          ...walletCollections,
          [wallet.chainId]: wallet.address,
        }), {});
        const userChains = new Set(Object.keys(wallets));
        const fetchedWithdrawableCoins = Object.fromEntries(new Map(Object.entries(
          fetchedCoinsWithMasterCoins,
        ).filter(([, coinInfo]) => !coinInfo.protocolId
          && !unsupportedChain.includes(coinInfo.chainId)
          && userChains.has(coinInfo.chainId)
          && coinInfo.masterCoin.status !== MasterCoinStatus.UiDisabled)));
        if (!canceled) {
          setDepositableCoins(fetchedWithdrawableCoins);
          setWalletAddresses(wallets);
        }
      };

      await Promise.allSettled([fetchRebalancingStatus(), fetchMinMaxPerf(true), fetchWithdrawableCoins()]);

      if (readyWallet && !canceled) {
        setLoading(false);
      }
    };

    if (!canceled) {
      setLoading(true);
      if (walletDeps != null) {
        fetchData();
      }
    }

    const interval = setInterval(fetchData, 60 * 1000);
    return () => {
      canceled = true;
      clearInterval(interval);
    };
  }, [readyWallet]);

  if (readyWallet && !isDefi) return <Redirect to={DashboardRoute} />;

  return (
    <Box className={classes.root}>
      <Box className={classes.portfolioValueCard}>
        <PortfolioValueCard
          value={walletBalances ?? 0}
          lastUpdate={walletsTs ? new Date(walletsTs) : undefined}
          disableButtons
        />
      </Box>
      <Card className={classes.section} isLoading={loading} isSection>
        {shouldShowRebalancingStatus(rebalancingStatus) && rebalancingStatus === 'reporting-address' && (
          <RebalancingStatusComponent status={rebalancingStatus} />
        )}
        <Box className={classes.titleBox}>
          <Typography paletteColor={800} className={classes.titleText} align="center">Deposit Crypto</Typography>
          <AccountVerificationTag className={classes.accountVerificationTag} />
        </Box>
        <DepositSection
          walletAddresses={walletAddresses}
          depositableCoins={depositableCoins}
          portfolioValue={maxPerf?.point?.totalAssetValue ?? 0}
        />
      </Card>
      <Box className={classes.history}>
        <DepositAndWithdrawHistory coinsWithMasterCoins={coins} />
      </Box>
    </Box>
  );
};

Deposit.routePath = DepositRoute;
Deposit.loginRequired = true;
Deposit.redirect = 'depositRedirect';

export default Deposit;
