import { Box, makeStyles } from '@material-ui/core';
import { Button, InputWithTitle, LightModal, Typography } from 'elements';
import { LightTextField } from 'elements/LightTextField';
import * as React from 'react';
import roleService from 'services/role-service';
import { useStore as useUserStore } from 'store/zustand/User';
import isEmail from 'validator/lib/isEmail';

interface InviteMemberModalProps {
  open: boolean;
  setOpen: (value: boolean) => void;
}

const useStyles = makeStyles(() => ({
  modalContent: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    gap: 16,
  },
  modalButtons: {
    display: 'flex',
    justifyContent: 'space-between',
  },
}));

const InviteMemberModal = (props: InviteMemberModalProps) => {
  const classes = useStyles();
  const [email, setEmail] = React.useState('');
  const [emailError, setEmailError] = React.useState('');
  const [firstName, setFirstName] = React.useState('');
  const [lastName, setLastName] = React.useState('');
  const [loading, setLoading] = React.useState(false);
  const [complete, setComplete] = React.useState(false);
  const [error, setError] = React.useState(false);
  const [isReadyToSave, setIsReadyToSave] = React.useState(false);
  const [allowedUsers, fetchAllowedUsers] = useUserStore((state) => [state.allowedUsers, state.fetchAllowedUsers]);

  const onInviteMember = async () => {
    if (isReadyToSave) {
      setLoading(true);
      try {
        await roleService.invite(firstName, lastName, email);
        setLoading(false);
        setComplete(true);
        fetchAllowedUsers(true);
      } catch {
        setLoading(false);
        setError(true);
      }
    }
  };

  React.useEffect(() => {
    if (!props.open) {
      setEmail('');
      setEmailError('');
      setFirstName('');
      setLastName('');
      setLoading(false);
      setComplete(false);
      setError(false);
    }
  }, [props.open]);

  const checkValid = (text: string) => {
    const isValidEmail = isEmail(text);
    const isExist = isValidEmail && (allowedUsers ?? []).findIndex((u) => u.email === text) >= 0;
    setEmailError(text ? (isValidEmail ? (isExist ? 'Email is already exist' : '') : 'Invalid email') : '');
    setIsReadyToSave(text.length > 0 && isValidEmail && !isExist);
  };

  return (
    <LightModal open={props.open} setOpen={props.setOpen} maxWidth={372} modalTitle="Invite Member" showCloseButton>
      <Box className={classes.modalContent}>
        {complete ? (
          <>
            <Typography variant="h4" paletteColor={700} align="center">
              Invitation email sent
            </Typography>
            <Button fullWidth onClick={() => props.setOpen(false)}>
              Done
            </Button>
          </>
        ) : (
          <>
            <InputWithTitle
              title="Email"
              titleRightItem={(
                <Typography variant="h6" palette="red" paletteColor={500} strong={false}>
                  {emailError}
                </Typography>
              )}
            >
              <LightTextField
                value={email}
                error={!!emailError}
                onChange={(e) => setEmail(e.target.value)}
                onBlur={(e) => { checkValid(e.target.value); }}
                placeholder="example@email.com"
              />
            </InputWithTitle>
            <InputWithTitle title="First Name">
              <LightTextField
                value={firstName}
                onChange={(e) => setFirstName(e.target.value)}
                placeholder="Member's first name"
              />
            </InputWithTitle>
            <InputWithTitle title="Last Name">
              <LightTextField
                value={lastName}
                onChange={(e) => setLastName(e.target.value)}
                placeholder="Member's last name"
              />
            </InputWithTitle>
            {error && (
              <Typography variant="h6" palette="red" paletteColor={500} strong={false} align="center">
                Something went wrong please try again later.
              </Typography>
            )}
            <Box className={classes.modalButtons}>
              <Button disabled={loading} variant="secondary" onClick={() => props.setOpen(false)}>Cancel</Button>
              <Button variant="primary" disabled={!isReadyToSave || loading} onClick={onInviteMember}>Invite</Button>
            </Box>
          </>
        )}
      </Box>
    </LightModal>
  );
};

export default InviteMemberModal;
