import { Box, makeStyles } from '@material-ui/core';
import { Typography } from 'elements';
import * as React from 'react';
import SVG from 'react-inlinesvg';
import { Permission } from 'services/types/role';
import { useStore as useRoleStore } from 'store/zustand/Role';

interface SelectApproverPopoverProps {
  permission: Permission;
  onClose: () => void;
}

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    '& div:first-child': {
      borderTop: 'none',
    },
  },
  row: {
    display: 'flex',
    cursor: 'pointer',
    padding: '8px 12px',
    alignItems: 'center',
    justifyContent: 'space-between',
    borderTop: `1px solid ${theme.palette.grey[200]}`,
    '&:hover': {
      backgroundColor: theme.palette.green[50],
    },
    '& svg': {
      flexShrink: 0,
    },
  },
}));

const SelectApproverPopover = (props: SelectApproverPopoverProps) => {
  const classes = useStyles();

  const [updateRequiredApprovers] = useRoleStore((state) => [state.updateRequiredApprovers]);

  const [selectedValue, setSelectValue] = React.useState(0);

  const options: { label: string, value: number }[] = React.useMemo(() => {
    const requiredApprovers = Math.min(props.permission.requiredApprovers, props.permission.approvers.length);
    setSelectValue((Math.max(0, requiredApprovers)));

    const result = [];
    if (props.permission.approvers.length === 0) {
      result.push({ label: 'Not required ', value: 0 });
    }
    for (let i = 1; i < props.permission.approvers.length; i += 1) {
      if (i === 1) {
        result.push({ label: '1 approver', value: 1 });
      } else {
        result.push({ label: `${i} total approvers`, value: i });
      }
    }
    if (props.permission.approvers.length > 0) {
      result.push({ label: 'All approving members', value: props.permission.approvers.length });
    }
    return result;
  }, [props.permission.approvers, props.permission.requiredApprovers]);

  const updateValue = (value: number) => {
    if (props.permission) {
      updateRequiredApprovers(props.permission.permissionId, value);
      props.onClose();
    }
  };

  return (
    <Box className={classes.root}>
      {options.map(({ label, value }, index) => (
        <Box className={classes.row} key={index} onClick={() => updateValue(value)}>
          <Typography variant="h5" paletteColor={700}>{label}</Typography>
          {selectedValue === value && <SVG src="/icons/ic_check.svg" width={16} height={16} />}
        </Box>
      ))}
    </Box>
  );
};

export default SelectApproverPopover;
