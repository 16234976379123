import {
  Box,
  createStyles,
  Drawer,
  Hidden,
  Link,
  makeStyles,
  Menu,
  MenuItem,
  Theme,
  useMediaQuery,
  useTheme,
} from '@material-ui/core';
import { Button } from 'elements';
import SocialLinks from 'elements/SocialLink';
import { Typography } from 'elements/Typography';
import React, { FC } from 'react';
import SVG from 'react-inlinesvg';
import {
  HomeRoutes,
  OurStoryRoute,
  SignInRoute,
  SignUpIndividualInvestorsRoute,
  SignUpInstitutionalInvestorsRoute,
  SignUpPartnerRoute,
  WhyCoinbagRoute,
} from 'routes';
import { palette } from 'theme';

const useStyles = makeStyles((theme: Theme) => createStyles({
  root: {
    position: 'sticky',
    height: 80,
    width: '100%',
    top: 0,
    zIndex: 10,
    backgroundColor: 'white',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    '& a:hover': {
      textDecoration: 'none',
    },

    [theme.breakpoints.up('md')]: {
      filter: 'drop-shadow(0px 4px 40px rgba(81, 89, 111, 0.1))',
    },
    [theme.breakpoints.down('sm')]: {
      height: 60,
      borderBottom: '1px solid #F7F8FD',
    },
  },
  content: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
    maxWidth: '1080px',
    padding: '0 16px',
    gap: 12,
    [theme.breakpoints.up('xl')]: {
      maxWidth: 1366,
    },
  },
  logo: {
    userDrag: 'none',
    userSelect: 'none',
    width: 128,
    height: 32,
    [theme.breakpoints.down('sm')]: {
      width: 80,
      height: 20,
    },
  },
  logoWrapper: {
    display: 'flex',
    flexWrap: 'nowrap',
    gap: 12,
    alignItems: 'center',
  },
  link: {
    display: 'flex',
    alignItems: 'center',
  },
  menuBox: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    flexGrow: 1,
    maxWidth: 500,
  },
  about: {
    '& svg': {
      width: 16,
      height: 16,
      marginLeft: 8,
      '& path': {
        fill: palette.grey[700],
      },
    },
  },
  selected: {
    '& h5': {
      color: palette.green[500],
    },
    '& svg': {
      '& path': {
        fill: palette.green[500],
      },
    },
  },
  button: {
    minWidth: 160,
    [theme.breakpoints.down('sm')]: {
      minWidth: 0,
    },
  },
  paper: {
    borderRadius: 12,
    marginTop: 50,
    boxShadow: '0px 12px 44px rgba(110, 123, 162, 0.4)',
    '& a:hover': {
      textDecoration: 'none',
    },
  },
  sidebarRoot: { zIndex: '1500 !important' as any },
  sidebar: {
    width: '100%',
    maxWidth: 450,
  },
  drawerMenuWrapper: {
    display: 'flex',
    flexDirection: 'column',
    padding: 24,
    minHeight: '100%',
    gap: 12,
    overflowY: 'scroll',
    '&::-webkit-scrollbar': {
      display: 'none',
      width: '0px',
      background: 'transparent',
    },
    '& .link': {
      padding: '16px 0',
      display: 'flex',
      justifyContent: 'space-between',
      '&:hover': {
        textDecoration: 'none',
      },
    },
    '& .coinbagLogo': {
      marginBottom: 12,
    },
    '& .close': {
      position: 'absolute',
      right: 24,
      top: 22,
      margin: 4,
      '& circle': {
        fill: theme.palette.grey[500],
      },
    },
    '& .arrow': {
      flexShrink: 0,
      '& path': {
        fill: theme.palette.grey[300],
      },
    },
    '& .footerWrapper': {
      display: 'flex',
      flexDirection: 'column',
      gap: 24,
      alignItems: 'center',
      marginBottom: 12,
      flex: 1,
      justifyContent: 'flex-end',
    },
    '& .subMenu': {
      marginLeft: 14,
      marginBottom: 12,
    },
  },
  arrow: {
    flexShrink: 0,
    width: 16,
  },
}));

interface HomePageLayoutHeaderProps {
  isHomePage?: boolean;
}

const HomePageLayoutHeader: FC<HomePageLayoutHeaderProps> = () => {
  const classes = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const [sidebarOpen, setSidebarOpen] = React.useState(false);
  const [aboutMenuOpen, setAboutMenuOpen] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <header className={classes.root}>
      <Box className={classes.content}>
        <Box className={classes.logoWrapper}>
          <Hidden mdUp>
            <Button variant="icon" onClick={() => setSidebarOpen(true)}>
              <img alt="menu" src="/icons/dashboard/ic_menu.svg" width={32} height={32} />
            </Button>
            <Drawer
              classes={{ root: classes.sidebarRoot, paper: classes.sidebar }}
              variant="temporary"
              anchor="left"
              open={sidebarOpen}
              onClose={() => setSidebarOpen(false)}
              ModalProps={{ keepMounted: true }}
            >
              <nav className={classes.drawerMenuWrapper}>
                <Box className="close" onClick={() => setSidebarOpen(false)}>
                  <SVG src="/icons/icon_cross_circle.svg" width={20} height={20} />
                </Box>
                <img
                  className="coinbagLogo"
                  alt="coinbag"
                  src="/logos/coinbag_long.svg"
                  width={130}
                  height={32}
                  fetchpriority="high"
                />
                <Link
                  className="link"
                  href={SignUpInstitutionalInvestorsRoute}
                  onClick={() => setSidebarOpen(false)}
                >
                  <Typography palette="grey" variant="h5" paletteColor={700}>Businesses</Typography>
                </Link>
                <Link
                  className="link"
                  href={SignUpIndividualInvestorsRoute}
                  onClick={() => setSidebarOpen(false)}
                >
                  <Typography palette="grey" variant="h5" paletteColor={700}>Individuals</Typography>
                </Link>
                <Link
                  className="link"
                  href={SignUpPartnerRoute}
                  onClick={() => setSidebarOpen(false)}
                >
                  <Typography palette="grey" variant="h5" paletteColor={700}>Partners</Typography>
                </Link>

                <Box className="link" onClick={() => setAboutMenuOpen(!aboutMenuOpen)}>
                  <Typography palette="grey" variant="h5" paletteColor={700}>About
                    Coinbag
                  </Typography>
                  <Box className="arrow">
                    <SVG width={28} height={28} src="/icons/dashboard/ic_arrow_down.svg" />
                  </Box>
                </Box>
                {aboutMenuOpen && (
                  <>
                    <Link underline="none" className="subMenu" href={WhyCoinbagRoute}>
                      <Typography palette="grey" variant="h5" paletteColor={500}>Why
                        Coinbag
                      </Typography>
                    </Link>
                    <Link underline="none" className="subMenu" href={OurStoryRoute}>
                      <Typography palette="grey" variant="h5" paletteColor={500}>Our
                        story
                      </Typography>
                    </Link>
                    <Link
                      underline="none"
                      className="subMenu"
                      href="https://coinbag.tawk.help"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <Typography palette="grey" variant="h5" paletteColor={500}>FAQ</Typography>
                    </Link>
                  </>
                )}
                <Box className="footerWrapper">
                  <Link
                    href="https://meetings.hubspot.com/sales-coinbag"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <Button id="demo" variant="outline" color="blue" size="xmd" fullWidth>
                      Demo
                    </Button>
                  </Link>
                  <Button id="signin" color="blue" fullWidth size="xmd" href={SignInRoute}>
                    Sign in
                  </Button>
                  <SocialLinks align="center" />
                </Box>
              </nav>
            </Drawer>
          </Hidden>

          <Link className={classes.link} href={HomeRoutes[0]}>
            <img className={classes.logo} alt="home" src="/logos/coinbag_long.svg" />
          </Link>
        </Box>

        <Hidden smDown>
          <Box className={classes.menuBox} component="nav">
            <Link href={SignUpInstitutionalInvestorsRoute}>
              <Typography palette="grey" variant="h5" paletteColor={700}>Businesses</Typography>
            </Link>
            <Link href={SignUpIndividualInvestorsRoute}>
              <Typography palette="grey" variant="h5" paletteColor={700}>Individuals</Typography>
            </Link>
            <Link href={SignUpPartnerRoute}>
              <Typography palette="grey" variant="h5" paletteColor={700}>Partners</Typography>
            </Link>
            <Button
              variant="icon"
              className={`${classes.about} ${open ? classes.selected : ''}`}
              colorVariant={700}
              onClick={handleClick}
            >
              <Typography palette="grey" variant="h5" paletteColor={700}>About Coinbag</Typography>
              <Box className={classes.arrow}>
                <SVG src="/icons/dashboard/ic_arrow_down.svg" />
              </Box>
            </Button>
          </Box>

          <Menu
            anchorEl={anchorEl}
            id="account-menu"
            open={open}
            onClose={handleClose}
            onClick={handleClose}
            variant="menu"
            PopoverClasses={{ paper: classes.paper }}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
            transformOrigin={{ vertical: 'top', horizontal: 'left' }}
          >
            <MenuItem component="a" href={WhyCoinbagRoute}>
              <Typography palette="grey" variant="h5" paletteColor={700}>Why Coinbag</Typography>
            </MenuItem>
            <MenuItem component="a" href={OurStoryRoute}>
              <Typography palette="grey" variant="h5" paletteColor={700}>Our story</Typography>
            </MenuItem>
            <MenuItem component="a" href="https://coinbag.tawk.help" target="_blank" rel="noreferrer">
              <Typography palette="grey" variant="h5" paletteColor={700}>FAQ</Typography>
            </MenuItem>
            <MenuItem component="a" href="mailto:support@coinbag.finance">
              <Typography palette="grey" variant="h5" paletteColor={700}>Contact us</Typography>
            </MenuItem>
            <MenuItem autoFocus={false}><SocialLinks /></MenuItem>
          </Menu>
        </Hidden>

        <Box display="flex" alignItems="center">
          <Link
            href="https://meetings.hubspot.com/sales-coinbag"
            target="_blank"
            rel="noopener noreferrer"
          >
            <Button
              id="demo"
              className={classes.button}
              size={isMobile ? 'sm' : 'md'}
              variant="outline"
              color="blue"
            >
              <Hidden smDown>Schedule </Hidden>Demo
            </Button>
          </Link>

          <Box marginLeft="12px" />
          <Button
            id="signin"
            color="blue"
            size={isMobile ? 'sm' : 'md'}
            className={classes.button}
            href={SignInRoute}
          >
            Sign in
          </Button>
        </Box>
      </Box>
    </header>
  );
};

HomePageLayoutHeader.displayName = 'Header';
export { HomePageLayoutHeader };
