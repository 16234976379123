import { Box, createStyles, Hidden, makeStyles, Theme } from '@material-ui/core';
import { Button, Typography } from 'elements';
import * as React from 'react';
import SVG from 'react-inlinesvg';
import { SignUpRoute } from 'routes';

const useStyles = makeStyles((theme: Theme) => createStyles({
  root: {
    zIndex: 1,
    display: 'flex',
    gap: 36,
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    color: theme.palette.grey[700],
    '& em': {
      color: theme.palette.green[600],
      fontStyle: 'normal',
    },
    '& h1': {
      fontSize: 44,
      lineHeight: '80px',
      fontWeight: 700,
      [theme.breakpoints.down('sm')]: {
        fontSize: 24,
        lineHeight: '44px',
        textAlign: 'center',
      },
    },
    '& p': {
      fontSize: 20,
      lineHeight: '32px',
      fontWeight: 500,
      color: theme.palette.grey[700],
      [theme.breakpoints.down('sm')]: {
        fontSize: 16,
        lineHeight: '24px',
        textAlign: 'center',
        marginBottom: 12,
      },
    },
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    gap: 16,
    [theme.breakpoints.down('sm')]: {
      alignItems: 'center',
      gap: 8,
    },
  },
  imageWrapper: {
    padding: 18,
    borderRadius: 36,
    background: 'rgba(255, 255, 255, 0.5)',
    backdropFilter: 'blur(8px)',
    border: '2px solid #F7F8FD',
    marginRight: -400,
    position: 'relative',
    zIndex: 1,
  },
  image: {
    userDrag: 'none',
    userSelect: 'none',
    width: 823,
    height: 503,
    border: '2px solid #F7F8FD',
    borderRadius: 18,
    [theme.breakpoints.down('sm')]: {
      width: 260,
      height: 496,
      padding: 12,
      background: 'rgba(255, 255, 255, 0.5)',
      backdropFilter: 'blur(8px)',
    },
  },
  icon: {
    position: 'absolute',
    '&#a': {
      bottom: -48,
      right: '40%',
      zIndex: 2,
    },
  },
  button: {
    [theme.breakpoints.up('md')]: {
      maxWidth: 300,
    },
  },
}));

const WhyCoinbag = () => {
  const classes = useStyles();
  return (
    <article className={classes.root}>
      <Box className={classes.content}>
        <Typography variant="h1">Why <em>Coinbag?</em></Typography>
        <Box>
          <p>Your wallet. Your crypto. Your control.</p>
          <p>Coinbag provides simple, secure and automated investment tools to allow you to control and maximize your crypto portfolio!</p>
        </Box>
        <Hidden mdUp>
          <img
            fetchpriority="high"
            className={classes.image}
            alt="coinbag dashboard"
            src="/images/home/dashboard_institutional_mobile@1x.png"
            srcSet="/images/home/dashboard_institutional_mobile@1x.png 1x, /images/home/dashboard_institutional_mobile@2x.png 2x, /images/home/dashboard_institutional_mobile@3x.png 3x"
          />
        </Hidden>
        <Button
          className={classes.button}
          href={SignUpRoute}
          fullWidth
          size="xmd"
        >
          Get started for Free
        </Button>
      </Box>
      <Hidden smDown>
        <Box className={classes.imageWrapper}>
          <img
            fetchpriority="high"
            className={classes.image}
            alt="coinbag dashboard"
            src="/images/marketing/coinbag_dashboard@1x.png"
            srcSet="/images/marketing/coinbag_dashboard@1x.png 1x, /images/marketing/coinbag_dashboard@2x.png 2x, /images/marketing/coinbag_dashboard@3x.png 3x"
          />
          <SVG width={90} height={90} src="/icons/signup/ic_bg_1.svg" className={classes.icon} id="a" />
        </Box>
      </Hidden>
    </article>
  );
};

export default WhyCoinbag;
