import { Box, createStyles, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import React from 'react';
import { useStore as useUserStore } from 'store/zustand/User';
import { useStore as useWalletStore } from 'store/zustand/Wallet';

interface Props {
  className?: string
}

const useStyles = makeStyles((theme) => createStyles({
  root: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-end',
    gap: 4,
  },
  icon: {
    display: 'flex',
    width: 16,
    height: 16,
    userDrag: 'none',
    userSelect: 'none',
  },
  text: {
    color: theme.palette.grey[700],
    textAlign: 'right',
    '& img': {
      display: 'inline',
      marginRight: 4,
      verticalAlign: 'sub',
    },
  },
}));

export const AccountVerificationTag = (props: Props) => {
  const classes = useStyles();
  const [user] = useUserStore((state) => [state.user]);
  const [wallet] = useWalletStore((state) => [state.wallet]);
  const selectedWalletInfo = React.useMemo(() => (
    user?.walletInfo.find((w) => w.walletId === wallet?.walletId)
  ), [user, wallet]);

  if (!selectedWalletInfo?.isOwn) return null;

  return (
    <Box className={`${props.className} ${classes.root}`}>
      {selectedWalletInfo?.isVerified ? (
        <Typography component="span" variant="h6" className={classes.text}>
          <img src="icons/withdraw/check_icon.svg" alt="Check" className={classes.icon} />
          Account verified
        </Typography>
      ) : (
        <Typography component="span" variant="h6" className={classes.text}>
          <img src="icons/ic_info_yellow.svg" alt="Info" className={classes.icon} />
          Account not verified
        </Typography>
      )}
    </Box>
  );
};
