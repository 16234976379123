import { User } from 'models';
import { create } from 'zustand';

import { useStore as userStore } from './User';

interface AccountSettingStore {
  firstName?: string;
  // eslint-disable-next-line no-unused-vars
  setFirstName: (value?: string) => void;
  lastName?: string;
  // eslint-disable-next-line no-unused-vars
  setLastName: (value?: string) => void;
  gender?: string;
  // eslint-disable-next-line no-unused-vars
  setGender: (value?: string) => void;
  birthDate?: Date;
  // eslint-disable-next-line no-unused-vars
  setBirthDate: (value?: Date) => void;
  phoneNumber?: string;
  // eslint-disable-next-line no-unused-vars
  setPhoneNumber: (value?: string) => void;
  countryIso2?: string;
  companyName?: string;
  // eslint-disable-next-line no-unused-vars
  setCountryIso2: (value?: string) => void;
  // eslint-disable-next-line no-unused-vars
  setCompanyName: (value?: string) => void;
  isAuthenticatorConfigured: boolean;
  // eslint-disable-next-line no-unused-vars
  setIsAuthenticatorConfigured: (value: boolean) => void;
  // eslint-disable-next-line no-unused-vars
  syncUserData: (user?: User) => void;
  fetchAndSyncUserData: () => Promise<void>;
  // eslint-disable-next-line no-unused-vars
  isChange: (user?: User) => boolean;
}

export const useStore = create<AccountSettingStore>((set, get) => ({
  firstName: undefined,
  lastName: undefined,
  gender: undefined,
  phoneNumber: undefined,
  countryIso2: undefined,
  companyName: undefined,
  isAuthenticatorConfigured: false,
  setFirstName: (value?: string) => {
    set({ firstName: value });
  },
  setLastName: (value?: string) => {
    set({ lastName: value });
  },
  setGender: (value?: string) => {
    set({ gender: value });
  },
  setBirthDate: (value?: Date) => {
    set({ birthDate: value });
  },
  setPhoneNumber: (value?: string) => {
    set({ phoneNumber: value });
  },
  setCountryIso2: (value?: string) => {
    set({ countryIso2: value });
  },
  setCompanyName: (value?: string) => {
    set({ companyName: value });
  },
  setIsAuthenticatorConfigured: (value: boolean) => {
    set({ isAuthenticatorConfigured: value });
  },
  syncUserData: (user?: User) => {
    get().setFirstName(user?.firstName);
    get().setLastName(user?.lastName);
    get().setGender(user?.gender);
    get().setBirthDate(user?.birthDate ? new Date(user.birthDate) : undefined);
    get().setCountryIso2(user?.residenceCountry);
    get().setCompanyName(user?.companyName);
    get().setPhoneNumber(user?.phoneNumber);
    get().setIsAuthenticatorConfigured(user?.secret_2fa === '');
  },
  fetchAndSyncUserData: async () => {
    const user = await userStore.getState().fetchUser(true);
    get().syncUserData(user);
  },
  isChange: (user?: User) => {
    if (user == null) return false;
    return !((get().firstName ?? '') === (user?.firstName ?? '')
      && (get().lastName ?? '') === (user?.lastName ?? '')
      && (get().gender ?? '') === (user?.gender ?? '')
      && get().birthDate === (user?.birthDate ? new Date(user.birthDate) : undefined)
      && get().countryIso2 === user?.residenceCountry
      && get().companyName === user?.companyName
      && (get().phoneNumber ?? '') === (user?.phoneNumber ?? ''));
  },
}));
