/* eslint-disable no-unused-vars */
import { LightTextField } from 'elements/LightTextField';
import React, { ChangeEvent, FC } from 'react';

import { placeholderMapper, walletPattern } from '../mappers';

interface AddressFieldProps {
  selectedNetwork: string;
  setAddressPatternError: (value: boolean) => void;
  address: string;
  setAddress: (value: string) => void;
  disabled?: boolean;
}

export const AddressField: FC<AddressFieldProps> = (props) => {
  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const pattern = walletPattern[props.selectedNetwork];
    props.setAddress(event.target.value);
    if (!pattern.test(event.target.value)) {
      props.setAddressPatternError(true);
    } else {
      props.setAddressPatternError(false);
    }
  };

  return (
    <LightTextField
      id="address"
      variant="outlined"
      value={props.address}
      onChange={handleChange}
      disabled={props.disabled}
      placeholder={props.selectedNetwork ? `e.g. ${placeholderMapper[props.selectedNetwork]}` : ''}
    />
  );
};
