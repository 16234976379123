import axios from 'axios';

import { IPResponse } from '../models/Ip';
import { UserInfo } from './auth-service';
import Headers from './headers';

const API_URL = '/api/user/';

const getUnfinishedKYCUserList = (): Promise<UserInfo[]> => axios.get(`${API_URL}getUnfinishedKYCUserList`,
  { headers: { ...Headers.def(), ...Headers.auth() } })
  .then((response) => response.data);

const revokeAccess = (emailCode: string | null, authenticatorCode: string | null): Promise<string> => axios.post(`${API_URL}revokeAccess`,
  { emailCode, authenticatorCode },
  { headers: { ...Headers.def(), ...Headers.auth() } })
  .then((response) => response.data);

const resendRevokeEmail = (): Promise<string> => axios.post(`${API_URL}resendRevokeEmail`,
  {},
  { headers: { ...Headers.def(), ...Headers.auth() } })
  .then((response) => response.data);

const confirmDeleteAccount = (token: string): Promise<string> => axios.post(`${API_URL}confirmDeleteAccount`,
  { token },
  { headers: { ...Headers.def() } })
  .then((response) => response.data);

const updateActiveWallet = (walletId?: number, institutionId?: number): Promise<void> => axios.post(`${API_URL}updateActiveWalletId`,
  { walletId, institutionId },
  { headers: { ...Headers.def(), ...Headers.auth() } });

const getAllowedUsers = (userId: number): Promise<UserInfo[]> => axios.get(`${API_URL}getAllowedUsers/${userId}`,
  { headers: { ...Headers.def(), ...Headers.auth() } })
  .then((response) => response.data);

const detectCountryIp = (): Promise<IPResponse> => axios.get(`${API_URL}detectIpCountry`).then((response) => response.data);

const detectTestMachine = (): Promise<boolean> => axios.get(`${API_URL}detectTestMachine`).then((response) => response.data === 'true');

export default {
  getUnfinishedKYCUserList,
  revokeAccess,
  resendRevokeEmail,
  confirmDeleteAccount,
  updateActiveWallet,
  getAllowedUsers,
  detectCountryIp,
  detectTestMachine,
};
