import { createStyles, Hidden, makeStyles, Theme } from '@material-ui/core';
import { Typography } from 'elements';
import * as React from 'react';
import { SignUpRoute } from 'routes';

import SignUp from './SignUp';

const useStyles = makeStyles((theme: Theme) => createStyles({
  root: {
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
    color: theme.palette.grey[700],
    '& em': {
      color: theme.palette.green[600],
      fontStyle: 'normal',
    },
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    width: '100%',
    maxWidth: 1080,
    padding: '48px 16px',
    gap: 120,
    alignItems: 'center',
    [theme.breakpoints.down('sm')]: {
      maxWidth: 640,
      gap: 40,
      paddingTop: 16,
    },
    '&::before': {
      content: '" "',
      width: '1600px',
      height: '1200px',
      top: '-400px',
      left: '40%',
      position: 'absolute',
      background: 'radial-gradient(50% 50% at 50% 50%, rgba(46, 255, 218, 0.315) 0%, rgba(255, 255, 255, 0) 100%)',
      zIndex: 0,
      filter: 'blur(80px)',
      backfaceVisibility: 'hidden',
      perspective: 1000,
      transform: 'translateZ(0)',
      [theme.breakpoints.down('sm')]: {
        width: '1400px',
        height: '680px',
        top: '-300px',
        left: 'unset',
      },
    },
  },
  title: {
    display: 'flex',
    flexDirection: 'column',
    gap: 20,
    '& h1': {
      fontSize: 44,
      lineHeight: '80px',
      fontWeight: 700,
      [theme.breakpoints.down('sm')]: {
        fontSize: 24,
        lineHeight: '44px',
        textAlign: 'center',
      },
    },
    '& h2': {
      fontSize: 20,
      lineHeight: '32px',
      fontWeight: 500,
      [theme.breakpoints.down('sm')]: {
        fontSize: 12,
        lineHeight: '20px',
        fontWeight: 400,
        textAlign: 'center',
      },
    },
    '& h3': {
      fontSize: 16,
      lineHeight: '28px',
      fontWeight: 500,
      [theme.breakpoints.down('sm')]: {
        fontSize: 14,
        lineHeight: '24px',
      },
    },
    '& em': {
      color: theme.palette.green[600],
      fontStyle: 'normal',
    },
    [theme.breakpoints.up('md')]: {
      textAlign: 'left',
      position: 'sticky',
      top: 170,
    },
    [theme.breakpoints.down('sm')]: {
      gap: 12,
    },
  },
}));

const SignUpPage = () => {
  const classes = useStyles();
  return (
    <main className={classes.root}>
      <section itemScope className={classes.content}>
        <SignUp
          title={(
            <article className={classes.title}>
              <Typography variant="h1"><em>Sign up</em> to Coinbag</Typography>
              <Hidden smDown>
                <Typography variant="h2">
                  In only a few minutes you can build a crypto portfolio, deposit, and start earning interest! Supercharge your portfolio, today!
                </Typography>
              </Hidden>
            </article>
          )}
        />
      </section>
    </main>
  );
};

SignUpPage.routePath = SignUpRoute;
SignUpPage.logoutRequired = true;
SignUpPage.allowedForAccessRevokedUser = true;

export default SignUpPage;
