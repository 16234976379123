import React from "react";
import { Footer } from "../../components/Footer.js";
import { InternalNavbar } from "../../components/navbar.js";
import { Container, Row, Col } from 'react-bootstrap'
import Loader from "../../components/Loader.js";
import SharedStateComponent from "../../components/SharedStateComponent.js";
import ChooseExchange from "../responsive/ChooseExchange.js";

export default class AboutDirectDeposit extends SharedStateComponent {
  static routePath = '/about-direct-deposit';
  static loginRequired = true;

  constructor(props) {
    super(props);

    this.state = {
      form: {}
    }
  }

  render() {
    return (
      <>

        <Loader loading={false} />

        <InternalNavbar history={this.props.history} title="About Direct Deposit" />

        <Container className="naked">
          <Row className="mt-0">
            <Col>
              <p className="mb-0"><b>How does direct deposit work?</b></p>
              <p>It’s quite simple. We create a unique wallet for you (more on that below). We then connect you to our partner Sendwyre where you can securely deposit money directly into this wallet using your credit/debit card. We then use a decentralized exchange (more on these below) to automatically build your portfolio. Your wallet will hold all of your cryptos.</p>
            </Col>
          </Row>
          <Row className="mt-3">
            <Col>
              <p className="mb-0"><b>What is a wallet, specifically Coinbag wallet?</b></p>
              <p>Think of a wallet as a private key to all of the cryptos that only you hold - very similar to a password. Unlike an actual wallet that stores cash, a crypto wallet does not actually store your crypto because the crypto lives on the blockchain. But, don’t worry, you can use your private key to prove ownership and access it at any time, like if you want to sell. Because you are the only holder of your private key, this makes it much more secure than most other assets you probably own. The Coinbag wallet is exactly this. It is a non-custodial wallet (more on this below), that gives you the key to securely hold or withdraw your crypto.</p>
            </Col>
          </Row>
          <Row className="mt-3">
            <Col>
              <p className="mb-0"><b>What does “non-custodial” mean?</b></p>
              <p>Non-custodial means that Coinbag does not own or hold the funds in the wallet. It is your wallet, your key, and you can do with it what you like. This can include allowing Coinbag to buy/sell cryptos on your behalf to build a portfolio that matches your risk profile.</p>
            </Col>
          </Row>
          <Row className="mt-3">
            <Col>
              <p className="mb-0"><b>How can I send money directly to my Coinbag wallet?</b></p>
              <p>We have partnered with Sendwyre to allow you to buy cryptocurrencies with your debit or credit card. You can use the Coinbag product to connect your wallet, and then all you have to do is input your credit/debit card, select the amount you want to purchase and of which crypto and you’re good to go!</p>
            </Col>
          </Row>
          <Row className="mt-3">
            <Col>
              <p className="mb-0"><b>Sendwyre has many options to buy - does it matter?</b></p>
              <p>Not really. We will use whichever crypto you purchase to ultimately purchase other cryptos that match your risk profile. To keep it simple, we recommend buying with a stablecoin like USDC or USDT. These coins are pegged to the U.S. dollar to always be as close to a 1:1 match as possible.</p>
            </Col>
          </Row>
          <Row className="mt-3">
            <Col>
              <p className="mb-0"><b>What is a decentralized exchange?</b></p>
              <p>A decentralized exchange or DEX is a place where people can go to trade cryptocurrencies without an intermediary like Binance, Coinbase, etc. Decentralized exchanges use blockchain smart contracts (agreements) to securely facilitate the trading of cryptocurrencies peer-to-peer rather than through a centralized entity.</p>
            </Col>
          </Row>
          <Row className="mt-3">
            <Col>
              <p className="mb-0"><b>Are decentralized exchanges secure?</b></p>
              <p>The exchanges we partner with are known to have put in place the best security measures available. There is never a 100% guarantee, though. Compared to centralized exchanges, they can be more secure as you hold your funds at all times, where centralized exchanges actually hold your funds in custodial accounts and can be susceptible to hacks. With that said, if there were to be an issue, centralized exchanges have a reputation to maintain and have been known to cover customers. With decentralized exchange, if something were to happen, there is no recourse to recover any losses. Any decentralized exchange used by Coinbag has to have gone through rigorous security audits in order to be a partner.</p>
            </Col>
          </Row>
          <Row className="mt-3">
            <Col>
              <p className="mb-0"><b>Why choose decentralized exchange over a centralized exchange?</b></p>
              <p>Decentralized exchanges offer more autonomy than centralized exchanges. No one knows who you are, and there is no sign up process for it. We use the keys you hold with your wallet to buy and sell on your behalf. With decentralized exchanges, your funds are always stored in your secure wallet, and not on an exchange.</p>
            </Col>
          </Row>
          <Row className="mt-3">
            <Col>
              <p className="mb-0"><b>Why are fees so much higher on decentralized exchanges?</b></p>
              <p>Unfortunately, as this new tech has become available, many of these platforms are built on the Ethereum network which has extremely high fees to process transactions. We do suspect these fees to go down to the point of being competitive, if not better, than that of centralized exchanges in the near future. This is also why Coinbag has focused heavily on our rebalancing algorithms to find the optimal trading path to minimize fees while maintaining portfolio accuracy.</p>
            </Col>
          </Row>
          <Row className="mt-4 text-center">
            <Col>
              <button className="btn-inverse btn-block" onClick={() => this.props.history.push(ChooseExchange.routePath)}>
                Back to exchange selection
              </button>
            </Col>
          </Row>
        </Container>

        <Footer />

      </>
    );
  }
}
