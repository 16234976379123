import { createStyles, makeStyles } from '@material-ui/core';
import { InfoBanner } from 'elements/InfoBanner';
import React, { FC } from 'react';

import { chainIdMapper } from '../mappers';

interface WithdrawWarningBannerProps {
  selectedMasterCoin: string;
  selectedNetwork: string;
}

const useStyles = makeStyles(() => createStyles({
  withdrawWarningBanner: {
    background: '#FFFBEC',
    lineHeight: '16px',
    color: '#DBA400',
  },
}));

export const WithdrawWarningBanner: FC<WithdrawWarningBannerProps> = (props) => {
  const classes = useStyles();
  return (
    <InfoBanner
      modifier={classes.withdrawWarningBanner}
      title={`Ensure the receiving address can receive
      ${props.selectedMasterCoin} on ${chainIdMapper[props.selectedNetwork][1]}`}
      description={`Sending any coin other than ${props.selectedMasterCoin} on any
      network other than ${chainIdMapper[props.selectedNetwork][1]} may result in a loss of your funds.`}
      icon="icons/withdraw/warning_icon_yellow.svg"
    />
  );
};
