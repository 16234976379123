import React from "react";

export default class ProgressRing extends React.Component {
  constructor(props) {
    super(props);

    const { radius, stroke } = this.props;

    this.normalizedRadius = radius - stroke * 2;
    this.circumference = this.normalizedRadius * 2 * Math.PI;
  }

  render() {
    const { radius, stroke, progress } = this.props;

    const strokeDashoffset = this.circumference - progress / 100 * this.circumference;
    const strokeDashoffset2 = this.circumference - (100 - progress) / 100 * this.circumference;

    return (
      <svg
        height={radius * 2}
        width={radius * 2}
        {...this.props}
      >
        <g>
          <circle
            transform={"rotate(" + -90 + " " + radius + " " + radius + ")"}
            stroke="#E9EBEE"
            fill="transparent"
            strokeWidth={stroke}
            strokeDasharray={this.circumference + ' ' + this.circumference}
            style={{ strokeDashoffset: 0, transition: "stroke-dashoffset 0.35s, rotate 0.35s"}}
            r={this.normalizedRadius}
            cx={radius}
            cy={radius}
          />
        </g>
        <g>
          <circle
            id = "risk-circle"
            stroke="#10AC84"
            fill="transparent"
            strokeWidth={stroke}
            strokeLinejoin="round"
            strokeDasharray={this.circumference + ' ' + this.circumference}
            style={{ strokeDashoffset }}
            r={this.normalizedRadius}
            cx={radius}
            cy={radius}
          />
          <g>
            <text id="risk-circle-text" x="50%" y="50%" textAnchor="middle" strokeWidth="2px" dy=".3em">
              {progress == 100? 100/10 : progress === 0? 0 :(progress/10).toFixed(1)}
              <tspan>/10</tspan>
            </text>
          </g>
        </g>

      </svg>
    );
  }
}

//   class Example extends React.Component {
//     constructor(props) {
//       super(props);

//       this.state = {
//         progress: 0
//       };
//     }

//     componentDidMount() {
//       // emulating progress
//       const interval = setInterval(() => {
//         this.setState({ progress: this.state.progress + 10 });
//         if (this.state.progress === 100)
//           clearInterval(interval);
//       }, 1000);
//     }

//     render() {
//       return (
//         <ProgressRing
//           radius={ 60 }
//           stroke={ 4 }
//           progress={ this.state.progress }
//         />
//       );
//     }
//   }
