import { Box, createStyles } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import React, { useEffect, useMemo } from 'react';
import { BatchPayrollRoute } from 'routes';
import { WorkItemName, WorkItemStatus } from 'services/types/workItem';
import { useStore as useWalletStore } from 'store/zustand/Wallet';
import { useStore as useWorkItemStore } from 'store/zustand/WorkItem';

import { AccountDetailsSection } from './AccountDetailsSection';
import { InProgressSection } from './InProgressSection';
import { PaymentSection } from './PaymentSection';
import { RebalancingInProgress } from './RebalancingInProgress';

const useStyles = makeStyles((theme) => createStyles({
  root: {
    display: 'flex',
    flexDirection: 'column',
    padding: 24,
    width: '100%',
    height: '100%',
    [theme.breakpoints.down('sm')]: {
      padding: 0,
      gap: 16,
    },
  },
  content: {
    display: 'flex',
    width: '100%',
    justifyContent: 'space-between',
    gap: 16,
    flexGrow: 1,
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column-reverse',
    },
  },
  addressBookIconWrapper: {
    width: 20,
    height: 20,
  },
  mainSection: {
    display: 'flex',
    flexDirection: 'column',
    gap: 16,
    width: '100%',
    flexGrow: 1,
  },
}));

const BatchPayroll = () => {
  const classes = useStyles();

  React.useEffect(() => {
    if (window.Tawk_API && window.Tawk_API.hideWidget) window.Tawk_API.hideWidget();
    return function cleanup() {
      if (window.Tawk_API && window.Tawk_API.showWidget) window.Tawk_API.showWidget();
    };
  }, []);

  const [rebalancingStatus] = useWalletStore((state) => [state.rebalancingStatus]);
  const [workItems, fetchWorkItems] = useWorkItemStore((state) => [state.workItems, state.fetchWorkItems]);

  useEffect(() => { fetchWorkItems(true); }, []);

  const withdrawWorkItem = useMemo(() => {
    if (!workItems || workItems.length === 0) return undefined;
    return workItems.find(
      (item) => (item.name === WorkItemName.Withdrawal || item.name === WorkItemName.WithdrawalToken)
        && (item.status === WorkItemStatus.InProgress || item.status === WorkItemStatus.Pending || item.status === WorkItemStatus.WaitingForApproval),
    );
  }, [workItems]);

  return (
    <Box className={classes.root}>
      <Box className={classes.content}>
        <Box className={classes.mainSection}>
          {withdrawWorkItem || rebalancingStatus
            ? (<RebalancingInProgress withdrawWorkItem={withdrawWorkItem} rebalancingStatus={rebalancingStatus} />)
            : (
              <>
                <InProgressSection />
                <PaymentSection />
              </>
            )}
        </Box>
        <AccountDetailsSection />
      </Box>
    </Box>
  );
};

BatchPayroll.routePath = BatchPayrollRoute;
BatchPayroll.loginRequired = true;
BatchPayroll.feature = 'batchPayroll';
BatchPayroll.redirect = 'batchPayrollRedirect';

export default BatchPayroll;
