import { create } from 'zustand';

import authService from '../../services/auth-service';
import { useStore as accountSettingStore } from './AccountSetting';

interface OtpStore {
  secretKey: string | null;
  code: string | null;
  error: boolean;
  errorText: string;
  enable2FaButton: boolean;

  getSecretKey(): Promise<void> ;
  resetSecretKey(): void ;
  // eslint-disable-next-line no-unused-vars
  onCodeChange(code: string): void;
  create2FA(): Promise<boolean>;
}

export const useStore = create<OtpStore>((set, get) => ({
  secretKey: null,
  code: null,
  error: false,
  errorText: '',
  enable2FaButton: true,

  getSecretKey: async () => {
    const response = await authService.generateSecretKey();
    set({ secretKey: response.data.secretKey });
  },
  resetSecretKey: () => {
    set({ secretKey: null, code: null, error: false, errorText: '', enable2FaButton: true });
  },
  onCodeChange: (code: string) => {
    set({ code });
  },
  create2FA: async () => {
    const { secretKey, code } = get();
    if (secretKey == null || code == null) return false;

    set({ enable2FaButton: false });

    try {
      await authService.addSecretKey(secretKey, code);
      await accountSettingStore.getState().fetchAndSyncUserData();
      set({ secretKey: null, code: null, error: false, errorText: '' });
    } catch (err) {
      set({ error: true, errorText: 'Invalid 2FA code' });
      return false;
    } finally {
      set({ enable2FaButton: true });
    }

    return true;
  },
}));
