import { Box, createStyles, makeStyles } from '@material-ui/core';
import React from 'react';

import { NotificationItemBase } from './NotificationType';
import TransactionApprovalItem, { TransactionApprovalItemProps } from './TransactionApprovalItem';
import UserEventItem, { UserEventItemProps } from './UserEventItem';

const useStyles = makeStyles((theme) => createStyles({
  root: {
    display: 'flex',
    padding: '0 16px',
    width: '100%',
    cursor: 'pointer',
    position: 'relative',
    transition: 'all 0.2s',
    '&:hover': {
      background: theme.palette.grey[100],
      '& $close': {
        opacity: 1,
      },
    },
  },
}));

interface NotificationItemProps {
  className?: string;
}

export const NotificationItem = React.memo(<T extends NotificationItemBase>(props: T & NotificationItemProps) => {
  const classes = useStyles();

  const item = React.useMemo(() => {
    switch (props.type) {
      case 'UserEvent':
        return <UserEventItem {...props as unknown as UserEventItemProps} />;
      default:
        return <TransactionApprovalItem {...props as unknown as TransactionApprovalItemProps} />;
    }
  }, [props]);

  return (
    <Box className={`${classes.root} ${props.className || ''}`}>
      {item}
    </Box>
  );
});

NotificationItem.displayName = 'NotificationItem';

export default NotificationItem;
