import axios, { AxiosResponse } from 'axios';

import Headers from './headers';
import { ChainDetails } from './types/chain';

const API_URL = '/api/chain/';

const getBlockchains = (): Promise<ChainDetails[]> => axios.get(`${API_URL}getBlockchains`,
  { headers: { ...Headers.def(), ...Headers.auth() } })
  .then((response: AxiosResponse<ChainDetails[]>) => response.data);

export default {
  getBlockchains,
};
