import { Box, createStyles, makeStyles } from '@material-ui/core';
import NotificationItem from 'components/Notifications/NotificationItem';
import { TransactionApprovalItemProps } from 'components/Notifications/TransactionApprovalItem';
import { Button, LightModal, Typography } from 'elements';
import React from 'react';
import { useHistory } from 'react-router-dom';
import { NotificationsAndRequestsRoute } from 'routes';
import { WorkItem } from 'services/types/workItem';
import { useStore as useUserStore } from 'store/zustand/User';

interface LoginApprovalModalProps {
  // eslint-disable-next-line no-unused-vars
  setOpen: (value: boolean) => void;
  open: boolean;
  items: WorkItem[];
  // eslint-disable-next-line no-unused-vars
  setModalItem: (id: number) => void;
  // eslint-disable-next-line no-unused-vars
  setModalOpen: (value: boolean) => void;
  // eslint-disable-next-line no-unused-vars
  setNotificationsOpen: (value: boolean) => void;
}

const useStyles = makeStyles((theme) => createStyles({
  root: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    gap: 8,
    paddingTop: 16,
    '& > h3': {
      fontSize: 16,
    },
  },
  items: {
    display: 'flex',
    flexDirection: 'column',
    '& > *': {
      borderTop: `1px solid ${theme.palette.grey[200]}`,
    },
  },
  item: {
    '& > *': {
      borderBottom: 'none',
    },
  },
  viewAll: {
    padding: 16,
    display: 'flex',
    justifyContent: 'space-between',
    '& bundle': {
      minWidth: 116,
    },
  },
}));

const LoginApprovalModal = (props: LoginApprovalModalProps) => {
  const classes = useStyles();
  const history = useHistory();

  const [user] = useUserStore((store) => [store.user]);
  const approvalItems = React.useMemo(() => props.items
    .map((item): TransactionApprovalItemProps => ({
      item,
      creation: new Date(item.lastUpdatedTs),
      type: 'transactionApproval',
      onSeeDetail: () => {
        props.setModalItem(item.workItemId);
        props.setModalOpen(true);
        props.setOpen(false);
      },
    })), [props.items]);

  return (
    <LightModal
      open={props.open}
      setOpen={props.setOpen}
      showCloseButton
      maxWidth={520}
      noPadding
    >
      <Box className={classes.root}>
        <Typography align="center" paletteColor={800} variant="h3">
          Welcome back{user?.firstName || user?.companyName ? `, ${user.firstName || user.companyName}!` : ''}
        </Typography>
        <Typography align="center" paletteColor={700} variant="h4">
          {approvalItems.length} new request(s) await your approval
        </Typography>
      </Box>
      <Box className={classes.items}>
        {approvalItems
          .slice(0, 2)
          .map((i, index) => (
            <NotificationItem
              className={classes.item}
              key={index}
              {...i}
            />
          ))}
        <Box className={classes.viewAll}>
          <Button variant="secondary" onClick={() => props.setOpen(false)}>Cancel</Button>
          <Button onClick={() => { props.setOpen(false); history.push(NotificationsAndRequestsRoute); }}>
            View all
          </Button>
        </Box>
      </Box>
    </LightModal>
  );
};

export default LoginApprovalModal;
