import { Box, createStyles, makeStyles,MenuItem } from '@material-ui/core';
import {
  Checkbox,
  InputWithTitle, LightAutocomplete, LightTextField, Typography,
} from 'elements';
import { LightFrenchFries } from 'elements/LightFrenchFries';
import { LightSelect } from 'elements/LightSelect';
import pluralize from 'pluralize';
import React, { useMemo } from 'react';
import SVG from 'react-inlinesvg';
import { useStore as useUserStore } from 'store/zustand/User';
import { useStore as useWalletStore } from 'store/zustand/Wallet';

import { AddressOptionRow } from './AddressOptionRow';

interface AddressInputProps {
  trackMyAddress?: boolean;
  trackAddress: string[];
  // eslint-disable-next-line no-unused-vars
  setTrackAddress: (value: string[]) => void;
  selectAddress: { [address: string]: boolean };
  // eslint-disable-next-line no-unused-vars
  setSelectAddress: React.Dispatch<React.SetStateAction<{ [address: string]: boolean }>>;
  // eslint-disable-next-line no-unused-vars
  setShowAddAddressModal: (value: boolean) => void;
}

const useStyles = makeStyles((theme) => createStyles({
  selectMenuItem: {
    '&.Mui-selected': {
      backgroundColor: theme.palette.common.white,
      '&:hover': {
        backgroundColor: theme.palette.green[100],
      },
    },
    borderBottom: `1px solid ${theme.palette.grey[200]}`,
    alignItems: 'center',
    justifyContent: 'flex-start',
    width: '100%',
    display: 'flex',
    flexWrap: 'nowrap',
    gap: 8,
  },
  selectAllMenuItem: {
    display: 'flex',
    alignItems: 'center',
    gap: 8,
    backgroundColor: theme.palette.grey[100],
    borderBottom: `1px solid ${theme.palette.grey[200]}`,
  },
  label: {
    flexWrap: 'nowrap',
    alignItems: 'center',
    display: 'flex',
    overflow: 'hidden',
    minWidth: 0,
  },
  tags: {
    display: 'flex',
    flexWrap: 'nowrap',
    gap: 8,
    alignItems: 'center',
    overflow: 'hidden',
    position: 'relative',
    minWidth: 0,
    paddingRight: 8,
  },
  countText: {
    flexShrink: 0,
    display: 'flex',
    paddingLeft: 4,
    position: 'relative',
    alignSelf: 'stretch',
    alignItems: 'center',
    '&:after': {
      content: '" "',
      position: 'absolute',
      zIndex: 1,
      top: 0,
      left: -8,
      pointerEvents: 'none',
      background: 'linear-gradient(to right, rgba(0, 0, 0, 0), white)',
      width: 8,
      height: '100%',
    },
  },
}));

export const AddressInput = React.memo(({
  trackMyAddress, trackAddress, selectAddress, setSelectAddress, setTrackAddress, setShowAddAddressModal,
}: AddressInputProps) => {
  const classes = useStyles();
  const [user] = useUserStore((state) => [state.user, state.fetchUser]);
  const [walletInfos] = useWalletStore((state) => [state.walletInfos]);
  const [addressInputError, setAddressInputError] = React.useState<boolean>(false);

  const selectedList = React.useMemo(
    () => Object.keys(selectAddress).filter((val) => selectAddress[val]), [selectAddress]
  );

  const selectAddressText = React.useMemo(() => {
    const addressCount = selectedList.length;
    const walletName = selectedList
      .map((address) => {
        const wi = walletInfos?.find((info) => info.address?.includes(address));
        return wi?.name || wi?.walletProvider || '';
      });
    const tags = walletName
      .filter((tag, index) => tag && walletName.indexOf(tag) === index);
    return (
      <Box className={classes.label}>
        <Box className={classes.tags}>
          {tags.map((tag) => <LightFrenchFries key={tag} noShrink label={tag} autoAssignColor />)}
        </Box>
        {addressCount === 1 && (
          <Typography variant="h6" paletteColor={500} middleTruncation>{Object.keys(selectAddress)[0]}</Typography>
        )}
        {addressCount > 1 && (
          <Box className={classes.countText}>
            <Typography variant="h6" paletteColor={700}>
              {`${addressCount} ${pluralize('address', addressCount)} selected`}
            </Typography>
          </Box>
        )}
      </Box>
    );
  }, [selectedList, walletInfos]);

  const selectAllState = useMemo(() => {
    const selectStates = Object.entries(selectAddress)
      .map(([, isChecked]) => isChecked);
    const isAllChecked = selectStates.reduce((prev, curr) => prev && curr, true);
    const indeterminate = !isAllChecked && selectStates.includes(true);
    return { isAllChecked, indeterminate };
  }, [selectAddress]);

  const handleSelectAll = () => {
    const allAddresses = Object.entries(selectAddress).map(([address]) => address);
    const newState = !selectAllState.isAllChecked;
    const newAddressState = allAddresses.reduce((prev, curr) => ({ ...prev, [curr]: newState }), {});
    setSelectAddress(newAddressState);
  };

  return (trackMyAddress ? (
    <InputWithTitle
      title=""
      titleRightItem={
        addressInputError &&
        <Typography strong={false} align="right" palette="red" paletteColor={600}>Invalid wallet address</Typography>
      }
    >
      <LightAutocomplete
        renderInput={(params) => (
          <LightTextField
            {...params}
            placeholder={(trackAddress.length === 0) ? 'Enter Addresses' : ''}
            error={addressInputError}
          />
        )}
        multiple
        value={trackAddress}
        limitTags={0}
        getLimitTagsText={() => `${trackAddress.length} ${pluralize('address', trackAddress.length)} selected`}
        getOptionLabel={(ta) => ta}
        options={[...(user?.walletInfo.map((wi) => wi.address ?? []).flat() ?? []), 'addWallet']}
        onBlur={() => { setAddressInputError(false); }}
        renderOption={(option) => {
          if (option !== 'addWallet') {
            return (<>{option}</>);
          }
          return (
            <Typography component="span" display="inline" variant="h6" palette="green" noWrap>
              <SVG width={20} height={20} src="icons/reporting/ic_add_item.svg" /> add address
            </Typography>
          );
        }}
        renderTags={() => [(<></>)]}
        filterOptions={(addresses: string[], state) => {
          if (state.inputValue === '') return addresses;
          return [...addresses.filter((a) => a.startsWith('0x') && a.startsWith(state.inputValue)), 'addWallet'];
        }}
        onChange={async (event, values, optionAction, change) => {
          if (change?.option === 'addWallet') {
            setShowAddAddressModal(true);
          } else {
            setTrackAddress(values);
            setSelectAddress(Object.fromEntries(values.map((v) => [v.toLowerCase(), true])));
          }
        }}
      />
    </InputWithTitle>
  ) : (
    <LightSelect
      value={selectedList}
      placeholder="Select reporting address"
      displayEmpty
      renderValue={selectedList.length > 0 ? () => selectAddressText : undefined}
      multiple
      size="medium"
    >
      <MenuItem className={classes.selectAllMenuItem}>
        <Checkbox
          checked={selectAllState.isAllChecked}
          indeterminate={selectAllState.indeterminate}
          onClick={handleSelectAll}
        />
        <Typography variant="h6" palette="grey" paletteColor={700}>Your addresses</Typography>
      </MenuItem>
      {walletInfos
        ?.filter((wi) => wi.status === 'Active')
        .map((wi) => wi.address?.map((wa) => ({ address: wa, name: (wi.name ?? wi.walletProvider) })))
        .flat()
        .filter((i) => i !== undefined)
        .map((item, index) => (
          <MenuItem
            key={index}
            value={item?.address}
            className={classes.selectMenuItem}
            onClick={() => setSelectAddress((prev) =>
              ({ ...prev, [item?.address!]: !(prev[item?.address!] ?? false) }))}
          >
            <AddressOptionRow
              address={item?.address ?? ''}
              checked={selectAddress[item?.address!] ?? false}
              tags={[item?.name ?? '']}
            />
          </MenuItem>
        ))}
    </LightSelect>
  ));
});

AddressInput.displayName = 'AddressInput';
