import React, { Component } from "react";
import { Footer } from "../../components/Footer.js";
import { InternalNavbar } from "../../components/navbar.js";
import { Container, Row, Col } from 'react-bootstrap'
import Loader from "../../components/Loader.js";
import Logger from "js-logger";
import BinanceConnect from "../../components/BinanceConnect.js";
import { DashboardRoute } from "routes";

export default class EditConnection extends Component {
  static routePath = '/edit-connection';
  static loginRequired = true;

  constructor(props) {
    super(props);
    this.state = {
      loading: true
    }
  }

  render() {
    Logger.log(this.state)

    return (
      <>
       <Loader loading={this.state.loading} />
        <div style={{ minHeight: "calc( 100vh - 1.5rem - 163px - 120px)" }}>
          <InternalNavbar history={this.props.history} title="Exchange management" />
          <Container className="p-3">
            <Row>
              <Col>
                <p className="mb-0">
                  We use your API keys to securely connect to Binance and manage your portfolio.
                </p>
              </Col>
            </Row>
          </Container>
          <BinanceConnect history={this.props.history} location={this.props.location} editMode={true} onLoadComplete={() => this.setState({ loading: false })} editExchange={false}/>
          <Container>
            <Row>
              <Col className="text-center">
                <a onClick={() => this.props.history.push(DashboardRoute)}>
                  Back to dashboard
                </a>
              </Col>
            </Row>
          </Container>
          </div>
          <Footer />

      </>
    );
  }
}
