// @flow
import { Box, MenuItem } from '@material-ui/core';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import React from 'react';
import { ShowroomRoute } from 'routes';

import { Button, Card, Checkbox, Dropdown, LightTextField, LightToggleGroup, LightTooltip, Typography } from '../../elements';
import { CheckBoxWithText } from '../../elements/CheckBoxWithText';
import { CoinIconBadge } from '../../elements/CoinIconBadge';
import { InfoBanner } from '../../elements/InfoBanner';
import { Switch } from '../../elements/Switch';

const useStyles = makeStyles(() => createStyles({
  root: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    background: 'white',
    width: '100%',
    zIndex: 1,
    padding: '16px',
    flexDirection: 'column',
    gap: 16,
  },
  content: {
    display: 'flex',
    width: '100%',
    maxWidth: '800px',
    flexDirection: 'column',
    padding: 16,
    gap: 8,
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    gap: 8,
    alignItems: 'center',
    justifyContent: 'space-around',
  },
  yellowBanner: {
    background: '#FFFBEC',
    lineHeight: '16px',
    color: '#DBA400',
  },
}));

export const Showroom = () => {
  const classes = useStyles();
  const [isChecked, setIsChecked] = React.useState<boolean>(false);
  const dropdownContent = (
    <Box>
      <MenuItem>Hello</MenuItem>
      <MenuItem>Guten Tag</MenuItem>
    </Box>
  );

  return (
    <Box className={classes.root}>
      <Box className={classes.content}>
        <Typography variant="h1">Button</Typography>
        <Typography variant="h3">Primary</Typography>
        <Box className={classes.row}>
          <Button size="noSpace"> noSpace</Button>
          <Button size="sm"> Small</Button>
          <Button>Medium</Button>
          <Button size="xmd">xMedium</Button>
          <Button size="lg">Large</Button>
          <Button disabled>Disabled</Button>
        </Box>

        <Typography variant="h3">Secondary</Typography>
        <Box className={classes.row}>
          <Button size="noSpace">noSpace</Button>
          <Button variant="secondary" size="sm">Small</Button>
          <Button variant="secondary">Medium</Button>
          <Button variant="secondary" size="xmd">xMedium</Button>
          <Button variant="secondary" size="lg">Large</Button>
          <Button variant="secondary" disabled>Disabled</Button>
        </Box>

        <Typography variant="h3">Tertiary</Typography>
        <Box className={classes.row}>
          <Button variant="tertiary" size="noSpace">noSpace</Button>
          <Button variant="tertiary" size="sm">Small</Button>
          <Button variant="tertiary">Medium</Button>
          <Button variant="tertiary" size="xmd">xMedium</Button>
          <Button variant="tertiary" size="lg">Large</Button>
          <Button variant="tertiary" disabled>Disabled</Button>
        </Box>

        <Typography variant="h3">Outline</Typography>
        <Box className={classes.row}>
          <Button variant="outline" size="noSpace">noSpace</Button>
          <Button variant="outline" size="sm">Small</Button>
          <Button variant="outline">Medium</Button>
          <Button variant="outline" size="xmd">xMedium</Button>
          <Button variant="outline" size="lg">Large</Button>
          <Button variant="outline" disabled>Disabled</Button>
        </Box>

        <Typography variant="h3">Light</Typography>
        <Box className={classes.row}>
          <Button variant="light" size="noSpace">noSpace</Button>
          <Button variant="light" size="sm">Small</Button>
          <Button variant="light">Medium</Button>
          <Button variant="light" size="xmd"> xMedium</Button>
          <Button variant="light" size="lg">Large</Button>
          <Button variant="light" disabled>Disabled</Button>
        </Box>

      </Box>
      <Box className={classes.content}>
        <Typography variant="h1">Card</Typography>
        <Card>
          <Typography variant="h6">card content</Typography>
        </Card>
        <Card isLoading>
          <Typography variant="h6">isLoading</Typography>
        </Card>
      </Box>
      <Box className={classes.content}>
        <Typography variant="h1">Checkbox</Typography>
        <Box>
          <Checkbox />
        </Box>
      </Box>
      <Box className={classes.content}>
        <Typography variant="h1">CheckBoxWithText</Typography>
        <CheckBoxWithText isChecked={isChecked} setIsChecked={setIsChecked}>
          <Typography variant="h6">Checkbox with text</Typography>
        </CheckBoxWithText>
      </Box>
      <Box className={classes.content}>
        <Typography variant="h1">Typography</Typography>
        <Typography variant="h6" palette="green" paletteColor={500}>
          Green colot
        </Typography>
        <Typography variant="h6" strong={false} uppercase>
          Uppercase
        </Typography>
        <Typography variant="h6" align="center">
          Align center
        </Typography>
      </Box>
      <Box className={classes.content}>
        <Typography variant="h1">CoinIconBadge</Typography>
        <Box width={24} height={24}>
          {
            // eslint-disable-next-line max-len 
          } <CoinIconBadge src={`<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"> <path fill-rule="evenodd" clip-rule="evenodd" d="M18.3939 5.74002L13.1818 3.50624C12.4271 3.18282 11.5729 3.18282 10.8182 3.50624L5.60608 5.74002C5.2384 5.8976 5 6.25914 5 6.65917V11.9121C5 13.8498 5.93579 15.6682 7.51257 16.7945L10.8375 19.1694C11.5329 19.6661 12.4671 19.6661 13.1625 19.1694L16.4874 16.7945C18.0642 15.6682 19 13.8498 19 11.9121V6.65917C19 6.25914 18.7616 5.8976 18.3939 5.74002ZM15.7809 9.62469C16.1259 9.19343 16.056 8.56414 15.6247 8.21913C15.1934 7.87412 14.5641 7.94404 14.2191 8.37531L10.9171 12.5029L9.70711 11.2929C9.31658 10.9024 8.68342 10.9024 8.29289 11.2929C7.90237 11.6834 7.90237 12.3166 8.29289 12.7071L9.89788 14.3121C10.53 14.9443 11.5714 14.8866 12.1298 14.1885L15.7809 9.62469Z"
      fill="black"/></svg>`}
          />
        </Box>
      </Box>
      <Box className={classes.content}>
        <Typography variant="h1">Dropdown</Typography>
        <Dropdown title="No Icon">{dropdownContent}</Dropdown>
        <Dropdown anchorPosition="left" title="Left" iconSrc="/icons/dashboard/ic_flag.svg">
          {dropdownContent}
        </Dropdown>
        <Dropdown title="Center" iconSrc="/icons/dashboard/ic_flag.svg">
          {dropdownContent}
        </Dropdown>
        <Dropdown anchorPosition="right" title="Right" iconSrc="/icons/dashboard/ic_flag.svg">
          {dropdownContent}
        </Dropdown>
      </Box>
      <Box className={classes.content}>
        <Typography variant="h1">LightTextField</Typography>
        <LightTextField placeholder="small" size="small" />
        <LightTextField placeholder="medium" size="medium" />
        <LightTextField placeholder="error" error />
        <LightTextField placeholder="disabled" disabled />
      </Box>
      <Box className={classes.content}>
        <Typography variant="h1">LightTooltip</Typography>
        <Box className={classes.row}>
          <LightTooltip title="content" open placement="right"><Box><Typography>tooltip</Typography></Box></LightTooltip>
          <LightTooltip title="content" arrow open placement="right"><Box><Typography>tooltip with arrow</Typography></Box></LightTooltip>
        </Box>
      </Box>
      <Box className={classes.content}>
        <Typography variant="h1">InfoBanner</Typography>
        <InfoBanner
          modifier={`${classes.yellowBanner}`}
          title="Banner title"
          description="content"
          icon="/icons/withdraw/pending_icon_blue.svg"
        />
        <InfoBanner
          modifier={`${classes.yellowBanner}`}
          description="content without title"
          icon="/icons/withdraw/pending_icon_blue.svg"
        />
      </Box>
      <Box className={classes.content}>
        <Typography variant="h1">Switch</Typography>
        <Switch
          color="primary"
          onChange={() => setIsChecked(!isChecked)}
          checked={isChecked}
        />
        <Switch
          color="primary"
          onChange={() => setIsChecked(!isChecked)}
          checked={!isChecked}
        />
      </Box>
      <Box className={classes.content}>
        <Typography variant="h1">ToggleGroup</Typography>
        <LightToggleGroup
          value={isChecked ? '1' : '2'}
          onChange={() => setIsChecked(!isChecked)}
          values={[{ value: '1', title: 'Option 1' }, { value: '2', title: 'Option 2' }]}
          exclusive
        />
      </Box>
    </Box>
  );
};

Showroom.routePath = ShowroomRoute;
Showroom.loginRequired = false;
