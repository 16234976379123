import { createStyles } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import React, { useRef } from 'react';

import { Menu } from './Menu';

interface Props {
  parent: React.ReactNode;
  children: React.ReactNode;
  className?: string;
  open: boolean;
  setOpen: (value: boolean) => void
}

const useStyles = makeStyles(() => createStyles({
  root: {
    cursor: 'pointer',
  },
  paper: {
    boxShadow: '0px 4px 32px rgba(105, 107, 122, 0.24)',
  },
  menuList: {
    '& > *:first-child': {
      borderTop: 'none',
    },
    '& > *:last-child': {
      borderBottom: 'none',
    },
  },
}));

export const LightMenu = ({ parent, children, className, open, setOpen }: Props) => {
  const classes = useStyles();
  const anchorEl = useRef<HTMLDivElement>(null);

  const handleOnClick = () => {
    setOpen(true);
  };

  return (
    <>
      <div
        onClick={handleOnClick}
        className={`${classes.root} ${className ?? ''}`}
        ref={anchorEl}
      >
        {parent}
      </div>
      <Menu
        anchorEl={anchorEl.current}
        getContentAnchorEl={null}
        open={open}
        onClose={() => { setOpen(false); }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: -8,
          horizontal: 'right',
        }}
        PaperProps={
          { className: classes.root }
        }
        MenuListProps={
          { className: classes.menuList }
        }
      >
        {children}
      </Menu>
    </>
  );
};
