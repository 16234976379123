import { Box, Hidden, makeStyles } from '@material-ui/core';
import * as React from 'react';
import { HomeRoutes } from 'routes';

import Hero from './Hero';
import { MediaBanner } from './MediaBanner';
import PartnerBanner from './PartnerBanner';

const useStyles = makeStyles((theme) => ({
  root: {
    position: 'relative',
    overflow: 'hidden',

    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',

    background: 'white',

    '& a:hover': {
      textDecoration: 'none',
    },
    '& em': {
      color: theme.palette.blue[600],
      fontStyle: 'normal',
    },
    '& h1': {
      fontSize: 24,
      fontWeight: 700,
      lineHeight: '180%',
      [theme.breakpoints.up('sm')]: {
        fontSize: 36,
        lineHeight: '170%',
      },
      [theme.breakpoints.up('lg')]: {
        fontSize: 44,
        lineHeight: '160%',
      },
    },
  },
  content: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',

    padding: '0 16px',
    maxWidth: 1080,
    width: '100%',

    [theme.breakpoints.down('sm')]: {
      maxWidth: 640,
    },

    [theme.breakpoints.up('xl')]: {
      maxWidth: 1366,
    },
  },
  mediaContent: {
    position: 'relative',
    zIndex: 0,
    rowGap: 40,
    [theme.breakpoints.up('lg')]: {
      rowGap: 60,
    },
  },
  mediaGradient: {
    position: 'absolute',
    left: 0,
    top: '50%',
    height: '60vh',
    width: '80vw',
    transform: 'translateX(-60%) translateY(-50%) translateZ(0)',
    background: 'radial-gradient(48.42% 48.42% at 50% 50%, rgba(110, 158, 254, 0.48) 0%, rgba(235, 255, 252, 0) 100%)',
    filter: 'blur(33.9499px)',
    [theme.breakpoints.down('md')]: {
      display: 'none',
    },
  },
}));

const VersusSection = React.lazy(() => import('./Versus/VersusSection'));
const DashboardFeatures = React.lazy(() => import('../../../components/Marketing/DashboardFeatures/DashboardFeatures'));
const LearnMoreAboutCoinbag = React.lazy(() => import('../../../components/Marketing/LearnMoreAboutCoinbag'));
const Supported = React.lazy(() => import('../../../components/Marketing/Supported'));

const Cta = React.lazy(() => import('./Cta'));
const AccountSetup = React.lazy(() => import('./AccountSetup'));
const RiskMitigation = React.lazy(() => import('./RiskMitigation'));
const TransactionTracking = React.lazy(() => import('./TransactionTracking'));
const BatchPayroll = React.lazy(() => import('./BatchPayroll'));
const WalletOperations = React.lazy(() => import('./WalletOperations/WalletOperationsSection'));
const WalletPermissions = React.lazy(() => import('./WalletPermission'));
const ClientPartner = React.lazy(() => import('./ClientPartner'));
const Pricing = React.lazy(() => import('./Pricing/Pricing'));

const Home = () => {
  const classes = useStyles();
  return (
    <main className={classes.root}>
      <Hero />

      <Box marginTop="40px" />
      <Box className={`${classes.content} ${classes.mediaContent}`}>
        <Box className={classes.mediaGradient} />
        {/* <SecurityBanner /> */}
        <MediaBanner />
        <PartnerBanner />
      </Box>

      <React.Suspense fallback={<></>}>
        <Hidden smDown><Box marginTop="80px" /></Hidden>
        <Hidden mdUp><Box marginTop="40px" /></Hidden>
        <Cta variant="green" />
      </React.Suspense>

      <React.Suspense fallback={<></>}>
        <Hidden mdUp><Box marginTop="40px" /></Hidden>
        <AccountSetup />
      </React.Suspense>

      <React.Suspense fallback={<></>}>
        <Hidden mdUp><Box marginTop="60px" /></Hidden>
        <RiskMitigation />
      </React.Suspense>

      <React.Suspense fallback={<></>}>
        <Hidden mdUp><Box marginTop="40px" /></Hidden>
        <WalletPermissions />
      </React.Suspense>

      <React.Suspense fallback={<></>}>
        <Hidden mdUp><Box marginTop="40px" /></Hidden>
        <Box className={classes.content}>
          <WalletOperations />
        </Box>
      </React.Suspense>

      <React.Suspense fallback={<></>}>
        <Hidden mdUp><Box marginTop="40px" /></Hidden>
        <BatchPayroll />
      </React.Suspense>

      <React.Suspense fallback={<></>}>
        <Hidden mdUp><Box marginTop="60px" /></Hidden>
        <TransactionTracking />
      </React.Suspense>

      <React.Suspense fallback={<></>}>
        <Box marginTop="40px" />
        <Cta variant="purple" />
      </React.Suspense>

      <React.Suspense fallback={<></>}>
        <Hidden mdUp><Box marginTop="40px" /></Hidden>
        <Box className={classes.content}>
          <ClientPartner />
        </Box>
      </React.Suspense>

      <React.Suspense fallback={<></>}>
        <Hidden mdUp><Box marginTop="80px" /></Hidden>
        <Pricing />
      </React.Suspense>

      <Box className={classes.content}>
        <Hidden lgUp><Box marginTop="80px" /></Hidden>
        <React.Suspense fallback={<></>}>
          <DashboardFeatures />
        </React.Suspense>

        <Hidden mdUp><Box marginTop="80px" /></Hidden>
        <React.Suspense fallback={<></>}>
          <VersusSection />
        </React.Suspense>

        <Hidden mdUp><Box marginTop="60px" /></Hidden>
        <React.Suspense fallback={<></>}>
          <Supported color="blue" />
        </React.Suspense>
      </Box>

      <React.Suspense fallback={<></>}>
        <Hidden lgUp><Box marginTop="60px" /></Hidden>
        <Box className={classes.content}>
          <LearnMoreAboutCoinbag color="blue" />
        </Box>
        <Hidden lgUp><Box marginTop="60px" /></Hidden>
      </React.Suspense>
    </main>
  );
};

Home.routePath = HomeRoutes;
Home.loginRequired = false;
Home.logoutRequired = true;
Home.allowedForAccessRevokedUser = true;
Home.displayName = 'Home';

export default Home;
