import { Card, Container } from '@material-ui/core';
import * as React from 'react';
import { Link } from 'react-router-dom';

import bundleService from '../../../services/bundle.service';

const Toolbox = () => {
  const [riskProfile, setRiskProfile] = React.useState(70);
  const [minusDays, setMinusDays] = React.useState(365);
  const [, setYtd] = React.useState<number | null>(null);

  React.useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    setRiskProfile(Number(event.target.value));
  }, []);

  React.useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    setMinusDays(Number(event.target.value));
  }, []);

  React.useCallback(async () => {
    console.log(`Risk profile: ${riskProfile}%`);
    console.log(`Minus days: ${minusDays}`);
    const ytd = await bundleService.getYtdVariable(riskProfile, minusDays);
    setYtd(ytd.data);
  }, [riskProfile, minusDays]);

  return (
    <Container fixed>
      <h1>Toolbox</h1>
      <Card>
        <h5>Links</h5>
        <ul>
          <li><Link to="/work-items">Work Items</Link></li>
          <li><Link to="/view-bundles">Bundles</Link></li>
          <li><Link to="/view-coins">Coins</Link></li>
          <li><Link to="/view-translations">Translations</Link></li>
        </ul>
      </Card>
    </Container>
  );
};

Toolbox.displayName = 'Toolbox';
Toolbox.routePath = '/toolbox';
Toolbox.loginRequired = true;
Toolbox.adminRequired = true;

export default Toolbox;
