import React, { Component } from 'react';
import { FIELD } from '../common';
import SharedStateComponent from './SharedStateComponent';

/*
    FormField have status like value, valid, connected...
*/
export default class FormField extends SharedStateComponent {
    constructor(props){
        super(props);

        this.updateValue = this.updateValue.bind(this);
        this.updateField = this.updateField.bind(this);
        this.distributeFieldVariables = this.distributeFieldVariables.bind(this)
        this.distributeFieldVariables();

        if (props.value !== undefined)
            this.updateValue(props.value)
    }

    updateValue(value){
        this.updateParentState({[this.name] : FIELD(value).ERROR(null)})
    }

    updateField(f){
        this.updateParentState({[this.name] : f})
    }

    distributeFieldVariables(){
        var field = this.getParentState(this.props.name)
        if (field === undefined){
            field = FIELD(undefined)
        }
        Object.keys(field).map(key => {
            this[key] = field[key]
        });
    }

    shouldComponentUpdate(){
        this.distributeFieldVariables()
        return true;
    }
}