import { Box } from '@material-ui/core';
import { Button, LightModal, Typography } from 'elements';
import React, { ReactNode, useState } from 'react';
import SVG from 'react-inlinesvg';
import { EmailCodePurpose } from 'services/types/email-code-purpose';
import { FA, FAResponseStatus } from 'services/types/wallet';

import { FAStatus } from '../services/types/wallet-enum';
import SubmitWithFA from './VerificationCode';

interface Props {
  title: string;
  detail: string | ReactNode;
  // eslint-disable-next-line no-unused-vars
  onSubmit: (fa: FA) => Promise<FAResponseStatus>;
  open: boolean;
  // eslint-disable-next-line no-unused-vars
  setOpen: (value: boolean) => void;
  emailParams: Record<string, string>;
  purpose?: EmailCodePurpose
  onClose?: () => void
}

export const FAModal = (
  {
    title,
    detail,
    onSubmit,
    open,
    setOpen,
    emailParams,
    purpose = EmailCodePurpose.Withdrawal,
    onClose = () => {},
  }: Props,
) => {
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const handleOnSubmit = async (fa: FA): Promise<FAResponseStatus> => {
    try {
      setIsSubmitting(true);
      const res = await onSubmit(fa);
      setIsSubmitting(false);
      return res;
    } catch (e) {
      setIsSubmitting(false);
      return { emailStatus: FAStatus.IncorrectOrExpired, authenticatorStatus: FAStatus.Unknown }
    }
  };

  return (
    <LightModal
      open={open}
      setOpen={setOpen}
      maxWidth={372}
      showCloseButton
      modalTitle={title}
      onClose={onClose}
    >
      <Box display="flex" flexDirection="column" gridRowGap="12px" width="100%">
        {typeof detail === 'string' ? (<Typography variant="h6" paletteColor={800} align="center">
          {detail}
        </Typography>) : detail}
        <Box display="flex" flexDirection="column" gridRowGap="16px">
          <SubmitWithFA
            email
            onSubmit={handleOnSubmit}
            submitButtonText={
              <>Approve <Box width={24} height={24}><SVG src="/icons/ic_shield.svg" width={24} height={24} /></Box></>
            }
            isSubmitting={isSubmitting}
            emailParams={emailParams}
            purpose={purpose}
          />
          <Button
            size="xmd"
            variant="secondary"
            onClick={() => {
              setOpen(false);
            }}
            fullWidth
            disabled={isSubmitting}
          >
            Deny
          </Button>
        </Box>
      </Box>
    </LightModal>
  );
};
