import { Box, createStyles } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Typography } from 'elements';
import React from 'react';
import SVG from 'react-inlinesvg';
import { Link } from 'react-router-dom';

const useStyles = makeStyles((theme) => createStyles({
  root: {
    position: 'sticky',
    top: 0,
    zIndex: 5,
    padding: '0.85rem 0',
    display: 'flex',
    justifyContent: 'center',
    backgroundColor: 'white',
    filter: 'drop-shadow(0px 4px 40px rgba(81, 89, 111, 0.1))',
  },
  nav: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
    maxWidth: 1200,
    padding: '0 24px',
    [theme.breakpoints.down('sm')]: {
      padding: '0 16px',
    },
  },
  logoWrapper: {
    display: 'flex',
    alignItems: 'center',
    gap: 8,
  },
  title: {
    whiteSpace: 'nowrap',
  },
  powerByCoinbag: {
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap',
    justifyContent: 'flex-end',
    '& a': {
      textDecoration: 'underline',
    },
  },
}));

export const WalletReportingHeader = () => {
  const classes = useStyles();
  return (
    <header className={classes.root}>
      <nav className={classes.nav}>
        <Link to="/">
          <Box className={classes.logoWrapper}>
            <Box>
              <SVG src="icons/wallet_reporting/ic_logo.svg" width={32} height={32} />
            </Box>
            <Typography className={classes.title} variant="h2" palette="grey" paletteColor={700}>Wallet Reporting</Typography>
          </Box>
        </Link>
        <Typography
          variant="h4"
          palette="grey"
          paletteColor={500}
          className={classes.powerByCoinbag}
        >Powered by&nbsp;<a href="https://coinbag.finance">Coinbag</a>
        </Typography>
      </nav>
    </header>
  );
};
