import { createTheme } from '@material-ui/core';
import { TypographyOptions } from '@material-ui/core/styles/createTypography';
import type {} from '@material-ui/lab/themeAugmentation';

import { breakpoints } from './base';

export const palette = {
  primary: {
    main: '#06D2AE', // 500
    dark: '#01A487', // 700
    light: '#8AF9E5', // 300
    50: '#F7FFFE',
    100: '#EBFFFC',
    200: '#D6FFF8',
    300: '#8AF9E5',
    400: '#37E2C3',
    500: '#06D2AE',
    600: '#02BD9F',
    700: '#01A487',
    800: '#007762',
    900: '#005243',
  },
  error: {
    main: '#FF6187', // 500
    dark: '#C81D47', // 700
    light: '#FFC5D3', // 300
    100: '#FFF5F7',
    200: '#FFE7EC',
    300: '#FFC5D3',
    400: '#FF92AC',
    500: '#FF6187',
    600: '#E93460',
    700: '#C81D47',
    800: '#950B2C',
    900: '#61061B',
  },
  grey: {
    100: '#F7F8FD',
    200: '#DEE3EF',
    300: '#BBC4DD',
    400: '#909CBD',
    500: '#6E7BA2',
    600: '#4E5A7C',
    700: '#38425E',
    800: '#283149',
    900: '#171C2B',
  },
  green: {
    50: '#F7FFFE',
    100: '#EBFFFC',
    200: '#D6FFF8',
    300: '#8AF9E5',
    400: '#37E2C3',
    500: '#06D2AE',
    600: '#02BD9F',
    700: '#01A487',
    800: '#007762',
    900: '#005243',
  },
  blue: {
    100: '#F4F8FF',
    200: '#E4EDFF',
    300: '#C9DBFF',
    400: '#99BBFF',
    500: '#6E9EFE',
    600: '#4078E8',
    700: '#225CCF',
    800: '#1345A8',
    900: '#00246D',
  },
  purple: {
    100: '#F5F6FF',
    200: '#E7E8FF',
    300: '#B9BCFF',
    400: '#858AFF',
    500: '#6066F3',
    600: '#4147DC',
    700: '#262CAD',
    800: '#121782',
    900: '#070A57',
  },
  yellow: {
    100: '#FFFBEC',
    200: '#FFF7CC',
    300: '#FFF7CC',
    400: '#FFD94A',
    500: '#FFC107',
    600: '#DBA400',
    700: '#AC8100',
    800: '#725600',
    900: '#332600',
  },
  red: {
    100: '#FFF5F7',
    200: '#FFE7EC',
    300: '#FFC5D3',
    400: '#FF92AC',
    500: '#FF6187',
    600: '#E93460',
    700: '#C81D47',
    800: '#950B2C',
    900: '#61061B',
  },
};

export const typography: TypographyOptions = {
  fontFamily: 'Poppins,sans-serif',
  h1: {
    fontSize: 28,
    fontWeight: 500,
    lineHeight: '44px',
  },
  h2: {
    fontSize: 20,
    fontWeight: 500,
    lineHeight: '28px',
  },
  h3: {
    fontSize: 20,
    fontWeight: 500,
    lineHeight: '32px',
  },
  h4: {
    fontSize: 14,
    fontWeight: 500,
    lineHeight: '24px',
  },
  h5: {
    fontSize: 16,
    fontWeight: 500,
    lineHeight: '24px',
  },
  h6: {
    fontSize: 12,
    fontWeight: 500,
    lineHeight: '20px',
  },
  button: {
    fontSize: 12,
    fontWeight: 500,
    lineHeight: '20px',
  },
};

export const DashboardTheme = createTheme({
  palette,
  breakpoints,
  typography,
  props: {
    MuiButtonBase: {
      disableRipple: true,
    },
  },
  overrides: {
    MuiCardContent: {
      root: {
        borderRadius: '0px',
      },
    },
    MuiButton: {
      root: {
        background: 'rgba(6,210,174,1)',
        borderRadius: '24px',
        '&:hover': {
          backgroundColor: 'rgba(6,210,174,0.7)',
        },
      },
    },
    MuiOutlinedInput: {
      root: {
        position: 'relative',
        '& $notchedOutline': {
          borderColor: '#DEE3EF',
        },
        '&:hover:not($disabled):not($focused):not($error) $notchedOutline': {
          borderColor: '#06D2AE',
          '@media (hover: none)': {
            borderColor: '#DEE3EF',
          },
        },
        '&$focused $notchedOutline': {
          borderColor: '#06D2AE',
          borderWidth: 1,
        },
      },
    },
    MuiFormLabel: {
      root: {
        '&$focused': {
          color: '#4A90E2',
        },
      },
    },
    MuiLinearProgress: {
      colorPrimary: {
        backgroundColor: '#D6FFF8',
      },
      barColorPrimary: {
        backgroundColor: '#06D2AE',
      },
    },
    MuiPaginationItem: {
      root: {
        color: palette.grey[700],
      },
    },
    MuiSwitch: {
      switchBase: {
        '&.Mui-checked': {
          color: palette.green['500'],
        },
      },
      track: {
        '.Mui-checked.Mui-checked + &': {
          backgroundColor: palette.green['500'],
        },
      },
    },
  },
});
