import { Box, Link, Typography, withStyles } from '@material-ui/core';
import * as React from 'react';
import { SignUpRoute } from 'routes';

type AccountSecurityBadgeProps = {
  isAuthenticated?: boolean,
}

const SignupBox = withStyles((theme) => ({
  root: {
    boxSizing: 'border-box',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',

    padding: '14px 16px',
    width: 164,

    color: '#FFFFFF',
    fontSize: 16,
    fontWeight: 500,
    lineHeight: '24px',

    background: '#06D2AE',
    borderRadius: 40,

    '&:hover': {
      background: '#1A9071',
    },
    [theme.breakpoints.down('sm')]: {
      padding: '4px',
      width: 100,
      fontSize: 12,
    },
  },
}))(Box);

const AccountSecurityBadge = ({ isAuthenticated = false }: AccountSecurityBadgeProps): JSX.Element => {
  const ContainerBox = withStyles((theme) => ({
    root: {
      boxSizing: 'border-box',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      columnGap: isAuthenticated ? 12 : 28,
      gap: '8px',
      padding: '16px',
      width: 'auto',
      maxWidth: isAuthenticated ? '500px' : undefined,

      background: '#EBFFFC',
      border: '1px solid #8AF9E5',
      borderRadius: 10,

      [theme.breakpoints.up('md')]: {
        flexDirection: 'row',
      },

      '& img': {
        marginRight: '8px',
        [theme.breakpoints.down('sm')]: {
          width: '56px',
        },
      },
    },
  }))(Box);

  const TextBox = withStyles({
    root: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-start',
      marginBottom: 0,
    },
  })(Box);

  const TitleText = withStyles((theme) => ({
    root: {
      color: '#02BD9F',
      fontSize: isAuthenticated ? 16 : 20,
      fontWeight: isAuthenticated ? 500 : 600,
      lineHeight: isAuthenticated ? '24px' : '36px',
      textAlign: 'left',
      [theme.breakpoints.down('sm')]: {
        fontSize: isAuthenticated ? 16 : 18,
        lineHeight: isAuthenticated ? '24px' : '30px',
      },
    },
  }))(Typography);

  const RegularText = withStyles((theme) => ({
    root: {
      color: '#283149',
      fontSize: isAuthenticated ? 12 : 16,
      fontWeight: 400,
      lineHeight: isAuthenticated ? '24px' : '28px',
      textAlign: 'left',
      [theme.breakpoints.down('sm')]: {
        fontSize: 12,
        lineHeight: '24px',
      },
    },
  }))(Typography);

  return (
    <ContainerBox>
      <Box style={{ display: 'flex', alignItems: 'center', marginRight: 'auto' }}>
        <img
          style={{ objectFit: 'contain', marginBottom: '-20px', alignSelf: 'flex-start' }}
          src="/images/footer-account-security-shield.png"
          alt="Account Security"
          width={isAuthenticated ? 56 : 100}
        />

        <TextBox>
          <TitleText>Your security is our priority.</TitleText>
          <RegularText>
            Your account is protected with{' '}
            <Typography variant="inherit" display="inline" style={{ fontWeight: 500 }}>
              Military Grade Data Encryption
            </Typography>
          </RegularText>
        </TextBox>
      </Box>

      {!isAuthenticated && (
        <Box
          sx={{
            marginLeft: 'auto',
            display: 'flex',
            justifyContent: 'flex-end',
          }}
        >
          <Link href={SignUpRoute}>
            <SignupBox>Sign Up</SignupBox>
          </Link>
        </Box>
      )}
    </ContainerBox>
  );
};

AccountSecurityBadge.displayName = 'AccountSecurityBadge';
export default AccountSecurityBadge;
