/* eslint-disable indent */
import { Button, Tooltip } from '@material-ui/core';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import { Alert } from '@material-ui/lab';
import MaterialTable, { MTableToolbar } from 'material-table';
import * as React from 'react';
import { WorkItem, WorkItemAutomationType, WorkItemStatus } from 'services/types/workItem';

import MuiTableIcons from '../../../../components/MuiTableIcons';
import workItemService from '../../../../services/workItem-service';

const ViewWorkItems = () => {
  const [workItems, setWorkItems] = React.useState<WorkItem[]>([]);
  const [refreshWorkItems, setRefreshWorkItems] = React.useState<boolean>(true);

  React.useEffect(() => {
    workItemService.getWorkItems().then((workItemsFromDb) => {
      setWorkItems(workItemsFromDb);
      setRefreshWorkItems(false);
    });
  }, [refreshWorkItems]);

  return (
    <div style={{ maxWidth: '100%', padding: '10px' }}>
      {!workItems.length && <Alert severity="info">Loading...</Alert>}
      <MaterialTable
        icons={(MuiTableIcons) as any}
        actions={[
          (rowData) => ({
            icon: MuiTableIcons.Replay as any,
            tooltip: 'Replay',
            // eslint-disable-next-line no-async-promise-executor
            onClick: async (event, clickRowData: any) => await new Promise(async (resolve, reject) => {
              // eslint-disable-next-line no-alert
              if (window.confirm(`Do you want to replay automatic ${clickRowData.name} for user ${clickRowData.userId}?`)) {
                let updatedWorkItem = clickRowData;
                try {
                  updatedWorkItem = await workItemService.updateWorkItem(
                    clickRowData.workItemId,
                    WorkItemAutomationType.Automatic,
                    WorkItemStatus.Pending,
                  );

                  const updatedWorkItems = workItems.map((workItem: WorkItem) => {
                    if (workItem.workItemId === updatedWorkItem.workItemId) {
                      return updatedWorkItem;
                    }
                    return workItem;
                  });
                  setWorkItems(updatedWorkItems);
                } catch (Error) {
                  reject(Error);
                }
                resolve(updatedWorkItem);
              } else {
                reject();
              }
            }),
            disabled: rowData.name !== 'rebalancing' && rowData.name !== 'withdrawal',
          }),
        ]}
        columns={[
          { title: 'Id', field: 'workItemId', type: 'numeric', editable: 'never', cellStyle: { width: '150px' } },
          { title: 'UserId', field: 'userId', type: 'numeric', editable: 'never', cellStyle: { width: '150px' } },
          { title: 'Name', field: 'name', width: '10%', editable: 'never', cellStyle: { width: '150px' } },
          {
            title: 'Automation',
            field: 'automationType',
            editable: 'onUpdate',
            editComponent: ({ value, onChange }) => (
              <Select
                onChange={(event: any) => onChange(event.target.value)}
                value={value}
              >
                <MenuItem value="manual">manual</MenuItem>
                <MenuItem value="automatic">automatic</MenuItem>
              </Select>
            ),
          },
          {
            title: 'Status',
            field: 'status',
            editable: 'onUpdate',
            editComponent: ({ value, onChange }) => (
              <Select
                onChange={(event: any) => onChange(event.target.value)}
                value={value}
              >
                <MenuItem value="pending">pending</MenuItem>
                <MenuItem value="waiting-for-approval">waiting-for-approval</MenuItem>
                <MenuItem value="in-progress">in-progress</MenuItem>
                <MenuItem value="rejected">rejected</MenuItem>
                <MenuItem value="closed">closed</MenuItem>
              </Select>
            ),
          },
          { title: 'Opened', field: 'openedTs', editable: 'never' },
        ]}
        data={workItems}
        title=""
        options={{
          sorting: true,
          filtering: true,
          showTitle: false,
          pageSize: 100,
          emptyRowsWhenPaging: false,
          pageSizeOptions: [20, 50, 100, 500, 1000],
          rowStyle: {
            fontSize: 14,
          },
          headerStyle: {
            fontSize: 14,
            fontWeight: 'bold',
          },
          searchFieldStyle: {
            fontSize: 14,
          },
          searchFieldAlignment: 'right',
        }}
        components={{
          Toolbar: (props) => (
            <div style={{ paddingTop: '10px', paddingLeft: '10px' }}>
              <div style={{ display: 'inline-block' }}>
                <MTableToolbar {...props} />
              </div>
              <div style={{ display: 'inline-block', float: 'right' }}>
                <Tooltip title="Refresh work items">
                  <Button onClick={() => setRefreshWorkItems(true)}>
                    <MuiTableIcons.Refresh />
                  </Button>
                </Tooltip>
              </div>
            </div>
          ),
        }}
        editable={{
          // eslint-disable-next-line no-async-promise-executor
          onRowUpdate: async (newData: WorkItem, oldData?: WorkItem) => await new Promise(async (resolve, reject) => {
            if (oldData) {
              try {
                const updatedWorkItem = await workItemService.updateWorkItem(
                  newData.workItemId,
                  newData.automationType,
                  newData.status,
                );
                const updatedWorkItems = workItems.map((workItem: WorkItem) => {
                  if (workItem.workItemId === updatedWorkItem.workItemId) {
                    return updatedWorkItem;
                  }
                  return workItem;
                });
                setWorkItems(updatedWorkItems);
              } catch (Error) {
                reject(Error);
              }
              resolve(newData);
            } else {
              reject();
            }
          }),
        }}
      />
    </div>
  );
};

ViewWorkItems.routePath = '/work-items';
ViewWorkItems.loginRequired = true;
ViewWorkItems.displayName = 'ViewWorkItems';

export default ViewWorkItems;
