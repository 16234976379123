import { Box, makeStyles } from '@material-ui/core';
import { Typography } from 'elements';
import React from 'react';
import { CreateWalletParameter } from 'services/types/workItemParameter';

import { getWalletProviderIcon } from '../../../pages/responsive/WalletSetting/components/WalletTypes';
import { WalletProvider } from '../../../services/types/wallet';
import generateRandomString from '../../../utils/generateRandomString';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    columnGap: 4,
    cursor: 'pointer',
  },
  walletIcon: {
    width: 24,
    height: 24,
    borderRadius: 4,
    boxShadow: '0px 4px 12px rgba(110, 123, 162, 0.12)',
    '& img': {
      borderRadius: 4,
      width: 24,
      height: 24,
    },
    '& object': {
      borderRadius: 4,
      width: 24,
      height: 24,
    },
  },
  walletOrder: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: 16,
    height: 16,
    color: theme.palette.common.white,
    fontSize: 12,
    fontWeight: 500,
    background: theme.palette.blue[500],
    borderRadius: 10,
  },
  selectedBadge: {
    flexShrink: 0,
    marginLeft: 'auto',
    width: 20,
    height: 20,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    '& svg': {
      flexShrink: 0,
      margin: -2,
      '& path': {
        stroke: theme.palette.blue[500],
      },
    },
  },
  divider: { color: theme.palette.grey[300], fontSize: 12 },
  hashtag: { fontSize: 12 },
}));

const CreateWalletDetails = (props: CreateWalletParameter) => {
  const classes = useStyles();

  return (
    <Box className={classes.root}>
      <Box className={classes.walletIcon}>
        <img
          alt={props.name}
          src={`/icons/wallet_setting/${
            getWalletProviderIcon(props.walletProvider as WalletProvider)}_sm.svg?${generateRandomString()
          }`}
        />
      </Box>
      <Typography variant="h4">{props.name ?? props.walletProvider}</Typography>
    </Box>
  );
};

export default CreateWalletDetails;
