import React from 'react';
import FormField from './FormField';
import { Form} from 'react-bootstrap'


class Question extends FormField {
  constructor(props) {
    super(props);

  }

  onChange = (e) => {
    if (e.target.dataset.id != null) {
      this.updateValue(e.target.dataset.id);
    }
  }

  render() {
    return (
      <>
        <p className="p2">{this.props.data.question}</p>
        {this.props.data.answer.map((answer, i) => {
          return (
            <Form.Check 
              type="radio"
              key={i}
              data-id={i}
              className="pointer"
              id={"answer" + i} 
              checked={this.value === i.toString()}
              name={this.name}
              onChange={this.onChange}
              label={answer}
            />
          )
        })}
      </>
    );
  }
}


export default Question;