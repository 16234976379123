import { Box, makeStyles, Typography, useMediaQuery, useTheme } from '@material-ui/core';
import React, { FC } from 'react';
import { ReferralStats as ReferralStatsType } from 'services/types/wallet';

type ReferralStatsProps = {
  className?: string;
  stats: ReferralStatsType;
};

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    padding: 0,
    width: '100%',
    height: '100%',

    [theme.breakpoints.up('sm')]: {
      padding: 16,
      border: '1px solid',
      borderColor: theme.palette.grey[200],
      borderRadius: 8,
    },
  },
  statsBox: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'stretch',

    rowGap: 12,
    marginTop: 8,
    padding: '12px 8px',
    width: '100%',
    background: theme.palette.blue[100],
    border: '1px solid',
    borderColor: theme.palette.blue[200],
    borderRadius: 8,
  },
  statsRow: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-evenly',
    alignItems: 'center',
    columnGap: 12,
  },
  statsItem: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    rowGap: 8,
  },
  statsValueText: {
    color: theme.palette.blue[500],
    fontSize: 16,
    fontWeight: 500,
    lineHeight: '20px',
  },
  statsNameText: {
    fontSize: 12,
    fontWeight: 500,
    lineHeight: '16px',
    textAlign: 'center',
  },
  blueText: { color: theme.palette.blue[500] },
}));

const ReferralStats: FC<ReferralStatsProps> = ({ className, stats }) => {
  const classes = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('xs'));

  return (
    <Box className={`${classes.root} ${className}`}>
      <Typography variant={isMobile ? 'h4' : 'h3'}>Lifetime referral earnings</Typography>
      <Typography variant="h2" className={classes.blueText}>
        {(stats.totalEarningUsdt ?? 0).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })} USD
      </Typography>
      <Box className={classes.statsBox}>
        <Box className={classes.statsRow}>
          <Box className={classes.statsItem}>
            <Typography className={classes.statsValueText}>
              {(stats.totalEarningUsdt ?? 0)
                .toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })} USD
            </Typography>
            <Typography className={classes.statsNameText}>Total amount referred</Typography>
          </Box>
          <Box width="1px" height="100%" bgcolor={theme.palette.blue[300]} />
          <Box className={classes.statsItem}>
            <Typography className={classes.statsValueText}>
              {(stats.monthlyEarningUsdt ?? 0)
                .toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })} USD
            </Typography>
            <Typography className={classes.statsNameText}>Earnings this month</Typography>
          </Box>
        </Box>
        <Box width="100%" height="1px" bgcolor={theme.palette.blue[300]} />
        <Box className={classes.statsRow}>
          <Box className={classes.statsItem}>
            <Typography className={classes.statsValueText}>{stats.activeReferees}</Typography>
            <Typography className={classes.statsNameText}>Customers with account balance</Typography>
          </Box>
          <Box width="1px" height="100%" bgcolor={theme.palette.blue[300]} />
          <Box className={classes.statsItem}>
            <Typography className={classes.statsValueText}>{stats.inactiveReferees}</Typography>
            <Typography className={classes.statsNameText}>Customers referred, but no account balance</Typography>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

ReferralStats.displayName = 'ReferralStats';
export { ReferralStats };
