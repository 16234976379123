import { Box, Hidden, makeStyles } from '@material-ui/core';
import { Card, Typography } from 'elements';
import React, { useEffect, useMemo, useRef } from 'react';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { HashLink } from 'react-router-hash-link';
import { TermsOfUseRoute } from 'routes';

import { TermsOfUseSubpages } from '../../components/Layout/Header/PolicyNavigation';

const useStyles = makeStyles((theme) => ({
  pageGrid: {
    display: 'flex',
    alignItems: 'start',
  },
  tocContainer: {
    overflowY: 'auto',
    position: 'sticky',
    top: 96,

    display: 'flex',
    flexDirection: 'column',

    marginTop: 32,
    marginRight: 24,
    padding: '32px 24px',
    width: 'fit-content',
    maxWidth: 352,
    maxHeight: 'calc(100vh - 112px)',
    borderRadius: 32,
    '&::-webkit-scrollbar': {
      display: 'none',
    },
  },
  tocContent: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'start',
    rowGap: 24,
    paddingTop: 20,
  },
  pageLink: {
    color: theme.palette.grey[500],
    fontSize: 24,
    fontWeight: 600,
    lineHeight: '32px',
  },
  subpageLink: {
    color: `${theme.palette.grey[500]} !important`,
    fontSize: 16,
    fontWeight: 500,
    lineHeight: '24px',
  },
  termsContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'stretch',
    width: '100%',

    [theme.breakpoints.up('md')]: {
      margin: '32px auto 0',
    },

    '& h2': {
      fontSize: 16,
      fontWeight: 600,
      lineHeight: '24px',

      [theme.breakpoints.up('md')]: {
        fontSize: 20,
        lineHeight: '32px',
      },
    },
    '& li': {
      color: 'unset',
    },
  },

  sectionHeader: {
    textTransform: 'uppercase',
    scrollMarginTop: '128px',
    [theme.breakpoints.up('md')]: {
      scrollMarginTop: '108px',
    },
  },

  versionText: {
    fontSize: 20,
    fontWeight: 600,
    lineHeight: '32px',

    [theme.breakpoints.up('md')]: {
      fontSize: 24,
    },
  },
  updateText: {
    color: theme.palette.grey[400],
    fontSize: 14,
    lineHeight: '24px',
    textTransform: 'uppercase',
  },
  termsText: {
    fontSize: 12,
    fontWeight: 500,
    lineHeight: '20px',

    [theme.breakpoints.up('md')]: {
      fontSize: 16,
      lineHeight: '24px',
    },

    '& ol': {
      margin: 0,
      paddingLeft: '1.5rem',
      listStyle: 'decimal',
    },
    '& ol > li': {
      marginTop: 4,
      fontSize: 12,
      fontWeight: 500,
      lineHeight: '20px',

      [theme.breakpoints.up('md')]: {
        fontSize: 16,
        lineHeight: '24px',
      },
    },

    '& ul': {
      margin: 0,
      paddingLeft: '1.5rem',
      listStyle: 'none',
    },
    '& ul > li': {
      fontSize: 12,
      fontWeight: 500,
      lineHeight: '20px',

      [theme.breakpoints.up('md')]: {
        fontSize: 16,
        lineHeight: '24px',
      },

      '&::before': {
        content: '"\u2022"',
        display: 'inline-block',

        marginTop: 2,
        marginLeft: '-.75em',
        width: '.75em',
        transform: 'translateY(4px)',

        color: theme.palette.green[600],
        fontSize: '2em',
      },
    },
  },
  table: {
    overflow: 'auto',
    width: '100%',

    border: '1px solid',
    borderColor: theme.palette.grey[800],

    fontSize: 12,
    fontWeight: 500,
    lineHeight: '20px',

    [theme.breakpoints.up('md')]: {
      fontSize: 16,
      lineHeight: '24px',
    },

    '& th, td': {
      border: '1px solid',
      padding: '4px 6px',
    },
    '& th': {
      textAlign: 'center',
    },
  },
  active: { color: `${theme.palette.green[600]} !important` },
}));

const SectionHeader = ({ title }: { title: string }) => {
  const { push } = useHistory();

  const id = useMemo(() => title.split(' ').join('-').toLowerCase(), [title]);

  const ref = useRef<HTMLSpanElement>();
  useEffect(() => {
    const observer = new IntersectionObserver((e) => {
      if (e[0].isIntersecting) push({ hash: id });
    }, {
      root: null,
      rootMargin: '0px 0px -60%',
      threshold: 1.0,
    });

    if (ref?.current) observer.observe(ref.current);
    return () => {
      if (ref?.current) observer.unobserve(ref.current);
    };
  }, []);

  const classes = useStyles();
  return (
    <Typography
      id={id}
      innerRef={ref}
      className={classes.sectionHeader}
      variant="h2"
    >
      {title}
    </Typography>
  );
};

const TermsOfUse = () => {
  const { hash } = useLocation();

  const classes = useStyles();
  return (
    <>
      <Typography variant="h1">
        <em>Coinbag</em> Terms of Use
      </Typography>

      <Box className={classes.pageGrid}>
        <Hidden smDown>
          <Card className={classes.tocContainer}>
            <Box className={classes.pageLink}>
              Terms of Use
            </Box>

            <Box className={classes.tocContent}>
              {TermsOfUseSubpages.map((subpage, index) => (
                <HashLink
                  key={index}
                  className={`${classes.subpageLink} ${hash === `#${subpage.hash}` && classes.active}`}
                  to={{ hash: subpage.hash }}
                >
                  {subpage.title}
                </HashLink>
              ))}
            </Box>
          </Card>
        </Hidden>

        <Box className={classes.termsContainer}>
          <Typography className={classes.updateText}>
            Last updated: July 24, 2023
          </Typography>

          <Box className={classes.termsText}>
            <br />
            Coinbag puts transparency first. If at any time, you have any questions regarding our terms of use or
            privacy policy, please contact us directly at{' '}
            <a href="mailto:admin@coinbag.finance">admin@coinbag.finance</a> and we will respond within <b>3</b> working
            day.

            <br /><br />
            Coinbag (Coinbag, we, us, or ours) is offering access to a range of algorithms and related services
            (“Services”), as specified in the “COINBAG SERVICES” Section of this document, to enable Users (as defined
            below) to leverage their technologies to meet a variety of objectives, including improving the analysis and
            reporting of their operations, and to create a portfolio of their choosing and select the decentralized
            interest earning methods of their choosing by establishing an account via the{' '}
            <Link to="/">www.coinbag.finance</Link> website (“Site”). The Services are made available
            but remain under constant ongoing development and improvements, meaning it is not free from bugs or errors.
            Coinbag does not warrant or otherwise guarantee that the Services are free from error, fit for the purpose,
            nor are any representations or guarantees made as to actual or potential returns from using the Services.

            <br /><br />
            These Terms of Use are entered into between you (referred to as “you” or “your '' as the context requires)
            and Coinbag. By accessing the Site, downloading, using or clicking on “I agree” to subscribe to the Site and
            any Services therein, you acknowledge and agree that you have read, understood, and accepted the terms of
            use set out in these Terms of Use and the Privacy Policy available at{' '}
            <Link to="/privacy-policy">https://www.coinbag.finance/privacy-policy</Link>.

            <br /><br />
            <SectionHeader title="Definitions" />

            <br />
            <i>Aggregator Service</i> means the gathering of information from various Decentralized Finance (DeFi)
            protocols and integrating them into a single interface for better analysis by the User. It also includes
            providing other incidental functions such as connecting liquidity pools, reducing transaction costs,
            depositing funds into DeFi pools, and other protocol-related functions.

            <br /><br />
            <i>Applicable Law</i> means any law, statute, regulation, ordinance, treaty, guideline, policy, and act
            issued by Hong Kong Special Administrative Region.

            <br /><br />
            <i>Coinbag Services</i> means the services available from the Site.

            <br /><br />
            <i>Confidential Information</i> means the information described as such in accordance with Hong Kong
            regulations.

            <br /><br />
            <i>Custodial Service</i> and <i>Custodial Wallet Service</i> means the managing, custody, and safeguarding
            of the User's private keys of their Digital Assets Wallet on the user's behalf.

            <br /><br />
            <i>Decentralized Interest Earning Methods</i> means Smart Contracts aimed at generating yields on the User's
            Digital Assets through Decentralized Finance (DeFi) mechanisms that may consist of locking digital assets in
            exchange for a return (Staking), providing liquidity in protocols in exchange for obtaining earnings from
            the fees charged on transactions made in that protocol (Liquidity Providing), or lending digital assets for
            a specific time in exchange for an interest (Lending).

            <br /><br />
            <i>Digital Asset</i> means any representations of value that are generated and stored digitally in a
            blockchain held within a User's profile or available for purchase or sale on publicly known and available
            digital asset exchange platforms.

            <br /><br />
            <i>Fiat-to-Digital Asset Solutions</i> means a platform for the exchange between a company and the User of
            traditional money (fiat money) for Digital Assets, or vice versa, and the subsequent crediting of this money
            or Digital Assets to an account owned by the User. On-Ramp means that the User provides fiat money and
            receives Digital Assets; conversely, Off-Ramp means that the User provides Digital Assets and receives fiat
            money.

            <br /><br />
            <i>Privacy Policy</i> means the Coinbag Privacy Policy accessible <Link to="/privacy-policy">here</Link> and
            the Privacy Policy of any Third-Party Custodian Service Providers, which has been made accessible from their
            respective websites, both amended occasionally.

            <br /><br />
            <i>Third-Party Custodian Service Provider</i> means the Custodial Wallet Service Provider, which may include
            any of the companies shown as available on the Coinbag platform.

            <br /><br />
            <i>User</i> means a registered subscriber to the Services via the Site. For the purposes of this document,
            and in order to better communicate its contents, <i>"You"</i> may be used as a synonym for this definition.

            <br /><br />
            <SectionHeader title="General terms" />

            <br />
            These Terms constitute a legal agreement between Coinbag and You.

            <br /><br />
            This agreement also includes additional terms, including the Privacy Policy and any supplementary terms
            applicable to the Services, any additional features of the Services or new Services. It also includes any
            additional Terms of Uses and Privacy Policies from any Third Party Service Provider whose services the User
            agrees to use through the Coinbag platform. Any such supplementary terms will constitute part of these Terms
            and bind You as if they formed part of this agreement. Continuing to use the Services once notified of any
            supplementary terms will constitute deemed acceptance of such terms and your ongoing agreement to the Terms
            of Use.

            <br /><br />
            Coinbag reserves the right to change these Terms at its discretion at any time. Coinbag will notify any
            changes to You by updating the Terms on its Site and modifying the <b>[Last Updated]</b> date displayed on
            the web page. Any supplementary terms may be notified by publishing a customer notice on the Site with an
            effective date and shall take effect from that date as an addendum to these Terms. The obligation is on You
            as an user of the Services to stay updated concerning the Terms of Use and any supplementary terms.

            <br /><br />
            <b>
              IF YOU DO NOT AGREE TO ANY AMENDMENTS MADE BY COINBAG TO THESE TERMS, YOU MUST STOP USING THE SERVICES
              IMMEDIATELY AND EMAIL <a href="mailto:support@coinbag.finance">SUPPORT@COINBAG.FINANCE</a> TO REQUEST THAT
              YOUR ACCOUNT BE DEACTIVATED AND ANY FUNDS WITHDRAWN TO AN ADDRESS OF YOUR CHOICE.
            </b>

            <br /><br />
            <SectionHeader title="Establishing an account" />

            <br />
            When establishing an account on the site, you must provide your or your institution’s real name, an email,
            and a password of your choosing. You will provide complete and accurate information when opening an account
            on the Site. Coinbag may ask you for additional information to comply with our Anti-Money Laundering (AML)
            policies.

            <br /><br />
            All individual users creating a personal account established via the site are for one unique user, which
            corresponds to one natural person. All entities, companies, or businesses creating a business account via
            the site may invite others to act on behalf of the approved business account but from their own unique and
            traceable account with established permissions set by the business account creator. Coinbag does not permit
            the establishment of shared accounts or passwords.

            <br /><br />
            By registering to establish an account on the Site, You represent and warrant that You are at least 18 or
            over and of legal age to enter into a binding contract; In the event that you act on behalf of a company or
            any other corporate entity, you warrant that you have the sufficient legal authority to bind such entity and
            to subscribe to Coinbag's services.

            <br /><br />
            You represent and warrant that your use of the Services will not breach any laws and regulations applicable
            to You, including but not limited to, regulations and laws concerning anti-money laundering,
            anti-corruption, and counter-terrorism financing.

            <br /><br />
            The Coinbag account can only be used by the person or organization that opened the account. Coinbag reserves
            the right to suspend or cancel any account it suspects may be used by persons or organizations other than
            the account registrant. If You suspect or become aware of any unauthorized use of your username and
            password, You should notify Coinbag immediately. Coinbag does not assume or accept any liability for any
            loss or damage arising from the use of Your Coinbag account by You or any or any third party with or without
            Your authorization.

            <br /><br />
            You agree to treat your username and password as confidential information and not to disclose such
            information to any third party. It is also your obligation to take all necessary steps to protect your
            confidential information, comply with any Coinbag security requirements, exercise caution if using a shared
            computer to access the Services (including ensuring your login credentials are not stored on such shared
            computer or device) and to take appropriate steps to logout out of the Services after each visit.

            <br /><br />
            <SectionHeader title="Coinbag services" />

            <br />
            Coinbag will make available the Services that you nominate when registering an account, or such other
            Services as you may request from time to time in accordance with these Terms.

            <br /><br />
            Coinbag Services consist of the following:

            <br /><br />
            <ol>
              <li>
                <b>Coinbag Platform</b>
                <ul>
                  <li>
                    Initially includes the ability for you to choose the types of Digital Assets You want to manage in
                    your wallet.
                  </li>
                  <li>
                    Once you have selected the assets and ratios, you can choose if and how you would like to earn
                    interest by choosing the specific decentralized interest earning methods supported by the platform.
                    You do not need to choose to earn interest and can adjust your settings anytime.
                  </li>
                  <li>
                    After, you can choose your desired rebalancing cadence, whether manual or automated, by Coinbag
                    algorithms.
                  </li>
                  <li>
                    After your portfolio is set up, Coinbag may ask you to fulfill identity verification. Once you have
                    successfully past identity verification, if needed, you can then deposit digital assets into the
                    Coinbag platform. This can be done by a basic transfer of your digital assets held within a
                    different wallet or within a centralized exchange. Coinbag has also partnered with approved
                    fiat-to-digital assets on and off-ramp solutions that allow you to purchase and deposit via bank
                    transfer or credit/debit card.
                  </li>
                  <li>
                    After your deposit, the Coinbag algorithms may adjust your wallet to your desired settings
                    automatically based on your settings or actions.
                  </li>
                  <li>
                    At any time, pending network outages, you can withdraw your assets and will be required to have
                    successfully completed identity verification.
                  </li>
                </ul>
              </li>
            </ol>

            <br />
            Given the technological nature of the services provided by Coinbag, you expressly acknowledge that you are
            aware of and agree to the following:
            <ul>
              <li>
                Coinbag and any Third-Party Custodian Service Provider reserves the right to monitor incoming and
                outgoing transfers of assets using third party software as part of our Anti-Money Laundering (AML)
                policies.
              </li>
              <li>
                You can, and we suggest, set up two-factor authentication for additional account security.
              </li>
            </ul>

            <br />
            Coinbag, through a licensed Third-Party Custodian Service Provider, will provide a Digital Asset custodial
            wallet service in compliance with our AML Policies.

            <br /><br />
            <ol start={2}>
              <li>
                <b>Wallet Reporting</b>
                <br /><br />
                The Wallet Reporting service (‘WR’, or ‘WalletReporting’) is made available in the Coinbag Platform and
                is also provided as an independent service accessible through the{' '}
                <a href="https://walletreporting.com" target="_blank" rel="noreferrer">www.WalletReporting.com</a>{' '}
                website (‘WR Site’). WalletReporting offers access to a platform that allows any user to view and
                export, in report format, public information regarding specified wallet addresses in Supported
                Blockchains (as specified in the WR Site or the Site) and allows assigning on-platform text attributions
                (tags) to any transaction in the report to facilitate its use for various purposes, including primarily
                tax reporting purposes via the WR Site. For the purposes of using the WalletReporting services via the
                WR Site, the user agrees to these Terms of Use where applicable to the nature of the services and their
                usage by the User.
              </li>
              <br />
              <li>
                <b>Batch Payroll</b>
                <br /><br />
                The Batch Payroll Service (‘Batch Payroll’) is made available in the Coinbag Platform and allows the
                user to better manage their recurrent operations related to batch sending digital assets to their
                employees and tagging these operations as such altogether with the usage of WalletReporting.
              </li>
            </ol>

            <br />
            Coinbag does not provide any financial services, including but not limited to custodial arrangements, asset
            administration, collateral management, or margin facilities.

            <br /><br />
            Coinbag and the Coinbag Platform solely provide an Aggregator Service that connects Users to
            interest-earning methods, allowing them to control which protocols and the ratio to use by creating a
            portfolio allocation of their choosing.

            <br /><br />
            Notwithstanding the above, Coinbag retains the right to:
            <ul>
              <li>
                Provide, modify or terminate, in its sole discretion, any of the Services by giving notice to you; and
              </li>
              <li>
                Allow or prohibit your use of any Services in accordance with these Terms or any supplementary terms
                published and made binding in accordance with these Terms.
              </li>
            </ul>

            <br />
            By accessing the Services you acknowledge and agree that you have made your own inquiries and You:
            <ul>
              <li>
                Understand the risks involved in trading digital assets and any derivatives of the same;
              </li>
              <li>
                Accept all risks associated with the use of Coinbag Services to transact in digital assets and any
                associated derivatives; and
              </li>
              <li>
                Accept and agree that Coinbag is not liable for any risks related to your use of the Services, for any
                loss of financial capital in using the Services to trade digital assets or any derivatives, and that You
                release Coinbag against any claims related to all such risks.
              </li>
              <li>
                Accept and agree that Coinbag is a service provider which connects the User’s wallet to third-party,
                decentralized interest-earning methods. Coinbag does not have control over any losses that can occur on
                third-party platforms that You choose to send your funds to.
              </li>
            </ul>

            <br />
            <SectionHeader title="License" />

            <br />
            Subject to your continued compliance with these Terms, Coinbag will provide you access to the Services as
            described in these Terms of Use and applicable regulations.

            <br /><br />
            Unless agreed otherwise in a separate agreement, You cannot use the Services for the purpose of resale or
            commercial redistribution, including using the Services on behalf of other people or entities. In the case
            of a company or any other entity, it is understood that the person operating the platform on their behalf
            (President, Manager, or other position with representation) is not formally acting on behalf of another, but
            rather it is the entity that is operating the platform through an employee.

            <br /><br />
            Coinbag expressly reserves and retains all intellectual property rights in the Services.

            <br /><br />
            Coinbag owns any feedback, suggestions, ideas, or other information or materials (collectively “feedback”)
            about Coinbag or the Services that you provide through email, the Services, or any other means. You hereby
            transfer all rights, ownership and interests of the Feedback and any related intellectual property rights to
            Coinbag. You have no right and hereby waive any requirement for Coinbag to acknowledge or compensate you for
            any Feedback, or any modification to the Services based on any Feedback.

            <br /><br />
            <SectionHeader title="Using the services" />

            <br />
            When you have established a Coinbag account, you may proceed to:
            <ul>
              <li>
                Select the relevant Services;
              </li>
              <li>
                Choose/update which digital assets you want to manage
              </li>
              <li>
                Choose/update the ratios in your wallet
              </li>
              <li>
                Choose/update the third party interest earning methods (if any)
              </li>
              <li>
                Choose/update your rebalancing cadence
              </li>
              <li>
                Deposit digital assets
              </li>
              <li>
                Withdraw digital assets
              </li>
              <li>
                Track and View all transactions across all of your wallets in a single place. You may also Tag and Pivot
                on transactions for accounting purposes.
              </li>
              <li>
                Track and View all transactions across external wallets (not available via the Coinbag Platform) using
                the Import Feature available in the Coinbag Platform for this purpose.
              </li>
            </ul>

            <br />
            You represent and warrant that You will not use the Services or Account for any illegal activity, including,
            without limitation, illegal gambling, money laundering, fraud, blackmail, extortion, ransoming data, the
            financing of terrorism, other violent or criminal activities or any prohibited market practices.

            <br /><br />
            You shall not (1) copy or reproduce the Services; (2) sell, assign, lease, lend, rent, distribute, or
            sublicense the Services; (3) modify, alter, adapt, arrange, translate, decompile, disassemble, reverse
            engineer, or otherwise attempt to reconstruct, extract or discover the source code or non-literal aspects
            (such as, but not limited to, the underlying structure, sequence, organization, ideas, routines, facilities,
            and formats) BOT and Web application of, the Services or any part; (4) remove, alter, or conceal, in whole
            or in part, any copyright, trademark, patent, or other proprietary legend, notice or designation displayed
            or contained on/in the Services; (5) circumvent, disable or otherwise interfere with security-related
            features of the Custodial Services, or with features that prevent or restrict use of the Services; (6) make
            a derivative work of the Services, or use the Services to develop any service or product that is the same
            as, substantially similar to, or competes directly or indirectly with the Services; (7) disclose to the
            public the results of any internal performance testing or benchmarking studies of/about the Services; (8)
            publish, transmit, or link to any robot, spider, crawler, virus, malware, Trojan horse, spyware, or similar
            malicious code or item intended (or that has the potential) to damage, disrupt, compromise, or exploit the
            Services; or (9) use the Services in any manner that violates law; or (10) exploit any of the Services or
            Content, in whole or in part.

            <br /><br />
            <SectionHeader title="Service fees and pricing" />

            <br />
            In consideration of Coinbag providing the Services and as a User of our platform, you have the option to
            choose from the following subscription plans:
          </Box>

          <br />
          <Box overflow="auto">
            <table className={classes.table}>
              <thead>
                <tr>
                  <th>Concept</th>
                  <th>Monthly Cost</th>
                  <th>Monthly Deposit Capacity</th>
                  <th>Excess Deposit percentage fee (per excess deposit)</th>
                  <th>Team Members</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td><b>Level 1 - Startup</b></td>
                  <td>$199 USD</td>
                  <td>$150,000 USD</td>
                  <td>0.25%</td>
                  <td>3</td>
                </tr>
                <tr>
                  <td><b>Level 2 - Small Business</b></td>
                  <td>$499 USD</td>
                  <td>$500,000 USD</td>
                  <td>0.20%</td>
                  <td>7</td>
                </tr>
                <tr>
                  <td><b>Level 3 - Growth Stage</b></td>
                  <td>$999 USD</td>
                  <td>$1,500,000 USD</td>
                  <td>0.15%</td>
                  <td>10</td>
                </tr>
                <tr>
                  <td><b>Level 4 - Established</b></td>
                  <td>$1999 USD</td>
                  <td>$10,000,000 USD</td>
                  <td>0.125%</td>
                  <td>15</td>
                </tr>
                <tr>
                  <td><b>Level 5 - Enterprise</b></td>
                  <td>$4999 USD</td>
                  <td>$25,000,000 USD</td>
                  <td>0.10%</td>
                  <td>25</td>
                </tr>
                <tr>
                  <td><b>Level 6 - Custom</b></td>
                  <td>Custom</td>
                  <td>$25,000,000+ USD</td>
                  <td>0.01 - 0.10%</td>
                  <td>Unlimited</td>
                </tr>
              </tbody>
            </table>
          </Box>

          <Box className={classes.termsText}>
            <br />
            All of the above subscription plans include access to the Coinbag Platform (including Multi-Wallet
            Management), Wallet Reporting, and Batch Payroll services. Additional fees may be collected based on spread
            and savings.

            <br /><br />
            For the case of using WalletReporting services independently, the costs are as follows:
          </Box>

          <br />
          <Box overflow="auto">
            <table className={classes.table}>
              <thead>
                <tr>
                  <th>Concept</th>
                  <th>Fee</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Wallet Report Download</td>
                  <td>$5 USD per wallet</td>
                </tr>
                <tr>
                  <td>Unlimited Report Downloads for Unlimited Wallets</td>
                  <td>$49 USD per year</td>
                </tr>
              </tbody>
            </table>
          </Box>

          <Box className={classes.termsText}>
            <br />
            The above fees and plans can also be found on the Coinbag Website, as amended from time to time. Coinbag may
            make amendments to these fees at any time and publish such amendments on the Coinbag Website and in these
            Terms of Use. Changes in the fees will be notified at least <b>30 days</b> in advance, by publishing them in
            our website or modifying this document, and will take effect in the billing cycle following the expiration
            of this period.

            <br /><br />
            All amounts and rates are in addition to amounts charged by third parties, such as gas fees charged by the
            blockchain network or credit card fees charged by a financial institution. Real-time breakdowns of the
            estimated fees to be charged may be provided within the platform, and these fees are subject to small price
            changes known as slippage due to volatility on the blockchain.

            <br /><br />
            From time to time, Coinbag may offer special pricing or discount promotions according to specific situations
            or for marketing purposes. This special pricing will last for the time specified in the promotion or offer,
            and will only apply to the persons specified as beneficiaries of such promotion.

            <br /><br />
            <SectionHeader title="Invoicing and payment" />

            <br />
            The invoice for the Services will be issued on a quarterly or monthly basis on the first day of the
            following month (the "Invoice Date") based on the selected subscription plan.

            <br /><br />
            You agree to pay for the subscription fee within fourteen (14) days from the Invoice Date (the "Payment
            Period").

            <br /><br />
            Payments shall be made in the currency specified in the invoice and through the payment methods accepted by
            our platform. You are responsible for any transaction fees or charges associated with the chosen payment
            method.

            <br /><br />
            In the event of a delay or failure to pay within the Payment Period, we reserve the right to suspend or
            terminate your account according to the “TERMINATION” section of these Terms of Use. Additionally, any
            outstanding amounts may be subject to late payment fees or interest charges as specified in the invoice or
            otherwise communicated to you.

            <br /><br />
            It is your responsibility to ensure that the billing information details provided to us are accurate and up
            to date. In case of any changes to your billing information, please promptly update the details within your
            account settings or inform our customer support team.

            <br /><br />
            If you have any concerns or disputes regarding an invoice, you must notify us in writing within{' '}
            <b>seven (7) days</b> from the Invoice Date. Failure to do so will be deemed as your acceptance and
            acknowledgment of the invoice as issued.

            <br /><br />
            We reserve the right to modify or adjust the invoicing and payment terms, including the Invoice Date and
            Payment Period, upon providing reasonable notice to you. Such changes will be communicated through the email
            address associated with your account or via other reasonable means.

            <br /><br />
            <SectionHeader title="Disclaimers" />

            <br />
            <b>Disclaimer of Implied Warranties</b>: to the maximum extent permitted by law, Coinbag offers the Services
            on an “as is” basis and Coinbag expressly disclaims any and all warranties of any kind, whether express or
            implied, including without limitation, any warranties as to merchantability, fitness for a particular
            purpose, title or non-infringement of intellectual property rights, or warranties arising from course of
            performance, course of dealing or usage.

            <br /><br />
            Without limiting the disclaimer in this clause, Coinbag does not represent or warrant that the Site, the
            Services or any related materials are accurate, complete, reliable, current, error-free or free from viruses
            or other damaging components. Coinbag does not guarantee that any order generated by using the Services will
            be executed, accepted, recorded or remain open.

            <br /><br />
            Except for the express statements in these Terms, you hereby acknowledge and agree that you have not relied
            on any other statement or agreement, whether written or oral, concerning your use and access to the
            Services.

            <br /><br />
            Without limiting anything in this clause, you acknowledge and agree that Coinbag is not liable for any
            losses or damages arising out of or relating to:
            <ul>
              <li>Any inaccuracy, defect, or omission in data provided by third parties;</li>
              <li>
                Any inaccuracy, defect, or omission in any software forming part of the Services when processing such
                data sourced from third parties;
              </li>
              <li>Interruption in the supply of such data from third parties;</li>
              <li>Any interruption to or suspension of the Services;</li>
              <li>
                Any damage caused by illegal actions of third parties or actions that are not authorized by Coinbag;
              </li>
              <li>Any losses incurred from third party interest-earning methods.</li>
            </ul>

            <br />
            <b>Disclaimer of damages & limitation of liability</b>: to the maximum extent permitted by applicable law,
            Coinbag, its affiliates, their respective shareholders, members, directors, officers, employees, attorneys,
            agents, representatives, suppliers or contractors will not be liable for any incidental, indirect, special,
            punitive, consequential or similar damages or liabilities whatsoever, including, without limitation, damages
            for loss of data, information, revenue, profits, or any other benefit, financial or otherwise arising out
            the Services, any performance or non-performance of the Services, whether under contract, statute, strict
            liability or other legal principles, even if Coinbag has been advised of the possibility of such damages
            except to the extent of a final judicial determination that such damages are a result of Coinbag’s gross
            negligence, fraud, wilful misconduct or intentional violation of the law.

            <br /><br />
            Any malfunctioning or issues arising from third-party services providers' products and services used through
            the Coinbag platform shall be the sole responsibility of the respective third-party service provider.
            Coinbag shall not be held liable for any such malfunctions or issues, and any related claims or disputes
            should be addressed directly with the relevant third-party service provider.

            <br /><br />
            Coinbag's platform is provided on an "as is" basis, without warranties or guarantees of uninterrupted or
            error-free operation. Coinbag shall not be held liable for any downtime, disruptions, or consequences
            arising from the use or inability to use the platform.

            <br /><br />
            Claims or disputes regarding insurance coverage provided by third-party services providers should be
            directed to the respective third-party service provider. Coinbag shall not be responsible for facilitating
            or resolving any insurance claims or coverage issues.

            <br /><br />
            Notwithstanding the provisions of this clause, under no circumstances will the liability of Coinbag, its
            affiliates, their respective shareholders, members, directors, officers, employees, attorneys, agents,
            representatives, suppliers or contractors arising out of the Services, any performance or non-performance of
            the Services, whether under contract, statute, strict liability or other legal principles, exceed the amount
            of the fees paid by you to Coinbag under these Terms in the 12 month period immediately preceding the event
            giving rise to the claim for liability.

            <br /><br />
            The Disclaimers will be read down to the extent permitted by law in your jurisdiction, without necessarily
            rendering them unenforceable.

            <br /><br />
            <SectionHeader title="Indemnity" />

            <br />
            You agree to release from any responsibility and hold harmless Coinbag, its affiliates, contractors,
            licensors and their respective shareholders, directors, officers, employees, and agents from and against any
            claims, actions, proceedings, investigations, demands, suits, costs, expenses and damages (including
            attorney’s fees, fines or penalties imposed by any regulatory authority) arising out of or related to:
            <ul>
              <li>Your use of, or conduct in connection with the Services;</li>
              <li>Your breach or our enforcement of these Terms; or</li>
              <li>
                Your violation of any applicable law, regulation or rights of any third party during your use of the
                Services.
              </li>
            </ul>

            <br />
            If you are obligated to indemnify Coinbag or any other identified party under this clause, Coinbag will have
            the right, in its sole discretion, to control any action or proceeding and to determine if Coinbag wishes to
            settle, and if so, on what terms.

            <br /><br />
            <SectionHeader title="Termination" />

            <br />
            Coinbag has the right to immediately suspend your account and access to the Services if Coinbag suspects any
            accounts violating these Terms, including our Privacy Policy, AML Policies or any applicable laws or
            regulations. The above account controls may also apply in the following circumstances:
            <ul>
              <li>
                The account is subject to government proceedings, criminal investigation, or other pending litigation;
              </li>
              <li>We detect unauthorized access to the account;</li>
              <li>
                We are required to do so by a court order or authorized demand by a regulatory/government authority.
              </li>
            </ul>

            <br />
            <b>Cancellation</b>: Coinbag has the right to terminate these terms by canceling your account and will
            retain the right but not the obligation to permanently freeze or cancel your account’s access to the
            Services in the following circumstances:
            <ul>
              <li>After Coinbag terminates the provision of the Services to you;</li>
              <li>
                You allegedly register or register in any other person’s name as a Coinbag subscriber again, either
                directly or indirectly;
              </li>
              <li>The information that you have provided is untruthful, inaccurate, outdated, or incomplete;</li>
              <li>
                When these Terms are amended, you state your unwillingness to accept the amended Terms by applying for
                cancellation of your account or by any other means;
              </li>
              <li>You request that the Services be terminated;</li>
              <li>
                You violate the provisions contained in these Terms of Use or in Coinbag’s Privacy Policy or AML
                Policies; and
              </li>
              <li>Any other circumstances where Coinbag deems it should terminate the Services provided to you.</li>
            </ul>

            <br />
            If your account is terminated, the account and transactional information that meet data retention standards
            will be securely stored for up to 7 years.

            <br /><br />
            <SectionHeader title="Services are not financial advice" />

            <br />
            COINBAG IS NOT A FINANCIAL ADVISOR, BROKER, INTERMEDIARY, OR AGENT AND HAS NO FIDUCIARY RELATIONSHIP OR
            OBLIGATION TO YOU IN CONNECTION WITH ANY TRADES OR OTHER DECISIONS OR ACTIVITIES BY YOU USING THE SERVICES.
            NO COMMUNICATION OR INFORMATION PROVIDED TO YOU BY COINBAG IS INTENDED AS, NOR SHALL IT BE CONSTRUED AS,
            INVESTMENT ADVICE, FINANCIAL ADVICE, TRADING ADVICE, OR ANY OTHER SORT OF ADVICE.

            <br /><br />
            <SectionHeader title="Risk Disclosure" />

            <br />
            Any operation or transaction decision a user of the Coinbag platform may make is solely at his or her own
            discretion and risk. Operating with Digital Assets is risky and can result in losses greater than the user’s
            initial deposit. Hypothetical back-testing and real-time track records should not be relied upon in
            predicting future performance.

            <br /><br />
            Coinbag and its agents, brokers, affiliates or employees do not prepare and cannot be responsible for data
            and data compilation contained in or derived from back-testing and simulation features.

            <br /><br />
            Unless otherwise specified in these terms, all orders, actions and transactions are based on the parameters
            selected by you within the Services and in accordance with the execution procedures of the protocols related
            to the digital assets chosen. YOU ARE SOLELY RESPONSIBLE FOR DETERMINING IF ANY OPERATION, TRANSACTION,
            INVESTMENT, INVESTMENT STRATEGY OR RELATED TRANSACTION IS APPROPRIATE FOR YOU ACCORDING TO YOUR PERSONAL
            INVESTMENT OBJECTIVES, FINANCIAL CIRCUMSTANCES AND RISK TOLERANCE.

            <br /><br />
            It is your obligation to monitor the Services at all times to ensure that the outcomes remain consistent
            with your personal or your organization’s objectives, financial circumstances and risk tolerance and you are
            solely responsible for any loss or liability arising therefrom. You should consult legal or tax
            professionals concerning your specific situation. Before making any decision to buy, sell or hold any
            digital asset, you should conduct your own due diligence and consult your financial adviser prior to making
            any operation or trading decisions. Coinbag will not be held responsible for any decisions you make to buy,
            sell or hold digital assets based on the information provided by Coinbag, the Coinbag Platform, or any of
            its affiliates.

            <br /><br />
            <SectionHeader title="Compliance" />

            <br />
            It is your responsibility to comply with local laws in relation to the legal use of the Services in your
            local jurisdiction as well as other laws and regulations applicable to you and any terms or contractual
            requirements concerning your access to and use of the Services. You must also consider, the extent of local
            laws applicable to you concerning taxation, the withholding, collection, reporting, and remittance to their
            appropriate tax authorities.

            <br /><br />
            <SectionHeader title="Privacy Policy" />

            <br />
            The Coinbag Privacy Policy is part of these Terms and is available for you to review at{' '}
            <Link to="/privacy-policy">https://www.coinbag.finance/privacy-policy</Link>. Coinbag may amend the Privacy
            Policy from time to time by updating the policy at the designated link on the Site.

            <br /><br />
            <SectionHeader title="Dispute Resolution" />

            <br />
            <b>Notice of claim & dispute resolution period</b>: please contact Coinbag first. Coinbag would like to
            address your concerns without resorting to formal legal proceedings. If you have a dispute with Coinbag or
            an issue with any of the Services, you should contact Coinbag as a first priority. Coinbag will attempt to
            resolve your dispute directly with you as soon as possible. The parties agree to negotiate in good faith to
            resolve the dispute (and such discussions will remain confidential and be subject to applicable rules
            protecting settlement discussions from use as evidence in any subsequent legal proceedings).

            <br /><br />
            In the event that the dispute cannot be resolved satisfactorily, and you wish to assert a legal claim
            against Coinbag, you agree to outline the basis of any such claim in writing in a “Notice of Claim” as a
            form of prior notice to Coinbag. The Notice of Claim must:
            <ul>
              <li>Describe the nature and basis of the claim or dispute;</li>
              <li>Outline the specific relief sought;</li>
              <li>Include your Coinbag account email.</li>
            </ul>

            <br />
            After you have submitted the notice of claim to Coinbag, the dispute the subject of the notice of claim may
            be submitted by either Coinbag or you to arbitration in accordance with the arbitration clause of this
            document, for the avoidance of doubt, the submission of a dispute to Coinbag for resolution internally and
            the delivery of a notice of claim to Coinbag are pre-requisites to commencement of an arbitration proceeding
            (or any other legal proceeding). During the arbitration, the amount of any settlement offer made by you or
            Coinbag will not be disclosed to the arbitrator.

            <br /><br />
            <b>Agreement to arbitration & governing law</b>: You and Coinbag agree that any dispute, claim, or
            controversy between the parties arising in connection with or relating in any way to these Terms or to your
            relationship with Coinbag as a user of the Services (whether based in contract, tort, statute, fraud,
            misrepresentation, or any other legal principle, and whether the claims arise during or after the
            termination of these Terms) will be determined by mandatory final and binding individual (not class action)
            arbitration, except as detailed in clause of <i>Exceptions to Agreement to Arbitrate</i>. You and Coinbag
            further agree that the arbitrator will have the exclusive power to rule on their own jurisdiction, including
            without limitation any objections with respect to the existence, scope or validity of the Agreement to
            Arbitrate, or to the arbitrability of any claim or counterclaim. The arbitrator must follow this agreement
            and can award the same damages and relief as a court (including if applicable, attorneys fees) except that
            the award of declaratory or injunctive relief in favor of anyone but the parties to the arbitration. The
            arbitration provisions included in these Terms will survive the termination of these Terms.

            <br /><br />
            The appointing authority shall be <b>Hong Kong International Arbitration Centre (HKIAC)</b>, and any
            arbitration will be conducted in English. Regardless of the manner in which the arbitration is conducted,
            the arbitrator will issue a reasoned written decision sufficient to explain the essential findings and
            conclusions on which the decision and award, if any, are based.

            <br /><br />
            Any arbitration filed against Coinbag must be commenced by filing a request for arbitration within one year
            after the date the party asserting the claim first knows or reasonably should know of the alleged act,
            omission or default giving rise to the claim; and there will be no right to any remedy for any claim not
            asserted within that time period. This one-year period is inclusive of the internal dispute resolution
            procedure set out in the previous clauses.

            <br /><br />
            <b>Exceptions to Agreement to Arbitrate</b>: If the applicable law in any relevant jurisdiction prohibits a
            one-year limitation period for asserting claims, any claim must be asserted within the shortest time period
            permitted by applicable law.

            <br /><br />
            <b>Confidential Arbitration</b>. The Parties agree that any and all disputes, controversies or claims based
            on, arising out of, or relating to this Agreement, or the alleged breach thereof, shall be submitted to
            final and binding arbitration. Such arbitration proceeding shall be conducted confidentially and under seal.

            <br /><br />
            All arbitration shall be conducted in Hong Kong, unless otherwise agreed by the parties.

            <br /><br />
            These Terms shall be governed by and construed in accordance with the laws of Hong Kong.

            <br /><br />
            <b>Class action waiver</b>: THE PARTIES WAIVE ANY RIGHT TO ASSERT ANY CLAIMS AGAINST THE OTHER PARTY AS A
            REPRESENTATIVE OR MEMBER IN ANY CLASS OR REPRESENTATIVE ACTION, EXCEPT WHERE SUCH WAIVER IS PROHIBITED BY
            LAW OR DEEMED BY A COURT OF LAW TO BE AGAINST PUBLIC POLICY. TO THE EXTENT EITHER PARTY IS PERMITTED BY LAW
            OR COURT OF LAW TO PROCEED WITH A CLASS OR REPRESENTATIVE ACTION AGAINST THE OTHER, THE PARTIES AGREE THAT:
            (I) THE PREVAILING PARTY SHALL NOT BE ENTITLED TO RECOVER ATTORNEYS’ FEES OR COSTS ASSOCIATED WITH PURSUING
            THE CLASS OR REPRESENTATIVE ACTION (NOTWITHSTANDING ANY OTHER PROVISION IN THIS AGREEMENT); AND (II) THE
            PARTY WHO INITIATES OR PARTICIPATES AS A MEMBER OF THE CLASS WILL NOT SUBMIT A CLAIM OR OTHERWISE
            PARTICIPATE IN ANY RECOVERY SECURED THROUGH THE CLASS OR REPRESENTATIVE ACTION.

            <br /><br />
            <SectionHeader title="Miscellaneous" />

            <br />
            Coinbag is an independent contractor but not an agent of You in the performance of these Terms and provision
            of the Services.

            <br /><br />
            These Terms constitute the entire agreement between the parties concerning the provision and use of the
            Services and will supersede all prior written or oral agreements between the parties. No usage of trade or
            other regular practice or method of dealing between the parties will be used to modify, interpret,
            supplement or alter the terms in this agreement.

            <br /><br />
            If any portion of these Terms is adjudged invalid or unenforceable for any reason or to any extent, the
            remainder of these Terms will remain valid and enforceable and the invalid or unenforceable portion will be
            given effect to the greatest extent permitted by law.

            <br /><br />
            Coinbag will not be liable for any delay or failure to perform as required by these Terms because of any
            cause or condition beyond Coinbag’s reasonable control.

            <br /><br />
            You may not assign or transfer any right to use the Service(s) or any of your rights or obligations under
            these Terms without prior written consent from Coinbag, including any right or obligation related to the
            enforcement of laws or the change of control. Coinbag may assign or transfer any or all of its rights or
            obligations under these Terms, in whole or in part, without notice or obtaining your consent or approval.

            <br /><br />
            The failure of any party to require the performance of any provision will not affect the party’s right to
            require performance at any time thereafter. At the same time, the waiver of one party to seek recovery for
            the other party’s violation of these Terms or any provision of applicable terms will not constitute a waiver
            by that party of any subsequent breach or violation by the other party or of the provision itself.

            <br /><br />
            Any links to third-party websites or APIs by the Services do not imply endorsement by Coinbag of any
            product, service, information, or disclaimer presented therein, nor does Coinbag guarantee the accuracy of
            the information contained on them. If you suffer a loss from using such third-party product or service,
            Coinbag will not be liable for such loss. In addition, since Coinbag has no control over third-party
            website's terms of use or privacy policies, you should read and understand those policies carefully.
          </Box>
        </Box>
      </Box>
    </>
  );
};

TermsOfUse.routePath = TermsOfUseRoute;
TermsOfUse.loginRequired = false;
export default TermsOfUse;
