import { Box, CircularProgress, createStyles, Hidden, makeStyles } from '@material-ui/core';
import { Loading } from 'components/Loading';
import LoginApprovalModal from 'components/TransactionApproval/LoginApprovalModal';
import TransactionApprovalModal from 'components/TransactionApproval/TransactionApprovalModal';
import { Typography } from 'elements';
import { Scrollable } from 'elements/Scrollable';
import React, { useRef, useState } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import SVG from 'react-inlinesvg';
import { Link, useHistory } from 'react-router-dom';
import { DashboardRoute, NotificationsAndRequestsRoute } from 'routes';
import { isApproverWorkItem, WorkItemApproverStatus, WorkItemStatus } from 'services/types/workItem';
import { useStore as useCoinStore } from 'store/zustand/Coin';
import { useStore as useRoleStore } from 'store/zustand/Role';
import { useStore as useUserStore } from 'store/zustand/User';
import { useStore as useWorkItemStore } from 'store/zustand/WorkItem';

import { NotificationItem } from './NotificationItem';
import { NotificationItemBase } from './NotificationType';
import { TransactionApprovalItemProps } from './TransactionApprovalItem';
import { UserEventItemProps } from './UserEventItem';

const useStyles = makeStyles((theme) => createStyles({
  root: {
    borderRadius: 8,
    width: 340,
    minHeight: 'min(500px, 90vh)',
    [theme.breakpoints.down('xs')]: {
      width: '100%',
      height: '100%',
      maxWidth: '100vw',
      maxHeight: '100vh',
      borderRadius: 0,
      top: '0 !important',
      left: '0 !important',
    },
  },
  button: {
    borderRadius: 8,
    width: 32,
    height: 32,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer',
    '&:hover': {
      background: theme.palette.grey[100],
    },
    '& svg': {
      width: 32,
      height: 32,
    },
  },
  title: {
    display: 'flex',
    flexWrap: 'nowrap',
    gap: 8,
    alignItems: 'center',
  },
  viewAll: {
    display: 'flex',
    gap: 4,
    flexWrap: 'nowrap',
    alignItems: 'center',
  },
  viewAllText: {
    fontSize: 12,
    lineHeight: '16px',
    color: theme.palette.grey[500],
    fontWeight: 400,
  },
  clearButton: {
    width: 16,
    height: 16,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    '& svg': {
      width: 16,
      height: 16,
    },
  },
  modalContent: {
    display: 'flex',
    flexDirection: 'column',
    maxHeight: 'min(500px, 90vh)',
    [theme.breakpoints.down('xs')]: {
      maxHeight: '100vh',
    },
  },
  modalHeader: {
    display: 'flex',
    flexWrap: 'nowrap',
    borderBottom: `1px solid ${theme.palette.grey[200]}`,
    alignItems: 'center',
    gap: 8,
    padding: '8px 16px',
    justifyContent: 'space-between',
    [theme.breakpoints.down('xs')]: {
      padding: '14px 16px',
    },
  },
  headerText: {
    fontSize: 14,
    lineHeight: '24px',
    color: theme.palette.grey[600],
    fontWeight: 500,
    [theme.breakpoints.down('xs')]: {
      fontSize: 16,
      lineHeight: '24px',
      color: theme.palette.grey[700],
    },
  },
  loading: {
    position: 'absolute',
    left: 0,
    top: 0,
    zIndex: 1,
    backdropFilter: 'blur(12px)',
    background: 'rgba(255, 255, 255, 0.6)',
  },
  empty: {
    fontSize: 12,
    lineHeight: '16px',
    color: theme.palette.grey[400],
    fontWeight: 400,
    alignSelf: 'center',
    justifySelf: 'center',
    width: '100%',
    textAlign: 'center',
    margin: '48px 0',
  },
  backButton: {
    cursor: 'pointer',
    width: 32,
    height: 32,
  },
  alert: {
    '& circle': {
      fill: theme.palette.red[700],
      stroke: theme.palette.red[700],
    },
  },
}));

const Popover = React.lazy(() => import('@material-ui/core/Popover'));
const Dialog = React.lazy(() => import('@material-ui/core/Dialog'));

export const Notifications = () => {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const [loading, setLoading] = React.useState(true);
  const [items, setItems] = React.useState<NotificationItemBase[]>([]);
  const [modalItem, setModalItem] = React.useState<number | undefined>(undefined);
  const [modalOpen, setModalOpen] = React.useState(false);
  const [loginModalOpen, setLoginModalOpen] = React.useState(false);
  const [loginModalShown, setLoginModalShown] = React.useState(false);
  const [isWorkItemAvailable, setIsWorkItemAvailable] = useState<boolean>(true);
  const [alert, setAlert] = React.useState(false);

  const [userEvents, fetchEvents, user] = useUserStore((state) => [state.userEvents, state.fetchEvents, state.user]);
  const [fetchCoins] = useCoinStore((state) => [state.fetchCoins]);
  const [workItems, fetchWorkItems] = useWorkItemStore((state) => [state.workItems, state.fetchWorkItems]);
  const [validateApprovalToken] = useRoleStore((state) => [state.validateApprovalToken]);

  const history = useHistory();

  const anchorEl = useRef<HTMLDivElement>(null);

  const handleClose = () => {
    setOpen(false);
    setItems(items.slice(0, 15));
  };

  const addItems = () => {
    if (allItems && items.length < allItems?.length) {
      setItems(items.concat(allItems?.slice(items.length, items.length + 5)));
    }
  };

  React.useEffect(() => {
    fetchWorkItems(false);
  }, []);

  const workItemsToApprove = React.useMemo(() => {
    if (!user || workItems === undefined) return [];
    return workItems?.filter((item) => (
      item.status === WorkItemStatus.WaitingForApproval && item.approvers.findIndex((approver) => (
        approver.email === user.email && approver.status === WorkItemApproverStatus.Pending
      )) >= 0)) || [];
  }, [workItems, user]);

  React.useEffect(() => {
    const checkWorkItemToApprove = async () => {
      setAlert(workItemsToApprove.length > 0);

      const query = new URLSearchParams(window.location.search);
      const approveToken = query.get('approve');

      if (!loginModalShown
        && workItemsToApprove.length > 0
        && window.location.pathname === DashboardRoute
        && !approveToken) {
        setLoginModalOpen(true);
        setLoginModalShown(true);
      }

      if (approveToken && workItems !== undefined) {
        try {
          const { workItemId } = await validateApprovalToken(approveToken);
          const hasTokenWorkItem = workItemsToApprove.find((item) => item.workItemId === workItemId);
          if (hasTokenWorkItem) {
            setModalItem(workItemId);
          } else {
            setIsWorkItemAvailable(false);
          }
        } catch (e) {
          setIsWorkItemAvailable(false);
        } finally {
          setModalOpen(true);
          query.delete('approve');
          history.replace({
            search: query.toString(),
          });
        }
      }
    };
    checkWorkItemToApprove();
  }, [workItemsToApprove]);

  const allItems = React.useMemo(() => {
    const mappedItems: NotificationItemBase[] = [];
    if (userEvents !== undefined && workItems !== undefined && user !== undefined) {
      mappedItems.push(...workItems
        .filter((item) => (
          isApproverWorkItem(item.name)
          && (item.status === WorkItemStatus.WaitingForApproval
            || ((item.status === WorkItemStatus.Rejected || item.status === WorkItemStatus.Pending)
              && item.userId === user?.userId))
        ))
        .map((item): TransactionApprovalItemProps => ({
          item,
          creation: new Date(item.lastUpdatedTs),
          type: 'transactionApproval',
          onSeeDetail: () => {
            setModalItem(item.workItemId);
            setModalOpen(true);
          },
        })));
      mappedItems.push(...userEvents
        .map((event): UserEventItemProps => ({
          title: event.title,
          summary: event.summary,
          creation: new Date(event.timestamp),
          eventType: event.eventType,
          coin: event.coin,
          type: 'UserEvent',
        })));
      mappedItems.sort((a, b) => (b.creation.getTime() - a.creation.getTime()));
      setItems(mappedItems.slice(0, 10));
      return mappedItems;
    }
    return undefined;
  }, [userEvents, workItems, user]);

  React.useEffect(() => {
    const fetch = async () => {
      if (open) {
        setLoading(true);
        fetchCoins();
        await Promise.all([fetchEvents(false), fetchWorkItems(true)]);
        setLoading(false);
      }
    };
    fetch();
  }, [open]);

  const content = React.useMemo(() => (
    <Box className={classes.modalContent}>
      <Box className={classes.modalHeader}>
        <Box className={classes.title}>
          <Hidden smUp>
            <Box onClick={handleClose}>
              <SVG src="/icons/dashboard/ic_arrow_left.svg" />
            </Box>
          </Hidden>
          <Typography className={classes.headerText} component="span">
            Notifications
            {loading && (
              <CircularProgress
                size={14}
                thickness={5}
                color="primary"
                style={{ verticalAlign: 'middle', marginLeft: 8 }}
              />
            )}
          </Typography>
        </Box>
        <Link to={NotificationsAndRequestsRoute} className={classes.viewAll}>
          <Typography className={classes.viewAllText}>View all</Typography>
        </Link>
      </Box>
      <Scrollable id="scrollable">
        <InfiniteScroll
          dataLength={items?.length ?? 0}
          next={addItems}
          hasMore={(allItems?.length ?? 0) > items.length}
          loader={<></>}
          scrollableTarget="scrollable"
        >
          {items?.map((notification, index) => (
            <NotificationItem key={index} {...notification} />
          ))}
        </InfiniteScroll>
      </Scrollable>
      {loading && (!items || items?.length <= 0) && <Loading className={classes.loading} />}
      {!loading && (!items || items?.length <= 0) && <Typography className={classes.empty}>No messages</Typography>}
    </Box>
  ), [items, allItems, loading]);

  const handleOnModalClose = () => { setModalOpen(false); setIsWorkItemAvailable(true); };
  return (
    <Box>
      <div
        className={`${classes.button} ${alert ? classes.alert : ''}`}
        ref={anchorEl}
        onClick={() => setOpen((prev) => !prev)}
      >
        <SVG src="/icons/dashboard/ic_notification.svg" />
      </div>
      <Hidden xsDown>
        <React.Suspense fallback={<></>}>
          <Popover
            open={open}
            onClose={handleClose}
            anchorEl={anchorEl.current}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            PaperProps={
              { className: classes.root }
            }
          >
            {content}
          </Popover>
        </React.Suspense>
      </Hidden>
      <Hidden smUp>
        <React.Suspense fallback={<></>}>
          <Dialog
            open={open}
            onClose={handleClose}
            fullScreen
          >
            {content}
          </Dialog>
        </React.Suspense>
      </Hidden>
      <TransactionApprovalModal
        open={modalOpen}
        setOpen={setModalOpen}
        id={modalItem}
        isWorkItemAvailable={isWorkItemAvailable}
        openNotificationBox={() => { handleOnModalClose(); setOpen(true); }}
        onClose={handleOnModalClose}
      />
      <LoginApprovalModal
        open={loginModalOpen}
        setOpen={setLoginModalOpen}
        items={workItemsToApprove}
        setModalItem={setModalItem}
        setModalOpen={setModalOpen}
        setNotificationsOpen={setOpen}
      />
    </Box>
  );
};

export default Notifications;
