import {
  Box,
  createStyles,
  Dialog,
  DialogProps,
  Hidden,
  makeStyles,
  Theme,
  Typography,
  useMediaQuery,
  useTheme,
} from '@material-ui/core';
import * as React from 'react';
import SVG from 'react-inlinesvg';

type LightModalProps = Omit<DialogProps, 'maxWidth'> & {
  children: React.ReactNode;
  fullScreenOnMobile?: boolean;
  // eslint-disable-next-line no-unused-vars
  setOpen: (value: boolean) => void;
  maxWidth?: number;
  modalTitle?: string | React.ReactNode;
  titleItem?: React.ReactNode;
  showCloseButton?: boolean;
  noPadding?: boolean;
  className?: string;
}

const useStyles = makeStyles<Theme, Partial<LightModalProps> & { isFullScreen: boolean }>(
  (theme) => createStyles({
    modal: {
      borderRadius: 8,
      outline: 0,
      width: '100%',
      display: 'flex',
      overflow: 'hidden',
      maxWidth: ({ maxWidth }) => (maxWidth || '100%'),
    },
    modalContent: {
      display: 'flex',
      flexDirection: 'column',
      padding: ({ noPadding, isFullScreen }) => ((noPadding || isFullScreen) ? 0 : 16),
      gap: ({ isFullScreen }) => (isFullScreen ? 0 : 16),
      maxHeight: 'calc(100vh - 64px)',
    },
    modalTitle: {
      color: theme.palette.grey[800],
      fontSize: 16,
      lineHeight: '24px',
      fontWeight: 500,
      width: '100%',
      marginTop: ({ noPadding, isFullScreen }) => (noPadding && !isFullScreen ? 16 : 0),
      padding: ({ showCloseButton, isFullScreen }) => (showCloseButton && !isFullScreen ? '0 40px' : 0),
    },
    title: {
      display: 'flex',
      flexWrap: 'nowrap',
      gap: 8,
      alignItems: 'center',
      flexGrow: 1,
    },
    closeButton: {
      position: 'absolute',
      top: 16,
      right: 16,
      width: 20,
      height: 20,
      cursor: 'pointer',
      '& path': { fill: theme.palette.grey[300] },
      [theme.breakpoints.down('sm')]: {
        right: 16,
      },
    },
    fullscreen: {
      borderRadius: 0,
      maxWidth: '100% !important',
      '& $modalContent': {
        maxHeight: '100vh',
        height: '100%',
      },
    },
    modalHeader: {
      display: 'flex',
      flexDirection: 'column',
      borderBottom: `1px solid ${theme.palette.grey[200]}`,
      alignItems: 'stretch',
      justifyContent: 'space-between',
      [theme.breakpoints.down('xs')]: {
        padding: '14px 16px',
      },
    },
    headerText: {
      fontSize: 14,
      lineHeight: '24px',
      color: theme.palette.grey[600],
      fontWeight: 500,
      padding: '8px 16px',
      [theme.breakpoints.down('xs')]: {
        fontSize: 16,
        lineHeight: '24px',
        color: theme.palette.grey[700],
      },
    },
    backButton: {
      cursor: 'pointer',
    },
  }),
);

const LightModal = ({
  maxWidth,
  noPadding,
  showCloseButton,
  fullScreenOnMobile,
  modalTitle,
  titleItem,
  children,
  className,
  setOpen,
  ...dialogProps
}: LightModalProps) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('xs'));
  const isFullScreen = React.useMemo(() => (
    (dialogProps.fullScreen || (isMobile && fullScreenOnMobile)) ?? false
  ), [dialogProps.fullScreen, isMobile, fullScreenOnMobile]);
  const classes = useStyles({ maxWidth, noPadding, isFullScreen, showCloseButton });
  return (
    <Dialog
      {...dialogProps}
      onClose={
        dialogProps.onClose || (() => { setOpen(false); })
      }
      fullScreen={isFullScreen}
      PaperProps={
        {
          ...dialogProps.PaperProps,
          className: `${dialogProps.PaperProps?.className ?? ''} ${(dialogProps.fullScreen || (isMobile && fullScreenOnMobile)) ? classes.fullscreen : ''} ${classes.modal}`,
        }
      }
    >
      <Box className={`${classes.modalContent} ${className || ''}`}>
        {modalTitle ? (isFullScreen ? (
          <Box className={classes.modalHeader}>
            <Box className={classes.title}>
              <Hidden smUp>
                <Box onClick={() => { if (dialogProps.onClose) { dialogProps.onClose({}, 'backdropClick'); } setOpen(false); }}>
                  <SVG className={classes.backButton} src="/icons/dashboard/ic_arrow_left.svg" />
                </Box>
              </Hidden>
              {typeof modalTitle === 'string' ? <Typography className={classes.modalTitle}>{modalTitle}</Typography> : modalTitle}
            </Box>
            {titleItem}
          </Box>
        ) : (typeof modalTitle === 'string' ? (
          <>
            <Typography className={classes.modalTitle} align="center">{modalTitle}</Typography>
            {titleItem}
          </>
        ) : (
          <>
            {modalTitle}
            {titleItem}
          </>
        ))
        ) : titleItem}
        {showCloseButton && !isFullScreen && (
          <SVG
            src="icons/dashboard/ic_cross.svg"
            className={classes.closeButton}
            onClick={() => { if (dialogProps.onClose) { dialogProps.onClose({}, 'backdropClick'); } setOpen(false); }}
          />
        )}
        {children}
      </Box>
    </Dialog>
  );
};

export default LightModal;
