import { makeStyles, Typography } from '@material-ui/core';
import React, { FC } from 'react';
import { Link } from 'react-router-dom';

import { Card } from '../../../elements';
import { DashboardUstRoute } from '../../../routes';

type Terra2CardProps = {
  className?: string
}

const useStyles = makeStyles((theme) => ({
  root: {
    overflow: 'hidden',
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',

    padding: 16,
    height: '100%',
    width: '100%',

    [theme.breakpoints.up('sm')]: {
      padding: 24,
    },
  },

  actionRoot: {
    justifyContent: 'center',
    alignItems: 'left',
    rowGap: 2,

    background: theme.palette.common.white,

    border: '2px dashed',
    borderColor: theme.palette.grey['200'],
  },

  background: {
    position: 'absolute',
    top: 0,
    right: 0,

    height: '100%',
    transform: 'scale(-1, -1)',
  },
  actionBackground: {
    position: 'absolute',
    right: 0,
    bottom: 0,
    height: '100%',
    zIndex: 0,
  },

  title: {
    [theme.breakpoints.down('sm')]: {
      fontSize: 12,
      fontWeight: 500,
      lineHeight: '20px',
    },
  },

  link: {
    color: theme.palette.green['400'],
    textDecorationLine: 'underline',
  },
  centerLink: {
    zIndex: 20,
    [theme.breakpoints.down('sm')]: {
      margin: 'auto',
    },
  },
}));

const Terra2Card: FC<Terra2CardProps> = ({ className }) => {
  const classes = useStyles();

  return (
    <Card className={`${className} ${classes.root} ${classes.actionRoot}`}>
      <img className={classes.actionBackground} alt="" src="/images/dashboard/bg_interest_total.svg" />

      <Typography variant="h4" className={classes.title} align="center">
        Coinbag will support the Terra 2.0 airdrop for customers holding UST during the de-pegging event.
      </Typography>

      <Link to={DashboardUstRoute} className={classes.centerLink}>
        <Typography variant="h6" className={classes.link} align="center">
          LEARN MORE
        </Typography>
      </Link>
    </Card>
  );
};

Terra2Card.displayName = 'Terra2Announcement';
export { Terra2Card };
