import { RebalancingMethod } from 'models/Bundle';
// eslint-disable-next-line import/extensions
import { coinConfigToMap } from 'services/protocol-service';
import { CoinConfig } from 'services/types/wallet';
import { create } from 'zustand';

import { BundleV2 } from '../../services/types/bundle';
import UserBundleService from '../../services/userbundle-service';
import { EarningType } from './InterestEarning';

export enum PortfolioSettingPage {
  ReadyMade,
  Custom,
  Rebalancing,
  LendingProtocol,
}

interface PortfolioSettingStore {
  // Manage Crypto
  userOwnBundle?: BundleV2;
  fetchUserOwnBundle: () => void;

  // Interrest Setting
  selectedEarningType?: EarningType;
  // eslint-disable-next-line no-unused-vars
  setSelectedEarningType: (type: EarningType) => void;

  // Rebalance Setting
  selectedRebalancingMethod: RebalancingMethod;
  // eslint-disable-next-line no-unused-vars
  setRebalancingMethod: (method: RebalancingMethod) => void;
  selectedProtocolId?: number;
  // eslint-disable-next-line no-unused-vars
  setSelectedProtocolId: (id: number | undefined) => void;
  selectedCoinConfig?: Record<number, number>;
  // eslint-disable-next-line no-unused-vars
  setSelectedCoinConfig: (config: CoinConfig[] | undefined) => void;
  // eslint-disable-next-line no-unused-vars
  addSelectedCoinConfig: (coinId: number, protocolId: number) => void;

  // Page Control
  currentPage: PortfolioSettingPage;
  // eslint-disable-next-line no-unused-vars
  goTo: (page: PortfolioSettingPage) => void;
}

export const useStore = create<PortfolioSettingStore>((set, get) => ({
  currentPage: PortfolioSettingPage.ReadyMade,
  userOwnBundle: undefined,
  async fetchUserOwnBundle() {
    const response = await UserBundleService.getUserOwnBundle();
    const bundle = response.data;
    if (bundle) {
      set(() => ({ userOwnBundle: bundle }));
    }
  },

  selectedEarningType: undefined,
  setSelectedEarningType(type: EarningType) {
    set(() => ({ selectedEarningType: type }));
  },
  selectedProtocolId: undefined,
  setSelectedProtocolId(id) {
    set(() => ({ selectedProtocolId: id }));
  },
  selectedCoinConfig: undefined,
  setSelectedCoinConfig(config) {
    set({ selectedCoinConfig: config ? coinConfigToMap(config) : undefined });
  },
  addSelectedCoinConfig(coinId, protocolId) {
    const config = { ...(get().selectedCoinConfig || {}) };
    config[coinId] = protocolId;
    set({ selectedCoinConfig: config });
  },

  selectedRebalancingMethod: RebalancingMethod.Manual,
  setRebalancingMethod(method) {
    set(() => ({ selectedRebalancingMethod: method }));
  },
  goTo(page) {
    set(() => ({ currentPage: page }));
  },
}));
