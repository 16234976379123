import { createStyles, makeStyles } from '@material-ui/core';
import * as React from 'react';

const useStyles = makeStyles(() => createStyles({
  coinIcon: {
    width: '100%',
    height: '100%',
    borderRadius: '100px',
    overflow: 'hidden',
    boxShadow: '0px 2px 4px rgba(144, 156, 189, 0.32)',
    display: 'inline',
    border: '1px solid #FFFFFF',
  },
}));

interface CoinIconBadgeProps {
  src: string;
}

export const CoinIconBadge = (props: CoinIconBadgeProps) => {
  const classes = useStyles();

  return (
    <img className={classes.coinIcon} src={`data:image/svg+xml;utf8,${encodeURIComponent(props.src)}`} alt="icon" />
  );
};
