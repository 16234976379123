import { Box, createStyles } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Typography } from 'elements';
import React, { useEffect, useState } from 'react';
import SVG from 'react-inlinesvg';
import { CoinsWithMasterCoins } from 'services/types/coin';
import { PayrollEstimatedGasFee, PayrollId, PayrollWithExchangeRate } from 'services/types/payroll';

interface Props {
  updatedTs?: number;
  totalFeeUsd?: number;
  estimateFees: () => void;
  isGasEstError: boolean;
  isInsufficientGas: boolean;
}

const useStyles = makeStyles((theme) => createStyles({
  estimateFeeWrapper: {
    display: 'flex',
    alignItems: 'center',
    gap: 8,
  },
  refreshButton: {
    '&:hover': {
      cursor: 'pointer',
    },
    '& path:first-child': {
      fill: theme.palette.grey[300],
    },
    '& path:last-child': {
      stroke: theme.palette.grey[300],
    },
  },
}));

export const EstimateFee = ({ updatedTs, totalFeeUsd, estimateFees, isGasEstError, isInsufficientGas }: Props) => {
  const classes = useStyles();

  const [timeSinceLastUpdate, setTimeSinceLastUpdate] = useState<string>('00:00');

  useEffect(() => {
    const intervalAction = setInterval(() => {
      const currentTime = Date.now();
      const numSeconds = updatedTs ? Math.floor((currentTime - updatedTs) / 1000) : 0;
      const sec = numSeconds % 60;
      const min = Math.floor((numSeconds % 3600) / 60);
      const hour = Math.floor(numSeconds / 3600);
      const timeText = `${hour > 0 ? hour.toLocaleString()
        : ''}${hour > 0 ? ':' : ''}${min < 10 ? '0' : ''}${min}:${sec < 10 ? '0' : ''}${sec}`;
      setTimeSinceLastUpdate(timeText);
    }, 500);
    return () => clearInterval(intervalAction);
  }, [updatedTs]);

  return (updatedTs !== undefined || totalFeeUsd !== undefined || isGasEstError) ? (
    <Box className={classes.estimateFeeWrapper}>
      {!isGasEstError && (
        <Typography variant="h6" paletteColor={500} strong={false}>
          <Typography
            variant="h6"
            palette={isInsufficientGas ? 'red' : 'green'}
            paletteColor={600}
            component="span"
          >≈ ${totalFeeUsd?.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
          </Typography>&nbsp; {updatedTs && `| ${timeSinceLastUpdate}`}
        </Typography>
      )}
      {isGasEstError && (
        <Typography variant="h6" palette="red" paletteColor={700} noWrap strong={false}>
          Failed to estimate gas
        </Typography>
      )}
      <SVG
        src="icons/dashboard/ic_refresh.svg"
        width={24}
        height={24}
        onClick={estimateFees}
        className={classes.refreshButton}
      />
    </Box>
  ) : null;
};

export const findTotalFeeUsd = (groupedFeeByChainId: undefined | Record<string, number>) => {
  if (groupedFeeByChainId === undefined) return undefined;
  return Object.entries(groupedFeeByChainId)
    .reduce((prev, [, usdFee]) => prev + usdFee, 0);
};

export const groupUsdFeesByChain = (
  coins: CoinsWithMasterCoins,
  estimatedFees?: { fees: Record<PayrollId, PayrollEstimatedGasFee> },
  payrolls?: PayrollWithExchangeRate[],
) => {
  if (estimatedFees === undefined || payrolls === undefined || Object.entries(coins).length <= 0) return undefined;
  const { fees } = estimatedFees;
  return payrolls
    .filter((pr) => pr.enable)
    .reduce((prev: Record<string, number>, curr) => {
      const coinChainId = coins[curr.coinId]?.chainId;
      const updatedData = { [coinChainId]: (fees[curr.payrollId]?.usdValue ?? 0) + (prev[coinChainId] ?? 0) };
      return { ...prev, ...updatedData };
    }, {});
};
