import React from "react";
import AuthService from "../services/auth-service";
import { HashLink as Link } from 'react-router-hash-link';
import { Navbar, Nav, NavDropdown, Container, Row, Col } from 'react-bootstrap'
import { BackArrowIcon, LockIcon, OpenLockIcon } from "../components/Icons.js";
import Sidebar from "react-sidebar";
import { ExitIcon, ProfileIcon } from "./Icons";
import EditConnection from "../pages/desktop/EditConnection";
import Welcome from "../pages/mobile/Welcome";
import ChooseExchange from "../pages/responsive/ChooseExchange";
import RiskProfile from "../pages/mobile/RiskProfile";
import ConnectExchange from "../pages/mobile/ConnectExchange";
import ProfileReview from "../pages/mobile/ProfileReview";
import walletService from "../services/wallet-service";
import ReviewDeposit from "../pages/responsive/ReviewDeposit";
import Withdraw from "../pages/responsive/Withdraw";
import { AccountDeleteRoute, DashboardRoute } from "routes";


export const InternalNavbar = class InternalNavbar extends React.Component {
  constructor(props) {
    super(props);
    this.sideBarToggle = this.sideBarToggle.bind(this);
    this.getSideBarName = this.getSideBarName.bind(this);
    this.handleLogout = this.handleLogout.bind(this);

    const currentUser = AuthService.getCurrentUser()
    this.state = {
      sideBarToggle: false,
      currentUser: currentUser,
      sideBarName: this.getSideBarName(currentUser.email),
      sideBarWidth: "90%"
    };

    walletService.getWallet().then(w => { this.setState({ exchangeId: w.data.exchangeId }) })

  }

  getSideBarName(email) {
    const arr = email.split(".")
    const domain = arr[arr.length - 2]
    return email.charAt(0) + "**@**" + domain.charAt(domain.length - 1) + "." + arr[arr.length - 1]
  }

  sideBarToggle() {
    this.setState({ sideBarToggle: !this.state.sideBarToggle });
  }

  handleLogout() {
    AuthService.logout();
    window.location.href = '/'
  }

  componentDidMount() {
    const isDesktop = window.matchMedia('(min-width: 768px)').matches
    var clientHeight = this.props.title ? 120 : 60 // INACCURATE
    if (isDesktop) {
      this.setState({ sideBarWidth: "45%" })
      clientHeight = 76
    }
    document.body.style = "margin-top: " + clientHeight + "px!important";
  }

  removePage(allPages, toRemove) {
    return allPages.map(page => {
      if (page.sub) {
        page.sub = page.sub.filter(subpage => !toRemove.includes(subpage.path))
      }
      if (!toRemove.includes(page.path)) {
        return page
      }
    }).filter(x => x)
  }

  navbarLinkFromUserStep(sidebar) {
    let step = Number.parseInt(sessionStorage.getItem("userStep"))
    if (false) {
      var page = [
        { path: DashboardRoute, title: "Dashboard" },
        { path: ReviewDeposit.routePath, title: <><div id="dynamic"></div>Deposit</> },
        { path: Withdraw.routePath, title: "Withdraw" },
        // { path: ManageExchange.routePath, title: "Exchange management" },
        {
          title: "Settings",
          sub: [
            { path: EditConnection.routePath, title: "Exchange connection" },
            { path: AccountDeleteRoute, title: "Account details" }
          ]
        }
      ]

      if (this.state.exchangeId && this.state.exchangeId !== 4) {
        page = this.removePage(page, [Withdraw.routePath, ReviewDeposit.routePath])
      } else if (this.state.exchangeId && this.state.exchangeId === 4) {
        page = this.removePage(page, [EditConnection.routePath])
      }

      return page
    }

    if (!sidebar) {
      return []
    }

    let pages = [
      { path: Welcome.routePath, title: "Welcome", step: 0 },
      { path: RiskProfile.routePath, title: "Risk Assessment", step: 2 },
      { path: ChooseExchange.routePath, title: "Choose Exchange", step: 3 },
      { path: ConnectExchange.routePath, title: "Connect Exchange/Fund wallet", step: 4 },
      { path: ProfileReview.routePath, title: "Final Review", step: 4 },
    ]

    return pages.map((page) => {
      if (step >= page.step || step < 0) {
        page.locked = false
      } else {
        page.locked = true
      }
      return page;
    })
  }

  renderPage(page, i, Item) {
    var clazz = page.locked === true ? "nav-link-disabled" : ""
    clazz = page.locked === false ? "nav-link-enabled" : clazz
    return (
      <Item key={i} className={clazz} href={page.locked ? "" : page.path}>
        {page.locked === false ? <span className="lock-icon"><OpenLockIcon /></span> : null}
        {page.locked === true ? <span className="lock-icon"><LockIcon /></span> : null}
        {page.title}
      </Item>
    )
  }

  render() {
    return (
      <div className="bg-white">
        <Sidebar
          sidebar={
            <Container>
              <Row>
                <Col xs={9}></Col>
                <Col className="text-right pt-3 px-4" onClick={this.sideBarToggle}>
                  <ExitIcon />
                </Col>
              </Row>
              <Navbar expand="lg">
                <Nav>
                  <Nav.Item >{this.state.sideBarName}</Nav.Item>
                  <NavDropdown.Divider />
                  {this.navbarLinkFromUserStep(true).map((page, i) => {
                    if (page.sub) {
                      return (
                        page.sub.map((subpage, i) => {
                          return this.renderPage(subpage, i, Nav.Link)
                        })
                      )
                    } else {
                      return this.renderPage(page, i, Nav.Link)
                    }
                  })}
                  <Nav.Item className="mx-4 mb-5 fixed-bottom"><button className="btn-inverse btn-block" onClick={this.handleLogout}>Log out</button></Nav.Item>
                </Nav>
              </Navbar>
            </ Container>
          }
          sidebarClassName="sidebar"
          overlayClassName="sidebar-overlay"
          open={this.state.sideBarToggle}
          onSetOpen={this.sideBarToggle}
          styles={{ sidebar: { background: "#1D2129", width: this.state.sideBarWidth } }}
          pullRight={true}
        ><></></Sidebar>

        <Navbar expand="lg" id="navbar" className="fixed-top bg-white">
          <Container fluid className="px-4 py-3 m-0">
            <Navbar.Brand href="/"><span>COIN</span>BAG</Navbar.Brand>
            <Navbar.Toggle onClick={this.sideBarToggle} >
              <ProfileIcon />
            </ Navbar.Toggle>
            <Navbar className="d-lg-flex" id="navbar-white">
              <Nav className="mr-auto align-items-center">
                {this.navbarLinkFromUserStep().map((page, j) => {
                  if (page.sub) {
                    return (
                      <NavDropdown key={j} title={page.title}>
                        {page.sub.map((subpage, i) => {
                          return this.renderPage(subpage, i, NavDropdown.Item)
                        })}
                      </NavDropdown>
                    )
                  } else {
                    return this.renderPage(page, j, Nav.Link)
                  }
                })}
              </Nav>
              <Nav.Link className="p-0">
                <button className="btn-inverse btn-block py-2" onClick={this.handleLogout}>Log out</button>
              </Nav.Link>
            </Navbar>
          </Container>
          {this.props.title && (
            <Container className="justify-content-center navbar-title d-lg-none">
              <Row className="mt-0">
                {this.props.backArrow === false ? null :
                  <div className="navbar-back pointer" onClick={() => this.props.history.go(-1)}>
                    <BackArrowIcon />
                  </div>
                }
                <Col className="nav-title w-100">
                  <h5 className="mb-0 text-center">{this.props.title}</h5>
                </Col>
              </Row>
            </Container>
          )}
        </Navbar>
      </div>
    );
  }
}

export const Navibar = class Navbar extends React.Component {
  render() {
    const currentUser = AuthService.getCurrentUser();
    return (
      <div className="navbar navbar-default navbar-fixed-top">
        <div className="container">

          <div className="navbar-header">
            <button className="navbar-toggle external" data-toggle="collapse" data-target=".navbar-collapse">
              <span className="icon icon-bar"></span>
              <span className="icon icon-bar"></span>
              <span className="icon icon-bar"></span>
            </button>
            <a href="/" className="navbar-brand"><span>Coin</span>bag</a>
          </div>

          <div id="ext-navbar-collapse" className="collapse navbar-collapse">
            <ul className="nav navbar-nav navbar-right">
              <li><Link to="#how-it-works-scroll" smooth={true}>How it works</Link></li>
              <li><Link to="#pricing-scroll" smooth={true}>Features</Link></li>
              <li><Link to="#about-scroll" smooth={true}>Our team</Link></li>
              <li><a className="login-btn" href={currentUser ? "/dashboard" : "/login"}> {currentUser ? "Dashboard" : "Sign in"} </a></li>
            </ul>
          </div>

        </div>
      </div>
    )
  }
}

