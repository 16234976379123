import { Box, createStyles, Hidden, Link, makeStyles, Theme } from '@material-ui/core';
import { Typography } from 'elements/Typography';
import * as React from 'react';
import SVG from 'react-inlinesvg';
import { SignUpIndividualInvestorsRoute } from 'routes';

import { SignUp } from '.';
import Clients from './Components/Clients';
import PrivateClientBanner from './Components/PrivateClientBanner';
import { individualInvestorsSteps } from './Components/Steps';

const useStyles = makeStyles((theme: Theme) => createStyles({
  root: {
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
    color: theme.palette.grey[700],
    '& em': {
      color: theme.palette.green[600],
      fontStyle: 'normal',
    },
    '& #private_client': {
      marginTop: -80,
      paddingTop: 80,
    },
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    width: '100%',
    maxWidth: 1080,
    padding: 16,
    gap: 120,
    alignItems: 'center',
    [theme.breakpoints.up('md')]: {
      paddingTop: 48,
    },
    [theme.breakpoints.down('sm')]: {
      maxWidth: 640,
      gap: 40,
    },
    '&::before': {
      content: '" "',
      width: '1600px',
      height: '1200px',
      top: '-400px',
      left: '40%',
      position: 'absolute',
      backfaceVisibility: 'hidden',
      perspective: 1000,
      transform: 'translateZ(0)',
      background: 'radial-gradient(50% 50% at 50% 50%, rgba(46, 255, 218, 0.315) 0%, rgba(255, 255, 255, 0) 100%)',
      zIndex: 0,
      filter: 'blur(80px)',
      [theme.breakpoints.down('sm')]: {
        width: '1400px',
        height: '680px',
        top: '-300px',
        left: 'unset',
      },
    },
  },
  title: {
    display: 'flex',
    flexDirection: 'column',
    gap: 20,
    '& h1': {
      fontSize: 44,
      lineHeight: '80px',
      fontWeight: 700,
      [theme.breakpoints.down('sm')]: {
        fontSize: 24,
        lineHeight: '44px',
        textAlign: 'center',
      },
    },
    '& h2': {
      fontSize: 20,
      lineHeight: '32px',
      fontWeight: 500,
      [theme.breakpoints.down('sm')]: {
        fontSize: 12,
        lineHeight: '20px',
        fontWeight: 400,
        textAlign: 'center',
      },
    },
    '& h3': {
      fontSize: 16,
      lineHeight: '28px',
      fontWeight: 500,
      [theme.breakpoints.down('sm')]: {
        fontSize: 14,
        lineHeight: '24px',
      },
    },
    '& em': {
      color: theme.palette.green[600],
      fontStyle: 'normal',
    },
    [theme.breakpoints.down('sm')]: {
      gap: 12,
    },
    [theme.breakpoints.up('md')]: {
      position: 'sticky',
      top: 170,
    },
  },
  learnAbout: {
    width: '100%',
    marginTop: 8,
    display: 'inline-block',
    '& h3': {
      fontSize: 16,
      lineHeight: '28px',
      fontWeight: 600,
      display: 'inline',
      [theme.breakpoints.down('sm')]: {
        fontSize: 14,
        lineHeight: '24px',
      },
    },
    '& svg': {
      marginLeft: 8,
    },
    [theme.breakpoints.down('sm')]: {
      textAlign: 'center',
    },
  },
  learnMore: {
    width: '100%',
    margin: '0 0 200px',
    [theme.breakpoints.down('sm')]: {
      margin: '80px 0 100px',
    },
  },
  feature: {
    marginTop: -80,
    paddingTop: 80,
  },
}));

const DashboardFeatures = React.lazy(() => import('../../../components/Marketing/DashboardFeatures/DashboardFeatures'));
const LearnMoreAboutCoinbag = React.lazy(() => import('../../../components/Marketing/LearnMoreAboutCoinbag'));
const Supported = React.lazy(() => import('../../../components/Marketing/Supported'));
const PrivateClientDetail = React.lazy(() => import('./Components/PrivateClientDetail'));
const Steps = React.lazy(() => import('./Components/Steps'));
const Pricing = React.lazy(() => import('../Home/Pricing/Pricing'));

const IndividualInvestors = () => {
  const classes = useStyles();

  return (
    <main className={classes.root}>
      <Box className={classes.content}>
        <SignUp
          type="individual"
          title={(
            <article className={classes.title}>
              <Typography variant="h1">Sign up to Coinbag <br /> as an <em>Individual</em></Typography>
              <Hidden smDown>
                <Typography variant="h2">
                  In only a few minutes you can build a crypto portfolio, deposit, and start earning interest! Supercharge your portfolio, today!
                </Typography>
                <PrivateClientBanner />
              </Hidden>
              <Link className={classes.learnAbout} href="#feature" underline="none">
                <Typography variant="h3" uppercase paletteColor={600} palette="green">
                  Learn about our individual investor solutions
                </Typography>
                <SVG src="/icons/home/ic_arrow_down.svg" width={22} height={22} />
              </Link>
            </article>
          )}
        >
          <Clients />
          <React.Suspense fallback={<></>}>
            <Box id="feature" className={classes.feature}>
              <Steps steps={individualInvestorsSteps} />
            </Box>
          </React.Suspense>
          <React.Suspense fallback={<></>}>
            <PrivateClientDetail />
          </React.Suspense>

          <React.Suspense fallback={<></>}>
            <Pricing type="individual" />
          </React.Suspense>
          <React.Suspense fallback={<></>}>
            <Hidden smDown>
              <DashboardFeatures selectType={0} />
            </Hidden>
            <Supported />
            <Hidden mdUp>
              <DashboardFeatures selectType={0} />
            </Hidden>
            <Box className={classes.learnMore}>
              <LearnMoreAboutCoinbag />
            </Box>
          </React.Suspense>
        </SignUp>
      </Box>
    </main>
  );
};

IndividualInvestors.routePath = SignUpIndividualInvestorsRoute;
IndividualInvestors.logoutRequired = true;

export default IndividualInvestors;
