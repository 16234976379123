import { Box, BoxProps, Link, makeStyles, Typography } from '@material-ui/core';
import { Button } from 'elements';
import { InfoBanner } from 'elements/InfoBanner';
import { List } from 'elements/List';
import { UserType } from 'models/User';
import React, { FC } from 'react';
import { useStore as useUserStore } from 'store/zustand/User';

type KycBannerProps = BoxProps & {
  onVerify: () => void;
  isOwnWallet: boolean;
};

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
    width: '100%',

    background: theme.palette.green['50'],
    border: '1px solid',
    borderColor: theme.palette.green['300'],
    borderRadius: 8,
  },
  wrapper: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    gap: 12,
    width: '100%',
    padding: 16,
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
    },
  },
  textBox: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    [theme.breakpoints.down('sm')]: {
      alignSelf: 'flex-start',
    },
  },
  buttonBox: {
    width: 'content-width',
    margin: '0px 5px 0px, 5px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    gap: 8,
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      flexDirection: 'row',
    },
  },
  text: {
    color: theme.palette.grey['700'],
    lineHeight: '24px',
    [theme.breakpoints.down('sm')]: {
      fontSize: 12,
      lineHeight: '20px',
    },
  },
  durationBox: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: 4,
  },
  durationText: {
    color: theme.palette.grey['500'],
    width: '100%',
  },
  icon: {
    width: '16px',
    height: '16px',
    flexShrink: 0,
  },
  button: {
    width: '96px',
    height: '28px',
    minHeight: '0',
    [theme.breakpoints.down('sm')]: {
      width: '96px',
    },
  },
  br: {
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  list: {
    marginTop: 4,
  },
  kyb: {
    '& $wrapper': {
      flexDirection: 'column',
    },
    '& $durationBox': {
      alignSelf: 'flex-end',
    },
  },
  warningBanner: {
    background: '#FFFBEC',
    lineHeight: '16px',
    color: '#DBA400',
  },
}));

const KycBanner: FC<KycBannerProps> = ({ className, onVerify, isOwnWallet, ...props }) => {
  const classes = useStyles();
  const [user, fetchUser] = useUserStore((state) => [state.user, state.fetchUser]);

  React.useEffect(() => {
    fetchUser(false);
  }, []);

  const title = 'To enable deposits to this wallet, ' +
    'please make sure to finish business verification for your company account.';

  if (!isOwnWallet) {
    return (
      <InfoBanner
        modifier={classes.warningBanner}
        title={title}
        icon="icons/withdraw/warning_icon_yellow.svg"
      />
    );
  }

  return (
    <Box
      className={`${classes.root} ${className ?? ''} ${user?.userType !== UserType.Individual ? classes.kyb : ''}`}
      {...props}
    >
      <Box className={classes.wrapper}>
        {user?.userType === UserType.Individual ? (
          <>
            <Box className={classes.textBox}>
              <Typography
                variant="h4"
                className={classes.text}
              >
                In order to deposit, <br className={classes.br} />please first complete identity verification
              </Typography>
            </Box>
            <Box className={classes.buttonBox}>
              <Box className={classes.durationBox}>
                <img src="icons/deposit/ic_pending_icon_green.svg" alt="Pending icon" className={classes.icon} />
                <Typography variant="h6" className={classes.durationText}>3-5 minutes</Typography>
              </Box>
              <Button id="verify-kyc" className={classes.button} onClick={onVerify}>
                <Typography variant="h6">Verify</Typography>
              </Button>
            </Box>
          </>
        ) : (
          <>
            <Typography variant="h4" className={classes.text}>
              In order to deposit, please first complete business verification.
              Please submit the following documents to{' '}
              <Link underline="none" href="mailto:support@coinbag.finance">support@coinbag.finance</Link>
              <List
                className={classes.list}
                markerSize={12}
                items={[
                  <Typography key={0} variant="h4" className={classes.text}>Legal Business Name</Typography>,
                  <Typography key={1} variant="h4" className={classes.text}>Certificate of incorporation</Typography>,
                  <Typography key={2} variant="h4" className={classes.text}>Articles of association</Typography>,
                  <Typography key={3} variant="h4" className={classes.text}>
                    Passport and proof of address of each UBO (25%+ shareholder)
                  </Typography>,
                  <Typography key={4} variant="h4" className={classes.text}>
                    Designation of an account signatory
                  </Typography>,
                ]}
              />
            </Typography>
            <Box className={classes.durationBox}>
              <img src="icons/deposit/ic_pending_icon_green.svg" alt="Pending icon" className={classes.icon} />
              <Typography variant="h6" className={classes.durationText}>1-3 business days</Typography>
            </Box>
          </>
        )}
      </Box>
    </Box>
  );
};

KycBanner.displayName = 'KycBanner';
export { KycBanner };
