import { Box, makeStyles } from '@material-ui/core';
import { Button } from 'elements';
import { Typography } from 'elements/Typography';
import React from 'react';
import SVG from 'react-inlinesvg';

export interface SignUpFlowStepProps {
  stepTitle: string;
  icon: string;
  title: string;
  time: string;
  state?: StateStep;
  buttonAction: () => void;
}

// eslint-disable-next-line no-shadow
export enum StateStep {
  // eslint-disable-next-line no-unused-vars
  WAIT,
  // eslint-disable-next-line no-unused-vars
  ACTIVE,
  // eslint-disable-next-line no-unused-vars
  DONE,
}

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    gap: '12px',
    '& .step': {
      display: 'grid',
      gridTemplateColumns: '1fr 1fr 1fr',
      alignItems: 'center',
      '& .dashline': {
        borderBottom: '1px dashed #C9DBFF',
        margin: '1px',
      },
    },
  },
  card: {
    background: 'white',
    borderRadius: '8px',
    boxShadow: '0px 4px 32px rgba(58, 60, 77, 0.04)',
    padding: '16px',
    display: 'flex',
    flexDirection: 'column',
    gap: '4px',
    alignItems: 'center',
    margin: '0 16px',
    flexGrow: 1,
    [theme.breakpoints.down('md')]: {
      margin: '0 12px',
    },
    [theme.breakpoints.down('sm')]: {
      margin: '0 8px',
    },
  },
  icon: {
    width: '40px !important',
    height: '40px',
    marginBottom: '4px',
    objectFit: 'contain',
  },
  title: {
    flexGrow: 1,
  },
  timeWrapper: {
    display: 'flex',
    flexWrap: 'nowrap',
    gap: '8px',
    alignItems: 'center',
  },
  timeIcon: {
    width: '18px',
    height: '18px',
    '& circle': {
      fill: theme.palette.green[400],
    },
  },
  button: {
    marginTop: '8px',
    maxWidth: '136px',
    display: 'flex',
    justifyContent: 'center',
    '& span': { gap: '4px' },
  },
  editButton: { width: 'auto' },
}));

export const SignUpFlowStep = ({ stepTitle, icon, title, time, state, buttonAction }: SignUpFlowStepProps) => {
  const classes = useStyles();

  const buttonText = React.useMemo(() => {
    switch (state) {
      case StateStep.DONE:
        return 'Edit';
      case StateStep.WAIT:
      case StateStep.ACTIVE:
      default: return 'Start';
    }
  }, [state]);

  return (
    <Box className={classes.root}>
      <Box className="step">
        <Box className="dashline" />
        <Typography variant="h4" palette="blue" paletteColor={100} align="center">{stepTitle}</Typography>
        <Box className="dashline" />
      </Box>
      <Box className={classes.card}>
        <img className={classes.icon} src={icon} alt="icon" width={40} height={40} />
        <Typography className={classes.title} variant="h4" palette="grey" paletteColor={700} align="center">{title}</Typography>
        <Box className={classes.timeWrapper}>
          <SVG src={state === StateStep.DONE ? 'icons/dashboard/ic_check_2.svg' : '/icons/dashboard/ic_time.svg'} className={classes.timeIcon} />
          <Typography palette="grey" paletteColor={500} variant="h6">{state === StateStep.DONE ? 'Done' : time}</Typography>
        </Box>
        <Button
          disabled={state === StateStep.WAIT}
          className={`${state === StateStep.DONE && classes.editButton} ${classes.button}`}
          fullWidth
          size="sm"
          onClick={buttonAction}
          variant={state === StateStep.DONE ? 'secondary' : 'primary'}
        >
          <Typography variant="h6">{buttonText}</Typography>
          {state === StateStep.DONE && (<SVG src="icons/icon_edit_thin.svg" />)}
        </Button>
      </Box>
    </Box>
  );
};
