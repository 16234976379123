import { Alert } from '@material-ui/lab';
import Flags from 'country-flag-icons/react/3x2';
import MaterialTable, { MTableToolbar } from 'material-table';
import * as React from 'react';

import MuiTableIcons from '../../../components/MuiTableIcons';
import translationService from '../../../services/translation.service';
import { Translation } from '../../../store/translations/translation';

const ViewTranslations = () => {
  const [translations, setTranslations] = React.useState<Translation[]>([]);

  React.useEffect(() => {
    translationService.getAllTranslations().then(translations => {
      setTranslations(translations);
    });
  }, []);

  return (
    <div style={{ maxWidth: '100%', padding: '10px' }}>
      {!translations.length && <Alert severity="info">Loading...</Alert>}
      <MaterialTable
        icons={(MuiTableIcons) as any}
        columns={[
          { title: 'Id', field: 'translationId', type: 'numeric', editable: 'never', cellStyle: { width: '150px' } },
          {
            title: 'Language', field: 'isoCode', width: '10%', editable: 'never', render: (rowData: Translation) => {
              return (
                <>
                  <div style={{ display: 'inline-block', width: '20px', marginRight: '5px' }}>
                    {rowData.isoCode === 'en-us' && <Flags.US />}
                    {rowData.isoCode === 'th-th' && <Flags.TH />}
                  </div>
                  {rowData.isoCode}
                </>
              );
            },
          },
          { title: 'Copy', field: 'copy', editable: 'onUpdate' },
        ]}
        data={translations}
        title=""
        options={{
          sorting: true,
          filtering: true,
          showTitle: false,
          pageSize: 100,
          emptyRowsWhenPaging: false,
          pageSizeOptions: [20, 50, 100, 500, 1000],
          rowStyle: {
            fontSize: 14,
          },
          headerStyle: {
            fontSize: 14,
            fontWeight: 'bold',
          },
          searchFieldStyle: {
            fontSize: 14,
          },
          searchFieldAlignment: 'right',
        }}
        components={{
          Toolbar: props => (
            <div style={{ paddingTop: '10px', paddingLeft: '10px'}}>

              {/*<Link to="/add-translation"><MuiTableIcons.Add /> Add translation</Link>*/}
              <div style={{ display: 'inline-block'}}>
                <MTableToolbar {...props}/>
              </div>
            </div>
          ),
        }}
        editable={{
          onRowUpdate: async (newData: Translation, oldData?: Translation) =>
            // eslint-disable-next-line no-async-promise-executor
            await new Promise(async (resolve, reject) => {
              if (oldData) {
                try {
                  const updatedTranslation = await translationService.updateTranslation(newData.translationId, newData.isoCode, newData.copy);
                  const updatedTranslations = translations.map((translation: Translation) => {
                    if (translation.translationId === updatedTranslation.translationId && translation.isoCode === updatedTranslation.isoCode) {
                      return updatedTranslation;
                    }
                    return translation;
                  });
                  setTranslations(updatedTranslations);
                } catch (Error) {
                  reject(Error);
                }
                resolve(newData);
              } else {
                reject();
              }
            }),
        }}
      />
    </div>
  );
};


ViewTranslations.routePath = '/view-translations';
ViewTranslations.loginRequired = true;
ViewTranslations.displayName = 'ViewTranslations';

export default ViewTranslations;
