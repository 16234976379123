import { Box, createStyles, makeStyles, Theme } from '@material-ui/core';
import { Typography } from 'elements';
import * as React from 'react';
import SVG from 'react-inlinesvg';

export interface CaseStudiesCardProps {
  logoImg: string;
  name: string;
  partnerType: string;
  screenshot: string;
  desc: string;
  color: string;
}

const useStyles = makeStyles<Theme, Partial<CaseStudiesCardProps>>((theme: Theme) => createStyles({
  card: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: 40,
    [theme.breakpoints.down('sm')]: {
      gap: 24,
    },
  },
  cardTitle: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: 8,
    minHeight: 186,
    width: '100%',
    '& h2': {
      fontSize: 20,
      lineHeight: '28px',
      color: theme.palette.grey[700],
    },
    '& h3': {
      fontSize: 12,
      lineHeight: '16px',
      color: theme.palette.grey[500],
      textTransform: 'uppercase',
    },
    [theme.breakpoints.down('sm')]: {
      gap: 8,
      border: '1px solid #DEE3EF',
      borderRadius: 32,
      padding: 12,
    },
  },
  logo: {
    width: 200,
    height: 200,
    borderRadius: 200,
    boxShadow: '0px 4px 44px rgba(144, 156, 189, 0.2)',
    marginBottom: 12,
    [theme.breakpoints.down('sm')]: {
      width: 60,
      height: 60,
    },
  },
  screenshotWrapper: {
    padding: 16,
    backdropFilter: 'blur(10px)',
    border: '2px solid rgba(187, 196, 221, 0.17)',
    borderRadius: 32,
    [theme.breakpoints.down('sm')]: {
      padding: 12,
    },
  },
  screenshot: {
    width: '100%',
    position: 'relative',
    background: 'rgba(255, 255, 255, 0.5)',
    overflow: 'hidden',
    borderRadius: 16,

    [theme.breakpoints.down('sm')]: {
      borderRadius: 20,
    },
    '& img': {
      width: 310,
      height: 623,
      [theme.breakpoints.down('sm')]: {
        width: 235,
        height: 471,
      },
    },
  },
  cardDesc: {
    position: 'absolute',
    alignItems: 'center',
    padding: 16,
    display: 'flex',
    flexDirection: 'column',
    bottom: 0,
    left: 0,
    right: 0,
    background: ({ color }) => color,
    color: 'white',
    '& h2': {
      fontWeight: 500,
      fontSize: 12,
      lineHeight: '20px',
    },
  },
  arrow: {
    width: 28,
    height: 28,
    border: '1px solid #FFFFFF',
    borderRadius: 20,
    marginTop: -28,
    background: ({ color }) => color,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer',
  },
  arrowIcon: {
    width: 22,
    height: 22,
    '& path': {
      fill: 'white',
    },
  },
  aboutIntegrate: {
    margin: -8,
    cursor: 'pointer',
    '& h2': {
      display: 'inline',
      paddingRight: 4,
    },
  },
}));

const CaseStudiesCard = ({ name, partnerType, logoImg, screenshot, desc, color }: CaseStudiesCardProps) => {
  const classes = useStyles({ color });
  const [showDesc, setShowDesc] = React.useState(false);
  return (
    <article className={classes.card}>
      <Box className={classes.cardTitle}>
        <picture>
          <source srcSet={`${logoImg}@1x.png 1x, ${logoImg}@2x.png 2x, ${logoImg}@3x.png 3x`} media="(min-width: 900px)" />
          <source srcSet={`${logoImg}_mob@1x.png 1x, ${logoImg}_mob@2x.png 2x, ${logoImg}_mob@3x.png 3x`} media="(min-width: 0px)" />
          <img
            className={classes.logo}
            src={`${logoImg}@1x.png`}
            alt={`${name} logo`}
          />
        </picture>
        <Typography variant="h2" align="center">{name}</Typography>
        <Typography variant="h3" align="center">{partnerType}</Typography>
      </Box>
      <figure className={classes.screenshotWrapper}>
        <Box className={classes.screenshot}>
          <picture>
            <source srcSet={`${screenshot}@1x.png 1x, ${screenshot}@2x.png 2x, ${screenshot}@3x.png 3x`} media="(min-width: 900px)" />
            <source srcSet={`${screenshot}_mob@1x.png 1x, ${screenshot}_mob@2x.png 2x, ${screenshot}_mob@3x.png 3x`} media="(min-width: 0px)" />
            <img
              src={`${screenshot}@1x.png`}
              alt={`${name} screenshot`}
            />
          </picture>
          <Box className={classes.cardDesc}>
            {showDesc && (
              <Box className={classes.arrow} onClick={() => { setShowDesc(false); }}>
                <SVG className={classes.arrowIcon} src="/icons/dashboard/ic_arrow_down.svg" />
              </Box>
            )}
            {!showDesc && (
              <Box className={classes.aboutIntegrate} onClick={() => { setShowDesc(true); }}>
                <Typography align="center" variant="h2">About this integration</Typography>
                <SVG className={classes.arrowIcon} src="/icons/dashboard/ic_arrow_up.svg" />
              </Box>
            )}
            {showDesc && <Typography align="center" variant="h2">{desc}</Typography>}
          </Box>
        </Box>
      </figure>
    </article>
  );
};

export { CaseStudiesCard };
