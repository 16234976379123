/* eslint-disable no-unused-vars */
import {
  InputAdornment,
  makeStyles,
} from '@material-ui/core';
import { Typography } from 'elements';
import { LightTextField } from 'elements/LightTextField';
import React, { ChangeEvent, FC } from 'react';

const useStyles = makeStyles((theme) => ({
  usdText: {
    color: theme.palette.grey[500],
    fontSize: 12,
    lineHeight: '20px',
    fontWeight: 400,
  },
  percentSymbol: {
    color: theme.palette.grey[400],
    fontSize: 12,
    lineHeight: '20px',
    fontWeight: 500,
  },
  maxButton: {
    color: '#06D2AE',
    paddingLeft: 8,
    fontWeight: 600,
    fontSize: '12px',
    cursor: 'pointer',
  },
  percentInput: {
    width: 60,
    textAlign: 'right',
    '& input[type="text"]': {
      padding: 0,
      marginRight: 4,
    },
    '& input::-webkit-clear-button, & input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button': {
      display: 'none',
    },
  },
  percentInputProps: {
    fontSize: '12px',
    '& input': {
      textAlign: 'right',
    },
  },
}));

interface AmountFieldProps {
  value: number;
  onValueChanged: (value: number) => void;
  percent: number;
  onPercentChanged: (value: number) => void;
  availableAmount: number;
  unit: string;
  disabled?: boolean
}

export const AmountField: FC<AmountFieldProps> = (props) => {
  const classes = useStyles();
  const [amount, setAmount] = React.useState('');

  const handleChangeAmount = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { value } = event.target;

    const newAmount: number = parseFloat(value);
    if (value === '' || newAmount < 0) {
      setAmount('');
      props.onValueChanged(0);
      props.onPercentChanged(0);
    } else if (newAmount < props.availableAmount && newAmount >= 0) {
      const newPercentageAmount = Math.round((newAmount / props.availableAmount) * 10000) / 100;
      setAmount(value);
      props.onValueChanged(newAmount);
      props.onPercentChanged(newPercentageAmount);
    } else {
      setAmount(props.availableAmount.toString());
      props.onValueChanged(props.availableAmount);
      props.onPercentChanged(100);
    }
  };
  const updatePercentageAmount = (percentage: number) => {
    if (percentage <= 0) {
      setAmount('');
      props.onValueChanged(0);
      props.onPercentChanged(0);
    } else if (percentage < 100 && percentage > 0) {
      const newAmount = (props.availableAmount * percentage) / 100;
      setAmount(newAmount.toString());
      props.onValueChanged(newAmount);
      props.onPercentChanged(percentage);
    } else {
      setAmount(props.availableAmount.toString());
      props.onValueChanged(props.availableAmount);
      props.onPercentChanged(100);
    }
  };

  const handleChangePercentageAmount = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { value } = event.target;
    // Round to 1 decimal place.
    const newPercentageAmount = value === '' ? -1 : Math.round(parseFloat(event.target.value) * 10) / 10;
    updatePercentageAmount(newPercentageAmount);
  };

  React.useEffect(() => {
    updatePercentageAmount(0);
  }, [props.availableAmount]);

  return (
    <LightTextField
      placeholder={`Enter Amount in ${props.unit}`}
      value={amount}
      type="number"
      onChange={handleChangeAmount}
      disabled={props.disabled}
      InputProps={{
        startAdornment: <Typography className={classes.usdText}>{props.unit}</Typography>,
        endAdornment: (
          <InputAdornment position="end">
            <LightTextField
              variant="standard"
              type="number"
              placeholder=""
              value={props.percent > 0 ? props.percent : ''}
              onChange={handleChangePercentageAmount}
              className={classes.percentInput}
              InputProps={{
                disableUnderline: true,
                className: classes.percentInputProps,
              }}
            />
            <Typography variant="h6" className={classes.percentSymbol}>%</Typography>
            <Typography className={classes.maxButton} onClick={() => updatePercentageAmount(100)}>max</Typography>
          </InputAdornment>
        ),
      }}
    />
  );
};
