// @flow
import { Box } from '@material-ui/core';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import React from 'react';

interface Props {
  children: React.ReactNode;
  className?: string;
  id?: string;
}

const useStyles = makeStyles(() => createStyles({
  root: {
    overflow: 'auto',
    background: 'linear-gradient(#fff 30%, #fff0), linear-gradient(#fff0, #fff 70%) 0 100%, radial-gradient(farthest-side at 50% 0, #0003, #0000), radial-gradient(farthest-side at 50% 100%, #0003, #0000) 0 100%',
    backgroundRepeat: 'no-repeat',
    backgroundColor: 'white',
    backgroundAttachment: 'local, local, scroll, scroll',
    backgroundSize: '100% 40px, 100% 40px, 100% 14px, 100% 14px',
    '&::-webkit-scrollbar': {
      display: 'none',
    },
  },
}));

export const Scrollable = (props: Props) => {
  const classes = useStyles();
  return (
    <Box className={`${classes.root} ${props.className || ''}`} id={props.id}>
      {props.children}
    </Box>
  );
};
