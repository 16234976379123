import { Box, makeStyles, Typography } from '@material-ui/core';
import { Card } from 'elements';
import React, { FC } from 'react';
import { InterestByMasterCoin } from 'services/types/interest';

import NoData from './NoData';

type InterestEarnedSectionProps = {
  className?: string;
  interests: InterestByMasterCoin[];
  selectedCoins: string[];
  isLoading?: boolean;
};

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
  },
  chartUnit: {
    color: theme.palette.grey['600'],
    fontSize: 16,
    fontWeight: 400,
  },

  chart: {
    marginTop: -16,
    width: '100%',
  },

  legendsRow: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    alignItems: 'center',
    columnGap: 16,

    marginTop: 4,
    marginLeft: 12,
  },
  legend: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'start',
    alignItems: 'center',
    columnGap: 4,
  },
  legendIcon: {
    width: 8,
    filter: 'invert(52%) sepia(49%) saturate(2382%) hue-rotate(134deg) brightness(97%) contrast(98%)',
  },
  legendLabel: {
    color: theme.palette.grey['600'],
    fontSize: 12,
    fontWeight: 400,
    lineHeight: '16px',
  },

  noData: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    height: 64,
  },
}));

const InterestEarnedChart = React.lazy(() => import('./InterestEarnedChart'));

const InterestEarnedSection: FC<InterestEarnedSectionProps> = ({ className, interests, selectedCoins, isLoading }) => {
  const classes = useStyles();

  return (
    <Card className={`${className} ${classes.root}`} isLoading={isLoading}>
      <Typography variant="h3">
        Interest earned by coin
      </Typography>

      {!interests || interests.length === 0 || selectedCoins.length === 0 ? (
        <NoData hasSelectedCoins={interests?.length !== 0} />
      ) : (
        <Box className={classes.chart}>
          <React.Suspense fallback={<></>}>
            <InterestEarnedChart data={interests} selectedCoins={selectedCoins}/>
          </React.Suspense>
        </Box>
      )}
    </Card>
  );
};

InterestEarnedSection.displayName = 'InterestEarnedSection';
export { InterestEarnedSection };
