import {
  Box,
  Button as MuiButton, ButtonProps as MuiButtonProps, makeStyles,
  Theme, TooltipProps } from '@material-ui/core';
import { LightTooltip } from 'elements/LightTooltip';
import React from 'react';

import { palette } from '../../theme';

type ButtonProps = Omit<MuiButtonProps, 'size' | 'variant' | 'color'> & {
  variant?: 'primary' | 'secondary' | 'tertiary' | 'outline' | 'light' | 'icon' | 'translucent'
  size?: 'sm' | 'md' | 'xmd' | 'lg' | 'xlg' | 'noSpace'
  color?: keyof Omit<typeof palette, 'primary' | 'error'>;
  colorVariant?: 200 | 300 | 400 | 500 | 600 | 700;
  shape?: 'square' | 'round' | 'normal';
  tooltip?: Omit<TooltipProps, 'children'>;
}

const useStyles = makeStyles<Theme, ButtonProps>((theme) => ({
  root: {
    padding: ({ size, variant }) => (
      variant === 'icon' ? 0
        : size === 'sm' ? '2px 10px'
          : size === 'md' ? '6px 20px'
            : size === 'noSpace' ? '4px'
              : size === 'xmd' ? '10px 20px'
                : size === 'xlg' ? '14px 20px'
                  : '12px 20px'),
    minWidth: 0,
    minHeight: ({ size, variant }) => (
      variant === 'icon' ? 0
        : size === 'sm' || size === 'noSpace' ? 28
          : size === 'md' ? 36
            : size === 'xmd' ? 44
              : size === 'lg' ? 48
                : size === 'xlg' ? 52 : 0),
    textTransform: 'none',
    color: ({ variant, color }) => (
      variant === 'primary' || variant === 'translucent' ? theme.palette.common.white
        : variant === 'secondary' ? theme.palette.grey['700']
          : theme.palette[color ?? 'green'][600]),
    fontSize: ({ size }) => (
      size === 'sm' || size === 'noSpace' ? 12
        : size === 'xlg' ? 20 : 16),
    background: ({ variant, color, colorVariant }) => (
      variant === 'primary' ? theme.palette[color ?? 'green'][colorVariant || 600]
        : variant === 'secondary' ? theme.palette.grey['100']
          : variant === 'light' ? theme.palette[color ?? 'green']['100']
            : variant === 'outline' ? 'white'
              : variant === 'translucent' ? 'rgba(255, 255, 255, 0.2)' : 'transparent'),
    border: ({ variant, color }) => (
      variant === 'outline' || variant === 'light' ? `1px solid ${theme.palette[color ?? 'green'][300]}` : 'none'
    ),
    borderRadius: ({ shape }) => (`${shape === 'square' ? 6 : shape === 'round' ? 1000 : 24}px`),
    '&:hover': {
      color: ({ variant, color }) => (
        variant === 'primary' || variant === 'translucent' ? theme.palette.common.white
          : variant === 'secondary' ? theme.palette.grey['700']
            : theme.palette[color ?? 'green']['700']
      ),
      background: ({ variant, color }) => (
        variant === 'primary' ? theme.palette[color ?? 'green']['700']
          : variant === 'translucent' ? 'rgba(255, 255, 255, 0.1)'
            : variant === 'light' ? theme.palette[color ?? 'green']['200']
              : variant === 'secondary' ? theme.palette.grey['200']
                : variant === 'icon' ? 'transparent'
                  : theme.palette[color ?? 'green']['100']
      ),
    },
    '&:focus': {
      color: ({ variant, color }) => (
        variant === 'primary' || variant === 'translucent' ? theme.palette.common.white
          : variant === 'secondary' ? theme.palette.grey['700']
            : theme.palette[color ?? 'green']['700']
      ),
      background: ({ variant, color }) => (
        variant === 'primary' ? theme.palette[color ?? 'green']['600']
          : variant === 'translucent' ? 'rgba(255, 255, 255, 0.1)'
            : variant === 'light' ? theme.palette[color ?? 'green']['200']
              : variant === 'secondary' ? theme.palette.grey['200']
                : variant === 'icon' ? 'transparent'
                  : theme.palette[color ?? 'green']['100']
      ),
    },
    '&:active': {
      color: ({ variant, color }) => (
        variant === 'primary' || variant === 'translucent' ? theme.palette.common.white
          : variant === 'secondary' ? theme.palette.grey['700']
            : theme.palette[color ?? 'green']['700']
      ),
      background: ({ variant, color }) => (
        variant === 'primary' ? theme.palette[color ?? 'green']['600']
          : variant === 'translucent' ? 'rgba(255, 255, 255, 0.1)'
            : variant === 'secondary' ? theme.palette.grey['100']
              : variant === 'icon' ? 'transparent'
                : theme.palette[color ?? 'green']['200']
      ),
      boxShadow: ({ variant }) => (
        variant === 'primary' ? 'inset 0px 4px 8px rgba(26, 144, 113, 0.4)'
          : variant === 'secondary' ? 'inset 0px 4px 8px rgba(212, 217, 227, 0.2)'
            : ''
      ),
    },
    '&:disabled': {
      color: theme.palette.grey['300'],
      background: ({ variant }) => (
        variant === 'tertiary' || variant === 'icon' ? 'transparent' : theme.palette.grey['100']
      ),
    },
  },
  tooltipWrapper: {
    cursor: 'pointer',
  },
}));

const Button = (props: ButtonProps) => {
  const { shape, variant, size, color, colorVariant, className, children, ...rest } = props;
  const classes = useStyles({ variant, size, color, shape, colorVariant });
  if (props.tooltip) {
    return (
      <LightTooltip {...props.tooltip}>
        <Box className={classes.tooltipWrapper}>
          <MuiButton className={`${classes.root} ${className || ''}`} {...rest}>
            {children}
          </MuiButton>
        </Box>
      </LightTooltip>
    );
  }
  return <MuiButton className={`${classes.root} ${className || ''}`} {...rest}>{children}</MuiButton>;
};

Button.defaultProps = {
  variant: 'primary',
  size: 'md',
};

export { Button };
