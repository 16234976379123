import { Box, CircularProgress, createStyles, makeStyles, Typography } from '@material-ui/core';
import { useConnectWallet } from '@web3-onboard/react';
import { Button } from 'elements';
import { InfoBanner } from 'elements/InfoBanner';
import React from 'react';
import SVG from 'react-inlinesvg';
import { isAddressSupported, showAccountCenter, WalletStatus } from 'services/OnboardService';
import { ChainDetails } from 'services/types/chain';
import { MasterCoin } from 'services/types/coin';
import { EstimatedGasFeeWithdrawToken } from 'services/types/wallet';
import WalletService from 'services/wallet-service';
import { useStore as useWalletStore } from 'store/zustand/Wallet';

import { GasStatus } from './GasStatus';


const useStyles = makeStyles((theme) => createStyles({
  withdrawBoxBackground: {
    width: 'calc(100% + 32px)',
    display: 'flex',
    justifyContent: 'center',
    margin: '0 -16px -16px',
    padding: 16,
    [theme.breakpoints.up('md')]: {
      width: 'calc(100% + 64px)',
      padding: 32,
      margin: '0 -32px -16px',
    },
  },
  contentWrapper: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    [theme.breakpoints.up('md')]: {
      padding: 0,
      width: 560,
    },
  },
  withdrawBoxBackgroundRed: {
    background: '#FFF5F7',
    borderTop: '1px solid #FF92AC',
  },
  withdrawBoxBackgroundGreen: {
    background: theme.palette.green[50],
    borderTop: `1px solid ${theme.palette.green[300]}`,
  },
  withdrawBox: {
    display: 'flex',
    flexDirection: 'column',
    rowGap: '16px',
  },
  withdrawErrorBanner: {
    background: '#FFC5D3',
    lineHeight: '16px',
    color: '#C81D47',
  },
  withdrawWarningBanner: {
    background: '#FFFBEC',
    lineHeight: '16px',
    color: '#DBA400',
  },
  withdrawAmountText: {
    color: theme.palette.grey[500],
    fontWeight: 400,
    fontSize: 12,
    lineHeight: '20px',
  },
  withdrawAmountNonConverted: {
    color: '#38425E',
    fontWeight: 500,
    fontSize: '14px',
    lineHeight: '24px',
  },
  withdrawAmountConverted: {
    color: '#6E7BA1',
    fontWeight: 500,
    fontSize: '12px',
    lineHeight: '16px',
  },
  withdrawAmountConvertedSpace: {
    paddingLeft: '0.6em',
    paddingRight: '0.3em',
  },
  withdrawTotalAmount: {
    color: '#02BD9F',
    fontWeight: 500,
    fontSize: '14px',
    lineHeight: '24px',
  },
  withdrawTotalAmountText: {
    color: theme.palette.grey[800],
    fontWeight: 500,
    fontSize: 12,
    lineHeight: '20px',
  },
  withdrawSummaryBox: {
    display: 'flex',
    flexWrap: 'nowrap',
    justifyContent: 'space-around',
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column',
      gap: '18px',
    },
  },
  withdrawAmount: {
    display: 'flex',
    flexWrap: 'nowrap',
    alignItems: 'center',
  },
  withdrawAmountIcon: {
    width: '16px',
    height: '16px',
    marginRight: '0.5em',
  },
  estimatedGasBox: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    rowGap: '8px',
  },
  withdrawAmountBox: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    rowGap: '8px',
  },
  withdrawTotalAmountBox: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    rowGap: '8px',
  },
}));

interface WithdrawSummaryProps {
  isWithdrawable: boolean;
  isWithdrawFullAmount: boolean;
  amount: number;
  amountUsdt?: number;
  isTokenAmount?: boolean;
  withdrawCoinId: number | null;
  selectedMasterCoin?: MasterCoin;
  handleWithdrawButton: () => void;
  withdrawChain?: ChainDetails;
  gasStatus?: GasStatus;
  estimatedGasFee?: EstimatedGasFeeWithdrawToken;
  showEstimatedGas?: boolean;
}

export const WithdrawSummary = ({
  isWithdrawFullAmount, isWithdrawable, amount, amountUsdt, isTokenAmount, withdrawCoinId, selectedMasterCoin,
  handleWithdrawButton, withdrawChain, gasStatus, estimatedGasFee, showEstimatedGas = false,
}: WithdrawSummaryProps) => {
  const classes = useStyles();
  const [isAmountRefreshing, setIsAmountRefreshing] = React.useState(false);
  const [convertedAmount, setConvertedAmount] = React.useState(0);

  const [activeWalletInfo] = useWalletStore((state) => [state.activeWalletInfo])
  const [{wallet, connecting}, connect] = useConnectWallet();

  React.useEffect(() => {
    if (!withdrawCoinId || amount <= 0) {
      setConvertedAmount(0);
      setIsAmountRefreshing(false);
      return;
    }
    const getExchangeRate = async () => {
      setIsAmountRefreshing(true);
      try {
        const converted = ((isTokenAmount
          ? String(amountUsdt)
          : await WalletService.convertFromMasterCoin(9, withdrawCoinId, amount)));
        setConvertedAmount(parseFloat(converted));
      } catch (error) {
        setConvertedAmount(0);
        setIsAmountRefreshing(false);
      }
      setIsAmountRefreshing(false);
    };
    getExchangeRate();
  }, [isTokenAmount, amount, withdrawCoinId]);

  React.useEffect(() => {
    if (amount <= 0) setConvertedAmount(0);
  }, [convertedAmount]);

  const tokenAmount = React.useMemo(() => (
    (isTokenAmount ? amount : convertedAmount).toFixed(0).length > 11
      ? (isTokenAmount ? amount : convertedAmount).toLocaleString(
        undefined,
        { unitDisplay: 'narrow', notation: 'compact', minimumFractionDigits: 2 },
      )
      : (isTokenAmount ? amount : convertedAmount).toLocaleString(
        undefined,
        { minimumSignificantDigits: 2, maximumSignificantDigits: 8 },
      )
  ), [isTokenAmount, amount, convertedAmount]);

  const tokenValueFormatted = React.useMemo(() => (
    (isTokenAmount ? convertedAmount : amount).toLocaleString(
      undefined,
      { minimumFractionDigits: 2, maximumFractionDigits: 2, signDisplay: 'never' },
    )
  ), [isTokenAmount, amount, convertedAmount]);

  const showConnectWalletButton = React.useMemo(() => {
    return activeWalletInfo?.walletProvider === 'Reporting' && isTokenAmount && wallet == null
  }, [activeWalletInfo, isTokenAmount, wallet]);

  const walletAddress = React.useMemo(() => (
    activeWalletInfo?.address?.filter((a) => a.startsWith('0x'))?.at(0)
  ), [activeWalletInfo]);

  const walletStatus = React.useMemo(() => {
    if (activeWalletInfo?.walletProvider === 'Reporting') {
      if (walletAddress) {
        return isAddressSupported(walletAddress);
      }
    }
    return WalletStatus.Ok;
  }, [activeWalletInfo, walletAddress, wallet]);

  if (!withdrawCoinId || !selectedMasterCoin) return <Box />;

  return (
    <Box
      className={`${(isWithdrawable && !isWithdrawFullAmount && (walletStatus === WalletStatus.Ok || walletStatus === WalletStatus.OkAuto)) ?
        classes.withdrawBoxBackgroundGreen :
        classes.withdrawBoxBackgroundRed} ${classes.withdrawBoxBackground}`}
    >
      <Box className={classes.contentWrapper}>
        <Box className={classes.withdrawBox}>
          {withdrawChain && gasStatus && gasStatus === GasStatus.FAILED ? (
            <InfoBanner
              modifier={classes.withdrawErrorBanner}
              title="Gas estimation failed"
              description={'We\'re currently unable to estimate if your wallet has sufficient ' +
                `${withdrawChain.nativeCoin.masterCoin.ticker} to cover the gas fees on ${withdrawChain.name} ` +
                'for this withdrawal. Please try again in a few minutes.'}
              icon="icons/withdraw/warning_icon_red.svg"
            />
          ) : null}
          {withdrawChain && gasStatus && gasStatus === GasStatus.INSUFFICIENT ? (
            <InfoBanner
              modifier={classes.withdrawErrorBanner}
              title="Insufficient gas available."
              description={`Please deposit ${withdrawChain.nativeCoin.masterCoin.ticker} on ` +
                `${withdrawChain.name} to pay network gas fees.`}
              subDescription={estimatedGasFee && estimatedGasFee.amount > 0 ?
                `Estimated gas fee: ${estimatedGasFee.amount.toFixed(0).length > 11
                  ? (estimatedGasFee.amount).toLocaleString(
                    undefined,
                    { unitDisplay: 'narrow', notation: 'compact', minimumFractionDigits: 2 },
                  )
                  : (estimatedGasFee.amount).toLocaleString(
                    undefined,
                    { minimumSignificantDigits: 2, maximumSignificantDigits: 8 },
                  )} ${estimatedGasFee.nativeCoin.masterCoin.ticker}` : undefined}
              icon="icons/withdraw/warning_icon_red.svg"
            />
          ) : null}
          {isWithdrawable && isWithdrawFullAmount && (
            <InfoBanner
              modifier={classes.withdrawErrorBanner}
              title="Your balance will fall below our minimum required balance of 350 USD equivalent"
              description="You must withdraw your full balance, or adjust the value to keep 350 USD or more."
              icon="icons/withdraw/warning_icon_red.svg"
            />
          )}
          <Box className={classes.withdrawSummaryBox}>
            {showEstimatedGas && (
              <Box className={classes.estimatedGasBox}>
                <Typography variant="h4" className={classes.withdrawAmountText}>Estimated Fee</Typography>
                <Box className={classes.withdrawAmount}>
                  {gasStatus === GasStatus.LOADING && (
                    <CircularProgress color="primary" size="1rem" />
                  )}
                  {!isAmountRefreshing && amount > 0 && withdrawChain && estimatedGasFee ? (
                    <>
                      <SVG src={withdrawChain.nativeCoin.masterCoin.icon} className={classes.withdrawAmountIcon} />
                      <Typography
                        className={classes.withdrawAmountNonConverted}
                      >
                        {estimatedGasFee.amount.toFixed(0).length > 11
                          ? (estimatedGasFee.amount).toLocaleString(
                            undefined,
                            { unitDisplay: 'narrow', notation: 'compact', minimumFractionDigits: 2 },
                          )
                          : (estimatedGasFee.amount).toLocaleString(
                            undefined,
                            { minimumSignificantDigits: 2, maximumSignificantDigits: 8 },
                          )}
                      </Typography>
                      <Typography
                        className={`${classes.withdrawAmountConverted} ${classes.withdrawAmountConvertedSpace}`}
                      >
                        ≈
                      </Typography>
                      <Typography
                        className={classes.withdrawAmountConverted}
                      >
                        {estimatedGasFee.usdtAmount.toLocaleString(
                          undefined,
                          { minimumFractionDigits: 2, maximumFractionDigits: 2, signDisplay: 'never' },
                        )}
                      </Typography>
                    </>
                  ) : gasStatus !== GasStatus.LOADING && '-'}
                </Box>
              </Box>
            )}
            <Box className={classes.withdrawAmountBox}>
              <Typography variant="h4" className={classes.withdrawAmountText}>Amount to withdraw</Typography>
              <Box className={classes.withdrawAmount}>
                {!isAmountRefreshing && amount > 0 ? (
                  <>
                    <SVG src={selectedMasterCoin.icon} className={classes.withdrawAmountIcon} />
                    <Typography className={classes.withdrawAmountNonConverted}>
                      {tokenAmount}
                    </Typography>
                    <Typography className={`${classes.withdrawAmountConverted} ${classes.withdrawAmountConvertedSpace}`}>
                      ≈
                    </Typography>
                    <Typography className={classes.withdrawAmountConverted}>
                      {tokenValueFormatted}
                    </Typography>
                  </>
                ) : (
                  <Typography className={classes.withdrawAmountNonConverted}>-</Typography>
                )}
              </Box>
            </Box>
            <Box className={classes.withdrawTotalAmountBox}>
              <Typography variant="h4" className={classes.withdrawTotalAmountText}>
                Total amount USDT
              </Typography>
              <Typography variant="h4" className={classes.withdrawTotalAmount}>
                {tokenValueFormatted}
              </Typography>
            </Box>
          </Box>
          {walletStatus === WalletStatus.OkManual && wallet !== null ? (
            <InfoBanner
              modifier={classes.withdrawErrorBanner}
              title="Choose the primary wallet & network to proceed"
              description={`Please select and the primary wallet and network for address
              ${walletAddress ? ': ' + walletAddress.slice(0, 2) + '...' + walletAddress.slice(-6) : ''}`}
              icon="icons/withdraw/warning_icon_red.svg"
            >
              <Button
                fullWidth
                size="sm"
                variant="primary"
                color="red"
                onClick={(e) => {
                  showAccountCenter()
                  e.stopPropagation()
                }}
              >
                Manage connected wallet
              </Button>
            </InfoBanner>
          ) : null}
          {walletStatus === WalletStatus.No && wallet !== null ? (
            <InfoBanner
              modifier={classes.withdrawErrorBanner}
              title="Wallet not connected"
              description={`Please select and connect your wallet address
              ${walletAddress ? ': ' + walletAddress.slice(0, 2) + '...' + walletAddress.slice(-6) : ''}`}
              icon="icons/withdraw/warning_icon_red.svg"
            >
              <Button
                fullWidth
                size="sm"
                variant="primary"
                color="red"
                onClick={(e) => {
                  showAccountCenter()
                  e.stopPropagation()
                }}
              >
                Manage connected wallet
              </Button>
            </InfoBanner>
          ) : null}
          {showConnectWalletButton && (
            <Button
              fullWidth
              disabled={connecting}
              variant="primary"
              onClick={ async () => {
                await connect();
              }}
            >
              Connect wallet
            </Button>
          )}
          <Button
            id="submit-withdraw"
            fullWidth
            onClick={handleWithdrawButton}
            disabled={!isWithdrawable || walletStatus === WalletStatus.No || walletStatus === WalletStatus.OkManual}
          >
            {gasStatus === GasStatus.LOADING ? 'Fetching fee price' : (isWithdrawable && isWithdrawFullAmount ? 'Withdraw full balance' : 'Withdraw')}
          </Button>
        </Box>
      </Box>
    </Box>
  );
};
