import React from "react";
import { DisclaimerRoute, PrivacyPolicyRoute, TermsOfUseRoute } from '../routes';
import { Col, Container, Row } from 'react-bootstrap'

export const Footer = class Footer extends React.Component {
  render() {
    return (
      <Container className="footer mt-4">
        <Row className="mt-0">
          <Col>
            <p className="m-0 p-0">Copyright &copy; 2021 Coinbag Inc.</p>
            <div className="footer-privacy">
              <a href={TermsOfUseRoute}>Terms of use</a>
              <a href={PrivacyPolicyRoute}>Privacy policy</a>
              <a href={DisclaimerRoute}>Disclaimer</a>
            </div>
          </Col>
          </Row>
          <Row>
          <Col>
          <div className="footer-twitter">
            <a href="https://twitter.com/coinbag_app" className="fab fa-twitter" target="_blank"></a>
            <a href="https://t.me/coinbag_app" className="fab fa-telegram-plane" target="_blank"></a>
            </div>
          </Col>
        </Row>
      </Container>
    )
  }
}
