const fieldProps = ["VALID", "CONNECTED"]

class Field {
    constructor(props){

        this.value = props.value;

        Object.keys(props).map(p =>{
            if (fieldProps.find(fp => fp === p))
                this[p] = props[p]
        });

        fieldProps.forEach((method) => {
            // VALID(), CONNECTED()
            Field.prototype[method] = function (isit = true) {
                this[method.toLowerCase()] = isit
                return this
            }
        });
    }

    ERROR(msg) {
        this.error = msg
        return this
    }

    and(field){
        Object.keys(field).map(fp => {
            // We don't override values to undefined
            if(!(fp === "VALUE" && field[fp] === undefined))
                this[fp] = field[fp]
        });
        return this;
    }
}

const rebalancingStatus = {
    REBALANCING: "Rebalancing",
    WITHDRAWAL: "Withdrawal",
    WITHDRAWALREBALANCE: "Withdrawal_rebalance",
    WITHDRAWAL1: "Withdrawal1",
    WITHDRAWAL2: "Withdrawal2",
    WITHDRAWAL3: "Withdrawal3",
    PERIODIC: "Periodic",
    ONETIME: "OneTime",
    TRANSFER: "Transfer",
}

const stableCoins = ["USDT", "USDC", "USTC", "Anchor Terra USD", "aUST"]

const stableCoinNameMap = {"USDT": "Stablecoin (USDT)", "USDC":
"Stablecoin (USDC)", "USTC": "Stablecoin (UST)", "UST": "Stablecoin (UST)", "Anchor Terra USD": "Stablecoin (UST)", "aUST": "Stablecoin (UST)"}

function FIELD (value) { return new Field({value}) };
function VALID (value) { return new Field({value, valid:true}) };
function NOT_VALID (value) { return new Field({value, valid:false}) };
function CONNECTED (value) { return new Field({value, connected:true}) };
function NOT_CONNECTED (value) { return new Field({value, connected:false}) };

function sleep (time) {
    return new Promise((resolve) => setTimeout(resolve, time));
}

function loading(load){
    if(!load){
        const element = document.querySelector("#loader")
        sleep(100).then(() => {
            element.classList.remove("fade-in")
        })
        sleep(500).then(() => {
            // element.parentNode.removeChild(element);
        })
    }
}

function isDesktop(){
  return window.matchMedia('(min-width: 768px)').matches
}

function dateInUtcToUserDate(epochMillis) {
  const timeZoneOffsetInMs = new Date().getTimezoneOffset() * 60 * 1000; // minutes to milliseconds
  return epochMillis - timeZoneOffsetInMs;
}

const BnbLiquidationBundleStateId = 49;

export {dateInUtcToUserDate, isDesktop, loading, sleep, Field, FIELD, VALID, NOT_VALID, CONNECTED,
    NOT_CONNECTED, BnbLiquidationBundleStateId, rebalancingStatus, stableCoins, stableCoinNameMap};


