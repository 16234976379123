import { createStyles, makeStyles } from '@material-ui/core';
import { WithdrawStatus } from 'components/DepositAndWithdraw/WithdrawStatus';
import RebalancingStatusComponent, { shouldShowRebalancingStatus } from 'components/RebalancingStatus';
import { Card } from 'elements';
import React, { useEffect } from 'react';
import { RebalancingStatus } from 'services/types/wallet';
import { WorkItem } from 'services/types/workItem';
import { useStore as useCoinStore } from 'store/zustand/Coin';

interface Props {
  withdrawWorkItem?: WorkItem;
  rebalancingStatus?: RebalancingStatus;
}

const useStyles = makeStyles((theme) => createStyles({
  section: {
    padding: 16,
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%',
    minHeight: '100%',
    overflow: 'hidden',
    boxShadow: '0px 4px 32px rgba(43, 43, 48, 0.04)',
    gap: 16,
    [theme.breakpoints.up('md')]: {
      gridRow: '1 / span 2',
      padding: '16px 32px',
    },
    [theme.breakpoints.down('sm')]: {
      borderRadius: 0,
      minHeight: 300,
    },
  },
}));
export const RebalancingInProgress = ({ withdrawWorkItem, rebalancingStatus }: Props) => {
  const classes = useStyles();
  const [coins, fetchCoins] = useCoinStore((state) => [state.coins, state.fetchCoins]);
  useEffect(() => {
    if (Object.entries(coins).length === 0) {
      fetchCoins();
    }
  },
  []);

  return (
    <Card className={classes.section}>
      {withdrawWorkItem ? (
        <WithdrawStatus coins={coins} workItem={withdrawWorkItem} />
      ) : shouldShowRebalancingStatus(rebalancingStatus) ? (
        <RebalancingStatusComponent status={rebalancingStatus} />
      ) : (<></>) }
    </Card>
  );
};
