import {
  Accordion as MuiAccordion,
  AccordionDetails as MuiAccordionDetails,
  AccordionSummary as MuiAccordionSummary,
  withStyles,
} from '@material-ui/core';

const PlainAccordion = withStyles({
  root: {
    border: 'none',
    boxShadow: 'none',

    '&:not(:last-child)': {
      borderBottom: 0,
    },

    '&:before': {
      display: 'none',
    },

    '&$expanded': {
      margin: 0,
    },
  },
  expanded: {},
})(MuiAccordion);

const PlainAccordionSummary = withStyles({
  root: {
    padding: 0,
    minHeight: 0,

    '&$expanded': {
      minHeight: 0,
    },
  },
  content: {
    margin: 0,
    '&$expanded': {
      margin: 0,
    },
  },
  expanded: {},
})(MuiAccordionSummary);

const PlainAccordionDetails = withStyles({
  root: {
    padding: 0,
  },
})(MuiAccordionDetails);

export { PlainAccordion, PlainAccordionDetails,PlainAccordionSummary };
