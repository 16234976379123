import { Box, Hidden, makeStyles } from '@material-ui/core';
import { Typography } from 'elements';
import * as React from 'react';

interface PermissionCategoryProps {
  children: React.ReactNode;
  name: string;
  count: number;
}

const useStyles = makeStyles((theme) => ({
  tableWrapper: {
    display: 'flex',
    [theme.breakpoints.up('sm')]: {
      borderRadius: 8,
      boxShadow: '0px 4px 36px rgba(99, 116, 165, 0.08), 0px 4px 32px rgba(187, 196, 221, 0.1)',
      overflow: 'hidden',
    },
  },
  table: {
    display: 'grid',
    flexGrow: 1,
    marginBottom: 'auto',
    [theme.breakpoints.up('sm')]: {
      gridTemplateColumns: '1.5fr repeat(2, 1fr)',
    },
    '& > *': {
      display: 'flex',
      padding: '16px 0 16px 16px',
      [theme.breakpoints.up('sm')]: {
        borderTop: `1px solid ${theme.palette.grey[200]}`,
      },
      [theme.breakpoints.down('xs')]: {
        padding: '12px 0',
        margin: '0 16px',
      },
      '&:nth-child(2)': {
        [theme.breakpoints.down('xs')]: {
          borderTop: 'none',
        },
      },
      '&#member': {
        [theme.breakpoints.down('xs')]: {
          padding: '16px 0 8px',
        },
      },
      '&#approver': {
        [theme.breakpoints.down('xs')]: {
          padding: '8px 0 16px',
        },
      },
    },
  },
  name: {
    color: theme.palette.grey[500],
    [theme.breakpoints.up('sm')]: {
      fontSize: 14,
      lineHeight: '24px',
      fontWeight: 500,
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: 12,
      lineHeight: '20px',
      fontWeight: 500,
    },
  },
  count: {
    fontSize: 12,
    fontWeight: 400,
    lineHeight: '20px',
    color: theme.palette.grey[400],
    whiteSpace: 'nowrap',
  },
  header: {
    borderTop: 'none',
    padding: '6px 0 6px 16px',
    background: theme.palette.grey[50],
    alignItems: 'center',
    display: 'flex',
    flexWrap: 'wrap',
    [theme.breakpoints.down('xs')]: {
      margin: 0,
      padding: '12px 8px 12px 16px',
      borderTop: `1px solid ${theme.palette.grey[200]}`,
      borderBottom: `1px solid ${theme.palette.grey[200]}`,
    },
  },
}));

const PermissionCategory = (props: PermissionCategoryProps) => {
  const classes = useStyles();

  return (
    <Box className={classes.tableWrapper}>
      <Box className={classes.table}>
        <Box className={classes.header}>
          <Typography className={classes.name} mr={8}>
            {props.name}
          </Typography>
          <Typography className={classes.count}>{props.count.toLocaleString()} actions</Typography>
        </Box>
        <Hidden xsDown>
          <Typography className={classes.header} variant="h4" paletteColor={500}>
            Approving members
          </Typography>
          <Typography className={classes.header} variant="h4" paletteColor={500}>
            Required approvers
          </Typography>
        </Hidden>
        {props.children}
      </Box>
    </Box>
  );
};

export default PermissionCategory;
