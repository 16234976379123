import { Box, makeStyles, Theme, Typography } from '@material-ui/core';
import React from 'react';

interface InputWithTitleProps {
  title: string | React.ReactNode;
  titleRightItem?: React.ReactNode;
  children: React.ReactNode;
  fullWidth?: boolean;
  className?: string;
}

const useStyles = makeStyles<Theme, InputWithTitleProps>((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    width: ({ fullWidth }) => (fullWidth ? '100%' : undefined),
  },
  titleContainer: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-end',
    marginBottom: 4,
  },
  title: {
    fontWeight: 400,
    color: theme.palette.grey[500],
    lineHeight: '16px',
    fontSize: 12,
    textAlign: 'start',
  },
}));

const InputWithTitle = (props: InputWithTitleProps) => {
  const classes = useStyles(props);
  return (
    <Box className={`${props.className ?? ''} ${classes.root}`}>
      <Box className={classes.titleContainer}>
        {typeof props.title === 'string'
          ? <Typography className={classes.title}>{props.title}</Typography>
          : props.title}
        {props.titleRightItem}
      </Box>
      {props.children}
    </Box>
  );
};

export { InputWithTitle };
