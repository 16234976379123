import { Box, createStyles,Hidden } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Button, InputWithTitle, LightModal, LightTextField, Typography } from 'elements';
import { Scrollable } from 'elements/Scrollable';
import { AddTagNamePendingAction, RemoveTagNamePendingAction, UpdateTagNamePendingAction } from 'models/Features';
import { getWalletProviderIcon } from 'pages/responsive/WalletSetting/components/WalletTypes';
import React, { useEffect, useMemo, useState } from 'react';
import SVG from 'react-inlinesvg';
import { WorkItemName } from 'services/types/workItem';
import { useStore as useFeatureStore } from 'store/zustand/Feature';
import { useStore as useTransactionStore } from 'store/zustand/Transaction';
import { useStore as useWalletStore } from 'store/zustand/Wallet';
import generateRandomString from 'utils/generateRandomString';

import { AddTagModal } from '../AddTagModal';
import { RemoveItem } from '../RemoveItem';
import { ManageTagRow } from './ManageTagRow';
import { RemoveTagRow } from './RemoveTagRow';

interface Props {
  showModal: boolean;
  // eslint-disable-next-line no-unused-vars
  setShowModal: (value: boolean) => void;
  allowFetchingData: boolean;
}

const useStyles = makeStyles((theme) => createStyles({
  loading: {
    height: 292,
  },
  textFieldWrapper: {
    paddingTop: 16,
    [theme.breakpoints.up('sm')]: {
      padding: '0 16px 16px',
      borderBottom: `1px solid ${theme.palette.grey[200]}`,
    },
  },
  scrollable: {
    flexGrow: 1,
  },
  addItemIconWrapper: {
    width: 20,
    height: 20,
    margin: '0 -6px',
    cursor: 'pointer',
    '&#disabled': {
      '& circle': {
        fill: theme.palette.grey[200],
      },
    },
  },
  icon: {
    width: '100%',
    height: '100%',
  },
  rowWrapper: {
    display: 'flex',
    padding: '12px 16px',
    justifyContent: 'space-between',
    alignItems: 'center',
    borderBottom: `1px solid ${theme.palette.grey[200]}`,
    '&#header': {
      background: '#FAFBFF',
      justifyContent: 'flex-start',
      gap: 4,
      padding: '8px 16px',
    },
  },
  overlay: {
    zIndex: 1200,
    position: 'absolute',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,

    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',

    background: theme.palette.common.white,
    borderRadius: 8,
    opacity: 0.8,
  },
  header: {
    display: 'flex',
    alignItems: 'center',
    gap: 8,
    '& h2': {
      fontSize: 16,
      lineHeight: '24px',
      fontWeight: 500,
      textAlign: 'center',
      color: theme.palette.grey[800],
    },
    [theme.breakpoints.up('sm')]: {
      padding: 16,
    },
  },
  modal: {
    gap: 0,
    minHeight: 'max(400px, 50vh)',
  },
  noAddress: {
    padding: '24px 8px',
  },
  divider: {
    color: theme.palette.grey[300],
    fontSize: 12,
  },
  hashtag: {
    fontSize: 12,
  },
  walletOrder: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: 16,
    height: 16,
    color: theme.palette.common.white,
    fontSize: 12,
    fontWeight: 500,
    background: theme.palette.blue[500],
    borderRadius: 10,
  },
  walletIcon: {
    width: 24,
    height: 24,
    borderRadius: 4,
    '& img': {
      userDrag: 'none',
      userSelect: 'none',
      borderRadius: 4,
      width: 24,
      height: 24,
    },
  },
}));

// eslint-disable-next-line no-shadow, no-unused-vars
enum ModalState {
  // eslint-disable-next-line no-unused-vars
  ManageTags,
  // eslint-disable-next-line no-unused-vars
  RemoveTag,
}

export const ManageTagModal = ({ showModal, setShowModal, allowFetchingData }: Props) => {
  const classes = useStyles();

  const [fetchTxs, tags, addTagName, tagsWithCounts, removeTagName, fetchTags] = useTransactionStore((state) => [
    state.fetchTxs, state.tags, state.addTagName,
    state.tagsWithCounts, state.removeTagName, state.fetchTags,
  ]);
  const [isAllWallets, walletInfos, activeId] = useWalletStore((state) => [
    state.isAllWallets, state.walletInfos, state.activeId,
  ]);
  const [findPendingAction, features, fetchFeatures] = useFeatureStore((state) => [
    state.findPendingAction, state.features, state.fetchFeatures,
  ]);

  const [modalState, setModalState] = useState<ModalState>(ModalState.ManageTags);
  const [tagName, setTagName] = useState<string>('');
  const [tagIdToRemove, setTagIdToRemove] = useState<number | null>(null);
  const [disableButtons, setDisableButtons] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [editTagId, setEditTagId] = useState<number | undefined>();
  const [showAddTagModal, setShowAddTagModal] = useState<boolean>(false);

  const awaitingEdits = React.useMemo(() => (
    findPendingAction(WorkItemName.UpdateTagName)?.map((i) => i as UpdateTagNamePendingAction)
  ), [features]);

  const awaitingAdds = React.useMemo(() => (
    findPendingAction(WorkItemName.AddTagName)?.map((i) => i as AddTagNamePendingAction)
  ), [features]);

  const awaitingRemoves = React.useMemo(() => (
    findPendingAction(WorkItemName.RemoveTagName)?.map((i) => i as RemoveTagNamePendingAction)
  ), [features]);

  useEffect(() => {
    if (allowFetchingData && showModal) {
      (async () => {
        await fetchTxs(true);
      })();
    }
  }, [allowFetchingData, showModal]);

  const handleAddTagName = async () => {
    if (!tagName) return;
    if (isAllWallets()) {
      setShowAddTagModal(true);
    } else if (activeId?.activeWalletId !== undefined) {
      setIsLoading(true);
      await addTagName(tagName, activeId.activeWalletId);
      setTagName('');
      await Promise.allSettled([fetchTxs(true), fetchFeatures(true)]);
      setIsLoading(false);
    }
  };

  const handleOnClickRemove = (tagId: number) => () => {
    setTagIdToRemove(tagId);
    setModalState(ModalState.RemoveTag);
  };

  const handleRemoveTagName = async () => {
    if (tagIdToRemove != null) {
      setIsLoading(true);
      setDisableButtons(true);
      await removeTagName(tagIdToRemove);
      await Promise.allSettled([fetchTags(true), fetchFeatures(true)]);
      setDisableButtons(false);
      setTagIdToRemove(null);
      setModalState(ModalState.ManageTags);
      setIsLoading(false);
    }
  };

  const filteredTags = useMemo(() => {
    const filtered = (tags !== undefined && tagsWithCounts !== undefined)
      ? tags.filter((tag) => tag.tag.startsWith(tagName)) : [];
    return groupBy(filtered, (f) => f.walletId);
  }, [tagName, tags, tagsWithCounts]);

  const filteredAwaitingAdds = useMemo(() => {
    const filtered = awaitingAdds?.filter((tag) => tag.tagName.startsWith(tagName)) ?? [];
    return groupBy(filtered, (f) => f.toWalletId);
  }, [awaitingAdds, tagName]);

  const tagWalletIds = useMemo(() => {
    if (walletInfos) {
      const filteredTagKeys = Object.keys(filteredTags).map(Number);
      const filteredAwaitingAddKeys = Object.keys(filteredAwaitingAdds).map(Number);
      return Array.from(new Set([...filteredTagKeys, ...filteredAwaitingAddKeys]))
        .map((id) => ({ id, info: walletInfos.find((w) => w.walletId === id) }))
        .filter(({ info }) => !!info)
        .sort((a, b) => (a.id - b.id));
    }
    return [];
  }, [filteredTags, filteredAwaitingAdds, walletInfos]);

  const checkExistingTag = React.useCallback((name: string, walletId: number) => {
    const existingTag = tags.filter((t) => t.tag === name && t.walletId === walletId);
    const awaitingAdd = awaitingAdds?.filter((t) => t.tagName === name && t.walletId === walletId) ?? [];
    return (existingTag.length + awaitingAdd.length) > 0;
  }, [tags, awaitingAdds]);

  const disableAddTag = useMemo(() => {
    if (!tagName) return true;
    if (isAllWallets() || activeId?.activeWalletId === undefined) return false;
    return (checkExistingTag(tagName, activeId.activeWalletId));
  }, [tagName, checkExistingTag]);

  const tagInput = (
    <Box className={classes.textFieldWrapper}>
      <InputWithTitle
        title="Provide tags to filter down by"
      >
        <LightTextField
          value={tagName}
          onChange={(e) => {
            setEditTagId(undefined);
            setTagName(e.target.value);
          }}
          placeholder="Search/Add tag"
          onKeyDown={!isAllWallets() ? async (e) => { if (e.key === 'Enter') await handleAddTagName(); } : undefined}
          InputProps={{
            endAdornment: (
              <Button
                className={classes.addItemIconWrapper}
                onClick={disableAddTag ? () => { } : handleAddTagName}
                id={disableAddTag ? 'disabled' : undefined}
                variant="icon"
                tooltip={disableAddTag ? {
                  title: (
                    <Box display="flex" gridGap={4} alignItems="center">
                      <SVG width={12} height={12} src="/icons/circle_question_red.svg" />
                      <Typography variant="h6" paletteColor={600}>
                        {tagName ? 'Tag already exists' : 'Please enter tag name'}
                      </Typography>
                    </Box>
                  ),
                  enterTouchDelay: 1,
                  placement: 'top-end',
                  arrow: true,
                } : undefined}
              >
                <SVG src="icons/reporting/ic_add_item.svg" className={classes.icon} />
              </Button>
            ),
          }}
        />
      </InputWithTitle>
    </Box>
  );

  const manageTag = React.useMemo(() => (
    <Scrollable className={classes.scrollable}>
      {(tagWalletIds?.length ?? 0) > 0 ? tagWalletIds.map(({ id, info }) => (
        <React.Fragment key={id}>
          {tagWalletIds.length > 1 && (
            <Box className={classes.rowWrapper} id="header">
              <Box className={classes.walletIcon}>
                <img
                  alt={info?.name ?? ''}
                  src={`/icons/wallet_setting/${getWalletProviderIcon(info?.walletProvider)}_sm.svg?${generateRandomString()}`}
                />
              </Box>
              <Typography ml={4} paletteColor={700} variant="h4">{info?.name ?? info?.walletProvider}</Typography>
              <Typography paletteColor={700} className={classes.divider}>|</Typography>
              <Typography paletteColor={700} className={classes.hashtag}>#</Typography>
              <Typography paletteColor={700} className={classes.walletOrder}>{info?.order}</Typography>
            </Box>
          )}
          {filteredTags[id]?.map((tag) => (
            <Box key={tag.tagId} className={classes.rowWrapper}>
              {editTagId === tag.tagId ? (
                <RemoveTagRow
                  tag={tag}
                  onClose={() => { setEditTagId(undefined); }}
                  checkExistingTag={checkExistingTag}
                />
              ) : (
                <ManageTagRow
                  awaitingAdd={false}
                  awaitingEdit={!!awaitingEdits?.find((t) => t.old.tagId === tag.tagId)}
                  awaitingRemove={!!awaitingRemoves?.find((t) => t.tagId === tag.tagId)}
                  tagName={tag.tag}
                  tagId={tag.tagId}
                  onRemove={handleOnClickRemove(tag.tagId)}
                  onEdit={() => setEditTagId(tag.tagId)}
                />
              )}
            </Box>
          ))}
          {filteredAwaitingAdds[id]?.map((tag, index) => (
            <Box key={`awaiting ${index}`} className={classes.rowWrapper}>
              <ManageTagRow
                awaitingAdd
                awaitingEdit={false}
                awaitingRemove={false}
                tagName={tag.tagName}
              />
            </Box>
          ))}
        </React.Fragment>
      )) : (
        <Typography className={classes.noAddress} variant="h2" paletteColor={300} align="center">
          No Tag
        </Typography>
      )}
    </Scrollable>
  ), [filteredAwaitingAdds, filteredTags, tagWalletIds, awaitingEdits, awaitingRemoves, editTagId]);

  const removeTag = tags && tagIdToRemove && tagsWithCounts !== undefined && (
    <RemoveItem
      header="Are you sure you want to remove this tag?"
      content={`${tagsWithCounts[tagIdToRemove] ?? 0} transactions are connected to the tag so you will
      lost your filter for this type of transactions after removing it.`}
      tagLabel={tags.find((tag) => tag.tagId === tagIdToRemove)?.tag}
      onCancel={() => {
        setTagIdToRemove(null);
        setModalState(ModalState.ManageTags);
      }}
      onRemoveItem={handleRemoveTagName}
      disableButtons={disableButtons}
    />
  );

  const handleOnClose = () => {
    setShowModal(false);
    setModalState(ModalState.ManageTags);
    setTagIdToRemove(null);
    setEditTagId(undefined);
    setTagName('');
  };

  return (
    <LightModal
      open={showModal}
      onClose={handleOnClose}
      setOpen={setShowModal}
      showCloseButton
      noPadding
      maxWidth={680}
      fullScreenOnMobile
      className={classes.modal}
      titleItem={modalState === ModalState.ManageTags && tagInput}
      modalTitle={(
        <Box className={classes.header}>
          <Hidden xsDown>
            <Box width={24} height={24} display="flex" flexShrink={0}>
              <SVG src="icons/reporting/ic_tag.svg" width={24} height={24} />
            </Box>
          </Hidden>
          <Typography variant="h2">Tag Manager</Typography>
        </Box>
      )}
    >
      {modalState === ModalState.ManageTags && manageTag}
      {modalState === ModalState.RemoveTag && tagIdToRemove != null && removeTag}
      {isLoading && (
        <Box className={classes.overlay}>
          <Box className="sk-spinner-pulse" />
        </Box>
      )}
      <AddTagModal
        showModal={showAddTagModal}
        setShowModal={setShowAddTagModal}
        initTag={tagName}
      />
    </LightModal>
  );
};

// eslint-disable-next-line no-unused-vars
const groupBy = <T, K extends keyof any>(arr: T[], key: (i: T) => K) => (
  arr.reduce((groups, item) => {
    // eslint-disable-next-line no-param-reassign
    if (groups[key(item)] === undefined) groups[key(item)] = [];
    groups[key(item)].push(item);
    return groups;
  }, {} as Record<K, T[]>));
